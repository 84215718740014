// Local Dependencies
import useSelfQuery from './useSelfQuery';

// Hook Definition
const useIsOrgCreator = () => {
  const { self } = useSelfQuery();

  return self?.currentOrgId && self?.isCreator;
};

export default useIsOrgCreator;
