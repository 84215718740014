// External Dependencies
import { AxiosResponse } from 'axios';
import { DocumentNode } from '@apollo/client';
import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import {
  isPaginatedListDataLoaded as isPaginatedListDataLoadedSelector,
} from 'state/table/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useQueryEnhanced } from 'utils/lib/graphql';
import useDidMount from 'hooks/useDidMount';
import usePrevious from 'hooks/usePrevious';

export const usePaginatedListQuery = <T, D>({
  dataSelector,
  fullCountSelector,
  pageSize = 500,
  query,
  uniqueKey,
}: {
  dataSelector: (res: T) => D[] | undefined;
  fullCountSelector: (res: T) => number | undefined;
  pageSize?: number;
  query: DocumentNode;
  uniqueKey?: string;
}) => {
  const [allData, setAllData] = useState<D[]>([]);
  const [fullCount, setFullCount] = useState<number | null>(null);
  const [page, setPage] = useState(1);

  const didMount = useDidMount();

  const dispatch = useDispatch();
  const isPaginatedListDataLoaded = useSelector(isPaginatedListDataLoadedSelector);

  const refetch = useCallback(() => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));
  }, [dispatch]);

  const queryPageSize = Math.min(pageSize, 500);

  const hasFetchedAll = fullCount !== null && fullCount <= queryPageSize * (page - 1);

  const previouslyLoaded = usePrevious(isPaginatedListDataLoaded);

  useEffect(() => {
    if (isPaginatedListDataLoaded === false && previouslyLoaded) {
      setAllData([]);
      setFullCount(null);
      setPage(1);
    }
  }, [previouslyLoaded, isPaginatedListDataLoaded]);

  const result = useQueryEnhanced<T>(query, {
    onCompleted: (res) => {
      setFullCount(fullCountSelector(res) ?? null);

      setAllData([
        ...allData,
        ...(dataSelector(res) ?? []),
      ]);

      setPage((p) => p + 1);
    },
    skip: hasFetchedAll,
    variables: {
      queryParams: {
        limit: queryPageSize,
        page,
      },
    },
  });

  const isLoading = result.loading || !hasFetchedAll;

  useEffect(() => {
    if (hasFetchedAll) {
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: true,
      }));
    }
  }, [dispatch, hasFetchedAll]);

  useEffect(() => {
    if (didMount) {
      setPage(1);
      setAllData([]);
      setFullCount(null);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    }
  // Do not check for didMount in dep array
  // We only want this to run when uniqueKey changes
  // Otherwise, we will get an infinite loading state
  // From reading this issue: https://github.com/apollographql/apollo-client/issues/6636
  // It seems we might be able to remove the didMount value when we upgrade to Apollo 3
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueKey]);

  return useMemo(() => ({
    data: hasFetchedAll ? allData : null,
    fullCount: fullCount ?? 0,
    isLoading,
    refetch,
  // intentionally omitting allData from dep array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    fullCount,
    hasFetchedAll,
    isLoading,
    refetch,
  ]);
};

export const useInfinitePaginatedListQuery = <T, D>({
  dataSelector,
  fullCountSelector,
  pageSize = 500,
  queryKey,
  request,
}: {
  dataSelector: (res: T) => D[] | undefined;
  fullCountSelector?: (res: T) => number | undefined;
  pageSize?: number;
  queryKey: QueryKey;
  request: (queryParams: {
    limit: number;
    page: number;
  }) => Promise<AxiosResponse<T>>;
}) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery<AxiosResponse<T>>({
    getNextPageParam: (lastPage) => {
      const fullCountSelectorResult = fullCountSelector?.(lastPage.data) ?? null;

      const lastPageData = dataSelector(lastPage.data);
      const currentPage = lastPage?.config?.params.page ?? 1;

      const dataSelectorLength = lastPageData
        ? lastPageData.length + (pageSize * (currentPage - 1)) : null;
      const localFullCount = Number(fullCountSelectorResult ?? dataSelectorLength ?? 0);

      if (localFullCount < pageSize * currentPage) {
        return undefined;
      }

      return currentPage + 1;
    },
    queryFn: async ({ pageParam }) => {
      return request({
        limit: pageSize,
        page: pageParam ?? 1,
      });
    },
    queryKey: [...queryKey, 'infinite'],
  });

  useEffect(() => {
    const dataPagesCount = data?.pages.length ?? 0;

    if (dataPagesCount && hasNextPage) {
      fetchNextPage({
        pageParam: dataPagesCount + 1,
      });
    }
  }, [data, fetchNextPage, hasNextPage]);

  const allData = useMemo(() => {
    return (data?.pages.map((p) => dataSelector(p.data) ?? []) ?? []).flat();
  }, [data, dataSelector]);

  const isLoading = (!data || isFetching) || hasNextPage;

  const refetch = useCallback(() => {
    fetchNextPage({
      pageParam: 1,
    });
  }, [fetchNextPage]);

  const fullCount = useMemo(() => {
    if (data?.pages[0]?.data && fullCountSelector) {
      const firstPageFullCount = fullCountSelector(data?.pages[0]?.data);

      return firstPageFullCount ? Number(firstPageFullCount) : null;
    }

    if (fullCountSelector) {
      return null;
    }

    return allData.length;
  }, [allData.length, data?.pages, fullCountSelector]);

  return useMemo(() => ({
    data: isLoading ? null : allData,
    fullCount: fullCount ?? 0,
    isLoading,
    refetch,
  }), [
    allData,
    fullCount,
    isLoading,
    refetch,
  ]);
};
