// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { capitalize } from 'utils';
import {
  renderCheckboxCell,
} from 'components/shared/TableDataGrid/helpers';
import { selectEditModeTable } from 'state/table/selectors';

// Local Variables
const getPermissionCell = (args: {
  field: string;
  headerName: string;
  isEditModeActive: boolean;
  minWidth?: number;
  valueGetter: GridColDef<GQL.IMemberPermission>['valueGetter'];
  valueSetter: GridColDef<GQL.IMemberPermission>['valueSetter'];
}): GridColDef<GQL.IMemberPermission> => ({
  editable: true,
  field: args.field,
  headerName: args.headerName,
  hideable: true,
  minWidth: args.minWidth ?? 200,
  renderCell: (params) => renderCheckboxCell({
    isEditModeActive: args.isEditModeActive,
    params,
  }),
  renderEditCell: (params) => renderCheckboxCell({
    isEditModeActive: args.isEditModeActive,
    params,
  }),
  type: 'boolean',
  valueGetter: args.valueGetter,
  valueSetter: args.valueSetter,
  width: 250,
});

const getPermissionCellGroup = (args: {
  actions: (keyof GQL.IPermissionActions)[];
  headerName: string;
  isEditModeActive: boolean;
  minWidth?: number;
  permissionName: keyof GQL.IPermissions,
}) => args.actions.map((action) => getPermissionCell({
  field: `permissions.${args.permissionName}.${action}.permissionId`,
  headerName: `${args.headerName} — ${capitalize(action)}`,
  isEditModeActive: args.isEditModeActive,
  // cast to any is necessary because of possibly providing "__typename"
  minWidth: args.minWidth,
  valueGetter: (params) =>
    (params.row.permissions as any)[args.permissionName as any][action].allowed,
  valueSetter: (params) => ({
    ...params.row,
    permissions: {
      ...params.row.permissions,
      [args.permissionName]: {
        ...(params.row.permissions as any)[args.permissionName],
        [action]: {
          ...(params.row.permissions as any)[args.permissionName as any][action],
          allowed: params.value,
        },
      },
    },
  }),
}));

export const useColumns = (tableResource: 'directorPermissions') => {
  const currentEditModeTable = useSelector(selectEditModeTable);

  const isEditModeActive = currentEditModeTable === tableResource;

  return useMemo(() => {
    const columns: GridColDef<GQL.IMemberPermission>[] = [
      {
        editable: false,
        field: 'email',
        headerName: 'Email',
        hideable: false,
        minWidth: 150,
        valueGetter: (params) => params.row.member.email,
      },
      {
        editable: false,
        field: 'firstName',
        headerName: 'First Name',
        hideable: false,
        valueGetter: (params) => params.row.member.firstName,
      },
      {
        editable: false,
        field: 'lastName',
        headerName: 'Last Name',
        hideable: false,
        valueGetter: (params) => params.row.member.lastName,
      },
      {
        editable: false,
        field: 'organization',
        headerName: 'Organization',
        hideable: false,
        minWidth: 150,
        valueGetter: (params) => params.row.organization.label,
      },
      {
        editable: true,
        field: 'mayEditPermissions',
        headerName: 'May Edit Permissions',
        hideable: false,
        minWidth: 180,
        renderCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        renderEditCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        type: 'boolean',
        valueGetter: (params) => params.row.mayEditPermissions,
      },
      ...getPermissionCellGroup({
        actions: ['write'],
        headerName: 'Email',
        isEditModeActive,
        permissionName: 'emailMembers',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'File Uploads',
        isEditModeActive,
        permissionName: 's3Uploads',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Finances',
        isEditModeActive,
        permissionName: 'finances',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Financial Accounts',
        isEditModeActive,
        permissionName: 'financialAccounts',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Groups',
        isEditModeActive,
        permissionName: 'groups',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'delete'],
        headerName: 'Inventory (All Fields)',
        isEditModeActive,
        minWidth: 220,
        permissionName: 'inventory',
      }),
      ...getPermissionCellGroup({
        actions: ['edit'],
        headerName: 'Inventory Basic Info',
        isEditModeActive,
        permissionName: 'inventory',
      }),
      ...getPermissionCellGroup({
        actions: ['edit'],
        headerName: 'Inventory Status Info',
        isEditModeActive,
        permissionName: 'inventoryStatusInfo',
      }),
      ...getPermissionCellGroup({
        actions: ['edit'],
        headerName: 'Inventory Location Info',
        isEditModeActive,
        minWidth: 220,
        permissionName: 'inventoryLocationInfo',
      }),
      ...getPermissionCellGroup({
        actions: ['edit'],
        headerName: 'Inventory Purchase Info',
        isEditModeActive,
        minWidth: 230,
        permissionName: 'inventoryPurchaseInfo',
      }),
      ...getPermissionCellGroup({
        actions: ['edit'],
        headerName: 'Inventory Dynamic Fields',
        isEditModeActive,
        minWidth: 230,
        permissionName: 'inventoryDynamicFields',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit'],
        headerName: 'Inventory Checkouts',
        isEditModeActive,
        permissionName: 'inventoryCheckouts',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Library',
        isEditModeActive,
        permissionName: 'library',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit'],
        headerName: 'Payments',
        isEditModeActive,
        permissionName: 'payments',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Uniform Checkouts',
        isEditModeActive,
        permissionName: 'uniformCheckouts',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Uniforms',
        isEditModeActive,
        permissionName: 'uniforms',
      }),
      ...getPermissionCellGroup({
        actions: ['write', 'read', 'edit', 'delete'],
        headerName: 'Users',
        isEditModeActive,
        permissionName: 'users',
      }),
    ];

    return columns;
  }, [isEditModeActive]);
};
