// External Dependencies
import { useMemo } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';

// Local Dependencies
import { StyledTableRow } from '../styles';

// Local Typings
interface Props {
  onClickAdd: () => void;
}

// Component Definition
const AddNewRowTableRow = ({
  onClickAdd,
}: Props): JSX.Element => {
  const tableCellElement = useMemo(() => (
    <TableCell />
  ), []);

  return (
    <StyledTableRow>
      <TableCell>
        <Box marginY={0.5}>
          <IconButton
            color="primary"
            onClick={onClickAdd}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </Box>
      </TableCell>
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
      {tableCellElement}
    </StyledTableRow>
  );
};

export default AddNewRowTableRow;
