// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useGetInventoryItem } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateInventoryItem } from 'gql/mutations';
import InventoryForm,
{
  AdminInventoryPayloadValues,
  InventoryPayloadValues,
} from 'pages/Inventory/shared/InventoryForm';

// Component Definition
const AdminInventoryEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const navigateToShow = useCallback((inventoryId: string) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${inventoryId}`);
  }, [navigate]);

  const [handleUpdateInventoryItem] = useUpdateInventoryItem(
    {
      clearCachePredicates: ['districtInventory', 'inventoryItem'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (
    values: InventoryPayloadValues | AdminInventoryPayloadValues,
  ) => {
    await handleUpdateInventoryItem({
      variables: {
        id: id!,
        input: {
          ...values,
          onLoanToOrganizationId: (values.onLoanToOrganizationId === '-1' || values.onLoanToOrganizationId === '')
            ? null
            : values.onLoanToOrganizationId,
          ...applyDynamicFields(values as unknown as DynamicFields),
          purchaseYear: values.purchaseYear || null,
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Item Detail',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <InventoryForm
        canUseDynamicFields
        inventoryItem={data?.inventoryItem}
        isAdmin
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default AdminInventoryEdit;
