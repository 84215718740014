// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';

// Component Definition
const StyledH1 = styled.h1({
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
});

const Heading1: FC = ({
  children,
  ...props
}) => (
  <StyledH1
    aria-label={APP_NAME}
    {...props}
  >
    {children}
  </StyledH1>
);

export default Heading1;
