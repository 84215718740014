// External Dependencies
import {
  Box,
  CircularProgress,
} from '@mui/material';
import { FC } from 'react';

// Component Definition
const OnboardingProgress: FC = () => (
  <Box paddingTop={8}>
    <CircularProgress thickness={5} />
  </Box>
);

export default OnboardingProgress;
