// External Dependencies
import {
  CardContent,
  FormControl,
  FormControlLabel,
  ListSubheader,
  RadioGroup as MUIRadioGroup,
  Radio,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';

// Local Typings
interface RadioButtonOption {
  label: string;
  value: any;
}

export interface RadioGroupProps {
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  options: RadioButtonOption[];
  title: string;
  value: any;
}

// Component Definition
const RadioGroup: FC<RadioGroupProps> = ({
  name,
  onChange,
  options,
  title,
  value,
}) => (
  <CardContent className="cardContent">
    <FormControl component="fieldset">
      <ListSubheader
        component="legend"
        sx={{ background: 'inherit' }}
      >
        {title}
      </ListSubheader>

      <MUIRadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            control={<Radio color="primary" />}
            key={option.label}
            label={option.label}
            value={option.value}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  </CardContent>
);

export default RadioGroup;
