interface Options {
  pascalCase?: boolean;
}

const defaultOptions: Options = {
  pascalCase: false,
};

export default (input: string, options: Options = {}) => {
  const opts = {
    ...defaultOptions,
    ...options,
  };

  const rest = opts?.pascalCase ? input.slice(1) : input.slice(1).toLowerCase();

  return input.charAt(0).toUpperCase() + rest;
};
