const getFullName = ({
  firstName,
  lastName,
  middleName,
}: {
  firstName: string | undefined;
  lastName: string | undefined;
  middleName?: string | null;
}) => (middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`);

export default getFullName;
