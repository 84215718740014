// External Dependencies
import { FC, useCallback } from 'react';
import { updateSelfSchema } from '@presto-assistant/api_types/schemas/self';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { trimValues } from 'utils/lib/trimValues';
import { useGetSelf } from 'gql/queries';
import { useUpdateSelf } from 'gql/mutations';
import AdultForm from 'pages/People/shared/AdultForm';

// Component Definition
const AdminProfileEdit: FC = () => {
  const navigate = useNavigate();
  const { data: selfData, loading: isLoading } = useGetSelf();

  const navigateToProfile = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}`);
  }, [navigate]);

  const [updateAdminProfile] = useUpdateSelf(
    {
      onCompleted: navigateToProfile,
    },
  );

  const handleSubmit = useCallback(async (values: GQL.IUpdateSelfOnMutationArguments) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      primarySpokenLanguageId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      roleId,
      ...otherValues
    } = values;

    const localPayload: GQL.IUpdateSelfOnMutationArguments = {
      ...trimValues(otherValues) as GQL.IUpdateSelfOnMutationArguments,
      primarySpokenLanguageId: primarySpokenLanguageId ? Number(primarySpokenLanguageId) : 1,
    };

    if (localPayload) {
      updateAdminProfile({
        variables: localPayload,
      });
    }
  }, [updateAdminProfile]);

  return (
    <Page
      backButtonProps={{
        label: 'Profile',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}`,
      }}
      isLoading={isLoading}
    >
      {selfData?.self && (
        <AdultForm
          initialValues={{
            addressOne: selfData.self.addressOne ?? '',
            addressTwo: selfData.self.addressTwo ?? '',
            city: selfData.self.city ?? '',
            dateOfBirth: selfData.self.dateOfBirth ?? '',
            email: selfData.self.email ?? '',
            firstName: selfData.self.firstName ?? '',
            genderId: selfData.self.gender?.id ?? '',
            lastName: selfData.self.lastName ?? '',
            middleName: selfData.self.middleName ?? '',
            phoneNumber: selfData.self.phoneNumber ?? '',
            primarySpokenLanguageId:
              Number(selfData.self.primarySpokenLanguage?.id) ?? 1,
            stateId: selfData.self.stateId ?? '',
            title: selfData.self.title ?? '',
            zipcode: selfData.self.zipcode ?? '',
          }}
          isDfa
          isDirector
          onSubmit={handleSubmit as any} // this works for now, but it's a quick fix
          validationSchema={updateSelfSchema}
        />
      )}
    </Page>
  );
};

export default AdminProfileEdit;
