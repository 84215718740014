// External Dependencies
import { ReactElement, Ref, forwardRef } from 'react';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

// Component Definition
const SlideUpTransition = forwardRef((
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

export default SlideUpTransition;
