// External Dependencies
import {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AdminIcon from 'mdi-material-ui/ShieldAccount';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedMenuItem } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import {
  isDesktopScreenSize,
  isMobileScreenSize,
} from 'state/device/selectors';
import { isDistrictAdmin } from 'state/self/selectors';
import { logoutCurrentUser } from 'state/self/actions';
import { useLogout } from 'utils/api';
import UnclaimedMembersList from 'pages/Profile/UnclaimedMembersCard/UnclaimedMembersList';

// Local Dependencies
import OrgSubMenu from './OrgSubMenu';
import SideNavSubtitle from '../SideNav/SideNavSubtitle';

// Local Typings
interface Props {
  self: GQL.ISelf;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '.avatarSvgIcon': {
    [theme.breakpoints.down('md')]: {
      height: 20,
      width: 20,
    },
    height: 24,
    width: 24,
  },
  '.downArrowSvgIcon': {
    height: 20,
    paddingLeft: theme.spacing(0.5),
    width: 20,
  },
  '.textPrimary': {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    color: theme.palette.text.primary,
  },
  '.userNameContainer': {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    alignItems: 'center',
    display: 'flex',
    marginRight: 4,
    padding: '5px 8px 5px 10px',
  },
  '.userNameText': {
    marginLeft: 8,
    marginRight: 6,
  },
}));

const StyledProfileButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  padding: theme.spacing(0.5, 2),
  textTransform: 'none',
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '.MuiAvatar-root': {
    backgroundColor: theme.palette.prestoPrimaryLight,
  },
  '.closeIconButton': {
    fontSize: '1.25rem',
    padding: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  '.dividerSpaced': {
    margin: `${theme.spacing(1)} 0`,
  },
  '.popoverPaper': {
    minWidth: 264,
  },
  '.profileCardContent': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(0.5),
  },
}));

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin = { horizontal: 'right', vertical: 'top' };

// Component Definition
const ProfileMenu = ({
  self,
}: Props): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isDesktopScreen = useSelector(isDesktopScreenSize);
  const isMobileScreen = useSelector(isMobileScreenSize);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handlePressManageProfile = useCallback(() => {
    handleClose();
    navigate(isDistrictAdminUser
      ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}`
      : `/${PATHS.PROFILE}`);
  }, [navigate, isDistrictAdminUser, handleClose]);

  const handleOpenJoinNewDialog = useCallback(() => {
    handleClose();
    navigate(isDistrictAdminUser ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.JOIN_NEW_ORGANIZATION}` : `/${PATHS.JOIN_NEW_ORGANIZATION}`);
  }, [navigate, isDistrictAdminUser, handleClose]);

  const logout = useLogout();

  const handleLogout = async () => {
    handleClose();
    await logout();
    dispatch(logoutCurrentUser());
  };

  const avatarSvgIconOverride = { root: 'avatarSvgIcon' };
  const userNameTextOverride = { colorPrimary: 'textPrimary' };
  const downArrowSvgIconOverride = { root: 'downArrowSvgIcon' };

  // If the user has different member name or email in other user orgs,
  //  we want to show that in the Organization menu items
  // If all data matches, we leave it as the Organization name and type only
  const shouldShowMemberProfileInfo = useMemo(
    () => self.userOrgData.some((userOrg) =>
      (self.firstName !== userOrg.member?.firstName ?? self.firstName)
      || (self.lastName !== userOrg.member?.lastName ?? self.lastName)),
    [self.firstName, self.lastName, self.userOrgData],
  );

  const ProfileIcon = isDistrictAdminUser ? AdminIcon : AccountCircle;

  const hasUnclaimedMembers = self.matchedUnclaimedMembers.length > 0;

  return (
    <>
      <StyledButton
        aria-controls={isMenuOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        className="userNameContainer"
        onClick={handleClick}
        ref={buttonRef}
        variant="outlined"
      >
        {self.currentOrgId && (
          <AccountCircle
            classes={avatarSvgIconOverride}
            htmlColor={theme.palette.prestoPrimaryLight}
          />
        )}

        {isDistrictAdminUser && !self.currentOrgId && (
          <AdminIcon
            classes={avatarSvgIconOverride}
            htmlColor={theme.palette.prestoPrimaryLight}
          />
        )}

        {isDesktopScreen && (
          <Typography
            className="userNameText"
            classes={userNameTextOverride}
            color="primary"
            variant="button"
          >
            {self.firstName}
          </Typography>
        )}

        <KeyboardArrowDown
          classes={downArrowSvgIconOverride}
          htmlColor={theme.palette.text.secondary}
        />
      </StyledButton>

      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin as PopoverOrigin}
        classes={{
          paper: 'popoverPaper',
        }}
        id="profile-menu"
        onClose={handleClose}
        open={isMenuOpen}
        role="menu"
        transformOrigin={transformOrigin as PopoverOrigin}
      >
        <CardContent className="profileCardContent">
          {isMobileScreen && (
            <IconButton
              aria-label="Close Profile Menu"
              className="closeIconButton"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}

          <Avatar>
            <ProfileIcon />
          </Avatar>

          <Box marginTop={1.5}>
            {getFullName(self)}
          </Box>

          <Typography
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="body2"
          >
            {self.email}
          </Typography>

          <Box marginTop={2}>
            <StyledProfileButton
              onClick={handlePressManageProfile}
              size="small"
              variant="outlined"
            >
              Manage your Presto profile
            </StyledProfileButton>
          </Box>
        </CardContent>

        <SideNavSubtitle subtitleText="Organizations" />

        <OrgSubMenu
          closeParent={handleClose}
          currentOrgId={self.currentOrgId}
          onClickJoinOrg={handleOpenJoinNewDialog}
          shouldShowMemberProfileInfo={shouldShowMemberProfileInfo}
          userOrgData={self.userOrgData}
        />

        <Divider className="dividerSpaced" />

        {hasUnclaimedMembers && (
          <>
            <SideNavSubtitle subtitleText="Unclaimed Member Accounts" />

            <UnclaimedMembersList
              shouldSwitchToMember={false}
              unclaimedMembers={self.matchedUnclaimedMembers}
            />

            <Divider className="dividerSpaced" />
          </>
        )}

        <EnhancedMenuItem
          icon={LogoutIcon}
          onClick={handleLogout}
          text="Sign Out"
        />
      </StyledPopover>
    </>
  );
};

export default ProfileMenu;
