// External Dependencies
import { Column } from 'react-table';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import {
  TableResource,
  TableSelection,
  updateTableSelections,
} from 'state/table/actions';
import { tableSelection } from 'state/table/selectors';
import ToolbarMoreActionsIconMenu, { MoreActionsItem } from 'components/shared/DataTable/ToolbarMoreActionsIconMenu';

// Local Typings
export type TableColumn<T> = Omit<Column<T & object>, 'accessor'> & {
  Cell?: (value: any) => ReactElement;
  accessor: (row: T) => React.ReactNode;
  align?: 'left' | 'center' | 'right';
  sortBy?: string;
}

export type CreateActionColumnAction<T> = ToolbarAction<T> & MoreActionsItem<T>;

interface ToolbarAction<T> {
  shouldRender?: (row: T) => boolean;
}

interface ActionColumnOptions {
  disabled?: boolean;
}

export function createActionsColumn<T>(
  actions: (CreateActionColumnAction<T>)[],
  options?: ActionColumnOptions,
): TableColumn<T> | null {
  return actions.length ? {
    Header: 'Actions',
    accessor: ((row: T) => {
      const filteredActions = actions.filter((action) =>
        action.shouldRender?.(row) ?? true);

      if (!filteredActions.length) {
        return null;
      }

      return (
        <ToolbarMoreActionsIconMenu<T>
          disabled={options?.disabled}
          moreActions={filteredActions.map(({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            shouldRender,
            ...action
          }) => ({
            ...action,
          }))}
          row={row}
        />

      );
    }),
    align: 'right',
  } as TableColumn<T> : null;
}

export function createDataGridActionsColumn<T>(
  actions: (CreateActionColumnAction<T>)[],
  options?: ActionColumnOptions,
): GridColDef<T> | null {
  return actions.length ? {
    align: 'center',
    disableColumnMenu: true,
    disableExport: false,
    field: 'Actions',
    filterable: false,
    headerAlign: 'center',
    headerName: '',
    hideable: false,
    maxWidth: 40,
    minWidth: 40,
    pinnable: false,
    renderCell: (params) => {
      const { row } = params;

      const filteredActions = actions.filter((action) =>
        action.shouldRender?.(row) ?? true);

      if (!filteredActions.length) {
        return null;
      }

      return (
        <ToolbarMoreActionsIconMenu<T>
          disabled={options?.disabled}
          moreActions={filteredActions.map(({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            shouldRender,
            ...action
          }) => ({
            ...action,
          }))}
          row={row}
        />

      );
    },
    sortable: false,
  } : null;
}

export function useCheckboxes<T extends object & { id: string }>({
  fullCount = 0,
  localData,
  reduxStateKey,
  selectAllQuery,
  selectedAllIdData,
}: {
  fullCount?: number;
  localData: T[];
  reduxStateKey: TableResource;
  selectAllQuery?: () => void;
  selectedAllIdData?: object[];
}) {
  const dispatch = useDispatch();
  const selections = useSelector(tableSelection(reduxStateKey));

  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const {
    ids: selectionIds,
    selectionType: currentSelectionType,
  } = selections;

  const localDataIds = localData.map((item) => item.id);
  const isAllOnPageSelected = localDataIds.every((id) => selectionIds.includes(id));

  const handleClearSelection = () => {
    dispatch(updateTableSelections({
      key: reduxStateKey,
      value: {
        ids: [],
        selectionType: 'SelectedOne',
      },
    }));
  };

  const handleSelectAll = () => {
    if (selectAllQuery) {
      setIsSelectedAll(true);
      selectAllQuery();
    }
  };

  useEffect(() => {
    if (currentSelectionType !== 'SelectedAll') {
      setIsSelectedAll(false);
    }
  }, [currentSelectionType]);

  // If the selectAllQuery runs, then we set the
  //  Redux table selection state with the returned ids
  // We also check if the selectionType changes to 'SelectedAll'
  useEffect(() => {
    if (selectedAllIdData && selectedAllIdData.length > 0 && isSelectedAll) {
      const allIds = selectedAllIdData?.map(
        (datum: any) => datum.id,
      );

      const newIds = [
        ...new Set([...allIds]),
      ];

      dispatch(updateTableSelections({
        key: reduxStateKey,
        value: {
          ids: newIds,
          selectionType: 'SelectedAll',
        },
      }));
    }
  }, [
    dispatch,
    isSelectedAll,
    reduxStateKey,
    selectedAllIdData,
  ]);

  const handleSelectAllOnPage = () => {
    let ids: string[] = [];
    let newSelectionType: TableSelection['selectionType'] = 'SelectedOne';

    if (currentSelectionType === 'SelectedAll') {
      ids = [...localDataIds];
      newSelectionType = 'SelectedAllExcept';
    } else if (currentSelectionType === 'SelectedAllExcept') {
      const areAnyCheckboxesEmpty = localDataIds.some((id) => selectionIds.includes(id));

      ids = areAnyCheckboxesEmpty
        ? selectionIds.filter((id) => !localDataIds.includes(id))
        : [
          ...new Set([
            ...selectionIds,
            ...localDataIds,
          ]),
        ];
      newSelectionType = 'SelectedAllExcept';
    } else if (isAllOnPageSelected) {
      newSelectionType = 'SelectedOne';
      ids = selectionIds.filter((id) => !localDataIds.includes(id));
    } else {
      newSelectionType = 'SelectedMany';

      ids = [
        ...new Set([
          ...selectionIds,
          ...localDataIds,
        ]),
      ];
    }

    dispatch(updateTableSelections({
      key: reduxStateKey,
      value: {
        ids,
        selectionType: newSelectionType,
      },
    }));
  };

  const handleSelectItem = (id: string) => () => {
    const isCheckedAll = currentSelectionType === 'SelectedAll'
      || (currentSelectionType === 'SelectedMany' && isAllOnPageSelected);

    const ids = [...selectionIds];

    const idIndex = ids.indexOf(id);

    if (idIndex > -1) {
      ids.splice(idIndex, 1);
    } else {
      ids.push(id);
    }

    let newSelectionType: TableSelection['selectionType'] = isCheckedAll
      ? 'SelectedAllExcept'
      : 'SelectedOne';

    if (!isCheckedAll && ids.length > 1) {
      newSelectionType = 'SelectedMany';
    }

    if (currentSelectionType === 'SelectedMany' && ids.length > 1) {
      newSelectionType = 'SelectedMany';
    }

    if (currentSelectionType === 'SelectedAllExcept') {
      newSelectionType = 'SelectedAllExcept';
    }

    if (currentSelectionType === 'SelectedAll') {
      newSelectionType = 'SelectedAllExcept';
    }

    dispatch(updateTableSelections({
      key: reduxStateKey,
      value: {
        ids,
        selectionType: newSelectionType,
      },
    }));
  };

  const isChecked = (itemId: string) => {
    let checked = selectionIds.includes(itemId) || selections.selectionType === 'SelectedAll';

    if (selections.selectionType === 'SelectedAllExcept') {
      checked = selectionIds.includes(itemId);
    }

    return checked;
  };

  let isCheckedAll = localData.every((item) => selectionIds.includes(item.id));

  if (selections.selectionType === 'SelectedAllExcept') {
    isCheckedAll = !localData.some((item) => selectionIds.includes(item.id));
  }

  if (selections.selectionType === 'SelectedAll') {
    isCheckedAll = true;
  }

  let selectionCount = selectionIds.length;

  if (selections.selectionType === 'SelectedAll') {
    selectionCount = fullCount;
  }

  if (selections.selectionType === 'SelectedAllExcept') {
    selectionCount = selectionIds.length;
  }

  return {
    handleClearSelection,
    handleSelectAll,
    handleSelectAllOnPage,
    handleSelectItem,
    isChecked,
    isCheckedAll,
    selectionCount,
  };
}
