// External Dependencies
import {
  List,
  ListItem,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Internal Dependencies

// Local Variables
const StyledList = styled(List)(({ theme }) => ({
  '.icon': {
    marginRight: 8,
  },
  '.passwordGuideListItem': {
    paddingLeft: 0,
  },
  '.successIcon': {
    color: theme.palette.prestoSuccess,
    marginRight: 8,
  },
}));

const strengthGuidePropShape = PropTypes.shape({
  helperMessage: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
}).isRequired;

const propTypes = {
  strengthGuide: PropTypes.shape({
    hasLetter: strengthGuidePropShape,
    hasNoSpaces: strengthGuidePropShape,
    hasNumber: strengthGuidePropShape,
    isMinLength: strengthGuidePropShape,
  }).isRequired,
};

// Component Definition
const PasswordStrengthGuide = ({
  strengthGuide,
}) => {
  function renderCheckIcon(isValid) {
    return isValid
      ? (
        <CheckCircleIcon
          className="successIcon"
          fontSize="small"
        />
      ) : (
        <CheckCircleOutlineIcon
          className="icon"
          color="error"
          fontSize="small"
        />
      );
  }

  function renderText(message) {
    return (
      <Typography component="span">
        {message}
      </Typography>
    );
  }

  return (
    <StyledList
      component="span"
      dense
    >
      <ListItem className="passwordGuideListItem">
        {renderCheckIcon(strengthGuide.hasNoSpaces.isValid)}
        {renderText(strengthGuide.hasNoSpaces.helperMessage)}
      </ListItem>
      <ListItem className="passwordGuideListItem">
        {renderCheckIcon(strengthGuide.hasLetter.isValid)}
        {renderText(strengthGuide.hasLetter.helperMessage)}
      </ListItem>
      <ListItem className="passwordGuideListItem">
        {renderCheckIcon(strengthGuide.hasNumber.isValid)}
        {renderText(strengthGuide.hasNumber.helperMessage)}
      </ListItem>
      <ListItem className="passwordGuideListItem">
        {renderCheckIcon(strengthGuide.isMinLength.isValid)}
        {renderText(strengthGuide.isMinLength.helperMessage)}
      </ListItem>
    </StyledList>
  );
};

PasswordStrengthGuide.propTypes = propTypes;

export default PasswordStrengthGuide;
