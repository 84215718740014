// Internal Dependencies
import { mergeYupSchemas } from 'utils';
import { yup } from 'utils/yup';

export const libraryFormSchema = yup.object().shape({
  arranger: yup
    .string(),
  categoryId: yup
    .string(),
  composer: yup
    .string()
    .required('Required'),
  gradeLevelId: yup
    .string(),
  number: yup
    .string()
    .isPositive('Library Number must be positive'),
  title: yup
    .string()
    .required('Required'),
  year: yup
    .number()
    .positive('Year must be positive'),
});

const districtOnlySchema = yup.object().shape({
  orgTypeId: yup
    .string()
    .required('Required'),
  organizationId: yup
    .string()
    .required('Required'),
});

export const districtLibraryFormSchema = mergeYupSchemas(
  libraryFormSchema,
  districtOnlySchema,
);
