// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import styled from 'styled-components';

// Internal Dependencies
import { isMobileScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  context: string;
  navigateTo?: string;
  onClickButton?: () => void;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '.backIcon': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  // backgroundColor: theme.palette.common.white, // TODO: find out if we need this
}));

// Component Definition
const BackToButton = ({
  context,
  navigateTo,
  onClickButton,
  ...otherProps
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const handleClick = useCallback(() => {
    if (onClickButton) {
      onClickButton();
    } else if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo, onClickButton]);

  const buttonText = isMobileScreen
    ? 'Back'
    : `Back to ${context}`;

  return (
    <StyledButton
      color="primary"
      onClick={handleClick}
      onKeyPress={handleClick}
      size="small"
      startIcon={(
        <ArrowBackIcon
          className="backIcon"
          fontSize="small"
        />
      )}
      variant="outlined"
      {...otherProps}
    >
      {buttonText}
    </StyledButton>
  );
};

export default BackToButton;
