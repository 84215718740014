// External Dependencies
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  renderCheckboxCell,
} from 'components/shared/TableDataGrid/helpers';
import { selectEditModeTable } from 'state/table/selectors';
import {
  useGradeColDef,
  useGroupsColumn, usePrimaryRoleColDef,
} from 'components/shared/TableDataGrid/hooks';

// Local Variables
export const useColumns = ({
  checklist,
  tableResource,
} : {
  checklist: GQL.IChecklist;
  tableResource: 'checklist';
}) => {
  const currentEditModeTable = useSelector(selectEditModeTable);

  const isEditModeActive = currentEditModeTable === tableResource;

  const firstMemberDetails = checklist.memberItemDetails[0];
  const items = useMemo(
    () => firstMemberDetails?.checklistItems || [],
    [firstMemberDetails?.checklistItems],
  );

  const gradeCol = useGradeColDef<GQL.IChecklistMemberItemDetails>({
    field: 'member.grade' as keyof GQL.IChecklistMemberItemDetails,
    valueGetter: (params) => params.row.member.grade,
  });

  const groupsCol = useGroupsColumn<GQL.IChecklistMemberItemDetails>({
    field: 'member.groupIds' as keyof GQL.IChecklistMemberItemDetails,
    groupIdsSelector: (row) => row.member.groupIds,
  });

  const primaryRoleCol = usePrimaryRoleColDef<GQL.IChecklistMemberItemDetails>({
    editable: false,
    field: 'member.primaryRoleId' as keyof GQL.IChecklistMemberItemDetails,
    primaryRoleIdSelector: (row) => row.member.primaryRoleId,
    valueGetter: (params) => params.row.member.primaryRoleId,
  });

  return useMemo<DataGridColDef<GQL.IChecklistMemberItemDetails>[]>(() => {
    return [
      {
        field: 'member.firstName' as keyof GQL.IChecklistMemberItemDetails,
        headerName: 'First Name',
        valueGetter: (params) => params.row.member.firstName,
      },
      {
        field: 'member.lastName' as keyof GQL.IChecklistMemberItemDetails,
        headerName: 'Last Name',
        valueGetter: (params) => params.row.member.lastName,
      },
      { ...gradeCol },
      ...(primaryRoleCol ? [primaryRoleCol] : []),
      ...(groupsCol ? [groupsCol] : []),
      ...items.map<DataGridColDef<GQL.IChecklistMemberItemDetails>>((item, index) => ({
        editable: true,
        field: item.label as keyof GQL.IChecklistMemberItemDetails,
        headerName: `${item.label}${item.isRequired ? '*' : ''}`,
        minWidth: 200,
        renderCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        renderEditCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        type: 'boolean',
        valueGetter: (params) => {
          const checklistItem = params.row?.checklistItems[index];

          return Boolean(checklistItem?.completedAt);
        },
        valueSetter: (params) => {
          return {
            ...params.row,
            checklistItems: params.row.checklistItems.map((checklistItem) => {
              if (checklistItem.itemId === item.itemId) {
                return {
                  ...checklistItem,
                  completedAt: params.value ? new Date().toISOString() : null,
                };
              }

              return checklistItem;
            }),
          };
        },
      })),
    ];
  }, [gradeCol, groupsCol, isEditModeActive, items, primaryRoleCol]);
};
