// External Dependencies
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { createActionCreator } from 'deox';

// Actions
export const addOnePaymentId = createActionCreator(
  'ADD_ONE_PAYMENT_PICKER_DEPOSIT_FORM_PAYMENT',
  (resolve) => (recipientId: string) =>
    resolve(recipientId),
);

export const clearPaymentIds = createActionCreator(
  'CLEAR_ALL_PAYMENT_PICKER_DEPOSIT_FORM_PAYMENTS',
);

export const removePaymentId = createActionCreator(
  'REMOVE_PAYMENT_PICKER_DEPOSIT_FORM_PAYMENT',
  (resolve) => (recipientId: string) =>
    resolve(recipientId),
);

export const updatePaymentIds = createActionCreator(
  'UPDATE_PAYMENT_PICKER_DEPOSIT_FORM_PAYMENTS',
  (resolve) => (recipientIds: GridRowSelectionModel) =>
    resolve(recipientIds),
);
