// External Dependencies
import { TimelineDot } from '@mui/lab';
import { darken } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Component Definition
export const StyledChip = styled(Chip)(({ theme }) => ({
  '&:focus, &:hover': {
    backgroundColor: darken(theme.palette.history.showMoreButton, 0.2),
  },
  backgroundColor: theme.palette.history.showMoreButton,
  color: theme.palette.common.white,
  fontSize: '0.9rem',
  fontWeight: 500,
  padding: theme.spacing(0, 1.5),
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: darken(theme.palette.history.showMoreButton, 0.2),
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.simpleBackground,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const StyledTimelineDot = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.history.timelineDot,
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  marginTop: 6,
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
  },
  fontSize: '1em',
  marginBottom: theme.spacing(0.5),
}));
