// External Dependencies
import Box from '@mui/material/Box';
import CategoryIcon from '@mui/icons-material/Category';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { useGetDynamicFields } from 'gql/queries';

// Local Dependencies
import DynamicFormField from './DynamicFormField';
import DynamicFormFieldsFormikWrapper from './DyanmicFormFieldsFormikWrapper';
import DynamicFormFieldsZeroState from './DynamicFormFieldsZeroState';
import ShowCard, { ShowCardProps } from '../ShowCard';

// Local Typings
interface Props {
  isAdmin: boolean;
  item: DynamicFields | undefined;
  organizationTypeId?: string;
  showCardProps: Partial<ShowCardProps>;
  showSimpleeZeroState?: boolean;
  tableRef: 'inventory_items' | 'library_items' | 'members' | 'uniforms';
}

// Component Definition
const DynamicFormFields = ({
  isAdmin,
  item,
  organizationTypeId,
  showCardProps,
  showSimpleeZeroState,
  tableRef,
}: Props): JSX.Element => {
  const { data, loading } = useGetDynamicFields({
    organizationTypeId,
    tableRef,
  });

  const tooltipMessage = isAdmin
    ? 'Customize the fields you and your directors see'
    : 'Custom fields added by your district administrator';

  const content = (
    <ShowCard
      icon={CategoryIcon}
      isLoading={loading}
      subheader={showSimpleeZeroState ? undefined : tooltipMessage}
      title="Dynamic Fields"
      {...showCardProps}
    >
      {data?.dynamicFields.length ? data.dynamicFields.map((field) => (
        <DynamicFormField
          field={field}
          item={item}
          key={field.id}
          readOnly={showCardProps.readOnly}
        />
      )) : (
        <DynamicFormFieldsZeroState
          isAdmin={isAdmin}
          showSimpleeZeroState={showSimpleeZeroState}
        />
      )}
    </ShowCard>
  );

  return (
    <Box mb={2}>
      {showCardProps.readOnly ? (
        content
      ) : (
        <DynamicFormFieldsFormikWrapper
          dynamicFields={data?.dynamicFields}
          isAdmin={isAdmin}
          item={item}
          showCardProps={showCardProps}
        >
          {content}
        </DynamicFormFieldsFormikWrapper>
      )}
    </Box>
  );
};

export default DynamicFormFields;
