// External Dependencies
import {
  CardContent,
  CircularProgress,
  Grow,
  Typography,
} from '@mui/material/';
import { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

// Internal Dependencies
import {
  Alert,
  CustomFormButton,
  CustomInput,
  Flex,
  OnboardingStepTitle,
  StateSelector,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateDistrict } from 'gql/mutations';
import { yup } from 'utils/yup';

// Local Typings
interface Props {
  onSuccess?: () => void;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.formFields': {
    textAlign: 'left',
  },
  '.h6': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: `${theme.spacing(1.5)} auto`,
    },
    fontSize: '1em',
    margin: '16px auto',
    textAlign: 'center',
  },
  '.zipcode': {
    marginLeft: 16,
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

const districtInfoSchema = yup.object().shape({
  addressOne: yup.string()
    .max(50, 'Maximum length is 50 characters')
    .required('Required'),
  addressTwo: yup.string().max(50, 'Maximum length is 50 characters'),
  city: yup.string()
    .max(50, 'Maximum length is 50 characters')
    .required('Required'),
  label: yup.string().required('Required'),
  phoneNumber: yup.string()
    .required('Required'),
  stateId: yup.string().required('Required'),
  zipcode: yup.string()
    .zipcode()
    .required('Required'),
});

// Component Definition
const OnboardingDistrictInfo: FC<Props> = ({
  onSuccess,
}) => {
  const [submitError, setSubmitError] = useState('');

  const [
    createDistrict,
    { loading: isSubmittingCreateDistrict },
  ] = useCreateDistrict({
    onCompleted: onSuccess,
  });

  // TODO: Find userOrgData where current user is admin
  //  in order to pre-fill the form
  //  This would only happen if some of the values were deleted
  //  Since we are working on new users in Feb 2020, this can wait
  const districtInfoInitialValues: GQL.ICreateDistrictOnMutationArguments = {
    addressOne: '',
    addressTwo: '',
    city: '',
    label: '',
    phoneNumber: '',
    stateId: '',
    zipcode: '',
  };

  const handleSubmitForm = async (values: GQL.ICreateDistrictOnMutationArguments) => {
    try {
      await createDistrict({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SELF }],
        variables: trimValues(values) as GQL.ICreateDistrictOnMutationArguments,
      });
    } catch (err) {
      setSubmitError(
        'There was an error updating the district data. Please try again.',
      );
    }
  };

  return (
    <>
      <OnboardingStepTitle title="District Info" />

      <Formik
        initialValues={districtInfoInitialValues}
        onSubmit={handleSubmitForm}
        validationSchema={districtInfoSchema}
      >
        {(formikProps) => {
          const { handleSubmit, isSubmitting } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in>
                <StyledCardContent>
                  <Typography
                    component="h3"
                    className="h6"
                    variant="h6"
                  >
                    Help us learn more about your district.
                  </Typography>

                  <section className="formFields">
                    <Flex>
                      <CustomInput
                        label="District Name *"
                        name="label"
                      />
                      <Typography
                        className="h6"
                        component="h3"
                        variant="h6"
                      >
                        Contact information for the district
                      </Typography>
                      <CustomInput
                        label="Address 1 *"
                        name="addressOne"
                      />
                      <CustomInput
                        label="Address 2"
                        name="addressTwo"
                      />
                      <CustomInput
                        label="City *"
                        name="city"
                      />

                      <Flex
                        alignItems="flex-start"
                        flexWrap="nowrap"
                        flex="1 0 100%"
                      >
                        <StateSelector label="State *" />
                        <CustomInput
                          label="Zip Code *"
                          name="zipcode"
                          rootStyles="zipcode"
                        />
                      </Flex>

                      <CustomInput
                        label="Phone Number *"
                        name="phoneNumber"
                        type="tel"
                        variant="filled"
                      />
                    </Flex>
                  </section>

                  {Boolean(submitError) && <Alert mode="error">{submitError}</Alert>}

                  {isSubmitting || isSubmittingCreateDistrict ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText="Next"
                      className="button"
                      disabled={isSubmitting || isSubmittingCreateDistrict}
                      key="onboarding-org-info-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingDistrictInfo;
