// External Dependencies
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { SHORT_APP_NAME } from 'utils/constants';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DistrictLibrarySettingsDialog = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        District Settings
      </DialogTitle>

      <DialogContent>
        <DialogContentText gutterBottom>
          {SHORT_APP_NAME} allows your organization to view library items
          for other organizations in your district.
        </DialogContentText>

        <DialogContentText>
          Your district administrator has disabled this feature.
          Please contact your district administrator for more information.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DistrictLibrarySettingsDialog;
