// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { RevokePaymentProviderConfirmationDialog } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useSetVancoPcct } from 'utils/api/organization';

// Component Definition
const RevokeVancoButton: FC = () => {
  const dispatch = useDispatch();

  const {
    isOpen: isRevokeDialogOpen,
    toggleIsOpen: toggleIsRevokeDialogOpen,
  } = useIsOpen();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const {
    isLoading: isRevoking,
    mutate: setVancoPcct,
  } = useSetVancoPcct();

  const handleSuccess = useCallback(() => {
    toggleIsRevokeDialogOpen();
    dispatch(addNotification('Vanco/RevTrak revoked successfully', 'success'));
  }, [dispatch, toggleIsRevokeDialogOpen]);

  const handleRevokeVanco = useCallback(() => {
    setVancoPcct({ vancoAccountCode: null, vancoPcct: null }, {
      onSuccess: () => {
        handleSuccess();
      },
    });
  }, [handleSuccess, setVancoPcct]);

  return (
    <>
      <Button
        color="primary"
        onClick={toggleIsRevokeDialogOpen}
        size={isMobileScreen ? 'small' : 'medium'}
        variant="text"
      >
        {isMobileScreen ? 'Revoke' : 'Revoke Access'}
      </Button>

      <RevokePaymentProviderConfirmationDialog
        confirmButtonAction={handleRevokeVanco}
        isSubmitting={isRevoking}
        onClose={toggleIsRevokeDialogOpen}
        open={isRevokeDialogOpen}
        paymentProvider="Vanco"
      />
    </>
  );
};

export default RevokeVancoButton;
