// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { UniformShowResponse } from 'gql/queries';

// Local Typings
interface Props {
  isAdmin?: boolean;
  uniform: UniformShowResponse;
}

// Component Definition
const ShowUniformData: FC<Props> = ({
  isAdmin,
  uniform,
}) => {
  if (!uniform) {
    return null;
  }

  return (
    <>
      {isAdmin && (
        <>
          <ShowPageDataDisplay
            label="Organization Type"
            value={uniform.organization?.organizationType?.label}
          />
          <ShowPageDataDisplay
            label="Organization"
            value={uniform.organization?.label}
          />
        </>
      )}
      <ShowPageDataDisplay
        label="Category"
        value={uniform.category?.label}
      />
      <ShowPageDataDisplay
        label="Style"
        value={uniform.uniformStyle?.label}
      />
      <ShowPageDataDisplay
        label="Condition"
        value={uniform.condition?.label}
      />
      <ShowPageDataDisplay
        label="Size"
        value={uniform.size}
      />
      <ShowPageDataDisplay
        label="Uniform Number"
        value={uniform.uniformNumber}
      />
      <ShowPageDataDisplay
        label="Uniform Type"
        value={uniform.uniformType.label}
      />
      <ShowPageDataDisplay
        label="Color"
        value={uniform.color}
      />
      <ShowPageDataDisplay
        label="Purchase Amount"
        type="currency"
        value={uniform.purchaseValueInCents}
      />
      <ShowPageDataDisplay
        label="Purchase Date"
        type="date"
        value={uniform.purchasedAt}
      />
      <ShowPageDataDisplay
        label="Custom Barcode"
        value={uniform.customBarcode}
      />
      <ShowPageDataDisplay
        label="System Barcode"
        value={uniform.systemBarcode}
      />
      <ShowPageDataDisplay
        label="Comments"
        value={uniform.comments}
      />
    </>
  );
};

export default ShowUniformData;
