// External Dependencies
import { FC, useCallback } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag } from 'state/self/selectors';

// Local Dependencies
import AlertButton from './AlertButton';

// Component Definition
const ViewIntegrationsSettingsButton: FC = () => {
  const navigate = useNavigate();

  const hasMultiplePaymentProvidersFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const handlePressButton = useCallback(() => {
    if (hasMultiplePaymentProvidersFeatureFlag) {
      navigate(`/${PATHS.FINANCIAL_PAYMENTS_PROVIDERS_NEW}`);
    } else {
      navigate(`/${PATHS.SETTINGS}#integrations`);
    }
  }, [hasMultiplePaymentProvidersFeatureFlag, navigate]);

  return (
    <AlertButton
      buttonText="Manage"
      onClick={handlePressButton}
    />
  );
};

export default ViewIntegrationsSettingsButton;
