// External Dependencies
import { FormTypes } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import styled from 'styled-components';

// Internal Dependencies
import { CustomInput } from 'components/shared';
import { isDistrictAdmin } from 'state/self/selectors';
import AssignToMemberCheckboxes
  from 'pages/DistrictAdmin/AdminForms/shared/AssignToMemberCheckboxes';
import CampusTypeCheckboxes from 'pages/DistrictAdmin/AdminForms/shared/CampusTypeCheckboxes';
import GridWrapper from 'pages/Forms/components/DetailsCard/GridWrapper';
import OrganizationTypeCheckboxes
  from 'pages/DistrictAdmin/AdminForms/shared/OrganizationTypeCheckboxes';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
interface Props {
  form: GQL.IForm;
}

// Local Variables
const StyledMasonry = styled(Masonry)({
  '&.MuiMasonry-root': {
    // These adjustments allow the masonry children to take
    //  even space inside the parent container.
    margin: 0,
  },
});

// Component Definition
const FormInputs = ({
  form,
}: Props): JSX.Element => {
  const isDfa = useSelector(isDistrictAdmin);

  const isStandardForm = form.formTypeId === FormTypes.Standard;

  return (
    <>
      <GridWrapper>
        <CustomInput
          label="Title"
          name="title"
        />

        <SchoolYearSelect
          hidePreviousYears
          name="schoolYearEnding"
        />
      </GridWrapper>

      {isDfa && isStandardForm && (
        <Box width="100%">
          <StyledMasonry
            columns={{
              /* eslint-disable sort-keys */
              xs: 1,
              sm: 1,
              md: 1,
              mdLg: 2,
              lg: 3,
              /* eslint-enable sort-keys */
            }}
            spacing={{
              /* eslint-disable sort-keys */
              xs: 2,
              sm: 2,
              md: 2,
              mdLg: 2,
              lg: 2,
              /* eslint-enable sort-keys */
            }}
          >
            <CampusTypeCheckboxes />

            <OrganizationTypeCheckboxes />

            <AssignToMemberCheckboxes />
          </StyledMasonry>
        </Box>
      )}
    </>
  );
};

export default FormInputs;
