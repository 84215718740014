// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { useGetDistrictOrganizations } from 'gql/queries';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryStatusData = ({
  inventoryItemData,
}: Props): JSX.Element | null => {
  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  if (!inventoryItemData) {
    return null;
  }

  return (
    <>
      <ShowPageDataDisplay
        label="Condition"
        value={inventoryItemData.condition?.label}
      />
      <ShowPageDataDisplay
        label="Current Value"
        type="currency"
        value={inventoryItemData.currentValueInCents}
      />
      <ShowPageDataDisplay
        label="Accessories"
        value={inventoryItemData.accessories}
      />
      <ShowPageDataDisplay
        label="Quality"
        value={inventoryItemData.quality?.label}
      />
      <ShowPageDataDisplay
        label="Is Missing"
        type="boolean"
        value={inventoryItemData.isMissing}
      />
      <ShowPageDataDisplay
        label="Comments"
        value={inventoryItemData.comments}
      />
      {organizations.length > 1 && (
        <ShowPageDataDisplay
          label="On Loan To"
          value={inventoryItemData.onLoanToOrganization?.label}
        />
      )}
    </>
  );
};

export default ShowInventoryStatusData;
