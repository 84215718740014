// External Dependencies
import { FieldProps, useField } from 'formik';
import Checkbox from '@mui/material/Checkbox';

// Local Typings
interface Props {
  field: FieldProps
}

/*
 * This component should be used in a Formik context.
 *
 */

// Component Definition
const CustomCheckboxGroupCheckbox = ({ field }: Props): JSX.Element => {
  const [fieldProps] = useField((field as any).name);

  return (
    <Checkbox
      {...fieldProps}
      checked={fieldProps.value.includes((field as any).value)}
      id={(field as any).name}
      onChange={(field as any).onChange}
      value={(field as any).value}
    />
  );
};

export default CustomCheckboxGroupCheckbox;
