// External Dependencies
import { InputBaseComponentProps } from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import { CustomSelect } from 'components/shared';
import { useGetUniformStyles } from 'gql/queries/uniform-style-queries';

// Local Typings
interface Props {
  inputProps?: InputBaseComponentProps;
  orgTypeId: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const UniformStyleSelect = ({
  inputProps,
  orgTypeId,
}: Props): JSX.Element | null => {
  const {
    data,
    loading,
  } = useGetUniformStyles(orgTypeId);

  if (loading) {
    return (
      <CircularProgress
        size={24}
        sx={{ mb: 3, mt: 3 }}
        thickness={4}
      />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <CustomSelect
      inputProps={inputProps}
      label="Style"
      name="uniformStyleId"
      options={data?.uniformStyles}
      required={false}
    />
  );
};

export default UniformStyleSelect;
