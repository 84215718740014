// External Dependencies
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import EmailComposer from 'pages/Communication/EmailComposer';

// Component Definition
const EmailDraft: FC = () => {
  const { id: draftId } = useParams<{ id: string }>();

  if (!draftId) {
    return null;
  }

  return (
    <EmailComposer draftId={draftId} />
  );
};

export default EmailDraft;
