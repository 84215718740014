// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateOrganizationRoleResponse {
  createOrgnizationRole: GQL.ICreateOrganizationRoleInput;
}

const CREATE_ORGANIZATION_ROLE = gql`
  mutation CreateOrganizationRole(
    $input: CreateOrganizationRoleInput!
  ) {
    createOrganizationRole(
      input: $input
    ) {
        id
        label
        organizationId
        organizationTypeId
    }
  }
`;

export const useCreateOrganizationRole = (
  options?: MutationOptions<
    CreateOrganizationRoleResponse,
    GQL.ICreateOrganizationRoleOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateOrganizationRoleResponse,
  GQL.ICreateOrganizationRoleOnMutationArguments
>(
  CREATE_ORGANIZATION_ROLE,
  options,
);
