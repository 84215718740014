// External Dependencies
import {
  DistrictOrganizationDirectorResponseItem,
} from '@presto-assistant/api_types/api/v1/district';
import { Form, Formik } from 'formik';
import { SaveButton } from 'components/shared';
import {
  UpdateOrganizationHeadDirectorRequest,
} from '@presto-assistant/api_types/api/v1/organization';
import { getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { useGetAllDirectors } from 'utils/api/district';
import { useUpdateHeadDirector } from 'utils/api/organization';
import CustomSelect from 'components/shared/CustomSelect';

// Local Typings
interface Props {
  onClose: () => void;
  organization: GQL.IDistrictOrganizationIndexItem | null;
}

// Component Definition
const EditCreatorDialog = ({
  onClose,
  organization,
}: Props): JSX.Element => {
  const {
    data: directorsData,
    isLoading: areDirectorsLoading,
  } = useGetAllDirectors();

  const directorsForOrganization = useMemo(() => {
    return directorsData?.data?.directors?.filter((
      member: DistrictOrganizationDirectorResponseItem,
    ) => {
      return member.organizationId === organization?.id;
    }) ?? [];
  }, [directorsData, organization]);

  const currentHeadDirector = useMemo(() => {
    return directorsForOrganization.find((
      member: DistrictOrganizationDirectorResponseItem,
    ) => {
      return member.email === organization?.directorEmail;
    });
  }, [directorsForOrganization, organization]);

  const {
    isLoading: isUpdatingHeadDirector,
    mutate: updateHeadDirector,
  } = useUpdateHeadDirector();

  const handleFormikSubmit = useCallback((values) => {
    updateHeadDirector({
      body: {
        headDirectorMemberId: values.headDirectorMemberId,
      },
      params: {
        organizationId: organization?.id ?? '',
      },
    }, {
      onSuccess: onClose,
    });
  }, [onClose, organization, updateHeadDirector]);

  const directorOptions = useMemo(() => {
    return directorsForOrganization.map((
      member: DistrictOrganizationDirectorResponseItem,
    ) => ({
      id: member.memberId,
      label: getFullNameWithEmail(member),
    }));
  }, [directorsForOrganization]);

  return (
    <Dialog
      onClose={onClose}
      open={Boolean(organization)}
    >
      <DialogTitle>
        Update Creator
      </DialogTitle>

      <Formik<UpdateOrganizationHeadDirectorRequest['body']>
        initialValues={{
          headDirectorMemberId: currentHeadDirector?.memberId ?? '',
        }}
        onSubmit={handleFormikSubmit}
      >
        {({
          handleSubmit,
        }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <DialogContentText paragraph>
                Select the new creator for {organization?.label}.
              </DialogContentText>

              <CustomSelect
                isLoading={areDirectorsLoading}
                label="Director"
                name="headDirectorMemberId"
                options={directorOptions}
                required
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={onClose}
              >
                Cancel
              </Button>

              <SaveButton isSaving={isUpdatingHeadDirector}>
                Save
              </SaveButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditCreatorDialog;
