// External Dependencies
import { Avatar } from '@mui/material';
import { FC } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import FaceIcon from '@mui/icons-material/Face';
import styled, { useTheme } from 'styled-components';

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.relationships.avatar,
}));

// Local Typings
export type UserAvatarIconName = 'face' | 'building';
interface Props {
  iconName: UserAvatarIconName;
}

// Component Definition
const UserAvatar: FC<Props> = ({
  iconName,
}) => {
  const theme = useTheme();

  const icons: Record<UserAvatarIconName, React.ReactNode> = {
    building: <BusinessIcon htmlColor={theme.palette.relationships.icon} />,
    face: <FaceIcon htmlColor={theme.palette.relationships.icon} />,
  };

  return (
    <StyledAvatar variant="rounded">
      {icons[iconName]}
    </StyledAvatar>
  );
};

export default UserAvatar;
