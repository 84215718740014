// Internal Dependencies
import { AuthRouteProps } from 'components/shared/Auth';
import { PATHS } from 'utils/constants/routes';
import OnboardingAcceptTerms from 'pages/Onboarding/OnboardingAcceptTerms';
import OnboardingAcceptableUseAgreement from 'pages/Onboarding/OnboardingAcceptableUseAgreement';
import OnboardingComplete from 'pages/Onboarding/OnboardingComplete';
import OnboardingDistrictInfo from 'pages/Onboarding/OnboardingDistrictInfo';
import OnboardingEndOfYearWarning from 'pages/Onboarding/OnboardingEndOfYearWarning';
import OnboardingExtraVerificationNeeded from 'pages/Onboarding/OnboardingExtraVerificationNeeded';
import OnboardingMemberInfo from 'pages/Onboarding/OnboardingMemberInfo';
import OnboardingMoreOrganizationInfo from 'pages/Onboarding/OnboardingMoreOrganizationInfo';
import OnboardingOrganizationInfo from 'pages/Onboarding/OnboardingOrganizationInfo';
import OnboardingUserContactInfo from 'pages/Onboarding/OnboardingUserContactInfo';
import OnboardingUserProfileInfo from 'pages/Onboarding/OnboardingUserProfileInfo';
import OnboardingVerifyEmail from 'pages/Onboarding/OnboardingVerifyEmail';

export const useGetOnboardingRoutes = (): AuthRouteProps[] => [
  {
    component: OnboardingAcceptTerms,
    path: `${PATHS.ONBOARDING_ACCEPT_TERMS}`,
  },
  {
    component: OnboardingAcceptableUseAgreement,
    path: `${PATHS.ONBOARDING_ACCEPTABLE_USE_AGREEMENT}`,
  },
  {
    component: OnboardingMemberInfo,
    path: `${PATHS.ONBOARDING_MEMBER_INFO}`,
  },
  {
    component: OnboardingMoreOrganizationInfo,
    path: PATHS.ONBOARDING_MORE_ORGANIZATION_INFO,
  },
  {
    component: OnboardingUserContactInfo,
    path: `${PATHS.ONBOARDING_USER_CONTACT_INFO}`,
  },
  {
    component: OnboardingUserProfileInfo,
    path: `${PATHS.ONBOARDING_USER_PROFILE_INFO}`,
  },
  {
    component: OnboardingOrganizationInfo,
    path: `${PATHS.ONBOARDING_ORGANIZATION_INFO}`,
  },
  {
    component: OnboardingDistrictInfo,
    path: `${PATHS.ONBOARDING_DISTRICT_INFO}`,
  },
  {
    component: OnboardingEndOfYearWarning,
    path: `${PATHS.ONBOARDING_EOY_WARNING}`,
  },
  {
    component: OnboardingExtraVerificationNeeded,
    path: `${PATHS.ONBOARDING_EXTRA_VERIFICATION_NEEDED}`,
  },
  {
    component: OnboardingVerifyEmail,
    path: `${PATHS.ONBOARDING_VERIFY_EMAIL}`,
  },
  {
    component: OnboardingComplete,
    path: `${PATHS.ONBOARDING_COMPLETE}`,
  },
];
