// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME, CONTACT_INFO } from 'utils/constants/app';
import {
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';

// Local Dependencies
import FeedbackAlert from './FeedbackAlert';

// Local Variables
const subject = encodeURIComponent(`${APP_NAME} User Feedback`);

const mailtoLink = `${CONTACT_INFO.MAILTO_SUPPORT}?subject=${subject}`;

// Component Definition
const AskPresto = (): JSX.Element => {
  return (
    <EnhancedCard>
      <EnhancedCardContent>
        <Typography variant="h4">
          Ask Team Presto
        </Typography>

        <Box
          marginBottom={2}
          marginTop={1}
        >
          <FeedbackAlert />
        </Box>

        <Typography
          paragraph
          variant="body1"
        >
          We&apos;re updating our feedback system right now with some minor improvements.
        </Typography>

        <Typography
          paragraph
          variant="body1"
        >
          In the meantime, press the button below to email our Support team,
          or email us at <strong>{CONTACT_INFO.SUPPORT}</strong>.
        </Typography>

        <Button
          href={mailtoLink}
          size="large"
          startIcon={<SendIcon fontSize="small" />}
          sx={{ marginTop: 1 }}
          variant="contained"
        >
          Email Support Team
        </Button>
      </EnhancedCardContent>
    </EnhancedCard>
  );
};

export default AskPresto;
