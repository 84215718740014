// External Dependencies
import { FC } from 'react';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { ToolbarProps } from 'components/shared/DataTable/Toolbar';
import {
  useDownloadDistrictDeletedInventoryIndexItems,
  useGetDistrictDeletedInventoryIndexItems,
} from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import TableV2 from 'components/shared/TableV2';

// Local Dependencies
import { useColumns } from './hooks';
import Filters from './Filters';

// Component Definition
const AdminDeletedInventoryTable: FC = () => {
  const parsedSearch = useParsedSearch();

  const {
    data,
    loading,
  } = useGetDistrictDeletedInventoryIndexItems();

  const [
    handleExportDeletedInventory,
    { loading: isExporting },
  ] = useDownloadDistrictDeletedInventoryIndexItems();

  const hasAtLeastOneDeletedInventoryItem = (
    data?.districtDeletedInventoryItemsIndex.fullCount ?? 0
  ) > 0;

  const columns = useColumns(parsedSearch.organizationTypeId);

  const moreActions: ToolbarProps['moreActions'] = [
    {
      action: handleExportDeletedInventory,
      icon: <DownloadIcon />,
      isDisabled: isExporting || (data?.districtDeletedInventoryItemsIndex.fullCount ?? 0) < 1,
      text: 'Export deleted inventory',
    },
  ];

  return (
    <TableV2<GQL.IDistrictDeletedInventoryIndexItem>
      actions={moreActions}
      Filters={<Filters />}
      clickRowTo={(row) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_DELETED}/${row.id}`}
      columns={columns}
      data={data?.districtDeletedInventoryItemsIndex.data ?? []}
      disableSearch={!parsedSearch.q && !hasAtLeastOneDeletedInventoryItem}
      fullCount={data?.districtDeletedInventoryItemsIndex.fullCount}
      isLoading={loading}
      reduxStateKey="districtDeletedInventoryItems"
      withSearch
      zeroStateMessage="Any inventory item deleted in your district will appear here."
    />
  );
};

export default AdminDeletedInventoryTable;
