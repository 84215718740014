// External Dependencies
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { ALERT_IDS } from 'utils/constants/bannerAlerts';
import { APP_NAME, SHORT_APP_NAME } from 'utils/constants';
import {
  ConfirmationDialog,
  DialogIntegration,
  EnhancedCard,
  EnhancedContainer,
  OnlinePaymentsLearnMoreLink,
  Page,
  RevokePaymentProviderConfirmationDialog,
  ShowCardHeader,
  StripeLogoSvg,
  StyledLink,
} from 'components/shared';
import { GET_SELF, useGetOrganization } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { parseSearch } from 'utils';
import { removeBannerAlert } from 'state/bannerAlerts/actions';
import { stripeUrl } from 'utils/constants/urls';
import { useIsOpen } from 'hooks/useIsOpen';
import { useSendToStripe } from 'hooks/useSendToStripe';
import { useUpdateStripeId } from 'gql/mutations';
import SubscriberOnlyButton from 'components/shared/SubscriberOnlyButton';

// Local Variables
const StyledEnhancedContainer = styled(EnhancedContainer)(({ theme }) => ({
  '.MuiListItemText-secondary': {
    [theme.breakpoints.down('md')]: {
      maxWidth: '60%',
    },
    maxWidth: '90%',
  },

  '.actionsSubtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },

  '.avatar': {
    backgroundColor: theme.palette.showPage.people,
  },

  '.trademark': {
    left: -12,
    position: 'relative',
    top: 24,
  },
}));

const title = 'Set Up Online Payments';
const noop = () => null;

// Component Definition
const StripeVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isStripeDialogOpen, setIsStripeDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isStripeCodeStored, setIsStripeCodeStored] = useState(false);
  const [stripeCodeFromUrl, setStripeCodeFromUrl] = useState('');

  const isMobileScreen = useSelector(isMobileScreenSize);
  const canWriteFinancialAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  const {
    isOpen: isRevokeDialogOpen,
    toggleIsOpen: toggleIsRevokeDialogOpen,
  } = useIsOpen();

  const {
    data: orgData,
  } = useGetOrganization();

  const hasStripeId = orgData?.organization.hasStripeId;
  const vancoPcct = orgData?.organization.vancoPcct;

  const {
    handleClickStripeButton,
  } = useSendToStripe();

  const [
    revokeStripeId,
    {
      loading: isRevoking,
    },
  ] = useUpdateStripeId({
    awaitRefetchQueries: true,
    clearCachePredicates: ['organization'],
    onCompleted: toggleIsRevokeDialogOpen,
  });

  const handleRevokeStripe = useCallback(() => {
    revokeStripeId({
      variables: { input: { stripeCode: null } },
    });
  }, [revokeStripeId]);

  useEffect(() => {
    setIsStripeDialogOpen(true);

    // Get the code from the url param
    const { search } = window.location;
    const parsedSearch = parseSearch(search);
    const { code } = parsedSearch;
    setStripeCodeFromUrl(code);
  }, []);

  const showError = () => {
    setErrorMessage(
      'Something went wrong while syncing with Stripe. Please try again.',
    );
  };

  const showSuccess = () => {
    setIsStripeCodeStored(true);
  };

  const handleCloseDialog = useCallback(() => {
    setIsStripeDialogOpen(false);
    // We navigate to a clean url to avoid any retries to sync with Stripe
    navigate(`/${PATHS.STRIPE_VERIFICATION}`, { replace: true });
  }, [navigate]);

  const handleNavigateToSettings = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}`);
  }, [navigate]);

  const [
    updateStripeId,
  ] = useUpdateStripeId({
    awaitRefetchQueries: true,
    clearCachePredicates: ['organization'],
    onCompleted: showSuccess,
    onError: showError,
    refetchQueries: [{ query: GET_SELF }],
  });

  useEffect(() => {
    const saveCode = async (stripeCode: string) => {
      try {
        await updateStripeId({ variables: { input: { stripeCode } } });
        dispatch(removeBannerAlert(ALERT_IDS.NO_STRIPE_ALERT));
      } catch (error) {
        console.log(
          'There was an error adding the Stripe verification data.',
        );
        console.error({ error });
      }
    };

    if (stripeCodeFromUrl) {
      saveCode(stripeCodeFromUrl);
    }
  }, [stripeCodeFromUrl, dispatch, updateStripeId]);

  const successDescription = (
    <>
      <Typography gutterBottom>
        Your organization is now synced with Stripe.
      </Typography>

      <Typography>
        This will allow parents in your organization to securely pay fees
        for their students in the {APP_NAME} application.
      </Typography>
    </>
  );

  return (
    <>
      <Page>
        <StyledEnhancedContainer>
          <Box mb={2}>
            <EnhancedCard>
              <ShowCardHeader
                avatar={(
                  <Avatar className="avatar">
                    <MonetizationOnIcon aria-label={title} />
                  </Avatar>
                )}
                title={title}
              />

              <CardContent>
                <Box
                  alignItems="flex-start"
                  component="section"
                  display="flex"
                  justifyContent="center"
                  marginTop={2}
                >
                  <a
                    href={stripeUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <StripeLogoSvg height="96px" />
                  </a>
                  <span className="trademark">
                    &trade;
                  </span>
                </Box>
              </CardContent>

              <CardContent>
                <Typography paragraph>
                  {APP_NAME} offers an integration with{' '}
                  <StyledLink
                    href={stripeUrl}
                    openInNewTab
                  >
                    Stripe
                  </StyledLink>{' '}
                  to securely handle online payments, all without
                  leaving {SHORT_APP_NAME}.
                </Typography>

                <Typography paragraph>
                  Your organization can use ONE Stripe account to allow
                  parents to make payments online for their students.
                </Typography>

                <Typography paragraph>
                  To get started, follow the steps in this
                  support article. <OnlinePaymentsLearnMoreLink />
                </Typography>
              </CardContent>

              <Divider />

              <CardContent>
                <Typography
                  className="actionsSubtitle"
                  component="h6"
                >
                  Stripe Actions
                </Typography>

                <List>
                  <ListItem>
                    <ListItemText
                      primary={`${hasStripeId ? 'Stripe is enabled' : 'Enable Stripe'} for online payments`}
                      secondary={!canWriteFinancialAccounts
                        ? `A director with permission to add new Financial Accounts can
                        ${hasStripeId ? 'revoke' : 'enable'} Stripe.`
                        : null}
                    />

                    <ListItemSecondaryAction>
                      <SubscriberOnlyButton
                        color="primary"
                        disabled={!canWriteFinancialAccounts}
                        onClick={hasStripeId
                          ? toggleIsRevokeDialogOpen
                          : handleClickStripeButton}
                        size={isMobileScreen ? 'small' : 'medium'}
                        variant={hasStripeId ? 'text' : 'outlined'}
                      >
                        {hasStripeId ? 'Revoke' : 'Sync'}
                      </SubscriberOnlyButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </CardContent>
            </EnhancedCard>
          </Box>
        </StyledEnhancedContainer>
      </Page>

      {stripeCodeFromUrl && (
        <DialogIntegration
          context="Stripe"
          errorMessage={errorMessage}
          isCodeStored={isStripeCodeStored}
          isDialogOpen={isStripeDialogOpen}
          onCloseDialog={handleCloseDialog}
          successDescription={successDescription}
        />
      )}

      <RevokePaymentProviderConfirmationDialog
        confirmButtonAction={handleRevokeStripe}
        isSubmitting={isRevoking}
        onClose={toggleIsRevokeDialogOpen}
        open={isRevokeDialogOpen}
        paymentProvider="Stripe"
      />

      <ConfirmationDialog
        confirmButtonAction={handleNavigateToSettings}
        description="Your organization is linked with a Vanco account. You must first unlink your Vanco account before syncing Stripe."
        handleClose={noop}
        hideDeclineButton
        open={Boolean(vancoPcct)}
        title="Cannot sync Stripe"
      />
    </>
  );
};

export default StripeVerification;
