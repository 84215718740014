// External Dependencies
import { Typography } from '@mui/material/';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const HowWeUseIt = () => (
  <>
    <InfoTitle>
      How do we use this information?
    </InfoTitle>

    <InfoSection>
      <Typography>
        {/* eslint-disable-next-line max-len */}
        This information is used for {APP_NAME} to work as intended. It will be visible to your students&apos; fine arts teachers, district administrators, and any parents in the organization with permission to view student details (such as parent volunteers). This data allows a teacher to have quick access to contact information.
      </Typography>
    </InfoSection>
  </>
);

export default HowWeUseIt;
