// External Dependencies
import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props extends SvgIconProps {
  htmlColor?: string;
  rootClasses?: string;
}

// Local Variables
const StyledSvgIcon = styled(SvgIcon)({
  height: '100%',
});

// Component Definition
const PrestoLogoOnlySvg: FC<Props> = ({
  rootClasses,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      className={clsx(rootClasses)}
      htmlColor={theme.palette.logo}
      viewBox="0 0 208.74 156.99"
      {...props}
    >
      <g
        id="Layer_2"
        data-name="Layer 2"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1"
        >
          <circle
            className="cls-1"
            cx="129.5"
            cy="78.16"
            r="23"
          />
          <path
            className="cls-1"
            d="M166.34,8.94c-14.1-7-26-10.47-48.71-8.29A65.5,65.5,0,0,0,93.29,8.41c-9.16,5.23-9.16,5.23-19.08,14.06L5.7,91A19.46,19.46,0,0,0,33.22,118.5h0L101.73,50A39.89,39.89,0,1,1,145,115.16a39.85,39.85,0,0,1-43.22-8.76l-7.85-7.86c-7.46-7.46-19.62-8-27.34-.82a19.47,19.47,0,0,0-.51,28l8.18,8.18A78.81,78.81,0,0,0,207.3,63.16a70,70,0,0,0-3.25-10.26c-2.8-7.38-2.92-8.51-7.16-15.17-12.08-19-20.66-22.7-30.55-28.79"
          />
        </g>
      </g>
    </StyledSvgIcon>
  );
};
export default PrestoLogoOnlySvg;
