// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { setLocalStorageItem } from 'utils/lib/local_storage';

// Actions
export const changeInventoryTableQueryParams = createActionCreator(
  'CHANGE_INVENTORY_TABLE_QUERY_PARAMS',
  (resolve) => (queryParams) => {
    const { pagesize } = queryParams;

    // Local Storage always expects a value as string
    setLocalStorageItem('inventoryItemsPageSize', pagesize);

    return resolve(queryParams);
  },
);
