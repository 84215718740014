// External Dependencies
import { Form, Formik } from 'formik';
import { createDynamicFieldSchema, updateDynamicFieldSchema } from '@presto-assistant/api_types/schemas/dynamicField';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import Container from '@mui/material/Container';

// Internal Dependencies
import {
  CustomInput, CustomSelect, FormActions, ShowCard, ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { parseSearch } from 'utils';
import { useCreateDynamicField, useUpdateDynamicField } from 'gql/mutations';
import { useGetDynamicFieldTypes, useGetOrganizationTypes } from 'gql/queries';

// Local Dependencies
import { tableRefOptions } from './helpers';

// Local Typings
interface Props {
  dynamicField?: GQL.IDynamicField;
}

// Component Definition
const DynamicFieldForm = ({ dynamicField }: Props): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const parsedSearch = parseSearch(search);

  const { data: orgTypeData, loading: isLoadingOrgTypes } = useGetOrganizationTypes();

  const { data: fieldTypesData, loading: isLoadingFieldTypes } = useGetDynamicFieldTypes();

  const handleNavigateToIndex = useCallback((queryString = search) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${queryString}`);
  }, [search, navigate]);

  const [
    createDynamicField,
    {
      loading: isCreating,
    },
  ] = useCreateDynamicField(
    {
      clearCachePredicates: ['dynamicFields'],
      onCompleted: (data) => {
        const field = data.createDynamicField;

        handleNavigateToIndex(`?table_ref=${field.tableRef}&organization_type_id=${field.organizationType.id}`);
      },
    },
  );

  const [
    updateDynamicField,
    {
      loading: isUpdating,
    },
  ] = useUpdateDynamicField(
    {
      clearCachePredicates: ['dynamicFields'],
      onCompleted: (data) => {
        const field = data.updateDynamicField.dynamicField;

        if (field) {
          handleNavigateToIndex(`?table_ref=${field.tableRef}&organization_type_id=${field.organizationType.id}`);
        }
      },
    },
  );

  const handleSubmitForm = useCallback((values: GQL.ICreateDynamicFieldInput) => {
    const trimmedLabel = values.label.trim();

    if (dynamicField) {
      updateDynamicField({
        variables: {
          id: dynamicField.id,
          input: {
            label: trimmedLabel,
            typeId: values.typeId,
          },
        },
      });
    } else {
      createDynamicField({
        variables: {
          input: {
            ...values,
            label: trimmedLabel,
          },
        },
      });
    }
  }, [createDynamicField, dynamicField, updateDynamicField]);

  const organizationTypeId = dynamicField?.organizationType ?? parsedSearch.organizationTypeId;

  const initialTableRefId = dynamicField?.tableRef
    ?? parsedSearch.tableRef
    ?? tableRefOptions[0].id;
  const initialTypeId = dynamicField?.type.id ?? '1';

  const isEditing = !!dynamicField;

  const selectedFieldTypeLabel = useMemo(
    () => fieldTypesData?.dynamicFieldTypes
      .find((type) => type.id.toString() === initialTypeId)?.label ?? '',
    [fieldTypesData, initialTypeId],
  );

  return (
    <Formik<GQL.ICreateDynamicFieldInput>
      initialValues={{
        label: dynamicField?.label ?? '',
        organizationTypeId: organizationTypeId ? `${organizationTypeId}` : '1',
        tableRef: initialTableRefId,
        typeId: initialTypeId,
      }}
      onSubmit={handleSubmitForm}
      validationSchema={isEditing ? updateDynamicFieldSchema : createDynamicFieldSchema}
    >
      {({
        handleSubmit,
        touched,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Container
              maxWidth="sm"
              sx={{ marginBottom: 2 }}
            >
              <ShowCard
                icon={CategoryIcon}
                title="Dynamic Field"
              >
                <CustomInput
                  label="Name *"
                  name="label"
                />

                {dynamicField ? (
                  <ShowPageDataDisplay
                    label="Type"
                    value={selectedFieldTypeLabel}
                  />
                ) : (
                  <CustomSelect
                    inputProps={{ readOnly: isEditing && !!fieldTypesData?.dynamicFieldTypes }}
                    isLoading={isLoadingFieldTypes}
                    label="Dynamic Field Type *"
                    name="typeId"
                    options={fieldTypesData?.dynamicFieldTypes ?? []}
                    required
                  />
                )}

                {dynamicField ? (
                  <ShowPageDataDisplay
                    label="Organization Type"
                    value={dynamicField.organizationType.label}
                  />
                ) : (
                  <CustomSelect
                    inputProps={{ readOnly: isEditing && !!orgTypeData?.organizationTypes }}
                    isLoading={isLoadingOrgTypes}
                    label="Organization Type *"
                    name="organizationTypeId"
                    options={orgTypeData?.organizationTypes ?? []}
                    required
                  />
                )}

                {dynamicField ? (
                  <ShowPageDataDisplay
                    label="Table"
                    value={tableRefOptions.find((opt) => opt.id === initialTableRefId)?.label}
                  />
                ) : (
                  <CustomSelect
                    inputProps={{ readOnly: isEditing }}
                    label="Table *"
                    name="tableRef"
                    options={tableRefOptions}
                    required
                  />
                )}
              </ShowCard>
            </Container>

            <FormActions
              context="Field"
              isEditing={isEditing}
              isFormTouched={isFormTouched}
              isSubmitting={isCreating || isUpdating}
              onPressCancelOrBackButton={handleNavigateToIndex}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DynamicFieldForm;
