// External Dependencies
import {
  FC, Fragment, useCallback, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import KeyIcon from 'mdi-material-ui/Key';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableColumn, displayCell } from 'components/shared/TableV2';
import { TableV2 } from 'components/shared';
import { createActionsColumn } from 'components/shared/TableV2/helpers';
import {
  getDistrictAdminOrganizationIdFromPathname,
} from 'utils';
import { useGetDistrictParentsWithPermissionsIndex } from 'gql/queries';

// Local Variables
export const HEADER_CELL_ELEMENTS = [
  {
    cell: 'firstName',
    id: 'firstName',
    label: 'First Name',
  },
  {
    cell: 'lastName',
    id: 'lastName',
    label: 'Last Name',
  },
  {
    cell: 'email',
    id: 'email',
    label: 'Email',
  },
];

// Component Definition
const AdultsWithPermissionsTableUI: FC = () => {
  const navigate = useNavigate();
  const organizationId = useMemo(getDistrictAdminOrganizationIdFromPathname, []);

  const {
    data,
    loading,
  } = useGetDistrictParentsWithPermissionsIndex(organizationId);

  const permissionsAccessor = ({
    email,
    permissions,
  }: GQL.IDistrictParentsWithPermissionsIndex) => (
    <p>
      {permissions.split(',\n').map((permission, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${email}-permission-row-${index}`}>
          {permission}
          <br />
        </Fragment>
      ))}
    </p>
  );

  const columns: TableColumn<GQL.IDistrictParentsWithPermissionsIndex>[] = [
    {
      Header: 'First Name',
      accessor: (row) => displayCell(row.firstName),
      sortBy: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: (row) => displayCell(row.lastName),
      sortBy: 'lastName',
    },
    {
      Header: 'Email',
      accessor: (row) => displayCell(row.email),
      sortBy: 'email',
    },
    {
      Header: 'Permissions',
      accessor: (row) => permissionsAccessor(row),
    },
  ];

  const handleClickEditPermissions = useCallback((
    parent: GQL.IDistrictParentsWithPermissionsIndex,
  ) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${organizationId}/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}/${parent.id}/edit/${PATHS.PERMISSIONS}`);
  }, [navigate, organizationId]);

  const actionsColumn = createActionsColumn<GQL.IDistrictParentsWithPermissionsIndex>([
    {
      action: handleClickEditPermissions,
      icon: <KeyIcon />,
      text: 'Edit Permissions',
    },
  ]);

  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return (
    <TableV2<GQL.IDistrictParentsWithPermissionsIndex>
      columns={columns}
      data={data?.districtParentsWithPermissionsIndex.data ?? []}
      fullCount={data?.districtParentsWithPermissionsIndex.fullCount}
      isLoading={loading}
      reduxStateKey="districtOrganizationsParentsWithPermissions"
      withSearch
      zeroStateMessage="Adults with expanded permissions will appear in this table."
    />
  );
};

export default AdultsWithPermissionsTableUI;
