// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LicenseIcon from 'mdi-material-ui/CardAccountDetailsOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  SupportLink,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  hasLicense?: boolean;
  isDistrictAdmin?: boolean;
}

// Component Definition
const PurchaseALicenseCard = ({
  hasLicense = false,
  isDistrictAdmin = false,
}: Props): JSX.Element => {
  const {
    palette: {
      prestoPrimaryLight,
      stripeBlue,
    },
  } = useTheme();
  const navigate = useNavigate();

  const licenseParams = useSelector(tableQueryParams('licenses'));

  const handleNavigateToLicensesTable = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.LICENSES}${licenseParams}`);
  }, [licenseParams, navigate]);

  const iconElement = (
    <ZeroStateIcon
      Icon={hasLicense ? LicenseIcon : ShoppingCartIcon}
      htmlColor={isDistrictAdmin ? stripeBlue['500'] : prestoPrimaryLight}
    />
  );

  const content = (
    <>
      {iconElement}

      <Box
        px={2}
        textAlign="center"
      >
        {!hasLicense ? (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Learn how to purchase {!isDistrictAdmin ? 'a ' : ''} {APP_NAME} license{isDistrictAdmin ? 's' : ''} for your{' '}
            {isDistrictAdmin ? 'district' : 'organization'}.
          </Typography>
        ) : (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Manage licenses for the organizations in your district.
          </Typography>
        )}
      </Box>

      {!hasLicense ? (
        <SupportLink
          button
          slug="purchase-a-presto-license"
        >
          Learn More
        </SupportLink>
      ) : (
        <Button
          onClick={handleNavigateToLicensesTable}
          size="small"
          variant="outlined"
        >
          View Licenses
        </Button>
      )}
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title={`${hasLicense ? 'Manage Licenses' : 'Purchase a License'}`}
      verticallyCenterContent
    />
  );
};

export default PurchaseALicenseCard;
