// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME, PRIVACY_POLICY_HREF } from 'utils/constants';
import { StyledLink } from 'components/shared';
import DataInfo from 'pages/Onboarding/OnboardingAcceptableUseAgreement/DataInfo';
import InfoSection from 'pages/Onboarding/OnboardingAcceptableUseAgreement/InfoSection';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const ParentalConsentInfoDialog = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        {APP_NAME} Data Policy
      </DialogTitle>
      <DialogContent>
        <DataInfo />

        <Box marginBottom={2}>
          <Divider />
        </Box>

        <InfoSection>
          <Typography>
            More information can be found in our{' '}
            <StyledLink
              href={PRIVACY_POLICY_HREF}
              openInNewTab
            >
              Privacy Policy
            </StyledLink>
          </Typography>
        </InfoSection>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParentalConsentInfoDialog;
