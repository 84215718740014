// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  addOneRecipient,
  clearRecipients,
  removeRecipient,
  updateRecipients,
} from './actions';

// Local Typings
interface ISingleRecipientAction {
  payload: string;
  type: string;
}

interface IMultipleRecipientsAction {
  payload: string[];
  type: string;
}

export interface EmailNewState {
  recipientIds: string[];
}

// Reducers
const RECIPIENT_IDS_INITIAL_STATE: EmailNewState['recipientIds'] = [];
const recipientIds = createReducer(RECIPIENT_IDS_INITIAL_STATE, (handleAction) => [
  handleAction(
    addOneRecipient,
    (state, { payload }: ISingleRecipientAction) => [...state, payload],
  ),
  handleAction(
    updateRecipients,
    (state, { payload }: IMultipleRecipientsAction) => [...payload],
  ),
  handleAction(removeRecipient, (state, { payload }: ISingleRecipientAction) => {
    if (state.length > 0) {
      return state.filter((id) => payload !== id);
    }
    return state;
  }),
  handleAction([
    clearRecipients,
    logoutCurrentUser,
  ], () => RECIPIENT_IDS_INITIAL_STATE),
]);

export default combineReducers<EmailNewState>({
  recipientIds,
});
