// External Dependencies
import Button, { ButtonProps } from '@mui/material/Button';
import cx from 'clsx';
import styled from 'styled-components';

// Local Typings
interface Props extends ButtonProps {
  useMaterialV3?: boolean;
}

// Local Variables
const StyledButton = styled(Button)({
  '&.material-v3': {
    borderRadius: 20,
  },
});

// Component Definition
const EnhancedButton = ({
  children,
  color = 'primary',
  useMaterialV3,
  variant = 'contained',
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StyledButton
      className={cx(useMaterialV3 && 'material-v3')}
      color={color}
      variant={variant}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

export default EnhancedButton;
