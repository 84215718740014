// External Dependencies
import { combineReducers } from 'redux';

// Internal Dependencies
import directorFileUploadDialog, { DirectorFileUploadDialogState } from './directorFileUploadDialog/reducers';
import editFinancialPaymentItemForm, { EditFinancialPaymentItemFormState } from './editFinancialPaymentItemForm/reducers';
import editInventoryItemForm, { EditInventoryItemFormState } from './editInventoryItemForm/reducers';
import editOrganizationDialog, { EditOrganizationDialogState } from './editOrganizationDialog/reducers';
import emailNew, { EmailNewState } from './emailNew/reducers';
import financialPaymentFileUploadDialog, { FinancialPaymentFileUploadDialogState } from './financialPaymentFileUploadDialog/reducers';
import inventoryFileUploadDialog, { InventoryFileUploadDialogState } from './inventoryFileUploadDialog/reducers';
import inventoryMissingSerialNumberDialog, { InventoryMissingSerialNumberDialogState } from './inventoryMissingSerialNumberDialog/reducers';
import libraryFileUploadDialog, { LibraryFileUploadDialogState } from './libraryFileUploadDialog/reducers';
import organizationLogoUploadDialog, { OrganizationLogoUploadDialogState } from './organizationLogoUploadDialog/reducers';
import parentFileUploadDialog, { ParentFileUploadDialogState } from './parentFileUploadDialog/reducers';
import paymentAlertDialog, { PaymentAlertDialogState } from './paymentAlertDialog/reducers';
import paymentPickerDepositForm, { PaymentPickerDepositFormState } from './paymentPickerDepositForm/reducers';
import peoplePickerDialog, { PeoplePickerDialogState } from './peoplePickerDialog/reducers';
import quickSettingsMenu, { QuickSettingsMenuState } from './quickSettingsMenu/reducers';
import studentDialog, { StudentDialogState } from './studentDialog/reducers';
import studentFileUploadDialog, { StudentFileUploadDialogState } from './studentFileUploadDialog/reducers';
import successDialog, { SuccessDialogState } from './successDialog/reducers';
import uncategorizedFinancialFeeFileUploadDialog, { UncategorizedFinancialFeeFileUploadDialogState } from './uncategorizedFinancialFeeUploadDialog/reducers';
import uniformFileUploadDialog, { UniformFileUploadDialogState } from './uniformFileUploadDialog/reducers';
import updatePasswordDialog, { UpdatePasswordDialogState } from './updatePasswordDialog/reducers';

// Local Variables
export interface UIState {
  directorFileUploadDialog: DirectorFileUploadDialogState;
  editFinancialPaymentItemForm: EditFinancialPaymentItemFormState;
  editInventoryItemForm: EditInventoryItemFormState;
  editOrganizationDialog: EditOrganizationDialogState;
  emailNew: EmailNewState;
  financialPaymentFileUploadDialog: FinancialPaymentFileUploadDialogState;
  inventoryFileUploadDialog: InventoryFileUploadDialogState;
  inventoryMissingSerialNumberDialog: InventoryMissingSerialNumberDialogState;
  libraryFileUploadDialog: LibraryFileUploadDialogState;
  organizationLogoUploadDialog: OrganizationLogoUploadDialogState;
  parentFileUploadDialog: ParentFileUploadDialogState;
  paymentAlertDialog: PaymentAlertDialogState;
  paymentPickerDepositForm: PaymentPickerDepositFormState;
  peoplePickerDialog: PeoplePickerDialogState;
  quickSettingsMenu: QuickSettingsMenuState;
  studentDialog: StudentDialogState;
  studentFileUploadDialog: StudentFileUploadDialogState;
  successDialog: SuccessDialogState;
  uncategorizedFinancialFeeFileUploadDialog: UncategorizedFinancialFeeFileUploadDialogState;
  uniformFileUploadDialog: UniformFileUploadDialogState;
  updatePasswordDialog: UpdatePasswordDialogState;
}

export default combineReducers<UIState>({
  directorFileUploadDialog,
  editFinancialPaymentItemForm,
  editInventoryItemForm,
  editOrganizationDialog,
  emailNew,
  financialPaymentFileUploadDialog,
  inventoryFileUploadDialog,
  inventoryMissingSerialNumberDialog,
  libraryFileUploadDialog,
  organizationLogoUploadDialog,
  parentFileUploadDialog,
  paymentAlertDialog,
  paymentPickerDepositForm,
  peoplePickerDialog,
  quickSettingsMenu,
  studentDialog,
  studentFileUploadDialog,
  successDialog,
  uncategorizedFinancialFeeFileUploadDialog,
  uniformFileUploadDialog,
  updatePasswordDialog,
});
