// External Dependencies
import { AxiosError } from 'axios';
import { FileWithPath } from 'react-dropzone';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  ListItemWithSecondaryAction,
  SaveButton,
  ShowPageDataDisplay,
} from 'components/shared';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { convertAxiosErrorToYupError } from 'utils/lib/convertAxiosErrorToYupError';
import { isMobileOrTabletScreenSize as selectIsMobileOrTabletScreenSize } from 'state/device/selectors';
import {
  useCreateTaxExemptFormPresignedUrl,
  useDownloadTaxExemptForm,
  useUpdateTaxExemptFormS3Key,
} from 'utils/api/district';
import { useGetDistrict } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import UploadDropzone from 'components/shared/UploadDropzone';

// Local Dependencies
import ConfirmUpdateDistrictTaxExemptFormDialog from './ConfirmUpdateDistrictTaxExemptFormDialog';

// Local Variables
const acceptedFileTypes = [
  UPLOAD_FILE_TYPES.pdf,
  UPLOAD_FILE_TYPES.msword,
  UPLOAD_FILE_TYPES.msoffice,
  UPLOAD_FILE_TYPES.allImages,
  UPLOAD_FILE_TYPES.text,
];

// Component Definition
const TaxExemptForm = (): JSX.Element => {
  const navigate = useNavigate();

  const [taxExemptFormFile, setTaxExemptFormFile] = useState<FileWithPath | null>(null);
  const [taxExemptS3GetUrl, setTaxExemptS3GetUrl] = useState<string | null>(null);
  const [taxExemptS3PutUrl, setTaxExemptS3PutUrl] = useState<string | null>(null);
  const [isUploadingToS3, setIsUploadingToS3] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showAddFileMessage, setShowAddFileMessage] = useState(false);
  const [hasConfirmedUpdate, setHasConfirmedUpdate] = useState(false);

  const isMobileOrTabletScreenSize = useSelector(selectIsMobileOrTabletScreenSize);

  const {
    handleClose: handleCloseConfirmUpdateDialog,
    handleOpen: handleOpenConfirmUpdateDialog,
    isOpen: isConfirmUpdateDialogOpen,
  } = useIsOpen();

  const { data, loading } = useGetDistrict();

  const hasTaxExemptForm = useMemo(() => {
    if (!data) {
      return false;
    }

    return Boolean(data.district.taxExemptFormS3Key);
  }, [data]);

  const districtId = data?.district.id;

  const {
    data: presignedUrlData,
    error: presignedUrlError,
    isLoading: isGeneratingPresignedUrl,
    mutate: createTaxExemptFormPresignedUrl,
  } = useCreateTaxExemptFormPresignedUrl();

  const {
    error: updateTaxExemptFormS3KeyError,
    isLoading: isUpdatingTaxExemptFormS3Key,
    mutate: updateTaxExemptFormS3Key,
  } = useUpdateTaxExemptFormS3Key();

  const {
    mutate: downloadTaxExemptForm,
  } = useDownloadTaxExemptForm();

  // When the presignedUrlData changes, set the taxExemptS3Url
  useEffect(() => {
    if (presignedUrlData) {
      setTaxExemptS3GetUrl(presignedUrlData.data?.presignedUrl?.getUrl);
      setTaxExemptS3PutUrl(presignedUrlData.data?.presignedUrl?.putUrl);
    }
  }, [presignedUrlData]);

  const handleDownloadTaxExemptForm = useCallback(() => {
    return downloadTaxExemptForm(null);
  }, [downloadTaxExemptForm]);

  const handleConfirmUpdate = useCallback(() => {
    setHasConfirmedUpdate(true);
    handleCloseConfirmUpdateDialog();
  }, [handleCloseConfirmUpdateDialog]);

  // The user presses the CTA button that reads "Upload Tax-Exempt Form".
  // This will send the file to S3 and update the Presto database with the S3 key.
  const handleUploadToS3 = useCallback(async () => {
    if (!taxExemptS3PutUrl) {
      return setHasError(true);
    }

    setIsUploadingToS3(true);

    try {
      const res = await fetch(taxExemptS3PutUrl, {
        body: taxExemptFormFile,
        method: 'PUT',
      });

      if (!res.ok) {
        throw new Error('Unable to upload tax-exempt form file.');
      }

      if (hasError) {
        setHasError(false);
      }

      return updateTaxExemptFormS3Key({
        districtId,
        payload: {
          taxExemptFormS3Url: taxExemptS3GetUrl,
        },
      });
    } catch (error) {
      return setHasError(true);
    } finally {
      setIsUploadingToS3(false);
    }
  }, [
    updateTaxExemptFormS3Key,
    districtId,
    hasError,
    taxExemptFormFile,
    taxExemptS3GetUrl,
    taxExemptS3PutUrl,
  ]);

  const yupErrors = useMemo(() => {
    if (!presignedUrlError && !updateTaxExemptFormS3KeyError) {
      return null;
    }

    const yupCreatePresignedUrlError = convertAxiosErrorToYupError(presignedUrlError as AxiosError);
    const yupAddTaxExemptFormS3KeyError = convertAxiosErrorToYupError(
      updateTaxExemptFormS3KeyError as AxiosError,
    );

    if (yupCreatePresignedUrlError) {
      return Object.values(yupCreatePresignedUrlError);
    }

    if (yupAddTaxExemptFormS3KeyError) {
      return Object.values(yupAddTaxExemptFormS3KeyError);
    }

    return null;
  }, [updateTaxExemptFormS3KeyError, presignedUrlError]);

  const handleGoToDistrictShowPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const hasTaxExemptFormAndHasNotConfirmedUpdate = hasTaxExemptForm && !hasConfirmedUpdate;

  const handleUploadFile = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];

    setTaxExemptFormFile(file);

    try {
      setHasError(false);

      return createTaxExemptFormPresignedUrl({
        districtId: districtId!,
        payload: {
          filename: file.name,
          filetype: file.type,
        },
      });
    } catch (err) {
      setTaxExemptFormFile(null);
      return setHasError(true);
    }
  }, [createTaxExemptFormPresignedUrl, districtId]);

  const handlePressUploadDropzoneContainer = useCallback(() => {
    if (hasTaxExemptFormAndHasNotConfirmedUpdate) {
      return handleOpenConfirmUpdateDialog();
    }

    return setShowAddFileMessage(false);
  }, [handleOpenConfirmUpdateDialog, hasTaxExemptFormAndHasNotConfirmedUpdate]);

  const handlePressUploadButton = useCallback(() => {
    if (!taxExemptFormFile) {
      setShowAddFileMessage(true);
    } else {
      handleUploadToS3();
    }
  }, [handleUploadToS3, taxExemptFormFile]);

  const isLoading = isGeneratingPresignedUrl || isUploadingToS3 || isUpdatingTaxExemptFormS3Key;

  return (
    <>
      <Container maxWidth="md">
        <EnhancedCard useMaterialV3>
          <Typography
            component="h1"
            gutterBottom
            sx={{
              marginLeft: 2,
              marginTop: 2,
            }}
            variant="h5"
          >
            Add Tax-Exempt Form
          </Typography>

          <CardContent
            sx={{
              padding: 2,
            }}
          >
            {loading
              ? <CircularProgress />
              : (
                <Typography
                  gutterBottom
                  variant="h6"
                >
                  {data?.district.label}
                </Typography>
              )}

            <ListItemWithSecondaryAction
              primaryText={(
                <ShowPageDataDisplay
                  label="Currently Has Tax-Exempt Form?"
                  type="boolean"
                  value={hasTaxExemptForm}
                />
              )}
              secondaryAction={hasTaxExemptForm ? {
                alwaysShowButton: true,
                buttonIcon: <DownloadIcon />,
                buttonText: 'Download',
                onClick: handleDownloadTaxExemptForm,
              } : undefined}
              secondaryTypographyProps={{
                component: 'div',
              }}
              useMaterialV3
            />

            <div
              onClick={handlePressUploadDropzoneContainer}
              role="presentation"
              tabIndex={-1}
            >
              <UploadDropzone
                acceptedFileTypes={acceptedFileTypes}
                disabled={isLoading}
                handleDrop={hasTaxExemptFormAndHasNotConfirmedUpdate
                  ? null
                  : handleUploadFile}
                helperText={`Upload a tax-exempt form for ${data?.district.label}`}
                marginBottom={1}
                marginTop={1}
                multiple={false}
                preventFileDialogOpen={hasTaxExemptFormAndHasNotConfirmedUpdate}
                rejectedDropErrorMessage="Unable to upload files"
                showAcceptedFiles
                useMaterialV3
              />
            </div>

            {yupErrors?.map((errorMessage) => (
              <EnhancedAlert
                key={errorMessage as string}
                severity="error"
                sx={{ marginTop: 2 }}
              >
                {errorMessage as string}
              </EnhancedAlert>
            ))}

            <Collapse in={hasError}>
              <EnhancedAlert
                severity="error"
                sx={{ marginTop: 2 }}
                title="Something went sideways"
              >
                Please try again or refresh the page.
              </EnhancedAlert>
            </Collapse>

            <Collapse in={showAddFileMessage}>
              <EnhancedAlert
                severity="info"
                sx={{ marginTop: 2 }}
                title="No file added"
              >
                Click on the dashed box above or drop a file there.
              </EnhancedAlert>
            </Collapse>
          </CardContent>

          <CardActions
            sx={{
              justifyContent: 'flex-end',
              padding: 2,
            }}
          >
            <Button
              onClick={handleGoToDistrictShowPage}
              startIcon={<ArrowBackIcon />}
              sx={{
                borderRadius: '20px',
              }}
              variant="outlined"
            >
              Go Back
            </Button>

            <SaveButton
              disabled={!taxExemptFormFile && showAddFileMessage}
              isSaving={isGeneratingPresignedUrl || isUploadingToS3 || isUpdatingTaxExemptFormS3Key}
              onClick={handlePressUploadButton}
              startIcon={<CloudUploadIcon />}
              sx={{
                borderRadius: '20px',
              }}
              variant="contained"
            >
              {isMobileOrTabletScreenSize ? 'Upload' : 'Upload Tax-Exempt Form'}
            </SaveButton>
          </CardActions>
        </EnhancedCard>
      </Container>

      <ConfirmUpdateDistrictTaxExemptFormDialog
        districtName={data?.district.label}
        onClose={handleCloseConfirmUpdateDialog}
        onConfirm={handleConfirmUpdate}
        open={isConfirmUpdateDialogOpen}
      />
    </>
  );
};

export default TaxExemptForm;
