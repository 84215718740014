// External Dependencies
import {
  CompleteInventoryAuditRequest,
  CreateInventoryAuditAssessmentRequest,
} from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { DistrictInventoryItemsRequest } from '@presto-assistant/api_types/api/v1/inventory';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useCallback } from 'react';
import { useTanstackMutation } from 'utils/api/index';

export const useGetDistrictInventoryCategories = () => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventory.districtCategories();
    },
    queryKey: ['districtInventoryCategories'],
  });
};

export const useGetDistrictInventoryIndex = (queryParams: DistrictInventoryItemsRequest['query']) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventory.districtIndex(queryParams);
    },
    queryKey: ['districtInventory', queryParams],
  });
};

export const useGetDistrictInventoryIndexReport = () => {
  return useTanstackMutation({
    mutationFn: (queryParams: DistrictInventoryItemsRequest['query']) => {
      return apiClient.v1.inventory.districtIndex({
        ...queryParams,
        exportReport: true,
      });
    },
  });
};

export const useGetInventoryAudits = () => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventoryAuditClient.listInventoryAudits();
    },
    queryKey: ['inventoryAuditsIndex'],
  });
};

export const useGetInventoryAudit = (id: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventoryAuditClient.getInventoryAudit({
        params: { id },
      });
    },
    queryKey: ['inventoryAudit', id],
  });
};

export const useGetInventoryAuditAssessments = (id: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventoryAuditClient.listInventoryAuditAssessments({
        params: { id },
      });
    },
    queryKey: [id, 'inventoryAuditAssessments'],
  });
};

export const useGetInventoryAuditAssessmentsByInventoryId = (inventoryAuditId: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventoryAuditClient.listInventoryAuditAssessmentsByInventoryId({
        params: { id: inventoryAuditId },
      });
    },
    queryKey: [inventoryAuditId, 'inventoryAuditAssessmentsByInventoryId'],
  });
};

export const useCreateInventoryAuditAssessment = () => {
  const mutationFn = useCallback((payload: {
    body: CreateInventoryAuditAssessmentRequest['body'];
    params: CreateInventoryAuditAssessmentRequest['params'];
  }) => {
    return apiClient.v1.inventoryAuditClient.createAssessment(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};

export const useMarkInventoryAuditAsComplete = () => {
  const mutationFn = useCallback((payload: { params: CompleteInventoryAuditRequest['params'] }) => {
    return apiClient.v1.inventoryAuditClient.completeInventoryAudit(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};
