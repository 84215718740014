// External Dependencies
import { APP_NAME } from 'utils/constants';
import {
  Box,
  CardContent,
  Collapse,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  FC, useMemo, useState,
} from 'react';
import { useGetOrganization, useGetPossibleFeederOrganizations } from 'gql/queries';
import { useUpdateOrganization } from 'gql/mutations';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  StandaloneSelect,
  StyledLink,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import { StyledStrong } from '../shared/styles';

// Local Typings
interface Props {
  hideTitle?: boolean;
  successorOrganization: GQL.IOrganization['successorOrganization'];
}

// Local Variables
const EnhancedStyledLink = styled(StyledLink)({
  fontSize: 15,
});
const EMPTY_STRING = 'empty';

// Component Definition
const EndOfYearSuccessorOrg: FC<Props> = ({
  hideTitle,
  successorOrganization,
}) => {
  const [showSuccessorOrgSelect, setShowSuccessorOrgSelect] = useState(false);

  const {
    data: organizationData,
  } = useGetOrganization();

  const {
    data: possibleFeederOrgData,
  } = useGetPossibleFeederOrganizations();

  const [
    updateOrganization,
  ] = useUpdateOrganization({
    clearCachePredicates: ['returningStudents'],
  });

  const handlePressChangeSuccessorOrgSelectLink = () => {
    setShowSuccessorOrgSelect(true);
  };

  const successorOrganizationOptions = useMemo(() => {
    const options = possibleFeederOrgData?.possibleFeederOrganizations.map((datum) => ({
      id: datum.id,
      label: datum.label,
    })) ?? [];

    // Add an "empty" value to allow the director to unset successor org
    options.unshift({ id: EMPTY_STRING, label: ' ' });

    return options;
  }, [
    successorOrganization?.id,
    possibleFeederOrgData?.possibleFeederOrganizations,
  ]);

  if (!organizationData?.organization || !possibleFeederOrgData) {
    return null;
  }

  const handleChangeSuccessorOrg = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    const commonOrgValues = {
      addressOne: organizationData?.organization.addressOne,
      addressTwo: organizationData?.organization.addressTwo,
      ccDirectors: organizationData?.organization.ccDirectors,
      city: organizationData?.organization.city,
      code: organizationData?.organization.code,
      entityTypeId: organizationData?.organization.entityType.id,
      label: organizationData?.organization.label,
      phoneNumber: organizationData?.organization.phoneNumber,
      stateId: organizationData?.organization.stateId,
      zipcode: organizationData?.organization.zipcode,
    };

    if (value === EMPTY_STRING) {
      updateOrganization({
        variables: {
          ...commonOrgValues,
          successorOrganizationId: null,
        },
      });
    } else {
      setShowSuccessorOrgSelect(false);
      updateOrganization({
        variables: {
          ...commonOrgValues,
          successorOrganizationId: value as string,
        },
      });
    }
  };

  return (
    <Box mb={2}>
      {!hideTitle && <Subtitle>Successor Organization</Subtitle>}

      <EnhancedCard>
        <CardContent>
          <Collapse
            in={Boolean(successorOrganization?.id && !showSuccessorOrgSelect)}
          >
            <Typography>
              <StyledStrong>
                {possibleFeederOrgData.possibleFeederOrganizations
                  .find((feederOrg) => feederOrg.id === successorOrganization?.id)?.label}
              </StyledStrong>{' '}
              <EnhancedStyledLink
                onClick={handlePressChangeSuccessorOrgSelectLink}
              >
                Change
              </EnhancedStyledLink>
            </Typography>
          </Collapse>

          <Collapse
            in={successorOrganization === null || showSuccessorOrgSelect}
          >
            <Typography sx={{ marginBottom: 1 }}>
              Which campus will most graduating students attend?
            </Typography>

            <Box marginTop={1}>
              <StandaloneSelect
                id="select-successor-organization"
                label="Successor Organization"
                name="successorOrganization"
                onChange={handleChangeSuccessorOrg}
                options={successorOrganizationOptions}
                value={successorOrganization?.id || ''}
              />
            </Box>
          </Collapse>

          <Collapse
            in={successorOrganization === null}
          >
            <EnhancedAlert
              severity="warning"
              sx={{ marginTop: 1 }}
            >
              Graduating students will only be automatically sent to another{' '}
              {APP_NAME} organization if you designate a
              successor organization.
            </EnhancedAlert>
          </Collapse>
        </CardContent>
      </EnhancedCard>
    </Box>
  );
};

export default EndOfYearSuccessorOrg;
