// External Dependencies
import { FC, useCallback } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@presto-assistant/api_types/utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BookIcon from '@mui/icons-material/Book';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';

// Internal Dependencies
import {
  DynamicFormFields,
  EnhancedContainer,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import {
  districtLibraryFormSchema,
  libraryFormSchema,
} from './schema';
import { useGetGradeLevelSelectOptions } from './data';
import LibraryAdditionalFormFields from './LibraryAdditionalFormFields';
import LibraryDangerZone from './LibraryDangerZone';
import LibraryInfoFormFields from './LibraryInfoFormFields';
import ShowLibraryAdditionalData from '../Show/ShowLibraryAdditionalData';
import ShowLibraryData from '../Show/ShowLibraryData';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  isAdmin?: boolean;
  libraryItem?: GQL.ILibraryItem;
  onSubmit?: (values: GQL.ICreateLibraryItemInput | GQL.IUpdateLibraryItemInput) => Promise<void>;
  readOnly?: boolean;
  title: string;
}
export interface LibraryFormValues extends GQL.ICreateLibraryItemInput {
  orgTypeId?: string;
  organizationId?: string;
  systemBarcode?: string;
}
export interface AdminLibraryFormValues extends LibraryFormValues {
  organizationId: string;
}

// Component Definition
const LibraryForm: FC<Props> = ({
  canUseDynamicFields,
  isAdmin,
  libraryItem,
  onSubmit,
  readOnly,
  title,
}) => {
  const navigate = useNavigate();

  const canEditLibrary = useSelector(hasPermission('library', 'edit'));
  const canDeleteLibrary = useSelector(hasPermission('library', 'delete'));
  const isDFA = useSelector(isDistrictAdmin);

  const canEdit = isDFA || canEditLibrary;
  const canDelete = isDFA || canDeleteLibrary;

  const libraryParams = useSelector(tableQueryParams('libraryItems'));
  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));

  const libraryIndexUrl = isDFA
    ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}${districtLibraryParams}`
    : `/${PATHS.LIBRARY}${libraryParams}`;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(libraryIndexUrl);
  }, [libraryIndexUrl, navigate]);

  const {
    data: gradeLevelData,
  } = useGetGradeLevelSelectOptions();

  // If readonly view, we need to make sure the libraryItem data exists
  // We also will not render the form until the select options arrive
  if ((readOnly && !libraryItem)
    || (!gradeLevelData)) {
    return null;
  }

  const initialValues: LibraryFormValues = {
    ...(canUseDynamicFields ? applyDynamicFields(libraryItem) : {}),
    accompaniment: libraryItem?.accompaniment ?? '',
    arranger: libraryItem?.arranger ?? '',
    author: libraryItem?.author ?? '',
    categoryId: libraryItem?.categoryId ?? '',
    comments: libraryItem?.comments ?? '',
    composer: libraryItem?.composer ?? '',
    conditionId: libraryItem?.conditionId ?? '',
    copyrightYear: libraryItem?.copyrightYear ?? null,
    gradeLevelId: libraryItem?.gradeLevel?.id ?? '',
    hasAccompaniment: libraryItem?.hasAccompaniment ?? null,
    instrumentationId: libraryItem?.instrumentationId ?? '',
    isMajorWork: libraryItem?.isMajorWork ?? null,
    isOutOfPrint: libraryItem?.isOutOfPrint ?? null,
    isSolo: libraryItem?.isSolo ?? null,
    language: libraryItem?.language ?? '',
    lastPerformance: libraryItem?.lastPerformance ?? '',
    majorWork: libraryItem?.majorWork ?? '',
    number: libraryItem?.number ?? '',
    numberOfCopies: libraryItem?.numberOfCopies ?? null,
    orgTypeId: libraryItem?.organization?.organizationType?.id ?? '1',
    period: libraryItem?.period ?? '',
    priceInCents: libraryItem?.priceInCents != null
      ? convertCentsToDollars(Number(libraryItem.priceInCents))
      : undefined,
    publisher: libraryItem?.publisher ?? '',
    stateCode: libraryItem?.stateCode ?? '',
    systemBarcode: libraryItem?.systemBarcode ?? '',
    title: libraryItem?.title ?? '',
    year: libraryItem?.year ?? undefined,
  };

  const adminInitialValues: AdminLibraryFormValues = {
    ...initialValues,
    organizationId: libraryItem?.organization?.id ?? '',
  };

  const handleFormikSubmit = async (
    values: LibraryFormValues | AdminLibraryFormValues,
    formikProps: FormikHelpers<GQL.ICreateLibraryItemInput>,
  ) => {
    const { setSubmitting } = formikProps;

    // We send in `null` for values if the user didn't provide/update them
    const updatedValues: LibraryFormValues | AdminLibraryFormValues = { ...values };

    if (!values.arranger) {
      updatedValues.arranger = null;
    }

    if (!values.categoryId) {
      updatedValues.categoryId = null;
    }

    if (!values.conditionId) {
      updatedValues.conditionId = null;
    }

    if (!values.copyrightYear) {
      updatedValues.copyrightYear = null;
    }

    if (!values.gradeLevelId) {
      updatedValues.gradeLevelId = null;
    }

    if (!values.instrumentationId) {
      updatedValues.instrumentationId = null;
    }

    if (!values.number) {
      updatedValues.number = null;
    }

    if (!values.year) {
      updatedValues.year = null;
    }

    if (values.priceInCents) {
      updatedValues.priceInCents = convertDollarsToCents(Number(values.priceInCents));
    } else if ((values as any).priceInCents === '') {
      updatedValues.priceInCents = null;
    }

    // The user is not allowed to edit the system barcode
    delete updatedValues.systemBarcode;

    await onSubmit?.(updatedValues);

    setSubmitting(false);
  };

  return (
    <>
      <Formik<LibraryFormValues | AdminLibraryFormValues>
        enableReinitialize
        initialValues={isAdmin ? adminInitialValues : initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={isAdmin
          ? districtLibraryFormSchema
          : libraryFormSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <EnhancedContainer maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit}
                      icon={BookIcon}
                      readOnly={readOnly}
                      title={title}
                    >
                      {readOnly && libraryItem ? (
                        <ShowLibraryData
                          isAdmin={isAdmin}
                          libraryItem={libraryItem}
                          organizationId={isAdmin ? values.organizationId : undefined}
                        />
                      ) : (
                        <LibraryInfoFormFields
                          isAdmin={Boolean(isAdmin)}
                          orgTypeId={values.orgTypeId}
                          organizationId={isAdmin ? values.organizationId : undefined}
                        />
                      )}
                    </ShowCard>
                  </Box>
                </EnhancedContainer>

                <EnhancedContainer maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit}
                      icon={InfoIcon}
                      readOnly={readOnly}
                      title="Additional Information"
                    >
                      {readOnly && libraryItem ? (
                        <ShowLibraryAdditionalData libraryItem={libraryItem} />
                      ) : (
                        <LibraryAdditionalFormFields />
                      )}
                    </ShowCard>
                  </Box>

                  <DynamicFormFields
                    isAdmin={Boolean(isAdmin)}
                    item={libraryItem}
                    organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                    showCardProps={{
                      canEdit: canEditLibrary,
                      readOnly,
                    }}
                    tableRef="library_items"
                  />
                </EnhancedContainer>
              </Grid>

              {!readOnly && (
                <FormActions
                  context="Library Item"
                  isEditing={!!libraryItem}
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={handlePressCancelOrBackButton}
                />
              )}
            </Form>
          );
        }}
      </Formik>

      {canDelete && !readOnly && libraryItem?.id && libraryItem?.organization?.label && (
        <EnhancedContainer>
          <LibraryDangerZone
            libraryIndexUrl={libraryIndexUrl}
            libraryItemId={libraryItem.id}
            organizationLabel={libraryItem.organization.label}
          />
        </EnhancedContainer>
      )}
    </>
  );
};

export default LibraryForm;
