// External Dependencies
import {
  Dispatch, SetStateAction, useCallback, useEffect, useMemo,
} from 'react';
import {
  GlobalEditField,
  globalEditResources,
} from '@presto-assistant/api_types/api/v1/globalEdit';
import { SelectChangeEvent } from '@mui/material/Select';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import MinusCircleOutlineIcon from 'mdi-material-ui/MinusCircleOutline';
import styled from 'styled-components';

// Internal Dependencies
import { Select } from 'components/shared';
import { StyledStrong } from 'components/shared/StyledStrong';
import { pluralize } from 'utils';
import { useIsOpen } from 'hooks/useIsOpen';
import Flex from 'components/shared/Flex';
import GlobalEditInput from 'components/shared/GlobalEditDialog/GlobalEditInput';
import SaveButton from 'components/shared/SaveButton';

// Local Typings
interface Props {
  fieldValues: Record<string, string>;
  fields: GlobalEditField[];
  globalEditResource: typeof globalEditResources[number];
  onClickSubmit: () => void;
  onClose: () => void;
  rowCount: number;
  selectedFields: GlobalEditField[];
  setFieldValues: Dispatch<SetStateAction<Record<string, string>>>;
  setSelectedFields: Dispatch<SetStateAction<GlobalEditField[]>>;
}

// Local Variables
const StyledDialogContent = styled(DialogContent)({
  paddingTop: 0,
});

// Component Definition
const GlobalEditForm = ({
  fieldValues,
  fields,
  globalEditResource,
  onClickSubmit,
  onClose,
  rowCount,
  selectedFields,
  setFieldValues,
  setSelectedFields,
}: Props): JSX.Element => {
  const {
    handleOpen: openAvailableFields,
    isOpen: isShowingAvailableFields,
    toggleIsOpen: toggleIsShowingAvailableFields,
  } = useIsOpen();

  const availableFields = useMemo<GlobalEditField[]>(() => {
    const selectedFieldIds = selectedFields.map((field) => field.id);

    return fields.filter((field) => !selectedFieldIds.includes(field.id));
  }, [fields, selectedFields]);

  const handleSelectField = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;

    const selectedField = availableFields.find((field) => field.id === value);

    if (selectedField) {
      setSelectedFields((prevFields) => [...prevFields, selectedField]);
    }

    toggleIsShowingAvailableFields();
  }, [availableFields, setSelectedFields, toggleIsShowingAvailableFields]);

  const handleRemoveField = useCallback((fieldId: string) => () => {
    setSelectedFields((prevFields) => prevFields.filter((field) => field.id !== fieldId));
  }, [setSelectedFields]);

  useEffect(() => {
    if (!selectedFields.length && availableFields.length) {
      openAvailableFields();
    }
  }, [availableFields, openAvailableFields, selectedFields]);

  useEffect(() => {
    const fieldValuesCopy = { ...fieldValues };

    selectedFields.forEach((field) => {
      if (!fieldValuesCopy[field.id]) {
        fieldValuesCopy[field.id] = '';
      }
    });

    Object.keys(fieldValuesCopy).forEach((key) => {
      if (!selectedFields.find((field) => field.id === key)) {
        delete fieldValuesCopy[key];
      }
    });

    setFieldValues(fieldValuesCopy);
    // do not include fieldValues in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields]);

  const handleChange = useCallback((fieldId: string) => (value: string) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  }, [setFieldValues]);

  return (
    <>
      <StyledDialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Editing{' '}
          <StyledStrong>{rowCount}</StyledStrong>{' '}
          {pluralize(rowCount, toTitleCase(globalEditResource))}
        </DialogContentText>

        {selectedFields.map((field) => (
          <Flex
            flexWrap="nowrap"
            gap={1}
            key={field.id}
            sx={{ marginBottom: 2.5 }}
          >
            <GlobalEditInput
              field={field}
              onChangeValue={handleChange(field.id)}
              value={fieldValues[field.id]}
            />

            <IconButton
              color="primary"
              onClick={handleRemoveField(field.id)}
            >
              <MinusCircleOutlineIcon />
            </IconButton>
          </Flex>
        ))}

        {!isShowingAvailableFields && availableFields.length > 0 && (
          <Button
            color="primary"
            onClick={toggleIsShowingAvailableFields}
            size="small"
            sx={{
              marginTop: 2,
            }}
            variant="outlined"
          >
            Edit more fields
          </Button>
        )}

        {isShowingAvailableFields && (
          <Box>
            <Select
              label="Choose Field"
              name="newField"
              onChange={handleSelectField}
              options={availableFields}
              value=""
            />
          </Box>
        )}
      </StyledDialogContent>

      <DialogActions
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <SaveButton
          onClick={onClickSubmit}
        >
          Next
        </SaveButton>
      </DialogActions>
    </>
  );
};

export default GlobalEditForm;
