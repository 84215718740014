// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import Subtitle from 'components/shared/Subtitle';

// Local Dependencies
import MyGroupsTable from './MyGroupsTable';

// Component Definition
const MyGroups: FC = () => {
  return (
    <>
      <Subtitle>
        My Groups
      </Subtitle>

      <MyGroupsTable />
    </>
  );
};

export default MyGroups;
