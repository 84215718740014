// External Dependencies
import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    AuditHistory: {
      fields: {
        changes: {
          merge(existing, incoming) {
            return [
              ...(existing ?? []),
              ...(incoming ?? []),
            ];
          },
        },
      },
    },
  },
});
