// External Dependencies
import {
  CardContent,
  CardHeader,
} from '@mui/material';
import { FC } from 'react';
import { alpha } from '@mui/material/styles';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { SimpleUniform } from 'gql/queries';

// Local Typings
interface Props {
  simpleUniform: SimpleUniform;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardHeader-root': {
    paddingBottom: 0,
  },

  '.MuiCardHeader-title': {
    fontSize: '1.1rem',
    paddingBottom: 0,
  },

  backgroundColor: alpha(theme.palette.dashboardOrgName, 0.3),
  margin: theme.spacing(0, 1, 1.5),
  width: '45%',
}));

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const UniformSimpleDataCard: FC<Props> = ({
  simpleUniform,
}) => {
  const {
    size,
    uniformNumber,
  } = simpleUniform;

  return (
    <StyledEnhancedCard>
      <CardHeader title="Uniform part" />

      <CardContent>
        <ShowPageDataDisplay
          label="Uniform Number"
          value={uniformNumber}
        />
        <ShowPageDataDisplay
          label="Size"
          value={size}
        />
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default UniformSimpleDataCard;
