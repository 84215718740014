// External Dependencies
import { FC, useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { getOperationName } from '@apollo/client/utilities';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { DELETE_FILE } from 'gql/mutations';
import {
  GET_FILES_INDEX,
  GET_FILE_DIRECTORIES,
  useGetFileSignedUrl,
  useGetFilesIndex,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { displayFileSize } from 'utils/lib/display_file_size';
import { formatDateTime } from 'utils';
import { hasPermission, selfId as selfIdSelector } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';
import TableV2, { TableColumn, createActionsColumn } from 'components/shared/TableV2';

// Local Typings
interface Props {
  directory: string;
}

// Component Definition
const FilesTable: FC<Props> = ({ directory }) => {
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const selfId = useSelector(selfIdSelector);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();

  const canDeleteFiles = useSelector(hasPermission('s3Uploads', 'delete'));
  const canWriteFiles = useSelector(hasPermission('s3Uploads', 'write'));

  const formatDeletePayload = useCallback<DeleteDialogV2Props<GQL.IDeleteFileOnMutationArguments>['formatPayload']>(() => ({
    id: singleItemDeleteId ?? '',
  }), [singleItemDeleteId]);

  const [getSignedUrl] = useGetFileSignedUrl();

  const {
    data,
    loading,
  } = useGetFilesIndex(directory);

  const handleOpenSingleItemDeleteDialog = (row: GQL.IFileIndexItem) => {
    setSingleItemDeleteId(row.id);
  };

  const handleCloseDeleteDialog = () => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  };

  const handleClickDownload = (row: GQL.IFileIndexItem) => {
    getSignedUrl({
      variables: { id: row.id },
    });
  };

  const uploadedByAccessor = ({
    createdBy,
    createdById,
  }: GQL.IFileIndexItem) => (
    <Typography variant="body2">
      {createdBy}{createdById === selfId
        ? (
          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
          >
            {' '}(You)
          </Typography>
        )
        : ''}
    </Typography>
  );

  const columns: TableColumn<GQL.IFileIndexItem>[] = [
    {
      Header: 'Name',
      accessor: (item) => item.fileName,
      sortBy: 'fileName',
    },
    {
      Header: 'Uploaded',
      accessor: (item) => formatDateTime(item.createdAt),
      sortBy: 'createdAt',
    },
    {
      Header: 'Uploaded By',
      accessor: (item) => uploadedByAccessor(item),
      sortBy: 'userFirstName',
    },
    {
      Header: 'Size',
      accessor: (item) => displayFileSize(item.fileSize),
      sortBy: 'fileSize',
    },
  ];

  const addButtonProps = {
    label: 'Files',
    to: `/${PATHS.FILES}/new?directory=${directory}`,
  };

  const actions = [
    {
      action: handleClickDownload,
      icon: <DownloadIcon />,
      text: 'Download',
    },
  ];

  if (canDeleteFiles) {
    actions.push({
      action: handleOpenSingleItemDeleteDialog,
      icon: <DeleteIcon />,
      text: 'Delete',
    });
  }

  const actionColumn = createActionsColumn<GQL.IFileIndexItem>(actions);

  if (actionColumn) {
    columns.push(actionColumn);
  }

  return (
    <>
      <TableV2<GQL.IFileIndexItem>
        addButtonProps={canWriteFiles ? addButtonProps : null}
        clickRowTo={(row) => `/${PATHS.FILES}/${directory}/${row.id}`}
        columns={columns}
        data={data?.filesIndex.data ?? []}
        fullCount={data?.filesIndex.fullCount}
        isLoading={loading}
        reduxStateKey="files"
        withSearch
      />

      <DeleteDialogV2<GQL.IDeleteFileOnMutationArguments>
        context={['file']}
        formatPayload={formatDeletePayload}
        hasSelection={false}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_FILE}
        onClose={handleCloseDeleteDialog}
        refetchQueries={() => [
          getOperationName(GET_FILE_DIRECTORIES) as string,
          getOperationName(GET_FILES_INDEX) as string,
        ]}
      />
    </>
  );
};

export default FilesTable;
