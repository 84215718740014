// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialCreditsIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialCreditsTableZeroState from './FinancialCreditsTableZeroState';

// Component Definition
const FinancialCreditsTable: FC = () => {
  const canWritePayments = useSelector(hasPermission('payments', 'write'));

  const handleClickRowTo = (id: string) =>
    `/${PATHS.FINANCIAL_CREDITS}/${id}`;

  const {
    data,
    isLoading,
  } = useGetFinancialCreditsIndex();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={canWritePayments ? {
          label: 'Financial Credit',
          to: `/${PATHS.FINANCIAL_CREDITS_NEW}`,
        } : null}
        clickRowTo={handleClickRowTo}
        columns={columns}
        components={{
          NoRowsOverlay: FinancialCreditsTableZeroState,
        }}
        loading={isLoading}
        rows={data}
        tableResource="financialCredits"
        withSearch
      />
    </DataGridContainer>
  );
};

export default FinancialCreditsTable;
