// External Dependencies
import {
  Box, CardContent, InputAdornment, TextField,
} from '@mui/material';
import {
  FC,
} from 'react';

// Internal Dependencies
import {
  EnhancedCard, MoneyInput, ShowCardHeader, ShowPageDataDisplay,
} from 'components/shared';
import { useFormikTextField } from 'hooks/useFormikTextField';

// Local Dependencies
import { RowValues } from '../PaymentTable/PaymentTableRow';

// Local Typings
export interface PaymentsFormValues {
  payments: RowValues[];
}

interface Props {
  fee: GQL.IFinancialFeeIndexItem;
  hasMixedFees: boolean;
  index: number;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const PaymentCard: FC<Props> = ({
  fee,
  hasMixedFees,
  index,
}) => {
  const paymentField = useFormikTextField(`payments[${index}].amountInDollars`, '');

  return (
    <EnhancedCard sx={{ marginTop: 1 }}>
      <ShowCardHeader
        title={`${fee.userFirstName} ${fee.userLastName}`}
      />

      <CardContent>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <ShowPageDataDisplay
            align="left"
            key={`${fee.financialItemId}-label`}
            label="Financial Item"
            type="text"
            value={fee.financialItemLabel}
          />

          {hasMixedFees && (
            <ShowPageDataDisplay
              align="left"
              key={`${fee.financialItemId}-processingFee`}
              label="Processing Fee"
              type="text"
              value={fee.isOrganizationCoveringStripeFee ? 'No' : 'Yes'}
            />
          )}

          <ShowPageDataDisplay
            align="left"
            key={`${fee.financialItemId}-balanceDue`}
            label="Balance Due"
            type="currency"
            value={fee.balanceDueInCents}
          />

          <TextField
            InputProps={{
              inputComponent: MoneyInput as any,
              inputProps: {
                'aria-label': 'Amount',
              },
              startAdornment,
            }}
            label="Payment Amount *"
            sx={{ marginTop: 1, width: 180 }}
            variant="filled"
            {...paymentField}
          />

        </Box>
      </CardContent>
    </EnhancedCard>
  );
};

export default PaymentCard;
