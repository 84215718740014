// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToMyCredits: FC = () => {
  const myFinancialCreditsParams = useSelector(tableQueryParams('myFinancialCredits'));

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View credits from overpaying fees for you and your children
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.STUDENT_CREDITS}${myFinancialCreditsParams}`}>
        My Credits
      </ForwardButton>
    </Box>
  );
};

export default LinkToMyCredits;
