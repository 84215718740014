// External Dependencies
import {
  Box, Button, Card, CardContent,
} from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import { Container, Flex, Subtitle } from 'components/shared';
import { InventoryTypeAhead } from 'components/shared/TypeAheads';
import { SimpleInventoryItem } from 'gql/queries';

// Local Typings
interface Props {
  onSelectInventoryItem: (libraryItem: SimpleInventoryItem) => any;
}

// Component Definition
const SelectInventoryItem: FC<Props> = ({
  onSelectInventoryItem,
}) => {
  const [inventoryItem, setInventoryItem] = useState<SimpleInventoryItem | null>(null);

  const handleClickButton = () => {
    if (inventoryItem) {
      onSelectInventoryItem(inventoryItem);
    }
  };

  return (
    <Container maxWidth={500}>
      <Subtitle>
        Select Inventory
      </Subtitle>

      <Card>
        <CardContent>
          <InventoryTypeAhead onSelectInventoryItem={setInventoryItem} />
        </CardContent>

        <Box
          mb={1}
          mr={1}
        >
          <Flex justifyContent="flex-end">
            <Button
              color="primary"
              disabled={!inventoryItem}
              onClick={handleClickButton}
              variant="contained"
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Card>
    </Container>
  );
};

export default SelectInventoryItem;
