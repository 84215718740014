// External Dependencies
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { LibraryIndexResponseItem, LibraryItemsResponse } from '@presto-assistant/api_types/api/v1/library';
import { getOperationName } from '@apollo/client/utilities';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteCircleOutlineIcon from 'mdi-material-ui/DeleteCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LibraryIcon from '@mui/icons-material/LibraryBooks';

// Internal Dependencies
import { DELETE_LIBRARY_ITEMS } from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { GET_LIBRARY_INDEX_ITEMS, useGetOrganization } from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { apiClient } from 'utils/apiClient';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { open as openLibraryFileUploadDialog } from 'state/ui/libraryFileUploadDialog/actions';
import { tableQueryParams } from 'state/table/selectors';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DialogLibraryFileUpload from 'components/shared/DialogLibraryFileUpload';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import DistrictLibrarySettingsDialog from './DistrictLibrarySettingsDialog';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.LIBRARY}/${id}`;
const dataSelector = (response: LibraryItemsResponse) => response?.data;
const fullCountSelector = (response: LibraryItemsResponse) => response?.fullCount;

// Component Definition
const LibraryTableDataGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    selectedLibraryItemIds,
    setSelectedLibraryItemIds,
  ] = useState<GridRowSelectionModel>([]);

  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);
  const [
    isDistrictLibrarySettingsDialogOpen,
    setIsDistrictLibrarySettingsDialogOpen,
  ] = useState(false);

  const handleRemoveSelectionAll = useClearTableSelection('libraryItems');

  const canWriteLibraryItems = useSelector(hasPermission('library', 'write'));
  const canEditLibraryItems = useSelector(hasPermission('library', 'edit'));
  const canDeleteLibraryItems = useSelector(hasPermission('library', 'delete'));
  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.library.index(queryParams);
  }, []);

  const {
    data: organizationData,
  } = useGetOrganization();

  const {
    data,
    fullCount,
    isLoading,
  } = useInfinitePaginatedListQuery<LibraryItemsResponse, LibraryIndexResponseItem>({
    dataSelector,
    fullCountSelector,
    pageSize: 500,
    queryKey: ['libraryItems'],
    request: fetchData,
  });

  const handleOpenSingleItemDeleteDialog = useCallback((row: LibraryIndexResponseItem) => {
    setSingleItemDeleteId(row.id);
  }, [setSingleItemDeleteId]);

  const handleIsDistrictLibrarySettingsDialogOpen = useCallback(() => {
    setIsDistrictLibrarySettingsDialogOpen(!isDistrictLibrarySettingsDialogOpen);
  }, [isDistrictLibrarySettingsDialogOpen]);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleDeleteDialog,
  } = useIsOpen();

  const handleCloseDeleteDialog = useCallback(() => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  }, [closeDeleteDialog]);

  const handleOpenDialogLibraryFileUpload = useCallback(() => {
    dispatch(openLibraryFileUploadDialog());
  }, [dispatch]);

  const handleNavigateToEdit = useCallback((row: LibraryIndexResponseItem) => {
    navigate(`/${PATHS.LIBRARY}/${row.id}/edit`);
  }, [navigate]);

  const handleClickDistrictLibrary = useCallback(() => {
    if (organizationData?.organization?.district?.isDistrictLibraryEnabled) {
      navigate(`/${PATHS.LIBRARY_DISTRICT}${districtLibraryParams}`);
    } else {
      handleIsDistrictLibrarySettingsDialogOpen();
    }
  }, [
    districtLibraryParams,
    handleIsDistrictLibrarySettingsDialogOpen,
    navigate,
    organizationData?.organization?.district?.isDistrictLibraryEnabled,
  ]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canWriteLibraryItems) {
      actions.push({
        action: handleOpenDialogLibraryFileUpload,
        icon: <CloudUploadIcon />,
        sectionTitle: 'Import',
        text: 'Import library items',
      });
    }

    if (canDeleteLibraryItems) {
      actions.push(...[
        {
          action: toggleDeleteDialog,
          icon: <DeleteCircleOutlineIcon />,
          isDisabled: selectedLibraryItemIds.length === 0,
          sectionTitle: 'Delete',
          text: `Delete selected (${selectedLibraryItemIds.length})`,
        },
      ]);
    }

    if (organizationData?.organization.district) {
      actions.push({
        action: handleClickDistrictLibrary,
        icon: <LibraryIcon />,
        sectionTitle: 'District Library',
        text: 'View district library',
      });
    }

    return actions;
  }, [
    canWriteLibraryItems,
    canDeleteLibraryItems,
    handleClickDistrictLibrary,
    handleOpenDialogLibraryFileUpload,
    organizationData,
    toggleDeleteDialog,
    selectedLibraryItemIds,
  ]);

  const extraColumns = useMemo<DataGridColDef<LibraryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<LibraryIndexResponseItem>([
        {
          action: handleNavigateToEdit,
          icon: <EditIcon />,
          isDisabled: !canEditLibraryItems,
          text: 'Edit',
        },
        ...(canDeleteLibraryItems ? [
          {
            action: handleOpenSingleItemDeleteDialog,
            icon: <DeleteIcon />,
            text: 'Delete',
          },
        ] : []),
      ]) as DataGridColDef<LibraryIndexResponseItem>;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteLibraryItems,
      canEditLibraryItems,
      handleNavigateToEdit,
      handleOpenSingleItemDeleteDialog,
    ],
  );

  const columns = useColumns(extraColumns);

  const fetchDeleteDialogQueries = useCallback(() => [
    getOperationName(GET_LIBRARY_INDEX_ITEMS) as string,
  ], []);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWriteLibraryItems ? {
            label: 'Library Item',
            to: `/${PATHS.LIBRARY}/new`,
          } : null}
          checkboxSelection={canDeleteLibraryItems || canEditLibraryItems}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          globalEditResource="library_item"
          loading={isLoading}
          onSelectionModelChange={setSelectedLibraryItemIds}
          rows={data}
          selectionModel={selectedLibraryItemIds}
          tableResource="libraryItems"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogLibraryFileUpload />

      <DistrictLibrarySettingsDialog
        isOpen={isDistrictLibrarySettingsDialogOpen}
        onClose={handleIsDistrictLibrarySettingsDialogOpen}
      />

      <DeleteDialog
        context={['library item']}
        idsToDelete={singleItemDeleteId
          ? [singleItemDeleteId]
          : selectedLibraryItemIds as string[]}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_LIBRARY_ITEMS}
        onClose={handleCloseDeleteDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="libraryItems"
        refetchQueries={fetchDeleteDialogQueries}
        size={fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default LibraryTableDataGrid;
