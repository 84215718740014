// External Dependencies
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useGetAllDistrictDynamicFields = () => {
  const query = useCallback(() => {
    return apiClient.v1.dynamicFieldClient.listAll();
  }, []);

  return useQuery({
    queryFn: query,
    queryKey: ['dynamicField'],
  });
};
