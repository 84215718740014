// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

interface Props {
  isLast: boolean;
  item: GQL.IItemWithBalance;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.StyledLinearProgress': {
    backgroundColor: `${theme.palette.prestoPrimary}44`,
    borderRadius: 6,
    height: 12,
  },
  '.strong': {
    fontWeight: 'bold',
  },
}));

const HighestBalanceItem: FC<Props> = ({ isLast, item }) => {
  const totalBalanceInCents = item.balancePaidInCents + item.balanceDueInCents;

  const percentagePaid = Math.floor(
    (item.balancePaidInCents / totalBalanceInCents) * 100,
  );

  return (
    <StyledRoot
      key={item.id}
      paddingY={1}
    >
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent="space-between"
        marginBottom={1}
      >
        <Typography>
          {item.label}
        </Typography>

        <Typography className="strong">
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(item.balanceDueInCents),
          )}
        </Typography>
      </Box>

      <LinearProgress
        aria-label={`${item.label} - ${percentagePaid} percent paid`}
        className="StyledLinearProgress"
        value={percentagePaid}
        variant="determinate"
      />

      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={0.5}
      >
        <Typography variant="caption">
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(item.balancePaidInCents),
          )}
          {' '}/{' '}
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(totalBalanceInCents),
          )}
          {' '}
          ({percentagePaid}%)
        </Typography>
      </Box>

      <Box marginTop={1}>
        {!isLast && (
          <Divider />
        )}
      </Box>
    </StyledRoot>
  );
};

export default HighestBalanceItem;
