// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const AdminOrganizationsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="Invite directors or find organizations to link to your district to show data in this table."
  />
);

export default AdminOrganizationsTableZeroState;
