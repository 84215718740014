// External Dependencies
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { Form, SaveButton, Subtitle } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { useGetMySentInvitationsToDfa } from 'gql/queries';
import { useInviteDistrict } from 'gql/mutations';
import { validateEmail } from 'utils';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import InvitationListItem from './InvitationListItem';

// Component Definition
const InvitationToDfaForm = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const emailField = useTextField('');

  const dispatch = useDispatch();

  useEffect(() => {
    const locationHash = window.location.hash;

    // If the user was sent here to send an invite to a district admin,
    //  then we focus the input to help the user know what to do
    if (locationHash === '#invite-district-admin-form' && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView();
    }
  }, []);

  const invitationData = useGetMySentInvitationsToDfa();

  const [inviteDistrict] = useInviteDistrict({
    onCompleted: () => {
      emailField.onReset();
      dispatch(addNotification('Invitation sent!', 'success'));
    },
  });

  const handleSubmit = () => {
    inviteDistrict({
      variables: {
        input: {
          dfaEmail: emailField.value,
        },
      },
    });
  };

  const invitationCount = invitationData.data?.mySentInvitationsToDfa.length ?? 0;
  const hasSentInvitations = !!invitationCount;

  return (
    <>
      <Form
        id="invite-district-admin-form"
        onSubmit={handleSubmit}
      >
        <TextField
          {...emailField}
          fullWidth
          inputRef={inputRef}
          label="District Administrator Email"
          variant="filled"
        />

        <Box
          display="flex"
          justifyContent="flex-end"
          mt={2}
        >
          <SaveButton
            disabled={!validateEmail(emailField.value)}
            endIcon={<SendIcon />}
            isSaving={false}
          >
            Invite Admin
          </SaveButton>
        </Box>
      </Form>

      {hasSentInvitations && (
        <>
          <Subtitle>
            Sent invitations
          </Subtitle>

          <List dense>
            {invitationData.data?.mySentInvitationsToDfa.map((invitation, index) => (
              <InvitationListItem
                divider={index < invitationCount - 1}
                invitation={invitation}
                key={invitation.id}
              />
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default InvitationToDfaForm;
