// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const ChecklistTableZeroState: FC = () => {
  return (
    <TableDataGridZeroState
      message={(
        <>
          <Typography
            component="div"
            paragraph
          >
            No members added to this checklist yet.
          </Typography>

          <Typography>
            Press the{' '}
            <strong>Add Checklist Data</strong>{' '}
            button at the top of the page to get started.
          </Typography>
        </>
      )}
    />
  );
};

export default ChecklistTableZeroState;
