// External Dependencies
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  ListItemWithSecondaryAction,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrict } from 'gql/queries';

// Component Definition
const TaxExemptFormSection = (): JSX.Element => {
  const navigate = useNavigate();

  const { data } = useGetDistrict();

  const hasTaxExemptForm = Boolean(data?.district.taxExemptFormS3Key);

  const secondaryAction = useMemo(() => ({
    buttonIcon: hasTaxExemptForm
      ? <ArrowRightIcon />
      : <AddIcon />,
    buttonText: hasTaxExemptForm ? 'View' : 'Add',
    onClick: () => {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.BILLING_TAX_EXEMPT_FORM}`);
    },
  }), [hasTaxExemptForm, navigate]);

  return (
    <ListItemWithSecondaryAction
      primaryText={`${hasTaxExemptForm ? 'View/Update' : 'Add'} Tax-Exempt Form`}
      secondaryAction={secondaryAction}
      secondaryText={hasTaxExemptForm
        ? 'View or update the tax-exempt form for your district'
        : 'Add a tax-exempt form for to ensure your district is not charged sales tax'}
      secondaryTypographyProps={{
        component: 'div',
      }}
      useMaterialV3
    />
  );
};

export default TaxExemptFormSection;
