// External Dependencies
import { HTMLProps, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { BaseTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';

// Internal Dependencies
import { formatPhoneNumber } from 'utils';
import formatCCExp from 'utils/lib/formatCCExp';
import useDidMount from 'hooks/useDidMount';

// Local Dependencies
import MoneyInput from './MoneyInput';

// Local Typings
export interface CustomInputProps extends BaseTextFieldProps {
  InputProps?: object;
  dataType?: 'currency' | 'ccExp';
  helperText?: string;
  htmlInputProps?: TextFieldProps['inputProps'];
  isCurrency?: boolean;
  name: string;
  onBlur?: HTMLProps<HTMLInputElement>['onBlur'];
  onChangeValue?: (value: string) => void;
  rootStyles?: string;
  testId?: string;
  type?: 'text' | string;
  variant?: 'filled' | 'outlined' | 'standard';
}

// Local Variables
const StyledTextField = styled(TextField)({
  margin: '8px 0',
});

const currencyStartAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const CustomInput = ({
  InputProps = {},
  color,
  dataType,
  error,
  fullWidth = true,
  helperText,
  htmlInputProps = {},
  isCurrency,
  label,
  name,
  onChangeValue,
  rootStyles,
  testId,
  type,
  variant = 'filled',
  ...otherProps
}: CustomInputProps): JSX.Element => {
  // Must be used in a Formik context
  const { setFieldValue } = useFormikContext();

  const didMount = useDidMount();

  const [field, meta] = useField(name);
  const hasError = Boolean(meta.error);
  const isTouched = Boolean(meta.touched);

  const { value: valueFromField } = field;

  useEffect(() => {
    // Useful for debouncing fields
    if (didMount && onChangeValue) {
      onChangeValue(valueFromField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeValue, valueFromField]);

  useEffect(() => {
    // Values that need to be formatted manually like
    //  phone numbers can be done in this spot
    if (type === 'tel' && valueFromField.length > 0) {
      setFieldValue(
        name as never,
        formatPhoneNumber(valueFromField) as string,
      );
    } else if (dataType === 'ccExp') {
      setFieldValue(
        name as never,
        formatCCExp(valueFromField) as string,
      );
    }
  }, [dataType, name, setFieldValue, type, valueFromField]);

  let helperOrErrorText = helperText;

  if (isTouched && hasError) {
    helperOrErrorText = meta.error;
  }

  return (
    <StyledTextField
      className={rootStyles}
      {...otherProps}
      InputLabelProps={{
        classes: { root: 'label' },
      }}
      InputProps={{
        classes: { root: 'input' },
        inputComponent: isCurrency ? MoneyInput as any : undefined,
        ...(isCurrency
          ? {
            startAdornment: currencyStartAdornment,
          }
          : {}),
        inputProps: {
          'data-testid': testId,
          maxLength: dataType === 'ccExp' ? 5 : undefined,
          ...htmlInputProps,
        },
        ...InputProps,
      }}
      color={color}
      error={error || (isTouched && hasError)}
      fullWidth={fullWidth}
      helperText={helperOrErrorText || undefined}
      id={name}
      label={label}
      type={isCurrency ? 'text' : type}
      variant={variant as any}
      {...field}
    />
  );
};

export default CustomInput;
