// External Dependencies
import { Box, Paper } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  BackToButton,
  Flex,
  ForwardButton,
} from 'components/shared';

// Local Typings
export interface PageHeaderProps {
  backButtonProps?: {
    label: string;
    path: string;
  }
  editButtonPath?: string;
}

// Local Variables
// making this a constant to ensure constistency in button placement
// regardless of whether or not paper is present.
const PAPER_BORDER_WIDTH = 1;
const VERTICAL_SPACING = 8;

const StyledPaper = styled(Paper)(({ theme }) => ({
  '& button': {
    margin: 0,
  },
  borderWidth: PAPER_BORDER_WIDTH,
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${VERTICAL_SPACING}px ${theme.spacing(3)}`,
}));

// Component Definition
const PageHeader: FC<PageHeaderProps> = ({
  backButtonProps,
  editButtonPath,
}) => {
  if (!backButtonProps && !editButtonPath) {
    return null;
  }

  if (backButtonProps && editButtonPath) {
    return (
      <StyledPaper variant="outlined">
        <BackToButton
          context={backButtonProps.label}
          navigateTo={backButtonProps.path}
        />

        <ForwardButton navigateTo={editButtonPath}>
          Edit
        </ForwardButton>
      </StyledPaper>
    );
  }

  return (
    <Box py={`${VERTICAL_SPACING + PAPER_BORDER_WIDTH}px`}>
      <Flex justifyContent={backButtonProps ? 'space-between' : 'flex-end'}>
        {backButtonProps && (
          <BackToButton
            context={backButtonProps.label}
            navigateTo={backButtonProps.path}
          />
        )}

        {editButtonPath && (
          <ForwardButton navigateTo={editButtonPath}>
            Edit
          </ForwardButton>
        )}
      </Flex>
    </Box>
  );
};

export default PageHeader;
