// External Dependencies
import { HTMLProps } from 'react';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';

// Internal Dependencies
import { CustomInput } from 'components/shared';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
  isTextArea?: boolean;
  type?: HTMLProps<HTMLInputElement>['type'];
}

// Component Definition
const TextBlock = ({
  disabled,
  formBlock,
  isTextArea,
  type,
}: Props): JSX.Element => {
  return (
    <CustomInput
      disabled={disabled}
      label={formBlock.label}
      multiline={isTextArea}
      name="response"
      required={formBlock.isRequired}
      rows={4}
      type={type}
    />
  );
};

export default TextBlock;
