// External Dependencies
import { Form, FormikErrors, FormikTouched } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  CustomInput,
  EnhancedAlert,
  FormActions,
  MoneyInput,
  PriceChangeSvg,
  SelectOneUser,
  ShowCard,
} from 'components/shared';
import { PATHS, TITLES } from 'utils/constants/routes';
import { displayPriceStringFromDollarAmount } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useFormikTextField } from 'hooks/useFormikTextField';
import { useIsOpen } from 'hooks/useIsOpen';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';

// Local Dependencies
import { CreditFormValues } from './CreditForm';

// Local Typings
interface Props {
  errors: FormikErrors<GQL.ICreateFinancialCreditInput>;
  formikValues: CreditFormValues;
  isSubmitting: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  touched: FormikTouched<GQL.ICreateFinancialCreditInput>;
  userName?: string;
  validateForm: () => Promise<FormikErrors<CreditFormValues>>
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));
const StyledStrong = styled.strong({ fontSize: '1.25rem' });

const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Local Components
const PrinceChangeIcon = () => <PriceChangeSvg />;

// Component Definition
const CreditFormFormikForm = ({
  errors,
  formikValues,
  isSubmitting,
  onSubmit,
  touched,
  userName,
  validateForm,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const fundraiserCreditsParams = useSelector(
    tableQueryParams('financialFundraiserCredits'),
  );

  const {
    isOpen: isAddCreditConfirmationDialogOpen,
    toggleIsOpen: toggleAddCreditConfirmationDialog,
  } = useIsOpen();

  const isFormTouched = Object.keys(touched).length > 0;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`);
  }, [financialCreditsParams, navigate]);

  const amountField = useFormikTextField('amountInCents', formikValues.amountInCents.toLocaleString());

  const handleSubmitCreditForm: Props['onSubmit'] = useCallback(async (event) => {
    event?.preventDefault();
    // Manually check for errors before opening the confirmation dialog
    // The value returned from validateForm is the new errors object
    const updatedErrors = await validateForm();

    // We short-circuit if the form has errors after we manually ran validation
    if (Object.values(updatedErrors).length > 0) {
      // Calling `onSubmit` will set all fields
      //  as "touched" so errors will show
      return onSubmit();
    }

    // If we have no errors, open the confirmation dialog
    return toggleAddCreditConfirmationDialog();
  }, [onSubmit, toggleAddCreditConfirmationDialog, validateForm]);

  return (
    <Form onSubmit={handleSubmitCreditForm}>
      <Box marginBottom={2}>
        <ShowCard
          icon={PrinceChangeIcon}
          title={TITLES[PATHS.FINANCIAL_CREDITS_NEW]}
        >
          <StyledTypography
            color="textSecondary"
            paragraph
          >
            Select an organization member and assign a credit amount.
            Please provide a note indicating why this credit is being added.
          </StyledTypography>

          <EnhancedAlert>
            <Typography
              paragraph
              variant="body2"
            >
              Credits are funds that belong to the member and can be applied to their fees.
              {' '}
              Credits are not intended to be used for recording money raised from a fundraiser.
            </Typography>

            <Typography variant="body2">
              To reduce the amount of money owed by a member from a fundraiser,
              please use
              {' '}
              <Link to={`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`}>
                Fundraiser Credits
              </Link>
              .
            </Typography>
          </EnhancedAlert>

          <Box marginY={2}>
            <SelectOneUser userId={formikValues.userId} />
          </Box>

          <Collapse in={Boolean(touched.userId && errors.userId)}>
            <Box marginBottom={2}>
              <EnhancedAlert severity="error">{errors.userId}</EnhancedAlert>
            </Box>
          </Collapse>

          <Box marginBottom={1}>
            <TextField
              InputProps={{
                inputComponent: MoneyInput as any,
                inputProps: {
                  'aria-label': 'Amount',
                },
                startAdornment,
              }}
              label="Amount *"
              variant="filled"
              {...amountField}
            />
          </Box>

          <FinancialAccountSelect />

          <CustomInput
            helperText="This note will be visible on a financial statement."
            label="Note *"
            maxRows={5}
            minRows={2}
            multiline
            name="note"
          />
        </ShowCard>
      </Box>

      <ConfirmationDialog
        confirmButtonAction={onSubmit}
        confirmButtonText="Yes, add credit"
        declineButtonAction={toggleAddCreditConfirmationDialog}
        description={(
          <span>The credit amount of{' '}
            <StyledStrong>
              {displayPriceStringFromDollarAmount(formikValues.amountInCents)}
            </StyledStrong>{' '}
            will be available to apply to payments{' '}
            for <strong>{userName}</strong>.
          </span>
        )}
        handleClose={toggleAddCreditConfirmationDialog}
        isSubmitting={isSubmitting}
        open={isAddCreditConfirmationDialogOpen}
        title="Add Credit?"
      />

      <FormActions
        context="Credit"
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={handlePressCancelOrBackButton}
      />
    </Form>
  );
};

export default CreditFormFormikForm;
