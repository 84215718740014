// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount, getFullName } from 'utils';
import { useGetFinancialFee } from 'gql/queries';

// Local Typings
interface Props {
  paymentInput: GQL.ICreateFinancialPaymentInput;
}

// Component Definition
const PaymentPreviewTableRow = ({
  paymentInput,
}: Props): JSX.Element => {
  const {
    data: financialFeeData,
  } = useGetFinancialFee(paymentInput.financialFeeId);

  const fee = financialFeeData?.financialFee;

  return (
    <TableRow>
      <TableCell>
        {fee
          ? getFullName(fee.user)
          : <CircularProgress />}
      </TableCell>
      <TableCell>
        {fee
          ? fee?.financialItem.label
          : <CircularProgress />}
      </TableCell>
      <TableCell>
        {FinancialPaymentTypes[paymentInput.financialPaymentTypeId as unknown as number]}
      </TableCell>
      <TableCell align="right">
        {displayPriceStringFromDollarAmount(
          convertCentsToDollars(paymentInput.amountInCents),
        )}
      </TableCell>
      <TableCell align="right">
        {displayPriceStringFromDollarAmount(
          convertCentsToDollars(paymentInput.creditAppliedAmountInCents ?? 0),
        )}
      </TableCell>
    </TableRow>
  );
};

export default PaymentPreviewTableRow;
