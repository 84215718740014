// External Dependencies
import {
  Box,
  CircularProgress,
  Divider,
  List,
  Typography,
} from '@mui/material';
import {
  FC,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import CartIcon from 'mdi-material-ui/Cart';
import DownloadIcon from 'mdi-material-ui/Download';
import EmailIcon from '@mui/icons-material/Email';
import FinancesIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/AddCircle';

// Internal Dependencies
import {
  FinancialStatementDialog,
  ListItemWithSecondaryAction,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { RelationshipTypeLabel } from 'components/shared/Selectors/ChildSelect';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { hasPermission } from 'state/self/selectors';
import {
  useGetFinancialFeesIndexWhere,
  useGetRelationshipsByMemberId,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import AddFeeForm from 'pages/People/shared/FeesCard/AddFeeForm';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';

// Local Dependencies
import { isAdultRelationshipType } from 'utils/lib/is_adult_relationship_type';
import FeesList, { StyledStrong } from './FeesList';

// Local Typings
interface Props {
  hasMemberOrgData: boolean;
  isStudent?: boolean;
  memberEmail: string;
  userId: string;
  userName: string;
}

// Component Definition
const FeesCard: FC<Props> = ({
  hasMemberOrgData,
  isStudent = false,
  memberEmail,
  userId,
  userName,
}) => {
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));

  const {
    isOpen: isAddingFee,
    toggleIsOpen: toggleIsAddingFee,
  } = useIsOpen();

  const {
    isOpen: isDownloadStatementDialogOpen,
    toggleIsOpen: toggleDownloadStatementDialog,
  } = useIsOpen();

  const {
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();

  const { data, loading } = useGetFinancialFeesIndexWhere({
    schoolYearEnding: 0,
    userId,
  });

  const { data: relationshipData } = useGetRelationshipsByMemberId({
    memberId: userId,
  });

  // We only show the confirmed relationships
  const confirmedAdultRelationshipEmails: string[] = isStudent
    ? (relationshipData?.userRelationshipsByMemberId ?? [])
      .map((relationship) => {
        if (!relationship.isConfirmed) {
          return '';
        }

        const isSender = relationship.sender.id === userId;

        const otherMember = isSender ? relationship.recipient : relationship.sender;

        const otherRelationshipTypeLabel = isSender
          ? relationship.recipientType.label
          : relationship.senderType.label;

        // TODO: use satisfies when we go to TS 5.0
        const isAdult = isAdultRelationshipType(
          otherRelationshipTypeLabel as RelationshipTypeLabel,
        );

        if (isAdult) {
          return otherMember?.email ?? '';
        }

        return '';
      }).filter(Boolean)
    : [];

  const hasEmails = confirmedAdultRelationshipEmails.length > 0 || Boolean(memberEmail);

  const content = useMemo(() => {
    if (loading) {
      return <CircularProgress />;
    }

    return (
      <FeesList
        fees={data?.financialFeesIndex.data ?? []}
        userName={userName}
      />
    );
  }, [
    data,
    loading,
    userName,
  ]);

  const totalBalanceDueInCents = useMemo(
    () => (data?.financialFeesIndex.data ?? [])
      .reduce((prev, curr) => prev + curr.balanceDueInCents, 0),
    [data],
  );

  const userIds = useMemo(() => [userId], [userId]);

  return (
    <>
      <Box mb={2}>
        <ShowCard
          icon={FinancesIcon}
          title="Finances"
        >
          {content}

          <Box
            display="flex"
            justifyContent="flex-end"
            mb={2}
          >
            <Typography>
              {/* eslint-disable-next-line max-len */}
              Total balance: <StyledStrong>{displayPriceStringFromDollarAmount(convertCentsToDollars(totalBalanceDueInCents))}</StyledStrong>
            </Typography>
          </Box>

          <Divider />

          <List>
            <ListItemWithSecondaryAction
              primaryText="Download statement"
              secondaryAction={{
                buttonIcon: <DownloadIcon />,
                buttonText: 'Download',
                onClick: toggleDownloadStatementDialog,
              }}
              secondaryText="Download a detailed report of fees, payments, and credits for this member."
            />

            <ListItemWithSecondaryAction
              primaryText="Email statement"
              secondaryAction={{
                buttonIcon: <EmailIcon />,
                buttonText: 'Email',
                disabled: !hasEmails,
                onClick: toggleSendStatementDialog,
              }}
              secondaryText={hasEmails
                ? 'Email a financial statement to this member.'
                : 'No email address available for this member or any adult relatives.'}
            />

            {(hasMemberOrgData || totalBalanceDueInCents > 0) && (
              <>
                {canWriteFinances && (
                  <ListItemWithSecondaryAction
                    primaryText="Add a payment"
                    secondaryAction={{
                      buttonIcon: <LocalAtmIcon />,
                      buttonText: 'Payment',
                      isAddButton: true,
                      to: `/${PATHS.FINANCIAL_PAYMENTS_NEW}?user_id=${userId}`,
                    }}
                    secondaryText="Enter additional payments for this member."
                  />
                )}

                {canWriteFinances && !isAddingFee && (
                  <ListItemWithSecondaryAction
                    primaryText="Add a fee"
                    secondaryAction={{
                      buttonIcon: <CartIcon />,
                      buttonText: 'Fee',
                      isAddButton: true,
                      onClick: toggleIsAddingFee,
                    }}
                    secondaryText="Assign another fee to this member."
                  />
                )}
              </>
            )}

          </List>

          {isAddingFee && (
            <>
              <Divider sx={{ marginBottom: 2 }} />

              <AddFeeForm
                memberId={userId}
                onClose={toggleIsAddingFee}
              />
            </>
          )}
        </ShowCard>
      </Box>

      <FinancialStatementDialog
        downloadFileName={`Financial statement - ${userName}`}
        isOpen={isDownloadStatementDialogOpen}
        onClose={toggleDownloadStatementDialog}
        userIds={userIds}
      />

      <SendFinancialStatementDialog
        isStudent={isStudent}
        isOpen={isSendStatementDialogOpen}
        onClose={toggleSendStatementDialog}
        memberId={userId}
      />
    </>
  );
};

export default FeesCard;
