// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import FinancialDepositsTable from './FinancialDepositsTable';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const FinancialDeposits: FC = () => (
  <>
    <Subtitle>
      Financial Deposits
    </Subtitle>

    <Box
      mb={1.5}
      ml={2}
    >
      <StyledTypography
        color="textSecondary"
        variant="body2"
      >
        Deposits consist of financial activity — payments and refunds
      </StyledTypography>
    </Box>

    <Box mb={4}>
      <EnhancedCard>
        <FinancialDepositsTable />
      </EnhancedCard>
    </Box>
  </>
);

export default FinancialDeposits;
