// External Dependencies
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import Box from '@mui/material/Box';

// Internal Dependencies
import { Flex } from 'components/shared';

// Component Definition
export const renderAttachmentCell = (item: number) => (
  <Flex justifyContent="space-between">
    {item > 0 && (
      <Box
        alignItems="center"
        display="flex"
        ml={1}
      >
        <AttachFileOutlinedIcon fontSize="small" />
        {' '}
        ({item})
      </Box>
    )}
  </Flex>
);
