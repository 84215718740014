// External Dependencies
import { Fragment, PureComponent } from 'react';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';

// Local Variables
const propTypes = {
  animationType: PropTypes.oneOf([
    'fade',
    'grow',
    'slide',
  ]),
  children: PropTypes.arrayOf(
    PropTypes.element,
  ).isRequired,
  in: PropTypes.bool,
  interval: PropTypes.number,
  timeout: PropTypes.number,
};

const defaultProps = {
  animationType: 'fade',
  in: true,
  interval: 500,
  timeout: 1000,
};

const defaultTransitionProps = {};
let TransitionComponent = Fragment;

// Component Definition
class TransitionStagger extends PureComponent {
  constructor(props) {
    super(props);

    const {
      animationType,
      children,
      timeout,
    } = props;

    defaultTransitionProps.timeout = timeout;

    switch (animationType) {
      case 'fade':
        TransitionComponent = Fade;
        break;
      case 'grow':
        TransitionComponent = Grow;
        break;
      case 'slide':
        TransitionComponent = Slide;
        break;
      default:
        TransitionComponent = Fade;
    }

    this.state = {
      childrenLength: children.length,
      inCount: 0,
    };
  }

  componentDidMount() {
    this.initStagger(true);
  }

  componentDidUpdate(prevProps) {
    const { in: inProp } = this.props;
    if (prevProps.in !== inProp) {
      this.initStagger(inProp);
    }
  }

  stagger = (increasing) => {
    const {
      childrenLength,
      inCount,
    } = this.state;

    // avoid breaking if developer does not pass in an array
    if (!childrenLength) {
      clearInterval(this.staggerInterval);
    }

    const diff = increasing ? 1 : -1;
    const newInCount = inCount + diff;
    const increaseComplete = increasing && newInCount === childrenLength;
    const decreaseComplete = !increasing && newInCount === 0;

    if (increaseComplete || decreaseComplete) {
      clearInterval(this.staggerInterval);
    }

    this.setState({
      inCount: newInCount,
    });
  };

  initStagger = (increasing) => {
    const { interval } = this.props;
    this.staggerInterval = setInterval(() => this.stagger(increasing), interval);
  };

  render() {
    const {
      children,
      className,
    } = this.props;
    const { inCount } = this.state;

    return (
      <div className={className}>
        {children.map((child, index) => {
          const isIn = inCount > index;
          const transitionProps = { ...defaultTransitionProps };

          if (isIn) {
            transitionProps.in = true;
          }

          return (
            <TransitionComponent
              key={child.props.id}
              {...transitionProps}
            >
              {child}
            </TransitionComponent>
          );
        })}
      </div>
    );
  }
}

TransitionStagger.propTypes = propTypes;
TransitionStagger.defaultProps = defaultProps;

export default TransitionStagger;
