// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { EnhancedAlert } from 'components/shared';

// Local Dependencies
import GettingStartedUI from './GettingStartedUI';

// Component Definition
const GettingStarted = (): JSX.Element => {
  return (
    <>
      <EnhancedAlert
        isTip
        sx={{ marginBottom: 3 }}
        title={`Get started using ${APP_NAME}`}
      >
        Use this guide to get started exploring the {APP_NAME} platform.
        Follow the links to support articles/videos for detailed information on each topic.
      </EnhancedAlert>

      <GettingStartedUI />
    </>
  );
};

export default GettingStarted;
