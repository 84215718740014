// External Dependencies
import {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import { darken, lighten } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Paper, { PaperProps } from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { TableResource, updateTableQueryParams } from 'state/table/actions';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
  formatDate,
  formatDateTime,
  parseSearch,
} from 'utils';
import {
  isTableSelectionActive,
  tableSelection,
} from 'state/table/selectors';
import { navigateSearch } from 'utils/lib/navigate_search';
import { useDenseTable as useDenseTableSelector } from 'state/ui/quickSettingsMenu/selectors';

// Local Dependencies
import { DynamicFieldTypes } from '@presto-assistant/api_types';
import { DynamicFields } from 'types/api';
import { EnhancedIconButton } from '..';
import { IToolbarAction } from '../DataTable/Toolbar';
import { TableColumn, useCheckboxes } from './helpers';
import {
  handleChangePage,
  handleChangeRowsPerPage,
  handleClickSort,
  handleKeyDownSort,
  handleSearch,
} from './eventHandlers';
import Flex from '../Flex';
import IconButtonMenu, { IconButtonMenuProps } from '../IconButtonMenu';
import SubscriberAddButton, { SubscriberAddButtonProps } from '../SubscriberAddButton';
import ToolbarMoreActionsIconMenu from '../DataTable/ToolbarMoreActionsIconMenu';
import ToolbarSearch from '../DataTable/ToolbarSearch';
import ZeroState, { Props as ZeroStateProps } from '../ZeroState';

export * from './helpers';

// Local Typings
export interface PaginationProps {
  limit: number;
  page: number;
}
export interface TableProps<T extends object> {
  Filters?: React.ReactNode;
  actions?: IToolbarAction[];
  addButtonProps?: SubscriberAddButtonProps | null;
  clickRowTo?: (row: T) => string;
  columns: TableColumn<T>[];
  data: T[];
  disableSearch?: boolean;
  excludeZeroStateNeedHelp?: ZeroStateProps['excludeNeedHelp'];
  fullCount: number | undefined;
  hideCheckAll?: boolean;
  isLoading: boolean;
  onUpdateParams?: (updatedQueryParams: object) => void;
  paperProps?: PaperProps;
  params?: string; // Some tables like PeoplePicker will send their own params
  reduxStateKey: TableResource;
  selectAllQuery?: () => void;
  selectedAllIdData?: object[];
  selectionProps?: {
    rightIconElements?: React.ReactElement[];
    withSelection?: boolean;
  };
  settingsMenuProps?: IconButtonMenuProps;
  withPagination?: boolean;
  withSearch?: boolean;
  zeroStateMessage?: ZeroStateProps['message'];
}
interface DisplayCellOptions {
  format: 'date' | 'datetime' | 'money' | 'text';
}
interface StyledPaperProps {
  $hasClickableRow: boolean;
}

// Local Variables
const defaultRowsPerPageOptions = [10, 25, 50, 100, 250];

export const displayCell = (
  value?: string | number | null,
  options: DisplayCellOptions = {
    format: 'text',
  },
) => {
  if (value === '' || value === null || value === undefined) {
    return '—';
  }

  switch (options.format) {
    case 'date':
      return formatDate(value as string);
    case 'datetime':
      return formatDateTime(value as string);
    case 'money':
      return displayPriceStringFromDollarAmount(convertCentsToDollars(value));
    default:
      return value.toString(); // if it is a boolean, we want to return the string
  }
};

export const displayDynamicFieldCell = (
  field: GQL.IDynamicField,
  row: DynamicFields,
) => {
  const fieldTypeId = Number(field.type.id);

  const format: DisplayCellOptions['format'] = fieldTypeId === DynamicFieldTypes.date ? 'date' : 'text';

  return displayCell((row as any)[field.dynamicFieldRef], { format });
};

const stopPropagation = (evt: React.MouseEvent) => {
  evt.stopPropagation();
};

const StyledPaper = styled(Paper)<StyledPaperProps>(({ $hasClickableRow, theme }) => ({
  '.bottomBorder': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
  },
  '.filterRow': {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: theme.spacing(6),
  },
  '.highlight': {
    backgroundColor: theme.palette.mode === 'dark'
      ? darken(theme.palette.prestoSecondary, 0.8)
      : lighten(theme.palette.prestoSecondary, 0.75),
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  '.loading': {
    alignItems: 'center',
    backgroundColor: theme.palette.loadingBackground,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  '.sortableHeader': {
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    cursor: 'pointer',
  },
  '.sortableHeaderLabel': {
    '&:focus, &:hover': {
      color: theme.palette.common.black,
    },
  },
  '.tableCell': {
    whiteSpace: 'nowrap',
  },
  '.tableFooter': {
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
    bottom: 0,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '.tableHead': {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '.tableRow': {
    backgroundColor: theme.palette.grey[50],
    cursor: $hasClickableRow ? 'pointer' : undefined,

    // eslint-disable-next-line sort-keys
    '&:focus, &:hover': {
      backgroundColor: $hasClickableRow
        ? theme.palette.grey[100]
        : undefined,
    },
  },
  '.toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
    left: 0,
    minHeight: 56,
    position: 'sticky',
    right: 0,
  },
  position: 'relative',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
})) as typeof Typography;

// Component Definition
function TableV2<T extends object & { id: string }>(props: TableProps<T>) {
  const {
    Filters,
    actions,
    addButtonProps,
    clickRowTo,
    columns,
    data,
    disableSearch,
    excludeZeroStateNeedHelp,
    fullCount,
    hideCheckAll,
    isLoading,
    onUpdateParams = navigateSearch,
    paperProps,
    params,
    reduxStateKey,
    selectAllQuery,
    selectedAllIdData,
    selectionProps,
    settingsMenuProps,
    withPagination = true,
    withSearch,
    zeroStateMessage,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const { search } = useLocation();

  const useDenseTable = useSelector(useDenseTableSelector);

  // We assign passed-in params first to keep logic simpler everywhere
  const parsedParams = parseSearch(params ?? window.location.search);

  const page = (parsedParams.page ?? 1) - 1;
  const rowsPerPage = (parsedParams.limit) ?? 10;
  const fallbackFullCount = (page + 1) * rowsPerPage;

  const [localData, setLocalData] = useState(data);
  const [localFullCount, setLocalFullCount] = useState(fullCount ?? fallbackFullCount);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(defaultRowsPerPageOptions);
  const [showFilters, setShowFilters] = useState(Boolean(Filters));

  const dispatch = useDispatch();

  const isSelectionActive = useSelector(isTableSelectionActive(reduxStateKey));
  const selection = useSelector(tableSelection(reduxStateKey));

  const {
    handleClearSelection,
    handleSelectAll,
    handleSelectAllOnPage,
    handleSelectItem,
    isChecked,
    isCheckedAll,
    selectionCount,
  } = useCheckboxes({
    fullCount,
    localData,
    reduxStateKey,
    selectAllQuery,
    selectedAllIdData,
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns: columns as any,
    data: localData,
  });

  // remove selection when leaving the page
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => handleClearSelection(), []);

  useEffect(() => {
    if (!isLoading) {
      setLocalData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setLocalFullCount(fullCount ?? fallbackFullCount);
    }
  }, [fallbackFullCount, fullCount, isLoading]);

  useEffect(() => {
    if (reduxStateKey) {
      dispatch(updateTableQueryParams({
        key: reduxStateKey,
        value: params ?? search,
      }));
    }
  }, [dispatch, params, reduxStateKey, search]);

  // do not allow the limit options to have 2 values greater than the full count
  useEffect(() => {
    if (localFullCount > 0) {
      const maxNumIndex = defaultRowsPerPageOptions.findIndex((num) => num > localFullCount);

      const index = maxNumIndex === -1
        ? defaultRowsPerPageOptions.length - 1
        : maxNumIndex;

      const maxPage = defaultRowsPerPageOptions[index];

      // if we have to limit the page size options, be sure we
      // are setting a limit that is available as one of the new options
      if (parsedParams.limit > maxPage) {
        navigateSearch(
          navigate,
          {
            limit: maxPage,
          },
        );
      }

      const newDefaultRowsPerPageOptions = defaultRowsPerPageOptions.slice(0, index + 1);

      setRowsPerPageOptions(newDefaultRowsPerPageOptions);
    }
  }, [localFullCount, navigate, parsedParams.limit]);

  const searchZeroStateMessage = (
    <Typography>
      No matches for{' '}
      <StyledTypography component="span">{parsedParams.q}</StyledTypography>
    </Typography>
  );

  // We have two versions of the toolbar
  // 1. Stock - add button, search, more actions
  // 2. Selection - count, select all button, selection actions
  const stockToolbar = (
    <Collapse
      in={Boolean(!isSelectionActive && (addButtonProps || withSearch || actions?.length))}
      timeout={theme.transitions.duration.shortest}
      unmountOnExit
    >
      <Toolbar
        className={clsx(
          'toolbar',
          !showFilters && 'bottomBorder',
        )}
      >
        <Flex>
          {addButtonProps && (
            <SubscriberAddButton
              {...addButtonProps}
              marginRight={24}
            />
          )}
        </Flex>

        <Flex>
          {withSearch && (
            <ToolbarSearch
              disableSearch={disableSearch || (!parsedParams?.q && !fullCount)}
              onClearSearch={() => handleSearch('', onUpdateParams, navigate)}
              onSearch={(value) => handleSearch(value, onUpdateParams, navigate)}
              searchTerm={parsedParams.q}
            />
          )}

          {settingsMenuProps && <IconButtonMenu {...settingsMenuProps} />}

          {actions && actions.length > 0 && (
            <ToolbarMoreActionsIconMenu moreActions={actions} />
          )}
        </Flex>
      </Toolbar>
    </Collapse>
  );

  const selectionToolbar = (
    <Collapse
      in={Boolean(selectionProps && isSelectionActive)}
      onEnter={() => setShowFilters(false)}
      onExited={() => setShowFilters(Boolean(Filters))}
      timeout={theme.transitions.duration.shortest}
      unmountOnExit
    >
      <Toolbar
        className={clsx(
          'toolbar',
          isSelectionActive && selectionProps && 'highlight',
          !showFilters && 'bottomBorder',
        )}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <EnhancedIconButton
            icon={<CloseIcon />}
            onClick={handleClearSelection}
            tooltip="Clear selection"
            tooltipPlacement="bottom-start"
          />
          {isLoading ? (
            <CircularProgress
              size={16}
              sx={{ ml: 1 }}
            />
          ) : (
            <Typography
              sx={{ ml: 1 }}
              variant="body2"
            >
              {selectionCount} Selected
            </Typography>
          )}

          {selectAllQuery && selectionCount < (fullCount ?? 0) && (
            <Button
              onClick={handleSelectAll}
              size="small"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Select All {fullCount}
            </Button>
          )}
        </Flex>

        <Flex>
          {withSearch && selection.selectionType !== 'SelectedAll' && (
            <ToolbarSearch
              disableSearch={disableSearch || (!parsedParams?.q && !fullCount)}
              onClearSearch={() => handleSearch('', onUpdateParams, navigate)}
              onSearch={(value) => handleSearch(value, onUpdateParams, navigate)}
              searchTerm={parsedParams.q}
            />
          )}
          {selectionProps?.rightIconElements}
        </Flex>
      </Toolbar>
    </Collapse>
  );

  // We show the filter row all of the time if there are filters passed in
  const filterRow = useMemo(() => (
    <Collapse in={Boolean(Filters)}>
      <Toolbar className="filterRow bottomBorder">
        {Filters}
      </Toolbar>
    </Collapse>
  ), [Filters]);

  return (
    <StyledPaper
      $hasClickableRow={Boolean(clickRowTo)}
      {...paperProps}
    >
      {stockToolbar}
      {selectionToolbar}
      {filterRow}

      <TableContainer>
        {rows.length > 0 ? (
          <Table
            {...getTableProps()}
            component="div"
            size={useDenseTable ? 'small' : 'medium'}
          >
            <TableHead
              className="tableHead"
              component="div"
            >
              {headerGroups.map((headerGroup) => {
                const headerGroupKey = headerGroup.headers
                  .map((h) => h.id)
                  .join('-');

                return (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    component="div"
                    key={headerGroupKey}
                  >
                    {selectionProps && (
                      <TableCell
                        aria-label="Selections"
                        className="tableCell"
                        component="div"
                        key="header-checkbox" // needed for react-table library
                        padding="checkbox"
                      >
                        {!hideCheckAll && (
                          <Checkbox
                            checked={isCheckedAll}
                            inputProps={{
                              'aria-label': 'Select All',
                            }}
                            onChange={handleSelectAllOnPage}
                          />
                        )}
                      </TableCell>
                    )}

                    {headerGroup.headers.map((column) => {
                      const { align, sortBy } = column as unknown as TableColumn<T>;

                      const selected = sortBy === parsedParams.orderBy;
                      const sortOrder = parsedParams.asc ? 'asc' : 'desc';
                      const direction = sortBy ? sortOrder : undefined;

                      return (
                        <TableCell
                          {...column.getHeaderProps()}
                          align={align}
                          className={sortBy
                            ? 'sortableHeader tableCell'
                            : 'tableCell'}
                          component="div"
                          key={column.id}
                          onClick={sortBy
                            ? handleClickSort(sortBy, onUpdateParams, navigate, params)
                            : undefined}
                          onKeyDown={sortBy
                            ? handleKeyDownSort(sortBy, onUpdateParams, navigate, params)
                            : undefined}
                          tabIndex={sortBy ? 0 : -1}
                        >
                          {sortBy ? (
                            <Tooltip
                              enterDelay={300}
                              placement="bottom-start"
                              title="Sort"
                            >
                              <TableSortLabel
                                active={selected}
                                className="sortableHeaderLabel"
                                direction={direction}
                                tabIndex={-1}
                              >
                                {column.render('Header')}
                              </TableSortLabel>
                            </Tooltip>
                          ) : column.render('Header')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>

            <TableBody
              {...getTableBodyProps()}
              component="div"
            >
              {rows.map((row) => {
                prepareRow(row);

                const itemId = row.original.id;

                return (
                  <TableRow
                    {...row.getRowProps()}
                    className="tableRow"
                    component="div"
                    key={row.id}
                    onClick={clickRowTo ? () => {
                      navigate(clickRowTo(row.original));
                    } : undefined}
                    onKeyDown={clickRowTo ? (e: React.KeyboardEvent) => {
                      if (e.key === 'Enter' || e.code === 'Space') {
                        navigate(clickRowTo(row.original));
                      }
                    } : undefined}
                    tabIndex={clickRowTo ? 0 : -1}
                  >
                    {selectionProps && (
                      <TableCell
                        className="tableCell"
                        component="div"
                        key={`checkbox-${itemId}`} // needed for react-table library
                        padding="checkbox"
                      >
                        <Checkbox
                          checked={isChecked(itemId)}
                          inputProps={{
                            'aria-label': 'Select item',
                          }}
                          onChange={handleSelectItem(itemId)}
                          onClick={stopPropagation}
                        />
                      </TableCell>
                    )}

                    {row.cells.map((cell) => {
                      const { align } = cell.column as unknown as TableColumn<T>;

                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          align={align}
                          className="tableCell"
                          component="div"
                          key={`${cell.row.id}-${cell.column.id}`}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <ZeroState
            excludeNeedHelp={excludeZeroStateNeedHelp}
            message={parsedParams.q ? searchZeroStateMessage : zeroStateMessage}
          />
        )}
      </TableContainer>

      {rows.length > 0 && withPagination && (
        <TablePagination
          SelectProps={{ inputProps: { 'aria-label': 'Rows per page' } }}
          className="tableFooter"
          component="div"
          count={localFullCount}
          onPageChange={(evt, newPage) =>
            handleChangePage(evt, newPage, onUpdateParams, navigate)}
          onRowsPerPageChange={(evt: ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(evt, onUpdateParams, navigate)}
          page={(parsedParams.page ?? 1) - 1}
          rowsPerPage={parsedParams.limit ?? 10}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}

      {isLoading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </StyledPaper>
  );
}

export default TableV2;
