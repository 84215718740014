// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { getGradeOptions } from 'components/shared/GradeSelect';
import { hasFeatureFlag } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';

// Hook Definition
export const useGetGradeOptions = () => {
  const showAllNumericGrades = useSelector(hasFeatureFlag(FeatureFlag.AllGradeOptions));

  const { data: organizationData, loading } = useGetOrganization();

  const entityTypeId = organizationData
    ? Number(organizationData.organization.entityType.id)
    : 0;

  const gradeOptions = useMemo(
    () => (getGradeOptions(entityTypeId, showAllNumericGrades)),
    [entityTypeId, showAllNumericGrades],
  );

  return useMemo(() => ({
    loading,
    options: gradeOptions,
  }), [gradeOptions, loading]);
};
