// External Dependencies
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// Local Typings
export interface SelectOption {
  id: string;
  label: string | undefined;
  value?: string | undefined;
}

/*
 * Must be used in a MUI DataGrid context.
 *
 * Use the SelectEditInputCell when a cell has a select.
 * We implement "auto-stop" so that the selection of an
 * option will immediately attempt to stop editing that cell.
 *
 */

// Component Definition
const SelectEditInputCell = ({
  options,
  params,
}: {
  options: SelectOption[] | undefined,
  params: GridRenderEditCellParams,
}) => {
  const apiRef = useGridApiContext();

  const {
    field,
    id,
    value,
  } = params;

  if (!options) {
    return null;
  }

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      field,
      id,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ field, id });
  };

  return (
    <Select
      autoFocus
      native
      onChange={handleChange}
      size="small"
      sx={{ width: '100%' }}
      value={value}
    >
      {options.map(({ id: optionId, label: optionLabel }) => (
        <option
          key={optionId}
          value={optionLabel}
        >
          {optionLabel}
        </option>
      ))}
    </Select>
  );
};

export default SelectEditInputCell;
