// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const FormsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="There are no forms to display."
  />
);

export default FormsTableZeroState;
