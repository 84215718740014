// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import EmailDraftsTable from './EmailDraftsTable';

// Component Definition
const EmailDrafts: FC = () => (
  <>
    <Subtitle>
      Email Drafts
    </Subtitle>
    <EnhancedCard>
      <EmailDraftsTable />
    </EnhancedCard>
  </>
);

export default EmailDrafts;
