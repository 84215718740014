// External Dependencies
import { Form, Formik } from 'formik';
import {
  createFinancialAccountSchema,
  updateFinancialAccountSchema,
} from '@presto-assistant/api_types/schemas/financialAccounts';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomInput,
  FormActions,
  OnlinePaymentsLearnMoreLink,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { hasFeatureFlag } from 'state/self/selectors';
import { useHasEnabledOnlinePaymentsForOrg } from 'hooks/useHasEnabledOnlinePaymentsForOrg';
import { useSelector } from 'react-redux';
import FinancialPaymentProviderAccountSelect
  from 'components/shared/FinancialPaymentProviderAccountSelect';
import OnlinePaymentsAlert from './OnlinePaymentsAlert';

// Local Typings
interface Props {
  initialValues?: GQL.ICreateFinancialAccountInput;
  onSubmit: (values: GQL.ICreateFinancialAccountInput) => void;
}

// Local Variables
const StyledCustomCheckbox = styled(CustomCheckbox)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    marginTop: theme.spacing(1),
  },
  '.MuiFormControlLabel-root': {
    alignItems: 'flex-start',
    display: 'flex',
  },
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

// Component Definition
const AccountForm = ({
  initialValues,
  onSubmit,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    areOnlinePaymentsEnabled,
  } = useIsOnlinePaymentsFeatureAvailable();

  const hasMultiplePaymentProvidersFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const areOnlinePaymentsEnabledForOrg = useHasEnabledOnlinePaymentsForOrg();

  const [
    localFormValues,
    setLocalFormValues,
  ] = useState<GQL.ICreateFinancialAccountInput>(
    initialValues ?? {
      canApplyFundraiserCredits: false,
      financialPaymentProviderAccountId: '',
      label: '',
      onlinePaymentsEnabled: false,
    },
  );

  const {
    isOpen: isAddAccountConfirmationDialogOpen,
    toggleIsOpen: toggleAddAccountConfirmationDialog,
  } = useIsOpen();

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_OVERVIEW}`);
  }, [navigate]);

  const handleFormikSubmit = useCallback(async (
    values: GQL.ICreateFinancialAccountInput,
  ) => {
    setLocalFormValues(values);

    toggleAddAccountConfirmationDialog();
  }, [toggleAddAccountConfirmationDialog]);

  const handleConfirmSubmit = useCallback(() => {
    const payload = {
      ...localFormValues,
    };

    if (hasMultiplePaymentProvidersFeatureFlag) {
      delete payload.onlinePaymentsEnabled;
    } else {
      delete payload.financialPaymentProviderAccountId;
    }

    if (payload.financialPaymentProviderAccountId === '') {
      payload.financialPaymentProviderAccountId = null;
    }

    onSubmit(payload);
  }, [hasMultiplePaymentProvidersFeatureFlag, localFormValues, onSubmit]);

  const isEditing = Boolean(initialValues?.label);

  return (
    <Formik<GQL.ICreateFinancialAccountInput>
      enableReinitialize
      initialValues={localFormValues}
      onSubmit={handleFormikSubmit}
      validationSchema={isEditing ? updateFinancialAccountSchema : createFinancialAccountSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <ShowCard
              icon={AccountBalance}
              title={isEditing ? 'Update Financial Account' : 'New Financial Account'}
            >
              <Typography
                color="textSecondary"
                paragraph
              >
                Enter details for the financial account.
              </Typography>

              <CustomInput
                label="Name"
                name="label"
              />

              {hasMultiplePaymentProvidersFeatureFlag ? (
                <FinancialPaymentProviderAccountSelect />
              ) : (
                <StyledCustomCheckbox
                  disabled={!areOnlinePaymentsEnabled}
                  label={(
                    <>
                      <Typography>
                        Allow Online Payments
                      </Typography>

                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        Online payments allow parents to pay fees for this account online.
                        {' '}
                        <OnlinePaymentsLearnMoreLink />
                      </Typography>
                    </>
                  )}
                  name="onlinePaymentsEnabled"
                />
              )}

              <CustomCheckbox
                label="Can Apply Fundraiser Credits"
                name="canApplyFundraiserCredits"
              />

              {!areOnlinePaymentsEnabledForOrg && (
                <Box marginTop={2}>
                  <OnlinePaymentsAlert />
                </Box>
              )}
            </ShowCard>

            <ConfirmationDialog
              confirmButtonAction={handleConfirmSubmit}
              confirmButtonText={isEditing
                ? 'Yes, update account'
                : 'Yes, add account'}
              declineButtonAction={toggleAddAccountConfirmationDialog}
              description={isEditing
                ? 'Your changes will be saved and you can continue assigning items to this account.'
                : 'You will be able to begin assigning items to this account.'}
              handleClose={toggleAddAccountConfirmationDialog}
              isSubmitting={isSubmitting}
              open={isAddAccountConfirmationDialogOpen}
              title={isEditing ? 'Update Account?' : 'Add Account?'}
            />

            <FormActions
              context="Account"
              isEditing={isEditing}
              isFormTouched={isFormTouched}
              isSubmitting={isSubmitting}
              onPressCancelOrBackButton={handlePressCancelOrBackButton}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountForm;
