// External Dependencies
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';
import { useUpdateOrganization } from 'gql/mutations';

// Component Definition
const EmailSettings: FC = () => {
  const { data: organizationData } = useGetOrganization();

  const organization = organizationData?.organization;

  const [ccDirectors, setCcDirectors] = useState(Boolean(organization?.ccDirectors));

  useEffect(() => {
    setCcDirectors(Boolean(organization?.ccDirectors));
  }, [organization?.ccDirectors]);

  const [updateOrganization] = useUpdateOrganization();

  const handleChangeCcDirectors = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;

    setCcDirectors(isChecked);

    if (organization) {
      updateOrganization({
        variables: {
          addressOne: organization.addressOne,
          addressTwo: organization.addressTwo,
          ccDirectors: isChecked,
          city: organization.city,
          code: organization.code,
          entityTypeId: organization.entityType.id,
          label: organization.label,
          phoneNumber: organization.phoneNumber,
          stateId: organization.stateId,
          zipcode: organization.zipcode,
        },
      });
    }
  }, [organization]);

  return (
    <>
      <Typography>
        Email Settings
      </Typography>

      <FormControlLabel
        control={(
          <Switch
            checked={ccDirectors}
            color="primary"
            inputProps={{ 'aria-label': 'CC Directors', name: 'ccDirectors' }}
            onChange={handleChangeCcDirectors}
          />
        )}
        label="CC Directors on all emails"
        labelPlacement="start"
      />
    </>
  );
};

export default EmailSettings;
