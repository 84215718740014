// External Dependencies
import { FC } from 'react';
import CardActions from '@mui/material/CardActions';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedContainer,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetOrganization } from 'gql/queries';
import RevokeStripeButton from 'components/shared/RevokeStripeButton';
import StripeOrgSignUpButton from 'components/AlertButtons/StripeOrgSignUpButton';

// Local Dependencies
import CoverOnlineProcessingFeeSetting from '../shared/CoverOnlineProcessingFeeSetting';

// Local Variables
const StyledEnhancedContainer = styled(EnhancedContainer)({
  margin: '0 auto',
  padding: 0,
});

// Component Definition
const StripeSettingsPage: FC = () => {
  // EmailSettings uses organization, so we want to be sure this loads before rendering
  const { data: organizationData, loading: isLoadingOrganization } = useGetOrganization();

  const hasStripeId = organizationData?.organization?.hasStripeId;

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.SETTINGS}`,
      }}
      isLoading={isLoadingOrganization}
    >
      <StyledEnhancedContainer>
        <ShowCard
          cardActions={(
            <CardActions>
              {hasStripeId
                ? <RevokeStripeButton />
                : <StripeOrgSignUpButton />}
            </CardActions>
          )}
          icon={PriceChangeIcon}
          title="Stripe Settings"
        >
          {hasStripeId ? (
            <CoverOnlineProcessingFeeSetting
              alert={(
                <EnhancedAlert title="Cover Stripe fees">
                  <Typography
                    color="inherit"
                    fontSize="inherit"
                    paragraph
                  >
                    Your organization can cover Stripe fees for members, eliminating their
                    {' '}
                    payment processing fee. You can override this on a per item basis.
                  </Typography>

                  <Typography
                    color="inherit"
                    fontSize="inherit"
                    paragraph
                  >
                    When covering the fees, incorporate them into your pricing.
                    {' '}
                    The fees amount to 2.9% + $0.30 per transaction.
                  </Typography>
                </EnhancedAlert>
              )}
              paymentProvider="Stripe"
            />
          ) : (
            <Typography>
              You have not connected your organization to Stripe.
            </Typography>
          )}
        </ShowCard>
      </StyledEnhancedContainer>
    </Page>
  );
};

export default StripeSettingsPage;
