// External Dependencies
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getToken } from 'utils/cookies';
import Maintenance from 'pages/Maintenance';

// Local Dependencies
import PrivateRouteContainer from './PrivateRouteContainer';
import SimpleContainer from './SimpleContainer';

// Local Variables
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
});

// Component Definition
const MainContentContainer = (): JSX.Element => {
  const classes = useStyles();

  const { pathname } = useLocation();

  // If the pathname changes, we want to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isMaintenanceMode = getIsMaintenanceMode();

  if (isMaintenanceMode) {
    return (
      <div className={classes.root}>
        <SimpleContainer>
          <Maintenance />
        </SimpleContainer>
      </div>
    );
  }

  const showSimpleContainer = !getToken();

  return (
    <div className={classes.root}>
      {showSimpleContainer
        ? (
          <SimpleContainer>
            <Outlet /> {/* This renders the nested routes */}
          </SimpleContainer>
        ) : (
          <PrivateRouteContainer>
            <Outlet /> {/* This renders the nested routes */}
          </PrivateRouteContainer>
        )}
    </div>
  );
};

export default MainContentContainer;
