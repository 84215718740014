// External Dependencies
import { MyGroupResponseItem } from '@presto-assistant/api_types/api/v1/group';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';

// Local Dependencies
export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<MyGroupResponseItem>[] = [
      {
        field: 'label',
        headerName: 'Title',
        width: 160,
      },
    ];

    return columns;
  }, []);
};
