// External Dependencies
import { Form, Formik } from 'formik';
import {
  useCallback, useEffect, useState,
} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import {
  CustomInput,
  SaveButton,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import {
  useCreateAuthEmailChangeRequest,
  useUpdateAuthEmail,
} from 'gql/mutations';
import { useDispatch } from 'react-redux';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogEditUsername = ({
  isOpen,
  onClose,
}: Props): JSX.Element | null => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    dispatch(addNotification('Login email updated!', 'success'));
    onClose();
  }, [dispatch, onClose]);

  const handleGoBack = useCallback(() => {
    setStep(1);
  }, []);

  const [
    createAuthEmailChangeRequest,
    { loading: isCreatingAuthEmailChangeRequest },
  ] = useCreateAuthEmailChangeRequest({
    onCompleted: () => {
      setStep(2);
    },
  });

  const [
    updateAuthEmail,
    { loading: isUpdatingAuthEmail },
  ] = useUpdateAuthEmail({
    onCompleted: handleSuccess,
    refetchQueries: [{ query: GET_SELF }],
  });

  const { self } = useSelfQuery();

  useEffect(() => {
    if (isOpen) {
      setEmail('');
      setStep(1);
    }
  }, [isOpen]);

  if (!self) {
    return null;
  }

  const {
    authUserEmail,
  } = self;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
    >
      <DialogTitle>
        {step === 1 && 'Enter New Email'}
        {step === 2 && 'Enter Verification Code'}
      </DialogTitle>

      <DialogContent sx={{ marginY: 0, paddingY: 0 }}>
        <DialogContentText>
          {step === 1
            ? `${authUserEmail} is your current username. Please enter the new email you would like to use.`
            : `Enter your password and the verification code that was emailed to ${email}.`}
        </DialogContentText>
      </DialogContent>

      {step === 1 ? (
        <Formik<GQL.ICreateAuthEmailChangeRequestInput>
          enableReinitialize
          initialValues={{
            email,
          }}
          key="createAuthEmailChangeRequest"
          onSubmit={(values) => {
            setEmail(values.email);

            createAuthEmailChangeRequest({
              variables: {
                input: values,
              },
            });
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <CustomInput
                  label="Email"
                  name="email"
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>
                  Cancel
                </Button>

                <SaveButton
                  isSaving={isCreatingAuthEmailChangeRequest}
                >
                  Request Change
                </SaveButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik<GQL.IUpdateAuthEmailInput>
          initialValues={{
            email,
            password: '',
            verificationCode: '',
          }}
          key="updateAuthEmail"
          onSubmit={(values) => {
            updateAuthEmail({
              variables: {
                input: values,
              },
            });
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <CustomInput
                  label="Password"
                  name="password"
                  required
                  type="password"
                />

                <CustomInput
                  label="Verification Code"
                  name="verificationCode"
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={handleGoBack}>
                  Go Back
                </Button>

                <SaveButton
                  isSaving={isUpdatingAuthEmail}
                >
                  Submit Code
                </SaveButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default DialogEditUsername;
