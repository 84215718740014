// External Dependencies
import { useQueryClient } from '@tanstack/react-query';
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateDynamicFieldResponse {
  createDynamicField: GQL.IDynamicField;
}

interface DeleteDynamicFieldResponse {
  deleteDynamicField: boolean;
}

interface UpdateDynamicFieldResponse {
  updateDynamicField: GQL.IUpdateDynamicFieldResponse;
}

const CREATE_DYNAMIC_FIELD = gql`
  mutation CreateDynamicField(
    $input: CreateDynamicFieldInput!
  ) {
    createDynamicField(
      input: $input
    ) {
      dynamicFieldRef
      id
      label
      tableRef
      organizationType {
        id
        label
      }
      type {
        id
        label
      }
    }
  }
`;

const DELETE_DYNAMIC_FIELD = gql`
  mutation DeleteDynamicField($id: ID!) {
    deleteDynamicField(id: $id)
  }
`;

const UPDATE_DYNAMIC_FIELD = gql`
  mutation UpdateDynamicField(
    $id: ID!
    $input: UpdateDynamicFieldInput!
  ) {
    updateDynamicField(
      id: $id
      input: $input
    ) {
      dynamicField {
        dynamicFieldRef
        id
        label
        tableRef
        organizationType {
          id
          label
        }
        type {
          id
          label
        }
      }
      errors {
        id
        value
      }
    }
  }
`;

export function useCreateDynamicField(
  options?: MutationOptions<
    CreateDynamicFieldResponse,
    GQL.ICreateDynamicFieldOnMutationArguments
  >,
) {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
    CreateDynamicFieldResponse,
    GQL.ICreateDynamicFieldOnMutationArguments
  >(
    CREATE_DYNAMIC_FIELD,
    {
      ...options,
      onCompleted: (data) => {
        queryClient.invalidateQueries(['dynamicField']);

        options?.onCompleted?.(data);
      },
    },
  );
}

export function useDeleteDynamicField(
  options?: MutationOptions<
    DeleteDynamicFieldResponse,
    GQL.IDeleteDynamicFieldOnMutationArguments
  >,
) {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
    DeleteDynamicFieldResponse,
    GQL.IDeleteDynamicFieldOnMutationArguments
  >(
    DELETE_DYNAMIC_FIELD,
    {
      ...options,
      onCompleted: (data) => {
        queryClient.invalidateQueries(['dynamicField']);

        options?.onCompleted?.(data);
      },
    },
  );
}

export const useUpdateDynamicField = (
  options?: MutationOptions<
    UpdateDynamicFieldResponse,
    GQL.IUpdateDynamicFieldOnMutationArguments
  >,
) => {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
  UpdateDynamicFieldResponse,
  GQL.IUpdateDynamicFieldOnMutationArguments
  >(
    UPDATE_DYNAMIC_FIELD,
    {
      ...options,
      onCompleted: (data) => {
        queryClient.invalidateQueries(['dynamicField']);

        options?.onCompleted?.(data);
      },
    },
  );
};
