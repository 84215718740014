// External Dependencies
import { FC } from 'react';
import { lighten } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { isDesktopScreenSize } from 'state/device/selectors';
import {
  isDistrictAdmin as isDistrictAdminSelector,
} from 'state/self/selectors';
import Flex from 'components/shared/Flex';

// Local Dependencies
import AlertHandler from './AlertHandler';
import GlobalSettingsMenu from './GlobalSettingsMenu';
import Heading1 from '../Heading1';
import HelpMenu from './HelpMenu';
import PrestoLogoOnlySvg from '../PrestoLogoOnlySvg';
import ProfileMenu from './ProfileMenu';

// Local Typings
interface Props {
  onClickHamburger: () => void;
  self: GQL.ISelf;
  title: string;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.hamburger': {
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-10px)',
    },
    transform: 'translateX(-12px)',
  },
  '.hamburgerIcon': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1.5rem',
    },
  },
  '.helpMenuContainer': {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    marginRight: theme.spacing(1),
  },
  '.rightElements': {
    alignItems: 'center',
    display: 'flex',
  },
  '.settingsMenuContainer': {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginRight: theme.spacing(1.5),
  },
  '.title': {
    // On a small screen a longer title will force the entire layout
    //  to shift off of the screen, so we truncate it
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 132,
      padding: theme.spacing(1, 0),
    },
    alignSelf: 'center',
  },

  [theme.breakpoints.down('md')]: {
    minHeight: theme.spacing(6),
    padding: `0 ${theme.spacing(2)}`,
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: `0 ${theme.spacing(2)}`,
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    minHeight: theme.spacing(7),
  },
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.mainContentBackground : theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.divider}`,
  // box-shadow borrowed from the Google Play Console
  boxShadow: theme.palette.mode === 'light' ? `0 5px 5px -5px ${lighten(theme.palette.divider, 0.5)}` : '',
  display: 'flex',
  flex: '0 0 auto',
  height: theme.spacing(8),
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  padding: `0 ${theme.spacing(3)}`,
}));

// Component Definition
const TitleBar: FC<Props> = ({
  onClickHamburger,
  self,
  title = '',
}) => {
  const isDistrictAdmin = useSelector(isDistrictAdminSelector);
  const isDesktopScreen = useSelector(isDesktopScreenSize);

  return (
    <>
      {!isDistrictAdmin && <AlertHandler self={self} />}

      <StyledRoot>
        {isDesktopScreen ? (
          <Typography
            component="h2"
            variant="h6"
          >
            {title}
          </Typography>
        ) : (
          <Flex>
            <IconButton
              className="hamburger"
              onClick={onClickHamburger}
              size="small"
            >
              <MenuIcon className="hamburgerIcon" />
            </IconButton>

            <Heading1>
              <PrestoLogoOnlySvg />
            </Heading1>

            <Typography
              className="title"
              component="h2"
              variant="h6"
            >
              {title}
            </Typography>
          </Flex>
        )}

        <div className="rightElements">
          <Box className="helpMenuContainer">
            <HelpMenu />
          </Box>

          <Box className="settingsMenuContainer">
            <GlobalSettingsMenu />
          </Box>

          <ProfileMenu self={self} />
        </div>
      </StyledRoot>
    </>
  );
};

export default TitleBar;
