// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import ZeroState, { Props as ZeroStateProps } from '../ZeroState';

// Local Variables
const StyledZeroState = styled(ZeroState)(({ theme }) => ({
  '& .MuiDialogContentText-root': {
    marginTop: theme.spacing(2.5),
    textAlign: 'center',
  },

  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  position: 'relative',
  zIndex: 1,
}));

// We can reuse the ZeroState from our old tables, but we have use different
//  styles to allow interaction with the ContactUs link

// Component Definition
const TableDataGridZeroState: FC<ZeroStateProps> = ({
  Icon,
  excludeNeedHelp,
  maxTextWidth,
  message,
}) => (
  <StyledZeroState
    Icon={Icon}
    excludeNeedHelp={excludeNeedHelp}
    maxTextWidth={maxTextWidth}
    message={message}
  />
);

export default TableDataGridZeroState;
