// External Dependencies
import { Typography } from '@mui/material/';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const HowWeProtectIt = () => (
  <>
    <InfoTitle>
      How do we protect your information?
    </InfoTitle>

    <InfoSection>
      <Typography>
        {/* eslint-disable-next-line max-len */}
        {APP_NAME} uses modern, industry-standard technologies to ensure your protection. Access to these technologies is limited only to the {APP_NAME} employees who help manage these services. User passwords in the database are hashed (one-way encryption), so any employees with access to that data will not be able to view or decrypt your password.
      </Typography>
    </InfoSection>
  </>
);

export default HowWeProtectIt;
