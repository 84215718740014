// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { ContextData } from 'types/context';
import {
  addBannerAlert,
  clearAllAlerts,
  clearAllBannerAlerts,
  removeBannerAlert,
} from './actions';
import {
  logoutCurrentUser,
} from '../self/actions';

// Local Typings
export interface BannerAlertsState {
  data: BannerAlertsDataState;
}
interface BannerAlertsDataState {
  [items: string]: ContextData.IAlertDetail[];
}

// Reducers
const BANNER_ALERTS_INITIAL_STATE: BannerAlertsDataState = { items: [] };
const data = createReducer(BANNER_ALERTS_INITIAL_STATE, (handleAction) => [
  handleAction(addBannerAlert, (state: BannerAlertsDataState, { payload }) => {
    if (payload) {
      return {
        items: [...state.items, payload],
      };
    }
    return state;
  }),
  handleAction(
    [clearAllAlerts, clearAllBannerAlerts, logoutCurrentUser],
    () => BANNER_ALERTS_INITIAL_STATE,
  ),
  handleAction(
    removeBannerAlert,
    (state: BannerAlertsDataState, { payload }): BannerAlertsDataState => {
      if (state.items.length > 0 && payload) {
        // Find the provided alert id and remove from data state
        const updatedData: ContextData.IAlertDetail[] = state.items.filter(
          (alert: ContextData.IAlertDetail) => alert.id !== payload,
        );

        return {
          items: updatedData,
        };
      }

      return state;
    },
  ),
]);

export default combineReducers<BannerAlertsState>({ data });
