// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { displayFileSize } from 'utils/lib/display_file_size';
import { formatDateTime } from 'utils';
import {
  useGetMyFileSignedUrl,
  useGetMyFilesIndex,
} from 'gql/queries';
import TableV2, { TableColumn, createActionsColumn } from 'components/shared/TableV2';

// Local Dependencies
import Filters from './Filters';

// Local Typings
interface Props {
  schoolYearEnding: number | null;
  userId: string | null;
}

// Component Definition
const MyFilesTableUI: FC<Props> = ({
  schoolYearEnding,
  userId,
}) => {
  const [getMySignedUrl] = useGetMyFileSignedUrl();

  const {
    data,
    loading,
  } = useGetMyFilesIndex(
    schoolYearEnding,
    userId,
  );

  const handleClickDownload = (row: GQL.IMyFile) => {
    getMySignedUrl({
      variables: {
        fileId: row.id,
        userId,
      },
    });
  };

  const uploadedByAccessor = ({ createdByName }: GQL.IMyFile) => (
    <Typography variant="body2">
      {createdByName}{createdByName === userId
        ? (
          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
          >
            {' '}(You)
          </Typography>
        )
        : ''}
    </Typography>
  );

  const columns: TableColumn<GQL.IMyFile>[] = [
    {
      Header: 'Name',
      accessor: (item) => item.fileName,
      sortBy: 'fileName',
    },
    {
      Header: 'Uploaded',
      accessor: (item) => formatDateTime(item.createdAt),
      sortBy: 'createdAt',
    },
    {
      Header: 'Uploaded By',
      accessor: (item) => uploadedByAccessor(item),
      sortBy: 'createdByFirstName',
    },
    {
      Header: 'Size',
      accessor: (item) => displayFileSize(item.fileSize),
      sortBy: 'fileSize',
    },
  ];

  const actionColumn = createActionsColumn<GQL.IMyFile>([
    {
      action: handleClickDownload,
      icon: <DownloadIcon />,
      text: 'Download',
    },
  ]);

  if (actionColumn) {
    columns.push(actionColumn);
  }

  return (
    <TableV2<GQL.IMyFile>
      Filters={<Filters />}
      columns={columns}
      data={data?.myFilesIndex.data ?? []}
      fullCount={data?.myFilesIndex.fullCount}
      isLoading={loading}
      paperProps={{ variant: 'outlined' }}
      reduxStateKey="myFiles"
      withSearch
      zeroStateMessage="Files will show here when your directors share them with you."
    />
  );
};

export default MyFilesTableUI;
