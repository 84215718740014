// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DistrictLibaryCategoriesOnQueryArguments {
  organizationId?: string;
}
interface DistrictLibaryCategoriesResponse {
  dfaLibraryCategories: Array<GQL.ILibraryCategory>
}

const DISTRICT_LIBRARY_CATEGORIES_SELECT_OPTIONS_QUERY = gql`
  query DistrictLibraryCategoriesSelectOptons(
    $organizationId: ID!
  ) {
    dfaLibraryCategories(
      organizationId: $organizationId
    ) {
      id
      label
    }
  }
`;

export const useGetDistrictLibraryCategories = (
  args: DistrictLibaryCategoriesOnQueryArguments,
) =>
  useQueryEnhanced<
    DistrictLibaryCategoriesResponse,
    DistrictLibaryCategoriesOnQueryArguments
  >(
    DISTRICT_LIBRARY_CATEGORIES_SELECT_OPTIONS_QUERY,
    {
      skip: !args.organizationId,
      variables: args,
    },
  );
