// External Dependencies
import AccountMultipleCheckOutline from 'mdi-material-ui/AccountMultipleCheckOutline';

// Internal Dependencies
import { ShowCard } from 'components/shared';

// Local Dependencies
import InventoryCheckoutHistoryList from '../shared/InventoryCheckoutHistoryList';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const InventoryCheckoutCard = ({
  inventoryItemData,
}: Props): JSX.Element => {
  return (
    <ShowCard
      icon={AccountMultipleCheckOutline}
      title="Checkout History"
    >
      <InventoryCheckoutHistoryList inventoryItem={inventoryItemData} />
    </ShowCard>
  );
};

export default InventoryCheckoutCard;
