// Internal Dependencies
import { ROLES, ROLE_IDS } from 'utils/constants';

export const isSelfAdminInCurrentOrg = (self: GQL.ISelf): boolean => {
  const currentOrg = self.userOrgData.find(
    (org: GQL.IUserOrganization) => org?.organizationId === self.currentOrgId
      && org?.member?.id === self.id,
  );

  return Boolean(currentOrg?.admin);
};

export const isDirectorAdminInCurrentOrg = (self: GQL.ISelf): boolean =>
  self.role?.id === ROLE_IDS[ROLES.ADULT] && isSelfAdminInCurrentOrg(self);
