// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { TableColumn, displayCell } from 'components/shared/TableV2';

export const useColumns = (
  extraColumns?: TableColumn<GQL.IUser>[],
): TableColumn<GQL.IUser>[] => useMemo(() => {
  const columns: TableColumn<GQL.IUser>[] = [
    {
      Header: 'First Name',
      accessor: (row) => displayCell(row.firstName),
      sortBy: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: (row) => displayCell(row.lastName),
      sortBy: 'last_name',
    },
    {
      Header: 'Email',
      accessor: (row) => displayCell(row.email),
      sortBy: 'email',
    },
    {
      Header: 'Role',
      accessor: (row: any) => displayCell(row.role.label),
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
