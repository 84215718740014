// External Dependencies
import { useMemo } from 'react';

// Local Dependencies
import { GET_ORGANIZATION } from 'gql/queries';
import { useQueryEnhanced } from 'utils/lib/graphql';
import useSelfQuery from './useSelfQuery';

// Hook Definition
const useCanUseDynamicFields = () => {
  const { self } = useSelfQuery();

  const { data: orgData } = useQueryEnhanced<{
    organization: GQL.IOrganization
  }>(
    GET_ORGANIZATION,
    {
      skip: !self || Boolean(!self?.currentOrgId),
    },
  );

  // Dynamic fields can be used by:
  //  • a district administrator
  //  • an organization that belongs to a district
  const canUseDynamicFields = useMemo(
    () =>
      (self?.hasDistrict && !self?.currentOrgId)
    || Boolean(orgData?.organization.district),
    [orgData, self],
  );

  return canUseDynamicFields;
};

export default useCanUseDynamicFields;
