// External Dependencies
import { FormBlockTypes, FormBlockTypesSemantic } from '@presto-assistant/api_types';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import isEqual from 'lodash.isequal';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomCheckbox, CustomInput, EnhancedAlert, ShowPageDataDisplay,
} from 'components/shared';
import { apiClient } from 'utils/apiClient';
import FormBlockTypeSelect from 'components/shared/Selectors/FormBlockTypeSelect';
import InputWithChips from 'components/shared/InputWithChips';
import UploadDropzone from 'components/shared/UploadDropzone';

// Local Typings
interface Props {
hasSelectedType: boolean;
  values: FormValues;
}

export interface FormValues {
  adultInitials: boolean | undefined;
  adultSignature: boolean | undefined;
  content: string;
  formBlockTypeId: number;
  formId: string;
  isRequired: boolean;
  label: string;
  options: string[];
  position: number;
  s3Filename: string;
  s3Url: string;
}

// Local Variables
const StyledSelectWrapper = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-inputRoot': {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(3),
  },

  paddingTop: theme.spacing(1),
}));

const shouldRenderLabel = (formBlockTypeId: number) => {
  switch (formBlockTypeId) {
    case FormBlockTypes.Heading:
    case FormBlockTypes.Paragraph:
      return false;
    default:
      return true;
  }
};

// Component Definition
const NewBlockFormInputs = ({
  hasSelectedType,
  values,
}: Props): JSX.Element => {
  const formBlockTypeId = Number(values.formBlockTypeId);

  const [chips, setChips] = useState<string[]>([]);
  const [filename, setFilename] = useState<string | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);

  const formikContext = useFormikContext<FormValues>();

  useEffect(() => {
    if (!isEqual(chips, values.options)) {
      formikContext.setFieldValue('options', chips);
    }
    // not passing formikContext as it causes rendering issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips, values.options]);

  useEffect(() => {
    // set undefined values to false
    if (formBlockTypeId === FormBlockTypes.Signature) {
      formikContext.setFieldValue('adultSignature', false);
    } else if (formBlockTypeId === FormBlockTypes.Initials) {
      formikContext.setFieldValue('adultInitials', false);
    } else if (formBlockTypeId === FormBlockTypes.FileDownload) {
      formikContext.setFieldValue('s3Url', '');
      formikContext.setFieldValue('s3Filename', '');
      setHasUploadError(false);
      setFilename(null);
    } else {
      formikContext.setFieldValue('adultSignature', undefined);
      formikContext.setFieldValue('adultInitials', undefined);
    }
    // not passing formikContext as it causes rendering issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBlockTypeId]);

  const handleTheDrop = async (droppedFiles: File[]) => {
    try {
      setHasUploadError(false);
      setIsUploadingFile(true);

      const {
        data: {
          data: {
            getUrl,
            putUrl,
          },
        },
      } = await apiClient.v1.form.getPresignedUrls({
        body: {
          filename: droppedFiles[0].name,
        },
        params: {
          formId: values.formId,
        },
      });

      const s3Response = await fetch(putUrl, {
        body: droppedFiles[0],
        method: 'PUT',
      });

      if (!s3Response.ok) {
        throw new Error('Unable to upload file.');
      }

      formikContext.setFieldValue('s3Url', getUrl);
      formikContext.setFieldValue('s3Filename', droppedFiles[0].name);

      setFilename(droppedFiles[0].name);
    } catch (s3UploadError) {
      setHasUploadError(true);
    } finally {
      setIsUploadingFile(false);
    }
  };

  return hasSelectedType ? (
    <>
      <ShowPageDataDisplay
        label="Type"
        value={FormBlockTypesSemantic[formBlockTypeId]}
      />

      {shouldRenderLabel(formBlockTypeId) && (
        <CustomInput
          label="Label"
          name="label"
        />
      )}

      {formBlockTypeId === FormBlockTypes.Heading && (
        <CustomInput
          label="Heading"
          name="content"
        />
      )}

      {formBlockTypeId === FormBlockTypes.Paragraph && (
        <CustomInput
          label="Paragraph"
          multiline
          name="content"
          rows={4}
        />
      )}

      {formBlockTypeId === FormBlockTypes.Select && (
        <StyledSelectWrapper>
          <InputWithChips
            chips={chips}
            label="Options"
            name="options"
            onUpdateChips={setChips}
          />
        </StyledSelectWrapper>
      )}

      {formBlockTypeId === FormBlockTypes.Signature && (
        <div>
          <CustomCheckbox
            label="Only adult relatives can sign"
            name="adultSignature"
          />
        </div>
      )}

      {formBlockTypeId === FormBlockTypes.Initials && (
        <div>
          <CustomCheckbox
            label="Only adult relatives can initial"
            name="adultInitials"
          />
        </div>
      )}

      {formBlockTypeId === FormBlockTypes.FileDownload && !filename && (
        <div>
          <UploadDropzone
            acceptedFileTypes={['*']}
            disabled={isUploadingFile}
            handleDrop={handleTheDrop}
            helperText="Upload a file up to 10 MB"
            marginTop={0}
            maxSizeInMb={10}
            rejectedDropErrorMessage="Unable to upload files"
          />

          {hasUploadError && (
            <EnhancedAlert
              severity="error"
              sx={{ marginTop: 2 }}
            >
              Unable to upload files
            </EnhancedAlert>
          )}
        </div>
      )}

      {formBlockTypeId === FormBlockTypes.FileDownload && filename && (
        <ShowPageDataDisplay
          label="File"
          value={filename}
        />
      )}

      {formBlockTypeId !== FormBlockTypes.Heading
        && formBlockTypeId !== FormBlockTypes.Paragraph
        && formBlockTypeId !== FormBlockTypes.FileDownload && (
          <CustomCheckbox
            label="Required"
            name="isRequired"
          />
      )}
    </>
  ) : (
    <FormBlockTypeSelect required />
  );
};

export default NewBlockFormInputs;
