// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialItem } from 'gql/queries';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useParamsWithId } from 'hooks/useParamsWithId';
import FinancialItemMemberFeesTable from 'pages/Finances/shared/FinancialItemMemberFeesTable';

// Local Dependencies
import FinancialItemsForm from '../shared/FinancialItemsForm';

// Component Definition
const FinancialItemsShow: FC = () => {
  const { id } = useParamsWithId();

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));

  const { data, loading } = useGetFinancialItem(id!);

  return (
    <Page
      backButtonProps={{
        label: 'Financial Items',
        path: `/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`,
      }}
      editButtonPath={`/${PATHS.FINANCIAL_ITEMS}/${id}/edit`}
      isLoading={loading}
      notFound={!data?.financialItem}
    >
      {data?.financialItem && (
        <FinancialItemsForm
          financialItemData={data.financialItem}
          initialValues={{
            canApplyFundraiserCredits: data.financialItem.canApplyFundraiserCredits,
            isActive: data.financialItem.isActive,
            label: data.financialItem.label,
            priceInCents: convertCentsToDollars(data.financialItem.priceInCents),
            schoolYearEnding: data.financialItem.schoolYearEnding,
          }}
          readOnly
          title="Financial Item Details"
        />
      )}

      <FinancialItemMemberFeesTable financialItemId={id!} />

      <History
        id={id!}
        resource="financialItems"
      />
    </Page>
  );
};

export default FinancialItemsShow;
