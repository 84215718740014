// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const InactiveMembersTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <>
        Any student, parent, or director who has been removed
        from your organization will appear here.
      </>
    )}
  />
);

export default InactiveMembersTableZeroState;
