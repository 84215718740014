// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedContainer,
  ShowStudentUniformCheckoutUI,
} from 'components/shared';
import {
  useGetMyUniformCheckouts,
} from 'gql/queries';

// Component Definition
const MyUniformCheckouts: FC = () => {
  const { data } = useGetMyUniformCheckouts();

  if (!data) {
    return null;
  }

  const { uniformCheckouts } = data.myUniformCheckouts;

  return (
    <EnhancedContainer>
      <ShowStudentUniformCheckoutUI
        uniformCheckoutsData={uniformCheckouts}
      />
    </EnhancedContainer>
  );
};

export default MyUniformCheckouts;
