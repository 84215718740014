// External Dependencies
import { FormResponsesResponseItem } from '@presto-assistant/api_types/api/v1/form';
import { useMemo } from 'react';

// Internal Dependencies
import { useGetFormResponses } from 'pages/Forms/Show/hooks';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';

// Local Typings
interface Props {
  form: GQL.IForm;
}

// Component Definition
const FormResponsesProgressBar = ({
  form,
}: Props): JSX.Element => {
  const { data } = useGetFormResponses(form.id);

  const numOfSubmittedResponses = useMemo(() => {
    return data?.filter((response: FormResponsesResponseItem) => {
      return response.submittedAt;
    })?.length ?? 0;
  }, [data]);

  return (
    <PercentageProgressBar
      denominator={data ? data.length : 0}
      label="Forms Submitted"
      numerator={numOfSubmittedResponses}
    />
  );
};

export default FormResponsesProgressBar;
