// External Dependencies
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { pluralize } from 'utils';

// Local Dependencies
import { PaymentsByUserFormValues } from './PaymentFormByUserForm';
import PaymentsByUserTable from './PaymentsByUserTable';

// Local Typings
interface Props {
  formValues: PaymentsByUserFormValues;
  userId: string;
}

type AlertData = {
  message: string;
  severity: 'error' | 'info';
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  '.sectionSubtitle': {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

// Component Definition
const PaymentFormFees = ({
  formValues,
  userId,
}: Props): JSX.Element => {
  // We will compare the total amount against the running total
  const [alertData, setAlertData] = useState<AlertData>({ message: '', severity: 'info' });
  const [showAlert, setShowAlert] = useState(false);

  const feePluralized: string = useMemo(() => {
    return pluralize(formValues.payments.length, 'fee');
  }, [formValues]);

  const subtitleText = useMemo(() => {
    return `Apply this payment to ${feePluralized}`;
  }, [feePluralized]);

  useEffect(() => {
    const totalAmountNumber = Number(formValues.amount);
    const runningTotal = formValues.payments
      .reduce((prev, curr) => prev + Number(curr.amountInCents), 0);
    const hasAmounts = totalAmountNumber > 0 || runningTotal > 0;

    if (hasAmounts && runningTotal > totalAmountNumber) {
      setAlertData({ message: 'Fee amounts shouldn\'t be more than the member\'s payment', severity: 'error' });
      setShowAlert(true);
    } else if (hasAmounts && runningTotal < totalAmountNumber) {
      setAlertData({
        message: `Apply $${totalAmountNumber - runningTotal} worth of payments to the ${feePluralized} listed above. 
          ${formValues.payments.length > 1 ? 'This amount can be split between items.' : ''} `,
        severity: 'info',
      });
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [formValues, feePluralized]);

  return (
    <Collapse
      in={Boolean(userId)}
      mountOnEnter
      timeout="auto"
    >
      <Divider sx={{ marginX: 3, marginY: 0.5 }} />

      <StyledCardContent>
        <Typography
          className="sectionSubtitle"
          component="h2"
          variant="subtitle1"
        >
          {subtitleText}
        </Typography>

        <PaymentsByUserTable
          formValues={formValues}
          userId={userId}
        />
      </StyledCardContent>

      <Collapse in={showAlert}>
        <Box
          marginX={6}
          marginY={1}
        >
          <EnhancedAlert severity={alertData.severity}>
            {alertData.message}
          </EnhancedAlert>
        </Box>
      </Collapse>
    </Collapse>
  );
};

export default PaymentFormFees;
