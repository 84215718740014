// External Dependencies
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

// Internal Dependencies
import { CustomSelect, Flex } from 'components/shared';
import { useGetGroupsAll, useGetOrganizationRoles } from 'gql/queries';

// Local Typings
interface Props {
  assignmentIndex: number;
  getName: (fieldName: string) => string;
  onDeleteAssignment: () => void;
  readOnly?: boolean;
}

// Component Definition
const FileAssignmentForm: FC<Props> = ({
  assignmentIndex,
  getName,
  onDeleteAssignment,
  readOnly,
}) => {
  const getFieldName = (fieldName: string) => `assignments[${assignmentIndex}].${fieldName}`;

  const {
    data: groupData,
  } = useGetGroupsAll();

  const {
    data: roleData,
  } = useGetOrganizationRoles();

  return (
    <Flex>
      {groupData && (
        <Box
          flexBasis="40%"
          flexGrow={1}
          minWidth={300}
          mx={1}
        >
          <CustomSelect
            inputProps={{ readOnly }}
            label="Group"
            name={getName(getFieldName('groupId'))}
            options={groupData?.groups.data}
          />
        </Box>
      )}

      {roleData && (
        <Box
          flexBasis="40%"
          flexGrow={1}
          minWidth={300}
          mx={1}
        >
          <CustomSelect
            inputProps={{ readOnly }}
            label="Role"
            name={getName(getFieldName('organizationRoleId'))}
            options={roleData?.organizationRoles}
          />
        </Box>
      )}

      {!readOnly && (
        <IconButton
          color="primary"
          onClick={onDeleteAssignment}
          size="large"
        >
          <RemoveIcon />
        </IconButton>
      )}
    </Flex>
  );
};

export default FileAssignmentForm;
