// Internal Dependencies
import {
  ShowStudentUniformCheckoutUI,
} from 'components/shared';
import {
  useGetUniformCheckoutsByUserId,
} from 'gql/queries';

// Local Typings
interface Props {
  name: string;
  userId: string;
}

// Component Definition
const ShowStudentUniformCheckout = ({
  name,
  userId,
}: Props): JSX.Element | null => {
  const { data } = useGetUniformCheckoutsByUserId(userId);

  if (!data) {
    return null;
  }

  const { uniformCheckouts } = data.uniformCheckoutsByUserId;

  return (
    <ShowStudentUniformCheckoutUI
      name={name}
      uniformCheckoutsData={uniformCheckouts}
      userId={userId}
    />
  );
};

export default ShowStudentUniformCheckout;
