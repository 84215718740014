// External Dependencies
import { PATHS } from 'utils/constants/routes';
import { useLocation } from 'react-router-dom';

// Internal Dependencies
import { Page } from 'components/shared';
import { useGetDynamicField } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import DynamicFieldForm from '../shared/DynamicFieldForm';

// Component Definition
const DynamicFieldsEdit = (): JSX.Element => {
  const { search } = useLocation();
  const { id } = useParamsWithId();

  const {
    data,
    loading,
  } = useGetDynamicField({ id });

  const dynamicField = data?.dynamicField;

  return (
    <Page
      backButtonProps={{
        label: 'Dynamic Fields',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${search}`,
      }}
      isLoading={loading}
      notFound={!loading && !dynamicField}
    >
      <DynamicFieldForm dynamicField={dynamicField} />
    </Page>
  );
};

export default DynamicFieldsEdit;
