// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Internal Dependencies
import {
  close,
  open,
  resetForm,
  setCurrentStep,
  updateCompletedSteps,
  updateForm,
  updateFormErrors,
} from './actions';

// Local Typings
export interface StudentDialogState {
  completedSteps: number[];
  currentStep: number;
  form: boolean | {};
  formErrors: boolean | {};
  isOpen: boolean;
}

// Reducers
const COMPLETED_STEPS_INITIAL_STATE: number[] = [];
const completedSteps = createReducer(
  COMPLETED_STEPS_INITIAL_STATE,
  (handleAction) => [
    handleAction([close, logoutCurrentUser, resetForm], () => COMPLETED_STEPS_INITIAL_STATE),
    handleAction(updateCompletedSteps, (state, { payload }) => [...state, payload]),
  ],
);

const CURRENT_STEP_INITIAL_STATE = 1;
const currentStep = createReducer(
  CURRENT_STEP_INITIAL_STATE,
  (handleAction) => [
    handleAction([close, logoutCurrentUser, resetForm], () => CURRENT_STEP_INITIAL_STATE),
    handleAction(setCurrentStep, (state, { payload }) => payload),
  ],
);

const FORM_INITIAL_STATE = {};
const form = createReducer(FORM_INITIAL_STATE, (handleAction) => [
  handleAction(
    updateForm,
    (state, { payload }: ReduxData.IAction) => payload,
  ),
  handleAction(
    [close, logoutCurrentUser, resetForm],
    () => FORM_INITIAL_STATE,
  ),
]);

const FORM_ERRORS_INITIAL_STATE = {};
const formErrors = createReducer(FORM_ERRORS_INITIAL_STATE, (handleAction) => [
  handleAction(
    updateFormErrors,
    (state, { payload }: ReduxData.IAction) => payload,
  ),
  handleAction(
    [close, logoutCurrentUser, resetForm],
    () => FORM_ERRORS_INITIAL_STATE,
  ),
]);

const isOpen = createReducer(false, (handleAction) => [
  handleAction([
    close,
    logoutCurrentUser,
  ], () => false),
  handleAction(open, () => true),
]);

export default combineReducers<StudentDialogState>({
  completedSteps, currentStep, form, formErrors, isOpen,
});
