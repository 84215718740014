// External Dependencies
import {
  DistrictInventoryIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventory';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from 'mdi-material-ui/Download';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListChecksIcon from 'mdi-material-ui/FormatListChecks';
import SettingsIcon from '@mui/icons-material/Settings';

// Internal Dependencies
import { DELETE_INVENTORY_ITEM } from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { tableQueryParams } from 'state/table/selectors';
import {
  useGetDistrictInventoryIndex,
  useGetDistrictInventoryIndexReport,
} from 'utils/api/inventory';
import { useGetDistrictInventoryReport } from 'gql/queries/report-queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import TableDataGridServerSide, {
  useParsedDataGridQueryString,
} from 'components/shared/TableDataGrid/ServerSide';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { FormTypes } from '@presto-assistant/api_types';
import { useColumns } from './hooks';
import DialogInventoryFileUpload from './DialogInventoryFileUpload';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}`;

// Component Definition
const InventoryTable = () => {
  const navigate = useNavigate();
  const parsedSearch = useParsedSearch();
  const { search } = useLocation();

  const [deleteInventoryItemId, setDeleteInventoryItemId] = useState<string | null>(null);

  const districtIinventoryAuditsParams = useSelector(tableQueryParams('inventoryAudits'));

  const {
    handleClose: closeUploadDialog,
    handleOpen: openUploadDialog,
    isOpen: isUploadDialogOpen,
  } = useIsOpen();

  const handleDeleteInventoryItem = useCallback((row: DistrictInventoryIndexResponseItem) => {
    setDeleteInventoryItemId(row.id);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteInventoryItemId(null);
  }, []);

  const extraColumns = useMemo<DataGridColDef<DistrictInventoryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<DistrictInventoryIndexResponseItem>([
        {
          action: handleDeleteInventoryItem,
          icon: <DeleteIcon />,
          text: 'Delete inventory item',
        },
      ]);

      return (actionsColumn
        ? [actionsColumn]
        : []
      ) as DataGridColDef<DistrictInventoryIndexResponseItem>[];
    },
    [
      handleDeleteInventoryItem,
    ],
  );

  const columnsArgs = useMemo(() => ({
    extraColumns,
  }), [extraColumns]);

  const columns = useColumns(columnsArgs);

  const {
    filters,
    pagination,
    sort,
  } = useParsedDataGridQueryString(search, {
    columns,
    mapSortKeys: {
      categoryId: 'categoryLabel',
      onLoanToOrganizationId: 'onLoanToOrganizationLabel',
      organizationId: 'organizationLabel',
    },
  });

  const {
    data: districtInventoryData,
    isLoading,
  } = useGetDistrictInventoryIndex({
    filters,
    limit: pagination?.pageSize ?? 100,
    page: pagination?.page ?? 1,
    sort,
  });

  const {
    isLoading: isExporting,
    mutate: handleExportInventory,
  } = useGetDistrictInventoryIndexReport();

  const handleClickExportInventory = useCallback(() => {
    handleExportInventory({
      filters,
      limit: pagination?.pageSize ?? 100,
      page: pagination?.page ?? 1,
      sort,
    });
  }, [
    handleExportInventory,
    filters,
    pagination?.pageSize,
    pagination?.page,
    sort,
  ]);

  const [
    handleExportFullReport,
    { loading: isExportingFullReport },
  ] = useGetDistrictInventoryReport({});

  const handleNavigateToDynamicFields = useCallback(() => {
    const organizationTypeId = parsedSearch.organizationTypeId ?? '1';

    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}?organization_type_id=${organizationTypeId}&table_ref=inventory_items`);
  }, [navigate, parsedSearch.organizationTypeId]);

  const handleNavigateToSettings = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_SETTINGS}`);
  }, [navigate]);

  const handleNavigateToInventoryAudits = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}${districtIinventoryAuditsParams}`);
  }, [districtIinventoryAuditsParams, navigate]);

  const handleNavigateToInventoryCheckoutForm = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS_NEW}?form_type_id=${FormTypes.InventoryCheckout}`);
  }, [navigate]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [
      {
        action: handleClickExportInventory,
        icon: <DownloadIcon />,
        isDisabled: isExporting || (districtInventoryData?.data.fullCount ?? 0) < 1,
        sectionTitle: 'Export',
        text: 'Export inventory',
      },
      {
        action: handleExportFullReport,
        icon: <EqualizerIcon />,
        isDisabled: isExportingFullReport || districtInventoryData?.data.fullCount === 0,
        text: 'Export full report',
      },
      {
        action: openUploadDialog,
        icon: <CloudUploadIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        sectionTitle: 'Import',
        text: 'Import inventory',
      },
      {
        action: handleNavigateToDynamicFields,
        icon: <CategoryIcon />,
        sectionTitle: 'Administration',
        text: 'Dynamic fields',
      },
      {
        action: handleNavigateToInventoryAudits,
        icon: <FormatListChecksIcon />,
        text: 'Inventory Audits',
      },
      {
        action: handleNavigateToInventoryCheckoutForm,
        icon: <FormatListChecksIcon />,
        text: 'Create Inventory Checkout Form',
      },
      {
        action: handleNavigateToSettings,
        icon: <SettingsIcon />,
        sectionTitle: 'Settings',
        text: 'Inventory Settings',
      },
    ];

    return actions;
  }, [
    districtInventoryData?.data.fullCount,
    handleExportFullReport,
    handleClickExportInventory,
    handleNavigateToDynamicFields,
    handleNavigateToInventoryAudits,
    handleNavigateToInventoryCheckoutForm,
    handleNavigateToSettings,
    isExporting,
    isExportingFullReport,
    openUploadDialog,
  ]);

  return (
    <>
      <DataGridContainer>
        <TableDataGridServerSide
          addButtonProps={{
            label: 'Inventory',
            to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/new`,
          }}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          fullCount={districtInventoryData?.data.fullCount ?? 0}
          loading={isLoading}
          rows={districtInventoryData?.data.data ?? null}
          tableResource="districtInventoryItems"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogInventoryFileUpload
        isOpen={isUploadDialogOpen}
        onClose={closeUploadDialog}
      />

      <DeleteDialog
        clearCachePredicates={['districtInventory', 'inventory']}
        context={['inventory']}
        isOpen={Boolean(deleteInventoryItemId)}
        mutation={DELETE_INVENTORY_ITEM}
        onClose={handleCloseDeleteDialog}
        reduxTableKey="districtInventoryItems"
        singleItemId={deleteInventoryItemId}
        size={districtInventoryData?.data.fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default InventoryTable;
