// External Dependencies
import { DialogContentText } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';

// Local Typings
interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
}

// Component Definition
const DiscardConfirmDialog: FC<Props> = ({
  isOpen,
  onConfirm,
  onRequestClose,
}) => (
  <ConfirmationDialog
    confirmButtonAction={onConfirm}
    confirmButtonText="Yes, Discard"
    declineButtonAction={onRequestClose}
    declineButtonText="Nevermind"
    description={(
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to discard your unsaved changes?
      </DialogContentText>
    )}
    handleClose={onRequestClose}
    open={isOpen}
    title="You sure about that?"
    useCustomText
  />
);

export default DiscardConfirmDialog;
