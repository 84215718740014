// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationEnhancedOptions, MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

const CHECKLIST_FRAGMENT = gql`
  fragment ChecklistFragment on Checklist {
    checklistItems {
      dueDate
      id
      isRequired
      label
    }
    emailRemindersEnabled
    id
    label
    memberIds
    memberItemDetails {
      checklistItems {
        completedAt
        id
        isRequired
        itemId
        label
      }
      id
      member {
        id
        email
        firstName
        grade
        lastName
        roleId
        roleLabel
      }
    }
    schoolYearEnding
  }
`;

const CREATE_CHECKLIST = gql`
  mutation CreateChecklist(
    $input: CreateChecklistInput!
  ) {
    createChecklist(input: $input) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

const UPDATE_CHECKLIST = gql`
  mutation UpdateChecklist(
    $id: ID!
    $input: UpdateChecklistInput!
  ) {
    updateChecklist(
      id: $id
      input: $input
    ) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

const UPDATE_CHECKLIST_MEMBERS = gql`
  mutation UpdateChecklistMembers(
    $input: UpdateChecklistMembersInput!
  ) {
    updateChecklistMembers(
        input: $input
    ) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

const CREATE_CHECKLIST_ITEM = gql`
  mutation CreateChecklistItem(
    $input: CreateChecklistItemInput!
  ) {
    createChecklistItem(input: $input) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

const UPDATE_CHECKLIST_ITEM = gql`
  mutation UpdateChecklistItem(
    $id: ID!
    $input: UpdateChecklistItemInput!
  ) {
    updateChecklistItem(
      id: $id
      input: $input
    ) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

const MARK_CHECKLIST_ITEM_COMPLETED = gql`
  mutation MarkChecklistItemCompleted(
    $input: MarkChecklistItemCompletedInput!
  ) {
    markChecklistItemCompleted(input: $input) {
      checklist {
        ...ChecklistFragment
      }
      checklistItems {
        completedAt
        dueDate
        id
        isRequired
        label
      }
      id
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

export const DELETE_CHECKLIST = gql`
  mutation DeleteChecklist(
    $input: DeleteChecklistInput!
  ) {
    deleteChecklist(input: $input)
  }
`;

export const DELETE_CHECKLIST_ITEM = gql`
  mutation DeleteChecklistItem(
    $input: DeleteChecklistItemInput!
  ) {
    deleteChecklistItem(input: $input) {
      ...ChecklistFragment
    }
  },
  ${CHECKLIST_FRAGMENT}
`;

export const useCreateChecklist = (
  options?: MutationOptions<
    { createChecklist: GQL.IChecklist },
    GQL.ICreateChecklistOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { createChecklist: GQL.IChecklist },
    GQL.ICreateChecklistOnMutationArguments
  >(CREATE_CHECKLIST, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklists',
    ],
  });

export const useUpdateChecklist = (
  options?: MutationOptions<
    { updateChecklist: GQL.IChecklist },
    GQL.IUpdateChecklistOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { updateChecklist: GQL.IChecklist },
    GQL.IUpdateChecklistOnMutationArguments
  >(UPDATE_CHECKLIST, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklists',
    ],
  });

export const useUpdateChecklistMembers = (
  options?: MutationOptions<
    { updateChecklistMembers: GQL.IChecklist },
    GQL.IUpdateChecklistMembersOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { updateChecklistMembers: GQL.IChecklist },
    GQL.IUpdateChecklistMembersOnMutationArguments
  >(UPDATE_CHECKLIST_MEMBERS, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklist',
    ],
  });

export const useCreateChecklistItem = (
  options?: MutationEnhancedOptions<
    { createChecklistItem: GQL.IChecklist },
    GQL.ICreateChecklistItemOnMutationArguments
  >,
) => useMutationEnhanced<
    { createChecklistItem: GQL.IChecklist },
    GQL.ICreateChecklistItemOnMutationArguments
  >(CREATE_CHECKLIST_ITEM, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklist',
      'checklists',
    ],
  });

export const useUpdateChecklistItem = (
  options?: MutationEnhancedOptions<
    { updateChecklistItem: GQL.IChecklist },
    GQL.IUpdateChecklistItemOnMutationArguments
  >,
) => useMutationEnhanced<
    { updateChecklistItem: GQL.IChecklist },
    GQL.IUpdateChecklistItemOnMutationArguments
  >(UPDATE_CHECKLIST_ITEM, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklist',
      'checklists',
    ],
  });

export const useMarkChecklistItemCompleted = (
  options?: MutationEnhancedOptions<
    { markChecklistItemCompleted: GQL.IChecklistMemberItem },
    GQL.IMarkChecklistItemCompletedOnMutationArguments
  >,
) => useMutationEnhanced<
    { markChecklistItemCompleted: GQL.IChecklistMemberItem },
    GQL.IMarkChecklistItemCompletedOnMutationArguments
  >(MARK_CHECKLIST_ITEM_COMPLETED, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'checklist',
      'checklists',
    ],
  });
