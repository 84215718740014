// External Dependencies
import { FC } from 'react';
import { ImportResources } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { close } from 'state/ui/uniformFileUploadDialog/actions';
import { isOpen } from 'state/ui/uniformFileUploadDialog/selectors';
import { useGetGettingStartedCounts } from 'gql/queries';
import CSVImporter from 'components/CSVImporter';

// Component Definition
const DialogUniformFileUpload: FC = () => {
  const dispatch = useDispatch();

  const isUniformFileUploadDialogOpen = useSelector(isOpen);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneUniformItem = (gettingStartedCountsData?.dashboardMetrics?.uniformCount ?? 0) > 0;

  const handleCloseDialogUniformFileUpload = () => {
    dispatch(close());
  };

  return (
    <CSVImporter
      canMigrateFromAnotherSystem
      context="uniform"
      csvFileInfoResource="uniforms"
      dialogTitle="Add Uniforms"
      importResourceId={ImportResources.Uniforms}
      insertMutationName="insertUniformCsvFileToDB"
      insertMutationOptions={{
        clearCachePredicates: [
          'uniformsIndex',
          ...!hasAtLeastOneUniformItem ? ['dashboardMetrics'] : [],
        ],
      }}
      isOpen={isUniformFileUploadDialogOpen}
      onClose={handleCloseDialogUniformFileUpload}
      uploadMutationName="csvUniformUpload"
    />
  );
};

export default DialogUniformFileUpload;
