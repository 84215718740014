// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DistrictLibaryInstrumentationsOnQueryArguments {
  organizationId?: string;
}
interface DistrictLibaryInstrumentationsResponse {
  dfaLibraryInstrumentations: Array<GQL.ILibraryInstrumentation>
}

const DISTRICT_LIBRARY_INSTRUMENTATIONS_SELECT_OPTIONS_QUERY = gql`
  query DistrictLibraryInstrumentationsSelectOptons(
    $organizationId: ID!
  ) {
    dfaLibraryInstrumentations(
      organizationId: $organizationId
    ) {
      id
      label
    }
  }
`;

export const useGetDistrictLibraryInstrumentations = (
  args: DistrictLibaryInstrumentationsOnQueryArguments,
) =>
  useQueryEnhanced<
    DistrictLibaryInstrumentationsResponse,
    DistrictLibaryInstrumentationsOnQueryArguments
  >(
    DISTRICT_LIBRARY_INSTRUMENTATIONS_SELECT_OPTIONS_QUERY,
    {
      skip: !args.organizationId,
      variables: args,
    },
  );
