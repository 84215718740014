// External Dependencies
import { DocumentNode } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { TableResource } from 'state/table/actions';
import { getIndexQueryParams, useLazyQueryEnhanced } from 'utils/lib/graphql';
import { parseSearch } from 'utils';
import {
  tableQueryParams,
} from 'state/table/selectors';

// Local Typings
export interface UseGetTableQueryArgs {
  getAdditionalVariables?: (search: ReturnType<typeof parseSearch>) => object;
  gqlQuery: DocumentNode;
  isUsingDataGrid?: boolean;
  options: { exportReport: boolean };
  skipQuery?: boolean;
  tableResource: TableResource;
}

// Local Variables
// We pull this out to avoid an infinite loop
//  for tables that don't have filters
const noOp = () => ({});

// Hook Definition
export const useGetTableQuery = <Response, Variables>({
  getAdditionalVariables = noOp,
  gqlQuery,
  isUsingDataGrid,
  options,
  skipQuery,
  tableResource,
}: UseGetTableQueryArgs) => {
  const searchString = useSelector(tableQueryParams(tableResource));

  const [
    query,
    values,
  ] = useLazyQueryEnhanced<
    Response,
    Variables
  >(
    gqlQuery,
    options.exportReport ? {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'standby',
    } : {},
  );

  const [apiRequest, setApiRequest] = useState<() => void>(() => () => null);

  useEffect(() => {
    const search = parseSearch(searchString);
    const additionalVariables = isUsingDataGrid
      ? {}
      : getAdditionalVariables(search);

    const req = () => {
      if (!skipQuery) {
        const queryParams = getIndexQueryParams(searchString);

        (query as any)({
          variables: {
            queryParams: isUsingDataGrid
              ? {}
              : {
                ...queryParams,
                exportReport: options.exportReport,
              },
            ...additionalVariables,
          },
        });
      }
    };

    setApiRequest(() => req);
    // We ignore getAdditionalVariables to prevent an infinite loop
    //  for some tables. We should investigate why that happens.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.exportReport,
    query,
    searchString,
    setApiRequest,
    skipQuery,
  ]);

  return {
    apiRequest,
    values,
  };
};
