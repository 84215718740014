// External Dependencies
import { useMemo } from 'react';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';
import { capitalize } from 'utils';
import CustomCheckboxGroup from 'components/shared/CustomCheckboxGroup';

// Local Dependencies
import { StyledFormLabel } from './StyledFormLabel';

// Local Variables
const MEMBER_TYPES = {
  Directors: 'directors',
  Parents: 'parents',
  Students: 'students',
};

/*
 * This component should be used in a Formik context.
 *
 */

// Component Definition
const AssignToMemberCheckboxes = (): JSX.Element => {
  // We need three checkboxes, one for each member type:
  //  Directors, Parents, and Students
  const checkboxData = useMemo(() => ([
    {
      label: capitalize(MEMBER_TYPES.Directors),
      value: MEMBER_TYPES.Directors,
    },
    {
      label: capitalize(MEMBER_TYPES.Parents),
      value: MEMBER_TYPES.Parents,
    },
    {
      label: capitalize(MEMBER_TYPES.Students),
      value: MEMBER_TYPES.Students,
    },
  ]), []);

  return (
    <EnhancedCard>
      <EnhancedCardContent>
        <StyledFormLabel component="legend">
          Member Types
        </StyledFormLabel>

        <Box marginTop={1.5}>
          <CustomCheckboxGroup
            checkboxData={checkboxData}
            name="assignTo"
          />
        </Box>
      </EnhancedCardContent>
    </EnhancedCard>
  );
};

export default AssignToMemberCheckboxes;
