// External Dependencies
import { InputBaseComponentProps } from '@mui/material/InputBase';

// Internal Dependencies
import { useGetDistrictOrganizations } from 'gql/queries';
import CustomSelect, { SelectOption } from 'components/shared/CustomSelect';

// Local Typings
interface Props {
  allowUnassignedOption?: boolean;
  excludeOrganizationId?: string;
  helperText?: string;
  inputProps?: InputBaseComponentProps;
  label?: string;
  name?: string;
  orgTypeId: string | null;
  required?: boolean;
  unassignedOptionText?: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const DistrictOrganizationSelect = ({
  allowUnassignedOption,
  unassignedOptionText = '(Unassigned)',
  excludeOrganizationId,
  helperText,
  inputProps,
  required = true,
  label = required ? 'Organization *' : 'Organization',
  name = 'organizationId',
  orgTypeId,
}: Props): JSX.Element => {
  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  const organizationOptions: SelectOption[] = organizations
    .filter(
      (org) =>
        org?.organizationType?.id
      && (orgTypeId === null || org.organizationType.id === orgTypeId)
      && org.id !== excludeOrganizationId,
    ).map((organization) => ({
      id: organization.id,
      label: organization.label,
    }));

  if (allowUnassignedOption) {
    organizationOptions.unshift({
      id: '-1',
      label: unassignedOptionText,
    });
  }

  return (
    <CustomSelect
      helperText={helperText}
      inputProps={inputProps}
      label={label}
      name={name}
      options={organizationOptions}
      required={required}
    />
  );
};

export default DistrictOrganizationSelect;
