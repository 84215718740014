// External Dependencies
import { BaseSelectProps } from '@mui/material/Select';
import { FC } from 'react';

// Internal Dependencies
import { useGetFinancialItems } from 'gql/queries';
import CustomSelect from 'components/shared/CustomSelect';

// Local Typings
type PartialSelectProps = Omit<BaseSelectProps, 'onChange' | 'value' | 'label' | 'options'>;
type Props = PartialSelectProps & {
  schoolYearEnding: number;
}

// Component Definition
const FinancialItemSelect: FC<Props> = ({
  schoolYearEnding,
  ...props
}) => {
  const { data } = useGetFinancialItems(schoolYearEnding);

  if (!data) {
    return null;
  }

  return (
    <CustomSelect
      {...props}
      label="Financial Item"
      name="financialItemId"
      options={data.financialItems.data}
    />
  );
};

export default FinancialItemSelect;
