export const COLLEGE_GRADE_OPTIONS = [
  { id: '17', label: 'Graduate Student' },
  { id: '16', label: 'Senior' },
  { id: '15', label: 'Junior' },
  { id: '14', label: 'Sophomore' },
  { id: '13', label: 'Freshman' },
];

export const HIGH_SCHOOL_GRADE_OPTIONS = [
  { id: '12', label: '12' },
  { id: '11', label: '11' },
  { id: '10', label: '10' },
  { id: '9', label: '9' },
  { id: '8', label: '8' },
];

export const MIDDLE_SCHOOL_GRADE_OPTIONS = [
  { id: '9', label: '9' },
  { id: '8', label: '8' },
  { id: '7', label: '7' },
  { id: '6', label: '6' },
  { id: '5', label: '5' },
];

export const ELEMENTARY_SCHOOL_GRADE_OPTIONS = [
  { id: '7', label: '7' },
  { id: '6', label: '6' },
  { id: '5', label: '5' },
  { id: '4', label: '4' },
  { id: '3', label: '3' },
  { id: '2', label: '2' },
  { id: '1', label: '1' },
  { id: '0', label: 'K' },
];

export const ALL_GRADE_OPTIONS: { id: string; label: string; }[] = Array.from(new Set([
  ...COLLEGE_GRADE_OPTIONS,
  ...HIGH_SCHOOL_GRADE_OPTIONS,
  ...MIDDLE_SCHOOL_GRADE_OPTIONS,
  ...ELEMENTARY_SCHOOL_GRADE_OPTIONS,
]));

export const combinedNumericGradeOptions = [
  ...HIGH_SCHOOL_GRADE_OPTIONS,
  ...MIDDLE_SCHOOL_GRADE_OPTIONS,
  ...ELEMENTARY_SCHOOL_GRADE_OPTIONS,
];

// We remove duplicates if the grade option lists have repeated data
// Borrowed from this GitHub gist commment
// https://gist.github.com/telekosmos/3b62a31a5c43f40849bb#gistcomment-2341219
export const ALL_NUMERIC_GRADE_OPTIONS = [
  ...new Set([
    // We add an empty option for use in a select
    JSON.stringify({ id: 'empty', label: ' ' }),
    ...combinedNumericGradeOptions.map((option) =>
      JSON.stringify(option)),
  ]),
].map((setItem) => JSON.parse(setItem));
