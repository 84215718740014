// External Dependencies
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';

// Internal Dependencies
import { CustomSelect } from 'components/shared';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
}

// Component Definition
const SelectBlock = ({
  disabled,
  formBlock,
}: Props): JSX.Element => {
  const options: string[] = formBlock.metadata?.options ?? [];

  return (
    <CustomSelect
      disabled={disabled}
      label={formBlock.label}
      name="response"
      options={options.map((option) => ({
        id: option,
        label: option,
      }))}
      required={formBlock.isRequired}
    />
  );
};

export default SelectBlock;
