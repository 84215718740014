// External Dependencies
import {
  ChangeEvent, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { ThemeOptions } from 'state/ui/quickSettingsMenu/reducers';
import {
  isDirector,
  isDistrictAdmin,
  isFullAccessDfa,
} from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import {
  useThemePreference as themePreferenceSelector,
  useDenseTable as useDenseTableSelector,
} from 'state/ui/quickSettingsMenu/selectors';
import {
  updateTableDensity,
  updateTheme,
} from 'state/ui/quickSettingsMenu/actions';

// Local Dependencies
import { MenuItemProps } from '../IconButtonMenu/MenuItem';
import IconButtonMenu from '../IconButtonMenu';

// Component Definition
const GlobalSettingsMenu = (): JSX.Element => {
  const dispatch = useDispatch();

  const shouldUseDenseTable = useSelector(useDenseTableSelector);
  const themePreference = useSelector(themePreferenceSelector);
  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isDirectorUser = useSelector(isDirector);
  const isFullAccessDfaUser = useSelector(isFullAccessDfa);
  const isMobileScreen = useSelector(isMobileScreenSize);

  const handleChangeThemePreference = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(updateTheme(value as ThemeOptions));
  }, [dispatch]);

  const handleChangeDensity = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsedValue = JSON.parse(value);

    dispatch(updateTableDensity(parsedValue));
  }, [dispatch]);

  const settingsItems = useMemo<MenuItemProps[]>(() => {
    const items: MenuItemProps[] = [
      {
        id: 'table-density',
        props: {
          name: 'density',
          onChange: handleChangeDensity,
          options: [
            {
              label: 'Comfortable',
              value: false,
            },
            {
              label: 'Compact',
              value: true,
            },
          ],
          title: 'Table Density',
          value: shouldUseDenseTable,
        },
        type: 'radio',
      },
      {
        id: 'dark-mode',
        props: {
          name: 'dark-mode',
          onChange: handleChangeThemePreference,
          options: [
            {
              label: 'Light',
              value: 'light',
            },
            {
              label: 'Dark',
              value: 'dark',
            },
            {
              label: 'Midnight',
              value: 'midnight',
            },
            {
              label: 'Slate',
              value: 'slate',
            },
          ],
          title: 'Color Scheme',
          value: themePreference,
        },
        type: 'radio',
      },
    ];

    if (isDirectorUser || isDistrictAdminUser || isFullAccessDfaUser) {
      const firstItem: MenuItemProps = {
        id: 'all-settings',
        props: {
          text: 'See all settings',
          to: `/${isDistrictAdminUser ? `${PATHS.DISTRICT_ADMIN}/` : ''}${PATHS.SETTINGS}`,
        },
        type: 'navItem',
      };

      items.unshift(firstItem);
    }

    return items;
  }, [
    handleChangeDensity,
    handleChangeThemePreference,
    isDirectorUser,
    isDistrictAdminUser,
    isFullAccessDfaUser,
    shouldUseDenseTable,
    themePreference,
  ]);

  return (
    <IconButtonMenu
      IconButtonIcon={SettingsIcon}
      iconProps={{
        fontSize: isMobileScreen ? 'small' : 'medium',
      }}
      settingsItems={settingsItems}
      title="Quick Settings"
    />
  );
};

export default GlobalSettingsMenu;
