// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  close,
  open,
} from './actions';

// Local Variables
const FORM_INITIAL_STATE = {};

// Local Typings
export interface InventoryMissingSerialNumberDialogState {
  form: any;
  isOpen: boolean;
}

// Reducers
const form = createReducer(FORM_INITIAL_STATE, (handleAction) => [
  handleAction([
    close,
    logoutCurrentUser,
  ], () => FORM_INITIAL_STATE),
  handleAction(open, (state, { payload }: any) => payload),
]);

const isOpen = createReducer(false, (handleAction) => [
  handleAction([
    close,
    logoutCurrentUser,
  ], () => false),
  handleAction(open, () => true),
]);

export default combineReducers<InventoryMissingSerialNumberDialogState>({
  form,
  isOpen,
});
