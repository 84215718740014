// External Dependencies
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

// Internal Dependencies
import { EnhancedDialog } from 'components/shared';
import { LOGOUT_TIMEOUT_LIMIT } from 'utils/lib/token';
import { pluralize } from 'utils';

// Local Typings
interface Props {
  isOpen: boolean;
  onLogout: () => void;
  onStayLoggedIn: () => void;
}

// Component Definition
const LogoutWarningDialog: FC<Props> = ({
  isOpen,
  onLogout,
  onStayLoggedIn,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState(LOGOUT_TIMEOUT_LIMIT);

  useEffect(() => {
    let interval: number;

    if (isOpen) {
      setSecondsRemaining(LOGOUT_TIMEOUT_LIMIT);

      interval = window.setInterval(() => {
        setSecondsRemaining((count) => count - 1);
      }, 1000);
    }

    return (() => {
      if (interval) {
        window.clearInterval(interval);
      }
    });
  }, [isOpen]);

  useEffect(() => {
    if (secondsRemaining < 1) {
      onLogout();
    }
  }, [onLogout, secondsRemaining]);

  return (
    <EnhancedDialog open={isOpen}>
      <DialogTitle>
        Hey there, you must be busy!
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          We&apos;re going to log you out to keep your account secure.
        </DialogContentText>

        <Box my={2}>
          <Typography>
            Logging out in <strong>{secondsRemaining}</strong> {pluralize(secondsRemaining, 'second')}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onStayLoggedIn}
          variant="contained"
        >
          Stay Logged In
        </Button>
      </DialogActions>
    </EnhancedDialog>
  );
};

export default LogoutWarningDialog;
