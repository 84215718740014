// External Dependencies
import { Form, FormikTouched, useFormikContext } from 'formik';
import {
  FormEventHandler, useCallback, useEffect, useState,
} from 'react';
import { createFinancialPaymentSchema } from '@presto-assistant/api_types/schemas/financialPayments';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import cloneDeep from 'lodash.clonedeep';

// Internal Dependencies
import { EMPTY_UUID } from 'utils/constants';
import {
  EnhancedAlert,
  EnhancedCard,
  FormActions,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, pluralize } from 'utils';
import { useGetFees } from 'gql/queries';

// Local Dependencies
import PaymentFormStudent from './PaymentFormStudent';
import PaymentFormUI from './PaymentFormUI';

// Local Typings
interface Props {
  onResetFormikForm: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  touched: FormikTouched<PaymentsByUserFormValues>;
  userId: string;
  values: PaymentsByUserFormValues;
}

export interface PaymentsByUserPaymentRowValues {
  amountInCents: number;
  creditAppliedAmountInCents: number;
  financialFeeId: string;
  note: string;
  remainingBalanceInCents: number;
}
export interface PaymentsByUserFormValues {
  amount: number;
  checkNumber: string;
  creditCardAccountHolder?: string;
  creditCardExp: string;
  creditCardLastFour: string;
  datePaid: string;
  financialPaymentTypeId: string;
  isPayingAllFees: boolean;
  otherLabel: string;
  payments: PaymentsByUserPaymentRowValues[];
  referenceNumber: string;
}

// modify the api schema to meet the needs of this form
const clonedSchema = cloneDeep(createFinancialPaymentSchema);

(clonedSchema.fields as any)
  .amount = clonedSchema.fields.amountInCents;

delete (clonedSchema.fields as any).amountInCents;
delete (clonedSchema.fields as any).financialFeeId;

// Component Definition
const PaymentFormByUserForm = ({
  onResetFormikForm,
  onSubmit,
  touched,
  userId,
  values,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { setValues } = useFormikContext();

  const [feesWithoutAccounts, setFeesWithoutAccounts] = useState<GQL.IFinancialFee[]>([]);

  const { isPayingAllFees } = values;

  const {
    getFees,
    results: { data: allFeesData },
  } = useGetFees({
    fetchPolicy: 'network-only', // using network-only will always give us the right data, and it prevents a weird NaN issue 🤷‍♂️
  });

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}`);
  }, [navigate]);

  const handleClickViewItemsWithoutAccounts = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}?financial_account_id=${EMPTY_UUID}`);
  }, [navigate]);

  useEffect(() => {
    getFees({ variables: { where: { userId } } });
  }, [getFees, userId]);

  // Addings values to the dependency array will cause infinite loop
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (allFeesData) {
      const filteredFees = allFeesData.financialFees.data
        .filter((datum) => Boolean(datum.remainingBalanceInCents));

      const localFeesWithAccounts = filteredFees
        .filter((fee) => fee.financialItem.financialAccount);
      const localFeesWithoutAccounts = filteredFees
        .filter((fee) => !fee.financialItem.financialAccount);

      const newAmount = isPayingAllFees
        ? convertCentsToDollars(
          localFeesWithAccounts.reduce((prev, curr) => prev + curr.remainingBalanceInCents, 0),
        )
        : values.amount;

      const newValues = {
        ...values,
        amount: newAmount,
        payments: localFeesWithAccounts
          .map(((datum) => {
            const valuePayment = values.payments
              .find((payment) => payment.financialFeeId === datum.id);

            return {
              amountInCents: 0,
              creditAppliedAmountInCents: 0,
              financialFeeId: datum.id,
              note: '',
              remainingBalanceInCents: datum.remainingBalanceInCents,
              ...(valuePayment ?? {}),
              ...(isPayingAllFees ? {
                amountInCents: convertCentsToDollars(datum.remainingBalanceInCents),
                creditAppliedAmountInCents: 0,
              } : {}),
            };
          })),
      };

      setFeesWithoutAccounts(localFeesWithoutAccounts);
      setValues(newValues);
    }
  }, [allFeesData, isPayingAllFees]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const isFormTouched = Object.keys(touched).length > 0;

  const totalNumberAmount = (Number(values.amount));

  const runningTotal = values.payments.reduce((prev, curr) => prev + Number(curr.amountInCents), 0);
  const runningCreditTotal = values.payments
    .reduce((prev, curr) => prev + Number(curr.creditAppliedAmountInCents), 0);

  // We disable the submit button if the amounts don't match
  //  or if there is no total amount entered yet
  // But the button is ok if the
  //  user checked the "pay in full" checkbox
  const isSubmitButtonDisabled = !isPayingAllFees
    && isFormTouched
    && (
      (!runningTotal && !runningCreditTotal)
      || totalNumberAmount !== runningTotal
    );

  return (
    <Form onSubmit={onSubmit}>
      {feesWithoutAccounts.length > 0 && (
        <Box marginBottom={2}>
          <EnhancedAlert
            action={(
              <Box marginRight={1}>
                <Button onClick={handleClickViewItemsWithoutAccounts}>
                  Manage Items
                </Button>
              </Box>
            )}
            severity="error"
          >
            There {pluralize(feesWithoutAccounts.length, 'is', 'are')} {feesWithoutAccounts.length} {pluralize(feesWithoutAccounts.length, 'fee')} without an account.
            {' '}
            Fees without accounts are not shown below.
          </EnhancedAlert>
        </Box>
      )}

      <EnhancedCard>
        <PaymentFormStudent studentId={userId} />

        <PaymentFormUI
          formValues={values}
          onResetFormikForm={onResetFormikForm}
          paymentTypeId={values.financialPaymentTypeId}
          studentId={userId}
        />

        <FormActions
          context="Payment"
          isButtonDisabled={isSubmitButtonDisabled}
          isFormTouched={isFormTouched}
          isSubmitting={false}
          onPressCancelOrBackButton={handlePressCancelOrBackButton}
        />
      </EnhancedCard>
    </Form>
  );
};

export default PaymentFormByUserForm;
