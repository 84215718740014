import gql from 'graphql-tag';

export const GET_S3_FILES = gql`
  query GetS3Files {
    s3Items {
      Contents {
        Key
        Size
        ETag
        LastModified
        uploadedBy {
          firstName
          lastName
        }
      }
      Name
    }
  }
`;
