// External Dependencies
import { FC } from 'react';
import {
  ListItem,
  ListItemText,
} from '@mui/material';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { formatDateTime } from 'utils';

// Local Typings
interface Props {
  loggedInAt?: string | null;
  receivedWelcomeEmailAt?: string | null;
  userOrgLoggedInAt?: string | null;
}

// Component Definition
const LoginActivityListItem: FC<Props> = ({
  loggedInAt,
  receivedWelcomeEmailAt,
  userOrgLoggedInAt,
}) => {
  const lastLoggedIntoOrganization = userOrgLoggedInAt && receivedWelcomeEmailAt
    ? formatDateTime(userOrgLoggedInAt)
    : 'Never';

  return (
    <ListItem>
      <ListItemText
        primary="Login Activity"
        secondary={(
          <>
            Last logged in to {APP_NAME}: <strong>{loggedInAt ? formatDateTime(loggedInAt) : 'Never'}</strong>
            <br />
            Last logged in to this organization: <strong>{lastLoggedIntoOrganization}</strong>
          </>
      )}
      />
    </ListItem>
  );
};

export default LoginActivityListItem;
