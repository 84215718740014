// External Dependencies
import { FC, ReactElement } from 'react';
import { MenuItemProps } from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { useMatch } from 'react-router-dom';

// Local Dependencies
import EnhancedMenuItem from './EnhancedMenuItem';

// Local Typings
export interface NavMenuItemProps extends MenuItemProps {
  contentRef?: React.Ref<HTMLDivElement>;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  isActive?: boolean;
  isFullWidth?: boolean;
  match?: string;
  onClick?: () => void;
  showNewChip?: boolean;
  text?: string | ReactElement;
  to: string;
}

// Component Definition
const NavMenuItem: FC<NavMenuItemProps> = ({
  children,
  isActive,
  isFullWidth,
  match,
  showNewChip = false,
  text,
  to,
  ...props
}) => {
  const isMatched = useMatch(`${match ?? to}/*`);

  return (
    <EnhancedMenuItem
      isActive={isActive || Boolean(isMatched)}
      isFullWidth={isFullWidth}
      showNewChip={showNewChip}
      text={text}
      to={to}
      {...props}
    >
      {children}
    </EnhancedMenuItem>
  );
};

export default NavMenuItem;
