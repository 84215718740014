// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { SHIRT_SIZE_OPTIONS } from 'utils/constants/user';

// Local Dependencies
import CustomSelect from './CustomSelect';

// Component Definition
const ShirtSizeSelect: FC = () => (
  <CustomSelect
    label="Shirt Size"
    name="shirtSize"
    options={SHIRT_SIZE_OPTIONS}
  />
);

export default ShirtSizeSelect;
