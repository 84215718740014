// External Dependencies
import { ComponentType, FC } from 'react';
import { Navigate } from 'react-router-dom';

// Internal Dependencies
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getToken } from 'utils/cookies';
import Maintenance from 'pages/Maintenance';

// Local Typings
interface Props {
  component: ComponentType<any>; // The component to render when not logged in
}

// Component Definition
const NoAuth: FC<Props> = ({ component: Component, ...rest }) => {
  const isLoggedIn = Boolean(getToken());

  // Show maintenance page if the system is in maintenance mode
  if (getIsMaintenanceMode()) {
    return <Maintenance />;
  }

  // If logged in, redirect to the home page
  if (isLoggedIn) {
    return (
      <Navigate
        replace
        to="/"
      />
    );
  }

  // If not logged in, render the provided component
  return <Component {...rest} />;
};

export default NoAuth;
