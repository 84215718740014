// External Dependencies
import { Pen } from 'mdi-material-ui';
import { UserRoles } from '@presto-assistant/api_types';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedAlert, Flex } from 'components/shared';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
}

// Component Definition
const InitialsBlock = ({
  disabled,
  formBlock,
}: Props): JSX.Element => {
  const {
    self,
  } = useSelfQuery();

  const isAdult = self?.role.id === UserRoles.Adult.toString();

  const selfInitials = self ? `${self.firstName[0]}${self.lastName[0]}` : '';

  const formikContext = useFormikContext<GQL.ISubmitFormBlockResponseInput>();

  const initials = formikContext.values.response;

  const handleClickInitialButton = useCallback(() => {
    formikContext.setFieldValue('response', selfInitials);
  }, [formikContext, selfInitials]);

  const handleClickClearInitialsButton = useCallback(() => {
    formikContext.setFieldValue('response', '');
  }, [formikContext]);

  const { adultInitials } = formBlock.metadata;

  const canEdit = isAdult || !adultInitials;

  const action = useMemo(() => {
    if (disabled) {
      return null;
    }

    return initials ? (
      <IconButton
        onClick={handleClickClearInitialsButton}
        size="small"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ) : (
      <Button
        onClick={handleClickInitialButton}
        startIcon={<Pen />}
        variant="outlined"
      >
        Initial
      </Button>
    );
  }, [
    disabled,
    handleClickClearInitialsButton,
    handleClickInitialButton,
    initials,
  ]);

  const parentInitialText = useMemo(() => {
    if (initials) {
      return null;
    }

    return (
      <EnhancedAlert>
        Only parents can initial here
      </EnhancedAlert>
    );
  }, [initials]);

  return (
    <>
      <Typography
        paragraph
        sx={{ fontSize: '1.1rem', fontWeight: 500 }}
        variant="h6"
      >
        {formBlock.label}
        {formBlock.isRequired ? ' *' : ''}
      </Typography>

      <Flex gap={1}>
        {initials && (
          <Typography
            fontFamily="Calibri"
            fontSize="1.5rem"
            fontStyle="italic"
          >
            {initials}
          </Typography>
        )}

        {canEdit ? action : parentInitialText}
      </Flex>
    </>
  );
};

export default InitialsBlock;
