// Internal Dependencies
import { getEndingSchoolYear } from 'utils/lib/date_helpers';
import { useGetOrganization } from 'gql/queries';

// Hook Definition
export const useGetOrganizationSchoolYear = () => {
  const {
    data: organizationData,
  } = useGetOrganization();

  return organizationData?.organization.currentSchoolYearEnding
    ?? getEndingSchoolYear();
};
