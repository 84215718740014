import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

const GET_GENDERS = gql`
  query Genders {
    genders {
      id
      label
    }
  }
`;

export const useGetGenders = () =>
  useQueryEnhanced<{
    genders: GQL.IGender[]
  }>(GET_GENDERS);
