// External Dependencies
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { close } from 'state/ui/organizationLogoUploadDialog/actions';
import { isOpen } from 'state/ui/organizationLogoUploadDialog/selectors';
import { useCreateOrganizationLogo } from 'gql/mutations';
import { useFileUploader } from 'components/shared/DialogFileUpload/hooks';
import { useImageCrop } from 'components/shared/ImageCrop/hooks';

// Local Variables
export const acceptedFileTypes = [UPLOAD_FILE_TYPES.jpeg, UPLOAD_FILE_TYPES.png];

export const useOrganizationLogoUploader = () => {
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useDispatch();

  const isDialogOpen = useSelector(isOpen);

  const closeDialog = useCallback(() => {
    dispatch(close());
  }, [close]);

  const {
    handleClickInput,
    hiddenInput,
  } = useFileUploader({
    acceptedFileTypes,
    inputId: 'organization-logo-input',
    onFileUpload: setFile,
  });

  const [
    createOrganizationLogo,
    {
      loading: isSubmitting,
    }] = useCreateOrganizationLogo({
    onCompleted: closeDialog,
  });

  const handleSaveLogo = useCallback((dataUrl: string) => {
    createOrganizationLogo({
      variables: {
        input: {
          base64: dataUrl,
        },
      },
    });
  }, []);

  const {
    editorRef,
    handleChangeScale,
    handleClickSave,
    handleResetScale,
    scale,
    src,
  } = useImageCrop({
    file,
    onSave: handleSaveLogo,
  });

  useEffect(() => {
    if (isDialogOpen) {
      setFile(null);
      handleResetScale();
    }
  }, [isDialogOpen]);

  return useMemo(() => ({
    editorRef,
    file,
    handleChangeScale,
    handleClickInput,
    handleClickSave,
    hiddenInput,
    isSubmitting,
    scale,
    setFile,
    src,
  }), [
    editorRef,
    file,
    handleChangeScale,
    handleClickInput,
    handleClickSave,
    hiddenInput,
    isSubmitting,
    scale,
    setFile,
    src,
  ]);
};
