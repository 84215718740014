// External Dependencies
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';

// Internal Dependencies
import { hasPermission } from 'state/self/selectors';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface EmailDraftIndexResponse {
  emailDraftsIndex: GQL.IEmailIndexItemAll;
}
interface EmailScheduledIndexResponse {
  emailScheduledIndex: GQL.IEmailIndexItemAll;
}
interface EmailIndexResponse {
  emailsIndex: GQL.IEmailIndexItemAll;
}

const GET_EMAIL = gql`
  query Email($id: ID!) {
    email(id: $id) {
      attachments {
        id
        fileName
        s3Url
      }
      body
      createdAt
      hasDeliveryInsights
      id
      lastSavedAt
      onlySentToAdults
      recipients {
        email
        firstName
        id
        lastName
        memberRoleId
        memberRoleLabel
        
        # email events
        processedAt
        deliveredAt
        firstOpenedAt
        lastOpenedAt
      }
      submittedAt
      scheduledSendAt
      senderName
      sentAt
      subject
    }
  }
`;

const GET_EMAIL_DRAFTS_INDEX = gql`
  query EmailDraftsIndex($queryParams: IndexQueryParams) {
    emailDraftsIndex(queryParams: $queryParams) {
      data {
        attachmentCount
        createdAt
        id
        recipientCount
        subject
      }
      fullCount
    }
  }
`;

const GET_EMAIL_SCHEDULED_INDEX = gql`
  query EmailScheduledIndex($queryParams: IndexQueryParams) {
    emailScheduledIndex(queryParams: $queryParams) {
      data {
        attachmentCount
        createdAt
        id
        recipientCount
        scheduledSendAt
        sentAt
        subject
        submittedAt
      }
      fullCount
    }
  }
`;

export const GET_EMAILS_INDEX = gql`
  query EmailsIndex($queryParams: IndexQueryParams) {
    emailsIndex(queryParams: $queryParams) {
      data {
        attachmentCount
        createdAt
        id
        recipientCount
        scheduledSendAt
        sentAt
        subject
      }
      fullCount
    }
  }
`;

const GET_CC_EMAIL_LIST = gql`
  query CcEmailList {
    ccEmailList{
      email
      id
    }
  }
`;

const GET_ALL_CC_EMAILS = gql`
  query AllCcEmails {
    ccEmailList {
      email
      id
    }
    directorsIndex {
      data {
        email
        id
      }
    }
  }
`;

export const useGetEmail = (id: string | undefined) =>
  useQueryEnhanced<{ email: GQL.IEmailShow }>(
    GET_EMAIL,
    {
      fetchPolicy: 'network-only', // this ensures we do not clear the content when loading a draft
      skip: !id,
      variables: {
        id,
      },
    },
  );

export const useGetEmailDraftsIndex = () =>
  usePaginatedListQuery<
    EmailDraftIndexResponse,
    GQL.IEmailIndexItem
  >({
    dataSelector: (data) => data.emailDraftsIndex.data,
    fullCountSelector: (data) => data.emailDraftsIndex.fullCount,
    query: GET_EMAIL_DRAFTS_INDEX,
  });

export const useGetEmailIndex = () =>
  usePaginatedListQuery<EmailIndexResponse, GQL.IEmailIndexItem>(
    {
      dataSelector: (data) => data.emailsIndex.data,
      fullCountSelector: (data) => data.emailsIndex.fullCount,
      query: GET_EMAILS_INDEX,
    },
  );

export const useGetEmailScheduledIndex = () =>
  usePaginatedListQuery<
    EmailScheduledIndexResponse,
    GQL.IEmailIndexItem
  >({
    dataSelector: (data) => data.emailScheduledIndex.data,
    fullCountSelector: (data) => data.emailScheduledIndex.fullCount,
    query: GET_EMAIL_SCHEDULED_INDEX,
  });

export const useGetCcEmailList = () =>
  useQueryEnhanced<{ ccEmailList: GQL.ICcEmail[] }>(GET_CC_EMAIL_LIST);

export const useGetAllCcEmails = () => {
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));

  return useQueryEnhanced<{
    ccEmailList: GQL.ICcEmail[];
    directorsIndex: GQL.IDirectorsIndexAll;
  }>(
    GET_ALL_CC_EMAILS,
    {
      skip: !canEmailMembers,
    },
  );
};
