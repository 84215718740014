// External Dependencies
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  ShowCard,
  ShowPageDataDisplay,
  UserLabelWithLink,
} from 'components/shared';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import LabelWithLink from 'components/shared/LabelWithLink';

// Local Typings
interface Props {
  financialItemId: string;
  financialItemLabel: string;
  originalPriceInCents: number;
  remainingBalanceInCents: number;
  user: GQL.IUser;
}

// Component Definition
const FinancialFeeShowCard = ({
  financialItemId,
  financialItemLabel,
  originalPriceInCents,
  remainingBalanceInCents,
  user,
}: Props): JSX.Element => {
  return (
    <ShowCard
      canEdit={false}
      icon={ShoppingCartIcon}
      title="Financial Fee"
    >
      <ShowPageDataDisplay
        label="Assigned To"
        value={(
          <UserLabelWithLink user={user} />
        )}
      />

      <ShowPageDataDisplay
        label="Item"
        value={(
          <LabelWithLink
            label={financialItemLabel}
            to={`/${PATHS.FINANCIAL_ITEMS}/${financialItemId}`}
          />
        )}
      />

      <ShowPageDataDisplay
        label="Remaining Balance"
        value={displayPriceStringFromDollarAmount(
          convertCentsToDollars(remainingBalanceInCents),
        )}
      />

      <ShowPageDataDisplay
        label="Original Price"
        value={displayPriceStringFromDollarAmount(
          convertCentsToDollars(originalPriceInCents),
        )}
      />
    </ShowCard>
  );
};

export default FinancialFeeShowCard;
