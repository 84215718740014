import { DynamicFields } from 'types/api';

export const applyDynamicFields = (
  input: DynamicFields | undefined,
): DynamicFields => ({
  dynamicField01: input?.dynamicField01?.toString() ?? '',
  dynamicField02: input?.dynamicField02?.toString() ?? '',
  dynamicField03: input?.dynamicField03?.toString() ?? '',
  dynamicField04: input?.dynamicField04?.toString() ?? '',
  dynamicField05: input?.dynamicField05?.toString() ?? '',
  dynamicField06: input?.dynamicField06?.toString() ?? '',
  dynamicField07: input?.dynamicField07?.toString() ?? '',
  dynamicField08: input?.dynamicField08?.toString() ?? '',
  dynamicField09: input?.dynamicField09?.toString() ?? '',
  dynamicField10: input?.dynamicField10?.toString() ?? '',
  dynamicField11: input?.dynamicField11?.toString() ?? '',
  dynamicField12: input?.dynamicField12?.toString() ?? '',
  dynamicField13: input?.dynamicField13?.toString() ?? '',
  dynamicField14: input?.dynamicField14?.toString() ?? '',
  dynamicField15: input?.dynamicField15?.toString() ?? '',
  dynamicField16: input?.dynamicField16?.toString() ?? '',
  dynamicField17: input?.dynamicField17?.toString() ?? '',
  dynamicField18: input?.dynamicField18?.toString() ?? '',
  dynamicField19: input?.dynamicField19?.toString() ?? '',
  dynamicField20: input?.dynamicField20?.toString() ?? '',
  dynamicField21: input?.dynamicField21?.toString() ?? '',
  dynamicField22: input?.dynamicField22?.toString() ?? '',
  dynamicField23: input?.dynamicField23?.toString() ?? '',
  dynamicField24: input?.dynamicField24?.toString() ?? '',
  dynamicField25: input?.dynamicField25?.toString() ?? '',
  dynamicField26: input?.dynamicField26?.toString() ?? '',
  dynamicField27: input?.dynamicField27?.toString() ?? '',
  dynamicField28: input?.dynamicField28?.toString() ?? '',
  dynamicField29: input?.dynamicField29?.toString() ?? '',
  dynamicField30: input?.dynamicField30?.toString() ?? '',
  dynamicField31: input?.dynamicField31?.toString() ?? '',
  dynamicField32: input?.dynamicField32?.toString() ?? '',
  dynamicField33: input?.dynamicField33?.toString() ?? '',
  dynamicField34: input?.dynamicField34?.toString() ?? '',
  dynamicField35: input?.dynamicField35?.toString() ?? '',
  dynamicField36: input?.dynamicField36?.toString() ?? '',
  dynamicField37: input?.dynamicField37?.toString() ?? '',
  dynamicField38: input?.dynamicField38?.toString() ?? '',
  dynamicField39: input?.dynamicField39?.toString() ?? '',
  dynamicField40: input?.dynamicField40?.toString() ?? '',
  dynamicField41: input?.dynamicField41?.toString() ?? '',
  dynamicField42: input?.dynamicField42?.toString() ?? '',
  dynamicField43: input?.dynamicField43?.toString() ?? '',
  dynamicField44: input?.dynamicField44?.toString() ?? '',
  dynamicField45: input?.dynamicField45?.toString() ?? '',
  dynamicField46: input?.dynamicField46?.toString() ?? '',
  dynamicField47: input?.dynamicField47?.toString() ?? '',
  dynamicField48: input?.dynamicField48?.toString() ?? '',
  dynamicField49: input?.dynamicField49?.toString() ?? '',
  dynamicField50: input?.dynamicField50?.toString() ?? '',
});
