// External Dependencies
import {
  FC, RefObject,
} from 'react';
import { Slider } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';

// Local Typings
interface Props {
  editorRef: RefObject<AvatarEditor>;
  handleChangeScale: (event: Event, newScale: (number | number[])) => void;
  scale: number;
  src: string;
}

// Local Variables
const cropSize = 300;

// Component Definition
const ImageCrop: FC<Props> = ({
  editorRef,
  handleChangeScale,
  scale,
  src,
}) => (
  <>
    <AvatarEditor
      ref={editorRef}
      image={src}
      width={cropSize}
      height={cropSize}
      border={20}
      borderRadius={cropSize / 2}
      color={[0, 0, 0, 0.5]} // RGBA
      scale={scale / 50}
      rotate={0}
    />

    <Slider
      aria-label="Small"
      onChange={handleChangeScale}
      sx={{ marginY: 2 }}
      value={scale}
    />
  </>
);

export default ImageCrop;
