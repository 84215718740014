// External Dependencies
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import InventoryCheckoutFormAlert from 'pages/DistrictAdmin/AdminForms/shared/InventoryCheckoutFormAlert';

// Local Dependencies
import InventoryTable from './InventoryTable';
import LinkToDeletedInventoryItems from './LinkToDeletedInventoryItems';

// Component Definition
const AdminInventory = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Inventory Items
      </Subtitle>

      <InventoryCheckoutFormAlert />

      <Box mb={4}>
        <EnhancedCard>
          <InventoryTable />
        </EnhancedCard>
      </Box>

      <LinkToDeletedInventoryItems />
    </>
  );
};

export default AdminInventory;
