// External Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount, formatPhoneNumber } from 'utils';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import formatCCExp from 'utils/lib/formatCCExp';

// Local Typings
export interface UseTextFieldResult {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  value: string;
}
export interface UseTextFieldOptions {
  format?: 'default' | 'ccExp' | 'currency' | 'tel';
  max?: number;
}

// Local Variables
const convertToCurrency = (inputValue: string) => {
  const centsString = inputValue.replaceAll('.', '').replaceAll(',', '').replace('$', '');

  if (!Number.isNaN(Number(centsString))) {
    const cents = Number(centsString);
    const dollars = convertCentsToDollars(cents);

    return displayPriceStringFromDollarAmount(dollars, { hideDollarSign: true });
  }

  return undefined;
};

// Hook Definition
function useTextField(initialValue: string = '', options?: UseTextFieldOptions): UseTextFieldResult {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { value: newValue } = evt.target;

      let formattedValue = newValue;

      if (options?.format === 'ccExp') {
        formattedValue = formatCCExp(newValue);
      } else if (options?.format === 'tel') {
        formattedValue = formatPhoneNumber(newValue);
      } else if (options?.format === 'currency') {
        const convertedValue = convertToCurrency(newValue);

        if (convertedValue !== undefined) {
          formattedValue = convertedValue;
        }
      }

      if (options?.max && formattedValue.length <= options.max) {
        setValue(formattedValue);
      } else if (!options?.max) {
        setValue(formattedValue);
      }
    },
    [],
  );

  const handleResetValue = useCallback(() => {
    setValue('');
  }, []);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return useMemo<UseTextFieldResult>(() => ({
    onChange: handleChange,
    onReset: handleResetValue,
    value,
  }), [handleChange, handleResetValue, value]);
}

export default useTextField;
