// External Dependencies
import { Button } from '@mui/material';
import { FC } from 'react';
import { MutationFunction } from '@apollo/client';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { RESEND_DISTRICT_INVITATION } from 'gql/mutations';
import { addNotification } from 'state/notifications/actions';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface Props {
  invitationId: string;
}

// Local Variables
function handleResendEmail(
  invitationId: string,
  mutation: MutationFunction,
) {
  // eslint-disable-next-line func-names
  return function () {
    mutation({
      variables: { invitationId },
    });
  };
}

// Component Definition
const ResendEmailButton: FC<Props> = ({ invitationId }) => {
  const dispatch = useDispatch();

  function handleComplete() {
    dispatch(addNotification('Email resent!', 'success'));
  }

  const [mutation] = useMutationEnhanced(
    RESEND_DISTRICT_INVITATION,
    {
      onCompleted: handleComplete,
    },
  );

  return (
    <Button
      color="primary"
      onClick={handleResendEmail(invitationId, mutation)}
    >
      Resend Invitation Email
    </Button>
  );
};

export default ResendEmailButton;
