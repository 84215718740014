// External Dependencies
import { FC } from 'react';
import {
  ListItem,
  ListItemText,
} from '@mui/material';

// Local Typings
interface Props {
  loginUsername: string | null;
}

// Component Definition
const LoginUsernameListItem: FC<Props> = ({
  loginUsername,
}) => (
  <ListItem>
    <ListItemText
      primary="Login Username"
      secondary={loginUsername ?? 'N/A'}
    />
  </ListItem>
);

export default LoginUsernameListItem;
