// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetDeletedInventoryItem } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import InventoryForm from 'pages/Inventory/shared/InventoryForm';

// Component Definition
const AdminDeletedInventoryShow: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetDeletedInventoryItem(id!);

  const districtDeletedInventoryItemsParams = useSelector(tableQueryParams('districtDeletedInventoryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Deleted Inventory Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_DELETED}${districtDeletedInventoryItemsParams}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <InventoryForm
        canUseDynamicFields
        inventoryItem={data?.deletedInventoryItem}
        isAdmin
        isDeleted
        readOnly
        showEdit={false}
      />

      {data?.deletedInventoryItem && (
        <History
          id={data.deletedInventoryItem.id}
          resource="inventoryItems"
        />
      )}
    </Page>
  );
};

export default AdminDeletedInventoryShow;
