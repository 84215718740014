// External Dependencies
import { FC, useMemo } from 'react';
import {
  calculateStripeFee,
  calculateVancoFee,
} from '@presto-assistant/api_types/utils/calculateFees';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, ShowCardHeader, ShowPageDataDisplay } from 'components/shared';
import { convertCentsToDollars, convertDollarsToCents, displayPriceStringFromDollarAmount } from 'utils';

// Local Dependencies
import { PaymentsFormValues } from '../PaymentTable';

// Local Typings
interface Props {
  hasFees: boolean;
  onEdit: () => void;
  paymentAmounts: PaymentsFormValues;
  paymentPlatform: 'stripe' | 'vanco';
}

// Local Variables
const StyledStrong = styled.strong({ fontSize: '1.2em' });

const useGetSubtotalInCents = (
  paymentAmounts: PaymentsFormValues | null,
) => useMemo(
  () => {
    const subtotalAmountInDollars = paymentAmounts?.payments
      .reduce((prev, curr) => prev + Number(curr.amountInDollars), 0) ?? 0;

    return convertDollarsToCents(subtotalAmountInDollars);
  },
  [paymentAmounts],
);

export const useGetStripeFeeFromPaymentAmounts = (
  paymentAmounts: PaymentsFormValues | null,
) => {
  const subtotalAmountInCents = useGetSubtotalInCents(paymentAmounts);

  return calculateStripeFee(subtotalAmountInCents);
};
export const useGetVancoFeeFromPaymentAmounts = (
  paymentAmounts: PaymentsFormValues | null,
) => {
  const subtotalAmountInCents = useGetSubtotalInCents(paymentAmounts);

  return calculateVancoFee(subtotalAmountInCents);
};

// Component Definition
const PaymentAmountsCard: FC<Props> = ({
  hasFees,
  onEdit,
  paymentAmounts,
  paymentPlatform,
}) => {
  const subtotalAmountInCents = useGetSubtotalInCents(paymentAmounts);

  const stripeFeeInCents = useGetStripeFeeFromPaymentAmounts(paymentAmounts);
  const vancoFeeInCents = useGetVancoFeeFromPaymentAmounts(paymentAmounts);

  const feeInCents = paymentPlatform === 'stripe' ? stripeFeeInCents : vancoFeeInCents;

  const paymentPlatformFeeInCents = hasFees ? feeInCents : 0;

  return (
    <EnhancedCard>
      <ShowCardHeader
        action={(
          <IconButton
            aria-label="Edit payment amount"
            onClick={onEdit}
            size="large"
          >
            <EditIcon />
          </IconButton>
        )}
        title="Payment Amount"
      />

      <CardContent>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {paymentAmounts.payments.map((payment) => (
            <ShowPageDataDisplay
              align="right"
              key={payment.financialFeeId}
              label={`${payment.userName} — ${payment.itemLabel}`}
              type="currency"
              value={convertDollarsToCents(payment.amountInDollars)}
            />
          ))}

          <Box width="100%">
            <Divider />
          </Box>

          <ShowPageDataDisplay
            align="right"
            label="Subtotal"
            value={(
              <strong>
                {displayPriceStringFromDollarAmount(
                  convertCentsToDollars(subtotalAmountInCents),
                )}
              </strong>
            )}
          />

          {hasFees && (
            <ShowPageDataDisplay
              align="right"
              label="Non-refundable Processing Fee"
              value={(
                <strong>
                  {displayPriceStringFromDollarAmount(
                    convertCentsToDollars(paymentPlatformFeeInCents ?? 0),
                  )}
                </strong>
              )}
            />
          )}

          <ShowPageDataDisplay
            align="right"
            label="Total Payment Amount (USD)"
            value={(
              <StyledStrong>
                {displayPriceStringFromDollarAmount(
                  convertCentsToDollars(subtotalAmountInCents + (paymentPlatformFeeInCents ?? 0)),
                )}
              </StyledStrong>
            )}
          />
        </Box>
      </CardContent>
    </EnhancedCard>
  );
};

export default PaymentAmountsCard;
