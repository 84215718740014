// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { useGetEmailIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import EmailTableZeroState from './EmailTableZeroState';

// Component Definition
const EmailTable: FC = () => {
  const {
    data,
    isLoading,
  } = useGetEmailIndex();

  const handleClickTableRow = (id: string) => `/${PATHS.EMAIL}/${id}`;

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={{
          hideIcon: true,
          label: 'Compose Email',
          to: `/${PATHS.EMAIL_NEW}`,
        }}
        clickRowTo={handleClickTableRow}
        columns={columns}
        components={{
          NoRowsOverlay: EmailTableZeroState,
        }}
        loading={isLoading}
        rows={data ?? []}
        tableResource="emails"
        withSearch
      />
    </DataGridContainer>
  );
};

export default EmailTable;
