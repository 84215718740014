// External Dependencies
import { CSVFileInfoResource } from '@presto-assistant/api_types/api/gql';
import gql from 'graphql-tag';

// Internal Dependencies
import { useLazyQueryEnhanced, useQueryEnhanced } from 'utils/lib/graphql';

const GET_CSV_FILE_INFO = gql`
  query CSVFileInfo(
    $resource: String!
    $organizationTypeId: ID
  ) {
    csvFileInfo(
      resource: $resource
      organizationTypeId: $organizationTypeId
    ) {
      optionalHeaders
      requiredHeaders
    }
  }
`;

const csvTemplateDownload = gql`
  query CSVFileInfo(
    $resource: String!
    $organizationTypeId: ID
  ) {
    csvTemplateDownload(
      resource: $resource
      organizationTypeId: $organizationTypeId
    )
  }
`;

export interface CSVFileInfo {
  optionalHeaders: string[];
  requiredHeaders: string[];
}

export const useGetCSVFileInfo = (
  resource: CSVFileInfoResource,
  organizationTypeId?: string,
) => useQueryEnhanced<{
  csvFileInfo: CSVFileInfo;
}, {
  organizationTypeId?: string;
  resource: CSVFileInfoResource;
}>(
  GET_CSV_FILE_INFO,
  { variables: { organizationTypeId, resource } },
);

export const useCSVTemplateDownload = (
  resource: CSVFileInfoResource,
  organizationTypeId?: string,
) => useLazyQueryEnhanced<{
  csvTemplateDownload: boolean;
}, {
  organizationTypeId?: string;
  resource: CSVFileInfoResource
}>(
  csvTemplateDownload,
  {
    fetchPolicy: 'network-only',
    variables: { organizationTypeId, resource },
  },
);
