// External Dependencies
import {
  FC, useCallback, useMemo,
} from 'react';
import { Formik, FormikHelpers } from 'formik';
import { createUncategorizedFinancialFeeSchema } from '@presto-assistant/api_types/schemas/financialFees';

// Internal Dependencies
import { getEndingSchoolYear } from 'utils/lib/date_helpers';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import UncategorizedFeeFormFormikForm, { FeeFormValues, normalizePayload } from './UncategorizedFeeFormFormikForm';

// Local Typings
interface Props {
  onSubmit?: (values: GQL.ICreateUncategorizedFinancialFeeInput) => void;
}

// Component Definition
const UncategorizedFeeForm: FC<Props> = ({
  onSubmit,
}) => {
  const { data: orgData } = useGetOrganization();

  const initialValues: FeeFormValues = useMemo(() => ({
    item: {
      canApplyFundraiserCredits: false, // we will not show this in the UI
      financialAccountId: '',
      isActive: true,
      label: '',
      priceInCents: 0,
      schoolYearEnding: orgData?.organization?.currentSchoolYearEnding ?? getEndingSchoolYear(),
    },
    userId: '',
  }), [orgData]);

  const handleFormikSubmit = useCallback(async (
    values: FeeFormValues,
    formikProps: FormikHelpers<FeeFormValues>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(normalizePayload(values));

    setSubmitting(false);
  }, [onSubmit]);

  return (
    <Formik<FeeFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={createUncategorizedFinancialFeeSchema}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setTouched,
        touched,
        validateForm,
        values: formikValues,
      }) => (
        <UncategorizedFeeFormFormikForm
          errors={errors}
          formikValues={formikValues}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          touched={touched}
          validateForm={validateForm}
        />
      )}
    </Formik>
  );
};

export default UncategorizedFeeForm;
