// External Dependencies
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  LinearProgress,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import linkifyHtml from 'linkify-html';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedDialog,
  Flex,
  SaveButton,
  TadaSvg,
} from 'components/shared';

// Local Typings
interface Props {
  context: string;
  errorMessage: string;
  isCodeStored: boolean;
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  successDescription: React.ReactNode;
}

// Loval Variables
const StyledDialogContentText = styled(DialogContentText)({
  fontSize: 17,
  paddingTop: 24,
});
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '.MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.stripeBlue[600],
  },
  backgroundColor: theme.palette.stripeBlue[300],
  margin: '48px 0',
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  '.loadingTitle': {
    textAlign: 'center',
  },
  '.sectionTitle': {
    color: theme.palette.common.black,
    marginBottom: 24,
  },
})) as typeof Typography;

// Component Definition
const DialogIntegration: FC<Props> = ({
  context,
  errorMessage,
  isCodeStored,
  isDialogOpen,
  onCloseDialog,
  successDescription,
}) => {
  const errorContent = (
    <StyledTypography
      className="sectionTitle"
      component="span"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line max-len
        // the API might provide a link to google security page if failed to get refresh token for google calendar
        __html: sanitizeHtml(linkifyHtml(errorMessage)),
      }}
      variant="subtitle1"
    />
  );

  const dialogContent = isCodeStored ? (
    <Flex
      alignContent="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography
        className="sectionTitle"
        component="h3"
        variant="h5"
      >
        {context} is Synced!
      </Typography>

      <TadaSvg
        height="160px"
        width="160px"
      />

      <StyledDialogContentText>
        {successDescription}
      </StyledDialogContentText>
    </Flex>
  ) : (
    <>
      <StyledTypography
        className="sectionTitle loadingTitle"
        variant="subtitle1"
      >
        {`Syncing ${context}`}
      </StyledTypography>

      <StyledLinearProgress
        color="primary"
        variant="query"
      />
    </>
  );

  return (
    <EnhancedDialog
      fullWidth
      id="verification-layout-dialog"
      maxWidth="xs"
      open={isDialogOpen}
    >
      {(!isCodeStored && !errorMessage)
        ? <DialogTitle>{context} Sync</DialogTitle>
        : null}
      {errorMessage ? <DialogTitle>{context} Sync Error</DialogTitle> : null}

      <Fade in>
        <DialogContent>
          <DialogContentText component="div">
            {errorMessage ? errorContent : dialogContent}
          </DialogContentText>
        </DialogContent>
      </Fade>

      {(errorMessage || isCodeStored) && (
        <DialogActions>
          <SaveButton onClick={onCloseDialog}>Ok</SaveButton>
        </DialogActions>
      )}
    </EnhancedDialog>
  );
};

export default DialogIntegration;
