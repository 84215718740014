// External Dependencies
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import {
  GridRowId,
} from '@mui/x-data-grid-pro';

// Internal Dependencies
import { SimpleGroup, useGetGroupsAll } from 'gql/queries';
import { TableDataGrid } from 'components/shared/index';

// Local Dependencies
import { ReturningGroupsState } from '.';
import { useColumns } from './hooks';
import ReturningGroupsTableEmptyState from './ReturningGroupsTableEmptyState';

// Local Typings
interface Props {
  returningGroups: ReturningGroupsState[];
  setReturningGroups: Dispatch<SetStateAction<ReturningGroupsState[]>>
}
interface ReturningGroupRow {
  id: string;
  label: string;
  memberCount: number;
  shouldRetainUsers: boolean;
}

// Component Definition
const ReturningGroupsTable = ({
  returningGroups,
  setReturningGroups,
}: Props): JSX.Element => {
  const returningGroupIds = useMemo(() =>
    returningGroups?.map((group) => group.groupId), [returningGroups]);

  const {
    data: simpleGroupsData,
    loading: isLoading,
  } = useGetGroupsAll();

  // Refine simpleGroupsData to build table rows with the correct shouldRetainUsers value
  const rows = simpleGroupsData?.groups?.data.map((group: SimpleGroup) => {
    const shouldRetainUsers = returningGroups?.find((groupFromState) =>
      groupFromState.groupId === group.id)?.shouldRetainUsers;

    const groupRow: ReturningGroupRow = {
      id: group.id,
      label: group.label,
      memberCount: group.userCount,
      // We search through local state to find id that matches
      //  and then use that id's `shouldRetainUsers` value,
      shouldRetainUsers: shouldRetainUsers ?? true,
    };

    return groupRow;
  });

  const handleSetGroups = useCallback((groupIds: GridRowId[]) => {
    const newSelectedGroups = groupIds.map((id) => {
      const shouldRetainUsers = returningGroups?.find((groupFromState) =>
        id === groupFromState.groupId)?.shouldRetainUsers;

      return {
        // The id type defaults to `GridRowId` so we cast it back to a string
        groupId: id as string,
        shouldRetainUsers: shouldRetainUsers ?? true,
      };
    });

    setReturningGroups(newSelectedGroups);
  }, [returningGroups, setReturningGroups]);

  const handleCheckShouldRetainUsers = (
    id: string,
    shouldRetainUsers: boolean,
  ) => {
    // If the user is unchecking the retain members switch, then
    //  we find the element's index and flip the boolean
    const indexOfGroup = returningGroups?.findIndex((groupFromState) =>
      groupFromState.groupId === id);

    const returningGroupsCopy = [...returningGroups];

    returningGroupsCopy[indexOfGroup] = {
      groupId: id,
      shouldRetainUsers,
    };

    setReturningGroups(returningGroupsCopy);
  };

  const columns = useColumns({
    onPressSwitch: handleCheckShouldRetainUsers,
  });

  return (
    <TableDataGrid
      checkboxSelection
      columns={columns}
      components={{
        NoRowsOverlay: ReturningGroupsTableEmptyState,
      }}
      hideExport
      loading={isLoading}
      onSelectionModelChange={handleSetGroups}
      rows={rows || []}
      selectionModel={returningGroupIds}
      withSearch
    />
  );
};

export default ReturningGroupsTable;
