// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import DomainIcon from '@mui/icons-material/Domain';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { isDistrictAdmin } from 'state/self/selectors';

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.stripeBlue['300']
    : theme.palette.stripeBlue['600'],
}));

// Component Definition
const DistrictCardHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const isDFA = useSelector(isDistrictAdmin);

  const handleClickEditDistrict = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS_DISTRICT_INFO}`);
  }, [navigate]);

  return (
    <CardHeader
      action={isDFA && (
        <IconButton
          aria-label="Edit District"
          onClick={handleClickEditDistrict}
          size="large"
        >
          <EditIcon />
        </IconButton>
      )}
      avatar={(
        <StyledAvatar>
          <DomainIcon aria-label="District information" />
        </StyledAvatar>
      )}
      title={(
        <Typography
          component="h2"
          variant="h6"
        >
          District
        </Typography>
      )}
    />
  );
};

export default DistrictCardHeader;
