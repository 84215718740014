// External Dependencies
import { DocumentNode } from 'graphql';
import {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { TableResource, updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { getIndexQueryParams, useMutationEnhanced } from 'utils/lib/graphql';
import {
  isTableSelectionActive,
  tableQueryParams,
  tableSelection,
} from 'state/table/selectors';
import { pluralize } from 'utils';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  actionVerb?: 'Delete' | 'Remove';
  additionalVariables?: object;
  clearCachePredicates?: string[];
  context?: [string] | [string, string, string];
  hasSelection?: boolean;
  idsToDelete?: string[];
  isOpen: boolean;
  mutation: DocumentNode;
  onClose: () => void;
  onRemoveSelectionAll?: () => void;
  reduxTableKey: TableResource;
  refetchQueries?: () => string[];
  singleItemId?: string | null;
  size: number;
  withNote?: boolean;
}
type SelectionType = 'SelectedOne' | 'SelectedMany' | undefined;

// Local Variables
const getSelectionType = (numberOfIds: number): SelectionType => {
  if (numberOfIds === 1) {
    return 'SelectedOne';
  }

  if (numberOfIds > 1) {
    return 'SelectedMany';
  }

  return undefined;
};

// Component Definition
const DeleteDialog: FC<Props> = ({
  actionVerb = 'Delete',
  additionalVariables = {},
  clearCachePredicates = [],
  context = ['item'],
  hasSelection = true,
  idsToDelete,
  isOpen,
  mutation,
  onClose,
  onRemoveSelectionAll,
  reduxTableKey,
  refetchQueries,
  singleItemId,
  size,
  withNote,
}) => {
  const deletedNoteField = useTextField();

  const dispatch = useDispatch();

  const selection = useSelector(tableSelection(reduxTableKey));

  const queryString = useSelector(tableQueryParams(reduxTableKey));
  const isSelectionActive = useSelector(isTableSelectionActive(reduxTableKey));

  const [singularContext, pluralContext = `${singularContext}s`, parentContext] = context;

  const ids = useMemo(() => (!isSelectionActive && singleItemId
    ? [singleItemId]
    : selection.ids), [isSelectionActive, selection.ids, singleItemId]);

  const queryParams = getIndexQueryParams(queryString);

  let selectionLength = idsToDelete?.length || ids.length || 0;

  if (selection.selectionType === 'SelectedAllExcept') {
    selectionLength = size - selection.ids.length;
  }

  let selectionType: string | undefined;

  if (idsToDelete && idsToDelete.length) {
    selectionType = getSelectionType(idsToDelete.length);
  } else if (ids && ids.length) {
    selectionType = getSelectionType(ids.length);
  } else {
    selectionType = selection.selectionType;
  }

  const descriptionText = `
    You'll no longer be able to view
    ${pluralize(selectionLength, `this ${singularContext}`, `these ${pluralContext}`)}${parentContext ? ` in this ${parentContext}.` : '.'}
  `;

  const handleCompleted = useCallback(() => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));

    onClose();

    if (hasSelection && onRemoveSelectionAll) {
      onRemoveSelectionAll();
    }
  }, [dispatch, hasSelection, onClose, onRemoveSelectionAll]);

  const [
    deleteMutation,
    {
      loading: isSubmitting,
    },
  ] = useMutationEnhanced(
    mutation,
    {
      awaitRefetchQueries: true,
      clearCachePredicates,
      onCompleted: handleCompleted,
      refetchQueries,
    },
  );

  const handleDelete = useCallback(() => {
    const newQueryParams = { ...queryParams };

    delete newQueryParams.orderBy;
    delete newQueryParams.limit;
    delete newQueryParams.page;

    const mutationPayload = hasSelection
      ? {
        variables: {
          ...(withNote && { deletedNote: deletedNoteField.value }),
          selection: {
            ids: idsToDelete || ids,
            mutationFlag: selectionType,
            queryParams: newQueryParams,
          },
          ...additionalVariables,
        },
      }
      : {
        variables: {
          ...(withNote && { deletedNote: deletedNoteField.value }),
          selection: {
            ids: singleItemId,
            mutationFlag: selectionType,
            queryParams: newQueryParams,
          },
        },
      };

    deleteMutation(mutationPayload);
  }, [
    additionalVariables,
    deleteMutation,
    deletedNoteField.value,
    hasSelection,
    ids,
    idsToDelete,
    queryParams,
    selectionType,
    singleItemId,
    withNote,
  ]);

  useEffect(() => {
    if (!isOpen) {
      deletedNoteField.onReset();
    }
  }, [deletedNoteField, isOpen]);

  const title = `${actionVerb} ${pluralize(selectionLength, `this ${singularContext}`, `these ${pluralContext}`)}?`;

  return (
    <ConfirmationDialog
      confirmButtonAction={handleDelete}
      confirmButtonText={actionVerb}
      declineButtonAction={onClose}
      deletedNoteInputProps={withNote ? deletedNoteField : undefined}
      description={descriptionText}
      handleClose={onClose}
      isSubmitting={isSubmitting}
      open={isOpen}
      title={title}
    />
  );
};

export default DeleteDialog;
