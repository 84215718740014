// External Dependencies
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
}

// Component Definition
const HeadingBlock = ({
  formBlock,
}: Props): JSX.Element => {
  const { content } = formBlock.metadata as { content: string };

  return (
    <Typography variant="h5">
      {content}
    </Typography>
  );
};

export default HeadingBlock;
