// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { CreateNonReturningUsersResponse } from 'gql/mutations/end-of-year-mutations';
import {
  DialogPeoplePicker,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  StyledLink,
  Subtitle,
} from 'components/shared';
import { MutationOptions } from 'utils/lib/graphql';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';
import {
  pluralize,
} from 'utils';
import {
  useGetNonReturningUsers,
} from 'gql/queries';

// Local Dependencies
import { StyledStrong } from '../shared/styles';

// Local Typings
interface Props {
  graduatingGrade: GQL.IOrganization['graduatingGrade'];
  isCollegeOrHasSeniors: boolean;
  onCreateNonReturningUsers: (
    options?: MutationOptions<
      CreateNonReturningUsersResponse,
      GQL.ICreateNonReturningUsersOnMutationArguments
    >
  ) => void;
  organizationType: GQL.IOrganization['organizationType'];
}

// Local Variables
const EnhancedStyledLink = styled(StyledLink)({
  fontSize: 15,
});

// Component Definition
const EndOfYearNonReturningStudents = ({
  graduatingGrade,
  isCollegeOrHasSeniors,
  onCreateNonReturningUsers,
  organizationType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([]);

  const isPeoplePickerDialogOpen = useSelector(isOpen);

  // Get non-returning students in case user has not completed entire process
  const { data: nonReturningUsersData } = useGetNonReturningUsers();

  useEffect(() => {
    // We are using state to keep track of the selected student ids,
    //  so we update the state when the data arrives
    if (nonReturningUsersData) {
      const incomingNonReturningUserIds = nonReturningUsersData.nonReturningUsers
        .map((user) => user.id);

      setSelectedStudentIds(incomingNonReturningUserIds);
    }
  }, [nonReturningUsersData]);

  if (!nonReturningUsersData) {
    return null;
  }

  const hasNonReturningUsers = selectedStudentIds.length > 0;

  const handlePressSelectStudents = () => {
    dispatch(openPeoplePickerDialog());
  };

  const handleUpdateNonReturningStudents = (ids: string[]) => {
    setSelectedStudentIds(ids);
    onCreateNonReturningUsers({
      variables: {
        input: {
          userIds: ids,
        },
      },
    });
  };

  return (
    <>
      <Box mb={2}>
        <Subtitle>Non-returning Students</Subtitle>

        <EnhancedCard>
          <EnhancedCardContent>
            <Collapse in={hasNonReturningUsers}>
              <Typography>
                There {pluralize(selectedStudentIds.length, 'is', 'are')}{' '}
                <StyledStrong>
                  {selectedStudentIds.length}
                </StyledStrong>{' '}
                {pluralize(selectedStudentIds.length, 'student')}{' '}
                marked as{' '}
                <strong>&quot;Not returning&quot;</strong>{' '}
                for next school year.{' '}
              </Typography>

              <Box
                marginBottom={1}
                marginTop={3}
              >
                <EnhancedStyledLink onClick={handlePressSelectStudents}>
                  View/Update Students
                </EnhancedStyledLink>
              </Box>
            </Collapse>

            <Collapse in={!hasNonReturningUsers}>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Typography>Which students are not returning next year?</Typography>

                <Button
                  color="primary"
                  onClick={handlePressSelectStudents}
                  size="small"
                  variant="outlined"
                >
                  Select Students
                </Button>
              </Box>
            </Collapse>

            <EnhancedAlert sx={{ marginTop: 3 }}>
              <Typography
                gutterBottom={!isCollegeOrHasSeniors}
                variant="body2"
              >
                You can add new students for next year
                after completing all End of Year steps.
              </Typography>

              {!isCollegeOrHasSeniors && (
                <Typography variant="body2">
                  Students in {graduatingGrade}th grade should be marked as
                  returning if they plan to participate in {organizationType.label.toLocaleLowerCase()}{' '}
                  next year. This will automatically add them to their successor
                  organization and remove them from your organization.
                </Typography>
              )}
            </EnhancedAlert>
          </EnhancedCardContent>
        </EnhancedCard>
      </Box>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateNonReturningStudents}
        preSelectedIds={selectedStudentIds}
        tableResource="peoplePickerEndOfYearNonReturningStudents"
        title="Select Non-Returning Students"
      />
    </>
  );
};

export default EndOfYearNonReturningStudents;
