// External Dependencies
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { InviteAdmin } from 'components/shared';

// Local Dependencies
import DashboardCard from './DashboardCard';

// Local Typings
interface Props {
  districtName: string | undefined;
  orgName?: string;
}

// Local Variables
const StyledDashboardCard = styled(DashboardCard)(({ theme }) => ({
  '.cardHeader': {
    padding: 0,
  },
  '.districtCardName': {
    justifyContent: 'center',
  },
  '.districtCardTitle': {
    backgroundColor: theme.palette.dashboardOrgName,
    padding: theme.spacing(1, 2),
  },
}));

// Component Definition
const DistrictCard = ({
  districtName,
  orgName,
}: Props): JSX.Element => {
  const title = useMemo(() => (
    <Typography
      className="districtCardTitle"
      component="h3"
      variant="h6"
    >
      {districtName || 'No District'}
    </Typography>
  ), [districtName]);

  const content = useMemo(() => (orgName ? (
    <>
      <Typography className="districtCardName">
        {orgName}
      </Typography>

      <InviteAdmin districtName={districtName} />
    </>
  ) : 'District Admin'
  ), [districtName, orgName]);

  return (
    <StyledDashboardCard
      cardHeaderProps={{
        classes: {
          root: 'cardHeader',
        },
        disableTypography: true,
      }}
      content={content}
      title={title}
    />
  );
};

export default DistrictCard;
