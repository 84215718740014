// External Dependencies
import {
  ClassLinkSsoPayload,
  ClassLinkTokenPayload,
} from '@presto-assistant/api_types/api/auth';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { parseSearch } from 'utils/index';
import { setTokenInStorage } from 'utils/cookies';
import { useTanstackMutation } from 'utils/api/index';

export const useClassLinkToken = () => {
  const mutationFn = useCallback((payload: ClassLinkTokenPayload) => {
    return apiClient.auth.classLinkToken(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (res) => {
      const token = res.headers['x-access-token'];

      if (token) {
        setTokenInStorage(token);
      }

      const queryString = `?${getSearchWithRedirectUrl()}`;

      const parsedSearch = parseSearch(queryString);

      if (parsedSearch.redirectUrl) {
        window.location.href = parsedSearch.redirectUrl;
      } else {
        window.location.href = '/';
      }
    },
  });
};

export const useClassLinkSso = () => {
  const mutationFn = useCallback((payload: ClassLinkSsoPayload) => {
    return apiClient.auth.classLinkSso(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (res) => {
      window.location.href = res.data.url;
    },
  });
};
