// External Dependencies
import { QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DynamicFieldsResponse {
  dynamicFields: GQL.IDynamicField[];
}
interface DynamicFieldResponse {
  dynamicField: GQL.IDynamicField;
}
interface DynamicFieldTypesResponse {
  dynamicFieldTypes: GQL.IDynamicFieldType[];
}

export const GET_DYNAMIC_FIELDS = gql`
  query DynamicFields(
    $organizationTypeId: ID
    $tableRef: String!
  ) {
    dynamicFields(
      organizationTypeId: $organizationTypeId
      tableRef: $tableRef
    ) {
      dynamicFieldRef
      id
    label
      organizationType {
        id
        label
      }
      tableRef
      type {
        id
        label
      }
    }
  }
`;

const GET_DYNAMIC_FIELD = gql`
  query DynamicField($id: ID) {
    dynamicField(id: $id) {
      dynamicFieldRef
      id
      label
      organizationType {
        id
        label
      }
      tableRef
      type {
        id
        label
      }
    }
  }
`;

const GET_DYNAMIC_FIELD_TYPES = gql`
  query DynamicFieldTypes {
    dynamicFieldTypes {
      id
      label
    }
  }
`;

export const useGetDynamicFields = (
  args: GQL.IDynamicFieldsOnQueryArguments,
  options?: QueryHookOptions<DynamicFieldsResponse, GQL.IDynamicFieldsOnQueryArguments>,
) =>
  useQueryEnhanced<
    DynamicFieldsResponse,
    GQL.IDynamicFieldsOnQueryArguments
  >(
    GET_DYNAMIC_FIELDS,
    {
      ...options,
      variables: args,
    },
  );

export const useGetDynamicField = (
  args: GQL.IDynamicFieldOnQueryArguments,
  options?: QueryHookOptions,
) =>
  useQueryEnhanced<
    DynamicFieldResponse,
    GQL.IDynamicFieldOnQueryArguments
  >(
    GET_DYNAMIC_FIELD,
    {
      ...options,
      skip: !args.id,
      variables: args,
    },
  );

export const useGetDynamicFieldTypes = () =>
  useQueryEnhanced<DynamicFieldTypesResponse>(GET_DYNAMIC_FIELD_TYPES);
