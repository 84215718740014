import TextField, { TextFieldProps } from '@mui/material/TextField';

const PaymentFormTableInput = (props: TextFieldProps): JSX.Element => {
  return (
    <TextField
      margin="none"
      size="small"
      variant="outlined"
      {...props}
    />
  );
};

export default PaymentFormTableInput;
