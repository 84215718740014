// External Dependencies
import { FC, useState } from 'react';

// Internal Dependencies
import { ConfirmInvitationListContainer } from 'components/shared';
import {
  DistrictInvitation,
  useGetDistrictInvitations,
} from 'gql/queries/district-invitation-queries';
import { PATHS } from 'utils/constants/routes';
import { useUpdateDistrictInvitation } from 'gql/mutations';
import ConfirmInvitationList,
{ InvitationListItem } from 'components/shared/ConfirmInvitationList';

// Local Variables
const getStatus = (invitation: DistrictInvitation): InvitationListItem['status'] => {
  if (invitation.accepted) {
    return 'confirmed';
  }

  if (invitation.accepted === false) {
    return 'rejected';
  }

  return 'pending';
};

// Local Typings
interface Props {
  title?: string;
}

// Component Definition
const ConfirmDistrictInvitations: FC<Props> = ({
  title,
}) => {
  const [itemUpdatingId, setItemUpdatingId] = useState<string | null>(null);

  const resetLoadingId = () => {
    setItemUpdatingId(null);
    window.location.href = `${PATHS.ONBOARDING}/${PATHS.ONBOARDING_MORE_ORGANIZATION_INFO}`;
  };

  const [updateInvitation] = useUpdateDistrictInvitation(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['districtInvitations'],
      onCompleted: resetLoadingId,
      onError: resetLoadingId,
    },
  );

  const handleUpdateInvitation = (id: string, wantsToConfirm: boolean) => () => {
    setItemUpdatingId(id);
    updateInvitation({
      variables: {
        accepted: wantsToConfirm,
        invitationId: id,
      },
    });
  };

  const handleConfirm = (id: string) => handleUpdateInvitation(id, true);

  const handleReject = (id: string) => handleUpdateInvitation(id, false);

  const { data } = useGetDistrictInvitations();

  if (!data) {
    return null;
  }

  const { districtInvitations } = data;

  return districtInvitations.length
    ? (
      <ConfirmInvitationListContainer
        description={districtInvitations.length > 1
          ? 'Confirm a district to connect it to your organization. You may only choose one.'
          : 'Confirm this district to connect it to your organization.'}
        title={title}
      >
        {({ width }) => (
          <ConfirmInvitationList
            itemUpdatingId={itemUpdatingId}
            listItems={districtInvitations.map(((item) => ({
              avatar: 'building',
              id: item.id,
              invitationTimestamp: item.createdAt,
              inviterEmail: item.district.admin.email,
              label: item.district.label,
              status: getStatus(item),
            })))}
            onConfirm={handleConfirm}
            onReject={handleReject}
            width={width}
          />
        )}
      </ConfirmInvitationListContainer>
    )
    : null;
};

export default ConfirmDistrictInvitations;
