// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { APP_NAME } from 'utils/constants';

// Component Definition
const AssistantsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={`Add Assistants to help oversee your ${APP_NAME} district.`}
  />
);

export default AssistantsTableZeroState;
