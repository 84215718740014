// External Dependencies
import { FC, useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import FileCopy from '@mui/icons-material/FileCopy';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';

// Local Typings
interface Props {
  successMessage?: string;
  textToCopy: string;
  tooltipTitle: string;
}

// Component Definition
const CopyToClipboardButton: FC<Props> = ({
  successMessage = 'Copied!',
  textToCopy,
  tooltipTitle,
}) => {
  const dispatch = useDispatch();

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);

    dispatch(addNotification(successMessage, 'success'));
  }, [textToCopy]);

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        onClick={handleCopy}
        size="large"
      >
        <FileCopy />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
