// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const ChecklistsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <>
        <Typography paragraph>
          Use the button at the top to add a new checklist.
        </Typography>

        <Typography>
          Your checklists can include items assigned to specific members.
        </Typography>
      </>
    )}
  />
);

export default ChecklistsTableZeroState;
