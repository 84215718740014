// External Dependencies
import { FormBlockTypes } from '@presto-assistant/api_types';
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  EnhancedCard,
  Flex,
  ShowPageDataDisplay,
} from 'components/shared';
import { useDownloadFormBlockFile } from 'utils/api/forms';
import BlockCardMenu from 'pages/Forms/Edit/BlocksCard/BlockCardMenu';

// Local Typings
interface Props {
  block: GQL.IFormBlock;
  formId: string;
  setDeleteBlockId: (blockId: string) => void;
}

// Component Definition
const BlockCard = ({
  block,
  formId,
  setDeleteBlockId,
}: Props): JSX.Element => {
  const blockTypeId = Number(block.formBlockType.id);

  const {
    isLoading: isDownloading,
    mutate: downloadFormBlockFile,
  } = useDownloadFormBlockFile();

  const handleClickDownload = useCallback(() => {
    downloadFormBlockFile({
      body: {},
      params: {
        formBlockId: block.id,
        formId,
      },
    });
  }, [block.id, downloadFormBlockFile, formId]);

  const content = useMemo(() => {
    if (blockTypeId === FormBlockTypes.Heading) {
      return (
        <Typography variant="h6">
          {block.metadata.content}
        </Typography>
      );
    }

    if (blockTypeId === FormBlockTypes.Paragraph) {
      return (
        <Typography>
          {block.metadata.content}
        </Typography>
      );
    }

    if (blockTypeId === FormBlockTypes.Select) {
      return (
        <>
          <Typography gutterBottom>
            {block.label}
          </Typography>

          <Flex
            flexWrap="wrap"
            gap={1}
          >
            {block.metadata.options.map((option: string) => (
              <Chip
                key={option}
                label={option}
              />
            ))}
          </Flex>
        </>
      );
    }

    if (blockTypeId === FormBlockTypes.Signature) {
      return (
        <>
          <Typography>
            {block.label}
          </Typography>

          <ShowPageDataDisplay
            label="Only adult relatives can sign"
            type="boolean"
            value={block.metadata.adultSignature}
          />
        </>
      );
    }

    if (blockTypeId === FormBlockTypes.Initials) {
      return (
        <>
          <Typography>
            {block.label}
          </Typography>

          <ShowPageDataDisplay
            label="Only adult relatives can initial"
            type="boolean"
            value={block.metadata.adultInitials}
          />
        </>
      );
    }

    if (blockTypeId === FormBlockTypes.FileDownload) {
      return (
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          gap={1}
        >
          <Typography>
            {block.label}
          </Typography>

          <Typography>
            {block.metadata.s3Filename}
          </Typography>

          <Button
            disabled={isDownloading}
            onClick={handleClickDownload}
            size="small"
            startIcon={<CloudDownloadIcon />}
            variant="outlined"
          >
            Download
          </Button>
        </Flex>
      );
    }

    return (
      <Typography>
        {block.label}
      </Typography>
    );
  }, [block, blockTypeId, handleClickDownload, isDownloading]);

  return (
    <EnhancedCard variant="elevation">
      <CardHeader
        action={(
          <BlockCardMenu
            block={block}
            formId={formId}
            setDeleteBlockId={setDeleteBlockId}
          />
        )}
        subheader={block.isFixed ? (
          <Chip
            label="Fixed form block"
            size="small"
          />
        ) : null}
        subheaderTypographyProps={{
          marginTop: 1,
        }}
        title={block.isFixed
          ? block.label
          : block.formBlockType.semanticLabel}
      />

      {!block.isFixed && (
        <CardContent>
          {content}

          {blockTypeId !== FormBlockTypes.Heading
            && blockTypeId !== FormBlockTypes.Paragraph
            && blockTypeId !== FormBlockTypes.FileDownload && (
            <Box marginTop={2}>
              <ShowPageDataDisplay
                label="Required"
                type="boolean"
                value={block.isRequired}
              />
            </Box>
          )}
        </CardContent>
      )}
    </EnhancedCard>
  );
};

export default BlockCard;
