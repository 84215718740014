// External Dependencies
import {
  FC, useCallback, useMemo,
} from 'react';
import { Formik } from 'formik';

// Internal Dependencies
import { feeSchema } from 'pages/Finances/FinancialFees/shared/schema';
import { useCreateManyFinancialFees } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import FeeFormFormikForm, {
  FeeFormValues,
} from 'pages/Finances/FinancialFees/shared/FeeFormFormikForm';

// Local Typings
interface Props {
  memberId: string;
  onClose: () => void;
}

const AddFeeForm: FC<Props> = ({
  memberId,
  onClose,
}) => {
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const memberIds = useMemo(() => [memberId], [memberId]);

  const initialValues: FeeFormValues = useMemo(() => ({
    financialItemId: '',
    schoolYearEnding,
    userIds: [memberId],
  }), [memberId, schoolYearEnding]);

  const [createFinancialFees] = useCreateManyFinancialFees({
    clearCachePredicates: [
      'financialFees',
      'financialFeesIndex',
      'financialFeesOverview',
      'financialItem',
      'user',
    ],
    onCompleted: onClose,
  });

  const handleFormikSubmit = useCallback((input: FeeFormValues) => {
    createFinancialFees({
      variables: {
        input: {
          financialItemId: input.financialItemId,
          userIds: input.userIds,
        },
      },
    });
  }, [createFinancialFees]);

  const handleRemoveUserId = useCallback(() => () => {
    onClose();
  }, [onClose]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={feeSchema}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values: formikValues,
      }) => (
        <FeeFormFormikForm
          errors={errors}
          formikValues={formikValues}
          hideSelectMembers
          isSubmitting={isSubmitting}
          onCancel={onClose}
          onRemoveUserId={handleRemoveUserId}
          onSubmit={handleSubmit}
          rawUserIds={memberIds}
          setFieldValue={setFieldValue}
          touched={touched}
          userIds={[]}
        />
      )}
    </Formik>
  );
};

export default AddFeeForm;
