// External Dependencies
import { IconProps } from '@mui/material/Icon';
import { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Dependencies
import MenuItem, { MenuItemProps } from './MenuItem';

// Local Typings
export interface IconButtonMenuProps {
  IconButtonIcon: any;
  iconProps?: IconProps;
  settingsItems: MenuItemProps[];
  title?: string;
}

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: '1.25rem',
  padding: theme.spacing(1),
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '.MuiListItemButton-root': {
    textAlign: 'center',
  },
  '.MuiPopover-paper': {
    width: 200,
  },
  '.cardContent': {
    padding: theme.spacing(0, 2, 2),
  },
  '.cardHeader': {
    alignItems: 'center',
    display: 'flex',
    fontSize: theme.spacing(2),
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
  },
  '.closeIconButton': {
    marginLeft: theme.spacing(2),
  },
  legend: {
    paddingLeft: 0,
    textTransform: 'uppercase',
  },
}));

const anchorOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};
const transformOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

// Component Definition
const IconButtonMenu = ({
  IconButtonIcon,
  iconProps = {},
  settingsItems,
  title,
}: IconButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'table-settings-popover' : undefined;

  return (
    <>
      <StyledIconButton
        aria-describedby={id}
        aria-label={title || 'Icon button'}
        onClick={handleClick}
      >
        <IconButtonIcon {...iconProps} />
      </StyledIconButton>

      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        {title && (
          <CardContent className="cardHeader">
            <Typography>{title}</Typography>

            <StyledIconButton
              aria-label={`Close ${title}`}
              className="closeIconButton"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </StyledIconButton>
          </CardContent>
        )}

        {settingsItems.map((item) => (
          <MenuItem
            {...item}
            key={item.id}
            onClose={handleClose}
          />
        ))}
      </StyledPopover>
    </>
  );
};

export default IconButtonMenu;
