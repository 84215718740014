// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { APP_NAME, SHORT_APP_NAME } from 'utils/constants';
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  EnhancedContainer,
  Page,
  ShowCardHeader,
  StyledLink,
  VancoLogoSvg,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';
import { vancoUrl } from 'utils/constants/urls';

// Local Dependencies
import CoverOnlineProcessingFeeSetting from '../shared/CoverOnlineProcessingFeeSetting';
import RevokeVancoButton from './RevokeVancoButton';
import SyncVancoButton from './SyncVancoButton';

// Local Variables
const StyledEnhancedContainer = styled(EnhancedContainer)(({ theme }) => ({
  '.MuiListItemText-secondary': {
    [theme.breakpoints.down('md')]: {
      maxWidth: '60%',
    },
    maxWidth: '90%',
  },

  '.actionsSubtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },

  '.avatar': {
    backgroundColor: theme.palette.showPage.people,
  },

  '.trademark': {
    position: 'relative',
    top: 12,
  },

  margin: '0 auto',
  padding: 0,
}));

const noop = () => null;
const title = 'Vanco Settings';

// Component Definition
const VancoSettingsPage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const canWriteFinancialAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  // EmailSettings uses organization, so we want to be sure this loads before rendering
  const { data: organizationData, loading: isLoadingOrganization } = useGetOrganization();

  const hasVancoPcct = organizationData?.organization.hasVancoPcct;
  const hasStripeId = organizationData?.organization.hasStripeId;

  const handleNavigateToSettings = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}`);
  }, [navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.SETTINGS}`,
      }}
      isLoading={isLoadingOrganization}
    >
      <StyledEnhancedContainer>
        <EnhancedCard>
          <ShowCardHeader
            avatar={(
              <Avatar className="avatar">
                <PriceChangeIcon aria-label={title} />
              </Avatar>
            )}
            title={title}
          />

          <CardContent>
            <Box
              alignItems="flex-start"
              component="section"
              display="flex"
              justifyContent="center"
              marginTop={2}
            >
              <a
                href={vancoUrl}
                rel="noreferrer"
                target="_blank"
              >
                <VancoLogoSvg height="96px" />
              </a>

              <span className="trademark">
                &trade;
              </span>
            </Box>
          </CardContent>

          <CardContent>
            <Typography paragraph>
              {APP_NAME} offers an integration with{' '}
              <StyledLink
                href={vancoUrl}
                openInNewTab
              >
                Vanco
              </StyledLink>{' '}
              to securely handle online payments, all without
              leaving {SHORT_APP_NAME}.
            </Typography>

          </CardContent>

          <Divider />

          <CardContent>
            <Typography
              className="actionsSubtitle"
              component="h6"
            >
              Vanco Status
            </Typography>

            <List>
              <ListItem>
                {hasVancoPcct ? (
                  <ListItemIcon>
                    <CheckCircleIcon htmlColor={theme.palette.success.main} />
                  </ListItemIcon>
                ) : null}

                <ListItemText
                  primary={hasVancoPcct
                    ? `Your ${APP_NAME} organization is connected to Vanco.`
                    : 'You have not connected your organization to Vanco.'}
                />
              </ListItem>
            </List>
          </CardContent>

          {hasVancoPcct ? (
            <>
              <Divider />

              <CardContent>
                <CoverOnlineProcessingFeeSetting
                  alert={(
                    <EnhancedAlert
                      title="Cover Vanco fees"
                    >
                      <Typography
                        color="inherit"
                        fontSize="inherit"
                        paragraph
                      >
                        Your organization can cover Vanco fees for members, eliminating their
                        {' '}
                        payment processing fee. You can override this on a per item basis.
                      </Typography>

                      <Typography
                        color="inherit"
                        fontSize="inherit"
                        paragraph
                      >
                        When covering the fees, incorporate them into your pricing.
                        {' '}
                        The fees amount to 3.49% per transaction.
                      </Typography>
                    </EnhancedAlert>
                  )}
                  paymentProvider="Vanco"
                />
              </CardContent>
            </>
          ) : null}

          <Divider />

          <CardContent>
            <Typography
              className="actionsSubtitle"
              component="h6"
            >
              Vanco Actions
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary={`${hasVancoPcct ? 'Vanco is enabled' : 'Enable Vanco'} for online payments`}
                  secondary={!canWriteFinancialAccounts
                    ? `A director with permission to add new Financial Accounts can
                    ${hasVancoPcct ? 'revoke' : 'enable'} Vanco.`
                    : null}
                />

                <ListItemSecondaryAction>
                  {hasVancoPcct
                    ? <RevokeVancoButton />
                    : <SyncVancoButton />}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </EnhancedCard>
      </StyledEnhancedContainer>

      <ConfirmationDialog
        confirmButtonAction={handleNavigateToSettings}
        description="Your organization is linked with a Stripe account. You must first unlink your Stripe account before syncing Vanco."
        handleClose={noop}
        hideDeclineButton
        open={Boolean(hasStripeId)}
        title="Cannot sync Vanco"
      />
    </Page>
  );
};

export default VancoSettingsPage;
