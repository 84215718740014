// External Dependencies
import { FC } from 'react';
import { FieldArray } from 'formik';
import {
  IconButton, List, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';

// Internal Dependencies
import { Flex } from 'components/shared';
import SectionTitle from 'components/shared/MainContentContainer/SectionTitle';

// Local Dependencies
import FileAssignmentForm from './FileAssignmentForm';

// Local Typings
interface Props {
  assignments: GQL.IFileAssignmentsInput[] | null;
  getName: (fieldName: string) => string;
  readOnly?: boolean;
}

// Component Definition
const FileAssignmentFormList: FC<Props> = ({
  assignments,
  getName,
  readOnly,
}) => (
  <FieldArray name={getName('assignments')}>
    {(arrayHelpers) => {
      const handleAddAssignment = () => {
        arrayHelpers.push({
          groupId: '',
          organizationRoleId: '',
        });
      };

      return (
        <>
          <Flex justifyContent="space-between">
            <Flex>
              <SectionTitle title="Assignments" />

              <Typography
                sx={{
                  mb: 1,
                  mx: 2,
                }}
                variant="caption"
              >
                {/* eslint-disable-next-line max-len */}
                (Will be visible to these users within the given date range.)
              </Typography>
            </Flex>

            {!readOnly && (
              <IconButton
                aria-label={(assignments?.length ?? 0) > 0
                  ? 'Add Another File Assignment'
                  : 'Add a File Assignment'}
                color="primary"
                onClick={handleAddAssignment}
                size="large"
              >
                <AddIcon />
              </IconButton>
            )}
          </Flex>

          <List>
            {assignments?.map((assignment, assignmentIndex) => (
              <FileAssignmentForm
                assignmentIndex={assignmentIndex}
                getName={getName}
                // eslint-disable-next-line react/no-array-index-key
                key={assignmentIndex}
                onDeleteAssignment={arrayHelpers.handleRemove(assignmentIndex)}
                readOnly={readOnly}
              />
            ))}
          </List>
        </>
      );
    }}
  </FieldArray>
);

export default FileAssignmentFormList;
