// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Local Dependencies
import SignupAppBar from './SignupAppBar';

// Component Definition
const SignupContainer: FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight="100vh"
    width="100%"
  >
    <SignupAppBar />

    {children}
  </Box>
);

export default SignupContainer;
