// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const AdminLicensesTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="All licenses purchased by your district will appear in this table."
  />
);

export default AdminLicensesTableZeroState;
