// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

// Internal Dependencies
import { Address, EnhancedCard, StyledLink } from 'components/shared';
import { DOWNLOADS, PATHS } from 'utils/constants/routes';
import {
  getFullName,
  pluralize,
} from 'utils';
import { isDistrictAdmin } from 'state/self/selectors';
import CardSectionTitle from 'components/shared/CardSectionTitle';
import ManageDistrictAssistantsListItem from 'pages/DistrictAdmin/AdminSettings/ManageDistrictAssistantsListItem';

// Local Dependencies
import DistrictCardHeader from './DistrictCardHeader';

// Local Typings
interface Props {
  district: GQL.IDistrict;
}

// Local Variables
const useStyles = makeStyles({
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemFirst: {
    fontWeight: 500,
  },
});

const StyledEnhancedCard = styled(EnhancedCard)({
  address: {
    fontStyle: 'normal',
  },
});

// Component Definition
const DistrictDataUI = ({ district }: Props): JSX.Element | null => {
  const navigate = useNavigate();

  const handleNavigateToDistrictSettings = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}/district_settings`);
  }, [navigate]);

  const classes = useStyles();

  const isDistrictAdminUser = useSelector(isDistrictAdmin);

  if (!district) {
    return null;
  }

  const {
    addressOne,
    addressTwo,
    admin,
    city,
    label: districtName,
    phoneNumber,
    state: {
      label: stateLabel,
    },
    zipcode,
  } = district;

  return (
    <StyledEnhancedCard>
      <DistrictCardHeader />

      <CardContent>
        <CardSectionTitle>
          District Info
        </CardSectionTitle>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={districtName}
              primaryTypographyProps={{
                component: 'div',
              }}
              secondary={(
                <Address
                  addressOne={addressOne}
                  addressTwo={addressTwo}
                  city={city}
                  phoneNumber={phoneNumber}
                  state={stateLabel}
                  zipcode={zipcode}
                />
              )}
              secondaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>

        <CardSectionTitle>
          District Administrator
        </CardSectionTitle>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getFullName(admin)}
              primaryTypographyProps={{
                component: 'div',
              }}
              secondary={(
                <address>
                  {admin.email}
                </address>
              )}
              secondaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>

        {/* District Admin "district" data will not have districtAssistants */}
        {district.districtAssistants?.length ? (
          <>
            <CardSectionTitle>
              {district.districtAssistants && pluralize(district.districtAssistants.length, 'District Assistant')}
            </CardSectionTitle>

            <List>
              {district.districtAssistants.map((districtAssistant) => (
                <ListItem
                  className={classes.listItem}
                  key={districtAssistant.email}
                >
                  <ListItemText
                    primary={getFullName(districtAssistant)}
                    primaryTypographyProps={{
                      component: 'div',
                    }}
                    secondary={(
                      <address>
                        {districtAssistant.email}
                      </address>
                    )}
                    secondaryTypographyProps={{
                      component: 'div',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : null}
      </CardContent>

      <Divider />

      <CardContent>
        <CardSectionTitle>
          Downloads
        </CardSectionTitle>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={(
                <StyledLink
                  href={DOWNLOADS.ACCEPTABLE_USE_POLICY_AGREEMENT}
                  openInNewTab
                >
                  Acceptable Use Policy Agreement
                </StyledLink>
              )}
              primaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>
      </CardContent>

      {isDistrictAdminUser && (
        <>
          <Divider />

          <CardContent>
            <CardSectionTitle>
              District Actions
            </CardSectionTitle>

            <List>
              <ManageDistrictAssistantsListItem />
            </List>

          </CardContent>

          <Divider />

          <CardContent>
            <CardSectionTitle>
              District Settings
            </CardSectionTitle>

            <Button
              onClick={handleNavigateToDistrictSettings}
              size="small"
              sx={{
                marginTop: 1,
              }}
              variant="outlined"
            >
              View District Settings
            </Button>
          </CardContent>
        </>
      )}
    </StyledEnhancedCard>
  );
};

export default DistrictDataUI;
