// External Dependencies
import { PATHS } from 'utils/constants/routes';
import { useLocation } from 'react-router-dom';

// Internal Dependencies
import { Page } from 'components/shared';

// Local Dependencies
import DynamicFieldForm from '../shared/DynamicFieldForm';

// Component Definition
const DynamicFieldsNew = (): JSX.Element => {
  const { search } = useLocation();

  return (
    <Page
      backButtonProps={{
        label: 'Dynamic Fields',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${search}`,
      }}
    >
      <DynamicFieldForm />
    </Page>
  );
};

export default DynamicFieldsNew;
