import { useMemo } from 'react';

export const useFlatFileTypes = (acceptedFileTypes: string[]) => useMemo(
  () =>
    acceptedFileTypes
      .flatMap((type) =>
        type
          .split(',')
          .map((value) =>
            value.trim())),
  [acceptedFileTypes],
);
