// External Dependencies
import { getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import FormSectionCard from './FormSectionCard';
import MyFormBlock from '../MyForms/Show/MyFormBlock';

// Local Typings
interface Props {
  formData: GQL.IForm;
}

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

// Component Definition
const ShowFormCard = ({ formData }: Props): JSX.Element => {
  const { self } = useSelfQuery();

  // Map over the form block and add response keys with an empty string.
  // This lets this "preview" data pretend to be a member's form data.
  const formBlocksWithEmptyResponses = formData?.formBlocks.map((block) => ({
    ...block,
    __typename: 'MyFormBlock',
    response: block.sampleResponse ?? '',
  })) as GQL.IMyFormBlock[];

  const groupedFormBlocks = useMemo(() => {
    const grouped: GQL.IMyFormBlock[][] = [];

    formBlocksWithEmptyResponses.forEach((block) => {
      const lastGroup = grouped[grouped.length - 1];

      if (!lastGroup) {
        grouped.push([block]);
        return;
      }

      const lastBlock = lastGroup[lastGroup.length - 1];

      if (lastBlock.isFixed && block.isFixed) {
        lastGroup.push(block);
        return;
      }

      grouped.push([block]);
    });

    return grouped;
  }, [formBlocksWithEmptyResponses]);

  return (
    <StyledContainer maxWidth="md">
      <EnhancedAlert title="Form Preview">
        <Typography gutterBottom>
          Explore how this form appears to assigned members.
        </Typography>

        <Typography>
          Interaction is enabled for testing purposes only.
          No data will be submitted from this preview.
        </Typography>
      </EnhancedAlert>

      <FormSectionCard
        hasNoPadding
        hasTopBorder
      >
        <EnhancedCardContent>
          <Typography variant="h4">
            {formData?.title ?? ''}
          </Typography>
        </EnhancedCardContent>

        {formData && self && (
          <>
            <Divider />

            <EnhancedCardContent>
              <ShowPageDataDisplay
                label="Assigned To"
                value={getFullNameWithEmail(self)}
              />

            </EnhancedCardContent>
          </>
        )}
      </FormSectionCard>

      {groupedFormBlocks.map((group, index) => (
        <FormSectionCard
          key={group.map((block) => block.id).join('—')}
        >
          {group.map((block) => (
            <MyFormBlock
              formAssignmentId="preview"
              formBlock={block}
              formId={formData?.id ?? ''}
              index={index}
              key={block.id}
              readOnly
            />
          ))}
        </FormSectionCard>
      ))}

      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button variant="contained">
          Submit
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default ShowFormCard;
