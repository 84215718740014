// External Dependencies
import { StripeStatus } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { TableColumn, displayCell } from 'components/shared/TableV2';
import StripeStatusChip, { renderPaymentType } from 'pages/Finances/FinancialPayments/shared/StripeStatusChip';

// Local Variables
const getRefundText = (stripeRefundStatusId: number | null) => {
  if (stripeRefundStatusId === StripeStatus.Failed) {
    return 'Refund Failed';
  }

  if (stripeRefundStatusId === StripeStatus.Pending) {
    return 'Pending Refund';
  }

  return 'Refunded';
};

export const useColumns = (
  extraColumns?: TableColumn<GQL.IFinancialPaymentIndexItem>[],
): TableColumn<GQL.IFinancialPaymentIndexItem>[] => useMemo(() => {
  const columns: TableColumn<GQL.IFinancialPaymentIndexItem>[] = [
    {
      Header: 'First',
      accessor: (row) => displayCell(row.userFirstName),
      sortBy: 'userFirstName',
    },
    {
      Header: 'Last',
      accessor: (row) => displayCell(row.userLastName),
      sortBy: 'userLastName',
    },
    {
      Header: 'Item',
      accessor: (row) => displayCell(row.financialItemLabel),
      sortBy: 'financialItemLabel',
    },
    {
      Header: 'Date Added',
      accessor: (row) => displayCell(row.createdAt, { format: 'datetime' }),
      sortBy: 'createdAt',
    },
    {
      Header: 'Payment Amount',
      accessor: ({
        amountInCents,
        refundedAt,
        stripeRefundStatusId,
      }) => (
        <>
          {refundedAt ? (
            <StripeStatusChip
              stripePaymentStatusId={stripeRefundStatusId === StripeStatus.Failed
                ? stripeRefundStatusId
                : null}
              label={getRefundText(stripeRefundStatusId)}
              size="small"
              sx={{ mr: 1 }}
            />
          ) : null}
          {displayCell(amountInCents, { format: 'money' })}
        </>
      ),
      align: 'right',
      sortBy: 'amountInCents',
    },
    {
      Header: 'Overpayment',
      accessor: (row) => displayCell(row.creditGeneratedInCents, { format: 'money' }),
      align: 'right',
      sortBy: 'creditGeneratedInCents',
    },
    {
      Header: 'Amount Applied',
      accessor: (row) => displayCell(row.amountInCents + row.creditAppliedInCents - row.creditGeneratedInCents, { format: 'money' }),
      align: 'right',
    },
    {
      Header: 'Credit Applied',
      accessor: (row) => displayCell(row.creditAppliedInCents, { format: 'money' }),
      align: 'right',
      sortBy: 'creditAppliedInCents',
    },
    {
      Header: 'Type',
      accessor: (row) => renderPaymentType({
        financialPaymentTypeId: Number(row.financialPaymentTypeId),
        financialPaymentTypeLabel: row.financialPaymentTypeLabel,
        stripePaymentStatusId: row.stripePaymentStatusId,
      }),
      sortBy: 'financialPaymentTypeLabel',
    },
    {
      Header: 'Check #',
      accessor: (row) => displayCell(row.checkNumber),
      sortBy: 'checkNumber',
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
