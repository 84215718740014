// External Dependencies
import { FormTypes, OrgTypes, OrganizationEntityTypes } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { capitalize } from 'utils';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { isDistrictAdmin } from 'state/self/selectors';
import GridWrapper from 'pages/Forms/components/DetailsCard/GridWrapper';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  form: GQL.IForm;
}

// Component Definition
const ReadOnlyFields = ({
  form,
}: Props): JSX.Element => {
  const isDfa = useSelector(isDistrictAdmin);

  const isStandardForm = form.formTypeId === FormTypes.Standard;

  return (
    <GridWrapper>
      <ShowPageDataDisplay
        label="Title"
        value={form?.title}
      />

      <ShowPageDataDisplay
        label="School Year"
        value={form?.schoolYearEnding
          ? displaySchoolYearString(form.schoolYearEnding) : null}
      />

      <ShowPageDataDisplay
        label="Form Type"
        value={(
          <>
            {toTitleCase(FormTypes[form.formTypeId])}

            {form.formTypeId === FormTypes.InventoryCheckout && (
              <Typography
                component="span"
                display="block"
                marginTop={1}
                variant="caption"
              >
                Inventory checkout forms will automatically be assigned
                to members when an inventory item is checked out.
              </Typography>
            )}
          </>
          )}
      />

      {isDfa && isStandardForm && (
        <>
          <ShowPageDataDisplay
            label="Organization Entity Types"
            value={form?.organizationEntityTypeIds?.map((entity) => toTitleCase(OrganizationEntityTypes[entity])).join(', ')}
          />

          <ShowPageDataDisplay
            label="Organization Types"
            value={form?.organizationTypeIds?.map((type) => OrgTypes[type]).join(', ')}
          />

          <ShowPageDataDisplay
            label="Assigned To"
            value={form?.assignTo?.map((assignment) => capitalize(assignment)).join(', ')}
          />
        </>
      )}
    </GridWrapper>
  );
};

export default ReadOnlyFields;
