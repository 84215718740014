// External Dependencies
import { QueryHookOptions } from '@apollo/client';

// Internal Dependencies
import { GET_SELF } from 'gql/queries';
import { clearTokens, getCookieToken } from 'utils/cookies';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Hook Definition
const useSelfQuery = (options?: QueryHookOptions) => {
  const token = getCookieToken();

  const {
    data,
    error,
    loading,
    refetch,
  } = useQueryEnhanced<{ self: GQL.ISelf }>(
    GET_SELF,
    {
      ...options,
      skip: !token || options?.skip,
    },
  );

  // if there was an error, log them out
  if (error) {
    clearTokens();
  }

  const self = data ? data.self : null;

  return {
    error,
    loading,
    refetch,
    self,
  };
};

export default useSelfQuery;
