// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const addSelfData = createActionCreator(
  'ADD_SELF_DATA',
  (resolve) => (isDistrictAdmin) => resolve(isDistrictAdmin),
);

export const logoutCurrentUser = createActionCreator(
  'LOGOUT_CURRENT_USER',
);
