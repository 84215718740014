// External Dependencies
import { FC, useState } from 'react';

// Internal Dependencies
import { GET_USERS_BY_IDS } from 'gql/queries';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface ChildrenProps {
  data: GQL.IUser[];
  loading: boolean;
}
interface Props {
  children: (props: ChildrenProps) => any;
  ids: string[];
}
interface QueryResponse {
  usersByIds: GQL.IUser[];
}

// Component Definition
const Users: FC<Props> = (props) => {
  const [users, setUsers] = useState<GQL.IUser[]>([]);

  const handleComplete = (newData: QueryResponse) => {
    const newUsers = newData.usersByIds;
    setUsers(newUsers);
  };

  const { loading } = useQueryEnhanced<QueryResponse>(
    GET_USERS_BY_IDS,
    {
      onCompleted: handleComplete,
      partialRefetch: true,
      variables: {
        ids: props.ids,
      },
    },
  );

  return props.children({
    data: users.filter((user) => props.ids.includes(user.id)),
    loading,
  });
};

export default Users;
