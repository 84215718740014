// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';

// Local Dependencies
import RedeemLicenseButton from '../RedeemLicenseButton';
import RedemptionCode from '../RedemptionCode';

// Local Typings
interface IUseColumnsProps {
  handleSetLicense: (license: GQL.ILicensesIndex) => void;
  organizationData: GQL.IDistrictOrganizationIndexItem[] | undefined;
}

// Local Variables
export const useColumns = ({ handleSetLicense, organizationData }: IUseColumnsProps) =>
  useMemo(() => {
    const columns: DataGridColDef<GQL.ILicensesIndex>[] = [
      dateColumn({
        field: 'purchasedAt',
        headerName: 'Purchased',
      }),
      dateColumn({
        field: 'redeemedAt',
        headerName: 'Redeemed',
      }),
      {
        field: 'organizationLabel',
        headerName: 'Organization',
        renderCell: (params) => {
          if (params.row.organizationLabel) {
            return params.row.organizationLabel;
          }

          return (
            <RedeemLicenseButton
              license={params.row}
              onSelectLicense={handleSetLicense}
            />
          );
        },
        type: 'singleSelect',
        valueOptions: () => {
          if (organizationData) {
            return organizationData.map((org) => {
              return { label: org.label, value: org.label };
            });
          }

          return [];
        },
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
      },
      {
        field: 'redemptionCode',
        headerName: 'Redemption Code',
        minWidth: 360,
        renderCell: (params) => <RedemptionCode code={params.value} />,
      },
      {
        field: 'numberOfMonths',
        headerName: 'Number of Months',
        type: 'number',
      },
      {
        field: 'authorizedBy',
        headerName: 'Purchased By',
      },
      dateColumn({
        field: 'expiresAt',
        headerName: 'Use By',
      }),
    ];

    return columns;
  }, [handleSetLicense, organizationData]);
