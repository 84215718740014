// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Local Dependencies
import RecipientList from './RecipientList';

// Local Typings
interface Props {
  email: GQL.IEmailShow;
}

// Component Definition
const EmailTitle: FC<Props> = ({ email }) => (
  <>
    <Typography
      component="h2"
      sx={{ mb: 2 }}
      variant="h6"
    >
      {email.subject}
    </Typography>

    <Typography
      component="p"
      variant="body2"
    >
      <strong>From: </strong>{email.senderName}
    </Typography>

    <RecipientList recipients={email.recipients} />

    <Typography
      component="p"
      variant="body2"
    >
      <strong>Only sent to adults:</strong> {email.onlySentToAdults ? 'Yes' : 'No'}
    </Typography>
  </>
);

export default EmailTitle;
