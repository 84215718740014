// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetUniform } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import UniformForm from 'pages/Uniforms/shared/UniformForm';

// Component Definition
const AdminUniformsShow: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetUniform(id!);

  const districtUniformsParams = useSelector(tableQueryParams('districtUniforms'));

  return (
    <Page
      backButtonProps={{
        label: 'All Uniforms',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`,
      }}
      editButtonPath={`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${id}/edit`}
      error={error}
      isLoading={!data || loading}
    >
      <UniformForm
        canUseDynamicFields
        isAdmin
        readOnly
        title="Uniform Details"
        uniform={data?.uniform}
      />

      {data?.uniform && (
        <History
          id={data.uniform.id}
          resource="uniforms"
        />
      )}
    </Page>
  );
};

export default AdminUniformsShow;
