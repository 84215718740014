// Internal Dependencies
import {
  SettingsCardWrapper,
  SettingsWrapper,
} from 'components/shared/Settings/styles';

// Local Dependencies
import AdminSettingsDistrictData from './AdminSettingsDistrictData';
import BillingSettingsCard from './BillingSettingsCard';
import DistrictIntegrationSettings from './DistrictIntegrationSettings';

// Component Definition
const AdminSettings = (): JSX.Element => {
  return (
    <SettingsWrapper>
      <SettingsCardWrapper>
        <AdminSettingsDistrictData />
      </SettingsCardWrapper>

      <SettingsCardWrapper>
        <BillingSettingsCard />
      </SettingsCardWrapper>

      <SettingsCardWrapper>
        <DistrictIntegrationSettings />
      </SettingsCardWrapper>
    </SettingsWrapper>
  );
};

export default AdminSettings;
