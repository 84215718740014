// External Dependencies
import { Divider } from '@mui/material';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { addMemberToGroupSchema } from '@presto-assistant/api_types/schemas/group';

// Internal Dependencies
import {
  CustomSelect, Flex, FormActionButtons, SubscriberAddButton,
} from 'components/shared';
import { useAddMemberToGroup } from 'gql/mutations';
import { useGetGroupsAll } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  activeGroupIds: string[];
  memberId: string;
}

// Component Definition
const AddToGroup: FC<Props> = ({
  activeGroupIds,
  memberId,
}) => {
  const {
    isOpen: isFormOpen,
    toggleIsOpen: toggleIsFormOpen,
  } = useIsOpen();

  const { data } = useGetGroupsAll();

  const hasLoaded = Boolean(data?.groups);

  const [addMemberToGroup, {
    loading: isSubmitting,
  }] = useAddMemberToGroup({
    onCompleted: toggleIsFormOpen,
  });

  const handleFormikSubmit = (values: GQL.IAddMemberToGroupInput) => {
    addMemberToGroup({
      variables: {
        input: {
          groupId: values.groupId,
          memberId: values.memberId,
        },
      },
    });
  };

  const unassignedGroups = data?.groups.data
    .filter((group) => !activeGroupIds.includes(group.id)) ?? [];

  if (unassignedGroups.length === 0) {
    return null;
  }

  return isFormOpen ? (
    <>
      <Divider sx={{ marginBottom: 1 }} />

      <Formik
        initialValues={{ groupId: '', memberId }}
        onSubmit={handleFormikSubmit}
        validationSchema={addMemberToGroupSchema}
      >
        {() => (
          <Form>
            <CustomSelect
              fullWidth
              label="Group"
              name="groupId"
              options={unassignedGroups.map((group) => ({
                id: group.id,
                label: group.label,
              }))}
            />

            <Flex
              justifyContent="flex-end"
              width="100%"
            >
              <FormActionButtons
                context="Group Member"
                isSubmitting={isSubmitting}
                onClickCancelButton={toggleIsFormOpen}
              />
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <Flex
      justifyContent="flex-end"
      marginTop={2}
    >
      <SubscriberAddButton
        disabled={!hasLoaded}
        label="Group"
        onClick={toggleIsFormOpen}
      />
    </Flex>
  );
};

export default AddToGroup;
