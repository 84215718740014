// External Dependencies
import { Container } from '@mui/material';
import styled from 'styled-components';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';

// Local Variables
const EnhancedContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  width: CONTAINER_WIDTH,
}));

export default EnhancedContainer;
