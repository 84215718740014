// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import { StyledLink, ZeroState } from '..';

// Component Definition
const OrganizationZeroState: FC = () => {
  const { data: organizationData } = useGetOrganization();

  const districtAdminEmail = organizationData?.organization.district?.admin.email;

  const dfaEmailHref = districtAdminEmail ? `mailto:${districtAdminEmail}` : undefined;

  return (
    <ZeroState
      maxTextWidth="90%"
      message={dfaEmailHref ? (
        <span>
          Please contact your
          {' '}
          <StyledLink
            href={dfaEmailHref}
            openInNewTab
          >
            district administrator
          </StyledLink>
          {' '}
          to use dynamic fields.
        </span>
      ) : 'This organization must belong to a district to use dynamic fields.'}
    />
  );
};

export default OrganizationZeroState;
