// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import {
  UpdateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@presto-assistant/api_types/utils';
import { updateFundraiserCreditSchema } from '@presto-assistant/api_types/schemas/financialFundraiserCredits';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import { Container, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialFundraiserCredit } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateFinancialFundraiserCredit } from 'utils/api/financialFundraiserCredit';

// Local Dependencies
import UpdateFinancialFundraiserCreditForm from './UpdateFInancialFundraiserCreditForm';

// Component Definition
const FinancialFundraiserCreditsEdit: FC = () => {
  const { id } = useParamsWithId();

  const navigate = useNavigate();

  const {
    data: fundraiserCreditData,
    loading: isLoading,
  } = useGetFinancialFundraiserCredit(id ?? '');

  const fundraiserCredit = fundraiserCreditData?.financialFundraiserCredit;

  const initialValues = useMemo<UpdateFinancialFundraiserCreditRequest['body']>(() => ({
    defaultAmountInCents: convertCentsToDollars(fundraiserCredit?.amountInCents ?? 0),
    items: (fundraiserCredit?.items ?? []).map((item) => ({
      financialItemId: item.financialItem.id,
      priority: item.priority,
    })),
    label: fundraiserCredit?.label ?? '',
  }), [
    fundraiserCredit,
  ]);

  const {
    isLoading: isSubmitting,
    mutate: updateFinancialFundraiserCredit,
  } = useUpdateFinancialFundraiserCredit(id ?? '');

  const handleSubmit = useCallback((values: UpdateFinancialFundraiserCreditRequest['body']) => {
    const defaultAmountInCents = convertDollarsToCents(values.defaultAmountInCents);

    updateFinancialFundraiserCredit({
      ...values,
      defaultAmountInCents,
    }, {
      onSuccess: (data) => {
        navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${data.data.fundraiserCredit.id}`);
      },
    });
  }, [
    navigate,
    updateFinancialFundraiserCredit,
  ]);

  const fundraiserCreditsParams = useSelector(
    tableQueryParams('financialFundraiserCredits'),
  );

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`);
  }, [fundraiserCreditsParams, navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'Financial Fundraiser Credit',
        path: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${id}`,
      }}
      isLoading={isLoading}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <Formik<UpdateFinancialFundraiserCreditRequest['body']>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={updateFundraiserCreditSchema}
        >
          {({
            errors,
            touched,
            values,
          }) => (
            <UpdateFinancialFundraiserCreditForm
              errors={errors}
              financialAccountIdFormikValue={fundraiserCredit?.financialAccount.id ?? ''}
              isIndividualizedFormikValue={fundraiserCredit?.isIndividualized ?? false}
              isSubmitting={isSubmitting}
              itemsFormikValue={values.items}
              onPressCancelOrBackButton={handlePressCancelOrBackButton}
              schoolYearEndingFormikValue={Number(fundraiserCredit?.schoolYearEnding ?? 0)}
              touched={touched}
            />
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default FinancialFundraiserCreditsEdit;
