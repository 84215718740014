// External Dependencies
import { FC } from 'react';
import { UserRoles } from '@presto-assistant/api_types';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import LabelWithLink from './LabelWithLink';

// Local Typings
interface Props {
  user: GQL.IUser;
}

// Component Definition
const UserLabelWithLink: FC<Props> = ({
  user,
}) => {
  const userShowPath = user.role.id === UserRoles.Adult.toString()
    ? `/${PATHS.PARENTS}/${user.id}` : `/${PATHS.STUDENTS}/${user.id}`;

  return (
    <LabelWithLink
      label={getFullName(user)}
      to={userShowPath}
    />
  );
};

export default UserLabelWithLink;
