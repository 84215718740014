// External Dependencies
import { DistrictListLibraryRequest } from '@presto-assistant/api_types/api/v1/library';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useGetDistrictLibraryIndex = (queryParams: DistrictListLibraryRequest['query']) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.library.districtIndex(queryParams);
    },
    queryKey: ['districtLibrary', queryParams],
  });
};

export const useGetDistrictLibraryIndexReport = () => {
  return useTanstackMutation({
    mutationFn: (queryParams: DistrictListLibraryRequest['query']) => {
      return apiClient.v1.library.districtIndex({
        ...queryParams,
        exportReport: true,
      });
    },
  });
};
