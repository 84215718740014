// External Dependencies
import Typography from '@mui/material/Typography';

// Local Dependencies
import DistrictZeroState from './DistrictZeroState';
import OrganizationZeroState from './OrganizationZeroState';

// Local Typings
interface Props {
  isAdmin: boolean;
  showSimpleeZeroState?: boolean;
}

// Component Definition
const DynamicFormFieldsZeroState = ({
  isAdmin,
  showSimpleeZeroState,
}: Props): JSX.Element => {
  if (showSimpleeZeroState) {
    return (
      <Typography color="textSecondary">
        No dynamic fields added yet.
      </Typography>
    );
  }

  if (isAdmin) {
    return <DistrictZeroState />;
  }

  return <OrganizationZeroState />;
};

export default DynamicFormFieldsZeroState;
