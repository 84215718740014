// External Dependencies
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedAlert, EnhancedCard, Subtitle } from 'components/shared';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/lib/local_storage';
import { hasPermission } from 'state/self/selectors';

// Local Dependencies
import LinkToInactiveUsers from '../../shared/LinkToInactiveUsers';
import LinkToPreviousYearMembers from '../../shared/LinkToPreviousYearMembers';
import ParentTable from './ParentTable';

// Component Definition
const Parents = (): JSX.Element => {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  const localStorageHasRemovedAlert = getLocalStorageItem(
    'hasRemovedParentsTableFinancesAlert',
  );
  const shouldSeeAlert = canReadFinances || canReadPayments;

  const [isAlertOpen, setIsAlertOpen] = useState(shouldSeeAlert && !localStorageHasRemovedAlert);

  const handleRemoveAlert = useCallback(() => {
    setLocalStorageItem('hasRemovedParentsTableFinancesAlert', 'true');
    setIsAlertOpen(false);
  }, []);

  return (
    <>
      <Subtitle>
        Parents
      </Subtitle>

      {shouldSeeAlert && (
        <EnhancedAlert
          isAlertOpen={isAlertOpen}
          onClose={handleRemoveAlert}
          sx={{ marginBottom: 2 }}
        >
          * Financial data in this table is updated hourly.
          Data shown is for the parent, not their student(s).
        </EnhancedAlert>
      )}

      <EnhancedCard>
        <ParentTable />
      </EnhancedCard>

      <LinkToInactiveUsers />

      <LinkToPreviousYearMembers />
    </>
  );
};

export default Parents;
