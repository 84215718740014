// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetGroup } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useUpdateGroup } from 'gql/mutations';

// Local Dependencies
import GroupsForm from '../shared/GroupsForm';

// Component Definition
const GroupsEdit: FC = () => {
  const { id } = useParamsWithId();
  const navigate = useNavigate();

  const showGroupParams = useSelector(tableQueryParams('group'));

  const pathToGroupShowPage = `/${PATHS.GROUPS}/${id}/${showGroupParams}`;

  const { data } = useGetGroup(id);

  const navigateToShow = useCallback(() => {
    console.log('group edit (2)');
    navigate(pathToGroupShowPage);
  }, [navigate, pathToGroupShowPage]);

  const [updateGroup] = useUpdateGroup(
    {
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.IUpdateGroupInput,
  ) =>
    updateGroup({
      variables: {
        id: id!,
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'Group Details',
        path: pathToGroupShowPage,
      }}
    >
      {data?.group && (
        <GroupsForm
          initialValues={{
            label: data.group.label,
            userIds: data.group.users.userList.map((user) => user.id),
          }}
          onSubmit={handleSubmitForm}
        />
      )}
    </Page>
  );
};

export default GroupsEdit;
