// External Dependencies
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { ConfirmationDialog, EnhancedAlert } from 'components/shared';

// Local Typings
interface Props {
  isEditing: boolean;
  isOpen: boolean;
  onSubmit?: () => (Promise<void> | void);
  onToggle: () => void;
}

// Component Definition
const DialogConfirmExistingMember = ({
  isEditing,
  isOpen,
  onSubmit,
  onToggle,
}: Props): JSX.Element => (
  <ConfirmationDialog
    confirmButtonAction={onSubmit!}
    confirmButtonText={isEditing ? 'Update Member' : 'Add Member'}
    declineButtonAction={onToggle}
    declineButtonText="Go Back"
    description={(
      <>
        <DialogContentText paragraph>
          A member with this exact email already exists in this organization.
          {isEditing
            ? 'This change will result in multiple members with the same email address.'
            : 'This change will add another member with the same email address.'}
        </DialogContentText>

        <DialogContentText paragraph>
          You can continue to update this member, change this data,
          or find the original member in
          the Students, Parents, or Directors table.
        </DialogContentText>

        <EnhancedAlert isTip>
          If you cannot find the original member, check in the Inactive Members table.
        </EnhancedAlert>
      </>
    )}
    handleClose={onToggle}
    open={isOpen}
    title="Member Already Exists"
    useCustomText
  />
);

export default DialogConfirmExistingMember;
