// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import { createUserSchema } from '@presto-assistant/api_types/schemas/user';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { CreateUserData } from 'gql/mutations/user-mutations';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateUser } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMemberLookup } from 'gql/queries';
import DialogConfirmExistingMember from 'pages/People/shared/DialogConfirmExistingMember';

// Local Dependencies
import StudentForm, { StudentValues } from '../shared/StudentForm';

// Component Definition
const StudentsNew = (): JSX.Element => {
  const navigate = useNavigate();

  const [payload, setPayload] = useState<GQL.ICreateUserInput | null>(null);

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const navigateToShow = useCallback((data: CreateUserData) => {
    navigate(`/${PATHS.STUDENTS}/${data.createUser.id}`);
  }, [navigate]);

  const [createStudent, { error }] = useCreateUser(
    {
      clearCachePredicates: ['dashboardMetrics', 'studentsIndex'],
      onCompleted: navigateToShow,
    },
  );

  const checkForExistingMemberInThisOrganization = useMemberLookup();

  const handleCreateStudent = () => {
    if (payload) {
      createStudent({
        variables: {
          input: payload,
        },
      });
    }
  };

  const handleSubmit = async (
    {
      grade,
      ...values
    }: StudentValues,
  ) => {
    const localPayload = {
      ...trimValues(values) as unknown as GQL.ICreateUserInput,
      admin: false,
      grade: grade ? Number(grade) : null,
      isEligible: values.isEligible !== undefined
        ? values.isEligible
        : undefined,
      primarySpokenLanguageId: values.primarySpokenLanguageId
        ? Number(values.primarySpokenLanguageId)
        : 1,
      roleId: UserRoles.Student,
    };

    setPayload(localPayload);

    const { data: existingMemberData } = await checkForExistingMemberInThisOrganization({
      memberEmail: values.email,
    });

    const isExistingMember = Boolean(existingMemberData.memberLookup.length);

    if (isExistingMember) {
      handleToggleIsConfirmationDialogOpen();
    } else {
      createStudent({
        variables: {
          input: localPayload,
        },
      });
    }
  };

  return (
    <Page
      backButtonProps={{
        label: 'Students',
        path: `/${PATHS.STUDENTS}`,
      }}
    >
      <StudentForm
        error={error}
        onSubmit={handleSubmit}
        validationSchema={createUserSchema}
      />

      <DialogConfirmExistingMember
        isEditing={false}
        isOpen={isConfirmationDialogOpen}
        onSubmit={handleCreateStudent}
        onToggle={handleToggleIsConfirmationDialogOpen}
      />
    </Page>
  );
};

export default StudentsNew;
