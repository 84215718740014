// External Dependencies
import { FC, memo } from 'react';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { useUpdateChecklistItem } from 'gql/mutations';

// Local Dependencies
import { SaveButton } from 'components/shared';
import { checklistItemSchema } from 'pages/Checklists/shared/schema';
import ChecklistErrorAlert from '../../shared/ChecklistErrorAlert';
import ChecklistItemsFormUI from './ChecklistItemsFormUI';

// Local Typings
interface Props {
  checklistItem: GQL.IChecklistItem | null;
  onClose: () => void;
  open: boolean;
}

// Component Definition
const DialogEditChecklistItem: FC<Props> = ({
  checklistItem,
  onClose,
  open,
}) => {
  const [
    updateChecklistItem,
    {
      error: udpateChecklistItemError,
      loading,
    },
  ] = useUpdateChecklistItem();

  if (!checklistItem) {
    return null;
  }

  const initialValues = {
    dueDate: null, // Not using for now
    isRequired: checklistItem.isRequired,
    label: checklistItem.label,
  };

  const handleFormikSubmit = (
    values: GQL.IUpdateChecklistItemInput,
  ) => {
    updateChecklistItem({
      variables: {
        id: checklistItem.id,
        input: values,
      },
    });
  };

  return (
    <Dialog
      aria-describedby="dialog-edit-checklist-item-description"
      aria-labelledby="dialog-edit-checklist-item"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="dialog-edit-checklist-item">
        Edit Checklist Item
      </DialogTitle>

      <Formik<GQL.IUpdateChecklistItemInput>
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={checklistItemSchema}
      >
        {({
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <>
                <DialogContent id="dialog-edit-checklist-item-description">
                  <Box marginBottom={2}>
                    <ChecklistItemsFormUI />
                  </Box>

                  <ChecklistErrorAlert
                    errorMessage={udpateChecklistItemError?.message}
                  />
                </DialogContent>

                <DialogActions>
                  <Button
                    color="primary"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <SaveButton
                    color="primary"
                    isSaving={loading}
                    onClick={onClose}
                    variant="contained"
                  >
                    Save
                  </SaveButton>
                </DialogActions>
              </>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default memo(DialogEditChecklistItem);
