import { ConfirmationDialog } from 'components/shared';
import { useCallback } from 'react';
import { useDeleteForm } from 'utils/api/forms';

interface Props {
  formId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmDeleteFormDialog = ({
  formId,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const {
    isLoading: isDeletingForm,
    mutate: deleteForm,
  } = useDeleteForm();

  const handleDeleteForm = useCallback(() => {
    if (formId) {
      deleteForm(formId, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  }, [deleteForm, formId, onClose]);

  return (
    <ConfirmationDialog
      canBeUndone={false}
      confirmButtonAction={handleDeleteForm}
      declineButtonAction={onClose}
      description="Are you sure you want to delete this form? All responses will also be deleted."
      handleClose={onClose}
      isSubmitting={isDeletingForm}
      open={isOpen}
      title="Delete Form?"
    />
  );
};

export default ConfirmDeleteFormDialog;
