// Local Dependencies
import useSelfQuery from './useSelfQuery';

// Hook Definition
function useIsSelf(id: string | undefined) {
  const { self } = useSelfQuery();

  if (!id) {
    return null;
  }

  return self?.id === id;
}

export default useIsSelf;
