// External Dependencies
import { ApolloError } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Local Dependencies
import FourOhFour from 'pages/FourOhFour';
import PageHeader, { PageHeaderProps } from './PageHeader';

// Local Typings
interface Props extends PageHeaderProps {
  children: any;
  error?: ApolloError;
  isLoading?: boolean;
  noMargin?: boolean;
  notFound?: boolean;
}

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  '.progress': {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

// TODO: Update the logic for `notFound` so that we don't flash the 404 page

// Component Definition
const Page: FC<Props> = ({
  backButtonProps,
  children,
  editButtonPath,
  error,
  isLoading,
  noMargin,
  notFound,
}) => (
  <>
    <PageHeader
      backButtonProps={backButtonProps}
      editButtonPath={editButtonPath}
    />

    {error && (
      <EnhancedAlert severity="error">
        {error.message}
      </EnhancedAlert>
    )}

    <StyledBox marginTop={noMargin ? 0 : 4}>
      {isLoading && (
        <div className="progress">
          <CircularProgress
            size={40}
            thickness={4}
          />
        </div>
      )}

      {!isLoading && (notFound ? <FourOhFour /> : children)}
    </StyledBox>
  </>
);

export default Page;
