// External Dependencies
import { DownloadCsvImportWithErrorsRequest } from '@presto-assistant/api_types/api/v1/csvImport';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useDownloadCsvImportWithErrors = () => {
  const mutationFn = useCallback((payload: {
    body: DownloadCsvImportWithErrorsRequest['body']
    params: DownloadCsvImportWithErrorsRequest['params'],
  }) => {
    return apiClient.v1.csvImport.downloadWithErrors(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};
