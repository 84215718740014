// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFilesTable from './MyFilesTable';

// Component Definition
const MyFiles: FC = () => (
  <>
    <Subtitle>
      My Files
    </Subtitle>

    <MyFilesTable />
  </>
);

export default MyFiles;
