// External Dependencies
function PaperAirplaneSvg(props: any) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 1920 1080"
      xmlSpace="preserve"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <style>
        {
          '.prefix__st0{fill:#82d6f4}.prefix__st3{fill:none;stroke:#00a99d;stroke-width:10;stroke-miterlimit:10}'
        }
      </style>
      <path
        className="prefix__st0"
        d="M1211.9 332.5L1782 165l-414.1 226.8z"
      />
      <path
        fill="#29abe2"
        d="M1782 165l-414.1 226.8 66.5 141.7z"
      />
      <path
        className="prefix__st0"
        d="M1782 165l-330.1 256.5 132.7 182.2z"
      />
      <path
        fill="#0071bc"
        d="M1434.4 533.5l17.5-112 38.4 52.7z"
      />
      <path
        className="prefix__st3"
        d="M1390.5 557.4s-2.5 2.5-7.2 6.9"
      />
      <path
        d="M1368.3 577.8c-62.6 55.1-247.4 199.7-416.4 156.1-207.5-53.4-166.3-275.2-43.8-274.8S1105.5 673 1039.5 814s-270 243-552.1 171c-229.3-58.5-419-299.7-466.8-364.9"
        fill="none"
        stroke="#00a99d"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeDasharray="20.1304,20.1304"
      />
      <path
        className="prefix__st3"
        d="M14.7 611.9c-3.8-5.3-5.7-8.2-5.7-8.2"
      />
    </svg>
  );
}

export default PaperAirplaneSvg;
