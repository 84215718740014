// External Dependencies
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToMyPayments = (): JSX.Element => {
  const myPaymentsParams = useSelector(tableQueryParams('myFinancialPayments'));

  return (
    <Box
      marginLeft={2}
      marginTop={4}
    >
      <Typography
        role="heading"
        sx={{ marginBottom: 2 }}
        variant="body2"
      >
        View payments assigned to you and your children
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.STUDENT_PAYMENTS}${myPaymentsParams}`}>
        My Payments
      </ForwardButton>
    </Box>
  );
};

export default LinkToMyPayments;
