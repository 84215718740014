// External Dependencies
import { GetDistrictInventoryAuditResponse } from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Internal Dependencies
import {
  EnhancedCard,
  TableDataGrid,
} from 'components/shared';

// Local Dependencies
import { NotFoundItem, useColumns } from './hooks';

// Local Typings
interface Props {
  isLoading: boolean;
  notFoundItems: GetDistrictInventoryAuditResponse['data']['inventoryItemsNotFound'] | undefined;
}

// Component Definition
const NotFoundItemsTable = ({
  isLoading,
  notFoundItems,
}: Props): JSX.Element => {
  const tableColumns = useColumns();

  return (
    <EnhancedCard>
      <DataGridContainer>
        <TableDataGrid
          columns={tableColumns}
          getRowId={(row: NotFoundItem) => row.inventoryItem.id}
          loading={isLoading}
          rows={notFoundItems ?? []}
          withSearch
          zeroStateMessage="All items found so far — great job! 🎉"
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default NotFoundItemsTable;
