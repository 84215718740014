// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import UniformTable from './UniformTable';

// Component Definition
const Uniforms = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Uniforms
      </Subtitle>

      <EnhancedCard>
        <UniformTable />
      </EnhancedCard>
    </>
  );
};

export default Uniforms;
