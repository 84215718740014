// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
} from 'components/shared';
import {
  useCreateUniformStyle,
} from 'gql/mutations';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  style: string;
}

// Local Variables
const initialValues: FormValues = {
  style: '',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

// Component Definition
const DialogAddUniformStyle = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const [
    handleCreateUniformStyle,
    {
      loading,
    },
  ] = useCreateUniformStyle({
    clearCachePredicates: ['uniformStyles', 'globalEditContract'],
    onCompleted: onClose,
  });

  const handleSubmit = async (values: FormValues) => {
    await handleCreateUniformStyle({
      variables: {
        input: {
          label: values.style,
        },
      },
    });
  };

  return (
    <DialogForm<FormValues>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Add Uniform Style"
    >
      <StyledTypography color="textSecondary">
        An uniform style will be available when adding or editing uniform items.
      </StyledTypography>

      <Box mb={2}>
        <CustomInput
          label="New Style"
          name="style"
        />
      </Box>
    </DialogForm>
  );
};

export default DialogAddUniformStyle;
