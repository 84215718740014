// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material/';
import styled from 'styled-components';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    margin: `${theme.spacing(1.5)} auto`,
  },
  fontSize: '1em',
  margin: '16px auto',
  textAlign: 'left',
})) as typeof Typography;

// Component Definition
const InfoTitle: FC = ({ children }) => (
  <StyledTypography
    component="h3"
    variant="h6"
  >
    {children}
  </StyledTypography>
);

export default InfoTitle;
