// External Dependencies
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';

// Internal Dependencies
import {
  ConfirmationDialog, EnhancedAlert, Flex, Page, SaveButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFormAsDistrictAdmin } from 'gql/queries/form-queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { usePublishForm } from 'gql/mutations/form-mutations';
import BlocksCard from 'pages/Forms/Edit/BlocksCard';
import DetailsCard from 'pages/Forms/components/DetailsCard';

// Component Definition
const AdminFormsEdit = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParamsWithId();

  const districtFormsParams = useSelector(tableQueryParams('districtForms'));

  const {
    handleClose: handleCloseConfirmPublishDialog,
    handleOpen: handleOpenConfirmPublishDialog,
    isOpen: isConfirmPublishDialogOpen,
  } = useIsOpen();

  const {
    handleClose: handleHideFormBlocksRequiredMessage,
    handleOpen: handleShowFormBlocksRequiredMessage,
    isOpen: isFormBlocksRequiredMessageShowing,
  } = useIsOpen();

  const {
    data: formData,
  } = useGetFormAsDistrictAdmin(id);

  const form = useMemo(() => {
    return formData?.form;
  }, [formData]);

  const handleCompletePublish = useCallback(async () => {
    handleCloseConfirmPublishDialog();

    await queryClient.invalidateQueries(['districtForms']);

    dispatch(addNotification('District form published successfully.', 'success'));

    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}/${form?.id}`, { replace: true });
  }, [
    dispatch,
    form?.id,
    handleCloseConfirmPublishDialog,
    navigate,
    queryClient,
  ]);

  const [
    publishForm,
    {
      loading: isPublishingForm,
    },
  ] = usePublishForm({
    clearCachePredicates: ['district'],
    onCompleted: handleCompletePublish,
  });

  const handleClickConfirmPublish = useCallback(async () => {
    publishForm({
      variables: {
        id: form?.id ?? '',
      },
    });
  }, [form, publishForm]);

  const handleClickPublish = useCallback(() => {
    if (!form?.formBlocks.length) {
      handleShowFormBlocksRequiredMessage();
    } else {
      handleOpenConfirmPublishDialog();
    }
  }, [
    form,
    handleOpenConfirmPublishDialog,
    handleShowFormBlocksRequiredMessage,
  ]);

  useEffect(() => {
    if (isFormBlocksRequiredMessageShowing && form?.formBlocks.length) {
      handleHideFormBlocksRequiredMessage();
    }
  }, [
    form?.formBlocks.length,
    handleHideFormBlocksRequiredMessage,
    isFormBlocksRequiredMessageShowing,
  ]);

  return (
    <Page
      backButtonProps={{
        label: 'District Forms',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}${districtFormsParams}`,
      }}
    >
      <Container maxWidth="md">
        {form && (
          <Box marginTop={2}>
            <DetailsCard
              form={form}
              isEditMode
            />
          </Box>
        )}

        {form && (
          <Box marginTop={2}>
            <BlocksCard
              blocks={form.formBlocks}
              formId={form.id}
            />
          </Box>
        )}

        <Collapse in={isFormBlocksRequiredMessageShowing}>
          <EnhancedAlert
            severity="error"
            sx={{ marginTop: 2 }}
            title="Form Blocks Required"
          >
            You must add at least one block to this district form before publishing.
          </EnhancedAlert>
        </Collapse>

        {form && !form.publishedAt && (
          <Flex
            justifyContent="flex-end"
            marginTop={2}
          >
            <SaveButton
              endIcon={<SendIcon />}
              isSaving={isPublishingForm}
              onClick={handleClickPublish}
            >
              Publish
            </SaveButton>
          </Flex>
        )}
      </Container>

      <ConfirmationDialog
        confirmButtonAction={handleClickConfirmPublish}
        confirmButtonText="Yes, Publish"
        declineButtonAction={handleCloseConfirmPublishDialog}
        description="After publishing this district form, you will not be able to make revisions. You will still be able to assign/unassign to members."
        handleClose={handleCloseConfirmPublishDialog}
        open={isConfirmPublishDialogOpen}
        title="Publish District Form?"
      />
    </Page>
  );
};

export default AdminFormsEdit;
