// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetLibraryItem } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import LibraryForm from '../shared/LibraryForm';

// Component Definition
const LibraryShow: FC = () => {
  const { id } = useParamsWithId();

  const canUseDynamicFields = useCanUseDynamicFields();

  const canEditLibrary = useSelector(hasPermission('inventory', 'edit'));
  const libraryParams = useSelector(tableQueryParams('libraryItems'));

  const {
    data,
    error,
    loading,
  } = useGetLibraryItem(id!);

  return (
    <Page
      backButtonProps={{
        label: 'All Library Items',
        path: `/${PATHS.LIBRARY}${libraryParams}`,
      }}
      editButtonPath={canEditLibrary
        ? `/${PATHS.LIBRARY}/${id}/edit`
        : undefined}
      error={error}
      isLoading={!data || loading}
    >
      <LibraryForm
        canUseDynamicFields={canUseDynamicFields}
        libraryItem={data?.libraryItem}
        readOnly
        title="Library Item"
      />

      {data?.libraryItem && (
        <History
          id={data.libraryItem.id}
          resource="libraryItems"
        />
      )}
    </Page>
  );
};

export default LibraryShow;
