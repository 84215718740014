import { FC, useImperativeHandle, useRef } from 'react';
import { useTheme } from 'styled-components';

const StripeInput: FC<any> = ({
  component: Component,
  inputRef,
  ...other
}) => {
  const elementRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current?.focus,
  }));

  return (
    <Component
      onReady={(element: any) => {
        (elementRef as any).current = element;
      }}
      options={{
        style: {
          base: {
            color: theme.palette.grey[900],
          },
        },
      }}
      {...other}
    />
  );
};

export default StripeInput;
