// External Dependencies
import {
  Button,
  ButtonProps,
  List,
  ListItem,
  ListItemText,
  Popover,
  PopoverOrigin,
} from '@mui/material';
import {
  MouseEvent, ReactElement, Ref, forwardRef, useState,
} from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import styled, { useTheme } from 'styled-components';

// Local Typings
export interface DropdownButtonProps {
  buttonChildren: ReactElement | string;
  className?: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
  id: string;
  onButtonClick?: (() => void) | null;
  size?: ButtonProps['size'];
  subButtons: SubButton[];
  variant?: ButtonProps['variant'];
}

interface SubButton {
  disabled?: boolean;
  onClick: () => void;
  selected?: boolean;
  text: string;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '&.buttonContainer': {
    border: `1px solid ${theme.palette.text.secondary}`,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textTransform: 'initial',
  },
  '&.rootButtonContainer': {
    [theme.breakpoints.down('md')]: {
      marginBottom: 6,
    },
    borderRadius: 100,
  },
  '.downArrowSvgIcon': {
    marginLeft: 6,
    marginRight: 0,
  },
}));

const StyledPopover = styled(Popover)({
  '.list': {
    maxHeight: 400,
    overflowY: 'auto',
  },
  '.popper': {
    zIndex: 10000,
  },
});

const noSubButtonsOptions = [{
  disabled: true,
  onClick: () => null,
  text: '(No options)',
}];

const anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'top' };

// Component Definition
const DropdownButton = forwardRef((
  props: DropdownButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    buttonChildren,
    className,
    color,
    disabled = false,
    id,
    onButtonClick,
    size,
    subButtons,
    variant,
    ...otherProps
  } = props;

  const theme = useTheme();

  const [
    anchorEl,
    setAnchorEl,
  ] = useState<MouseEvent<HTMLButtonElement>['currentTarget'] | null>(null);

  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if (onButtonClick) {
      onButtonClick();
    }
  };

  const subSelected = subButtons.some((button) => button.selected);

  return (
    <>
      <StyledButton
        aria-owns={isOpen ? id : undefined}
        aria-haspopup="true"
        classes={{
          outlinedSizeSmall: 'buttonContainer',
          root: 'rootButtonContainer',
        }}
        className={className}
        color={color}
        disabled={disabled}
        endIcon={(
          <KeyboardArrowDown
            classes={{ root: 'downArrowSvgIcon' }}
            fontSize="small"
            // eslint-disable-next-line no-nested-ternary
            htmlColor={disabled
              ? theme.palette.divider
              : subSelected
                ? theme.palette.common.white
                : theme.palette.text.secondary}
          />
        )}
        onClick={handleOpen}
        ref={ref}
        size={size}
        variant={variant}
        {...otherProps}
      >
        {buttonChildren}
      </StyledButton>

      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        className="popper"
        id={id}
        onClose={handleClose}
        open={isOpen}
        transformOrigin={transformOrigin}
      >
        <List className="list">
          {(subButtons.length ? subButtons : noSubButtonsOptions).map((
            button: SubButton,
            index: number,
          ) => (
            <ListItem
              button
              component="li"
              disabled={button.disabled}
              // Should be fine to disable this rule, as we are also
              // providing the text, which will ideally be unique
              // eslint-disable-next-line react/no-array-index-key
              key={`${button.text}-${index}`}
              onClick={() => {
                button.onClick();
                handleClose();
              }}
              role="listitem"
              selected={button.selected}
              tabIndex={0}
            >
              <ListItemText primary={button.text} />
            </ListItem>
          ))}
        </List>
      </StyledPopover>
    </>
  );
});

export default DropdownButton;
