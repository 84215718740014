// External Dependencies
import {
  CreateInventoryAuditAssessmentRequest,
  GetInventoryAuditResponse,
  ListInventoryAuditAssessmentsByInventoryIdResponse,
} from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { Form, Formik } from 'formik';
import { InventoryAuditAbsenceReasons } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  CustomSelect,
  SaveButton,
} from 'components/shared';
import { SelectOption } from 'components/shared/CustomSelect';
import { mapEnum } from 'utils/lib/map_enum';
import { useCreateInventoryAuditAssessment } from 'utils/api/inventory';
import { useGetDistrictOrganizations, useGetOrganization } from 'gql/queries';

// Local Typings
export type SelectionAction = 'markAbsent' | 'markPresent';

interface Props {
  inventoryAuditId: string;
  inventoryItemId: string | null;
  onClose: () => void;
  selectionAction: SelectionAction;
}

type FormikPayload = CreateInventoryAuditAssessmentRequest['body'] & {
  transferToAnotherCampus: boolean;
};

// Local Variables
const absenceReasonOptions: SelectOption[] = mapEnum(InventoryAuditAbsenceReasons)
  .map((absenceReason) => ({
    id: absenceReason.id,
    label: toTitleCase(absenceReason.label),
  }));

// Component Definition
const CreateAssessmentDialog = ({
  inventoryAuditId,
  inventoryItemId,
  onClose,
  selectionAction,
}: Props): JSX.Element => {
  const {
    isLoading: isCreatingAssessment,
    mutate: createInventoryAuditAssessment,
  } = useCreateInventoryAuditAssessment();

  const queryClient = useQueryClient();

  const handleFormikSubmit = (values: FormikPayload) => {
    const payload: CreateInventoryAuditAssessmentRequest['body'] = {
      inventoryItemId: values.inventoryItemId,
    };

    if (values.absenceReasonId) {
      payload.absenceReasonId = Number(values.absenceReasonId);
    }

    if (values.absenceReasonNote?.trim()) {
      payload.absenceReasonNote = values.absenceReasonNote.trim();
    }

    if (values.dataChangesRequested?.trim()) {
      payload.dataChangesRequested = values.dataChangesRequested.trim();
    }

    if (values.transferToAnotherCampus && values.requestTransferToOrganizationId) {
      payload.requestTransferToOrganizationId = values.requestTransferToOrganizationId;
    }

    createInventoryAuditAssessment({
      body: payload,
      params: {
        id: inventoryAuditId,
      },
    }, {
      onSuccess: () => {
        const inventoryAuditQueryKey = ['inventoryAudit', inventoryAuditId];
        const queryKey = [inventoryAuditId, 'inventoryAuditAssessmentsByInventoryId'];

        const existingInventoryAudit = queryClient
          .getQueryData(inventoryAuditQueryKey) as { data: GetInventoryAuditResponse };

        const existingAssessments = queryClient
          .getQueryData(queryKey) as { data: ListInventoryAuditAssessmentsByInventoryIdResponse };

        const isNewAssessment = existingAssessments.data.data[values.inventoryItemId] === undefined;

        queryClient.setQueryData<{ data: GetInventoryAuditResponse }>(inventoryAuditQueryKey, {
          data: {
            data: {
              ...existingInventoryAudit.data.data,
              numberOfInventoryItemsAudited: isNewAssessment
                ? existingInventoryAudit.data.data.numberOfInventoryItemsAudited + 1
                : existingInventoryAudit.data.data.numberOfInventoryItemsAudited,
            },
          },
        });

        queryClient.setQueryData<{ data: ListInventoryAuditAssessmentsByInventoryIdResponse }>(
          queryKey,
          {
            data: {
              data: {
                ...existingAssessments.data.data,
                [values.inventoryItemId]: {
                  absenceReasonId: values.absenceReasonId || null,
                  areChangesRequested: Boolean(values.dataChangesRequested),
                  isTransferRequested: values.transferToAnotherCampus,
                },
              },
            },
          },
        );

        onClose();
      },
    });
  };

  const {
    data: organizationData,
  } = useGetOrganization();

  const {
    data: organizationsData,
  } = useGetDistrictOrganizations();

  // all orgs that are not the current org and are the same type
  const filteredOrganizations = useMemo(() => {
    return organizationsData?.districtOrganizations
      .filter((organization) => {
        return organization.id !== organizationData?.organization.id
          && organization.organizationType.id
          === organizationData?.organization.organizationType.id;
      }) || [];
  }, [organizationData, organizationsData]);

  const organizationsAsOptions: SelectOption[] = useMemo(() => {
    return filteredOrganizations.map((organization) => ({
      id: organization.id,
      label: organization.label,
    })) || [];
  }, [filteredOrganizations]);

  return (
    <Dialog open={Boolean(inventoryItemId)}>
      <DialogTitle>
        Mark as {selectionAction === 'markAbsent' ? 'Absent' : 'Present'}?
      </DialogTitle>

      <Formik<FormikPayload>
        enableReinitialize
        initialValues={{
          absenceReasonId: selectionAction === 'markAbsent' ? 1 : null,
          absenceReasonNote: '',
          dataChangesRequested: '',
          inventoryItemId: inventoryItemId || '',
          requestTransferToOrganizationId: null,
          transferToAnotherCampus: false,
        }}
        onSubmit={handleFormikSubmit}
      >
        {({
          values,
        }) => {
          return (
            <Form>
              <DialogContent>
                {selectionAction === 'markAbsent' && (
                  <CustomSelect
                    label="Absence Reason"
                    name="absenceReasonId"
                    options={absenceReasonOptions}
                    required={selectionAction === 'markAbsent'}
                  />
                )}

                <CustomInput
                  label="Note to Administrator"
                  minRows={3}
                  multiline
                  name="absenceReasonNote"
                />

                <CustomInput
                  label="Data Changes Requested"
                  minRows={3}
                  multiline
                  name="dataChangesRequested"
                />

                {organizationsAsOptions.length > 0 && (
                  <>
                    <CustomCheckbox
                      label="Request Transfer to Another Campus?"
                      name="transferToAnotherCampus"
                    />

                    {values.transferToAnotherCampus && (
                      <CustomSelect
                        label="Organization"
                        name="requestTransferToOrganizationId"
                        options={organizationsAsOptions}
                      />
                    )}
                  </>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <SaveButton isSaving={isCreatingAssessment}>
                  Submit
                </SaveButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CreateAssessmentDialog;
