// Internal Dependencies
import {
  ShowStudentInventoryCheckoutUI,
} from 'components/shared';
import {
  useGetInventoryCheckoutsByMemberId,
} from 'gql/queries';

// Local Typings
interface Props {
  memberId: string;
  name: string;
}

// Component Definition
const ShowStudentInventoryCheckout = ({
  memberId,
  name,
}: Props): JSX.Element | null => {
  const { data } = useGetInventoryCheckoutsByMemberId(memberId);

  if (!data) {
    return null;
  }

  const { inventoryCheckouts } = data.inventoryCheckoutsByMemberId;

  return (
    <ShowStudentInventoryCheckoutUI
      inventoryCheckoutsData={inventoryCheckouts}
      memberId={memberId}
      name={name}
    />
  );
};

export default ShowStudentInventoryCheckout;
