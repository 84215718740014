// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface UniformOptionsResponse {
  uniformCategories: GQL.IShallowUniformCategory[];
}

export const UNIFORM_SELECT_OPTIONS_QUERY = gql`
  query UniformSelectOptions($organizationTypeId: ID) {
    uniformCategories(organizationTypeId: $organizationTypeId) {
      id
      label
    }
  }
`;

export const useGetUniformFilterOptions = (organizationTypeId: string | undefined) =>
  useQueryEnhanced<UniformOptionsResponse>(
    UNIFORM_SELECT_OPTIONS_QUERY,
    {
      skip: !organizationTypeId,
      variables: {
        organizationTypeId,
      },
    },
  );
