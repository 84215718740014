// External Dependencies
import styled from 'styled-components';

// Local Dependencies
import BasicMenuItem, { BasicMenuItemProps } from './BasicMenuItem';
import NavMenuItem, { NavMenuItemProps } from './NavMenuItem';
import RadioGroup, { RadioGroupProps } from './RadioGroup';

// Local Typings
type PropsWithType<T, P> = { id: string, props: P, type: T };

export type MenuItemProps =
  | PropsWithType<'menuItem', BasicMenuItemProps>
  | PropsWithType<'navItem', NavMenuItemProps>
  | PropsWithType<'radio', RadioGroupProps>

type Props = {
  onClose: () => void;
} & MenuItemProps;

// Local Variables
const StyledNavMenuItem = styled(NavMenuItem)(({ theme }) => ({
  '&&': {
    border: `1px solid ${theme.palette.prestoPrimaryLight}`,
    borderRadius: 100,
    padding: theme.spacing(0, 1.5),
  },
}));

// Component Definition
const MenuItem = ({
  onClose,
  props,
  type,
}: Props): JSX.Element | null => {
  if (type === 'navItem') {
    return (
      <StyledNavMenuItem
        {...props as NavMenuItemProps}
        onClose={onClose}
      />
    );
  }

  if (type === 'radio') {
    return <RadioGroup {...props as RadioGroupProps} />;
  }

  if (type === 'menuItem') {
    return <BasicMenuItem {...props as BasicMenuItemProps} />;
  }

  return null;
};

export default MenuItem;
