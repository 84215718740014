// External Dependencies
import { InventoryAuditsByOrganizationIdResponseItem } from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn, dateTimeColumn } from 'utils/lib/tableColumns';

// Local Dependencies

export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<InventoryAuditsByOrganizationIdResponseItem>[] = [
      {
        field: 'label',
        headerName: 'Title',
        width: 292,
      },
      dateColumn({
        field: 'dueDate',
        headerName: 'Due Date',
      }),
      dateTimeColumn({
        field: 'completedAt',
        headerName: 'Completed At',
      }),
    ];

    return columns;
  }, []);
};
