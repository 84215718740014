// External Dependencies
import { FC } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';

// Local Typings
interface Props {
  code: string;
  isAdminView?: boolean;
  orgName: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.orgCode': {
    backgroundColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    fontSize: '1.1rem',
    marginTop: theme.spacing(2),
    padding: 6,
    textAlign: 'center',
  },
  '.orgName': {
    whiteSpace: 'nowrap',
  },
  '.orgNameInfo': {
    padding: theme.spacing(2, 1.5, 0, 1.5),
  },
  '.subheader': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const OrganizationCode: FC<Props> = ({
  code,
  isAdminView,
  orgName,
}) => (
  <StyledCardContent>
    <Typography
      className="subheader"
      component="h3"
    >
      Organization Code
      <CopyToClipboardButton
        textToCopy={code ?? ''}
        tooltipTitle="Copy organization code"
      />
    </Typography>

    {isAdminView ? (
      <Typography
        className="orgNameInfo"
        variant="body2"
      >
        Provide this code in your CSV file when importing data for <span className="orgName">{orgName}</span>
      </Typography>
    ) : (
      <Typography
        className="orgNameInfo"
        variant="body2"
      >
        Provide this code to parents, students, and
        staff to use when joining <span className="orgName">{orgName}</span>
      </Typography>
    )}

    <Typography className="orgCode">{code}</Typography>
  </StyledCardContent>
);

export default OrganizationCode;
