// Internal Dependencies
import { ONE_MB_ROUND } from 'utils/constants/email';

// Local Dependencies
import { toHundredth } from './to_hundredth';

export const displayFileSize = (size: number) => {
  const sizeInMb = size / ONE_MB_ROUND;

  if (sizeInMb > 1) {
    return `${toHundredth(sizeInMb)}MB`;
  }

  const kbValue = Math.round(sizeInMb * 1000);

  return `${kbValue}KB`;
};
