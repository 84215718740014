// External Dependencies
import styled from 'styled-components';

// Internal Dependencies
import { StyledLink } from 'components/shared';

// Local Variables
export const EnhancedStyledLink = styled(StyledLink)({
  fontSize: 15,
  whiteSpace: 'pre',
});

export const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});
