// Solution found from this comment: https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
// This prevents the overlay from having role="none presentation"

// External Dependencies
import Dialog, { DialogProps } from '@mui/material/Dialog';

// Component Definition
const EnhancedDialog = ({
  children,
  PaperProps = {},
  TransitionProps = {},
  ...otherProps
}: DialogProps): JSX.Element => {
  return (
    <Dialog
      PaperProps={{
        'aria-label': 'Dialog',
        ...PaperProps,
      }}
      TransitionProps={{
        role: 'presentation',
        ...TransitionProps,
      } as any}
      {...otherProps}
    >
      {children}
    </Dialog>
  );
};

export default EnhancedDialog;
