const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
const SUFFIXES = {
  few: 'rd',
  many: 'th',
  one: 'st',
  other: 'th',
  two: 'nd',
  zero: 'th',
};

function getOrdinalNumber(number: number) {
  if (number < 1) {
    return `${number}${SUFFIXES.other}`;
  }

  // This will be one of the enumerated values in SUFFIXES const above
  const whichSuffix = englishOrdinalRules.select(number);

  const suffix = SUFFIXES[whichSuffix];
  return number + suffix;
}

export default getOrdinalNumber;
