// External Dependencies
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import AlertButton from './AlertButton';

// Component Definition
const ContinueEndOfYearProcess: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(PATHS.END_OF_YEAR);
  };

  return (
    <AlertButton
      buttonText="Continue"
      isLoading={false}
      onClick={handleClick}
    />
  );
};

export default ContinueEndOfYearProcess;
