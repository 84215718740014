// External Dependencies
import styled from 'styled-components';

// Internal Dependencies
import { formatAddress, formatPhoneNumber } from 'utils';

// Local Typings
interface Props {
  addressOne?: string | null;
  addressTwo?: string | null;
  city?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  state?: string | null;
  zipcode?: string | null;
}

// Local Variables
const StyledAddress = styled.address(({ theme }) => ({
  color: theme.palette.text.primary,
  fontStyle: 'normal',
}));

// Component Definition
const Address = ({
  addressOne,
  addressTwo,
  city = null,
  email,
  phoneNumber,
  state = null,
  zipcode = null,
}: Props): JSX.Element => {
  return (
    <StyledAddress>
      {email && <div>{email}</div>}
      {addressOne && <div>{addressOne}</div>}
      {addressTwo && <div>{addressTwo}</div>}
      {(city || state || zipcode) && (
        <div>{formatAddress({ city, state, zipcode })}</div>
      )}
      {phoneNumber && <div>{formatPhoneNumber(phoneNumber)}</div>}
    </StyledAddress>
  );
};

export default Address;
