// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

const UNIFORM_SELECT_OPTIONS_QUERY = gql`
  query UniformSelectOptions(
    $where: UniformTypesWhere
  ) {
    uniformTypes(
      where: $where
    ) {
      id
      label
    }
  }
`;

export const useGetUniformSelectOptions = (
  variables: GQL.IUniformTypesOnQueryArguments,
) =>
  useQueryEnhanced<{
    uniformTypes: Array<GQL.IUniformType>
  }>(
    UNIFORM_SELECT_OPTIONS_QUERY,
    {
      variables,
    },
  );

export const UNIFORM_CATEGORY_OPTIONS_QUERY = gql`
  query UniformCategorySelectOptions($organizationTypeId: ID!) {
    uniformCategories(organizationTypeId: $organizationTypeId) {
      id
      label
    }
  }
`;
