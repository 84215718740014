// External Dependencies
import { FC } from 'react';

// Component Definition
const DarkTheme: FC = () => (
  <style>
    {`
      body {
        background: black;
      }
    `}
  </style>
);

export default DarkTheme;
