// External Dependencies
import { Component } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';

// Internal Dependencies
import { requireFormFields } from 'utils';
import { trimValues } from 'utils/lib/trimValues';

// Local Dependencies
import DialogFormContent from './DialogFormContent';
import EnhancedDialog from '../EnhancedDialog';

// Local Typings
export interface DialogFormProps<Values> {
  cancelText?: string;
  children: any;
  dialogProps?: Partial<DialogProps>;
  enableReinitialize?: boolean;
  hasError?: boolean;
  initialValues: Values;
  isSubmitting?: boolean;
  nonRequiredValues?: string[];
  onClose?: () => void;
  onSubmit: (values: Values) => (Promise<void> | void);
  subTitle?: string;
  submitText?: string;
  title: string;
  validate?: (values: Values) => void;
  validationSchema?: { [key: string]: any };
}

// Component Definition
class DialogForm<Values> extends Component<DialogFormProps<Values>> {
  handleClose = (resetForm: () => void) => () => {
    const { onClose } = this.props;
    if (onClose) {
      resetForm();
      onClose();
    }
  };

  render() {
    const {
      cancelText,
      children,
      dialogProps = {} as Partial<DialogProps>,
      enableReinitialize,
      hasError,
      initialValues,
      isSubmitting: isFormSubmitting = false,
      nonRequiredValues = [],
      onClose,
      onSubmit,
      subTitle,
      submitText,
      title,
      validate,
      validationSchema,
      ...props
    } = this.props;

    // We handle the MUI reasons for closing
    const handleCloseDialog = (
      _event: object,
      reason: string,
    ) => {
      // We don't allow the user to click away from the success dialog
      // They will always be sent back to the correct "next" page
      if (['backdropClick', 'escapeKeyDown'].includes(reason)) {
        return null;
      }

      if (onClose) {
        return onClose();
      }

      return null;
    };

    return (
      <EnhancedDialog
        aria-labelledby="form-dialog-title"
        onClose={handleCloseDialog}
        open={dialogProps.open || false}
        {...dialogProps}
      >
        <Formik<Values>
          enableReinitialize={enableReinitialize}
          initialValues={initialValues}
          onSubmit={async (values, formikBag: FormikHelpers<Values>) => {
            // Formik will set isSubmitting to true here for us
            // And since it is "async", then isSubmitting will be set to
            //  false automatically when the function finishes
            try {
              await onSubmit(trimValues(values));
              this.handleClose(formikBag.resetForm);
            } catch (error) {
              console.error(error);
            }
          }}
          validate={
            validate && !validationSchema
              ? (values) => validate(values)
              : (values) => requireFormFields(values, nonRequiredValues)
          }
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleReset,
            handleSubmit,
            isSubmitting,
          }) => {
            const handleKeyDown = (
              evt: React.KeyboardEvent<HTMLFormElement>,
            ) => {
              if (evt.keyCode === 13 && evt.metaKey) {
                handleSubmit();
              }
            };

            return (
              <Form
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
              >
                <DialogFormContent
                  cancelText={cancelText}
                  hasError={Object.keys(errors).length > 0 || hasError}
                  hideCancelButton={!onClose}
                  isSubmitting={isSubmitting || isFormSubmitting}
                  onClose={this.handleClose(handleReset)}
                  subTitle={subTitle}
                  submitText={submitText}
                  title={title}
                  {...props}
                >
                  {children}
                </DialogFormContent>
              </Form>
            );
          }}
        </Formik>
      </EnhancedDialog>
    );
  }
}

export default DialogForm;
