// External Dependencies
import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { Flex } from 'components/shared';

// Local Typings
interface Props {
  recipients: GQL.IEmailShowRecipient[];
}

// Local Variables
const USER_SHOW_LIMIT = 10;

// Component Definition
const RecipientList: FC<Props> = ({ recipients }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen((open) => !open);
  };

  const sortedRecipients = [...recipients].sort((a, b) => a.lastName.localeCompare(b.lastName));

  const recipientsToShow = isOpen && recipients.length > USER_SHOW_LIMIT
    ? sortedRecipients
    : sortedRecipients.slice(0, USER_SHOW_LIMIT);

  const usersString = recipientsToShow
    .map((user) => `${user.firstName} ${user.lastName}`)
    .join(', ');

  const additionalAmount = recipients.length - USER_SHOW_LIMIT;

  const hasHiddenUsers = recipients.length > USER_SHOW_LIMIT;

  return (
    <>
      <Flex
        alignItems="start"
        flexWrap="nowrap"
        justifyContent="start"
      >
        <Typography
          component="p"
          sx={{ mr: 0.5 }}
          variant="body2"
        >
          <strong>To:</strong>
        </Typography>

        <Typography
          component="p"
          variant="body2"
        >
          <Collapse in={!isOpen}>
            {sortedRecipients.slice(0, USER_SHOW_LIMIT).map((user) => `${user.firstName} ${user.lastName}`).join(', ')}
          </Collapse>

          <Collapse in={isOpen}>
            {usersString}
          </Collapse>
        </Typography>
      </Flex>

      {hasHiddenUsers && (
        <Button
          onClick={toggleList}
          variant="text"
        >
          {isOpen ? `Show ${additionalAmount} less` : `Show ${additionalAmount} more`}
        </Button>
      )}
    </>
  );
};

export default RecipientList;
