// External Dependencies
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

// Internal Dependencies
import { FullAppMessageBanner } from 'components/shared';
import Footer from 'components/shared/Footer';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  main: {
    flexGrow: 1,
  },

  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  },
  // eslint-disable-next-line sort-keys
  backgroundColor: theme.palette.skewedBackgroundBottom,
  display: 'flex',
  flexFlow: 'column',
  flexGrow: 1,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

// Component Definition
const SimpleContainer: FC<Props> = ({ children }) => {
  return (
    <StyledRoot>
      {process.env.REACT_APP_BANNER_MESSAGE_FOR_AUTH_ONLY !== 'true' && <FullAppMessageBanner />}

      <main>
        {children ?? <Outlet />}
      </main>

      <Footer
        hideLinks
        showHelpLink
      />
    </StyledRoot>
  );
};

export default SimpleContainer;
