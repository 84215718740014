// External Dependencies
import { KeyboardEvent, useCallback, useMemo } from 'react';
import { TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';

// Internal Dependencies
import { EventKeys } from 'types/keyCodes';

// Local Dependencies
import { UseFormikInputErrorsResult, useFormikInputErrors } from './useFormikInputErrors';
import useTextField, { UseTextFieldOptions, UseTextFieldResult } from './useTextField';

// Local Typings
interface FieldProps {
  name: string;
  onBlur: TextFieldProps['onBlur'];
  onKeyDown: TextFieldProps['onKeyDown'];
}

export type UseFormikTextFieldResult = UseTextFieldResult
  & UseFormikInputErrorsResult
  & FieldProps;

export const useFormikTextField = (
  name: string,
  initialValue: string = '',
  options?: UseTextFieldOptions,
): UseFormikTextFieldResult => {
  const textField = useTextField(initialValue, options);
  const errors = useFormikInputErrors(name);
  const {
    setFieldValue,
    setTouched,
    touched,
  } = useFormikContext();

  const handleFocus = useCallback(() => {
    setTouched({ ...touched, [name]: true });
  }, [name]);

  const handleUpdateFormikValue = useCallback(() => {
    setFieldValue(name, textField.value);
  }, [name, setFieldValue, textField.value]);

  const handleKeyDown: FieldProps['onKeyDown'] = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
    // If user submits a form via keyboard, we need to update the input value
    if (evt.key === EventKeys.Enter) {
      handleUpdateFormikValue();
    }
  }, [handleUpdateFormikValue]);

  return useMemo<UseFormikTextFieldResult>(() => ({
    ...textField,
    ...errors,
    id: name,
    name,
    onBlur: handleUpdateFormikValue,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
  }), [textField, errors, name]);
};
