// External Dependencies
import { darken } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.prestoSecondary
    : darken(theme.palette.prestoSecondary, 0.15),
}));

// Component Definition
const BillingSettingsCardHeader = (): JSX.Element => {
  return (
    <CardHeader
      avatar={(
        <StyledAvatar>
          <CreditCardIcon aria-label="Billing information" />
        </StyledAvatar>
      )}
      title={(
        <Typography
          component="h2"
          variant="h6"
        >
          Billing
        </Typography>
      )}
    />
  );
};

export default BillingSettingsCardHeader;
