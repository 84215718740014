// External Dependencies
import {
  useCallback, useEffect, useMemo,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { useDeleteDynamicField } from 'gql/mutations';
import { useGetDynamicField } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  dynamicFieldId: string | null;
  onClearDynamicFieldId: () => void;
}

// Component Definition
const DialogDeleteDynamicField = ({
  dynamicFieldId,
  onClearDynamicFieldId,
}: Props): JSX.Element => {
  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen(!!dynamicFieldId);

  useEffect(() => {
    if (dynamicFieldId) {
      handleOpen();
    }
  }, [dynamicFieldId, handleOpen]);

  const [
    deleteDynamicField,
    {
      loading: isSubmitting,
    },
  ] = useDeleteDynamicField({
    onCompleted: handleClose,
  });

  const dynamicFieldData = useGetDynamicField({
    id: dynamicFieldId,
  });

  const dynamicField = dynamicFieldData.data?.dynamicField;

  const tableName = useMemo(() =>
    dynamicField?.tableRef.split('_').join(' '), [dynamicField]);

  const descriptionText = dynamicField
    ? (
      <Typography
        component="div"
        gutterBottom
      >
        Deleting the{' '}
        <strong>{dynamicField.label}</strong>
        {' '}dynamic field will also remove all values in the{' '}
        <strong>{tableName}</strong>
        {' '}data for all{' '}
        <strong>{dynamicField.organizationType.label.toLowerCase()}</strong>
        {' '}organizations.
      </Typography>
    )
    : <CircularProgress />;

  const handleConfirmDelete = useCallback(() => {
    if (dynamicFieldId) {
      deleteDynamicField({
        variables: {
          id: dynamicFieldId,
        },
      });
    }
  }, [deleteDynamicField, dynamicFieldId]);

  return (
    <ConfirmationDialog
      canBeUndone={false}
      confirmButtonAction={handleConfirmDelete}
      confirmButtonText="Yes, Delete"
      declineButtonAction={handleClose}
      description={descriptionText}
      handleClose={handleClose}
      isSubmitting={isSubmitting}
      onExited={onClearDynamicFieldId}
      open={isOpen}
      title="Delete this field?"
    />
  );
};

export default DialogDeleteDynamicField;
