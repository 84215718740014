// External Dependencies
import { DistrictFormsIndexResponseItem } from '@presto-assistant/api_types/api/v1/form';
import { FormTypes } from '@presto-assistant/api_types';
import { GridColDef } from '@mui/x-data-grid-pro';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';

// Local Variables
export const useColumns = (args: {
  actionsColumn: GridColDef<DistrictFormsIndexResponseItem> | null;
}) => {
  return useMemo(() => {
    const columns: DataGridColDef<DistrictFormsIndexResponseItem>[] = [
      {
        field: 'title',
        headerName: 'Title',
      },
      {
        field: 'formTypeId',
        headerName: 'Form Type',
        renderCell: (params) => {
          // we have to keep this here for backwards compatibility
          // due to index db not having the form type id in stale data
          if (params.value) {
            return toTitleCase(FormTypes[params.value as FormTypes]);
          }

          return '';
        },
      },
      dateColumn({
        field: 'publishedAt',
        headerName: 'Date Published',
      }),
      dateColumn({
        field: 'updatedAt',
        headerName: 'Date Updated',
      }),
      {
        field: 'schoolYearEnding',
        headerName: 'School Year',
        valueFormatter: ({ value }) => {
          if (value) {
            return displaySchoolYearString(value);
          }
          return '';
        },
      },
    ];

    if (args.actionsColumn) {
      columns.push(args.actionsColumn as DataGridColDef<DistrictFormsIndexResponseItem>);
    }

    return columns;
  }, [args.actionsColumn]);
};
