export const stringifyQueryParams = (obj: object) => {
  const kvPairs = Object.entries(obj).map(([k, v]) => {
    // we want to allow false and 0 to make it through
    if (v === null || v === undefined || v === '') {
      return null;
    }

    // if it is an array without length, we don't want it
    if (Array.isArray(v)) {
      return v.length ? v.join(',') : null;
    }

    return `${k}=${v.toString()}`;
  }).filter((pair) => !!pair);

  return kvPairs.join('&');
};
