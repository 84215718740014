// External Dependencies
import { useTheme } from 'styled-components';
import ChartBarIcon from 'mdi-material-ui/ChartBar';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';

// Local Dependencies
import UserOrgRoleCounts from './UserOrgRoleCounts';

// Local Typings
interface Props {
  userOrgRoleCounts: GQL.IUserOrgRoleCount[];
}

// Local Variables
const subtitle = 'Organization Role Counts';

// Component Definition
const GradeCountsCard = ({ userOrgRoleCounts }: Props): JSX.Element => {
  const {
    palette: {
      primary,
    },
  } = useTheme();

  // We don't render this component if there are no assigned primary roles yet
  if (userOrgRoleCounts.length < 1) {
    return (
      <DashboardCard
        content={(
          <>
            <ZeroStateIcon
              Icon={ChartBarIcon}
              htmlColor={primary.main}
            />
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Add primary roles to students to see metrics
            </Typography>
          </>
        )}
        horizontallyCenterContent
        title={subtitle}
        verticallyCenterContent
      />
    );
  }

  return (
    <DashboardCard
      content={<UserOrgRoleCounts userOrgRoleCounts={userOrgRoleCounts} />}
      horizontallyCenterContent
      title={subtitle}
      verticalSpan={2}
      verticallyCenterContent
    />
  );
};

export default GradeCountsCard;
