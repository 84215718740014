// External Dependencies
import ArroForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  OnboardingOrganizationInfoForm,
  Page,
  Subtitle,
  SupportLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrict } from 'gql/queries';

// Local Variables
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    margin: `${theme.spacing(1.5)} auto`,
  },
  fontSize: '1.2em',
  margin: '16px auto',
  textAlign: 'center',
}));

const pathToDistrictOrganizations = `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}`;

// Component Definition
const AdminCreateOrganization = (): JSX.Element => {
  const {
    data,
    error,
    loading,
  } = useGetDistrict();

  return (
    <Page
      backButtonProps={{
        label: 'Organizations',
        path: pathToDistrictOrganizations,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <Container maxWidth={500}>
        <Subtitle>
          Create New Organization
        </Subtitle>

        <StyledCard variant="outlined">
          <CardContent>
            <StyledTypography variant="h6">Who is this for?</StyledTypography>

            <List
              sx={{
                marginBottom: 1.5,
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <ArroForwardIcon />
                </ListItemIcon>

                <ListItemText primary="The district administrator is also the director of an organization." />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ArroForwardIcon />
                </ListItemIcon>

                <ListItemText
                  primary="You are setting up organizations for other directors to use."
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ArroForwardIcon />
                </ListItemIcon>

                <ListItemText primary="You are a college program." />
              </ListItem>
            </List>

            <EnhancedAlert
              isTip
              sx={{ marginBottom: 2 }}
            >
              In most cases, the directors of each organization should
              create their own {APP_NAME} organization.
            </EnhancedAlert>

            <EnhancedAlert title="Organization Head">
              <Typography
                paragraph
                variant="body2"
              >
                You will be listed as the head of a new organization created here.
                Once you invite other directors, you
                can switch one of them to be the head of the organization.
              </Typography>

              <SupportLink
                buttonText="Learn how to change the head director"
                slug="change-head-director"
              />
            </EnhancedAlert>
          </CardContent>
        </StyledCard>

        <EnhancedCard>
          <OnboardingOrganizationInfoForm
            districtStateId={data?.district.state.id}
          />
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default AdminCreateOrganization;
