// External Dependencies
import {
  GridRowId,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import EmailCheckIcon from 'mdi-material-ui/EmailCheck';
import EmailSearchIcon from 'mdi-material-ui/EmailSearch';

// Internal Dependencies
import {
  EnhancedAlert,
  SubscriberAddButton,
  TableDataGrid,
} from 'components/shared';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useMarkChecklistItemCompleted } from 'gql/mutations';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import ChecklistTableZeroState from './ChecklistTableZeroState';

// Local Typings
interface Props {
  checklistData: GQL.IChecklist;
  loading: boolean;
}

// Local Variables
const tableResource: 'checklist' = 'checklist';
const leftPinnedColumns = ['firstName', 'lastName'];

// Component Definition
const ChecklistTable = ({
  checklistData,
  loading,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const [
    selectedMemberIds,
    setSelectedMemberIds,
  ] = useState<GridRowSelectionModel>([]);
  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);

  const dispatch = useDispatch();

  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));

  const {
    checklistItems: checklistItemsData,
    memberIds,
  } = checklistData;

  const [markChecklistItemCompleted] = useMarkChecklistItemCompleted();

  const hasChecklistItems = checklistItemsData.length > 0;
  const hasMoreThanOneChecklistItem = checklistItemsData.length > 1;
  const hasMembers = memberIds.length > 0;

  const showNoItemsAlert = !hasChecklistItems;
  const showNoMembersAlert = !hasMembers;
  const hasNoData = showNoItemsAlert && showNoMembersAlert;

  const handleNavigateToEditPage = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}/${checklistData.id}/edit?step=1`);
  }, [checklistData.id, navigate]);

  const handleClickEmailMembers = useCallback((ids: string[]) => () => {
    // Add ids to the recipient ids for a new email
    dispatch(updateRecipients(ids));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch, navigate]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    // TODO: Add a permission check for edit checklist here
    //  when we add those permissions on the backend
    actions.push({
      action: handleNavigateToEditPage,
      icon: <EditIcon />,
      text: 'Edit Checklist Data',
    });

    if (canEmailMembers) {
      actions.push(...[
        {
          action: handleClickEmailMembers(selectedMemberIds as string[]),
          icon: <EmailCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Email',
          text: `Email selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickEmailMembers(filteredRowIds as string[]),
          icon: <EmailSearchIcon />,
          isDisabled: filteredRowIds.length === 0,
          text: `Email filtered (${filteredRowIds.length})`,
        },
      ]);
    }

    return actions;
  }, [
    canEmailMembers,
    filteredRowIds,
    handleClickEmailMembers,
    handleNavigateToEditPage,
    selectedMemberIds,
  ]);

  const columnArgs = useMemo(() => ({
    checklist: checklistData,
    tableResource,
  }), [
    checklistData,
  ]);

  const columns = useColumns(columnArgs);

  const processRowUpdate = useCallback((
    newRow: GridRowModel<GQL.IChecklistMemberItemDetails>,
    oldRow: GridRowModel<GQL.IChecklistMemberItemDetails>,
  ) =>
    new Promise<GridRowModel>((resolve) => {
      const oldRowChecklistItemLookup = oldRow?.checklistItems.reduce((acc, item) => {
        acc[item.itemId] = item;

        return acc;
      }, {} as Record<string, GQL.IChecklistMemberDetailsItem>);

      const newRowChecklistItemLookup = newRow?.checklistItems.reduce((acc, item) => {
        acc[item.itemId] = item;

        return acc;
      }, {} as Record<string, GQL.IChecklistMemberDetailsItem>);

      const checklistItemIds = oldRow?.checklistItems.map((item) => item.itemId) ?? [];

      const changedChecklistItemId = checklistItemIds.find((id) => {
        const oldRowChecklistItem = oldRowChecklistItemLookup?.[id];
        const newRowChecklistItem = newRowChecklistItemLookup?.[id];

        return oldRowChecklistItem?.completedAt !== newRowChecklistItem?.completedAt;
      });

      const newChecklistItem = newRowChecklistItemLookup?.[changedChecklistItemId ?? ''];

      if (newChecklistItem) {
        markChecklistItemCompleted({
          variables: {
            input: {
              checklistItemId: newChecklistItem.itemId,
              isCompleted: Boolean(newChecklistItem?.completedAt),
              memberId: oldRow.id,
            },
          },
        });
      }

      resolve(newRow);
    }), [markChecklistItemCompleted]);

  const addButtonElement = useMemo(() => (
    <SubscriberAddButton
      label="Add Checklist Data"
      onClick={handleNavigateToEditPage}
    />
  ), [handleNavigateToEditPage]);

  const alertElement = useMemo(() => (
    <Collapse in={showNoItemsAlert || showNoMembersAlert}>
      <EnhancedAlert
        action={addButtonElement}
        severity="warning"
        sx={{ marginBottom: 2 }}
        title="Missing Data"
      >
        Add {`${showNoMembersAlert ? 'Members' : ''}
          ${hasNoData ? ' and ' : ''}
          ${showNoItemsAlert ? 'Checklist Items ' : ''}`}
        to this checklist to get started.
      </EnhancedAlert>
    </Collapse>
  ), [addButtonElement, hasNoData, showNoItemsAlert, showNoMembersAlert]);

  return (
    <>
      {alertElement}

      <DataGridContainer>
        <TableDataGrid
          checkboxSelection
          columns={columns}
          components={{
            NoRowsOverlay: ChecklistTableZeroState,
          }}
          getRowId={(row: GQL.IChecklistItem) => row.id}
          leftPinnedColumns={hasMoreThanOneChecklistItem ? leftPinnedColumns : undefined}
          loading={loading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedMemberIds}
          processRowUpdate={processRowUpdate}
          rows={checklistData.memberItemDetails}
          selectionModel={selectedMemberIds}
          skipLocalDataFromIndexedDb
          tableResource={tableResource}
          toolbarActions={toolbarActions}
          withEditMode
          withSearch={!hasNoData}
        />
      </DataGridContainer>
    </>
  );
};

export default ChecklistTable;
