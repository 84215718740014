// External Dependencies
import { FC } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

// Local Typings
interface Props {
  children: any;
  // eslint-disable-next-line react/no-unused-prop-types
  wide?: boolean;
}

interface OwnStyleProps {
  wide?: boolean;
}

// Local Variables
const useStyles = makeStyles({
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // eslint-disable-next-line no-confusing-arrow
    maxWidth: (props: OwnStyleProps) => props.wide ? 600 : 384,
    padding: '24px 0',
    width: '100%',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 12,
  },
});

const FormPaper: FC<Props> = (props) => {
  const { children } = props;

  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </div>
  );
};

export default FormPaper;
