// External Dependencies
import { HistoryResource } from '@presto-assistant/api_types';
import gql from 'graphql-tag';

// Internal Dependencies
import { useLazyQueryEnhanced } from 'utils/lib/graphql';

// Local Variables
const GET_HISTORY = gql`
  query History(
    $id: ID!
    $nextCursor: String
    $resource: String!
  ) {
    history(
      id: $id
      nextCursor: $nextCursor
      resource: $resource
    ) {
      changes {
        date
        id
        message
        updates
      }
      id
      nextCursor
    }
  }
`;

export const useGetHistory = ({
  id,
  nextCursor,
  resource,
}: {
  id: string;
  nextCursor?: string;
  resource: HistoryResource;
}) =>
  useLazyQueryEnhanced<
    { history: GQL.IAuditHistory },
    GQL.IHistoryOnQueryArguments
  >(
    GET_HISTORY,
    {
      variables: {
        id,
        nextCursor,
        resource,
      },
    },
  );
