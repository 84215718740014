// External Dependencies
import {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import AvatarEditor from 'react-avatar-editor';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';

interface Args {
  file: File | null;
  onSave: (dataUrl: string) => void;
}

// Local Variables
const initialScale = 50;

export const useImageCrop = (args: Args) => {
  const editorRef = useRef<AvatarEditor>(null);

  const [scale, setScale] = useState(initialScale); // 50 = 1, 100 = 2

  const src = useMemo(() => (args.file ? URL.createObjectURL(args.file) : ''), [args.file]);

  const handleChangeScale = useCallback((event: Event, newScale: number | number[]) => {
    setScale(newScale as number);
  }, []);

  const handleResetScale = useCallback(() => {
    setScale(initialScale);
  }, []);

  const handleClickSave = useCallback(() => {
    if (editorRef?.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();

      // This is the URL we want to send to the API
      const url = canvasScaled.toDataURL(UPLOAD_FILE_TYPES.png);

      args.onSave(url);
    }
  }, [editorRef?.current, args.onSave]);

  return useMemo(() => ({
    editorRef,
    handleChangeScale,
    handleClickSave,
    handleResetScale,
    scale,
    src,
  }), [
    editorRef,
    handleChangeScale,
    handleClickSave,
    handleResetScale,
    scale,
    src,
  ]);
};
