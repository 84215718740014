// External Dependencies
import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

// Component Definition
const PriceChangeSvg: FC<SvgIconProps> = ({ ...otherProps }) => (
  <SvgIcon
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    {...otherProps}
  >
    <g>
      <path
        d="M0,0h24v24H0V0z"
        fill="none"
      />
    </g>
    <g>
      <g>
        <path d="M8,17h2v-1h1c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1H8v-1h4V8h-2V7H8v1H7C6.45,8,6,8.45,6,9v3c0,0.55,0.45,1,1,1h3v1H6v2 h2V17z M20,4H4C2.89,4,2.01,4.89,2.01,6L2,18c0,1.11,0.89,2,2,2h16c1.11,0,2-0.89,2-2V6C22,4.89,21.11,4,20,4z M20,18H4V6h16V18z M14,10l2-2l2,2 M18,14.25l-2,2l-2-2" />
      </g>
    </g>
  </SvgIcon>
);

export default PriceChangeSvg;
