// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

// Local Variables
const StyledTypography = styled(Typography)({
  margin: '12px 0 4px',
  paddingLeft: 12,
}) as typeof Typography;

// Local Typings
interface Props {
  subtitleText: string;
}

// Component Definition
const SideNavSubtitle: FC<Props> = ({ subtitleText }) => (
  <StyledTypography
    color="textSecondary"
    component="p"
    variant="subtitle2"
  >
    {subtitleText}
  </StyledTypography>
);

export default SideNavSubtitle;
