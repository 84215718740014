/* eslint-disable func-names */
// External Dependencies
import * as yupJS from 'yup';
import validateZipcode from './lib/validate_zipcode';

yupJS.addMethod(
  yupJS.string,
  'isPositive',
  function (message: string = 'Value may not be negative') {
    return this.test(
      'notNegative',
      message,
      function (value: string) {
        const { createError, path } = this;

        if (value?.trim().startsWith('-')) {
          return createError({
            message,
            path,
          });
        }

        return true;
      },
    );
  },
);

yupJS.addMethod(
  yupJS.string,
  'zipcode',
  function (message: string = 'Invalid zipcode') {
    return this.test(
      'notInvalidZipcode',
      message,
      function (value: string) {
        const { createError, path } = this;

        if (!validateZipcode(value)) {
          return createError({
            message,
            path,
          });
        }

        return true;
      },
    );
  },
);

export const yup = yupJS;
