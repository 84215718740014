// External Dependencies
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import OrgListIcon from '@mui/icons-material/Apps';
import styled from 'styled-components';

// Internal Dependencies
import {
  Address,
  EnhancedCard,
  OrganizationCode,
} from 'components/shared';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { getFullName } from 'utils';
import { switchToOrgAsDfa } from 'utils/api';

// Local Typings
interface Props {
  organization?: GQL.IOrganization;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['500'],
  },
  '.listItem': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.orgSubheader': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const OrganizationInfoCard: FC<Props> = ({ organization }) => {
  const [isSwitchingToOrg, setIsSwitchingToOrg] = useState(false);

  if (!organization) {
    return null;
  }

  const handleSwitchToOrganization = async () => {
    setIsSwitchingToOrg(true);

    try {
      await switchToOrgAsDfa({ organizationId: organization?.id ?? '' });
    } catch (error) {
      setIsSwitchingToOrg(false);
    }
  };

  const {
    addressOne,
    addressTwo,
    city,
    code,
    creator,
    currentSchoolYearEnding,
    entityType,
    label: orgName,
    organizationType,
    phoneNumber,
    state,
    zipcode,
  } = organization;

  const subheaderTopRow = `${entityType.label} ${organizationType.label}`;

  return (
    <StyledEnhancedCard>
      <CardHeader
        avatar={(
          <Avatar className="avatar">
            <OrgListIcon aria-label="Organization information" />
          </Avatar>
        )}
        subheader={subheaderTopRow}
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            {organization?.label}

            <Button
              color="primary"
              disabled={isSwitchingToOrg || !organization}
              onClick={handleSwitchToOrganization}
              size="small"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Switch to Organization
            </Button>
          </Typography>
        )}
      />

      <CardContent>
        <Typography
          className="orgSubheader"
          component="h3"
        >
          Organization Info
        </Typography>

        <List>
          <ListItem className="listItem">
            <ListItemText
              primary={orgName}
              primaryTypographyProps={{
                component: 'div',
              }}
              secondary={(
                <Address
                  addressOne={addressOne}
                  addressTwo={addressTwo}
                  city={city}
                  phoneNumber={phoneNumber}
                  state={state.label}
                  zipcode={zipcode}
                />
              )}
              secondaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>
      </CardContent>

      <Divider />

      <CardContent>
        <Typography
          className="orgSubheader"
          component="h3"
        >
          Organization Creator
        </Typography>
        <List>
          <ListItem className="listItem">
            {creator ? (
              <ListItemText
                primary={getFullName({
                  firstName: creator.firstName,
                  lastName: creator.lastName,
                  middleName: creator.middleName,
                })}
                primaryTypographyProps={{
                  component: 'div',
                }}
                secondary={(
                  <Address
                    email={creator.email}
                    phoneNumber={creator.phoneNumber}
                  />
                )}
                secondaryTypographyProps={{
                  component: 'div',
                }}
              />
            ) : 'No director'}
          </ListItem>
        </List>
      </CardContent>

      <Divider />

      <CardContent>
        <Typography
          className="orgSubheader"
          component="h3"
        >
          Organization School Year
        </Typography>

        <List>
          <ListItem>
            <ListItemText
              primary={displaySchoolYearString(currentSchoolYearEnding)}
            />
          </ListItem>
        </List>
      </CardContent>

      <Divider />

      <OrganizationCode
        code={code}
        isAdminView
        orgName={orgName}
      />
    </StyledEnhancedCard>
  );
};

export default OrganizationInfoCard;
