import { FRAGMENT_PERMISSION_ACTIONS, FRAGMENT_USER_PERMISSIONS } from 'gql/queries/user-queries';
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
export const GET_ALL_DISTRICT_DIRECTOR_PERMISSIONS = gql`
  query AllDistrictDirectorPermissions {
    allDistrictDirectorPermissions {
      mayEditPermissions
      member {
        email
        firstName
        id
        lastName
      }
      organization {
        id
        label
      }
      permissions {
        ...UserPermissionsFragment
      }
    }
  }
  ${FRAGMENT_PERMISSION_ACTIONS}
  ${FRAGMENT_USER_PERMISSIONS}
`;

export const useGetAllDistrictDirectorPermissions = () =>
  useQueryEnhanced<{ allDistrictDirectorPermissions: GQL.IMemberPermission[] }>(
    GET_ALL_DISTRICT_DIRECTOR_PERMISSIONS,
  );
