// External Dependencies
import { useTheme } from 'styled-components';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';

// Local Dependencies
import GradeCounts from './GradeCounts';

// Local Typings
interface Props {
  gradeCounts: GQL.IGradeCount[];
}

// Local Variables
const subtitle = 'Students by Grade';

// Component Definition
const GradeCountsCard = ({ gradeCounts }: Props): JSX.Element => {
  const {
    palette: {
      stripeBlue,
    },
  } = useTheme();

  // We don't render this component if there are no members yet
  if (gradeCounts.length < 1) {
    return (
      <DashboardCard
        content={(
          <>
            <ZeroStateIcon
              Icon={InsertChartOutlinedIcon}
              htmlColor={stripeBlue['500']}
            />
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Add students to your organization to see metrics
            </Typography>
          </>
        )}
        horizontallyCenterContent
        title={subtitle}
        verticallyCenterContent
      />
    );
  }

  return (
    <DashboardCard
      content={<GradeCounts gradeCounts={gradeCounts} />}
      horizontallyCenterContent
      title={subtitle}
    />
  );
};

export default GradeCountsCard;
