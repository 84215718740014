// External Dependencies
import { createActionCreator } from 'deox';

import { SuccessDialogProps } from './reducers';

// Actions
export const closeDialogSuccess = createActionCreator('CLOSE_DIALOG_SUCCESS');
export const openDialogSuccess = createActionCreator(
  'OPEN_DIALOG_SUCCESS',
  (resolve) => (dialogProps: Omit<SuccessDialogProps, 'isOpen'>) =>
    resolve(dialogProps),
);
