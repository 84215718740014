// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const GroupsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="Use the button at the top to manually add a new group."
  />
);

export default GroupsTableZeroState;
