// External Dependencies
import {
  CreateFinancialPaymentProviderAccountPayload,
} from '@presto-assistant/api_types/api/v1/financialPaymentProviderAccount';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { evictQueryCache } from 'utils/lib/graphql';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { useTanstackMutation } from 'utils/api/index';

export const useCreateFinancialPaymentProviderAccount = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: CreateFinancialPaymentProviderAccountPayload) => {
    return apiClient.v1.financialPaymentProviderAccount.create(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('financialAccount');
      evictQueryCache(queryClient, ['financialPaymentProviderAccountList']);
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};

export const useGetFinancialPaymentProviderAccounts = () => {
  const canReadFinancialAccounts = useSelector(hasPermission('financialAccounts', 'read'));
  const isUserDfa = useSelector(isDistrictAdmin);

  const queryFn = useCallback(() => {
    return apiClient.v1.financialPaymentProviderAccount.list();
  }, []);

  return useQuery({
    enabled: canReadFinancialAccounts && !isUserDfa,
    queryFn,
    queryKey: ['financialPaymentProviderAccountList'],
  });
};
