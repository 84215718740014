// External Dependencies
import { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';

// Internal Dependencies
import { ContextData } from 'types/context';
import { removeNotification } from 'state/notifications/actions';

// Local Typings
interface Props {
  notification: ContextData.INotificationDetail;
}

// Local Variables
// Borrowed from the MUI docs:
// https://mui.com/material-ui/react-snackbar/#customization
const Alert = forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    {...props}
  />
));

// Component Definition
const Notification = ({ notification }: Props) => {
  const dispatch = useDispatch();
  const {
    autoHideDuration = 5000, id, label, severity,
  } = notification;

  const handleClose = useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
    // If autoHideDuration is null, ignore 'clickaway'
    if (autoHideDuration === null && reason === 'clickaway') {
      return;
    }
    dispatch(removeNotification(id));
  }, [dispatch, id, autoHideDuration]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        open
      >
        <Alert
          onClose={handleClose}
          severity={severity ?? 'error'}
        >
          <span id="message-id">{label}</span>
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default Notification;
