// External Dependencies
import {
  ChangeEvent, FC, FocusEvent, useEffect, useState,
} from 'react';
import { TextFieldProps } from '@mui/material';

// Internal Dependencies
import { Input } from 'components/shared';
import { isPasswordValid } from 'utils/lib/validate_password';

// Local Dependencies
import PasswordStrengthGuide from './PasswordStrengthGuide';

// Local Typings
interface Props {
  displayStrengthGuide?: boolean;
  errorMessage?: string;
  helperText?: string;
  onBlur?: (evt: FocusEvent<HTMLInputElement>) => void;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

// Local Variables
const noOp = () => { };

// Component Definition
const PasswordField: FC<Props & TextFieldProps> = ({
  displayStrengthGuide = true,
  errorMessage,
  helperText = null,
  onBlur = noOp,
  onChange,
  value: propsValue,
  ...props
}) => {
  const [value, setValue] = useState(propsValue);
  const [errors, setErrors] = useState(isPasswordValid(value));

  useEffect(() => {
    setErrors(isPasswordValid(value));
  }, [value]);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    if (onChange) {
      onChange(evt);
    }
  };

  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    if (onBlur) {
      onBlur(evt);
    }
  };

  const hasError = !errors.isValid;

  return (
    <Input
      {...props}
      error={hasError}
      helperText={(
        displayStrengthGuide
          ? (
            <PasswordStrengthGuide
              strengthGuide={errors.strengthGuide}
            />
          ) : (
            helperText || errorMessage
          )
      )}
      onBlur={handleBlur}
      onChange={handleChange}
      type="password"
      value={propsValue}
    />
  );
};

export default PasswordField;
