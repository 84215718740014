// External Dependencies
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';
import { becomeUser } from 'utils/api';

export const useBecomeUser = (
  organizationId: string,
) => {
  const dispatch = useDispatch();

  return useCallback(async (userId: string) => {
    const response = await becomeUser({
      organizationId,
      userId,
    });

    const { error } = response.data as any;

    if (response.status === 200) {
      window.location.href = window.location.origin;
    } else if (error) {
      dispatch(addNotification(error, 'error'));
    }
  }, [organizationId]);
};
