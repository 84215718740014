// External Dependencies
import {
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import { FC } from 'react';
import FolderIcon from '@mui/icons-material/Folder';

// Internal Dependencies
import {
  Container,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { displayFileSize } from 'utils/lib/display_file_size';
import { getFullName } from 'utils';
import { useGetFileSignedUrl } from 'gql/queries';

// Local Typings
interface Props {
  canEditFiles: boolean;
  editPath: string;
  file?: GQL.IFile;
}

// Component Definition
const FileShowData: FC<Props> = ({
  canEditFiles,
  editPath,
  file,
}) => {
  const [getSignedUrl] = useGetFileSignedUrl();

  if (!file) {
    return null;
  }

  const {
    createdBy: {
      email,
      firstName,
      lastName,
      middleName,
    },
    directory,
    endDate,
    fileName,
    fileSize,
    id,
    isPublic: isVisibleToAllUsers,
    schoolYearEnding,
    startDate,
  } = file;

  const formattedSchoolYear = schoolYearEnding === null
    ? 'All Years'
    : `${schoolYearEnding - 1}-${schoolYearEnding}`;

  const handleDownloadFile = () => {
    if (id) {
      getSignedUrl({ variables: { id } });
    }
  };

  return (
    <Container maxWidth={500}>
      <Box mb={2}>
        <ShowCard
          canEdit={canEditFiles}
          editPath={editPath}
          icon={FolderIcon}
          title="File Details"
        >
          <ShowPageDataDisplay
            label="Directory"
            value={directory}
          />
          <ShowPageDataDisplay
            label="File Name"
            value={fileName}
          />
          <ShowPageDataDisplay
            label="File Size"
            value={displayFileSize(fileSize)}
          />
          <ShowPageDataDisplay
            label="School Year"
            value={formattedSchoolYear}
          />
          <ShowPageDataDisplay
            label="Start Date"
            value={startDate}
          />
          <ShowPageDataDisplay
            label="End Date"
            value={endDate}
          />
          <ShowPageDataDisplay
            label="Added By"
            value={`${getFullName({ firstName, lastName, middleName })} (${email})`}
          />
          <ShowPageDataDisplay
            label="Is visible to all users?"
            type="boolean"
            value={isVisibleToAllUsers}
          />

          <DialogActions>
            <Button
              color="primary"
              onClick={handleDownloadFile}
              variant="contained"
            >
              Download
            </Button>
          </DialogActions>
        </ShowCard>
      </Box>
    </Container>
  );
};

export default FileShowData;
