// External Dependencies
import { get, set } from 'idb-keyval';
import CryptoJS from 'crypto-js';

// Local Variables
const encrypt = (data: any, encryptionKey: string) => {
  const dataJsonString = JSON.stringify(data);

  const encrypted = CryptoJS.AES.encrypt(dataJsonString, encryptionKey);

  return encrypted.toString();
};

const decrypt = (encryptedData: any, encryptionKey: string) => {
  if (!encryptionKey) {
    return null;
  }

  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, encryptionKey);

    const jsonString = decrypted.toString(CryptoJS.enc.Utf8);

    return JSON.parse(jsonString);
  } catch {
    return null;
  }
};

export async function readFromDb({
  encryptionKey,
  memberId,
  resource,
}: {
  encryptionKey: string;
  memberId: string;
  resource: string;
}) {
  try {
    const key = `${memberId}-${resource}`;

    const encryptedData = await get(key);

    if (!encryptedData) {
      return null;
    }

    return decrypt(encryptedData, encryptionKey);
  } catch {
    return null;
  }
}

export async function writeToDb({
  data,
  encryptionKey,
  memberId,
  resource,
}: {
  data: any;
  encryptionKey: string;
  memberId: string;
  resource: string;
}) {
  try {
    const key = `${memberId}-${resource}`;

    if (!encryptionKey) {
      return null;
    }

    const encrypted = encrypt(data, encryptionKey);

    return set(key, encrypted);
  } catch {
    return null;
  }
}
