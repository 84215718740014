// External Dependencies
import {
  useCallback, useMemo, useState,
} from 'react';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';

// Internal Dependencies
import { DELETE_FORM_BLOCK } from 'gql/mutations/form-mutations';
import { EnhancedCard } from 'components/shared';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Dependencies
import BlockCard from './BlockCard';
import NewBlockButton from './NewBlockButton';

// Local Typings
interface Props {
  blocks: GQL.IFormBlock[];
  formId: string;
}

// Component Definition
const BlocksCard = ({
  blocks,
  formId,
}: Props): JSX.Element => {
  const [deleteBlockId, setDeleteBlockId] = useState<string | null>(null);

  const [newBlockPosition, setNewBlockPosition] = useState<number | null>(null);

  const highestBlockPosition = useMemo(
    () => Math.max(...blocks.map((block) => block.position), 0),
    [blocks],
  );

  const handleCancelDelete = useCallback(() => {
    setDeleteBlockId(null);
  }, []);

  const handleFormatPayload: DeleteDialogV2Props<GQL.IDeleteFormBlockOnMutationArguments>['formatPayload'] = useCallback(() => {
    return {
      formBlockId: deleteBlockId ?? '',
      formId,
    };
  }, [deleteBlockId, formId]);

  return (
    <>
      <EnhancedCard>
        <CardContent>
          <List>
            {blocks.map((block) => {
              return (
                <li key={block.id}>
                  <NewBlockButton
                    blockPosition={block.position}
                    formId={formId}
                    key={block.id}
                    newBlockPosition={newBlockPosition}
                    setNewBlockPosition={setNewBlockPosition}
                  />

                  <BlockCard
                    block={block}
                    formId={formId}
                    setDeleteBlockId={setDeleteBlockId}
                  />
                </li>
              );
            })}
          </List>

          <NewBlockButton
            alwaysShow
            blockPosition={highestBlockPosition + 1}
            formId={formId}
            key={`new-block-${blocks.length + 1}`}
            newBlockPosition={newBlockPosition}
            setNewBlockPosition={setNewBlockPosition}
          />
        </CardContent>
      </EnhancedCard>

      <DeleteDialogV2<GQL.IDeleteFormBlockOnMutationArguments>
        context={['form block']}
        formatPayload={handleFormatPayload}
        isOpen={Boolean(deleteBlockId)}
        mutation={DELETE_FORM_BLOCK}
        onClose={handleCancelDelete}
      />
    </>
  );
};

export default BlocksCard;
