// External Dependencies
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  DistrictCard,
  EnhancedAlert,
  Page,
  PurchaseALicenseCard,
} from 'components/shared';
import { StyledDashboardDiv } from 'components/shared/Dashboard/styles';
import {
  hasPermission,
  isDirector as isDirectorSelector,
  isNonParentOrStudent as isNonParentOrStudentSelector,
  isParentOrStudent as isParentOrStudentSelector,
  isParent as isParentSelector,
  isStudent as isStudentSelector,
} from 'state/self/selectors';
import { useDashboardOrgData } from 'hooks/useDashboardOrgData';
import {
  useGetItemsNeedingAttention,
  useGetPendingMembersCount, useGetSelf,
} from 'gql/queries';

// Local Dependencies
import Box from '@mui/material/Box';
import DashboardFamilyRelationshipsInfo from './DashboardFamilyRelationshipsInfo';
import EndOfYearProcessCard from './EndOfYearProcessCard';
import FinancialOverviewCard from './FinancialOverviewCard';
import InventoryCheckoutsCard from './InventoryCheckoutsCard';
import ItemsNeedingAttentionCard from './ItemsNeedingAttentionCard';
import MetricsWrapper from './MetricsWrapper';
import MyChildrenUnder13Card from './MyChildrenUnder13Card';
import MyFilesOverviewCard from './MyFilesOverviewCard';
import MyFinancialOverviewCard from './MyFinancialOverviewCard';
import MyFormsCard from './MyFormsCard';
import PendingMembersCard from './PendingMembersCard';
import UniformCheckoutsCard from './UniformCheckoutsCard';
import UpcomingCalendarEvents from './UpcomingCalendarEvents';

// Component Definition
const Dashboard = (): JSX.Element => {
  const isDirector = useSelector(isDirectorSelector);
  const isNonParentOrStudent = useSelector(isNonParentOrStudentSelector);
  const isParent = useSelector(isParentSelector);
  const isParentOrStudent = useSelector(isParentOrStudentSelector);
  const isStudent = useSelector(isStudentSelector);
  const orgData = useDashboardOrgData();
  const { data: selfData } = useGetSelf();

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canAddUsers = useSelector(hasPermission('users', 'write'));

  const hasNewOrganizationMemberships = useMemo(() => selfData?.self?.userOrgData?.some(
    (org) => !org.member?.loggedInAt,
  ), [selfData]);

  const {
    data: pendingMemberCountData,
    loading: isPendingMembersLoading,
  } = useGetPendingMembersCount(!canAddUsers);

  const {
    data: itemsNeedingAttentionData,
    loading: isItemsNeedingAttentionLoading,
  } = useGetItemsNeedingAttention();

  const isLoading = isPendingMembersLoading || isItemsNeedingAttentionLoading;

  const showCalendarEvents = (
    isParentOrStudent && orgData?.hasGoogleCalendar
  ) || isNonParentOrStudent;

  return (
    <Page
      isLoading={isLoading}
      noMargin
    >
      <Box>
        {hasNewOrganizationMemberships && (
          <EnhancedAlert sx={{ marginBottom: 2 }}>
            You have new organizations! Click on your
            {' '}
            name in the top right corner to switch between them.
          </EnhancedAlert>
        )}

        <DistrictCard
          districtName={orgData?.districtName}
          orgName={orgData?.orgName}
        />

        {itemsNeedingAttentionData?.itemsNeedingAttention
          && itemsNeedingAttentionData?.itemsNeedingAttention.length > 0 && (
            <ItemsNeedingAttentionCard
              itemsNeedingAttention={
                itemsNeedingAttentionData?.itemsNeedingAttention
              }
            />
        )}

        <StyledDashboardDiv>
          {isNonParentOrStudent
            && (!orgData?.active || orgData?.isFreeTrial) && (
              <PurchaseALicenseCard />
          )}

          {canAddUsers && (
            <PendingMembersCard
              count={pendingMemberCountData?.pendingMemberIndex?.fullCount}
            />
          )}

          {showCalendarEvents && <UpcomingCalendarEvents />}

          {orgData?.canBeginEndOfYearProcess && isDirector && (
            <EndOfYearProcessCard
              hasStartedEndOfYearProcess={orgData?.hasStartedEndOfYearProcess}
            />
          )}

          {isParent && (
            <>
              <MyChildrenUnder13Card />

              <MyFinancialOverviewCard />
            </>
          )}

          {isParentOrStudent && (
            <>
              <MyFormsCard />
              <DashboardFamilyRelationshipsInfo />
            </>
          )}

          {isStudent && (
            <>
              <InventoryCheckoutsCard />
              <UniformCheckoutsCard />
              <MyFilesOverviewCard />
            </>
          )}

          {canReadFinances && <FinancialOverviewCard />}

          {isNonParentOrStudent && <MetricsWrapper />}
        </StyledDashboardDiv>
      </Box>
    </Page>
  );
};

export default Dashboard;
