// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
  EnhancedAlert,
} from 'components/shared';
import {
  useCreateLibraryInstrumentation,
  useDistrictCreateLibraryInstrumentation,
} from 'gql/mutations';

// Local Typings
interface Props {
  isAdmin: boolean;
  isOpen: boolean;
  onClose: () => void;
  organizationId: string | undefined;
}

interface FormValues {
  instrumentation: string;
}

// Local Variables
const initialValues: FormValues = {
  instrumentation: '',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

// Component Definition
const DialogAddLibraryInstrumentation = ({
  isAdmin,
  isOpen,
  onClose,
  organizationId,
}: Props): JSX.Element | null => {
  const [
    handleCreateLibraryInstrumentation,
    {
      loading,
    },
  ] = useCreateLibraryInstrumentation({
    clearCachePredicates: ['libraryInstrumentations', 'globalEditContract'],
    onCompleted: onClose,
  });

  const [
    handleDistrictCreateLibraryInstrumentation,
    {
      loading: districtLoading,
    },
  ] = useDistrictCreateLibraryInstrumentation({
    clearCachePredicates: ['dfaLibraryInstrumentations'],
    onCompleted: onClose,
  });

  if (isAdmin && !organizationId) {
    return null;
  }

  const handleSubmit = async (values: FormValues) => {
    if (isAdmin && organizationId) {
      await handleDistrictCreateLibraryInstrumentation({
        variables: {
          input: {
            label: values.instrumentation,
            organizationId,
          },
        },
      });
    } else {
      await handleCreateLibraryInstrumentation({
        variables: {
          input: {
            label: values.instrumentation,
          },
        },
      });
    }
  };

  return (
    <DialogForm<FormValues>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={districtLoading || loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Add Library Voice/Instrumentation"
    >
      <StyledTypography color="textSecondary">
        A library voice/instrumentation will be available when adding or editing library items.
      </StyledTypography>

      <Box mb={2}>
        <CustomInput
          label="New Instrumentation"
          name="instrumentation"
        />
      </Box>

      {isAdmin && (
        <EnhancedAlert severity="info">
          The new instrumentation will only apply to the selected organization.
        </EnhancedAlert>
      )}
    </DialogForm>
  );
};

export default DialogAddLibraryInstrumentation;
