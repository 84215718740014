// External Dependencies
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import InventoryIcon from '@mui/icons-material/Inventory';

// Internal Dependencies
import {
  Container,
  EnhancedCard,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import {
  useGetInventoryAudit,
  useGetInventoryAuditAssessmentsByInventoryId,
} from 'utils/api/inventory';
import { useParamsWithId } from 'hooks/useParamsWithId';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';

// Local Dependencies
import CreateAssessmentDialog, { SelectionAction } from './CreateAssessmentDialog';
import InventoryTable from './InventoryTable';
import MarkAsComplete from './MarkAsComplete';

// Component Definition
const InventoryAuditShow = (): JSX.Element => {
  const [selectedInventoryItemId, setSelectedInventoryItemId] = useState<string | null>(null);
  const [selectionAction, setSelectionAction] = useState<SelectionAction>('markPresent');

  const { id } = useParamsWithId();

  const {
    data,
    isLoading,
  } = useGetInventoryAudit(id!);

  const {
    data: inventoryAuditAssessmentByInventoryId,
  } = useGetInventoryAuditAssessmentsByInventoryId(id!);

  const inventoryAuditAssessmentData = useMemo(() => {
    return inventoryAuditAssessmentByInventoryId?.data.data || {};
  }, [inventoryAuditAssessmentByInventoryId]);

  const inventoryAuditParams = useSelector(tableQueryParams('inventoryAudits'));

  const inventoryAudit = data?.data.data;

  const handleClickAssessment = useCallback((args: {
    inventoryItemId: string;
    isPresent: boolean;
  }) => {
    setSelectedInventoryItemId(args.inventoryItemId);
    setSelectionAction(args.isPresent ? 'markPresent' : 'markAbsent');
  }, []);

  const handleClose = useCallback(() => {
    setSelectedInventoryItemId(null);
  }, []);

  const numberOfInventoryItems = inventoryAudit?.numberOfInventoryItems ?? 0;
  const numberOfInventoryItemsAudited = inventoryAudit?.numberOfInventoryItemsAudited ?? 0;
  const itemsRemaining = numberOfInventoryItems - numberOfInventoryItemsAudited;

  return (
    <>
      <Page
        backButtonProps={{
          label: 'All Inventory Audits',
          path: `/${PATHS.INVENTORY_AUDITS}${inventoryAuditParams}`,
        }}
        isLoading={isLoading}
      >
        {inventoryAudit && (
          <>
            <Container maxWidth={500}>
              <Box mb={2}>
                <ShowCard
                  cardActions={(
                    <CardActions>
                      <MarkAsComplete
                        inventoryAuditId={inventoryAudit.id}
                        isComplete={Boolean(inventoryAudit.completedAt)}
                        itemsRemaining={itemsRemaining}
                      />
                    </CardActions>
                  )}
                  icon={InventoryIcon}
                  title="Inventory Audit Details"
                >
                  <ShowPageDataDisplay
                    label="Title"
                    value={inventoryAudit.label}
                  />
                  <ShowPageDataDisplay
                    label="Due Date"
                    type="date"
                    value={inventoryAudit.dueDate}
                  />
                  <ShowPageDataDisplay
                    label="Completed At"
                    type="datetime"
                    value={inventoryAudit.completedAt}
                  />

                  <PercentageProgressBar
                    denominator={inventoryAudit.numberOfInventoryItems}
                    label="Items audited"
                    numerator={inventoryAudit.numberOfInventoryItemsAudited}
                  />
                </ShowCard>
              </Box>
            </Container>

            <EnhancedCard>
              <DataGridContainer>
                <InventoryTable
                  inventoryAuditAssessmentData={inventoryAuditAssessmentData}
                  inventoryAuditCreatedAt={inventoryAudit.createdAt}
                  isComplete={Boolean(inventoryAudit.completedAt)}
                  onClickAssessment={handleClickAssessment}
                />
              </DataGridContainer>
            </EnhancedCard>
          </>
        )}
      </Page>

      <CreateAssessmentDialog
        inventoryAuditId={id!}
        inventoryItemId={selectedInventoryItemId}
        onClose={handleClose}
        selectionAction={selectionAction}
      />
    </>
  );
};

export default InventoryAuditShow;
