import { ApolloError } from '@apollo/client';

export const normalizeFormErrors = (error: ApolloError) =>
  (error?.graphQLErrors?.[0]?.extensions?.errors ?? {}) as Record<string, string>;

export const getDefaultErrorMessage = (
  error: ApolloError | undefined,
  defaultMessage: string,
): string => {
  const errorMessage = error?.graphQLErrors?.[0]?.message;
  const networkErrors = (error as any)?.networkError?.result?.errors;
  const resultError = (error as any)?.networkError?.result?.error;
  const firstNetworkError = networkErrors?.[0]?.message ?? resultError;
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (!errorMessage) {
    return isDevelopment
      ? firstNetworkError ?? defaultMessage
      : resultError ?? defaultMessage;
  }

  if (error && errorMessage === 'User input error') {
    const normalizedErrors = normalizeFormErrors(error);

    const errorValues = Object.values<string>(normalizedErrors);

    if (errorValues.length) {
      return errorValues[0];
    }
  }

  return errorMessage === 'Internal Server Error'
    ? defaultMessage
    : errorMessage;
};
