// External Dependencies
import { Form, Formik } from 'formik';
import { createStripePaymentSchema } from '@presto-assistant/api_types/schemas/financialPayments';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Internal Dependencies
import { APP_NAME, TERMS_OF_SERVICE_HREF } from 'utils/constants';
import { CustomCheckbox, SaveButton, StyledLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertDollarsToCents } from 'utils';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { useCreateStripePayment } from 'gql/mutations';
import { useCreateVancoPayment } from 'utils/api/financialPayment';
import paymentSuccessAnimationData from 'components/shared/Lottie/lottieFiles/payment-success.json';

// Local Dependencies
import { PaymentsFormValues } from '../PaymentTable';
import PaymentAmountsCard from '../shared/PaymentAmountsCard';
import PaymentMethodCard, { CardDetails } from '../shared/PaymentMethodCard';

// Local Typings
interface Props {
  cardDetails: CardDetails | null;
  hasFees: boolean;
  onEditPaymentAmounts: () => void;
  onEditPaymentMethod: () => void;
  paymentAmountValues: PaymentsFormValues | null;
  paymentPlatform: 'stripe' | 'vanco';
}

// Component Definition
const PaymentReview = ({
  cardDetails,
  hasFees,
  onEditPaymentAmounts,
  onEditPaymentMethod,
  paymentAmountValues,
  paymentPlatform,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    createStripePayment,
    {
      loading: isCreatingStripePayment,
    },
  ] = useCreateStripePayment({
    clearCachePredicates: ['myFinancialPayments', 'myFinancialFees'],
    onCompleted: () => {
      dispatch(openDialogSuccess({
        animationData: paymentSuccessAnimationData,
        title: 'Payment Successful',
      }));

      navigate(`/${PATHS.STUDENT_PAYMENTS}`);
    },
  });

  const {
    isLoading: isCreatingVancoPayment,
    mutate: createVancoPayment,
  } = useCreateVancoPayment();

  const isSubmitting = isCreatingStripePayment || isCreatingVancoPayment;

  const handleFormikSubmit = useCallback((input: GQL.ICreateStripeFinancialPaymentInput) => {
    if (paymentPlatform === 'stripe') {
      createStripePayment({
        variables: { input },
      });
    } else {
      // make vanco payment
      createVancoPayment({
        hasAcceptedTermsOfService: input.hasAcceptedTermsOfService,
        lineItems: input.lineItems,
        // TODO: rename formik field to paymentMethodToken
        // after we drop stripe payment graphql mutation
        paymentMethodToken: input.stripeToken,
      }, {
        onSuccess: () => {
          dispatch(openDialogSuccess({
            animationData: paymentSuccessAnimationData,
            title: 'Payment Successful',
          }));

          navigate(`/${PATHS.STUDENT_PAYMENTS}`);
        },
      });
    }
  }, [createVancoPayment, createStripePayment, dispatch, navigate, paymentPlatform]);

  if (!cardDetails?.paymentMethodToken || !paymentAmountValues?.payments.length) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Formik<GQL.ICreateStripeFinancialPaymentInput>
        initialValues={{
          hasAcceptedTermsOfService: false,
          lineItems: paymentAmountValues.payments.map((payment) => ({
            amountInCents: convertDollarsToCents(payment.amountInDollars),
            financialFeeId: payment.financialFeeId,
          })),
          stripeToken: cardDetails.paymentMethodToken,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={createStripePaymentSchema}
      >
        {({
          handleSubmit,
        }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
            >
              <PaymentAmountsCard
                hasFees={hasFees}
                onEdit={onEditPaymentAmounts}
                paymentAmounts={paymentAmountValues}
                paymentPlatform={paymentPlatform}
              />

              <PaymentMethodCard
                cardDetails={cardDetails}
                onEdit={onEditPaymentMethod}
              />

              <CustomCheckbox
                label={(
                  <>
                    I confirm the above amount is correct and I agree to the {APP_NAME}
                    {' '}
                    <StyledLink
                      href={TERMS_OF_SERVICE_HREF}
                      noWrap
                      openInNewTab
                    >
                      Terms of Service
                    </StyledLink>

                  </>
                )}
                name="hasAcceptedTermsOfService"
              />

              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <SaveButton
                  color="primary"
                  isSaving={isSubmitting}
                  variant="contained"
                >
                  Submit
                </SaveButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PaymentReview;
