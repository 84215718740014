// External Dependencies
import { GridPreProcessEditCellProps } from '@mui/x-data-grid-pro';
import { range } from '@presto-assistant/api_types/utils';
import { updateUserSchema } from '@presto-assistant/api_types/schemas/user';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { GroupsInput } from 'pages/People/Students/All/StudentsTable/hooks';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { formatPhoneNumber, runValidationSchema } from 'utils';
import {
  getMemberStatusValue,
  memberStatusOptions,
  minColumnWidth,
  renderMemberStatusCell,
} from 'pages/People/shared/memberStatusColumnHelpers';
import { hasPermission } from 'state/self/selectors';
import { renderEditCell } from 'components/shared/TableDataGrid/helpers';
import {
  useFinancialMaterializedViewColumns,
} from 'pages/People/Students/All/StudentsTable/helpers';

const generateStudentColumns = (maxStudentIndex: number) => {
  const studentIndexRange = range(0, maxStudentIndex, { excludeUpperBoundary: true });

  const studentColumns: DataGridColDef<GQL.IParentIndex>[] = studentIndexRange
    .flatMap((studentIndex) => [
      {
        editable: false,
        field: `students[${studentIndex}]?.firstName` as keyof GQL.IParentIndex,
        headerName: `Student ${studentIndex + 1} First Name`,
        minWidth: 150,
        valueGetter: (params) => params.row.students[studentIndex]?.firstName,
      },
      {
        editable: false,
        field: `students[${studentIndex}]?.lastName` as keyof GQL.IParentIndex,
        headerName: `Student ${studentIndex + 1} Last Name`,
        minWidth: 150,
        valueGetter: (params) => params.row.students[studentIndex]?.lastName,
      },
      {
        editable: false,
        field: `students[${studentIndex}]?.email` as keyof GQL.IParentIndex,
        headerName: `Student ${studentIndex + 1} Email`,
        valueGetter: (params) => params.row.students[studentIndex]?.email,
      },
      {
        editable: false,
        field: `students[${studentIndex}]?.studentId` as keyof GQL.IParentIndex,
        headerName: `Student ${studentIndex + 1} Student ID`,
        valueGetter: (params) => params.row.students[studentIndex]?.studentId,
      },
      {
        editable: false,
        field: `students[${studentIndex}]?.relationshipType?.label` as keyof GQL.IParentIndex,
        headerName: `Student ${studentIndex + 1} Relationship`,
        minWidth: 175,
        valueGetter: (params) => params.row.students[studentIndex]?.relationshipType?.label,
      },
    ]);

  return studentColumns;
};

export const useColumns = ({
  extraColumns,
  maxNumberOfStudents,
}: {
  extraColumns?: DataGridColDef<GQL.IParentIndex>[];
  maxNumberOfStudents: number;
}) => {
  const canReadGroups = useSelector(hasPermission('groups', 'read'));

  const financialMaterializedViewColumns = useFinancialMaterializedViewColumns<GQL.IParentIndex>();

  return useMemo(() => {
    const studentColumns: DataGridColDef<GQL.IParentIndex>[] = [
      ...generateStudentColumns(maxNumberOfStudents),
    ];

    const readGroupsColumns: DataGridColDef<GQL.IParentIndex>[] = [
      {
        field: 'groups',
        filterOperators: [
          {
            InputComponent: GroupsInput(),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IParentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return groupIds.some((id) => selectedGroupIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: GroupsInput(),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IParentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => groupIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: GroupsInput(),
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IParentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => !groupIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Groups',
        valueGetter: (params) => (params.row as GQL.IParentIndex).groups
          .map((group) => group.label).join('; '),
      },
    ];

    const preProcessEditCellProps = async (
      params: GridPreProcessEditCellProps,
      fieldName: keyof GQL.IUpdateUserInput,
    ) => {
      const value = params.props.value!.toString();

      // Validate like Formik does
      const errorMessage: any = await runValidationSchema<GQL.IUpdateUserInput>(
        value,
        fieldName,
        updateUserSchema as any,
      );

      return {
        ...params.props,
        error: errorMessage[fieldName as keyof GQL.IUpdateUserInput],
      };
    };
    const columns: DataGridColDef<GQL.IParentIndex>[] = [
      {
        editable: true,
        field: 'firstName',
        headerName: 'First Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'firstName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'middleName',
        headerName: 'Middle Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'middleName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'lastName',
        headerName: 'Last Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'lastName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'email',
        headerName: 'Member Email',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'email',
        ),
        renderEditCell,
        width: 292,
      },
      {
        editable: false,
        field: 'status' as keyof GQL.IParentIndex,
        headerName: 'Member Status',
        minWidth: minColumnWidth,
        renderCell: (params) => renderMemberStatusCell(params.value as string),
        type: 'singleSelect',
        valueGetter: (params) => getMemberStatusValue({
          authUserEmail: params.row.authUserEmail,
          receivedWelcomeEmailAt: params.row.receivedWelcomeEmailAt,
          rowId: params.row.id,
        }),
        valueOptions: memberStatusOptions,
      },
      ...financialMaterializedViewColumns,
      {
        editable: true,
        field: 'phoneNumber',
        headerName: 'Phone Number',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'phoneNumber',
        ),
        valueFormatter: (params) => formatPhoneNumber(params.value),
        width: 292,
      },
      {
        editable: false,
        field: 'authUserEmail',
        headerName: 'Account Email',
      },
      dateTimeColumn({
        editable: false,
        field: 'loggedInAt',
        headerName: 'Last Logged In',
      }),
      ...(canReadGroups ? readGroupsColumns : []),
      ...studentColumns,
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    canReadGroups,
    extraColumns,
    financialMaterializedViewColumns,
    maxNumberOfStudents,
  ]);
};
