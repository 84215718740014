import { useGetOrganization } from 'gql/queries';

export const useDashboardOrgData = () => {
  const { data } = useGetOrganization();

  if (!data?.organization) {
    return null;
  }

  const {
    active,
    canBeginEndOfYearProcess,
    hasGoogleCalendar,
    isFreeTrial,
    label,
    maxCompletedEndOfYearStep,
  } = data.organization;

  return {
    active,
    canBeginEndOfYearProcess,
    districtName: data.organization.district?.label,
    hasGoogleCalendar,
    hasStartedEndOfYearProcess: maxCompletedEndOfYearStep !== null,
    isFreeTrial,
    orgName: label,
  };
};
