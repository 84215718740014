// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { useGetDistrictLibraryCategories } from 'gql/queries';

// Local Dependencies
import { InventoryConditions } from '@presto-assistant/api_types';
import {
  useGetDistrictLibraryInstrumentations,
} from 'gql/queries/library-instrumentations-queries';
import {
  useGetLibraryCategorySelectOptions, useGetLibraryInstrumentationSelectOptions,
} from '../shared/data';

// Local Typings
interface Props {
  isAdmin?: boolean;
  libraryItem: GQL.ILibraryItem;
  organizationId?: string;
}

// Component Definition
const ShowLibraryData: FC<Props> = ({
  isAdmin,
  libraryItem,
  organizationId,
}) => {
  const {
    data: categoryData,
  } = useGetLibraryCategorySelectOptions({ isAdmin });

  const {
    data: districtCategoryData,
  } = useGetDistrictLibraryCategories({ organizationId });

  const {
    data: instrumentationData,
  } = useGetLibraryInstrumentationSelectOptions({ isAdmin });

  const {
    data: districtInstrumentationData,
  } = useGetDistrictLibraryInstrumentations({ organizationId });

  if (!libraryItem) {
    return null;
  }

  let categoryLabel;

  if (isAdmin) {
    categoryLabel = districtCategoryData?.dfaLibraryCategories.find((category) =>
      category.id === libraryItem?.categoryId)?.label;
  } else {
    categoryLabel = categoryData?.libraryCategories.find((category) =>
      category.id === libraryItem?.categoryId)?.label;
  }

  let instrumentationLabel;

  if (isAdmin) {
    instrumentationLabel = districtInstrumentationData?.dfaLibraryInstrumentations
      .find((instrumentation) =>
        instrumentation.id === libraryItem?.instrumentationId)?.label;
  } else {
    instrumentationLabel = instrumentationData?.libraryInstrumentations.find((instrumentation) =>
      instrumentation.id === libraryItem?.instrumentationId)?.label;
  }

  return (
    <>
      {isAdmin && (
        <>
          <ShowPageDataDisplay
            label="Organization Type"
            value={libraryItem.organization?.organizationType?.label}
          />
          <ShowPageDataDisplay
            label="Organization"
            value={libraryItem.organization?.label}
          />
        </>
      )}
      <ShowPageDataDisplay
        label="Category"
        value={categoryLabel}
      />
      <ShowPageDataDisplay
        label="Voice/Instrumentation"
        value={instrumentationLabel}
      />
      <ShowPageDataDisplay
        label="Title"
        value={libraryItem.title}
      />
      <ShowPageDataDisplay
        label="Composer"
        value={libraryItem.composer}
      />
      <ShowPageDataDisplay
        label="Arranger"
        value={libraryItem.arranger}
      />
      <ShowPageDataDisplay
        label="Library Number"
        value={libraryItem.number}
      />
      <ShowPageDataDisplay
        label="Publication Date"
        value={libraryItem.year}
      />
      <ShowPageDataDisplay
        label="Grade Level"
        value={libraryItem.gradeLevel && `${libraryItem.gradeLevel?.value}${libraryItem.gradeLevel?.description
          ? ` (${libraryItem.gradeLevel?.description})`
          : ''}`}
      />
      <ShowPageDataDisplay
        label="System Barcode"
        value={libraryItem.systemBarcode}
      />
      <ShowPageDataDisplay
        label="Condition"
        value={libraryItem.conditionId ? InventoryConditions[Number(libraryItem.conditionId)] : ''}
      />
      <ShowPageDataDisplay
        label="Comments"
        value={libraryItem.comments}
      />
    </>
  );
};

export default ShowLibraryData;
