// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateLibraryItem } from 'gql/mutations';
import { useGetGettingStartedCounts } from 'gql/queries';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import LibraryForm,
{
  LibraryFormValues,
} from '../shared/LibraryForm';

// Component Definition
const LibraryNew: FC = () => {
  const navigate = useNavigate();
  const canUseDynamicFields = useCanUseDynamicFields();

  const libraryParams = useSelector(tableQueryParams('libraryItems'));

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.LIBRARY}/${id}`);
  }, [navigate]);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneLibraryItem = (gettingStartedCountsData?.dashboardMetrics?.libraryCount ?? 0) > 0;

  const [
    handleCreateLibraryItem,
    {
      error,
    },
  ] = useCreateLibraryItem(
    {
      clearCachePredicates: [
        'libraryItemsIndex',
        ...!hasAtLeastOneLibraryItem ? ['dashboardMetrics'] : [],
      ],
      onCompleted: (data) => navigateToShow(data.createLibraryItem.id),
    },
  );

  const handleSubmit = async (values: LibraryFormValues) => {
    const updatedValues = values;

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleCreateLibraryItem({
      variables: {
        input: {
          ...updatedValues,
          ...(canUseDynamicFields
            ? applyDynamicFields(updatedValues as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'All Library Items',
        path: `/${PATHS.LIBRARY}${libraryParams}`,
      }}
      error={error}
    >
      <LibraryForm
        canUseDynamicFields={canUseDynamicFields}
        onSubmit={handleSubmit}
        title="New Library Item"
      />
    </Page>
  );
};

export default LibraryNew;
