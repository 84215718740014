// External Dependencies
import {
  Button,
  ButtonProps,
  CircularProgress,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { CustomFormButton } from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  cancelButtonText?: string;
  context: string;
  isButtonDisabled?: boolean;
  isEditing?: boolean;
  isSubmitting: boolean;
  onClickCancelButton: () => void;
  onPressSubmit?: () => void;
  submitButtonText?: string;
  type?: ButtonProps['type'];
}

// Local Variables
const StyledProgressContainer = styled.div(({ theme }) => ({
  '.progress': {
    width: '25%',
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1, 0),
  },
  margin: theme.spacing(2, 0),
  paddingRight: theme.spacing(3),
}));

// Component Definition
const FormActionButtons: FC<Props> = ({
  cancelButtonText = 'Cancel',
  context,
  isButtonDisabled,
  isEditing = false,
  isSubmitting,
  onClickCancelButton,
  onPressSubmit,
  submitButtonText,
  type,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const action = isEditing ? 'Update' : 'Add';
  const item = isMobileOrTabletScreen ? '' : context;
  const buttonText = submitButtonText ?? `${action} ${item}`.trim();

  return isSubmitting ? (
    <StyledProgressContainer className="progressContainer">
      <CircularProgress
        className="progress"
        size={28}
        thickness={4}
      />
    </StyledProgressContainer>
  ) : (
    <>
      <Button
        className="cancelButton"
        onClick={onClickCancelButton}
        onKeyPress={onClickCancelButton}
        size={isMobileOrTabletScreen ? 'small' : 'large'}
        sx={{ marginRight: 1.5 }}
        variant="outlined"
      >
        {cancelButtonText}
      </Button>

      <CustomFormButton
        buttonText={buttonText}
        disabled={isSubmitting || isButtonDisabled}
        onClick={type === 'button' ? onPressSubmit : undefined}
        size={isMobileOrTabletScreen ? 'small' : 'large'}
        type={type}
      />
    </>
  );
};

export default FormActionButtons;
