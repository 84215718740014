// External Dependencies
import { DynamicFieldTypes } from '@presto-assistant/api_types';
import { FC, useMemo } from 'react';
import { useField } from 'formik';

// Internal Dependencies
import { isDynamicFieldBoolean, isDynamicFieldDate, isDynamicFieldNumber } from 'utils/lib/dyanmic_field';

// Local Dependencies
import {
  CustomInput, CustomSelect, DatePickerField,
} from '..';

// Local Typings
interface Props {
  field: GQL.IDynamicField;
}

// Local Variables
const useErrorMessage = (field: GQL.IDynamicField) => {
  const [formikField] = useField(field.dynamicFieldRef);

  const { value } = formikField;

  const errorMessage = useMemo<string | null>(() => {
    if (!value) {
      return null;
    }

    const typeId = Number(field.type.id);

    if (typeId === DynamicFieldTypes.text) {
      return null;
    }

    if (typeId === DynamicFieldTypes.date && !isDynamicFieldDate(value)) {
      return `"${value}" is not a valid date. Please change this value.`;
    }

    if (typeId === DynamicFieldTypes['double precision'] && !isDynamicFieldNumber(value)) {
      return `"${value}" is not a valid number. Please change this value.`;
    }

    if (typeId === DynamicFieldTypes.boolean && !isDynamicFieldBoolean(value)) {
      return `"${value}" may only be true or false. Please change this value.`;
    }

    return null;
  }, [field, value]);

  return errorMessage;
};

// Component Definition
const CustomDynamicFormField: FC<Props> = ({
  field,
}) => {
  const errorMessage = useErrorMessage(field);

  switch (Number(field.type.id)) {
    case DynamicFieldTypes.boolean:
      return (
        <CustomSelect
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage ?? undefined}
          label={field.label}
          name={field.dynamicFieldRef}
          options={[
            {
              id: 'true',
              label: 'True',
            },
            {
              id: 'false',
              label: 'False',
            },
          ]}
        />
      );
    case DynamicFieldTypes.date:
      return (
        <DatePickerField
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage ?? undefined}
          label={field.label}
          name={field.dynamicFieldRef}
        />
      );
    case DynamicFieldTypes['double precision']:
      return (
        <CustomInput
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage ?? undefined}
          label={field.label}
          key={field.id}
          name={field.dynamicFieldRef}
          type="number"
        />
      );
    case DynamicFieldTypes.text:
      return (
        <CustomInput
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage ?? undefined}
          label={field.label}
          key={field.id}
          name={field.dynamicFieldRef}
        />
      );
    default:
      return null;
  }
};

export default CustomDynamicFormField;
