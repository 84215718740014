export function downloadFile(download: Omit<GQL.IDownload, '__typename'>) {
  const {
    base64,
    contentType,
    fileName,
  } = download;

  const linkSource = `data:${contentType};base64,${base64}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
