// External Dependencies
import { FC, useCallback } from 'react';
import Button from '@mui/material/Button';

// Local Typings
interface Props {
  isDisabled: boolean;
  memberId: string;
  memberName: string;
  onClick: (memberId: string, memberName: string) => void;
}

// Component Definition
const QuickAssignButton: FC<Props> = ({
  isDisabled,
  memberId,
  memberName,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(memberId, memberName);
  }, [
    memberId,
    memberName,
    onClick,
  ]);

  return (
    <Button
      disabled={isDisabled}
      onClick={handleClick}
      size="small"
    >
      Quick Assign Fee
    </Button>
  );
};

export default QuickAssignButton;
