// External Dependencies
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedCard, Page, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import FilesTable from './FilesTable';

// Component Definition
const FilesAllByDirectory: FC = () => {
  const { directory } = useParams<{ directory: string; }>();

  const directoriesParams = useSelector(tableQueryParams('fileDirectories'));

  return (
    <Page
      backButtonProps={{
        label: 'Files',
        path: `/${PATHS.FILES}${directoriesParams}`,
      }}
      isLoading={false}
    >
      <Subtitle>
        {directory!}
      </Subtitle>

      <EnhancedCard>
        <FilesTable directory={directory!} />
      </EnhancedCard>
    </Page>
  );
};

export default FilesAllByDirectory;
