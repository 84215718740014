// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationEnhancedOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DeactivateUserData {
  deactivateUser: boolean;
}
interface ActivateUserData {
  activateUser: boolean;
}
interface ApprovePendingMembersData {
  approveMembers: boolean;
}

export const JOIN_ORGANIZATION = gql`
  mutation JoinOrganization(
    $dateOfBirth: Date
    $email: String
    $firstName: String
    $lastName: String
    $organizationCode: String!
  ) {
    joinOrganization(
      dateOfBirth: $dateOfBirth
      email: $email
      firstName: $firstName
      lastName: $lastName
      organizationCode: $organizationCode
    ) {
      id
      organizationId
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser(
    $userOrganizationId: ID!
  ) {
    deactivateUser(
      userOrganizationId: $userOrganizationId
    )
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser(
    $userOrganizationId: ID!
  ) {
    activateUser(
      userOrganizationId: $userOrganizationId
    )
  }
`;

const APPROVE_PENDING_MEMBERS = gql`
  mutation ApprovePendingMembers(
    $input: ApproveMembersInput!
  ) {
    approveMembers(input: $input)
  }
`;

export const useDeactivateMember = (
  options: MutationEnhancedOptions<DeactivateUserData, GQL.IDeactivateUserOnMutationArguments>,
) =>
  useMutationEnhanced<DeactivateUserData, GQL.IDeactivateUserOnMutationArguments>(
    DEACTIVATE_USER,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        'group',
        'groupsIndex',
        'inactiveUsersIndex',
        'itemsNeedingAttention',
        'studentsIndex',
        'directorsIndex',
        'parentsIndex',
      ],
    },
  );

export const useActivateUser = (
  options: MutationEnhancedOptions<ActivateUserData, GQL.IActivateUserOnMutationArguments> = {},
) =>
  useMutationEnhanced<ActivateUserData, GQL.IActivateUserOnMutationArguments>(
    ACTIVATE_USER,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        'inactiveUsersIndex',
        'studentsIndex',
        'directorsIndex',
        'parentsIndex',
      ],
    },
  );

export const useApprovePendingMembers = (
  options: MutationEnhancedOptions<
    ApprovePendingMembersData,
    GQL.IApproveMembersOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    ApprovePendingMembersData,
    GQL.IApproveMembersOnMutationArguments
  >(
    APPROVE_PENDING_MEMBERS,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        'allMembers',
        'allDirectors',
        'parentsIndex',
        'pendingMemberIndex',
        'peoplePickerIndex',
        'studentsIndex',
        'users',
      ],
    },
  );
