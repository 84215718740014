// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateInventoryItem } from 'gql/mutations';
import { useGetGettingStartedCounts } from 'gql/queries';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import InventoryForm, { InventoryPayloadValues } from '../shared/InventoryForm';

// Component Definition
const InventoryNew: FC = () => {
  const navigate = useNavigate();

  const canUseDynamicFields = useCanUseDynamicFields();

  const inventoryParams = useSelector(tableQueryParams('inventoryItems'));

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.INVENTORY}/${id}`);
  }, [navigate]);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneInventoryItem = (gettingStartedCountsData?.dashboardMetrics?.inventoryCount ?? 0) > 0;

  const [
    handleCreateInventoryItem,
    {
      error,
    },
  ] = useCreateInventoryItem(
    {
      clearCachePredicates: [
        'inventoryItemsIndex',
        ...!hasAtLeastOneInventoryItem ? ['dashboardMetrics'] : [],
      ],
      onCompleted: (data) => navigateToShow(data.createInventoryItem.id),
    },
  );

  const handleSubmit = async (values: InventoryPayloadValues) => {
    await handleCreateInventoryItem({
      variables: {
        input: {
          ...values,
          ...(canUseDynamicFields
            ? applyDynamicFields(values as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.INVENTORY}${inventoryParams}`,
      }}
      error={error}
    >
      <InventoryForm
        canUseDynamicFields={canUseDynamicFields}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default InventoryNew;
