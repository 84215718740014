// External Dependencies
import { FC, useMemo } from 'react';

// Internal Dependencies
import { CustomSelect } from 'components/shared/index';
import { useGetOrganization, useGetPossibleFeederOrganizations } from 'gql/queries';

// Local Typings
interface Props {
  helperText?: string;
  name?: string;
}

// Local Variables
const defaultHelperText = 'Select the Presto organization your students will attend when they leave your campus. If there are multiple, select the organization the majority of your students will be in.';

// Component Definition
const SuccessorOrganizationSelect: FC<Props> = ({
  helperText = defaultHelperText,
  name = 'successorOrganizationId',
}) => {
  const {
    data,
  } = useGetPossibleFeederOrganizations();

  const {
    data: organizationData,
  } = useGetOrganization();

  const currentSuccessorOrganizationId = organizationData?.organization.successorOrganization?.id;

  const options = useMemo(() => data?.possibleFeederOrganizations.map((datum) => ({
    id: datum.id,
    label: datum.label,
  })) ?? [], [
    currentSuccessorOrganizationId,
    data?.possibleFeederOrganizations,
  ]);

  return (
    <CustomSelect
      helperText={helperText}
      label="Successor Organization"
      name={name}
      options={options}
    />
  );
};

export default SuccessorOrganizationSelect;
