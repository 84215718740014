// External Dependencies
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DialogContentText from '@mui/material/DialogContentText';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { SupportLink } from 'components/shared';
import { formatDateTime } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useSendWelcomeEmail } from 'gql/mutations';

// Local Dependencies
import ConfirmationDialog from './ConfirmationDialog';

// Local Typings
interface SendWelcomeEmailToMemberProps {
  email: string;
  name: string;
  receivedWelcomeEmailAt: string | null;
  userOrgId: string;
  userOrgLoggedInAt: string | null;
}
interface SendWelcomeEmailToAllProps {
  email?: never;
  name?: never;
  receivedWelcomeEmailAt?: never;
  userOrgId?: never;
  userOrgLoggedInAt?: never;
}
type Props = SendWelcomeEmailToMemberProps | SendWelcomeEmailToAllProps;

// Local Variables
const StyledListItem = styled(ListItem)({
  '.disabledButtonTooltip': {
    fontSize: '0.8rem',
    maxWidth: 144,
  },
  '.secondaryListItemText': {
    maxWidth: '75%',
  },
});

// This separate styled component overrides the MUI default styling more easily
//  than adding it to the styled component above
const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
  '.Mui-disabled': {
    color: theme.palette.success.main,
  },
}));

const allMembersSupportArticleHref = 'send-welcome-email-to-all';
const oneMemberSupportArticleHref = 'send-welcome-email-to-one-member';

// Component Definition
const SendWelcomeEmailListItem: FC<Props> = ({
  email,
  name,
  receivedWelcomeEmailAt,
  userOrgId,
  userOrgLoggedInAt,
}) => {
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const [sentWelcomeEmail, setSentWelcomeEmail] = useState(false);
  const {
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const handleCompleted = () => {
    setSentWelcomeEmail(true);
    toggleIsOpen();
  };

  const [
    sendWelcomeEmail,
    {
      loading,
    },
  ] = useSendWelcomeEmail({
    onCompleted: handleCompleted,
  });

  const handleConfirmSendWelcomeEmail = () => {
    // Sending no arguments will tell the backend to send the
    //  welcome email to ALL users who have not recieved it yet
    if (!userOrgId) {
      sendWelcomeEmail();
    } else {
      sendWelcomeEmail({
        variables: {
          userOrgIds: [userOrgId],
        },
      });
    }
  };

  const isSendingToAllMembers = !name;
  const shouldShowSendButton = Boolean(isSendingToAllMembers || email);

  const sendWelcomeEmailListItem = useMemo(() => {
    const secondaryText = shouldShowSendButton ? (
      <>
        Invite {name || 'all users'} to set their own password and finish setting up their {APP_NAME} profile.
        {' '}
        <SupportLink
          slug={name ? oneMemberSupportArticleHref : allMembersSupportArticleHref}
        />

        {name && (
          <Box
            component="span" // avoid rendering a div inside a p
            display="block"
            marginTop={1.5}
          >
            Welcome email sent: <strong>{receivedWelcomeEmailAt ? formatDateTime(receivedWelcomeEmailAt) : 'Never'}</strong>
          </Box>
        )}

      </>
    ) : (
      'Please provide an email address to send a welcome email.'
    );

    return (
      <ListItemText
        primary="Send Welcome Email"
        secondary={secondaryText}
        secondaryTypographyProps={{ classes: { root: 'secondaryListItemText' } }}
      />
    );
  }, [name, receivedWelcomeEmailAt, shouldShowSendButton]);

  const secondaryAction = useMemo(() => {
    if (!shouldShowSendButton) {
      return null;
    }

    return (
      <StyledListItemSecondaryAction>
        {sentWelcomeEmail ? (
          <Button
            color="success"
            disabled
            endIcon={(
              <CheckCircleIcon
                className="checkCircleIcon"
                color="success"
              />
            )}
            size={isMobileOrTabletScreen ? 'small' : 'medium'}
          >
            Sent
          </Button>
        ) : (
          <Tooltip
            classes={{ tooltip: 'disabledButtonTooltip' }}
            title={!canEmailMembers ? 'Your permission level doesn\'t allow sending email.' : ''}
          >
            <div>
              <Button
                color="primary"
                disabled={!canEmailMembers}
                onClick={toggleIsOpen}
                size={isMobileOrTabletScreen ? 'small' : 'medium'}
                variant="outlined"
              >
                More info
              </Button>
            </div>
          </Tooltip>
        )}
      </StyledListItemSecondaryAction>
    );
  }, [
    canEmailMembers,
    isMobileOrTabletScreen,
    sentWelcomeEmail,
    shouldShowSendButton,
    toggleIsOpen,
  ]);

  return (
    <>
      {(!userOrgLoggedInAt || !receivedWelcomeEmailAt) && (
        <StyledListItem>
          {sendWelcomeEmailListItem}

          {secondaryAction}
        </StyledListItem>
      )}

      <ConfirmationDialog
        canBeUndone={false}
        confirmButtonAction={handleConfirmSendWelcomeEmail}
        confirmButtonText="Yes, Send Welcome Email"
        declineButtonAction={toggleIsOpen}
        description={(
          <>
            <DialogContentText paragraph>
              Send an email to {name || 'all members'} inviting them to
              finish setting up their {APP_NAME} profile. The link in the email
              will be good for 48 hours and can only be used one time.
            </DialogContentText>

            {!name ? (
              <DialogContentText paragraph>
                The welcome email will go out to any member who meets all of the following criteria:
                <ul>
                  <li>Has never logged in.</li>
                  <li>Hasn&apos;t received the welcome email in the last 24 hours.</li>
                </ul>
              </DialogContentText>
            ) : null}

            <DialogContentText>
              Do you want to {receivedWelcomeEmailAt ? 're-' : ''}send a welcome email to <strong>{name || 'all members who have not already received the email'}</strong>?
            </DialogContentText>
          </>
        )}
        handleClose={toggleIsOpen}
        isSubmitting={loading}
        maxWidth={name ? 'sm' : 'md'}
        open={isOpen}
        title="Send Welcome Email?"
        useCustomText
      />
    </>
  );
};

export default SendWelcomeEmailListItem;
