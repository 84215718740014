// External Dependencies
import startCase from 'lodash.startcase';

// Internal Dependencies
import { PATHS, TITLES } from 'utils/constants/routes';

// Local Typings
type GetPageTitleReturn = (titleKey: string) => string;

// Utils
const getPageTitle: GetPageTitleReturn = (titleKey: string) => {
  let pageTitle;

  const isDistrictAdmin = titleKey.includes('district_admin');

  const isEditTitleKey = titleKey.endsWith('/edit');
  const isNewTitleKey = titleKey.endsWith('/new');

  const titleKeysArray = isDistrictAdmin
    ? titleKey.split('/').splice(1)
    : titleKey.split('/');

  const hasMultipleRoutes = titleKeysArray.length > 1;

  let [context] = titleKeysArray;

  if (hasMultipleRoutes) {
    if (titleKeysArray.includes('finances')) {
      // We want the title to read like "Financial Items"
      //  so we keep the second array element for this area
      let financialResource = titleKeysArray[1];

      if (isNewTitleKey || isEditTitleKey) {
        // Remove trailing `s` when dealing with a single item
        financialResource = financialResource.slice(0, -1);
      }
      context = `financial ${financialResource}`;
      // This prevents a page title like "Add Uniforms"
      //  when we know it is always a single item
      // We should be on the lookout for any form routes we add
      //  where the singular noun ends with an "s", though
    } else if (context?.endsWith('s')) {
      context = context.slice(0, -1);
    }
  }

  if (titleKeysArray.includes('previous_year_members')) {
    pageTitle = TITLES[PATHS.PREVIOUS_YEAR_MEMBERS];
  } else if (TITLES[titleKey]) {
    pageTitle = TITLES[titleKey];
  } else if (isNewTitleKey) {
    pageTitle = `Add ${startCase(context)}`;
  } else if (isEditTitleKey) {
    pageTitle = `Edit ${startCase(context)}`;
  } else if (hasMultipleRoutes && context) {
    pageTitle = `${startCase(context)} Detail`;
  } else if (context) {
    pageTitle = startCase(context);
  } else {
    // The `/` route is our login page
    pageTitle = 'Login';
  }

  return pageTitle;
};

export default getPageTitle;
