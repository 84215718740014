// External Dependencies
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Variables
const StyledRoot = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
  },
  fontWeight: 500,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  textTransform: 'uppercase',
})) as typeof Typography;

// Component Definition
const EditModeText = (): JSX.Element => {
  return (
    <StyledRoot component="span">
      EDIT MODE
    </StyledRoot>
  );
};

export default EditModeText;
