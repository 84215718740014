// External Dependencies
import {
  OnboardingOrganizationInfoForm,
  OnboardingStepTitle,
} from 'components/shared';

// Component Definition
const OnboardingOrganizationInfo = () => (
  <>
    <OnboardingStepTitle title="Organization Info" />
    <OnboardingOrganizationInfoForm isOnboarding />
  </>
);

export default OnboardingOrganizationInfo;
