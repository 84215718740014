// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

const CREATE_FILE_PRESIGNED_URL_FOR_RESOURCE = gql`
  mutation CreateFilePresignedUrlForResource($input: CreatePresignedUrlForResourceInput!) {
    createFilePresignedUrlForResource(
      input: $input
    ) {
      getUrl
      putUrl
    }
  }
`;

export const useCreateFilePresignedUrlForResource = (
  options?: MutationOptions<
    { createFilePresignedUrlForResource: GQL.IPresignedUrl },
    GQL.ICreateFilePresignedUrlForResourceOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { createFilePresignedUrlForResource: GQL.IPresignedUrl },
    GQL.ICreateFilePresignedUrlForResourceOnMutationArguments
  >(CREATE_FILE_PRESIGNED_URL_FOR_RESOURCE, options);
