// External Dependencies
import { gql } from '@apollo/client';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
export interface FormResponseData {
  form: GQL.IForm;
}

interface FormsIndexResponse {
  formIndex: GQL.IFormIndexItemAll;
}

interface MyFormsIndexResponse {
  myFormsIndex: GQL.IMyFormsIndexItemAll;
}

interface MyFormResponseData {
  myForm: GQL.IMyForm;
}

const FRAGMENT_SIMPLE_FORM = gql`
  fragment SimpleFormFragment on Form {
    formBlocks {
      formBlockType {
        id
        label
        semanticLabel
      }
      id
      isFixed
      isRequired
      label
      metadata
      position
      sampleResponse
    }
    formTypeId
    id
    publishedAt
    publishedBy {
      email
      firstName
      id
      lastName
    }
    title
    schoolYearEnding
    shouldNotifyOnAssignment
    updatedAt
  }
`;

export const GET_FORM_AS_DIRECTOR = gql`
  query Form($id: ID!) {
    form(id: $id) {
      assignedMembers {
        email
        id
      }
      ...SimpleFormFragment
    }
  }
  ${FRAGMENT_SIMPLE_FORM}
`;

export const GET_FORM_AS_DISTRICT_ADMIN = gql`
  query Form($id: ID!) {
    form(id: $id) {
      assignTo
      organizationEntityTypeIds
      organizationTypeIds
      ...SimpleFormFragment
    }
  }
  ${FRAGMENT_SIMPLE_FORM}
`;

const GET_MY_FORM = gql`
  query MyForm($formAssignmentId: ID!) {
    myForm(formAssignmentId: $formAssignmentId) {
      assignmentId
      assignedTo {
        email
        firstName
        id
        lastName
      }
      formBlocks {
        id
        formBlockType {
          id
          label
        }
        isFixed
        isRequired
        label
        metadata
        position
        response
      }
      formTypeId
      id
      publishedAt
      publishedBy {
        email
        firstName
        id
        lastName
      }
      submittedAt
      submittedBy {
        email
        firstName
        id
        lastName
      }
      title
    }
  }
`;

const FORMS_INDEX = gql`
  query FormIndex(
    $queryParams: IndexQueryParams
  ) {
    formIndex(
      queryParams: $queryParams
    ) {
      data {
        districtId
        formTypeId
        id
        publishedAt
        title
        schoolYearEnding
        updatedAt
      }
      fullCount
    }
  }
`;

const MY_FORMS_INDEX = gql`
  query MyFormsIndex(
    $queryParams: IndexQueryParams
  ) {
    myFormsIndex(
      queryParams: $queryParams
    ) {
      data {
        assignedToMemberEmail
        assignedToMemberFirstName
        assignedToMemberId
        assignedToMemberLastName
        assignmentId
        formId
        publishedAt
        schoolYearEnding
        submittedAt
        submittedByMemberEmail
        submittedByMemberFirstName
        submittedByMemberId
        submittedByMemberLastName
        title
        updatedAt
      }
      fullCount
    }
  }
`;

export const useGetFormsIndex = () => usePaginatedListQuery<
  FormsIndexResponse,
  GQL.IFormIndexItem>({
    dataSelector: (res) => res.formIndex.data,
    fullCountSelector: (res) => res.formIndex.fullCount,
    query: FORMS_INDEX,
  });

export const useGetMyFormsIndex = () => usePaginatedListQuery<
  MyFormsIndexResponse,
  GQL.IMyFormsIndexItem>({
    dataSelector: (res) => res.myFormsIndex.data,
    fullCountSelector: (res) => res.myFormsIndex.fullCount,
    query: MY_FORMS_INDEX,
  });

export const useGetFormAsDirector = (
  id?: string,
  onCompleted?: (data: FormResponseData) => void,
) =>
  useQueryEnhanced<FormResponseData>(
    GET_FORM_AS_DIRECTOR,
    {
      onCompleted,
      skip: !id,
      variables: {
        id,
      },
    },
  );

export const useGetFormAsDistrictAdmin = (
  id?: string,
  onCompleted?: (data: FormResponseData) => void,
) =>
  useQueryEnhanced<FormResponseData>(
    GET_FORM_AS_DISTRICT_ADMIN,
    {
      onCompleted,
      skip: !id,
      variables: {
        id,
      },
    },
  );

export const useGetMyForm = (
  formAssignmentId?: string,
) =>
  useQueryEnhanced<MyFormResponseData>(
    GET_MY_FORM,
    {
      skip: !formAssignmentId,
      variables: {
        formAssignmentId,
      },
    },
  );
