// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// Internal Dependencies
import { Page } from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialOverview, useGetOrganization } from 'gql/queries';

// Local Dependencies
import AcademicYTDTotalsCard from './AcademicYTDTotalsCard';
import AvailableMemberCreditCard from './AvailableMemberCreditCard';
import FinancialAccountsCard from './FinancialAccountsCard';
import ItemWithHighestBalanceCard from './ItemsWithHighestBalanceCard';
import PaymentsReceivedByDateCard from './PaymentsReceivedByDateCard';
import StatementCard from './StatementCard';
import TotalOutstandingFeesCard from './TotalOutstandingFeesCard';
import UsersWithHighestBalanceCard from './UsersWithHighestBalanceCard';

// Component Definition
const FinancialOverview: FC = () => {
  const theme = useTheme();

  const canReadFinancialAccounts = useSelector(hasPermission('financialAccounts', 'read'));

  const { data: orgData, loading: isOrgLoading } = useGetOrganization();

  const schoolYearEnding = orgData?.organization.currentSchoolYearEnding;

  const { data, loading: isOverviewDataLoading } = useGetFinancialOverview(schoolYearEnding ?? 0);

  const financialCreditOverview = data?.financialCreditOverview;
  const financialFeesOverview = data?.financialFeesOverview;

  const isLoading = isOrgLoading || isOverviewDataLoading;

  // eslint-disable-next-line max-len
  const outstandingFeesFromCurrentYearInCents = financialFeesOverview?.totalOutstandingBalanceInCents ?? 0;
  // eslint-disable-next-line max-len
  const outstandingFeesFromPriorYearsInCents = financialFeesOverview?.previousYearsTotalFeesInCents ?? 0;
  const feesAssignedInCents = financialFeesOverview?.totalFeesInCents ?? 0;

  const itemsWithHighestBalance = financialFeesOverview?.itemsWithHighestBalance;
  const usersWithHighestBalance = financialFeesOverview?.usersWithHighestBalance;

  const creditFromOverpaymentInCents = financialCreditOverview?.totalCreditFromOverpayment ?? 0;
  const creditFromManualEntryInCents = financialCreditOverview?.totalCreditFromManualEntry ?? 0;

  return (
    <Page isLoading={isLoading}>
      <Box
        display="grid"
        gridAutoFlow="row"
        gap={theme.spacing(2)}
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      >
        <Box
          display="grid"
          gridColumn="1 / -2"
          gridAutoRows="minmax(min-content, max-content)"
          gap={theme.spacing(2)}
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        >
          <TotalOutstandingFeesCard
            outstandingFeesFromPriorYearsInCents={outstandingFeesFromPriorYearsInCents}
            outstandingFeesFromCurrentYearInCents={outstandingFeesFromCurrentYearInCents}
          />

          <AcademicYTDTotalsCard
            feesAssignedInCents={feesAssignedInCents}
            outstandingFeesInCents={outstandingFeesFromCurrentYearInCents}
          />

          <AvailableMemberCreditCard
            creditFromManualEntryInCents={creditFromManualEntryInCents}
            creditFromOverpaymentInCents={creditFromOverpaymentInCents}
          />

          <StatementCard />

          <Box gridColumn="1 / -1">
            <PaymentsReceivedByDateCard />
          </Box>

          <Box gridColumn="1 / -1">
            {canReadFinancialAccounts && <FinancialAccountsCard />}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(2)}
        >
          <ItemWithHighestBalanceCard
            itemsWithHighestBalance={itemsWithHighestBalance}
          />

          <UsersWithHighestBalanceCard
            usersWithHighestBalance={usersWithHighestBalance}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default FinancialOverview;
