// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import DirectorsTable from './DirectorsTable';

// Component Definition
const Directors = () => (
  <>
    <Subtitle>
      Directors
    </Subtitle>

    <EnhancedCard>
      <DirectorsTable />
    </EnhancedCard>
  </>
);

export default Directors;
