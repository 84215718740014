// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';

// Local Typings
interface Props {
  feesAssignedInCents: number;
  outstandingFeesInCents: number;
}

// Component Definition
const AcademicYTDTotalsCard = ({
  feesAssignedInCents,
  outstandingFeesInCents,
}: Props): JSX.Element => {
  const feesPaidInCents = feesAssignedInCents - outstandingFeesInCents;

  return (
    <DashboardCard
      content={(
        <>
          <ShowPageDataDisplay
            label="Fees Assigned"
            type="currency"
            value={feesAssignedInCents}
          />

          <ShowPageDataDisplay
            label="Fees Paid"
            type="currency"
            value={feesPaidInCents}
          />

          <PercentageProgressBar
            denominator={feesAssignedInCents}
            displayCaptionAsCurrency
            label="Total Finances Paid"
            numerator={feesPaidInCents}
          />
        </>
    )}
      title="Current Year Fees"
    />
  );
};
export default AcademicYTDTotalsCard;
