// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Typography } from '@mui/material';

// Internal Dependencies
import { CONTACT_INFO, SHORT_APP_NAME } from 'utils/constants';
import { Page, StyledLink, SupportLink } from 'components/shared/index';
import { getHealthStatus } from 'utils/api';
import Maintenance from 'pages/Maintenance';

// Local Variables
const SUPPORT_EMAIL_HREF = `
  ${CONTACT_INFO.MAILTO_SUPPORT}?subject=Site%20Not%20Loading%20in%20Presto%20Assistant
`;

// Component Definition
const HealthStatusProvider: FC = ({
  children,
}) => {
  const [isHealthy, setIsHealthy] = useState(false);
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const getHealthCheckResponse = useCallback(async () => {
    try {
      const response = await getHealthStatus();

      setReceivedResponse(Boolean(response));

      setIsHealthy(response?.status === 200);
    } finally {
      setIsChecking(false);
    }
  }, []);

  useEffect(() => {
    getHealthCheckResponse();
  }, [getHealthCheckResponse]);

  if (isChecking) {
    return (
      <Page isLoading>
        {/* this will never be shown, but Page needs children */}
        <h1>Loading...</h1>
      </Page>
    );
  }

  if (!receivedResponse) {
    return (
      <Maintenance
        message={(
          <>
            If you are on a school network, your district might be blocking
            some traffic to {SHORT_APP_NAME} servers. If you are an administrator or
            director, please contact your district&apos;s IT department
            and provide
            {' '}
            <SupportLink
              buttonText="this support article"
              slug="district-it-checklist"
            />.
          </>
          )}
        title="Network Error"
      />
    );
  }

  if (!isHealthy) {
    return (
      <Maintenance
        message={(
          <>
            {SHORT_APP_NAME} is experiencing some technical issues. Please contact us at
            {' '}
            <Typography
              component="span"
              whiteSpace="pre"
            >
              <StyledLink href={SUPPORT_EMAIL_HREF}>
                {CONTACT_INFO.SUPPORT}
              </StyledLink>
            </Typography>.
          </>
        )}
      />
    );
  }

  return children as JSX.Element;
};

export default HealthStatusProvider;
