// External Dependencies
import { DialogProps } from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {
  useEffect, useRef, useState,
} from 'react';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { UseTextFieldResult } from 'hooks/useTextField';
import usePrevious from 'hooks/usePrevious';

// Local Dependencies
import EnhancedAlert from './EnhancedAlert';
import EnhancedDialog from './EnhancedDialog';
import SaveButton from './SaveButton';
import SlideUpTransition from './SlideUpTransition';

// Local Typings
interface Props {
  buttonSize?: ButtonProps['size'];
  canBeUndone?: boolean;
  confirmButtonAction: () => void;
  confirmButtonText?: string;
  declineButtonAction?: () => void;
  declineButtonText?: string;
  deletedNoteInputProps?: UseTextFieldResult;
  description: React.ReactNode;
  disableEscapeKeyDown?: DialogProps['disableEscapeKeyDown'];
  errorText?: string;
  fullScreen?: DialogProps['fullScreen'];
  handleClose: () => void;
  hideDeclineButton?: boolean;
  isConfirmButtonDisabled?: boolean;
  isDeclineButtonDisabled?: boolean;
  isSubmitting?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onExited?: () => void;
  open: boolean;
  title: string;
  useCustomText?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
}

// Component Definition
const ConfirmationDialog = ({
  buttonSize = 'medium',
  canBeUndone = true,
  confirmButtonAction,
  confirmButtonText = 'OK',
  declineButtonAction,
  declineButtonText = 'Cancel',
  deletedNoteInputProps,
  description,
  disableEscapeKeyDown,
  errorText = 'Please provide a reason to remove/delete this data.',
  fullScreen = false,
  handleClose,
  hideDeclineButton = false,
  isConfirmButtonDisabled,
  isDeclineButtonDisabled,
  isSubmitting,
  maxWidth = 'sm',
  onExited,
  open,
  title,
  useCustomText = false,
  variant = 'contained',
}: Props): JSX.Element => {
  const deletedNoteRef = useRef<HTMLTextAreaElement>(null);

  const [showDeletedNoteError, setShowDeletedNoteError] = useState(false);
  const previousShowDeletedNoteError = usePrevious(showDeletedNoteError);

  const handlePressConfirmButton = () => {
    if (deletedNoteInputProps && !deletedNoteRef?.current?.value) {
      setShowDeletedNoteError(true);
    } else {
      confirmButtonAction();
    }
  };

  useEffect(() => {
    if (showDeletedNoteError && deletedNoteRef?.current?.value) {
      setShowDeletedNoteError(false);
    }
    // The eslint rule is asking for us to remove the deletedNoteRef.current
    // TODO: Research if the dependency is needed for this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deletedNoteRef.current,
    previousShowDeletedNoteError,
    showDeletedNoteError,
  ]);

  const handleCloseDialog = (
    _event: object,
    reason: string,
  ) => {
    // We don't allow the user to click away from the success dialog
    // They will always be sent back to the correct "next" page
    if (reason === 'backdropClick'
      || (disableEscapeKeyDown && reason === 'escapeKeyDown')) {
      return null;
    }

    return handleClose();
  };

  return (
    <EnhancedDialog
      TransitionComponent={fullScreen ? SlideUpTransition : undefined}
      TransitionProps={{ onExited }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      onClose={!isSubmitting ? handleCloseDialog : undefined}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        {useCustomText ? (
          description
        ) : (
          <>
            <DialogContentText
              gutterBottom={!canBeUndone}
              id="alert-dialog-description"
            >
              {description}
            </DialogContentText>

            {!canBeUndone && (
              <DialogContentText>
                This action cannot be undone.
              </DialogContentText>
            )}
          </>
        )}

        {!!deletedNoteInputProps && (
          <TextField
            {...deletedNoteInputProps}
            fullWidth
            inputRef={deletedNoteRef}
            label="Reason"
            multiline
            required
            rows={4}
            sx={{ marginTop: 2 }}
            variant="outlined"
          />
        )}

        <Collapse
          in={showDeletedNoteError}
          mountOnEnter
        >
          <Box mt={2}>
            <EnhancedAlert severity="error">
              {errorText}
            </EnhancedAlert>
          </Box>
        </Collapse>
      </DialogContent>

      <DialogActions>
        {!hideDeclineButton && !isSubmitting && (
          <Button
            color="primary"
            disabled={isDeclineButtonDisabled}
            onClick={declineButtonAction}
            size={buttonSize}
          >
            {declineButtonText}
          </Button>
        )}
        <SaveButton
          autoFocus
          color="primary"
          disabled={isConfirmButtonDisabled}
          isSaving={isSubmitting}
          onClick={handlePressConfirmButton}
          size={buttonSize}
          variant={variant}
        >
          {confirmButtonText}
        </SaveButton>
      </DialogActions>
    </EnhancedDialog>
  );
};

export default ConfirmationDialog;
