// External Dependencies
import Box from '@mui/material/Box';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Component Definition
const BottomContainer = ({ children }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      p={2}
    >
      {children}
    </Box>
  );
};

export default BottomContainer;
