import { ConfirmationDialog } from 'components/shared';
import { useCallback } from 'react';
import { useUnpublishForm } from 'utils/api/forms';

interface Props {
  formId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmUnpublishFormDialog = ({
  formId,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const {
    isLoading: isUnpublishingForm,
    mutate: unpublishForm,
  } = useUnpublishForm();

  const handleUnpublishForm = useCallback(() => {
    if (formId) {
      unpublishForm(formId, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  }, [formId, unpublishForm, onClose]);

  return (
    <ConfirmationDialog
      canBeUndone={false}
      confirmButtonAction={handleUnpublishForm}
      declineButtonAction={onClose}
      description="Are you sure you want to unpublish this form? Form will be unassigned and all responses will be deleted."
      handleClose={onClose}
      isSubmitting={isUnpublishingForm}
      open={isOpen}
      title="Unpublish Form?"
    />
  );
};

export default ConfirmUnpublishFormDialog;
