// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import FormsTable from './FormsTable';
import LinkToMyForms from './LinkToMyForms';

// Component Definition
const FormsAll = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Forms
      </Subtitle>

      <EnhancedCard>
        <FormsTable />
      </EnhancedCard>

      <LinkToMyForms />
    </>
  );
};

export default FormsAll;
