// External Dependencies
import { GlobalEditField } from '@presto-assistant/api_types/api/v1/globalEdit';
import { useCallback, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { Flex } from 'components/shared';
import Select, { SelectProps } from 'components/shared/Select';

// Local Typings
interface Props {
  field: GlobalEditField;
  onChangeValue: (value: string) => void;
  value: string;
}

// Component Definition
const GlobalEditInput = ({
  field,
  onChangeValue,
  value,
}: Props): JSX.Element | null => {
  const [hasMadeSelection, setHasMadeSelection] = useState(false);

  const handleTextFieldChange: TextFieldProps['onChange'] = useCallback((evt) => {
    onChangeValue(evt.target.value);
  }, [onChangeValue]);

  const handleSelectChange: SelectProps['onChange'] = useCallback((evt) => {
    if (!hasMadeSelection) {
      setHasMadeSelection(true);
    }

    onChangeValue(evt.target.value as string);
  }, [hasMadeSelection, onChangeValue]);

  switch (field.type) {
    case 'text':
      return (
        <TextField
          fullWidth
          id={field.id}
          label={field.label}
          name={field.label}
          onChange={handleTextFieldChange}
          placeholder={field.label}
          value={value}
        />
      );
    case 'select':
      return (
        <Flex>
          <Select
            id={field.id}
            label={field.label}
            name={field.label}
            onChange={handleSelectChange}
            options={field.options}
            placeholder={field.label}
            value={value}
          />

          {!value && !hasMadeSelection && (
            <FormHelperText sx={{ marginLeft: 1 }}>
              <Typography variant="body2">
                Choose an option
              </Typography>
            </FormHelperText>
          )}
        </Flex>
      );
    default:
      return null;
  }
};

export default GlobalEditInput;
