// External Dependencies
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

// Local Typings
interface Props {
  percent: number;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.bar': {
    height: 8,
  },
  '.barOne': {
    backgroundColor: theme.palette.secondary.dark,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
  },
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
}));

// Component Definition
const TopFixedLinearProgress = ({
  percent,
}: Props) => (
  <StyledRoot>
    <LinearProgress
      classes={{
        bar1Determinate: 'barOne',
        root: 'bar',
      }}
      color="secondary"
      value={percent}
      variant="determinate"
    />
  </StyledRoot>
);

export default TopFixedLinearProgress;
