// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { useGetMyFormsIndex } from 'gql/queries/form-queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import MyFormsZeroState from './MyFormsZeroState';

// Local Variables
const handleClickRowTo = (formId: string) => `/${PATHS.MY_FORMS}/${formId}`;
const handleGetRowId = (row: GQL.IMyFormsIndexItem) => row.assignmentId;

// Component Definition
const MyFormsTable = (): JSX.Element => {
  const {
    data,
    isLoading,
  } = useGetMyFormsIndex();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleClickRowTo}
        columns={columns}
        components={{
          NoRowsOverlay: MyFormsZeroState,
        }}
        getRowId={handleGetRowId}
        loading={isLoading}
        rows={data}
        tableResource="myForms"
        withSearch
      />
    </DataGridContainer>
  );
};

export default MyFormsTable;
