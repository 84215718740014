// External Dependencies
import { FC, ReactElement, createElement } from 'react';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';

// Local Typings
interface Props extends IconButtonProps {
  buttonClasses?: any;
  disabled?: boolean;
  icon: ReactElement
  onClick: () => void;
  tooltip: string;
  tooltipPlacement?: TooltipProps['placement'];
  useSpan?: boolean;
}

// Component Definition
const EnhancedIconButton: FC<Props> = ({
  buttonClasses = null,
  disabled = false,
  icon,
  onClick,
  size = 'large',
  tooltip,
  tooltipPlacement = 'bottom',
  useSpan = false,
  ...props
}) => {
  const iconButton = (
    <IconButton
      aria-label={tooltip}
      classes={buttonClasses}
      disabled={disabled}
      onClick={onClick}
      {...props}
      size={size}
    >
      {icon}
    </IconButton>
  );

  return (
    <Tooltip
      key={`icon-button-${tooltip}`}
      placement={tooltipPlacement}
      title={tooltip}
    >
      {createElement(useSpan ? 'span' : 'div', null, iconButton)}
    </Tooltip>
  );
};

export default EnhancedIconButton;
