// External Dependencies
import { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { SUPPORT_SITE_HREF } from 'utils/constants';
import StyledLink from 'components/shared/StyledLink';

// Local Typings
interface Props {
  onClose: () => void;
  openPermissionDialog: boolean;
}

// Component Definition
const EditPermissionsDialog: FC<Props> = ({
  onClose,
  openPermissionDialog,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={openPermissionDialog}
    >
      <DialogTitle>
        You do not have permission to edit
      </DialogTitle>

      <DialogContent>
        <DialogContentText paragraph>
          Your administrator must grant you permission
          to edit the permissions of others.
        </DialogContentText>

        <StyledLink
          href={`${SUPPORT_SITE_HREF}/videos/members-walkthrough/#manage-permissions`}
          openInNewTab
        >
          Learn more about managing permissions.
        </StyledLink>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPermissionsDialog;
