import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

const GET_SPOKEN_LANGUAGES = gql`
  query SpokenLanguages {
    spokenLanguages {
      id
      label
    }
  }
`;

export const useGetSpokenLanguages = () =>
  useQueryEnhanced<{
    spokenLanguages: GQL.ISpokenLanguage[]
  }>(GET_SPOKEN_LANGUAGES);
