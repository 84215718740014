// External Dependencies
import { FC } from 'react';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useCompleteEndOfYearProcess } from 'gql/mutations';

// Local Typings
interface Props {
  fullNextSchoolYear: string;
  isOpen: boolean;
  toggleIsOpen: () => void;
}

// Local Variables
const handleNavigateToCompletedRoute = () => {
  window.location.href = `/${PATHS.END_OF_YEAR_COMPLETED}`;
};

// Component Definition
const DialogConfirmStartNextSchoolYear: FC<Props> = ({
  fullNextSchoolYear,
  isOpen,
  toggleIsOpen,
}) => {
  const [
    completeEndOfYearProcess,
    {
      data: successData,
      loading: isCompletingEndOfYearProcess,
    },
  ] = useCompleteEndOfYearProcess({
    awaitRefetchQueries: true,
    onCompleted: () => {
      handleNavigateToCompletedRoute();
    },
  });

  return (
    <ConfirmationDialog
      canBeUndone={false}
      confirmButtonAction={completeEndOfYearProcess}
      confirmButtonText="Let's Do This"
      declineButtonAction={toggleIsOpen}
      declineButtonText="Go Back"
      description={(
        <section id="alert-dialog-description">
          <DialogContentText>
            This will transition your organization&apos;s
            members and groups data to the{' '}
            <strong>{fullNextSchoolYear}</strong>{' '}
            school year.
          </DialogContentText>

          <DialogContentText>
            After you complete this step, you cannot edit members and
            groups data from the previous school year.
          </DialogContentText>

          <DialogContentText>
            Any outstanding items and fees can be updated at any time,
            regardless of the current school year.
          </DialogContentText>
        </section>
      )}
      handleClose={toggleIsOpen}
      isSubmitting={isCompletingEndOfYearProcess || Boolean(successData)}
      open={isOpen}
      title="Start Next School Year?"
      useCustomText
    />
  );
};

export default DialogConfirmStartNextSchoolYear;
