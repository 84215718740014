// Internal Dependencies
import { yup } from 'utils/yup';

export const checklistInfoSchema = yup.object().shape({
  emailRemindersEnabled: yup
    .bool(),
  // .required('Required'),
  label: yup
    .string()
    .max(255)
    .required('Name is Required'),
  schoolYearEnding: yup
    .string()
    .required('Required'),
});

export const checklistMembersSchema = yup.object().shape({
  checklistId: yup
    .string()
    .required(),
  memberIds: yup
    .array()
    .of(yup.string()),
});

export const checklistItemSchema = yup.object().shape({
  dueDate: yup
    .date()
    .nullable(),
  isRequired: yup
    .bool()
    .required(),
  label: yup
    .string()
    .label('Checklist Item Label')
    .max(255)
    .required('Checklist Item Label is required'),
});
