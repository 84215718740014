/* eslint-disable graphql/template-strings */
// External Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';

// Internal Dependencies
import { hasPermission } from 'state/self/selectors';

// Local Dependencies
import {
  FRAGMENT_SIMPLE_INVENTORY_ITEM,
  SimpleInventoryItem,
} from './inventory-item-queries';
import { USER_FRAGMENT } from './user-queries';

// Local Typings
export interface InventoryCheckoutResponse {
  active: boolean;
  checkinDate: string | null;
  checkoutDate: string;
  dueDate: string;
  id: string;
  item: SimpleInventoryItem;
  user: GQL.IUser;
}

export interface InventoryCheckoutsByIdResponse {
  id: string;
  inventoryCheckouts: GQL.IInventoryCheckout[];
}

// Fragment Definitions
export const FRAGMENT_INVENTORY_CHECKOUT = gql`
  fragment InventoryCheckoutFragment on InventoryCheckout {
    active
    checkinDate
    checkoutDate
    dueDate
    id
    item {
      ...SimpleInventoryItemFragment
      combination
      locker
      lockNumber
      customBarcode
      systemBarcode
    }
    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
  ${FRAGMENT_SIMPLE_INVENTORY_ITEM}
`;

// Query Definitions
const GET_INVENTORY_ITEM_CHECKOUT = gql`
  query InventoryCheckout($id: ID!) {
    inventoryCheckout (id: $id) {
      ...InventoryCheckoutFragment
    }
  }
  ${FRAGMENT_SIMPLE_INVENTORY_ITEM}
  ${FRAGMENT_INVENTORY_CHECKOUT}
`;

const GET_INVENTORY_CHECKOUTS_BY_MEMBER_ID = gql`
  query InventoryCheckoutsByMemberId($memberId: ID!) {
    inventoryCheckoutsByMemberId(memberId: $memberId) {
      id
      inventoryCheckouts {
        ...InventoryCheckoutFragment
      }
    }
  }
  ${FRAGMENT_INVENTORY_CHECKOUT}
`;

const GET_MY_INVENTORY_ITEM_CHECKOUTS = gql`
  query MyInventoryCheckouts {
    myInventoryCheckouts {
      id
      inventoryCheckouts {
        ...InventoryCheckoutFragment
      }
    }
  }
  ${FRAGMENT_SIMPLE_INVENTORY_ITEM}
  ${FRAGMENT_INVENTORY_CHECKOUT}
`;

export const useGetInventoryCheckout = (id: string) => useQueryEnhanced<
  {
    inventoryCheckout: InventoryCheckoutResponse;
  },
  GQL.IInventoryCheckoutOnQueryArguments
>(
  GET_INVENTORY_ITEM_CHECKOUT,
  {
    variables: { id },
  },
);

export const useGetInventoryCheckoutsByMemberId = (
  memberId: string,
) => {
  const canReadInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'read'));

  return useQueryEnhanced<
  {
    inventoryCheckoutsByMemberId: InventoryCheckoutsByIdResponse
  },
  GQL.IInventoryCheckoutsByMemberIdOnQueryArguments
>(
  GET_INVENTORY_CHECKOUTS_BY_MEMBER_ID,
  {
    skip: !memberId || !canReadInventoryCheckouts,
    variables: { memberId },
  },
);
};

export const useGetMyInventoryCheckouts = () =>
  useQueryEnhanced<{
    myInventoryCheckouts: InventoryCheckoutsByIdResponse;
  }>(GET_MY_INVENTORY_ITEM_CHECKOUTS);
