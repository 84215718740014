// External Dependencies
import { useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Avatar from '@mui/material/Avatar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { ListItemWithSecondaryAction } from 'components/shared';

// Local Typings
interface Props {
  actionDestination?: string;
  isCompleted: boolean | undefined;
  primaryText: string;
  secondaryText: React.ReactNode;
}

// // Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.showPage.groups,
}));

// Component Definition
const GettingStartedListItem = ({
  actionDestination,
  isCompleted,
  primaryText,
  secondaryText,
}: Props): JSX.Element => {
  const theme = useTheme();
  const Icon = isCompleted ? CheckBoxIcon : CheckBoxOutlineBlankIcon;

  const avatarElement = useMemo(() => (
    <ListItemAvatar>
      <StyledAvatar variant="rounded">
        <Icon htmlColor={theme.palette.prestoPrimaryLight} />
      </StyledAvatar>
    </ListItemAvatar>
  ), [Icon, theme.palette.prestoPrimaryLight]);

  const secondaryAction = useMemo(() => ({
    buttonIcon: <ArrowForwardIcon />,
    buttonText: 'View',
    to: actionDestination,
  }), [actionDestination]);

  return (
    <ListItemWithSecondaryAction
      avatarElement={avatarElement}
      primaryText={primaryText}
      secondaryAction={secondaryAction}
      secondaryText={secondaryText}
      secondaryTypographyProps={{
        component: 'div',
      }}
    />
  );
};

export default GettingStartedListItem;
