// Internal Dependencies
import { CustomInput } from 'components/shared';

// Component Definition
const AdminInventoryAuditFormFields = (): JSX.Element => {
  return (
    <>
      <CustomInput
        label="Name"
        name="label"
      />

      <CustomInput
        fullWidth
        label="Due Date"
        name="dueDate"
        type="date"
      />
    </>
  );
};

export default AdminInventoryAuditFormFields;
