// External Dependencies
import {
  CircularProgress,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { isMobileScreenSize } from 'state/device/selectors';

// Local Variables
const StyledLoadingWrapper = styled.div(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.loadingBackground,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  left: 0,
  paddingTop: 140,
  position: 'fixed',
  right: 0,
  top: 0,
}));

// Component Definition
const Loader: FC = () => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  return (
    <StyledLoadingWrapper>
      <Typography
        sx={{ mb: 6 }}
        variant={isMobileScreen ? 'h5' : 'h4'}
      >
        Welcome to Presto!
      </Typography>

      <CircularProgress size={48} />
    </StyledLoadingWrapper>
  );
};

export default Loader;
