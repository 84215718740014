// External Dependencies
import { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { StyledLink } from 'components/shared';

// Local Typings
interface Props {
  label: ReactNode;
  linkText?: string;
  to: string;
}

// Component Definition
const LabelWithLink: FC<Props> = ({
  label,
  linkText = 'View',
  to,
}) => {
  const navigate = useNavigate();

  const handleClickLink = useCallback(() => {
    navigate(to);
  }, [to, navigate]);

  return (
    <Typography component="span">
      {label}

      <Box
        component="span"
        marginLeft={1.5}
      >
        <StyledLink onClick={handleClickLink}>
          {linkText}
        </StyledLink>
      </Box>
    </Typography>
  );
};

export default LabelWithLink;
