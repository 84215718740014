// Local Dependencies
import DoWeSellIt from './DoWeSellIt';
import DoWeShareIt from './DoWeShareIt';
import HowWeProtectIt from './HowWeProtectIt';
import HowWeUseIt from './HowWeUseIt';
import WhatDoWeCollect from './WhatDoWeCollect';
import WhoDoWeCollectFrom from './WhoDoWeCollectFrom';

// Component Definition
const DataInfo = () => (
  <>
    <WhatDoWeCollect />

    <WhoDoWeCollectFrom />

    <HowWeUseIt />

    <DoWeShareIt />

    <DoWeSellIt />

    <HowWeProtectIt />
  </>
);

export default DataInfo;
