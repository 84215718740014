// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface AcceptDistrictRequestResponse {
  acceptDistrictRequest: boolean;
}

export const CREATE_DISTRICT_REQUEST = gql`
  mutation CreateDistrictRequest(
    $organizationId: ID
    $districtAdminOrgId: ID
  ) {
    createDistrictRequest(
      organizationId: $organizationId
      districtAdminOrgId: $districtAdminOrgId
    ) {
      id
    }
  }
`;

const ACCEPT_DISTRICT_REQUEST = gql`
  mutation AcceptDistrictRequest(
    $input: AcceptDistrictRequestInput!
  ) {
    acceptDistrictRequest(input: $input)
  }
`;

export const useAcceptDistrictRequest = (
  options?: MutationOptions<
    AcceptDistrictRequestResponse,
    GQL.IAcceptDistrictRequestOnMutationArguments
  >,
) => useMutationEnhanced<
  AcceptDistrictRequestResponse,
  GQL.IAcceptDistrictRequestOnMutationArguments
>(
  ACCEPT_DISTRICT_REQUEST,
  options,
);
