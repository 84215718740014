// External Dependencies
import { Form } from 'formik';
import { FormTypes } from '@presto-assistant/api_types';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Masonry from '@mui/lab/Masonry';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  EnhancedCard,
  Flex,
  SaveButton,
} from 'components/shared';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Dependencies
import AssignToMemberCheckboxes from '../shared/AssignToMemberCheckboxes';
import CampusTypeCheckboxes from '../shared/CampusTypeCheckboxes';
import OrganizationTypeCheckboxes from '../shared/OrganizationTypeCheckboxes';

// Local Typings
interface Props {
  formTypeId: number;
  isLoading: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

// Local Variables
const StyledMasonry = styled(Masonry)({
  '&.MuiMasonry-root': {
    // These adjustments allow the masonry children to take
    //  even space inside the parent container.
    margin: 0,
  },
});

// Component Definition
const AdminFormsNewUI = ({
  formTypeId,
  isLoading,
  onSubmit,
}: Props): JSX.Element => {
  return (
    <Form onSubmit={onSubmit}>
      <EnhancedCard>
        <CardContent>
          <CustomInput
            label="Title"
            name="title"
          />

          <SchoolYearSelect
            hidePreviousYears
            name="schoolYearEnding"
          />

          {formTypeId === FormTypes.Standard && (
            <>
              <Box
                marginBottom={2}
                marginTop={3}
              >
                <Typography variant="h6">
                  Assign To
                </Typography>
              </Box>

              <Box width="100%">
                <StyledMasonry
                  columns={{
                    /* eslint-disable sort-keys */
                    xs: 1,
                    sm: 1,
                    md: 1,
                    mdLg: 2,
                    lg: 3,
                    /* eslint-enable sort-keys */
                  }}
                  spacing={{
                    /* eslint-disable sort-keys */
                    xs: 2,
                    sm: 2,
                    md: 2,
                    mdLg: 2,
                    lg: 2,
                    /* eslint-enable sort-keys */
                  }}
                >
                  <CampusTypeCheckboxes />

                  <OrganizationTypeCheckboxes />

                  <AssignToMemberCheckboxes />
                </StyledMasonry>
              </Box>
            </>
          )}
        </CardContent>

        <CardActions>
          <Flex
            justifyContent="flex-end"
            width="100%"
          >
            <SaveButton isSaving={isLoading}>
              Next
            </SaveButton>
          </Flex>
        </CardActions>
      </EnhancedCard>
    </Form>
  );
};

export default AdminFormsNewUI;
