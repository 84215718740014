// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateOrganizationData {
  data: GQL.IOrganization;
}

interface ClaimMyOrganizationData {
  data: boolean;
}

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
  ) {
    createOrganization(input: $input) {
      addressOne
      addressTwo
      city
      code
      entityType {
        id
        label
      }
      label
      logoUrl
      organizationType {
        id
        label
      }
      phoneNumber
      stateId
      state {
        abbreviation
        id
        label
      }
      zipcode
    }
  }
`;

const CREATE_ORGANIZATION_LOGO = gql`
  mutation CreateOrganizationLogo(
    $input: CreateOrganizationLogoInput!
  ) {
    createOrganizationLogo(input: $input) {
      id
      logoUrl
    }
  }
`;

const DELETE_ORGANIZATION_LOGO = gql`
  mutation DeleteOrganizationLogo {
    deleteOrganizationLogo {
      id
      logoUrl
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $addressOne: String!
    $addressTwo: String
    $ccDirectors: Boolean
    $city: String!
    $code: String!
    $isCoveringStripeFeeByDefault: Boolean
    $isIgnoringPreviousYearsFinances: Boolean
    $label: String!
    $phoneNumber: String!
    $stateId: ID!
    $successorOrganizationId: String
    $zipcode: String!
  ) {
    updateOrganization(
      addressOne: $addressOne
      addressTwo: $addressTwo
      ccDirectors: $ccDirectors
      city: $city
      code: $code
      isCoveringStripeFeeByDefault: $isCoveringStripeFeeByDefault
      isIgnoringPreviousYearsFinances: $isIgnoringPreviousYearsFinances
      label: $label
      phoneNumber: $phoneNumber
      stateId: $stateId
      successorOrganizationId: $successorOrganizationId
      zipcode: $zipcode
    ) {
      addressOne
      addressTwo
      ccDirectors
      city
      code
      entityType {
        id
        label
      }
      graduatingGrade
      label
      logoUrl
      organizationType {
        id
        label
      }
      phoneNumber
      stateId
      state {
        abbreviation
        id
        label
      }
      successorOrganization {
        id
        label
      }
      zipcode
    }
  }
`;

export const UPDATE_GRADUATING_GRADE = gql`
  mutation UpdateGraduatingGrade(
    $graduatingGrade: Int!
  ) {
    updateGraduatingGrade(
      graduatingGrade: $graduatingGrade
    ) {
      graduatingGrade
      id
      label
    }
  }
`;

export const UPDATE_GOOGLE_CALENDAR_TOKEN = gql`
  mutation UpdateGoogleCalendarToken(
    $code: String!
  ) {
    updateGoogleCalendarToken(
      code: $code
    ) {
      id
    }
  }
`;

const UPDATE_STRIPE_ID = gql`
  mutation UpdateStripeId(
    $input: UpdateStripeIdInput!
  ) {
    updateStripeId(
      input: $input
    ) {
      id
    }
  }
`;

const CLAIM_MY_ORGANIZATION = gql`
  mutation ClaimMyOrganization($organizationId: ID!) {
    claimMyOrganization(organizationId: $organizationId)
  }
`;

export const useCreateOrganization = (
  options?: MutationOptions<
    CreateOrganizationData,
    GQL.ICreateOrganizationOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateOrganizationData,
    GQL.ICreateOrganizationOnMutationArguments
  >(
    CREATE_ORGANIZATION,
    options,
  );

export const useCreateOrganizationLogo = (
  options?: MutationOptions<
    { createOrganizationLogo: GQL.IOrganization },
    GQL.ICreateOrganizationLogoOnMutationArguments
    >,
) =>
  useMutationEnhanced<
    { createOrganizationLogo: GQL.IOrganization },
    GQL.ICreateOrganizationLogoOnMutationArguments
    >(
      CREATE_ORGANIZATION_LOGO,
      options,
    );

export const useDeleteOrganizationLogo = (
  options?: MutationOptions<
    { deleteOrganizationLogo: GQL.IOrganization },
    {}
    >,
) =>
  useMutationEnhanced<
    { deleteOrganizationLogo: GQL.IOrganization },
    {}
    >(
      DELETE_ORGANIZATION_LOGO,
      options,
    );

export const useUpdateOrganization = (
  options?: MutationOptions<
    GQL.IOrganization,
    GQL.IUpdateOrganizationOnMutationArguments
  >,
) => useMutationEnhanced<
  GQL.IOrganization,
  GQL.IUpdateOrganizationOnMutationArguments
>(
  UPDATE_ORGANIZATION,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'organization',
    ],
  },
);

export const useUpdateGraduatingGrade = (
  options?: MutationOptions<
    GQL.IOrganization,
    GQL.IUpdateGraduatingGradeOnMutationArguments
  >,
) => useMutationEnhanced<
  GQL.IOrganization,
  GQL.IUpdateGraduatingGradeOnMutationArguments
>(
  UPDATE_GRADUATING_GRADE,
  options,
);

export const useUpdateStripeId = (
  options?: MutationOptions<
    GQL.IOrganization,
    GQL.IUpdateStripeIdOnMutationArguments
  >,
) => useMutationEnhanced<
  GQL.IOrganization,
  GQL.IUpdateStripeIdOnMutationArguments
>(
  UPDATE_STRIPE_ID,
  options,
);

export const useClaimMyOrganization = (
  options?: MutationOptions<
    ClaimMyOrganizationData,
    GQL.IClaimMyOrganizationOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    ClaimMyOrganizationData,
    GQL.IClaimMyOrganizationOnMutationArguments
  >(
    CLAIM_MY_ORGANIZATION,
    options,
  );
