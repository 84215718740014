// External Depencencies
import { Ref, forwardRef } from 'react';
import styled from 'styled-components';

// Local Dependencies
import DropdownButton, { DropdownButtonProps } from './DropdownButton';

// Local Typings
interface Props extends Omit<DropdownButtonProps, 'className'> {
  active: boolean;
  onClick: (() => void) | null;
}

// Local Variables
const StyledDropdownButton = styled(DropdownButton)(({ theme }) => ({
  '&.active': {
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.common.white,
    paddingRight: theme.spacing(1.5),
  },
  marginBottom: theme.spacing(0.5),
  marginRight: theme.spacing(1),
}));

// Component Definition
const FilterButton = forwardRef((
  props: Props,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    active,
    buttonChildren,
    onClick,
    subButtons,
    ...otherProps
  } = props;

  return (
    <StyledDropdownButton
      buttonChildren={buttonChildren}
      className={active ? 'active' : ''}
      color="inherit"
      onButtonClick={onClick}
      ref={ref}
      size="small"
      subButtons={subButtons}
      variant="outlined"
      {...otherProps}
    />
  );
});

export default FilterButton;
