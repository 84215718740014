export const convertDollarsToCents = (priceInDollars) => Math.round(priceInDollars * 100);

export const convertCentsToDollars = (priceInCents) => priceInCents / 100;

export const displayPriceStringFromDollarAmount = (priceInDollars, options) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  let formattedString = formatter.format(priceInDollars);

  if (options?.hideDollarSign) {
    formattedString = formattedString.replace('$', '');
  }

  if (options?.hideCents) {
    [formattedString] = formattedString.split('.');
  }

  return formattedString;
};
