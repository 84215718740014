// External Dependencies
import { CardContent, Container } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import MemberInfoForm from 'components/shared/MemberInfoForm';

// Component Definition
const JoinNewOrganization: FC = () => (
  <Container maxWidth="md">
    <Subtitle>
      Join an organization
    </Subtitle>

    <EnhancedCard>
      <CardContent>
        <MemberInfoForm />
      </CardContent>
    </EnhancedCard>
  </Container>
);

export default JoinNewOrganization;
