// External Dependencies
import { FC } from 'react';
import { alpha } from '@mui/material/styles';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Typings
interface Props {
  backgroundColor: string;
}
interface StyledEnhancedCardProps {
  $backgroundColor: string;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)<StyledEnhancedCardProps>(({
  $backgroundColor,
  theme,
}) => ({
  '.MuiCardHeader-root': {
    paddingBottom: 0,
  },

  '.MuiCardHeader-title': {
    fontSize: '1.1rem',
    paddingBottom: 0,
  },

  backgroundColor: alpha($backgroundColor, 0.3),
  margin: theme.spacing(0, 1, 1.5),
  width: '45%',
}));

/*
 *  Use this component in flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const EnhancedDataCard: FC<Props> = ({
  backgroundColor,
  children,
}) => (
  <StyledEnhancedCard $backgroundColor={backgroundColor}>
    {children}
  </StyledEnhancedCard>
);

export default EnhancedDataCard;
