// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { StyledLink } from 'components/shared';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Variables
interface Props {
  currentMode: 'scanner' | 'manual';
}

// Component Definition
const ToggleCheckoutModeCard: FC<Props> = ({ currentMode }) => {
  const navigate = useNavigate();
  const parsedSearch = useParsedSearch();

  const {
    userId,
  } = parsedSearch;

  const handleNavigateToManualUniformCheckoutForm = useCallback(() => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/new?user_id=${userId}`);
  }, [navigate, userId]);

  const isScanning = currentMode === 'scanner';

  return (
    <CardContent>
      <Box
        alignItems="baseline"
        display="flex"
      >
        <Typography>Not {isScanning ? 'scanning' : 'entering manually'}?</Typography>
        <Box ml={1}>
          <StyledLink onClick={handleNavigateToManualUniformCheckoutForm}>
            Add {isScanning ? 'manually' : 'with scanner'}
          </StyledLink>
        </Box>
      </Box>
    </CardContent>
  );
};

export default ToggleCheckoutModeCard;
