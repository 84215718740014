// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { UserOrgRole } from 'gql/queries';

// Local Typings
interface Props {
  roles: UserOrgRole[] | undefined;
}

// Component Definition
const ShowStudentRoleData = ({ roles }: Props): JSX.Element | null => {
  if (!roles) {
    return null;
  }

  const primaryRole = roles.find((role) => role.isPrimary)?.organizationRole.label;

  const secondaryRoles = roles.reduce((acc: string[], curr) => {
    if (!curr.isPrimary) {
      acc.push(curr.organizationRole.label);
    }
    return acc;
  }, []);

  return (
    <>
      <ShowPageDataDisplay
        label="Primary Role"
        value={primaryRole}
      />
      <ShowPageDataDisplay
        label={`Secondary Role${secondaryRoles.length > 1 ? 's' : ''}`}
        value={secondaryRoles.join(', ')}
      />
    </>
  );
};

export default ShowStudentRoleData;
