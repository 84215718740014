// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import AssistantsTableUI from './AssistantsTableUI';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const AssistantsTable: FC = () => (
  <>
    <BackToButton
      context="Dashboard"
      navigateTo={`/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`}
    />

    <Box
      component="section"
      my={2}
    >
      <Subtitle>
        District Assistants
      </Subtitle>

      <Box
        mb={1.5}
        ml={2}
      >
        <StyledTypography
          color="textSecondary"
          variant="body2"
        >
          District Assistants have the same permissions as District Administrators.
        </StyledTypography>
      </Box>

      <EnhancedCard>
        <AssistantsTableUI />
      </EnhancedCard>
    </Box>
  </>
);

export default AssistantsTable;
