// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PenIcon from 'mdi-material-ui/Pen';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedAlert, Flex } from 'components/shared';
import { getFullName } from 'utils';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
}

// Component Definition
const SignatureBlock = ({
  disabled,
  formBlock,
}: Props): JSX.Element => {
  const {
    self,
  } = useSelfQuery();

  const isAdult = self?.role.id === UserRoles.Adult.toString();

  const fullName = self ? getFullName(self) : '';

  const formikContext = useFormikContext<GQL.ISubmitFormBlockResponseInput>();

  const signature = formikContext.values.response;

  const handleClickSignButton = useCallback(() => {
    formikContext.setFieldValue('response', fullName);
  }, [formikContext, fullName]);

  const handleClickClearSignatureButton = useCallback(() => {
    formikContext.setFieldValue('response', '');
  }, [formikContext]);

  const { adultSignature } = formBlock.metadata;

  const canEdit = isAdult || !adultSignature;

  const action = useMemo(() => {
    if (disabled) {
      return null;
    }

    return (
      signature ? (
        <IconButton
          onClick={handleClickClearSignatureButton}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : (
        <Button
          onClick={handleClickSignButton}
          startIcon={<PenIcon />}
          variant="outlined"
        >
          Sign
        </Button>
      ));
  }, [
    disabled,
    handleClickClearSignatureButton,
    handleClickSignButton,
    signature,
  ]);

  const parentSignatureText = useMemo(() => {
    if (signature) {
      return null;
    }

    return (
      <EnhancedAlert>
        Only adult relative can sign
      </EnhancedAlert>
    );
  }, [signature]);

  return (
    <>
      <Typography
        paragraph
        sx={{ fontSize: '1.1rem', fontWeight: 500 }}
        variant="h6"
      >
        {formBlock.label}
        {formBlock.isRequired ? ' *' : ''}
      </Typography>

      <Flex gap={1}>
        {signature && (
          <Typography
            fontFamily="Calibri"
            fontSize="1.5rem"
            fontStyle="italic"
          >
            {signature}
          </Typography>
        )}

        {canEdit ? action : parentSignatureText}
      </Flex>
    </>
  );
};

export default SignatureBlock;
