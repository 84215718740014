// External Dependencies
import { SelectChangeEvent } from '@mui/material/Select';

// Internal Dependencies
import { Select } from 'components/shared';

// Local Dependencies
import { useChildOptions } from './hooks';

// Local Typings
interface Props {
  excludeSelf?: boolean;
  handleChangeUserId: (userId: string) => void;
  userId: string | null;
}

// Keep current with udpates to the `relationship_types` DB table
// TODO: Update this once Story 182 is finished
// https://presto-assistant.atlassian.net/browse/PRESTO-182
export type RelationshipTypeLabel =
  | 'Parent'
  | 'Child'
  | 'Grandparent'
  | 'Grandchild'
  | 'Aunt/Uncle'
  | 'Niece/Nephew'
  | 'Sibling'
  | 'Step-Parent'
  | 'Step-Child'
  | 'Step-Sibling'
  | 'Spouse'
  | 'Guardian'
  | 'Child (with legal guardian)';

// This select will show the chilren for an adult user
// We can send the selfData in to include the current adult user in the select

// Component Definition
const ChildSelect = ({
  excludeSelf = false,
  handleChangeUserId,
  userId,
}: Props): JSX.Element | null => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    handleChangeUserId(event.target.value as string);
  };

  const childOptions = useChildOptions({ excludeSelf, userId });

  if (!childOptions.length) {
    return null;
  }

  return (
    <Select
      fullWidth={false}
      label={excludeSelf ? 'Child' : 'Person'}
      name={excludeSelf ? 'child' : 'person'}
      onChange={handleChange}
      options={childOptions}
      required
      testId="child-select"
      value={userId ?? ''}
    />
  );
};

export default ChildSelect;
