// External Dependencies
import { useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DescriptionIcon from '@mui/icons-material/Description';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { EnhancedCard, ListItemWithSecondaryAction } from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  '.subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const ExtraResourcesCard = (): JSX.Element => {
  const secondaryAction = useMemo(() => ({
    buttonIcon: <ArrowForwardIcon />,
    buttonText: 'View',
    to: `/${PATHS.GETTING_STARTED}`,
  }), []);

  return (
    <StyledEnhancedCard>
      <CardHeader
        avatar={(
          <Avatar className="avatar">
            <DescriptionIcon aria-label="Extra resources" />
          </Avatar>
          )}
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            Extra Resources
          </Typography>
          )}
      />

      <CardContent>
        <Typography
          className="subtitle"
          component="h6"
        >
          Getting Started
        </Typography>

        <List>
          <ListItemWithSecondaryAction
            primaryText="View the Getting Started Guide"
            secondaryAction={secondaryAction}
            secondaryText={`This guide will walk you through the basics of using the ${APP_NAME} platform.`}
          />
        </List>
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default ExtraResourcesCard;
