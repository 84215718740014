// External Dependencies
import { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import EnhancedBanner from './EnhancedBanner';

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8rem',
    paddingLeft: 0,
  },
  paddingLeft: theme.spacing(3),
}));

// Component Definition
const FullAppMessageBanner = (): JSX.Element | null => {
  const theme = useTheme();

  const {
    data: orgData,
  } = useGetOrganization();

  const fullAppBannerMessage = useMemo(() => {
    if (process.env.REACT_APP_FULL_APP_BANNER_MESSAGE) {
      return process.env.REACT_APP_FULL_APP_BANNER_MESSAGE as string;
    }

    if (orgData?.organization.hasVancoPcct && process.env.REACT_APP_REVTRAK_SYSTEM_ISSUES === 'true') {
      return 'We are currently experiencing issues with RevTrak payments. Online payments are unavailable at this time.';
    }

    return null;
  }, [orgData]);

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  useEffect(() => {
    if (fullAppBannerMessage) {
      handleOpen();
    }
  }, [fullAppBannerMessage, handleOpen]);

  return fullAppBannerMessage
    ? (
      <EnhancedBanner
        isAlertOpen={isOpen}
        onClose={handleClose}
      >
        <StyledBox
          alignItems="center"
          display="flex"
        >
          <Box
            alignItems="center"
            display="flex"
            marginRight={3}
          >
            <InfoIcon
              fontSize="large"
              htmlColor={theme.palette.alertColors.info.border}
            />
          </Box>

          {fullAppBannerMessage}
        </StyledBox>
      </EnhancedBanner>
    ) : null;
};

export default FullAppMessageBanner;
