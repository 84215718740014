// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import InventoryAuditsTable from './InventoryAuditsTable';

// Component Definition
const InventoryAudits = (): JSX.Element => (
  <>
    <Subtitle>
      Inventory Audits
    </Subtitle>
    <EnhancedCard>
      <InventoryAuditsTable />
    </EnhancedCard>
  </>
);

export default InventoryAudits;
