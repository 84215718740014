// External Dependencies
import { AlertProps } from '@mui/lab';
import { ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props extends AlertProps {
  action?: ReactNode;
  children: any;
  isAlertOpen?: boolean;
  isTip?: boolean
  onClose?: () => void;
  v3Style?: boolean;
}

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.v3': {
    // Small nod towards the Material Design v3
    borderRadius: '20px',
  },
  border: `1px solid ${theme.palette.grey[300]}`,
}));

// Component Definition
const EnhancedAlert = ({
  action,
  children,
  isAlertOpen,
  isTip,
  onClose,
  title,
  v3Style,
  ...otherProps
}: Props): JSX.Element => {
  const theme = useTheme();

  const actionElements = [action];

  const closeActionElement = (
    <StyledIconButton
      aria-label="close"
      key="close-button"
      onClick={onClose}
      size="small"
    >
      <CloseIcon fontSize="small" />
    </StyledIconButton>
  );

  if (onClose) {
    actionElements.push(closeActionElement);
  }

  const alertElement = (
    <StyledAlert
      action={actionElements}
      className={clsx(v3Style ? 'v3' : '')}
      icon={isTip ? <EmojiObjectsOutlinedIcon /> : null}
      severity={isTip ? 'success' : 'info'}
      {...otherProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  );

  return onClose
    ? (
      <Collapse
        in={isAlertOpen}
        mountOnEnter
        timeout={theme.transitions.duration.shortest}
        unmountOnExit
      >
        {alertElement}
      </Collapse>
    ) : alertElement;
};

export default EnhancedAlert;
