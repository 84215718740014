// External Dependencies
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import Flex from 'components/shared/Flex';

// Local Typings
interface FullPageSavingInterstitialProps {
  title : string;
}

// Local Variables
const useStyles = makeStyles({
  fullPage: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

// Component Definition
const FullPageSavingInterstitial = ({
  title,
}: FullPageSavingInterstitialProps) => {
  const classes = useStyles();

  return (
    <Modal
      open
    >
      <Flex
        alignItems="center"
        className={classes.fullPage}
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
      >
        <Typography
          component="h2"
          variant="h2"
        >
          {title}
        </Typography>
        <CircularProgress />
      </Flex>
    </Modal>
  );
};

export default FullPageSavingInterstitial;
