// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { Formik, FormikHelpers } from 'formik';
import {
  createFinancialPaymentSchema,
  createManyFinancialPaymentsSchema,
} from '@presto-assistant/api_types/schemas/financialPayments';
import {
  useCallback, useState,
} from 'react';
import { yup } from '@presto-assistant/api_types/schemas/yup';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import cloneDeep from 'lodash.clonedeep';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { convertDollarsToCents, getParamFormattedDate } from 'utils';

// Local Dependencies
import PaymentFormByUserForm, { PaymentsByUserFormValues } from './PaymentFormByUserForm';
import PaymentFormReviewDialog from './PaymentFormReviewDialog';

// Local Typings
interface Props {
  userId: string;
}

// Local Variables
const defaultValues: PaymentsByUserFormValues = {
  amount: 0,
  checkNumber: '',
  creditCardAccountHolder: '',
  creditCardExp: '',
  creditCardLastFour: '',
  datePaid: getParamFormattedDate(new Date()),
  financialPaymentTypeId: FinancialPaymentTypes.Cash.toString(),
  isPayingAllFees: false,
  otherLabel: '',
  payments: [
    {
      amountInCents: 0,
      creditAppliedAmountInCents: 0,
      financialFeeId: '',
      note: '',
      remainingBalanceInCents: 0,
    },
  ],
  referenceNumber: '',
};

// modify the api schema to meet the needs of this form
const clonedPaymentSchema = cloneDeep(createManyFinancialPaymentsSchema);

/* eslint-disable no-underscore-dangle */
let innerType = (clonedPaymentSchema as any).innerType ?? {} as any;

if (!Object.keys(innerType).length) {
  innerType = (clonedPaymentSchema as any)._subType ?? {} as any;
}

const clonedFields = innerType?.fields ?? {} as GQL.ICreateFinancialPaymentInput;
/* eslint-enable no-underscore-dangle */

delete clonedFields.checkNumber;
delete clonedFields.creditCardAccountHolder;
delete clonedFields.creditCardExp;
delete clonedFields.creditCardLastFour;
delete clonedFields.datePaid;
delete (clonedFields as any).financialPaymentTypeId;
delete clonedFields.otherLabel;
delete clonedFields.referenceNumber;

// disable this so a user may submit a "0" value
if (((clonedFields as any).creditAppliedAmountInCents as any)) {
  // eslint-disable-next-line no-underscore-dangle
  ((clonedFields as any).creditAppliedAmountInCents as any)._conditions = [];
}

const clonedSchema = yup.object().shape({
  payments: clonedPaymentSchema,
});

const clonedSingleSchema = cloneDeep(createFinancialPaymentSchema);

(clonedSchema.fields as any)
  .amount = createFinancialPaymentSchema.fields?.amountInCents;

delete (clonedSingleSchema.fields as any).amountInCents;
delete (clonedSingleSchema.fields as any).creditAppliedAmountInCents;
delete (clonedSingleSchema.fields as any).financialFeeId;
delete (clonedSingleSchema.fields as any).note;

const mergedSchemas = clonedSchema.concat(clonedSingleSchema);

// Component Definition
const PaymentFormByUser = ({ userId }: Props): JSX.Element => {
  const [
    previewPayload,
    setPreviewPayload,
  ] = useState<GQL.ICreateFinancialPaymentInput[] | null>(null);

  const handleClearPreviewPayload = useCallback(() => {
    setPreviewPayload(null);
  }, []);

  // Reformat the data going to the API
  const getUpdatedSubmitValues = (
    values: PaymentsByUserFormValues,
  ): GQL.ICreateFinancialPaymentInput[] => {
    const {
      checkNumber,
      creditCardAccountHolder,
      creditCardExp,
      creditCardLastFour,
      datePaid,
      financialPaymentTypeId,
      otherLabel,
      referenceNumber,
    } = values;

    return values.payments
      .map((payment) => ({
        amountInCents: convertDollarsToCents(Number(payment.amountInCents)),
        checkNumber,
        creditAppliedAmountInCents: convertDollarsToCents(
          Number(payment.creditAppliedAmountInCents),
        ),
        creditCardAccountHolder,
        creditCardExp,
        creditCardLastFour: creditCardLastFour || null,
        datePaid,
        financialFeeId: payment.financialFeeId,
        financialPaymentTypeId,
        note: payment.note.trim(),
        otherLabel: otherLabel.trim(),
        referenceNumber: referenceNumber.trim(),
      }))
      .filter((payment) => Boolean(payment.amountInCents || payment.creditAppliedAmountInCents));
  };

  const handleFormikSubmit = async (
    values: PaymentsByUserFormValues,
    formikProps: FormikHelpers<PaymentsByUserFormValues>,
  ) => {
    const { setSubmitting } = formikProps;

    const clonedValues = cloneDeep(values);

    clonedValues.amount = Number(clonedValues.amount);

    /* eslint-disable no-param-reassign */
    delete (clonedValues as any).isPayingAllFees;

    clonedValues.payments.forEach((payment) => {
      delete (payment as any).remainingBalanceInCents;

      payment.amountInCents = Number(payment.amountInCents);
      payment.creditAppliedAmountInCents = Number(payment.creditAppliedAmountInCents);
    });
    /* eslint-enable no-param-reassign */

    await mergedSchemas.validate(clonedValues, {
      abortEarly: false, strict: true,
    });

    // We reformat the data into the format that the API expects
    const updatedSubmitValues = getUpdatedSubmitValues(clonedValues);

    if (updatedSubmitValues.length) {
      setPreviewPayload(updatedSubmitValues);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Formik<PaymentsByUserFormValues>
        initialValues={defaultValues}
        onSubmit={handleFormikSubmit}
        validationSchema={mergedSchemas}
      >
        {({
          handleSubmit,
          resetForm: resetFormikForm,
          touched,
          values,
        }) => (
          <PaymentFormByUserForm
            onResetFormikForm={resetFormikForm}
            onSubmit={handleSubmit}
            touched={touched}
            userId={userId}
            values={values}
          />
        )}
      </Formik>
      <Collapse in>
        <Box
          marginX={6}
          marginY={1}
        >
          <EnhancedAlert severity="info">
            Students and their parents will
            receive an email with a receipt when payments are recorded.
          </EnhancedAlert>
        </Box>
      </Collapse>

      <PaymentFormReviewDialog
        onClickCancel={handleClearPreviewPayload}
        payload={previewPayload}
      />
    </>
  );
};

export default PaymentFormByUser;
