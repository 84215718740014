// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';

// Local Dependencies
import InvitationsToDfaList from './InvitationsToDfaList';

// Component Definition
const AdminInvitations: FC = () => (
  <Page
    backButtonProps={{
      label: 'Dashboard',
      path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`,
    }}
  >
    <InvitationsToDfaList />
  </Page>
);

export default AdminInvitations;
