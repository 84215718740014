// External Dependencies
import { InventoryIndexResponseItem, InventoryItemsResponse } from '@presto-assistant/api_types/api/v1/inventory';
import {
  ListInventoryAuditAssessmentsByInventoryIdResponse,
} from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import {
  ConfirmationDialog,
  TableDataGrid,
} from 'components/shared';
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { apiClient } from 'utils/apiClient';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useCheckInInventoryItem,
} from 'gql/mutations';
import {
  useGetInventoryItem,
} from 'gql/queries';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  inventoryAuditAssessmentData: ListInventoryAuditAssessmentsByInventoryIdResponse['data'];
  inventoryAuditCreatedAt: string;
  isComplete: boolean;
  onClickAssessment: (args: {
    inventoryItemId: string;
    isPresent: boolean;
  }) => void;
}

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.INVENTORY}/${id}`;
const dataSelector = (response: InventoryItemsResponse) => response?.data;
const fullCountSelector = (response: InventoryItemsResponse) => response?.fullCount;

// Component Definition
const InventoryTable = ({
  inventoryAuditAssessmentData,
  inventoryAuditCreatedAt,
  isComplete,
  onClickAssessment,
}: Props): JSX.Element => {
  const [checkInInventoryItemId, setCheckInInventoryItemId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));

  const {
    data: inventoryCheckinItemData,
  } = useGetInventoryItem(checkInInventoryItemId);

  const inventoryItemToCheckIn = inventoryCheckinItemData?.inventoryItem;

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.inventory.index(queryParams);
  }, []);

  const {
    data,
    isLoading,
  } = useInfinitePaginatedListQuery<InventoryItemsResponse, InventoryIndexResponseItem>({
    dataSelector,
    fullCountSelector,
    pageSize: 500,
    queryKey: ['inventoryItemsIndex'],
    request: fetchData,
  });

  const [checkInInventoryItem, {
    loading: isCheckingInInventoryItem,
  }] = useCheckInInventoryItem({
    onCompleted: () => {
      setCheckInInventoryItemId(null);

      dispatch(
        addNotification('Inventory item checked in!', 'success'),
      );

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleQuickCheckIn = useCallback((row: InventoryIndexResponseItem) => {
    setCheckInInventoryItemId(row.id);
  }, []);

  const handleConfirmCheckIn = useCallback(async () => {
    if (!inventoryItemToCheckIn) {
      return;
    }

    const activeCheckouts = inventoryItemToCheckIn.checkouts.filter((checkout) => checkout.active);

    if (!activeCheckouts.length) {
      dispatch(
        addNotification('Inventory item is not checked out!', 'error'),
      );
    }

    if (activeCheckouts.length > 1) {
      dispatch(
        addNotification('This item has multiple checkouts. Please check it in from the checkout page.', 'error'),
      );

      return;
    }

    checkInInventoryItem({
      variables: {
        inventoryCheckoutId: activeCheckouts[0].id,
      },
    });
  }, [
    checkInInventoryItem,
    dispatch,
    inventoryItemToCheckIn,
  ]);

  const handleCloseCheckInDialog = useCallback(() => {
    setCheckInInventoryItemId(null);
  }, []);

  const extraColumns = useMemo<DataGridColDef<InventoryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<InventoryIndexResponseItem>([
        ...(canEditInventoryCheckouts ? [{
          action: handleQuickCheckIn,
          icon: <RemoveIcon />,
          isDisabled: (row: InventoryIndexResponseItem) => !row.checkedOutTo,
          text: 'Quick check-in',
        }] : []),
      ]);

      return (actionsColumn ? [actionsColumn] : []) as DataGridColDef<InventoryIndexResponseItem>[];
    },
    [
      canEditInventoryCheckouts,
      handleQuickCheckIn,
    ],
  );

  const columnsArgs = useMemo(() => ({
    extraColumns,
    inventoryAuditAssessmentData,
    inventoryAuditCreatedAt,
    isComplete,
    onClickAssessment,
  }), [
    extraColumns,
    inventoryAuditAssessmentData,
    inventoryAuditCreatedAt,
    isComplete,
    onClickAssessment,
  ]);

  const columns = useColumns(columnsArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          loading={isLoading}
          rows={data}
          tableResource="inventoryItems"
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckIn}
        confirmButtonText="Yes, check in"
        declineButtonAction={handleCloseCheckInDialog}
        description="Are you sure you want to check in this inventory item?"
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInInventoryItem}
        open={Boolean(checkInInventoryItemId)}
        title="Check in inventory item"
      />
    </>
  );
};

export default InventoryTable;
