// External Dependencies
import {
  UpdateOrganizationFinancialSettingsRequest,
  UpdateOrganizationHeadDirectorRequest,
  VancoPcctPayload,
} from '@presto-assistant/api_types/api/v1/organization';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { useTanstackMutation } from 'utils/api/index';

export const useSetVancoPcct = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: VancoPcctPayload) => {
    return apiClient.v1.organization.setVancoPcct(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('organization');
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};

export const useUpdateFinancialSettings = () => {
  const mutationFn = useCallback((payload: UpdateOrganizationFinancialSettingsRequest['body']) => {
    return apiClient.v1.organization.updateFinancialSettings(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};

export const useUpdateHeadDirector = () => {
  const mutationFn = useCallback((request: Pick<UpdateOrganizationHeadDirectorRequest, 'body' | 'params'>) => {
    return apiClient.v1.organization.updateHeadDirector(request);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('organization');
      evictApolloCache('districtOrganizationsIndex');
    },
  });
};
