// External Dependencies
import {
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { RemoveFinancialFeeRequest } from '@presto-assistant/api_types/api/v1/financialFee';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { waiveFinancialFeeSchema } from '@presto-assistant/api_types/schemas/financialFees';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Box from '@mui/material/Box';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import Collapse from '@mui/material/Collapse';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import LinearProgress from '@mui/material/LinearProgress';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TextBoxCheckIcon from 'mdi-material-ui/TextBoxCheck';
import TextBoxIcon from 'mdi-material-ui/TextBox';
import TextBoxSearchIcon from 'mdi-material-ui/TextBoxSearch';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  SupportLink,
  TableDataGrid,
} from 'components/shared';
import {
  DELETE_FINANCIAL_FEE,
  WAIVE_FINANCIAL_FEE,
  useDeleteFinancialFees,
  useUnwaiveFinancialFee,
  useWaiveFinancialFee,
} from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { SELECTION_TYPES } from 'utils/constants';
import { addNotification } from 'state/notifications/actions';
import {
  createDataGridActionsColumn,
} from 'components/shared/TableV2';
import { evictApolloCache } from 'gql/client';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/uncategorizedFinancialFeeUploadDialog/actions';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useGetFinancialFeesIndex,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useRemoveFinancialFee } from 'utils/api/financialFee';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';
import DialogUncategorizedFinancialFeeFileUpload
  from 'components/shared/DialogUncategorizedFinancialFeeFileUpload';
import EnhancedAlert from 'components/shared/EnhancedAlert';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import { deleteFinancialFeeSchema } from '../../shared/schema';
import { useColumns } from './hooks';

// Local Typings
interface Props {
  schoolYearEnding: number;
}
type FeeActionGerund = 'waiving' | 'removing' | 'deleting';

// Local Variables
const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  ul: {
    margin: theme.spacing(0, 0, 1),
    paddingInlineStart: theme.spacing(2),
  },
}));

export const StyledStrong = styled.strong({
  fontSize: '1.25rem',
});

const waiveFeeClearCachePredicates = ['financialFeesIndex', 'financialFeesOverview'];
const waiveFeeContext: [string] = ['fee'];

// Component Definition
const FinancialFeesTable = ({
  schoolYearEnding,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteNoteFieldError, setDeleteNoteFieldError] = useState<string | null>(null);
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);
  const [itemToRemoveId, setItemToRemoveId] = useState<string | null>(null);
  const [itemToWaiveId, setItemToWaiveId] = useState<string | null>(null);
  const [itemToUnwaiveId, setItemToUnwaiveId] = useState<string | null>(null);
  const [memberIdsToEmail, setMemberIdsToEmail] = useState<string[] | null>(null);
  const [selectedFeeRemoveProgress, setSelectedFeeRemoveProgress] = useState(0);
  const [selectedFeeWaiveProgress, setSelectedFeeWaiveProgress] = useState(0);

  const canWritePayments = useSelector(hasPermission('payments', 'write'));
  const canDeleteFinances = useSelector(hasPermission('finances', 'delete'));
  const canReadMembers = useSelector(hasPermission('users', 'read'));
  const canRemoveFees = useSelector(hasPermission('finances', 'edit'));
  const canWaiveFees = useSelector(hasPermission('finances', 'edit'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));

  const deleteNoteField = useTextField();
  const {
    onReset: resetDeleteNoteField,
    value: deleteNoteFieldValue,
  } = deleteNoteField;

  const [deleteFinancialFees] = useDeleteFinancialFees();
  const [waiveFinancialFee] = useWaiveFinancialFee();

  const {
    isLoading: isRemovingFee,
    mutate: removeFinancialFee,
    mutateAsync: removeFinancialFeeAsync,
  } = useRemoveFinancialFee();

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedFeeIds,
    setSelectedFeeIds,
  ] = useState<GridRowSelectionModel>([]);

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleDeleteDialog,
  } = useIsOpen();
  const {
    isOpen: isRemoveDialogOpen,
    toggleIsOpen: toggleRemoveDialog,
  } = useIsOpen();
  const {
    isOpen: isWaiveDialogOpen,
    toggleIsOpen: toggleWaiveDialog,
  } = useIsOpen();
  const {
    isOpen: isRemoveSelectedFeesDialogOpen,
    toggleIsOpen: toggleRemoveSelectedFeesDialog,
  } = useIsOpen();
  const {
    isOpen: isWaiveSelectedFeesDialogOpen,
    toggleIsOpen: toggleWaiveSelectedFeesDialog,
  } = useIsOpen();
  const {
    isOpen: isDeleteSelectedFeesDialogOpen,
    toggleIsOpen: toggleDeleteSelectedFeesDialog,
  } = useIsOpen();
  const {
    isOpen: isUnwaiveDialogOpen,
    toggleIsOpen: toggleUnwaiveDialog,
  } = useIsOpen();
  const {
    handleOpen: handleOpenSendStatementDialog,
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();
  const {
    isOpen: isRemovingSelectedFees,
    toggleIsOpen: toggleIsRemovingSelectedFees,
  } = useIsOpen();
  const {
    isOpen: isWaivingSelectedFees,
    toggleIsOpen: toggleIsWaivingSelectedFees,
  } = useIsOpen();
  const {
    isOpen: isDeletingSelectedFees,
    toggleIsOpen: toggleIsDeletingSelectedFees,
  } = useIsOpen();

  useEffect(() => {
    setDeleteNoteFieldError(null);
  }, [deleteNoteFieldValue]);

  const handleCancelRemoveSelectedFees = useCallback(() => {
    toggleRemoveSelectedFeesDialog();
    resetDeleteNoteField();
    // only passing in onReset instead of the entire deleteNoteField
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDeleteNoteField, toggleRemoveSelectedFeesDialog]);

  const handleCancelWaiveSelectedFees = useCallback(() => {
    toggleWaiveSelectedFeesDialog();
    resetDeleteNoteField();
    // only passing in onReset instead of the entire deleteNoteField
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDeleteNoteField, toggleWaiveSelectedFeesDialog]);

  const handleCancelDeleteSelectedFees = useCallback(() => {
    toggleDeleteSelectedFeesDialog();
    resetDeleteNoteField();
    // only passing in onReset instead of the entire deleteNoteField
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDeleteNoteField, toggleDeleteSelectedFeesDialog]);

  const {
    data,
    isLoading,
  } = useGetFinancialFeesIndex(schoolYearEnding);

  const handleRemoveSuccess = useCallback((successMessage: string) => {
    dispatch(addNotification(successMessage, 'success'));
    resetDeleteNoteField();
    evictApolloCache('financialFeesIndex');
    evictApolloCache('financialFeesOverview');
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));
  }, [
    dispatch,
    resetDeleteNoteField,
  ]);

  const handleRemoveSelectedFees = useCallback(async () => {
    try {
      toggleIsRemovingSelectedFees();

      if (deleteNoteFieldValue.trim().length < 5) {
        setDeleteNoteFieldError('Please provide a reason for removing the fee that is at least 5 characters long.');
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const selectedFeeId of selectedFeeIds) {
        const body: RemoveFinancialFeeRequest['body'] = {
          removedReason: deleteNoteFieldValue,
        };

        const params: RemoveFinancialFeeRequest['params'] = {
          id: selectedFeeId as string,
        };

        // eslint-disable-next-line no-await-in-loop
        await removeFinancialFeeAsync({
          body,
          params,
        });

        setSelectedFeeRemoveProgress((prevProgress) => prevProgress + 1);
      }

      toggleRemoveSelectedFeesDialog();

      handleRemoveSuccess('Fees removed successfully');

      setSelectedFeeIds([]);
    } finally {
      toggleIsRemovingSelectedFees();
      setSelectedFeeRemoveProgress(0);
    }
  }, [
    deleteNoteFieldValue,
    handleRemoveSuccess,
    removeFinancialFeeAsync,
    selectedFeeIds,
    toggleIsRemovingSelectedFees,
    toggleRemoveSelectedFeesDialog,
  ]);

  const handleRemoveFinancialFee = useCallback(() => {
    if (itemToRemoveId) {
      if (deleteNoteFieldValue.trim().length < 5) {
        setDeleteNoteFieldError('Please provide a reason for removing the fee that is at least 5 characters long.');
        return;
      }

      removeFinancialFee({
        body: {
          removedReason: deleteNoteFieldValue,
        },
        params: {
          id: itemToRemoveId,
        },
      }, {
        onSuccess: () => {
          toggleRemoveDialog();
          setItemToRemoveId(null);
          handleRemoveSuccess('Fee removed successfully');
        },
      });
    }
  }, [
    deleteNoteFieldValue,
    handleRemoveSuccess,
    itemToRemoveId,
    removeFinancialFee,
    toggleRemoveDialog,
  ]);

  const handleWaiveSelectedFees = useCallback(async () => {
    try {
      toggleIsWaivingSelectedFees();

      if (deleteNoteFieldValue.trim().length < 5) {
        setDeleteNoteFieldError('Please provide a reason for waiving the fee that is at least 5 characters long.');
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const selectedFeeId of selectedFeeIds) {
        // eslint-disable-next-line no-await-in-loop
        await waiveFinancialFee({
          variables: {
            input: {
              financialFeeId: selectedFeeId as string,
              waivedReason: deleteNoteFieldValue,
            },
          },
        });

        setSelectedFeeWaiveProgress((prevProgress) => prevProgress + 1);
      }

      toggleWaiveSelectedFeesDialog();
      dispatch(addNotification('Fees waived successfully', 'success'));
      resetDeleteNoteField();
      setSelectedFeeIds([]);
    } finally {
      toggleIsWaivingSelectedFees();
      setSelectedFeeWaiveProgress(0);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    }
  }, [
    deleteNoteFieldValue,
    dispatch,
    resetDeleteNoteField,
    selectedFeeIds,
    toggleIsWaivingSelectedFees,
    toggleWaiveSelectedFeesDialog,
    waiveFinancialFee,
  ]);

  const handleDeleteSelectedFees = useCallback(async () => {
    try {
      const selectedData = data?.filter((row) => selectedFeeIds.includes(row.id)) ?? [];
      const isEveryFeeDeletable = selectedData.every((row) => row.canBeDeleted) ?? false;

      const feeWord = pluralize(selectedFeeIds.length, 'fee');

      toggleIsDeletingSelectedFees();

      if (!isEveryFeeDeletable) {
        dispatch(addNotification('Some selected fees cannot be deleted. Apply the filter for "Can Be Deleted" to only see deletable fees.', 'error'));
        return;
      }

      if (deleteNoteFieldValue.trim().length < 5) {
        setDeleteNoteFieldError(`Please provide a reason for deleting the ${feeWord} that is at least 5 characters long.`);
        return;
      }

      await deleteFinancialFees({
        variables: {
          deletedNote: deleteNoteFieldValue,
          selection: {
            ids: selectedFeeIds as string[],
            mutationFlag: SELECTION_TYPES.SELECTED_MANY as GQL.MutationFlag,
            queryParams: {},
          },
        },
      });

      toggleDeleteSelectedFeesDialog();
      dispatch(addNotification('Fees deleted successfully', 'success'));
      resetDeleteNoteField();
      setSelectedFeeIds([]);
    } finally {
      toggleIsDeletingSelectedFees();
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    }
  }, [
    data,
    deleteFinancialFees,
    deleteNoteFieldValue,
    dispatch,
    resetDeleteNoteField,
    selectedFeeIds,
    toggleDeleteSelectedFeesDialog,
    toggleIsDeletingSelectedFees,
  ]);

  const handleClickSendStatements = useCallback((memberIds: string[] | null) => () => {
    setMemberIdsToEmail(memberIds);
    handleOpenSendStatementDialog();
  }, [handleOpenSendStatementDialog]);

  const handleClickRemoveSelectedFees = useCallback(() => {
    toggleRemoveSelectedFeesDialog();
  }, [toggleRemoveSelectedFeesDialog]);

  const handleClickWaiveSelectedFees = useCallback(() => {
    toggleWaiveSelectedFeesDialog();
  }, [toggleWaiveSelectedFeesDialog]);

  const handleClickDeleteSelectedFees = useCallback(() => {
    toggleDeleteSelectedFeesDialog();
  }, [toggleDeleteSelectedFeesDialog]);

  const [
    unwaiveFinancialFee,
    {
      loading: isUnwaivingFinancialFee,
    },
  ] = useUnwaiveFinancialFee({
    onCompleted: () => {
      toggleUnwaiveDialog();
      setItemToUnwaiveId(null);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleClickViewFinancialItem = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}/${row.financialItemId}`);
  }, [navigate]);

  const handleClickViewMember = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.MEMBERS}/${row.userId}`);
  }, [navigate]);

  const handleNavigateToPaymentPage = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_NEW}?user_id=${row.userId}`);
  }, [navigate]);

  const handleOpenDialogUncategorizedFinancialFeeFileUpload = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setSingleItemDeleteId(row.id);
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  const handleOpenRemoveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToRemoveId(row.id);
    toggleRemoveDialog();
  }, [toggleRemoveDialog]);

  const handleOpenWaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToWaiveId(row.id);
    toggleWaiveDialog();
  }, [toggleWaiveDialog]);

  const handleOpenUnwaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToUnwaiveId(row.id);
    toggleUnwaiveDialog();
  }, [toggleUnwaiveDialog]);

  const createFeeActionsSupportArticleElement = useCallback((feeAction: FeeActionGerund) => (
    <SupportLink
      buttonText={`Learn more about ${feeAction} fees`}
      slug="waive-remove-delete-fees"
    />
  ), []);

  const handleUnwaiveFee = useCallback(() => {
    if (itemToUnwaiveId) {
      unwaiveFinancialFee({
        variables: {
          input: {
            financialFeeId: itemToUnwaiveId,
          },
        },
      });
    }
  }, [
    itemToUnwaiveId,
    unwaiveFinancialFee,
  ]);

  const formatWaiveFeePayload = useCallback<
    DeleteDialogV2Props<GQL.IWaiveFinancialFeeOnMutationArguments
  >['formatPayload']>((note) => ({
    input: {
      financialFeeId: itemToWaiveId ?? '',
      waivedReason: note ?? '',
    },
  }), [itemToWaiveId]);

  const formatDeleteFeePayload = useCallback<DeleteDialogV2Props<
    GQL.IDeleteFinancialFeeOnMutationArguments
  >['formatPayload']>((note) => ({
    deletedNote: note ?? '',
    selection: {
      ids: singleItemDeleteId ? [singleItemDeleteId] : [],
      mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
      queryParams: {},
    },
  }), [singleItemDeleteId]);

  const filteredMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        filteredRowIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, filteredRowIds],
  );

  const selectedMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        selectedFeeIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, selectedFeeIds],
  );

  const toolbarActions = useMemo<IToolbarAction[]>(() => [
    ...(canWriteFinances ? [{
      action: handleOpenDialogUncategorizedFinancialFeeFileUpload,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import fees',
    }] : []),
    {
      action: handleClickSendStatements(null),
      icon: <TextBoxIcon />,
      text: 'Send statements to everyone',
    },
    {
      action: handleClickSendStatements(selectedMemberIds),
      icon: <TextBoxCheckIcon />,
      isDisabled: selectedMemberIds.length === 0,
      text: `Send statements to selected (${selectedMemberIds.length})`,
    },
    {
      action: handleClickSendStatements(filteredMemberIds),
      icon: <TextBoxSearchIcon />,
      isDisabled: filteredMemberIds.length === 0,
      text: `Send statements to filtered (${filteredMemberIds.length})`,
    },
    ...(canWaiveFees ? [{
      action: handleClickWaiveSelectedFees,
      icon: <DoDisturbIcon />,
      isDisabled: selectedFeeIds.length === 0,
      text: `Waive selected fees (${selectedFeeIds.length})`,
    }] : []),
    ...(canRemoveFees ? [{
      action: handleClickRemoveSelectedFees,
      icon: <RemoveIcon />,
      isDisabled: selectedFeeIds.length === 0,
      text: `Remove selected fees (${selectedFeeIds.length})`,
    }] : []),
    ...(canDeleteFinances ? [{
      action: handleClickDeleteSelectedFees,
      icon: <DeleteIcon />,
      isDisabled: selectedFeeIds.length === 0,
      text: `Delete selected fees (${selectedFeeIds.length})`,
    }] : []),
  ], [
    canDeleteFinances,
    canRemoveFees,
    canWaiveFees,
    canWriteFinances,
    filteredMemberIds,
    handleClickDeleteSelectedFees,
    handleClickRemoveSelectedFees,
    handleClickSendStatements,
    handleClickWaiveSelectedFees,
    handleOpenDialogUncategorizedFinancialFeeFileUpload,
    selectedFeeIds,
    selectedMemberIds,
  ]);

  const extraColumns = useMemo<DataGridColDef<GQL.IFinancialFeeIndexItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IFinancialFeeIndexItem>([
        ...(canWritePayments ? [{
          action: handleNavigateToPaymentPage,
          icon: <AttachMoney />,
          text: 'Add a Payment',
        }] : []),
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View Member',
        }] : []),
        {
          action: handleClickViewFinancialItem,
          icon: <ReceiptIcon />,
          text: 'View Item',
        },
        ...(canWaiveFees ? [
          {
            action: handleOpenWaiveFeeDialog,
            icon: <DoDisturbIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => !row.isWaived,
            text: 'Waive Fee',
          },
          {
            action: handleOpenUnwaiveFeeDialog,
            icon: <AddShoppingCartIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => row.isWaived,
            text: 'Unwaive Fee',
          },
        ] : []),
        ...(canRemoveFees ? [{
          action: handleOpenRemoveFeeDialog,
          icon: <RemoveIcon />,
          isDisabled: (row: GQL.IFinancialFeeIndexItem) => Boolean(row.isRemoved),
          text: 'Remove Fee',
        }] : []),
        ...(canDeleteFinances ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <DeleteIcon />,
          isDisabled: (row: GQL.IFinancialFeeIndexItem) => !row.canBeDeleted,
          text: 'Delete Fee',
        }] : []),
      ]) as DataGridColDef<GQL.IFinancialFeeIndexItem> | null;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteFinances,
      canReadMembers,
      canRemoveFees,
      canWaiveFees,
      canWritePayments,
      handleClickViewFinancialItem,
      handleClickViewMember,
      handleNavigateToPaymentPage,
      handleOpenRemoveFeeDialog,
      handleOpenSingleItemDeleteDialog,
      handleOpenUnwaiveFeeDialog,
      handleOpenWaiveFeeDialog,
    ],
  );

  const columnArgs = useMemo(() => ({
    extraColumns,
    schoolYearEnding,
  }), [
    extraColumns,
    schoolYearEnding,
  ]);

  const columns = useColumns(columnArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWriteFinances ? {
            label: 'Fee',
            to: `/${PATHS.FINANCIAL_FEES_NEW}`,
          } : null}
          checkboxSelection
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          key={schoolYearEnding}
          loading={isLoading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedFeeIds}
          rows={data}
          selectionModel={selectedFeeIds}
          tableResource={`financialFees-${schoolYearEnding}`}
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <SendFinancialStatementDialog
        isOpen={isSendStatementDialogOpen}
        memberIds={memberIdsToEmail}
        onClose={toggleSendStatementDialog}
      />

      <DialogUncategorizedFinancialFeeFileUpload />

      <DeleteDialogV2<GQL.IWaiveFinancialFeeOnMutationArguments>
        actionVerb="Waive"
        clearCachePredicates={waiveFeeClearCachePredicates}
        context={waiveFeeContext}
        descriptionText={(
          <StyledDialogContentText>
            <ul>
              <li>
                Fee will show on the financial statement.
              </li>
              <li>
                Member will no longer be able to make payments on the fee.
              </li>
            </ul>

            {createFeeActionsSupportArticleElement('waiving')}
          </StyledDialogContentText>
        )}
        errorText="Please provide a reason to waive this fee."
        formatPayload={formatWaiveFeePayload}
        isOpen={isWaiveDialogOpen}
        mutation={WAIVE_FINANCIAL_FEE}
        onClose={toggleWaiveDialog}
        validationSchemaProps={{
          selectPayloadToValidate: (mutationPayload) => mutationPayload.input,
          validationSchema: waiveFinancialFeeSchema as any,
        }}
        withNote
      />

      <ConfirmationDialog
        confirmButtonAction={handleRemoveFinancialFee}
        confirmButtonText="Yes, Remove"
        declineButtonAction={toggleRemoveDialog}
        deletedNoteInputProps={deleteNoteField}
        description={(
          <>
            <StyledDialogContentText>
              <ul>
                <li>
                  Fee will only appear on the financial statement
                  if payments have been made on the fee.
                </li>
                <li>
                  Member will no longer be able to make payments on the fee.
                </li>
              </ul>

              {createFeeActionsSupportArticleElement('removing')}
            </StyledDialogContentText>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        errorText="Please provide a reason to remove this fee."
        handleClose={toggleRemoveDialog}
        isSubmitting={isRemovingFee}
        open={isRemoveDialogOpen}
        title="Remove Fee?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleRemoveSelectedFees}
        confirmButtonText="Yes, Remove"
        declineButtonAction={handleCancelRemoveSelectedFees}
        deletedNoteInputProps={isRemovingSelectedFees ? undefined : deleteNoteField}
        description={isRemovingSelectedFees ? (
          <>
            <Typography paragraph>
              Removing {selectedFeeIds.length} {pluralize(selectedFeeIds.length, 'fee')}...
            </Typography>

            <LinearProgress
              color="primary"
              sx={{ marginY: 2 }}
              value={(selectedFeeRemoveProgress / selectedFeeIds.length) * 100}
              variant="determinate"
            />
          </>
        ) : (
          <>
            <Typography gutterBottom>
              This will remove the{' '}
              <StyledStrong>{selectedFeeIds.length}</StyledStrong>{' '}
              selected {pluralize(selectedFeeIds.length, 'fee')}.
            </Typography>

            <StyledDialogContentText>
              <ul>
                <li>
                  {pluralize(selectedFeeIds.length, 'Fee')} will only appear on the financial statement
                  if payments have been made on the {pluralize(selectedFeeIds.length, 'fee')}.
                </li>
                <li>
                  Member will no longer be able to make payments on the {pluralize(selectedFeeIds.length, 'fee')}.
                </li>
              </ul>

              {createFeeActionsSupportArticleElement('removing')}
            </StyledDialogContentText>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        errorText={`Please provide a reason to remove ${pluralize(selectedFeeIds.length, 'this fee', 'these fees')}.`}
        handleClose={toggleRemoveSelectedFeesDialog}
        isSubmitting={isRemovingSelectedFees}
        open={isRemoveSelectedFeesDialogOpen}
        title="Remove Fees?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleWaiveSelectedFees}
        confirmButtonText="Yes, Waive"
        declineButtonAction={handleCancelWaiveSelectedFees}
        deletedNoteInputProps={isWaivingSelectedFees ? undefined : deleteNoteField}
        description={isWaivingSelectedFees ? (
          <>
            <Typography paragraph>
              Waiving {selectedFeeIds.length} {pluralize(selectedFeeIds.length, 'fee')}...
            </Typography>

            <LinearProgress
              color="primary"
              sx={{ marginY: 2 }}
              value={(selectedFeeWaiveProgress / selectedFeeIds.length) * 100}
              variant="determinate"
            />
          </>
        ) : (
          <>
            <Typography gutterBottom>
              This will waive the{' '}
              <StyledStrong>{selectedFeeIds.length}</StyledStrong>{' '}
              selected {pluralize(selectedFeeIds.length, 'fee')}.
            </Typography>

            <StyledDialogContentText>
              <ul>
                <li>
                  {pluralize(selectedFeeIds.length, 'Fee')} will show on the financial statement.
                </li>
                <li>
                  Member will no longer be able to make payments on the {pluralize(selectedFeeIds.length, 'fee')}.
                </li>
              </ul>

              {createFeeActionsSupportArticleElement('waiving')}
            </StyledDialogContentText>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        errorText={`Please provide a reason to waive ${pluralize(selectedFeeIds.length, 'this fee', 'these fees')}.`}
        handleClose={toggleWaiveSelectedFeesDialog}
        isSubmitting={isWaivingSelectedFees}
        open={isWaiveSelectedFeesDialogOpen}
        title="Waive Fees?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleDeleteSelectedFees}
        confirmButtonText="Yes, Delete"
        declineButtonAction={handleCancelDeleteSelectedFees}
        deletedNoteInputProps={isDeletingSelectedFees ? undefined : deleteNoteField}
        description={isDeletingSelectedFees ? (
          <Typography paragraph>
            Deleting {selectedFeeIds.length} {pluralize(selectedFeeIds.length, 'fee')}...
          </Typography>
        ) : (
          <>
            <Typography gutterBottom>
              This will delete the{' '}
              <StyledStrong>{selectedFeeIds.length}</StyledStrong>{' '}
              selected {pluralize(selectedFeeIds.length, 'fee')}.
            </Typography>

            <StyledDialogContentText>
              <ul>
                <li>
                  Fee will not appear on the financial statement.
                </li>
                <li>
                  Member will no longer be able to make payments on the fee.
                </li>
              </ul>

              {createFeeActionsSupportArticleElement('deleting')}
            </StyledDialogContentText>

            <Collapse
              in={deleteNoteFieldError !== null}
              mountOnEnter
            >
              <Box mt={2}>
                <EnhancedAlert severity="error">
                  {deleteNoteFieldError}
                </EnhancedAlert>
              </Box>
            </Collapse>
          </>
        )}
        errorText={`Please provide a reason to delete ${pluralize(selectedFeeIds.length, 'this fee', 'these fees')}.`}
        handleClose={toggleDeleteSelectedFeesDialog}
        isSubmitting={isDeletingSelectedFees}
        open={isDeleteSelectedFeesDialogOpen}
        title="Delete Fees?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleUnwaiveFee}
        declineButtonAction={toggleUnwaiveDialog}
        description="This member will be reassigned the fee."
        handleClose={toggleUnwaiveDialog}
        isSubmitting={isUnwaivingFinancialFee}
        open={isUnwaiveDialogOpen}
        title="Unwaive Fee?"
      />

      <DeleteDialogV2<GQL.IDeleteFinancialFeeOnMutationArguments>
        actionVerb="Delete"
        clearCachePredicates={waiveFeeClearCachePredicates}
        context={waiveFeeContext}
        descriptionText={(
          <StyledDialogContentText>
            <ul>
              <li>
                Fee will not appear on the financial statement.
              </li>
              <li>
                Member will no longer be able to make payments on the fee.
              </li>
            </ul>

            {createFeeActionsSupportArticleElement('deleting')}
          </StyledDialogContentText>
        )}
        errorText="Please provide a reason to delete this fee."
        formatPayload={formatDeleteFeePayload}
        isOpen={isDeleteDialogOpen}
        mutation={DELETE_FINANCIAL_FEE}
        onClose={toggleDeleteDialog}
        validationSchemaProps={{
          selectPayloadToValidate: (mutationPayload) => mutationPayload.deletedNote,
          validationSchema: deleteFinancialFeeSchema as any,
        }}
        withNote
      />
    </>
  );
};

export default FinancialFeesTable;
