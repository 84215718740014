// External Dependencies
import {
  ChangeEvent, FC, useCallback, useState,
} from 'react';
import { useFormikContext } from 'formik';

// Internal Dependencies
import { Checkbox } from 'components/shared';
import {
  useGetDistrict,
  useGetOrganization,
} from 'gql/queries';

// Local Typings
export interface AddressInfo {
  addressOne: string;
  addressTwo?: string;
  city: string;
  phoneNumber: string;
  stateId: string;
  zipcode: string;
}
export interface OnboardingContactValues extends AddressInfo {
  email: string;
  phoneNumber: string;
}

// Component Definition
const ExistingAddressCheckbox: FC = () => {
  const [checked, setChecked] = useState(false);

  // Fetch district data
  const {
    data: districtData,
    error: districtDataError,
    loading: districtDataLoading,
  } = useGetDistrict();

  // Fetch org data
  const {
    data: orgData,
    error: orgDataError,
    loading: orgDataLoading,
  } = useGetOrganization();

  const isDistrict = Boolean(districtData?.district);

  const formikContext = useFormikContext<OnboardingContactValues>();

  const formikValues = formikContext.values;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setChecked(isChecked);

    // Build payload
    let payload: AddressInfo = {
      addressOne: '',
      addressTwo: '',
      city: '',
      phoneNumber: '',
      stateId: '',
      zipcode: '',
    };

    if (!isChecked) {
      formikContext.setValues({
        ...formikValues,
        ...payload,
      });

      return;
    }

    if (districtData?.district) {
      payload = {
        addressOne: districtData.district.addressOne || '',
        addressTwo: districtData.district.addressTwo || '',
        city: districtData.district.city || '',
        phoneNumber: districtData.district.phoneNumber || '',
        stateId: districtData.district.state?.id || '',
        zipcode: districtData.district.zipcode || '',
      };
    } else if (orgData?.organization) {
      payload = {
        addressOne: orgData.organization.addressOne || '',
        addressTwo: orgData.organization.addressTwo || '',
        city: orgData.organization.city || '',
        phoneNumber: orgData.organization.phoneNumber || '',
        stateId: orgData.organization.state?.id || '',
        zipcode: orgData.organization.zipcode || '',
      };
    }

    // Update formik values
    formikContext.setValues({
      ...formikValues,
      ...payload,
    });
  // don't want to include formikContext in the dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    districtData,
    formikValues,
    orgData,
  ]);

  const isLoading = districtDataLoading || orgDataLoading;
  const hasError = Boolean(districtDataError || orgDataError);

  return (
    <Checkbox
      checked={checked}
      disabled={hasError || isLoading}
      label={`Use existing ${isDistrict ? 'district' : 'organization'} address`}
      onChange={handleChange}
    />
  );
};

export default ExistingAddressCheckbox;
