// External Dependencies
import { combineReducers } from 'redux';

// Internal Dependencies
import bannerAlerts, { BannerAlertsState } from './bannerAlerts/reducers';
import device, { DeviceState } from './device/reducers';
import inventory, { InventoryState } from './inventory/reducers';
import notifications, { NotificationsState } from './notifications/reducers';
import self, { SelfState } from './self/reducers';
import table, { TableState } from './table/reducers';
import ui, { UIState } from './ui/reducers';

export interface State {
  bannerAlerts: BannerAlertsState;
  device: DeviceState;
  inventory: InventoryState;
  notifications: NotificationsState;
  self: SelfState;
  table: TableState;
  ui: UIState;
}

export default combineReducers<State>({
  bannerAlerts,
  device,
  inventory,
  notifications,
  self,
  table,
  ui,
});
