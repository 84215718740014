// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';

// Internal Dependencies
import { dateTimeColumn } from 'utils/lib/tableColumns';

// Local Variables
const StyledBox = styled(Box)({
  wordWrap: 'break-word',
});

// Hook Definition
export const useColumns = (
  extraColumns?: GridColDef[],
) => useMemo(() => {
  const columns: GridColDef<GQL.ISuppressedEmailUserIndex>[] = [
    {
      field: 'email',
      headerName: 'Email',
      hideable: false,
      minWidth: 160,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 120,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 120,
    },
    dateTimeColumn({
      field: 'suppressedAt',
      headerName: 'Suppressed On',
    }),
    {
      field: 'reason',
      headerName: 'Reason',
      minWidth: 420,
      renderCell: ({ value }) => (
        <StyledBox>{value}</StyledBox>
      ),
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
