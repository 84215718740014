// External Dependencies
import {
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import TieIcon from 'mdi-material-ui/Tie';
import styled from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import CheckoutCount from './CheckoutCount';
import ShowCard from './ShowCard';
import StyledLink from './StyledLink';
import SubscriberAddButton from './SubscriberAddButton';
import UniformDataCard from './UniformDataCard';

// Local Typings
interface Props {
  name?: string;
  uniformCheckoutsData: GQL.IUniformCheckout[];
  userId?: string;
}

// Local Variables
const StyledShowCard = styled(ShowCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const ShowStudentUniformCheckoutUI: FC<Props> = ({
  name,
  uniformCheckoutsData,
  userId,
}) => {
  const {
    isOpen: isViewingUniformData,
    toggleIsOpen: toggleViewUniformData,
  } = useIsOpen();

  const uniformCheckoutsCount = uniformCheckoutsData.length;
  const hasUniformCheckouts = uniformCheckoutsCount > 0;

  return (
    <Box mb={2}>
      <StyledShowCard
        canEdit={false}
        icon={TieIcon}
        title="Uniforms"
      >
        {!hasUniformCheckouts && (
          <Typography color="textSecondary">
            No uniforms checked out
          </Typography>
        )}

        {hasUniformCheckouts && (
          <>
            <Box
              alignItems="baseline"
              display="flex"
              mb={2}
            >
              <CheckoutCount
                count={uniformCheckoutsCount}
                name={name}
                resource="uniform"
              />
              <Box ml={1}>
                <StyledLink onClick={toggleViewUniformData}>
                  {isViewingUniformData ? 'Hide' : 'View'}
                </StyledLink>
              </Box>
            </Box>

            <Collapse
              in={isViewingUniformData}
              mountOnEnter
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {uniformCheckoutsData.map((uniformCheckout) => uniformCheckout.active && (
                  <UniformDataCard uniformCheckoutData={uniformCheckout} />
                ))}
              </Box>
            </Collapse>
          </>
        )}

        {userId && (
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
          >
            <SubscriberAddButton
              label="Uniform Checkout"
              to={`/${PATHS.UNIFORM_CHECKOUTS}/${PATHS.BARCODE_SCAN}?userId=${userId}`}
            />
          </Box>
        )}
      </StyledShowCard>
    </Box>
  );
};

export default ShowStudentUniformCheckoutUI;
