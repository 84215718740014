// External Dependencies
import { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import styled from 'styled-components';

// Internal Dependencies
import { getGradientBackground } from 'utils/lib/getGradientBackground';

// Local Typings
interface Props {
  title: string;
}

// Local Variables
const StyledCard = styled(Card)(({ theme }) => ({
  '.smallScreen': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
  },
  '.titleCardHeader': {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
      padding: 0,
    },
    color: theme.palette.mode === 'dark'
      ? theme.palette.common.black : theme.palette.common.white,
    padding: `0 ${theme.spacing(2)}`,
    textTransform: 'uppercase',
  },
  background: getGradientBackground(theme),
}));

// Component Definition
const OnboardingStepTitle: FC<Props> = ({ title }) => (
  <StyledCard elevation={2}>
    <CardHeader
      className="titleCardHeader"
      component="h2"
      title={title}
      titleTypographyProps={{
        classes: { root: 'smallScreen' },
        variant: 'h5',
      }}
    />
  </StyledCard>
);

export default OnboardingStepTitle;
