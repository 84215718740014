// External Dependencies
import { FC } from 'react';
import { extractDollarAmountFromCurrencyString } from '@presto-assistant/api_types/utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { convertDollarsToCents } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateFinancialItem } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import { useGetOrganization } from 'gql/queries';
import FinancialItemsForm from '../shared/FinancialItemsForm';

// Component Definition
const FinancialItemsNew: FC = () => {
  const navigate = useNavigate();

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));

  const organizationData = useGetOrganization();

  const organization = organizationData.data?.organization;

  const initialValues: GQL.ICreateFinancialItemInput = {
    canApplyFundraiserCredits: false,
    financialAccountId: '',
    isActive: true,
    isOrganizationCoveringStripeFee: Boolean(organization?.isCoveringStripeFeeByDefault),
    label: '',
    priceInCents: 0,
    schoolYearEnding,
  };

  const itemShowParams = useSelector(tableQueryParams('financialItemMemberFees'));

  const [createFinancialItem, { error }] = useCreateFinancialItem(
    {
      clearCachePredicates: ['financialItems'],
      onCompleted: (data) => {
        navigate(`/${PATHS.FINANCIAL_ITEMS}/${data.createFinancialItem.id}${itemShowParams}`);
      },
    },
  );

  const handleSubmit = async (values: GQL.ICreateFinancialItemInput) => {
    const priceInDollars = extractDollarAmountFromCurrencyString(values.priceInCents);

    await createFinancialItem({
      variables: {
        input: {
          ...values,
          priceInCents: Number(convertDollarsToCents(priceInDollars)),
          // The CustomSelect turns the values into a string,
          //  but the API expects a number here
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Financial Items',
        path: `/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`,
      }}
      error={error}
    >
      <FinancialItemsForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        title="New Financial Item"
      />
    </Page>
  );
};

export default FinancialItemsNew;
