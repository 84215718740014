// External Dependencies
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useGetPaymentsFromTransactionId = (id: string) => {
  const queryFn = useCallback(async () => {
    return apiClient.v1.financialTransaction.payments(id);
  }, [id]);

  return useQuery({
    queryFn,
    queryKey: [`paymentsFromTransaction-${id}`],
  });
};
