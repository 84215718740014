// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { CustomSelect } from 'components/shared';
import { getFullName } from 'utils';
import { useGetFamilyMembersOnly } from 'gql/queries';

// Local Typings
export interface FormValues {
  isActive: boolean;
  label: string;
  priceInCents: string | number;
  schoolYearEnding: number;
}

interface Props {
  label?: string;
  memberId: string;
  name?: string;
}

// Component Definition
const FamilyMemberSelect: FC<Props> = ({
  label = 'Family Member',
  memberId,
  name = 'memberId',
}) => {
  const {
    familyMembers,
    isLoading: isFamilyMemberDataLoading,
  } = useGetFamilyMembersOnly(memberId);

  return (
    <CustomSelect
      disabled={!familyMembers?.length}
      label={label}
      isLoading={isFamilyMemberDataLoading}
      name={name}
      options={familyMembers?.filter(Boolean).map((familyMember) => ({
        id: familyMember!.id,
        label: getFullName(familyMember!),
      }))}
    />
  );
};

export default FamilyMemberSelect;
