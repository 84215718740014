// External Dependencies
import { FC } from 'react';

// Local Dependencies
import ChecklistsNew from '../New';
import EditChecklistInfoForm from '../Edit/EditChecklistInfoForm';

// Local Typings
interface Props {
  checklistId?: string;
}

// Component Definition
const ChecklistInfoFormWrapper: FC<Props> = ({ checklistId }) =>
  // If `id` is passed in, we are editing an existing checklist
  // If `id` is not passed in, we are creating a new checklist
  (checklistId
    ? <EditChecklistInfoForm checklistId={checklistId} />
    : <ChecklistsNew />);

export default ChecklistInfoFormWrapper;
