// External Dependencies
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME, SUPPORT_SITE_HREF } from 'utils/constants';
import { StyledLink } from 'components/shared';
import { isMobileScreenSize } from 'state/device/selectors';

// Local Dependencies
import PrivacyAndTermsLinks from './PrivacyAndTermsLinks';
import packageJson from '../../../package.json';

// Local Typings
interface FooterProps {
  hideLinks?: boolean;
  light?: boolean;
  showHelpLink?: boolean;
}

// Local Variables
const StyledFooter = styled.footer(({ theme }) => ({
  '.light': {
    color: theme.palette.link,
  },
  '.mobile': {
    flexDirection: 'column',
    paddingTop: 16,
  },
  '.topRow': {
    marginRight: 6,
    paddingBottom: 8,
  },

  [theme.breakpoints.down('md')]: {
    fontSize: 11,
  },
  alignContent: 'center',
  color: theme.palette.grey[900],
  display: 'flex',
  fontSize: 12,
  justifyContent: 'center',
  padding: theme.spacing(1),
  textAlign: 'center',
  zIndex: 1,
}));

// Component Definition
const Footer = ({
  hideLinks = false,
  light = false,
  showHelpLink = false,
}: FooterProps) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const { version } = packageJson;

  const versionNumberString = `v${version}`;

  return (
    <StyledFooter
      className={
        clsx(
          light && 'light',
          isMobileScreen && 'mobile',
        )
      }
    >
      <div className="topRow">
        &copy; {new Date().getFullYear()} {APP_NAME}.{' '}
        All Rights Reserved.{' '}
        {versionNumberString}
      </div>

      {showHelpLink && (
        <div>
          <StyledLink
            href={SUPPORT_SITE_HREF}
            openInNewTab
          >
            Help
          </StyledLink>
        </div>
      )}

      {!hideLinks && (
        <div>
          <PrivacyAndTermsLinks />
        </div>
      )}
    </StyledFooter>
  );
};

export default Footer;
