// External Dependencies
import {
  Button,
  DialogActions,
} from '@mui/material';
import {
  FC, ReactElement,
} from 'react';

// Internal Dependencies
import { DialogFileUploadContentProps } from 'components/shared/DialogFileUpload/DialogFileUploadContent';
import { SaveButton } from 'components/shared/index';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { useFileUploader } from 'components/shared/DialogFileUpload/hooks';
import { useFlatFileTypes } from 'hooks/useFlatFileTypes';

// Local Typings
export interface Props {
  acceptedFileTypes?: DialogFileUploadContentProps['acceptedFileTypes'];
  disabled?: boolean;
  id: string;
  isUploading: boolean;
  onClose: () => void;
  onFileUpload: DialogFileUploadContentProps['onFileUpload'];
  submitButton?: ReactElement | null;
}

// Component Definition
const DialogFileUploadActions: FC<Props> = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  disabled = false,
  id,
  isUploading,
  onClose,
  onFileUpload,
  submitButton,
}) => {
  const acceptedFileTypesArray = useFlatFileTypes(acceptedFileTypes);

  const {
    handleClickInput,
    hiddenInput,
  } = useFileUploader({
    acceptedFileTypes: acceptedFileTypesArray,
    disabled,
    inputId: id,
    onFileUpload,
  });

  return (
    <DialogActions>
      <Button
        color="primary"
        disabled={isUploading}
        onClick={onClose}
      >
        Cancel
      </Button>

      {submitButton || (
        <SaveButton
          disabled={disabled}
          isSaving={isUploading}
          onClick={handleClickInput}
        >
          Choose File
        </SaveButton>
      )}

      {hiddenInput}
    </DialogActions>
  );
};

export default DialogFileUploadActions;
