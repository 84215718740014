// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { FC, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowTopRightThickIcon from 'mdi-material-ui/ArrowTopRightThick';

// Internal Dependencies
import { DashboardCard, EnhancedIconButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, displayPriceStringFromDollarAmount, getFullName } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { isMobileOrTabletScreenSize as isMobileOrTableScreenSizeSelector } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';

// Local Typings
interface Props {
  usersWithHighestBalance: GQL.IUserWithBalance[] | undefined;
}

// Local Variables
const INITIAL_DISPLAY_COUNT = 4;

interface IHighestBalanceItemProps {
  canReadUsers: boolean;
  isMobileOrTabletScreenSize: boolean;
  user: GQL.IUserWithBalance;
}

const HighestBalanceUserListItem: FC<IHighestBalanceItemProps> = ({
  canReadUsers,
  isMobileOrTabletScreenSize,
  user,
}) => {
  const navigate = useNavigate();

  const handleNavigateToUser = (userId: string) => () => {
    const url = `/${PATHS.MEMBERS}/${userId}`;
    navigate(url);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
      }}
    >
      <Box>
        <Typography component="h4">{getFullName(user)}</Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>{displayPriceStringFromDollarAmount(
          convertCentsToDollars(user.balanceDueInCents),
        )}
        </Typography>
      </Box>

      {canReadUsers && (
        <Box>
          {isMobileOrTabletScreenSize ? (
            <EnhancedIconButton
              color="primary"
              icon={<ArrowTopRightThickIcon />}
              onClick={handleNavigateToUser(user.id)}
              tooltip="Go to user"
            />
          ) : (
            <Button
              color="primary"
              onClick={handleNavigateToUser(user.id)}
              size="small"
              variant="outlined"
            >
              View
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
// Component Definition
const UsersWithHighestBalanceCard: FC<Props> = ({
  usersWithHighestBalance,
}) => {
  const {
    isOpen: isFinancialStatementDialogOpen,
    toggleIsOpen: toggleIsFinancialStatementDialogOpen,
  } = useIsOpen();

  const isMobileOrTabletScreenSize = useSelector(isMobileOrTableScreenSizeSelector);
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const [showMore, setShowMore] = useState(false);

  const usersToShow = useMemo(() => {
    return showMore
      ? usersWithHighestBalance
      : usersWithHighestBalance?.slice(0, INITIAL_DISPLAY_COUNT);
  }, [usersWithHighestBalance, showMore]);

  return (
    <>
      <DashboardCard
        cardActions={usersToShow?.length ? (
          <Button
            color="primary"
            onClick={toggleIsFinancialStatementDialogOpen}
            size="small"
            variant="outlined"
          >
            Email statements
          </Button>
        ) : undefined}
        content={usersToShow?.length ? (
          <>
            <TransitionGroup component={List}>
              {usersToShow?.map((user) => {
                return (
                  <Collapse
                    component="li"
                    in
                    key={user.id}
                  >
                    <HighestBalanceUserListItem
                      canReadUsers={canReadUsers}
                      isMobileOrTabletScreenSize={isMobileOrTabletScreenSize}
                      key={user.id}
                      user={user}
                    />
                  </Collapse>
                );
              })}
            </TransitionGroup>
            {(usersWithHighestBalance?.length ?? 0) > INITIAL_DISPLAY_COUNT && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => setShowMore(!showMore)}
                  size="small"
                >
                  {showMore ? 'Show Less' : 'Show More'}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            No members with outstanding fees
          </Typography>
        )}
        title="Members with Highest Balance"
      />

      <SendFinancialStatementDialog
        isOpen={isFinancialStatementDialogOpen}
        memberIds={null}
        onClose={toggleIsFinancialStatementDialogOpen}
      />
    </>
  );
};

export default UsersWithHighestBalanceCard;
