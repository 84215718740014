// External Dependencies
import { FC } from 'react';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Typings
interface Props {
  context: string;
  previewData: {
    headers: string[];
    rows: string[][];
  }
}

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  '.MuiTableCell-head': {
    backgroundColor: theme.palette.dashboardOrgName,
  },
  '.container': {
    maxHeight: theme.spacing(30),
  },
  '.root': {
    width: '100%',
  },
  '.zeroState': {
    backgroundColor: theme.palette.mode === 'dark'
      ? darken(theme.palette.error.light, 0.5)
      : lighten(theme.palette.error.light, 0.9),
    margin: theme.spacing(1.5, 'auto'),
    padding: theme.spacing(2),
  },
}));

// Component Definition
const GoodRowTable: FC<Props> = ({
  context,
  previewData: {
    headers,
    rows,
  },
}) => {
  if (!rows.length) {
    return (
      <StyledSection>
        <Paper
          className="zeroState"
          variant="outlined"
        >
          <Box
            display="flex"
            gap={4}
          >
            <Typography sx={{ display: 'flex' }}>
              Oops! There&apos;s no valid data to import.
              Press the SHOW button to inspect the errors.
              You can update your data and try the import again.
            </Typography>
          </Box>
        </Paper>
      </StyledSection>
    );
  }

  return (
    <StyledSection>
      <Subtitle>Preview</Subtitle>

      <Paper
        className="root"
        variant="outlined"
      >
        <TableContainer className="container">
          <Table
            aria-label={`${context} preview table`}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headers.map((header: string) => (
                  <TableCell key={header}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row: any) => (
                <TableRow key={row}>
                  {row.map((cell: any, index: number) => (index === 0 ? (
                    <TableCell
                      component="th"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${cell}=${index}`}
                      scope="row"
                    >
                      {cell}
                    </TableCell>
                  ) : (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell key={`${cell}-${index}`}>{cell}</TableCell>
                  )))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </StyledSection>
  );
};

export default GoodRowTable;
