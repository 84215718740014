import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

export const UNIFORM_STYLES_QUERY = gql`
  query UniformStyleSelectOptions($organizationTypeId: ID!) {
    uniformStyles(organizationTypeId: $organizationTypeId) {
      id
      label
    }
  }
`;

export const useGetUniformStyles = (orgTypeId: string | undefined) =>
  useQueryEnhanced<{
    uniformStyles: GQL.IUniformStyle[];
  }>(UNIFORM_STYLES_QUERY, {
    skip: !orgTypeId,
    variables: { organizationTypeId: orgTypeId },
  });
