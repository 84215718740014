// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

interface UpdateData {
  updateFile: {
    id: string;
  }
}

const createManyFilesMutation = gql`
  mutation CreateManyFiles($input: CreateManyFilesInput!) {
    createManyFiles(input: $input)
  }
`;

const updateFileMutation = gql`
  mutation UpdateFile($id: ID!, $input: UpdateFileInput!) {
    updateFile(id: $id input: $input) {
      assignments {
        id
        group {
          id
          label
        }
        organizationRole {
          id
          label
        }
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
      directory
      endDate
      id
      fileName
      fileSize
      isPublic
      s3Key
      schoolYearEnding
      startDate
    }
  }
`;

const CREATE_FILE_PRESIGNED_URL = gql`
  mutation CreateFilePresignedUrl($input: CreatePresignedUrlInput!) {
    createFilePresignedUrl(
      input: $input
    ) {
      getUrl
      putUrl
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile ($id: ID!) {
    deleteFile(id: $id)
  }
`;

export const useCreateFiles = (onCompleted: () => void) => useMutationEnhanced(
  createManyFilesMutation,
  {
    clearCachePredicates: ['fileDirectories', 'filesIndex'],
    onCompleted,
  },
);

export const useCreateFilePresignedUrl = (
  options?: MutationOptions<
    { createFilePresignedUrl: GQL.IPresignedUrl },
    GQL.ICreateFilePresignedUrlOnMutationArguments
    >,
) =>
  useMutationEnhanced<
    { createFilePresignedUrl: GQL.IPresignedUrl },
    GQL.ICreateFilePresignedUrlOnMutationArguments
    >(CREATE_FILE_PRESIGNED_URL, options);

export const useUpdateFile = (onCompleted: () => void) =>
  useMutationEnhanced<
    UpdateData,
    GQL.IUpdateFileOnMutationArguments
  >(
    updateFileMutation,
    {
      clearCachePredicates: ['fileDirectories', 'filesIndex'],
      onCompleted,
    },
  );
