// External Dependencies
import {
  Bar,
  BarChart,
  LabelList,
  XAxis,
} from 'recharts';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  gradeCounts: GQL.IGradeCount[];
}

// Component Definition
const StudentMetrics = ({ gradeCounts }: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      common,
      stripeBlue,
    },
  } = useTheme();

  const handleClickBar = useCallback((data) => {
    const datagridFilters = {
      items: [{ field: 'grade', operator: 'is', value: data.grade }],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [''],
    };

    const queryString = `asc=true&orderBy=firstName&limit=10&page=1&dataGridFilters=${JSON.stringify(datagridFilters)}`;

    navigate(`/${PATHS.STUDENTS}?${queryString}`);
  }, [navigate]);

  return (
    <BarChart
      data={gradeCounts}
      height={180}
      width={300}
    >
      <XAxis
        axisLine={false}
        dataKey="grade"
        tickLine={false}
      />
      <Bar
        cursor="pointer"
        dataKey="count"
        fill={stripeBlue['500']}
        onClick={handleClickBar}
        textAnchor="center"
      >
        <LabelList
          dataKey="count"
          position="center"
          style={{ fill: common.white, fontSize: 18, fontWeight: 600 }}
        />
      </Bar>
    </BarChart>
  );
};

export default StudentMetrics;
