// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
  Flex,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  inventoryItem: GQL.IInventoryItem;
}

// Local Variables
const StyledSpan = styled.span(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

// Component Definition
const InventoryCheckoutDetails = ({
  inventoryItem,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { data } = useGetOrganization();

  const organizationId = data?.organization?.id;

  const ownsInventoryItem = inventoryItem?.organization?.id === organizationId;

  const handleNavigateToCreateCheckoutPage = useCallback(() => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/new?inventoryItem_id=${inventoryItem.id}`);
  }, [inventoryItem.id, navigate]);

  const handleNavigateToEditCheckoutPage = useCallback((checkoutId: string) => () => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/${checkoutId}/edit`);
  }, [navigate]);

  const activeCheckouts = inventoryItem.checkouts
    .filter((item) => item.active);

  const hasActiveCheckouts = activeCheckouts.length > 0;

  return (
    <EnhancedCard>
      <EnhancedCardContent>
        {activeCheckouts.map((checkout) => (
          <Flex
            justifyContent="space-between"
            key={checkout.id}
            sx={{ marginBottom: 2 }}
          >
            <Typography>
              Checked out to{' '}
              <StyledSpan>
                {getFullName(checkout.user)}
              </StyledSpan>
            </Typography>

            {(checkout.organization.id === organizationId || ownsInventoryItem) && (
              <Button
                color="primary"
                onClick={handleNavigateToEditCheckoutPage(checkout.id)}
                size="small"
                startIcon={<EditIcon />}
                variant="outlined"
              >
                Edit
              </Button>
            )}
          </Flex>
        ))}

        {!hasActiveCheckouts && (
          <Typography
            color="textSecondary"
            gutterBottom
          >
            No active checkouts
          </Typography>
        )}

        {hasActiveCheckouts && <Divider sx={{ marginBottom: 2 }} />}

        <Flex
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            onClick={handleNavigateToCreateCheckoutPage}
            size="small"
            startIcon={<AddIcon />}
            variant="contained"
          >
            New
          </Button>
        </Flex>
      </EnhancedCardContent>
    </EnhancedCard>
  );
};

export default InventoryCheckoutDetails;
