// External Dependencies
/* eslint-disable @typescript-eslint/no-unused-vars,no-unused-vars */
import { CopyAll } from '@mui/icons-material';
import { TrashCanOutline } from 'mdi-material-ui';
import { useCallback, useMemo } from 'react';
import ArrowDown from '@mui/icons-material/ArrowDownward';
import ArrowUp from '@mui/icons-material/ArrowUpward';
import Edit from '@mui/icons-material/Edit';
/* eslint-enable @typescript-eslint/no-unused-vars,no-unused-vars */

// Internal Dependencies
import ActionsIconMenu, { MoreActionsItem } from 'components/shared/ActionsIconMenu';

// Local Typings
interface Props {
  block: GQL.IFormBlock;
  formId: string;
  setDeleteBlockId: (blockId: string) => void;
}

// Component Definition
const BlockCardMenu = ({
  block,
  formId,
  setDeleteBlockId,
}: Props): JSX.Element | null => {
  const blockId = block.id;

  const handleClickDelete = useCallback(() => {
    setDeleteBlockId(blockId);
  }, [blockId, setDeleteBlockId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const handleClickEdit = useCallback(() => {
    console.log('Edit', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const handleClickMoveUp = useCallback(() => {
    console.log('Move Up', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const handleClickMoveDown = useCallback(() => {
    console.log('Move Down', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const handleClickDuplicate = useCallback(() => {
    console.log('Duplicate', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  const moreActions = useMemo<MoreActionsItem[]>(() => {
    return [
      // {
      //   action: handleClickMoveUp,
      //   icon: <ArrowUp />,
      //   isDisabled: true,
      //   text: 'Move Up',
      // },
      // {
      //   action: handleClickMoveDown,
      //   icon: <ArrowDown />,
      //   isDisabled: true,
      //   text: 'Move Down',
      // },
      // {
      //   action: handleClickDuplicate,
      //   icon: <CopyAll />,
      //   isDisabled: true,
      //   text: 'Duplicate',
      // },
      // {
      //   action: handleClickEdit,
      //   icon: <Edit />,
      //   isDisabled: true,
      //   text: 'Edit',
      // },
      ...(block.isFixed ? [] : [{
        action: handleClickDelete,
        icon: <TrashCanOutline />,
        text: 'Delete',
      }]),
    ];
  }, [
    block.isFixed,
    handleClickDelete,
    // handleClickDuplicate,
    // handleClickEdit,
    // handleClickMoveDown,
    // handleClickMoveUp,
  ]);

  if (!moreActions.length) {
    return null;
  }

  return (
    <ActionsIconMenu
      moreActions={moreActions}
      tooltipTitle="Block Actions"
    />
  );
};

export default BlockCardMenu;
