// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  addOnePaymentId,
  clearPaymentIds,
  removePaymentId,
  updatePaymentIds,
} from './actions';

// Local Typings
interface SinglePaymentAction {
  payload: string;
  type: string;
}

interface MultiplePaymentsAction {
  payload: string[];
  type: string;
}

export interface PaymentPickerDepositFormState {
  paymentIds: string[];
}

// Reducers
const PAYMENT_IDS_INITIAL_STATE: PaymentPickerDepositFormState['paymentIds'] = [];
const paymentIds = createReducer(PAYMENT_IDS_INITIAL_STATE, (handleAction) => [
  handleAction(
    addOnePaymentId,
    (state, { payload }: SinglePaymentAction) => [...state, payload],
  ),
  handleAction(
    updatePaymentIds,
    (state, { payload }: MultiplePaymentsAction) => [...payload],
  ),
  handleAction(removePaymentId, (state, { payload }: SinglePaymentAction) => {
    if (state.length > 0) {
      return state.filter((id) => payload !== id);
    }
    return state;
  }),
  handleAction([
    clearPaymentIds,
    logoutCurrentUser,
  ], () => PAYMENT_IDS_INITIAL_STATE),
]);

export default combineReducers<PaymentPickerDepositFormState>({
  paymentIds,
});
