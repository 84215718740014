// External Dependencies
import { Button } from '@mui/material';
import {
  FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { closeDialogSuccess } from 'state/ui/successDialog/actions';

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },

  borderRadius: 100, // a high number that will ensure round corners regardless of button height
  fontSize: 18,
  padding: theme.spacing(1, 0),
  width: 150,
}));

// Component Definition
const CloseButton: FC = () => {
  const dispatch = useDispatch();

  const handleClickDone = useCallback(() => {
    dispatch(closeDialogSuccess());
  }, [dispatch]);

  return (
    <StyledButton
      onClick={handleClickDone}
      variant="contained"
    >
      Close
    </StyledButton>
  );
};

export default CloseButton;
