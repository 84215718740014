// External Dependencies
import {
  Box, Button, Collapse, Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import styled from 'styled-components';

// Internal Dependencies
import {
  DialogPeoplePicker,
  ShowCard,
  StyledLink,
} from 'components/shared';
import { StyledStrong } from 'components/shared/StyledStrong';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';
import { pluralize } from 'utils';

// Local Typings
interface Props {
  arrayHelpers: FieldArrayRenderProps;
}

// Local Variables
const EnhancedStyledLink = styled(StyledLink)({
  fontSize: 15,
});

// Component Definition
const ChecklistMembersFormUI: FC<Props> = ({ arrayHelpers }) => {
  const dispatch = useDispatch();

  const { memberIds } = arrayHelpers.form.values;

  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const handlePressSelectMembers = () => {
    dispatch(openPeoplePickerDialog());
  };

  const addMembersToForm = useCallback((updatedChecklistMemberIds: string[]) => {
    arrayHelpers.form.setFieldValue('memberIds', updatedChecklistMemberIds);

    // We set the field to "touched" so that the form can be submitted
    // The `EditChecklistMembersForm` component is set to ignore
    //  the mutation if the form is not touched.
    arrayHelpers.form.setFieldTouched('memberIds', true);
  }, [arrayHelpers]);

  const hasChecklistMembers = memberIds.length > 0;

  return (
    <>
      <Box mb={2}>
        <ShowCard
          canEdit
          icon={PeopleAltIcon}
          title="Checklist Members"
        >
          <Collapse in={hasChecklistMembers}>
            <Typography>
              There {pluralize(memberIds.length, 'is', 'are')}{' '}
              <StyledStrong>
                {memberIds.length}
              </StyledStrong>{' '}
              {pluralize(memberIds.length, 'member')}{' '}
              assigned to this checklist.
            </Typography>

            <Box
              mb={1}
              mt={3}
            >
              <EnhancedStyledLink onClick={handlePressSelectMembers}>
                View/Update Members
              </EnhancedStyledLink>
            </Box>
          </Collapse>

          <Collapse in={!hasChecklistMembers}>
            <Box
              display="flex"
              flexDirection="column"
            >
              <Typography>Which members should be assigned to this checklist?</Typography>

              <Button
                color="primary"
                onClick={handlePressSelectMembers}
                size="small"
                sx={{ alignSelf: 'flex-end', marginTop: 2 }}
                variant="outlined"
              >
                Select Members
              </Button>
            </Box>
          </Collapse>
        </ShowCard>
      </Box>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={addMembersToForm}
        preSelectedIds={memberIds}
        tableResource="peoplePickerChecklistMembers"
        title="Select Members"
      />
    </>
  );
};

export default ChecklistMembersFormUI;
