// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';

// Internal Dependencies
import { Flex } from 'components/shared';

// Local Typings
interface Props {
  body: GQL.IEmailShow['body'];
  scheduledSendAt: GQL.IEmailShow['scheduledSendAt'];
  sentAt: GQL.IEmailShow['sentAt'];
  submittedAt: GQL.IEmailShow['submittedAt'];
}

const renderDateOrEmpty = (date: string) => moment(date).format('llll');

const removeScripts = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const scripts = doc.querySelectorAll('script');
  scripts.forEach((script) => script.remove());
  return doc.body.innerHTML;
};

// Component Definition
const EmailBody: FC<Props> = ({
  body,
  scheduledSendAt,
  sentAt,
  submittedAt,
}) => (
  <>
    <Flex
      alignContent="flex-end"
      alignItems="flex-end"
      flexDirection="column"
    >
      {submittedAt && (
        <Typography
          component="p"
          variant="caption"
        >
          Submitted at {renderDateOrEmpty(submittedAt)}
        </Typography>
      )}

      {scheduledSendAt && !sentAt && (
        <Typography
          component="p"
          variant="caption"
        >
          Scheduled to send at {renderDateOrEmpty(scheduledSendAt)}
        </Typography>
      )}

      {sentAt && (
        <Typography
          component="p"
          variant="caption"
        >
          Sent at {renderDateOrEmpty(sentAt)}
        </Typography>
      )}
    </Flex>

    <Box
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: removeScripts(body),
      }}
      sx={{
        '& strong': { fontWeight: 'bold' },
      }}
    />
  </>
);

export default EmailBody;
