// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface UpdateDistrictInvationData {
  updateDistrictInvitation: {
    id: string;
  }
}

export const CREATE_DISTRICT_INVITATION = gql`
  mutation CreateDistrictInvitation(
    $email: String!
  ) {
    createDistrictInvitation(
      email: $email
    ) {
      id
    }
  }
`;

export const RESEND_DISTRICT_INVITATION = gql`
  mutation ResendDistrictInvitation(
    $invitationId: ID!
  ) {
    resendDistrictInvitation(
      invitationId: $invitationId
    ) {
      id
    }
  }
`;

export const UPDATE_DISTRICT_INVITATION = gql`
  mutation ResendDistrictInvitation(
    $accepted: Boolean!
    $activationCode: String
    $invitationId: ID!
  ) {
    updateDistrictInvitation(
      accepted: $accepted
      activationCode: $activationCode
      invitationId: $invitationId
    ) {
      id
    }
  }
`;

export const useUpdateDistrictInvitation = (
  options?: MutationOptions<
    UpdateDistrictInvationData,
    GQL.IUpdateDistrictInvitationOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateDistrictInvationData,
  GQL.IUpdateDistrictInvitationOnMutationArguments
>(
  UPDATE_DISTRICT_INVITATION,
  options,
);
