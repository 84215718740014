// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarArrowRightIcon from 'mdi-material-ui/CalendarArrowRight';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  hasStartedEndOfYearProcess: boolean;
}

// Component Definition
const EndOfYearProcessCard = ({ hasStartedEndOfYearProcess }: Props): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      stripeBlue,
    },
  } = useTheme();

  const handleNavigateToEndOfYearProcess = useCallback(() => {
    navigate(`/${PATHS.END_OF_YEAR}`);
  }, [navigate]);

  const iconElement = (
    <ZeroStateIcon
      Icon={CalendarArrowRightIcon}
      htmlColor={stripeBlue['400']}
    />
  );

  const buttonText = hasStartedEndOfYearProcess
    ? 'Continue End of Year Process'
    : 'Begin End of Year Process';

  const content = (
    <>
      {iconElement}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          It&apos;s time to prepare for the next school year!
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToEndOfYearProcess}
        size="small"
        variant="outlined"
      >
        {buttonText}
      </Button>
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="End of Year Process"
      verticallyCenterContent
    />
  );
};

export default EndOfYearProcessCard;
