// External Dependencies
import { FC, useState } from 'react';
import { Paper } from '@mui/material';
import clsx from 'clsx';
import styled from 'styled-components';

// Local Dependencies
import {
  DiscardConfirmDialog,
  FormActionButtons,
} from 'components/shared';

// Local Typings
interface Props {
  context: string;
  isEditing?: boolean;
  isFormTouched: boolean;
  isSubmitting: boolean;
  onPressCancelOrBackButton: () => void;
}

// Local Variables
const StyledPaper = styled(Paper)(({ theme }) => ({
  '.buttonContainerLoading': {
    padding: theme.spacing(2),
  },
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
}));

// Component Definition
const FormRouteActions: FC<Props> = ({
  context,
  isEditing = false,
  isFormTouched,
  isSubmitting,
  onPressCancelOrBackButton,
}) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const handlePressCancelButton = () => {
    if (isFormTouched) {
      setIsCancelDialogOpen(true);
    } else {
      onPressCancelOrBackButton();
    }
  };

  const handleRequestClose = () => {
    setIsCancelDialogOpen(false);
  };

  return (
    <>
      <StyledPaper
        className={clsx(isSubmitting && 'buttonContainerLoading')}
        variant="outlined"
      >
        <FormActionButtons
          context={context}
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          onClickCancelButton={handlePressCancelButton}
        />
      </StyledPaper>

      <DiscardConfirmDialog
        isOpen={isCancelDialogOpen}
        onConfirm={onPressCancelOrBackButton}
        onRequestClose={handleRequestClose}
      />
    </>
  );
};

export default FormRouteActions;
