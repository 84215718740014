// External Dependencies
import { useGetDashboardMetrics } from 'gql/queries';

// Local Dependencies
import GradeCountsCard from './GradeCountsCard';
import UserOrgRoleCountsCard from './UserOrgRoleCountsCard';
import UserRolesMetricsCard from './UserRolesMetricsCard';

// Component Definition
const MetricsWrapper = (): JSX.Element | null => {
  const { data } = useGetDashboardMetrics();

  if (!data) {
    return null;
  }

  const {
    gradeCounts,
    userOrgRoleCounts,
    userRoleCounts,
  } = data.dashboardMetrics;

  return (
    <>
      <UserRolesMetricsCard userRoleCounts={userRoleCounts} />
      <GradeCountsCard gradeCounts={gradeCounts} />
      <UserOrgRoleCountsCard userOrgRoleCounts={userOrgRoleCounts} />
    </>
  );
};

export default MetricsWrapper;
