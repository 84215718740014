// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Component Definition
const JoinNewOrganizationSuccess = (): JSX.Element => {
  const navigate = useNavigate();
  const { formType, organization } = useParsedSearch();

  const handleClickBackToDashboard = useCallback(() => {
    navigate(`/${PATHS.DASHBOARD}`);
  }, [navigate]);

  return (
    <Container maxWidth="md">
      <Subtitle>
        Join an organization
      </Subtitle>

      <EnhancedCard>
        <CardContent>
          <Typography gutterBottom>
            You successfully joined {organization ? (<strong>{organization}</strong>) : 'your organization'}!
          </Typography>

          <Typography>
            {formType === 'joining'
              ? 'Your request to join this organization is under review.'
              : 'You can now switch to this organization in the account menu found in the upper right corner.'}
          </Typography>

          <Box marginTop={2}>
            <Button
              color="primary"
              onClick={handleClickBackToDashboard}
              variant="contained"
            >
              Back to Dashboard
            </Button>
          </Box>
        </CardContent>
      </EnhancedCard>
    </Container>
  );
};

export default JoinNewOrganizationSuccess;
