// Internal Dependencies
import { APP_NAME } from './app';

// Constant Defintions
export const EMAIL = {
  MAX_ATTACHMENT_SIZE: 10,
  MAX_CHARACTER_LENGTH: 10000,
};

export const ONE_MB = 1048576;
export const ONE_MB_ROUND = 1000000;

export const underThirteenMessage = `
  Students under 13 without parental authorization to use ${APP_NAME}
  will not receive emails. Their parents will receive emails on their behalf.
`;
