// External Dependencies
import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { ALERT_IDS } from 'utils/constants/bannerAlerts';
import { APP_NAME } from 'utils/constants';
import { DialogIntegration, StyledLink } from 'components/shared';
import { GET_ORGANIZATION } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { UPDATE_GOOGLE_CALENDAR_TOKEN } from 'gql/mutations';
import { getDefaultErrorMessage } from 'utils/lib/graphql_errors';
import { parseSearch } from 'utils';
import { removeBannerAlert } from 'state/bannerAlerts/actions';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Component Definition
const GoogleCalendarVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isGoogleCalDialogOpen, setIsGoogleCalDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGoogleCalCodeStored, setIsGoogleCalCodeStored] = useState(false);
  const [googleCalCodeFromUrl, setGoogleCalCCodeFromUrl] = useState('');

  const handleNavigateToCalendar = useCallback(() => {
    navigate(`/${PATHS.CALENDAR}`);
  }, [navigate]);

  useEffect(() => {
    setIsGoogleCalDialogOpen(true);

    // Get the code from the url param
    const { search } = window.location;
    const parsedSearch = parseSearch(search);
    const { code } = parsedSearch;
    setGoogleCalCCodeFromUrl(code);
  }, []);

  const showError = (error: ApolloError) => {
    const fallbackErrorMessage = 'Something went wrong while syncing with Google Calendar.';

    setErrorMessage(getDefaultErrorMessage(error, fallbackErrorMessage));
  };

  const showSuccess = () => {
    setIsGoogleCalCodeStored(true);
  };

  const handleCloseDialog = () => {
    setIsGoogleCalDialogOpen(false);
    navigate(`/${PATHS.DASHBOARD}`);
  };

  const [updateGoogleCredentials] = useMutationEnhanced(UPDATE_GOOGLE_CALENDAR_TOKEN, {
    awaitRefetchQueries: true,
    onCompleted: showSuccess,
    onError: showError,
    refetchQueries: [{ query: GET_ORGANIZATION }],
  });

  useEffect(() => {
    const saveCode = async (code: {}) => {
      const variables = { code };
      try {
        await updateGoogleCredentials({ variables });
        dispatch(removeBannerAlert(ALERT_IDS.NO_G_CAL_ALERT));
      } catch (error) {
        console.log(
          'There was an error adding the Google Calendar verification data.',
        );
        console.error({ error });
      }
    };

    if (googleCalCodeFromUrl) {
      saveCode(googleCalCodeFromUrl);
    }
  }, [googleCalCodeFromUrl, dispatch, updateGoogleCredentials]);

  if (!googleCalCodeFromUrl) {
    return null;
  }

  const successDescription = (
    <>
      By default, no calendars for the synced Google Calendar account are
      viewable to {APP_NAME} members of your organization. Visit the
      {' '}
      <StyledLink onClick={handleNavigateToCalendar}>Calendar page</StyledLink>
      {' '}
      to update these settings.
    </>
  );

  return (
    <DialogIntegration
      context="Google Calendar"
      errorMessage={errorMessage}
      isCodeStored={isGoogleCalCodeStored}
      isDialogOpen={isGoogleCalDialogOpen}
      onCloseDialog={handleCloseDialog}
      successDescription={successDescription}
    />
  );
};

export default GoogleCalendarVerification;
