// External Dependencies
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, Fragment } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Local Dependencies
import { getMomentDateFromString } from 'utils/lib/date_helpers';
import UserAvatar, { UserAvatarIconName } from './UserAvatar';

// Local Typings
export interface InvitationListItem {
  avatar: UserAvatarIconName;
  id: string;
  invitationTimestamp: string;
  inviterEmail: string;
  label: string;
  status: 'confirmed' | 'rejected' | 'pending';
}
interface Props {
  itemUpdatingId: string | null;
  listItems: InvitationListItem[];
  onConfirm: (id: string) => () => void;
  onReject: (id: string) => () => void;
  width: number;
}

// Local Variables
const StyledList = styled(List)(({ theme }) => ({
  '.actionContainer': {
    transform: `translateX(${theme.spacing(1.5)})`,
  },
  '.actionContainerSmall': {
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateX(0)',
  },
  '.altText': {
    fontFamily: 'BioRhyme',
    fontWeight: 500,
    letterSpacing: '1px',
    transform: 'rotate(-6deg)',
  },
  '.button': {
    margin: theme.spacing(4),
  },
  '.confirmedText': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      transform: 'rotate(-6deg)',
    },
    color: theme.palette.success.dark,
  },
  '.iconButton': {
    [theme.breakpoints.down('sm')]: {
      padding: 6,
    },
  },
  '.listItemText': {
    maxWidth: '64%',
  },
  '.rejectedText': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      transform: 'rotate(-6deg)',
    },
    color: theme.palette.error.dark,
    transform: 'rotate(-6deg) translateX(-16px)',
  },
  '.secondaryTop': {
    // We are setting a `<span>` to block to avoid HTML warnings
    //  since a `<p>` cannot have a nested `<div>`
    display: 'block',
    lineHeight: 1.6,
  },
}));

// Component Definition
const ConfirmInvitationList: FC<Props> = ({
  itemUpdatingId,
  listItems,
  onConfirm,
  onReject,
  width,
}) => {
  const theme = useTheme();

  return (
    <StyledList>
      {listItems.map((listItem, index, arr) => {
        const {
          avatar,
          id,
          invitationTimestamp,
          inviterEmail,
          label,
          status,
        } = listItem;

        const showProgressElement = itemUpdatingId === id;

        return (
          <Fragment key={id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <UserAvatar iconName={avatar} />
              </ListItemAvatar>

              <ListItemText
                className="listItemText"
                primary={label}
                secondary={(
                  <Box
                    component="span"
                    pt={0.5}
                  >
                    <Typography
                      className="secondaryTop"
                      color="textPrimary"
                      component="span"
                      variant="body2"
                    >
                      {inviterEmail}
                    </Typography>
                    {getMomentDateFromString(invitationTimestamp).format('M/D/YYYY, h:mm a')}
                  </Box>
                )}
              />

              <ListItemSecondaryAction id="list-item">
                {showProgressElement && (
                  <CircularProgress
                    className="button"
                    size={24}
                    thickness={6}
                  />
                )}

                {status === 'pending' && !showProgressElement && (
                  <div
                    className={clsx(
                      'actionContainer',
                      width < 400 && 'actionContainerSmall',
                    )}
                  >
                    <IconButton
                      aria-label="cancel"
                      className="iconButton"
                      disabled={!!itemUpdatingId}
                      onClick={onReject(id)}
                      size="large"
                    >
                      <CancelIcon htmlColor={theme.palette.error.main} />
                    </IconButton>
                    <IconButton
                      aria-label="confirm"
                      className="iconButton"
                      disabled={!!itemUpdatingId}
                      onClick={onConfirm(id)}
                      size="large"
                    >
                      <CheckCircleIcon htmlColor={theme.palette.success.main} />
                    </IconButton>
                  </div>
                )}
                {status === 'confirmed' && !showProgressElement && (
                  <Typography
                    className="altText confirmedText"
                    variant="subtitle1"
                  >
                    Confirmed!
                  </Typography>
                )}
                {status === 'rejected' && !showProgressElement && (
                  <Typography
                    className="altText rejectedText"
                    variant="subtitle1"
                  >
                    Rejected!
                  </Typography>
                )}
              </ListItemSecondaryAction>
            </ListItem>

            {arr.length > (index + 1) && (
              <Divider
                component="li"
                variant="inset"
              />
            )}
          </Fragment>
        );
      })}
    </StyledList>
  );
};

export default ConfirmInvitationList;
