// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { useEffect } from 'react';
import { useLazyQueryEnhanced, useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface FilesIndexResponse {
  filesIndex: GQL.IFileIndexItemAll;
}
interface FilesIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
}
interface FilesDirectoriesVariables {
  queryParams?: GQL.IIndexQueryParams;
}
interface FileDirectoriesResponse {
  fileDirectories: GQL.IFileDirectoryIndexAll;
}
interface MyFilesDirectoriesVariables {
  queryParams?: GQL.IIndexQueryParams;
}
interface MyFileDirectoriesResponse {
  myFilesIndex: GQL.IMyFilesIndexAll;
}
interface MyFileSignedUrlVariables {
  fileId: string;
  userId: string | null;
}

export const GET_FILES_INDEX = gql`
  query FilesIndex(
    $directory: String!
    $queryParams: IndexQueryParams
  ) {
    filesIndex(
      directory: $directory
      queryParams: $queryParams
    ) {
      data {
        createdAt
        createdBy
        createdById
        fileName
        fileSize
        id
        isPublic
      }
      fullCount
    }
  }
`;
export const GET_MY_FILES_INDEX = gql`
  query MyFilesIndex(
    $queryParams: IndexQueryParams
    $schoolYearEnding: Int
    $userId: ID!
  ) {
    myFilesIndex(
      queryParams: $queryParams
      schoolYearEnding: $schoolYearEnding
      userId: $userId
    ) {
      data {
        createdAt
        createdByName
        endDate
        id
        fileName
        fileSize
        startDate
      }
      fullCount
    }
  }
`;

export const GET_FILE_DIRECTORIES = gql`
  query FileDirectories($queryParams: IndexQueryParams) {
    fileDirectories(queryParams: $queryParams) {
      data {
        directory
        fileCount
        id
      }
      fullCount
    }
  }
`;

const GET_FILE = gql`
  query File($id: ID!) {
    file(id: $id) {
      assignments {
        id
        group {
          id
          label
        }
        organizationRole {
          id
          label
        }
      }
      createdBy {
        id
        email
        firstName
        lastName
      }
      directory
      endDate
      id
      fileName
      fileSize
      isPublic
      s3Key
      schoolYearEnding
      startDate
    }
  }
`;

const GET_FILE_SIGNED_URL = gql`
  query FileSignedUrl($id: ID!) {
    fileSignedUrl(id: $id)
  }
`;

const GET_MY_FILE_SIGNED_URL = gql`
  query MyFileSignedUrl(
    $fileId: ID!
    $userId: ID!
  ) {
    myFileSignedUrl(
      fileId: $fileId
      userId: $userId
    )
  }
`;

export const useGetFilesIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  directory: string,
): [
    (
    ) => void,
    LazyQueryResult<FilesIndexResponse, FilesIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<FilesIndexResponse, FilesIndexVariables>({
    getAdditionalVariables: () => ({ directory }),
    gqlQuery,
    options,
    tableResource: 'files',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetFilesIndex = (directory: string) => {
  const [query, values] = useGetFilesIndexItemsQuery(
    GET_FILES_INDEX,
    { exportReport: false },
    directory,
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetFilesDirectoriesQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
): [
    (
    ) => void,
    LazyQueryResult<FileDirectoriesResponse, FilesDirectoriesVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<FileDirectoriesResponse, FilesDirectoriesVariables>({
    gqlQuery,
    options: { exportReport: false },
    tableResource: 'fileDirectories',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetFilesDirectoriesIndex = () => {
  const [query, values] = useGetFilesDirectoriesQuery(GET_FILE_DIRECTORIES);

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetMyFilesIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  schoolYearEnding: number | null,
  userId: string | null,
): [
    (
    ) => void,
    LazyQueryResult<MyFileDirectoriesResponse, MyFilesDirectoriesVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    MyFileDirectoriesResponse,
    MyFilesDirectoriesVariables
  >({
    getAdditionalVariables: () => ({
      schoolYearEnding,
      userId,
    }),
    gqlQuery,
    options,
    tableResource: 'myFiles',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetMyFilesIndex = (
  schoolYearEnding: number | null,
  userId: string | null,
) => {
  const [query, values] = useGetMyFilesIndexItemsQuery(
    GET_MY_FILES_INDEX,
    { exportReport: false },
    schoolYearEnding,
    userId,
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetFile = (id: string) =>
  useQueryEnhanced<{ file: GQL.IFile }>(GET_FILE, {
    variables: {
      id,
    },
  });

export const useGetFileSignedUrl = () => useLazyQueryEnhanced<
  { fileSignedUrl: string },
  { id: string }
>(GET_FILE_SIGNED_URL, {
  fetchPolicy: 'network-only',
  onCompleted: (data) => {
    window.open(data.fileSignedUrl);
  },
});

export const useGetMyFileSignedUrl = () => useLazyQueryEnhanced<
  { myFileSignedUrl: string },
  MyFileSignedUrlVariables
>(GET_MY_FILE_SIGNED_URL, {
  fetchPolicy: 'network-only',
  onCompleted: (data) => {
    window.open(data.myFileSignedUrl);
  },
});
