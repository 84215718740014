// External Dependencies
import { FC } from 'react';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { AdultData } from 'gql/queries';
import { ShowPageDataDisplay } from 'components/shared';
import { getFullName } from 'utils';

// Local Typings
interface Props {
  isDirector?: boolean;
  userData: AdultData | undefined;
}

// Component Definition
const ShowAdultProfileData: FC<Props> = ({
  isDirector = false,
  userData,
}) => {
  if (!userData) {
    return null;
  }

  const {
    allergies,
    dateOfBirth,
    gender,
    primarySpokenLanguage,
    shirtSize,
  } = userData;

  return (
    <>
      <ShowPageDataDisplay
        label="Name"
        value={getFullName(userData)}
      />
      {isDirector && (
        <ShowPageDataDisplay
          label="Title"
          value={startCase(userData.title ?? '')}
        />
      )}
      <ShowPageDataDisplay
        label="Gender"
        value={startCase(gender?.label ?? '')}
      />
      <ShowPageDataDisplay
        label="Date of Birth"
        type="date"
        value={dateOfBirth}
      />
      <ShowPageDataDisplay
        label="Primary Spoken Language"
        value={primarySpokenLanguage?.label}
      />
      <ShowPageDataDisplay
        label="Shirt Size"
        value={shirtSize}
      />
      <ShowPageDataDisplay
        label="Allergies"
        value={allergies}
      />
    </>
  );
};

export default ShowAdultProfileData;
