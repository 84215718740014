// External Dependencies
import { OrganizationEntityTypes } from '@presto-assistant/api_types';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';

// Hook Definition
export const useIsCollegeOrUniversity = () => {
  const { data: organizationData } = useGetOrganization();

  const isCollegeOrUniversity = organizationData?.organization
    .entityType.id === OrganizationEntityTypes.College.toString();

  return isCollegeOrUniversity;
};
