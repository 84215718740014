// External Dependencies
import {
  Box,
  CardContent,
  Typography,
} from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer/polyfilled';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Typings
interface ChildrenProps {
  width: number;
}
interface Props {
  children: (props: ChildrenProps) => any;
  description: string;
  title?: string;
}

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  '.cardContent': {
    '&:last-child': {
      paddingBottom: 0,
    },
    padding: 0,
  },
  '.description': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(1.5),
    },
    margin: '16px 24px',
  },
  '.subtitle': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
      margin: theme.spacing(1.5),
    },
    margin: '16px 0 12px 12px',
    textAlign: 'left',
  },
}));

// Component Definition
const ConfirmInvitationListContainer: FC<Props> = ({
  children,
  description,
  title = 'Confirm',
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const {
    ref,
    width = 1,
  } = useResizeObserver();

  useEffect(() => {
    const locationHash = window.location.hash;

    // If the user was sent here to confirm an invitation
    //  from a district admin, we scroll the element into view
    if (locationHash === '#confirm-container' && titleRef.current) {
      titleRef.current.scrollIntoView();
    }
  }, [titleRef.current]);

  return (
    <StyledBox>
      <Typography
        component="h3"
        className="subtitle"
        id="confirm-container"
        ref={titleRef}
        variant="h6"
      >
        {title}
      </Typography>

      <EnhancedCard ref={ref}>
        <CardContent className="cardContent">
          <Typography
            className="description"
            variant="subtitle1"
          >
            {description}
          </Typography>

          {children({ width })}
        </CardContent>
      </EnhancedCard>
    </StyledBox>
  );
};

export default ConfirmInvitationListContainer;
