// External Dependencies
import { FC } from 'react';
import { ImportResources } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { close } from 'state/ui/inventoryFileUploadDialog/actions';
import {
  isOpen as isInventoryFileUploadDialogOpenSelector,
} from 'state/ui/inventoryFileUploadDialog/selectors';
import { useGetGettingStartedCounts } from 'gql/queries';
import CSVImporter from 'components/CSVImporter';

// Component Definition
const DialogInventoryFileUpload: FC = () => {
  const dispatch = useDispatch();

  const isInventoryFileUploadDialogOpen = useSelector(
    isInventoryFileUploadDialogOpenSelector,
  );

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneInventoryItem = (gettingStartedCountsData?.dashboardMetrics?.inventoryCount ?? 0) > 0;

  const handleCloseDialogInventoryFileUpload = () => {
    dispatch(close());
  };

  return (
    <CSVImporter
      canMigrateFromAnotherSystem
      context="inventory"
      csvFileInfoResource="inventoryItems"
      dialogTitle="Add Inventory Items"
      importResourceId={ImportResources.Inventory}
      insertMutationName="insertInventoryCsvFileToDB"
      insertMutationOptions={{
        clearCachePredicates: [
          'inventoryItems',
          ...!hasAtLeastOneInventoryItem ? ['dashboardMetrics'] : [],
        ],
      }}
      isOpen={isInventoryFileUploadDialogOpen}
      onClose={handleCloseDialogInventoryFileUpload}
      uploadMutationName="csvInventoryUpload"
    />
  );
};

export default DialogInventoryFileUpload;
