const checkForStripeId = (self: GQL.ISelf) => {
  const {
    currentOrgId,
    id: memberId,
    userOrgData,
  } = self;

  const currentUserOrg = userOrgData
    .find((uo) => uo.organization.id === currentOrgId && uo.member?.id === memberId);

  return currentUserOrg?.organization.hasStripeId;
};

export default checkForStripeId;
