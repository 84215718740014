// External Dependencies
import { useTheme } from 'styled-components';

function VancoLogoSvg(props: any) {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const fillColor = isDarkMode ? theme.palette.text.primary : '#312852';

  return (
    <svg
      fill="none"
      viewBox="0 0 297 81"
      xmlSpace="preserve"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g clipPath="url(#clip0_36_97)">
        <path
          d="M84.5558 16.8123C84.2283 16.2332 83.7321 15.7678 83.1333 15.478C82.7433 15.2833 82.317 15.1719 81.8816 15.1508C81.1214 15.1203 80.3751 15.3613 79.7764 15.8308C79.4245 16.0926 79.1334 16.4274 78.9229 16.8123L51.9307 65.1753H62.0954C62.7175 65.181 63.3302 65.0238 63.8727 64.7194C64.4152 64.4149 64.8686 63.9738 65.1878 63.4399L68.9203 56.6691H94.5811L98.3136 63.4399C98.628 63.9747 99.0766 64.4182 99.615 64.7266C100.153 65.0349 100.763 65.1974 101.383 65.198H111.548L84.5558 16.8123ZM73.6172 48.2567L81.7507 33.6881L89.8985 48.2567H73.6172Z"
          fill={fillColor}
        />
        <path
          d="M30.6622 46.6551L26.5883 39.3409L14.0964 16.9004C13.7829 16.3654 13.335 15.9217 12.797 15.6133C12.259 15.3049 11.6498 15.1425 11.0297 15.1423H0.853516C0.853516 15.1423 27.9311 63.6333 27.9709 63.6931C28.2603 64.1456 28.6579 64.5189 29.1278 64.7792C29.5977 65.0395 30.1251 65.1785 30.6622 65.1838C31.1993 65.1785 31.7267 65.0395 32.1966 64.7792C32.6665 64.5189 33.0641 64.1456 33.3535 63.6931C33.3904 63.6333 33.4331 63.5764 33.4672 63.5138C33.4672 63.5138 35.0092 60.8653 36.7474 57.7501L30.6622 46.6551Z"
          fill={fillColor}
        />
        <path
          d="M268.653 13.9389H268.628C253.302 13.9389 242.148 24.9316 242.099 40.0919C242.054 55.4344 252.935 66.188 268.557 66.2421H268.707C284.332 66.188 295.213 55.4315 295.165 40.0919C295.134 24.9316 283.982 13.9389 268.653 13.9389ZM268.653 56.0204C259.738 55.9578 253.194 49.3805 253.112 40.2968C253.069 35.799 254.631 31.7081 257.516 28.7778C260.329 25.9329 264.25 24.3313 268.557 24.3057H268.725C273.032 24.3313 276.952 25.9187 279.766 28.7778C282.65 31.7081 284.212 35.799 284.169 40.2968C284.078 49.3805 277.561 55.9578 268.642 56.0204H268.653Z"
          fill={fillColor}
        />
        <path
          d="M166.491 15.1423C165.609 15.1423 164.763 15.4927 164.139 16.1164C163.516 16.7401 163.165 17.586 163.165 18.468V44.6637C163.165 44.6637 129.442 16.9317 128.711 16.3571C128.245 15.9253 127.713 15.5709 127.135 15.3073C126.447 15.0574 125.689 15.0799 125.017 15.3702C124.345 15.6606 123.809 16.1968 123.519 16.8692C123.36 17.3333 123.301 17.8258 123.345 18.3144V65.1639H131.172C132.054 65.1639 132.9 64.8135 133.523 64.1898C134.147 63.5661 134.497 62.7202 134.497 61.8382V34.1376L171.546 65.1639H174.218V15.1423H166.491Z"
          fill={fillColor}
        />
        <path
          d="M227.403 51.6393L227.138 51.9238C227.098 51.9665 223.007 56.1143 215.736 56.1143C206.55 56.1143 199.884 49.3776 199.884 40.0976C199.884 30.866 206.55 24.1663 215.736 24.1663C222.057 24.1663 227.115 28.2601 227.167 28.2999L227.428 28.5161L233.479 20.3484L233.306 20.1521C233.078 19.9132 227.625 13.9389 215.571 13.9389C207.73 13.9389 200.999 16.5875 196.112 21.5973C191.478 26.3483 188.923 32.9171 188.923 40.0891C188.923 47.261 191.483 53.8327 196.112 58.5808C200.999 63.5907 207.73 66.2392 215.571 66.2392C227.539 66.2392 233.249 60.0886 233.303 60.026L233.482 59.8297L227.403 51.6393Z"
          fill={fillColor}
        />
        <path
          d="M33.0659 42.4162L34.7359 39.3409L47.2249 16.9033C47.5391 16.3689 47.9872 15.9256 48.525 15.6173C49.0628 15.309 49.6717 15.1462 50.2917 15.1452H60.4593L40.7784 50.4217C40.0786 51.6763 39.8652 52.1457 39.2308 53.2666L33.0659 42.4162Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_36_97">
          <rect
            fill="white"
            height="53.6431"
            transform="translate(0 13.37)"
            width="297"
          />
        </clipPath>
      </defs>
    </svg>

  );
}

export default VancoLogoSvg;
