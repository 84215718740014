// External Dependencies
import { Form, Formik, FormikHelpers } from 'formik';
import { createAndUpdateInventoryItemSchema } from '@presto-assistant/api_types/schemas/inventoryItem';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountMultipleCheckOutline from 'mdi-material-ui/AccountMultipleCheckOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import PlaceIcon from '@mui/icons-material/Place';

// Internal Dependencies
import {
  DynamicFormFields,
  EnhancedContainer,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SYSTEM_ITEM_NUMBER_PREFIX } from 'utils/constants/inventory';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from 'utils/lib/price_conversions';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { open } from 'state/ui/inventoryMissingSerialNumberDialog/actions';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import InventoryBasicInfoFormFields from './InventoryBasicInfoFormFields';
import InventoryCheckoutDetails from './InventoryCheckoutDetails';
import InventoryDangerZone from './InventoryDangerZone';
import InventoryItemMissingSerialNumberConfirmDialog from './InventoryItemMissingSerialNumberConfirmDialog';
import InventoryLocationInfoFormFields from './InventoryLocationInfoFormFields';
import InventoryPurchaseInfoFormFields from './InventoryPurchaseInfoFormFields';
import InventoryStatusInfoFormFields from './InventoryStatusInfoFormFields';
import ShowInventoryBasicData from './ShowInventoryBasicData';
import ShowInventoryDeletedData from './ShowInventoryDeletedData';
import ShowInventoryLocationData from './ShowInventoryLocationData';
import ShowInventoryPurchaseData from './ShowInventoryPurchaseData';
import ShowInventoryStatusData from './ShowInventoryStatusData';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  inventoryItem?: GQL.IInventoryItem;
  isAdmin?: boolean;
  isDeleted?: boolean;
  onSubmit?: (values: InventoryPayloadValues | AdminInventoryPayloadValues) => Promise<void>;
  readOnly?: boolean;
  showEdit?: boolean; // Deleted items show page cannot edit yet
}
interface BaseInventoryFormValues extends GQL.ICreateInventoryItemInput {
  onLoanToOrganizationId: string | null;
  orgTypeId?: string;
  systemBarcode?: string;
}

export interface InventoryFormValues extends BaseInventoryFormValues {
  currentValue?: string | number;
  purchaseValue?: string | number;
}

export interface AdminInventoryFormValues extends InventoryFormValues {
  organizationId: string;
}

export interface InventoryPayloadValues extends BaseInventoryFormValues {
  currentValueInCents?: number;
  isMissing: boolean;
  purchaseValueInCents?: number;
}

export interface AdminInventoryPayloadValues extends InventoryPayloadValues {
  organizationId: string;
}

// Component Definition
const InventoryForm = ({
  canUseDynamicFields,
  inventoryItem,
  isAdmin,
  isDeleted = false,
  onSubmit,
  readOnly,
  showEdit = true,
}: Props): JSX.Element | null => {
  const navigate = useNavigate();

  const { data } = useGetOrganization({
    skip: isAdmin,
  });

  const organizationId = data?.organization?.id;

  const isDFA = useSelector(isDistrictAdmin);

  const ownsInventoryItem = organizationId === inventoryItem?.organization?.id || isDFA;

  const dispatch = useDispatch();
  const canEditInventoryBasicInfo = useSelector(hasPermission('inventory', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryStatusInfo = useSelector(hasPermission('inventoryStatusInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryLocationInfo = useSelector(hasPermission('inventoryLocationInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryPurchaseInfo = useSelector(hasPermission('inventoryPurchaseInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryDynamicFields = useSelector(hasPermission('inventoryDynamicFields', 'edit'))
    && ownsInventoryItem;
  const canDeleteInventoryItem = useSelector(hasPermission('inventory', 'delete'));

  const canEditInventory = canEditInventoryBasicInfo
    || canEditInventoryStatusInfo
    || canEditInventoryLocationInfo
    || canEditInventoryPurchaseInfo
    || canEditInventoryDynamicFields;

  const canEdit = showEdit && (isDFA || canEditInventory);
  const canDelete = isDFA || canDeleteInventoryItem;

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const inventoryParams = useSelector(tableQueryParams('inventoryItems'));

  // For tiny phone screens, we use less padding on the inputs
  const inputSize = isMobileOrTabletScreen ? 'small' : 'medium';

  const handlePressCancelOrBackButton = useCallback(() => {
    if (isAdmin) {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}`);
    } else {
      navigate(`/${PATHS.INVENTORY}${inventoryParams}`);
    }
  }, [inventoryParams, isAdmin, navigate]);

  const inventoryIndexUrl = isDFA
    ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${inventoryParams}`
    : `/${PATHS.INVENTORY}${inventoryParams}`;

  const prestoEnhancedSerialNumber = `${SYSTEM_ITEM_NUMBER_PREFIX}-${inventoryItem?.itemNumber}`;

  const initialValues: InventoryFormValues = useMemo(() => ({
    ...(canUseDynamicFields ? applyDynamicFields(inventoryItem) : {}),
    accessories: inventoryItem?.accessories ?? '',
    brand: inventoryItem?.brand ?? '',
    caseNumber: inventoryItem?.caseNumber ?? '',
    categoryId: inventoryItem?.category?.id ?? '1',
    combination: inventoryItem?.combination ?? '',
    comments: inventoryItem?.comments ?? '',
    conditionId: inventoryItem?.condition?.id ?? '',
    currentValue: inventoryItem?.currentValueInCents
      ? convertCentsToDollars(inventoryItem.currentValueInCents)
      : '',
    customBarcode: inventoryItem?.customBarcode ?? '',
    districtNumber: inventoryItem?.districtNumber ?? '',
    inventoryFundingSourceId: inventoryItem?.inventoryFundingSourceId?.toString() ?? null,
    isMissing: inventoryItem?.isMissing ?? false,
    label: inventoryItem?.label ?? '',
    location: inventoryItem?.location ?? '',
    lockNumber: inventoryItem?.lockNumber ?? '',
    locker: inventoryItem?.locker ?? '',
    model: inventoryItem?.model ?? '',
    onLoanToOrganizationId: inventoryItem?.onLoanToOrganization?.id ?? null,
    orgTypeId: inventoryItem?.organizationType?.id ?? '1',
    purchaseOrderNumber: inventoryItem?.purchaseOrderNumber ?? '',
    purchaseValue: inventoryItem?.purchaseValueInCents
      ? convertCentsToDollars(inventoryItem.purchaseValueInCents)
      : '',
    purchaseYear: inventoryItem?.purchaseYear ?? null,
    purchasedAt: inventoryItem?.purchasedAt ?? null,
    qualityId: inventoryItem?.quality?.id ?? '',
    serialNumber: !inventoryItem
      ? ''
      : inventoryItem?.serialNumber || prestoEnhancedSerialNumber,
    systemBarcode: !inventoryItem
      ? ''
      : inventoryItem?.systemBarcode,
  }), [canUseDynamicFields, inventoryItem, prestoEnhancedSerialNumber]);

  const adminInitialValues: AdminInventoryFormValues = {
    ...initialValues,
    organizationId: inventoryItem?.organization?.id ?? '',
  };

  const handleFormikSubmit = useCallback(async (
    values: AdminInventoryFormValues | InventoryFormValues,
    formikProps: FormikHelpers<InventoryFormValues | AdminInventoryFormValues>,
  ) => {
    // We pull out the price data to convert
    //  dollars to cents before sending to API
    const {
      currentValue,
      orgTypeId,
      purchaseValue,
      serialNumber: currentSerialNumber,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      systemBarcode, // This value cannot be updated
      ...otherValues
    } = values;

    // If there is no serial number, and the user didn't modify
    //  the serial number field, then we want to send the
    //  original empty serial number to the API
    const isSerialNumberMissing = !inventoryItem?.serialNumber
      && currentSerialNumber === prestoEnhancedSerialNumber;

    const updatedValues: InventoryPayloadValues | AdminInventoryPayloadValues = {
      ...otherValues,
      // If the user did not input either "value",
      //  then we avoid sending a 0 to the API
      currentValueInCents: currentValue
        ? convertDollarsToCents(currentValue)
        : undefined,
      // isMissing: Boolean(Number(values.isMissing)),
      onLoanToOrganizationId: (values.onLoanToOrganizationId === '-1' || values.onLoanToOrganizationId === '')
        ? null
        : values.onLoanToOrganizationId,
      organizationTypeId: orgTypeId ?? undefined,
      purchaseValueInCents: purchaseValue
        ? convertDollarsToCents(purchaseValue)
        : undefined,
      serialNumber: isSerialNumberMissing
        ? inventoryItem?.serialNumber
        : currentSerialNumber,
      ...isAdmin && {
        organizationId: (values as AdminInventoryFormValues).organizationId === '-1'
          ? ''
          : (values as AdminInventoryFormValues).organizationId,
      },
    };

    if (isSerialNumberMissing) {
      delete updatedValues.serialNumber;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(updatedValues);

    setSubmitting(false);
  }, [isAdmin, inventoryItem?.serialNumber, onSubmit, prestoEnhancedSerialNumber]);

  const handlePressSubmitButton = useCallback((
    values: InventoryFormValues | AdminInventoryFormValues,
    formikProps: FormikHelpers<InventoryFormValues | AdminInventoryFormValues>,
  ) => {
    const { setSubmitting } = formikProps;

    // We pop up a dialog to tell the user we will add a unique identifier
    //  if they have removed the serial number
    const isSerialNumberRemoved = inventoryItem?.serialNumber
      && !values.serialNumber;

    if (isSerialNumberRemoved) {
      setSubmitting(false);
      dispatch(
        open({
          handleSubmitForm: () => handleFormikSubmit(values, formikProps),
        }),
      );
    } else {
      handleFormikSubmit(values, formikProps);
    }
  }, [dispatch, handleFormikSubmit, inventoryItem?.serialNumber]);

  if (readOnly && !inventoryItem) {
    return null;
  }

  return (
    <>
      <Formik<InventoryFormValues | AdminInventoryFormValues>
        initialValues={isAdmin ? adminInitialValues : initialValues}
        onSubmit={handlePressSubmitButton}
        validationSchema={createAndUpdateInventoryItemSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <EnhancedContainer maxWidth={false}>
                  {inventoryItem && isDeleted && (
                    <Box mb={2}>
                      <ShowInventoryDeletedData
                        deletedAt={inventoryItem.deletedAt}
                        deletedBy={inventoryItem.deletedBy}
                        deletedNote={inventoryItem.deletedNote}
                      />
                    </Box>
                  )}
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryBasicInfo}
                      icon={InfoIcon}
                      readOnly={readOnly}
                      title="Basic Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryBasicInfo) ? (
                        <ShowInventoryBasicData
                          inventoryItemData={inventoryItem}
                          isAdmin={isAdmin}
                          prestoEnhancedSerialNumber={prestoEnhancedSerialNumber}
                        />
                      ) : (
                        <InventoryBasicInfoFormFields
                          hasNoSerialNumber={!inventoryItem?.serialNumber}
                          inputSize={inputSize}
                          isAdmin={isAdmin}
                          isNewItem={!inventoryItem}
                          orgTypeId={values?.orgTypeId || '1'}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryStatusInfo}
                      icon={BarChartIcon}
                      readOnly={readOnly}
                      title="Status Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryStatusInfo) ? (
                        <ShowInventoryStatusData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryStatusInfoFormFields
                          excludeLoanToOrganizationId={organizationId}
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  {readOnly && (
                    <Box mb={2}>
                      <ShowCard
                        canEdit={false}
                        icon={AccountMultipleCheckOutline}
                        readOnly={readOnly}
                        title="Checkout Details"
                      >
                        {inventoryItem && (
                          <InventoryCheckoutDetails inventoryItem={inventoryItem} />
                        )}
                      </ShowCard>
                    </Box>
                  )}
                </EnhancedContainer>

                <EnhancedContainer maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryLocationInfo}
                      icon={PlaceIcon}
                      readOnly={readOnly}
                      title="Location Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryLocationInfo) ? (
                        <ShowInventoryLocationData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryLocationInfoFormFields
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryPurchaseInfo}
                      icon={AttachMoneyIcon}
                      readOnly={readOnly}
                      title="Purchase Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryPurchaseInfo) ? (
                        <ShowInventoryPurchaseData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryPurchaseInfoFormFields
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  <DynamicFormFields
                    isAdmin={!!isAdmin}
                    item={inventoryItem}
                    organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                    showCardProps={{
                      canEdit: canEdit && canEditInventoryDynamicFields,
                      readOnly: readOnly || !canEditInventoryDynamicFields,
                    }}
                    tableRef="inventory_items"
                  />
                </EnhancedContainer>
              </Grid>

              {!readOnly && (
                <FormActions
                  context="Inventory Item"
                  isEditing={!!inventoryItem}
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={handlePressCancelOrBackButton}
                />
              )}
            </Form>
          );
        }}
      </Formik>

      {canDelete && !readOnly && inventoryItem?.id && inventoryItem?.organization?.label && (
        <EnhancedContainer>
          <InventoryDangerZone
            inventoryId={inventoryItem.id}
            inventoryIndexUrl={inventoryIndexUrl}
            organizationLabel={inventoryItem?.organization?.label}
          />
        </EnhancedContainer>
      )}
      <InventoryItemMissingSerialNumberConfirmDialog isEditing />
    </>
  );
};

export default InventoryForm;
