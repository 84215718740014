// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const close = createActionCreator('CLOSE_MISSING_SERIAL_NUMBER_DIALOG');
export const open = createActionCreator(
  'OPEN_MISSING_SERIAL_NUMBER_DIALOG',
  (resolve) => (form: {
    handleSubmitForm: (values: any, formikProps: any) => void
   }) =>
    resolve(form),
);

// export const clearForm = createActionCreator('CLEAR_EDIT_INVENTORY_FORM');
// export const updateForm = createActionCreator(
//   'UPDATE_EDIT_INVENTORY_FORM',
//   (resolve) => (inventoryItem: GQL.IInventoryItem) => resolve(inventoryItem),
// );
