// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard } from 'components/shared';

// Local Dependencies
import PaymentsByDateChart from './PaymentsByDateChart';

// Local Variables
/*
The chart is not responsive on window resize
if the card is display: grid;
*/
const StyledDashboardCard = styled(DashboardCard)({
  '& > div': {
    display: 'block',
  },

  display: 'block',
});

// Component Definition
const PaymentsReceivedByDateCard: FC = (props) => (
  <StyledDashboardCard
    content={<PaymentsByDateChart />}
    title="Payments Received by Date"
    {...props}
  />
);

export default PaymentsReceivedByDateCard;
