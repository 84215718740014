import { DistrictAssistant } from 'gql/queries';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { createDataGridActionsColumn, displayCell } from 'components/shared/TableV2';

export const useColumns = ({
  isHeadDistrictAdmin,
  onDelete,
}: {
  isHeadDistrictAdmin: boolean;
  onDelete: (row: DistrictAssistant) => void;
  selfId: string | null;
}): GridColDef<DistrictAssistant>[] => {
  const actionsColumn = useMemo<GridColDef[]>(
    () => {
      const actions = createDataGridActionsColumn<GQL.IDistrictAssistantIndex>([
        ...(isHeadDistrictAdmin ? [
          {
            action: onDelete,
            icon: <DeleteIcon />,
            text: 'Delete',
          },
        ] : []),
      ]);

      return actions ? [actions] : [];
    },
    [isHeadDistrictAdmin, onDelete],
  );

  return useMemo(() => {
    const columns: GridColDef<DistrictAssistant>[] = [
      {
        field: 'firstName',
        headerName: 'First Name',
        valueGetter: (params) => displayCell(params.row.firstName),
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        valueGetter: (params) => displayCell(params.row.lastName),
      },
      {
        field: 'email',
        headerName: 'Email',
        valueGetter: (params) => displayCell(params.row.email),
      },
      ...actionsColumn,
    ];

    return columns;
  }, [actionsColumn]);
};
