// External Dependencies
import {
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert, StyledLink,
} from 'components/shared';
import { SHORT_APP_NAME } from 'utils/constants';
import { stripeUrl } from 'utils/constants/urls';
import { useGetOrganization } from 'gql/queries';

// Local Variables
const StyledStrong = styled.strong({ fontWeight: 600 });

// Component Definition
const StripeBillingAlert: FC = () => {
  const { data: orgData } = useGetOrganization();

  if (!orgData) {
    return null;
  }

  const stripeBillingStatementOrgName = `${SHORT_APP_NAME.toUpperCase()}* ${orgData.organization.label.toUpperCase()}`.slice(0, 22);

  return (
    <Collapse in={Boolean(orgData)}>
      <Box marginBottom={2}>
        <EnhancedAlert
          severity="info"
          title="Billing Details"
        >
          <Typography
            gutterBottom
            variant="body2"
          >
            A non-refundable fee of 2.9% + $0.30 is included
            in the total for each online transaction.
            {' '}
            Secure payment provided by
            {' '}
            <StyledLink
              href={stripeUrl}
              openInNewTab
            >
              Stripe
            </StyledLink>.
          </Typography>

          <Typography variant="body2">
            Any Stripe payment will appear on your billing statement
            as &quot;
            <code>
              <StyledStrong>
                {stripeBillingStatementOrgName}
              </StyledStrong>
            </code>&quot;.
          </Typography>
        </EnhancedAlert>
      </Box>
    </Collapse>
  );
};

export default StripeBillingAlert;
