// External Dependencies
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EventIcon from '@mui/icons-material/Event';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import SquareRoundedIcon from 'mdi-material-ui/SquareRounded';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedDialog } from 'components/shared';
import {
  formatCalendarEndTime,
  formatCalendarStartDate,
  formatCalendarStartTime,
} from 'utils';
import { useGetCalendarList } from 'gql/queries';

// Local Dependencies
import { CalendarEvent } from '../EventCalendar';
import EventDetail from './EventDetail';

// Local Typings
interface Props {
  event: CalendarEvent;
  onClose: () => void;
}

// Local Variables
const StyledIconButton = styled(IconButton)((props) => ({
  position: 'absolute',
  right: props.theme.spacing(1),
  top: props.theme.spacing(1),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '.MuiList-root': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.MuiListItemIcon-root': {
    alignSelf: 'flex-start',
    minWidth: 48,
    paddingTop: theme.spacing(0.5),
  },
  '.event-title': {
    fontSize: 22,
  },

  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
}));

// Component Definition
const EventDialog: FC<Props> = ({
  event,
  onClose,
}) => {
  const theme = useTheme();

  const eventIconColor = theme.palette.grey['800'];

  const [
    getCalendarList,
    { data: calendarList },
  ] = useGetCalendarList();

  useEffect(() => {
    getCalendarList();
  }, [getCalendarList]);

  const calendar = calendarList?.googleCalendarList
    .find((cal) => cal.id === event.googleCalendarId);

  return (
    <EnhancedDialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open
    >
      <DialogTitle>
        <StyledIconButton onClick={onClose}>
          <CloseIcon aria-label="Close calendar event" />
        </StyledIconButton>
      </DialogTitle>

      <StyledDialogContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <SquareRoundedIcon
                htmlColor={calendar?.backgroundColor ?? theme.palette.prestoPrimary}
              />
            </ListItemIcon>

            <ListItemText
              primary={(
                <Typography
                  className="event-title"
                  sx={{ mb: 0.5 }}
                  variant="h5"
                >
                  {event.title}
                </Typography>
              )}
              secondary={event.start && (
                <Typography
                  component="p"
                  variant="body2"
                >
                  {formatCalendarStartDate(event.start)}
                  {!event.allDay
                    ? (
                      <>
                        <Box
                          component="span"
                          mx={1}
                        >
                          •
                        </Box>
                        {formatCalendarStartTime({
                          endDate: event.end,
                          startDate: event.start,
                        })}
                        {event.end && ' - '}
                        {event.end ? formatCalendarEndTime(event.end) : ''}
                      </>
                    ) : ' • All Day'}
                </Typography>
              )}
            />
          </ListItem>

          {event.location && (
            <ListItem>
              <ListItemIcon>
                <LocationOnOutlinedIcon
                  htmlColor={eventIconColor}
                />
              </ListItemIcon>

              <ListItemText
                primary={(
                  <EventDetail
                    content={event.location}
                    type="location"
                  />
                )}
              />
            </ListItem>
          )}

          <ListItem>
            <ListItemIcon>
              <NotesIcon
                fontSize="small"
                htmlColor={eventIconColor}
              />
            </ListItemIcon>

            <ListItemText
              primary={event.description && (
                <EventDetail
                  content={event.description}
                />
              )}
            />
          </ListItem>

          {calendar && (
            <ListItem>
              <ListItemIcon>
                <EventIcon
                  fontSize="small"
                  htmlColor={eventIconColor}
                />
              </ListItemIcon>

              <ListItemText
                primary={(
                  <Typography
                    component="p"
                    variant="body2"
                  >
                    {calendar.summary}
                  </Typography>
                )}
              />
            </ListItem>
          )}
        </List>
      </StyledDialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </EnhancedDialog>
  );
};

export default EventDialog;
