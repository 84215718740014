// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  EnhancedCard,
  Subtitle,
  SupportLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetGettingStartedData } from 'hooks/useGetGettingStartedData';

// Local Dependencies
import GettingStartedListItem from './GettingStartedListItem';

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  '.MuiCard-root': {
    marginBottom: theme.spacing(2),
  },
  '.MuiListItemAvatar-root': {
    marginTop: theme.spacing(0.5),
  },
  li: {
    alignItems: 'flex-start',
  },
}));

// Component Definition
const GettingStartedUI = (): JSX.Element => {
  const inventoryItemsParams = useSelector(tableQueryParams('inventoryItems'));
  const libraryItemsParams = useSelector(tableQueryParams('libraryItems'));
  const uniformParams = useSelector(tableQueryParams('uniforms'));
  const studentParams = useSelector(tableQueryParams('students'));
  const hasVancoPaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.VancoPayments));

  const {
    hasAnotherDirector,
    hasAtLeastOneInventoryItem,
    hasAtLeastOneLibraryItem,
    hasAtLeastOneStudent,
    hasAtLeastOneUniformItem,
    hasConnectedGoogleCalendar,
    hasConnectedStripe,
    hasSentAtLeastOneEmail,
    isDistrictConnected,
  } = useGetGettingStartedData();

  return (
    <StyledSection>
      <Subtitle>General</Subtitle>
      <EnhancedCard>
        <List>
          <GettingStartedListItem
            actionDestination={`/${PATHS.SETTINGS}#invite-district-admin-form`}
            isCompleted={isDistrictConnected}
            primaryText="Connect your district"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Connect your district to import physical inventory items.
                </Typography>

                <div>
                  Share{' '}
                  <SupportLink
                    buttonText="this article about creating a new district"
                    slug="create-a-new-district"
                  />{' '}
                  with your district Fine Arts Administrator.
                </div>
              </>
            )}
          />

          <GettingStartedListItem
            actionDestination={`/${PATHS.EMAIL_NEW}`}
            isCompleted={hasSentAtLeastOneEmail}
            primaryText="Send your first email"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  You can send emails to students, staff, and parents.
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Learn more about sending emails"
                    isVideo
                    slug="communication-walkthrough/#send-emails"
                  />
                </div>
              </>
            )}
          />
        </List>
      </EnhancedCard>

      <Subtitle>Physical Inventory</Subtitle>
      <EnhancedCard>
        <List>
          <GettingStartedListItem
            actionDestination={`/${PATHS.INVENTORY}${inventoryItemsParams}`}
            isCompleted={hasAtLeastOneInventoryItem}
            primaryText="Add your first inventory item"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Add inventory items individually or in bulk using our import feature.
                </Typography>

                <Typography
                  gutterBottom
                  variant="body2"
                >
                  <SupportLink
                    buttonText="Learn more about importing inventory data"
                    slug="import-inventory-data"
                  />
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Explore our video about managing inventory items"
                    isVideo
                    slug="inventory-walkthrough/#manage-inventory-items"
                  />
                </div>
              </>
            )}
          />

          <GettingStartedListItem
            actionDestination={`/${PATHS.LIBRARY}${libraryItemsParams}`}
            isCompleted={hasAtLeastOneLibraryItem}
            primaryText="Add your first library item"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Add library items individually or in bulk using our import feature.
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Learn more about managing library items"
                    isVideo
                    slug="inventory-walkthrough/#manage-library-items"
                  />
                </div>
              </>
            )}
          />

          <GettingStartedListItem
            actionDestination={`/${PATHS.UNIFORMS}${uniformParams}`}
            isCompleted={hasAtLeastOneUniformItem}
            primaryText="Add your first uniform item"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Add uniform items individually or in bulk using our import feature.
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Learn more about managing uniforms"
                    isVideo
                    slug="inventory-walkthrough/#manage-uniforms"
                  />
                </div>
              </>
            )}
          />
        </List>
      </EnhancedCard>

      <Subtitle>People</Subtitle>
      <EnhancedCard>
        <List>
          <GettingStartedListItem
            actionDestination={`/${PATHS.DIRECTORS}/new`}
            isCompleted={hasAnotherDirector}
            primaryText="Add another director"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Add directors and staff members to help manage your organization.
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Learn more about adding additional staff members"
                    slug="add-assistant-directors-and-staff"
                  />
                </div>
              </>
            )}
          />

          <GettingStartedListItem
            actionDestination={`/${PATHS.STUDENTS}${studentParams}`}
            isCompleted={hasAtLeastOneStudent}
            primaryText="Add your first student"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Add student data individually or in bulk using our import feature.
                </Typography>

                <Typography
                  gutterBottom
                  variant="body2"
                >
                  <SupportLink
                    buttonText="Learn more about importing student data"
                    slug="import-student-data"
                  />
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Explore our videos about student import options"
                    isVideo
                    slug="members-walkthrough"
                  />
                </div>
              </>
            )}
          />
        </List>
      </EnhancedCard>

      <Subtitle>Integrations</Subtitle>
      <EnhancedCard>
        <List>
          <GettingStartedListItem
            actionDestination={`/${PATHS.SETTINGS}#integrations`}
            isCompleted={hasConnectedGoogleCalendar}
            primaryText="Connect your Google Calendar"
            secondaryText="You can connect your Google Calendar to sync events, showing selected calendars to members."
          />

          <GettingStartedListItem
            actionDestination={`/${PATHS.SETTINGS}#integrations`}
            isCompleted={hasConnectedStripe}
            primaryText="Set up Online Payments"
            secondaryText={(
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                >
                  Connect a Stripe account {hasVancoPaymentsFeatureFlag && 'or Vanco account '}to accept online payments from parents.
                </Typography>

                <Typography
                  gutterBottom
                  variant="body2"
                >
                  <strong>NOTE:</strong> The online payments feature is only available
                  for organizations with an active {APP_NAME} license.
                </Typography>

                <div>
                  <SupportLink
                    buttonText="Learn more about syncing with Stripe"
                    isVideo
                    slug="finances-walkthrough/#sync-with-stripe"
                  />
                </div>
              </>
            )}
          />
        </List>
      </EnhancedCard>
    </StyledSection>
  );
};

export default GettingStartedUI;
