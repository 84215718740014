// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import LibraryTableDataGrid from './LibraryTableDataGrid';

// Component Definition
const Library: FC = () => (
  <>
    <Subtitle>
      Library Items
    </Subtitle>

    <EnhancedCard>
      <LibraryTableDataGrid />
    </EnhancedCard>
  </>
);

export default Library;
