// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  payload: GQL.IUpdateFinancialCreditInput | null;
}

// Component Definition
const ConfirmationDialogContent = ({
  payload,
}: Props): JSX.Element => {
  return (
    <>
      <Box marginBottom={2}>
        <Typography color="textSecondary">
          Please review the following changes.
        </Typography>
      </Box>

      <ShowPageDataDisplay
        label="Amount"
        type="currency"
        value={payload?.amountInCents}
      />

      <ShowPageDataDisplay
        label="Note"
        value={payload?.note}
      />
    </>
  );
};

export default ConfirmationDialogContent;
