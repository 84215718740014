// External Dependencies
import { FC, useCallback, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { createFileSchema } from '@presto-assistant/api_types/schemas/file';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';

// Internal Dependencies
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  FormActionButtons,
  Page,
} from 'components/shared';
import { ONE_MEGABYTE_IN_BYTES } from 'utils/constants/files';
import { PATHS } from 'utils/constants/routes';
import { parseSearch, pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import {
  useCreateFilePresignedUrl,
  useCreateFiles,
} from 'gql/mutations';
import { useGetOrganization } from 'gql/queries';
import UploadDropzone from 'components/shared/UploadDropzone';

// Local Dependencies
import FileDetailsForm from '../shared/FileDetailsForm';

// Local Typings
interface FormValues {
  files: GQL.ICreateFileInput[];
}

export interface FileUpload {
  file: File;
  s3Urls: GQL.IPresignedUrl;
}

export interface FileUploadError {
  errorMessage: string;
  file: File;
}

// Component Definition
const FileNew: FC = () => {
  const navigate = useNavigate();

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [hasUploadError, setHasUploadError] = useState(false);

  const filesDirectoriesParams = useSelector(tableQueryParams('fileDirectories'));

  const { search } = useLocation();

  const { data: orgData } = useGetOrganization();

  const parsedSearch = parseSearch(search);

  const navigateToFileDirectories = useCallback(() => {
    navigate(`/${PATHS.FILES}${filesDirectoriesParams}`);
  }, [filesDirectoriesParams, navigate]);

  const [
    createFilePresignedUrl,
  ] = useCreateFilePresignedUrl();

  const [
    createManyFiles,
    {
      loading,
    },
  ] = useCreateFiles(navigateToFileDirectories);

  const handleUpdateAttachments = async (files: FileList): Promise<FileUpload[]> => {
    setIsUploadingFiles(true);
    setHasUploadError(false);

    try {
      // TODO: Tell user if they already added a file

      // The incoming FileList has to be transformed into an array
      const convertedFileList = [...files];

      const newAttachments: FileUpload[] = [];

      const errorAttachments: FileUploadError[] = [];

      const urlFetchers = convertedFileList
        // eslint-disable-next-line no-async-promise-executor
        .map((file) => new Promise<GQL.IPresignedUrl>(async (resolve, reject) => {
          try {
            const result = await createFilePresignedUrl({
              variables: {
                input: {
                  filename: file.name,
                  filetype: file.type,
                },
              },
            });

            if (!result.data?.createFilePresignedUrl) {
              throw new Error('Could not get presigned URL');
            }

            resolve(result.data.createFilePresignedUrl);
            return;
          } catch (error) {
            reject(error);
          }
        }));

      const presignedUrls = await Promise.all(urlFetchers);

      // eslint-disable-next-line no-restricted-syntax
      for (const urls of presignedUrls) {
        const index = presignedUrls.indexOf(urls);

        const file = convertedFileList[index];

        try {
          const limitInMb = 25;

          if (file.size > ONE_MEGABYTE_IN_BYTES * limitInMb) {
            throw new Error(`The size limit is ${limitInMb} MB`);
          }

          // eslint-disable-next-line no-await-in-loop
          const res = await fetch(urls.putUrl, {
            body: file,
            method: 'PUT',
          });

          if (!res.ok) {
            throw new Error('Unable to upload file.');
          }

          newAttachments.push({
            file,
            s3Urls: urls,
          });
        } catch (error) {
          errorAttachments.push({
            errorMessage: (error as unknown as Error).message,
            file,
          });
        }
      }

      if (errorAttachments.length > 0) {
        setHasUploadError(true);
        setUploadErrors(errorAttachments.map((attachment) => `${attachment.file.name} — ${attachment.errorMessage}`));

        return [];
      }

      return newAttachments;
    } catch (error) {
      setHasUploadError(true);

      return [];
    } finally {
      setIsUploadingFiles(false);
    }
  };

  if (!orgData) {
    return null;
  }

  const { currentSchoolYearEnding } = orgData.organization;

  const handleSubmit = async (values: FormValues) => {
    const files = values.files.map<GQL.ICreateFileInput>((file) => {
      const year = Number(file.schoolYearEnding);

      const schoolYearEnding = Number.isNaN(year) ? null : year || null;

      return {
        ...file,
        assignments: file.isPublic ? [] : file.assignments,
        schoolYearEnding,
      };
    });

    createManyFiles({
      variables: { input: { files } },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: `${parsedSearch?.directory ?? ''} Files`.trim(),
        path: `/${PATHS.FILES}/${parsedSearch?.directory ?? ''}`,
      }}
      isLoading={false}
    >
      <Container>
        <Formik<FormValues>
          initialValues={{
            files: [],
          }}
          onSubmit={handleSubmit}
          validationSchema={createFileSchema}
        >
          {({
            setFieldValue,
            values,
          }) => {
            const handleTheDrop = async (droppedFiles: File[]) => {
              try {
                setUploadErrors([]);

                const s3Urls = await handleUpdateAttachments(
                  droppedFiles as unknown as FileList,
                );

                if (s3Urls.length === droppedFiles.length) {
                  const newFileInputs: GQL.ICreateFileInput[] = droppedFiles.map((file, index) => ({
                    assignments: [],
                    directory: parsedSearch.directory ?? 'Home',
                    endDate: null,
                    fileName: file.name,
                    isPublic: false,
                    s3Url: s3Urls[index].s3Urls.getUrl,
                    schoolYearEnding: currentSchoolYearEnding,
                    startDate: null,
                  }));

                  setFieldValue(
                    'files',
                    [
                      ...values.files,
                      ...newFileInputs,
                    ],
                  );
                }
              } catch (s3UploadError) {
                setHasUploadError(true);
              }
            };

            return (
              <Form>
                <EnhancedCard
                  sx={{
                    mb: 2,
                    p: 0,
                  }}
                >
                  <Box padding={2}>
                    <UploadDropzone
                      acceptedFileTypes={['*']}
                      disabled={isUploadingFiles}
                      handleDrop={handleTheDrop}
                      marginTop={0}
                      rejectedDropErrorMessage="Unable to upload files"
                    />

                    {hasUploadError && (
                      <EnhancedAlert
                        severity="error"
                        sx={{ marginTop: 2 }}
                      >
                        Unable to upload files

                        {uploadErrors.length && (
                          <List>
                            {uploadErrors.map((uploadError) => (
                              <ListItem key={uploadError}>
                                {uploadError}
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </EnhancedAlert>
                    )}
                  </Box>

                  {isUploadingFiles && (
                    <LinearProgress variant="indeterminate" />
                  )}
                </EnhancedCard>

                <FieldArray name="files">
                  {(arrayHelpers) => (
                    <List>
                      {values.files.map((file, index) => (
                        <FileDetailsForm
                          assignments={file.assignments ?? null}
                          fieldNamePrefix={`files[${index}].`}
                          isPublic={file.isPublic}
                          key={file.s3Url}
                          maxDate={file.endDate}
                          minDate={file.startDate}
                          onDeleteFile={arrayHelpers.handleRemove(index)}
                        />
                      ))}
                    </List>
                  )}
                </FieldArray>

                <Paper
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    px: 2,
                  }}
                  variant="outlined"
                >
                  <FormActionButtons
                    context={pluralize(values.files.length, 'File')}
                    isButtonDisabled={values.files.length === 0}
                    isSubmitting={loading}
                    onClickCancelButton={navigateToFileDirectories}
                  />
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Page>
  );
};

export default FileNew;
