// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { useGetGoogleCalendarOAuthUrl } from 'gql/queries';
import AlertButton from './AlertButton';

// Component Definition
const GoogleCalendarButton: FC = () => {
  const [
    getURL,
    { loading },
  ] = useGetGoogleCalendarOAuthUrl();

  return (
    <AlertButton
      isLoading={loading}
      onClick={getURL}
    />
  );
};

export default GoogleCalendarButton;
