// External Dependencies
import { ApolloError } from '@apollo/client';
import { FC, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import Button from '@mui/material/Button';

// Internal Dependencies
import {
  CustomInput,
  Flex,
  SaveButton,
} from 'components/shared';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { trimValues } from 'utils/lib/trimValues';
import { useValidateUserFields } from 'gql/mutations';

// Local Typings
interface Props {
  onBack: () => void;
  onCompleted: (values: NameFormValues) => void;
  readOnly?: boolean;
}
export interface NameFormValues {
  firstName: string;
  lastName: string;
}

// Component Definition
const Name: FC<Props> = ({
  onBack,
  onCompleted,
  readOnly,
}) => {
  const [formValues, setFormValues] = useState<NameFormValues>({
    firstName: '',
    lastName: '',
  });
  const [errors, setErrors] = useState<FormikErrors<NameFormValues> | undefined>(undefined);

  const handleCompleted = (data: { validateUserFields: boolean }) => {
    if (data.validateUserFields) {
      onCompleted(formValues);
    }
  };

  const handleError = (error: ApolloError) => {
    setErrors(normalizeFormErrors(error));
  };

  const [
    validateUserFields,
    { loading },
  ] = useValidateUserFields(
    {
      onCompleted: handleCompleted,
      onError: handleError,
    },
  );

  const handleFormikSubmit = (values: NameFormValues) => {
    setFormValues(values);

    validateUserFields({
      variables: {
        input: trimValues(values) as NameFormValues,
      },
    });
  };

  return (
    <Formik<NameFormValues>
      enableReinitialize
      initialErrors={errors}
      initialValues={{
        firstName: '',
        lastName: '',
      }}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => (
        <Form
          onSubmit={handleSubmit}
        >
          <CustomInput
            InputProps={{ readOnly }}
            autoFocus
            label="First Name"
            name="firstName"
            required
          />

          <CustomInput
            InputProps={{ readOnly }}
            label="Last Name"
            name="lastName"
            required
          />

          {!readOnly && (
            <Flex
              justifyContent="flex-end"
              marginTop={1}
            >
              <Button
                color="primary"
                onClick={onBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Go Back
              </Button>

              <SaveButton
                color="primary"
                disabled={!values.firstName.trim().length || !values.lastName.trim().length}
                isSaving={loading}
                variant="contained"
              >
                Next
              </SaveButton>
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Name;
