// External Dependencies
import {
  FC, FormEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { SxProps } from '@mui/system';
import { Theme, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

// Internal Dependencies
import { CardDetails } from 'pages/Finances/MyFinancialPayments/New/shared/PaymentMethodCard';
import { IS_PRODUCTION } from 'utils/constants';
import { addNotification } from 'state/notifications/actions';
import { useAddScriptTag } from 'hooks/useAddScriptTag';

// Local Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  EnhancedContainer,
  SaveButton,
  ShowCardHeader,
} from '..';

// Local Typings
interface VancoPaymentMethod {
  bin: string;
  brand: string;
  countryCode: string;
  deferredUse: boolean;
  expireMonth: string;
  expireYear: string;
  isApplePay: boolean;
  isSaved: boolean;
  isSwiped: boolean;
  isTest: boolean;
  last4: string;
  paymentMethodToken: string;
  paymentMethodType: string;
  quickCheckout: boolean;
}
interface Props {
  onSetCardDetails: (paymentMethod: CardDetails) => void;
  pcct: string;
}

// Local Variables
const mapToCardDetails = (
  paymentMethod: VancoPaymentMethod,
): CardDetails => {
  return {
    brand: paymentMethod.brand,
    expMonth: paymentMethod.expireMonth,
    expYear: paymentMethod.expireYear,
    lastFour: paymentMethod.last4,
    paymentMethodToken: paymentMethod.paymentMethodToken,
  };
};
const inputStyle = {
  base: {
    color: '#3d3d3d',
    fontSize: '15px',
  },
};

// Component Definition
const VancoPaymentForm: FC<Props> = ({
  onSetCardDetails,
  pcct,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hexea, setHexea] = useState<unknown | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<unknown | null>(null);
  const [cardNumberField, setCardNumberField] = useState<unknown | null>(null);
  const [cardExpirationField, setCardExpirationField] = useState<unknown | null>(null);

  const handleLoadScript = useCallback(() => {
    if (!pcct) {
      return;
    }

    // @ts-ignore
    setHexea((Hexea as unknown as any)(pcct));
  }, [pcct]);

  useEffect(() => {
    if (hexea) {
      setPaymentMethod((hexea as any).paymentMethod('card'));
    }
  }, [hexea]);

  useEffect(() => {
    if (paymentMethod) {
      setCardNumberField((paymentMethod as unknown as any).field(
        'cardNumber',
        document.getElementById('card-number'),
        {
          style: inputStyle,
        },
      ));

      setCardExpirationField((paymentMethod as unknown as any).field(
        'cardExpiration',
        document.getElementById('card-exp'),
        { style: inputStyle },
      ));
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (cardNumberField && cardExpirationField) {
      // Listen for change events
      // Copying a snippet from https://apidocs.connexpoint.com/hexea-js.html#section/Creating-a-Payment-Form-for-Card-Entry
      // This isn't great, but it works for now
      [cardNumberField, cardExpirationField].forEach((field) => {
        (field as unknown as any).on('change', (event: unknown) => {
          if ((event as unknown as any).error) {
            setErrorMessage((event as unknown as any).error.message);
          } else {
            setErrorMessage(null);
          }
        });
      });
    }
  }, [cardNumberField, cardExpirationField]);

  const prodUrl = 'https://js.connexpoint.com/';
  const devUrl = 'https://js.connexpointstage.com/';

  const url = IS_PRODUCTION ? prodUrl : devUrl;

  useAddScriptTag(url, handleLoadScript);

  const [isSubmittingCardDetails, setIsSubmittingCardDetails] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (event: FormEvent) => {
    try {
      setIsSubmittingCardDetails(true);

      // We will be submitting the form with JS in the next step. For now, prevent
      // it from submitting until the token has been collected.
      event.preventDefault();

      // @ts-ignore
      const paymentResult = await (hexea as unknown as any).tokenize(paymentMethod, {
        isSaved: false,
      });

      if (paymentResult?.paymentMethod) {
        onSetCardDetails(mapToCardDetails(paymentResult.paymentMethod));
      }

      if (paymentResult?.error) {
        dispatch(addNotification(paymentResult.error.message ?? '', 'error'));
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsSubmittingCardDetails(false);
    }
  };

  const theme = useTheme();

  const inputContainerStyle = useMemo<SxProps<Theme>>(() => {
    return {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 1,
      padding: 1,
    };
  }, [theme]);

  return (
    <EnhancedContainer maxWidth="sm">
      <EnhancedCard>
        <ShowCardHeader title="Payment Method" />

        <CardContent>
          <Box
            action="/pay"
            component="form"
            id="payment-form"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              {/* disabling rule, as hexea.js provides the input field */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="card-number">
                Card number
                <Box
                  id="card-number"
                    // @ts-ignore
                  {...cardNumberField}
                  sx={inputContainerStyle}
                />
              </label>
            </div>
            <Box
              className="form-group"
            >
              {/* disabling rule, as hexea.js provides the input field */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="card-exp">
                Card expiration
                <Box
                  id="card-exp"
                    // @ts-ignore
                  {...cardExpirationField}
                  sx={inputContainerStyle}
                />
              </label>
            </Box>

            {errorMessage && (
              <EnhancedAlert
                severity="error"
                sx={{ marginTop: 2 }}
              >
                {errorMessage}
              </EnhancedAlert>
            )}

            <Box marginTop={2}>
              <SaveButton
                color="primary"
                disabled={!paymentMethod}
                isSaving={isSubmittingCardDetails}
                type="submit"
                variant="contained"
              >
                Next
              </SaveButton>
            </Box>
          </Box>
        </CardContent>
      </EnhancedCard>
    </EnhancedContainer>
  );
};

export default VancoPaymentForm;
