// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetFile } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import FileEditForm from '../shared/FileEditForm';

// Component Definition
const FilesEdit: FC = () => {
  const {
    directory,
    id,
  } = useParamsWithId<{ directory: string; }>();

  const {
    data,
    error,
    loading: fileLoading,
  } = useGetFile(id!);

  return (
    <Page
      backButtonProps={{
        label: 'File',
        path: `/${PATHS.FILES}/${directory}/${id}`,
      }}
      error={error}
      isLoading={fileLoading}
      notFound={!data?.file}
    >
      <FileEditForm
        directory={directory!}
        file={data?.file}
        id={id!}
      />
    </Page>
  );
};

export default FilesEdit;
