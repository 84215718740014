export const tableRefOptions = [
  {
    id: 'inventory_items',
    label: 'Inventory',
  },
  {
    id: 'library_items',
    label: 'Library',
  },
  {
    id: 'members',
    label: 'Members',
  },
  {
    id: 'uniforms',
    label: 'Uniforms',
  },
];
