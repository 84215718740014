// External Dependencies
import { BaseSelectProps } from '@mui/material/Select';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import {
  isDistrictAdmin,
} from 'state/self/selectors';
import { useGetOrganizationSchoolYearOptions } from 'gql/queries';

// Local Dependencies
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import CustomSelect from '../CustomSelect';

// Local Typings
interface BaseProps {
  includeAllYearsOption?: boolean;
}
interface PreviousYearProps extends BaseProps {
  hidePreviousYears?: never;
  previousYearsOnly?: boolean;
}
interface ExcludePreviousYearProps extends BaseProps {
  hidePreviousYears?: boolean;
  previousYearsOnly?: never;
}
interface AllYearProps extends BaseProps {
  hidePreviousYears?: never;
  previousYearsOnly?: never;
}
type Props = PreviousYearProps | ExcludePreviousYearProps | AllYearProps;
type PartialSelectProps = Omit<BaseSelectProps, 'onChange' | 'value' | 'label' | 'options'>;

// Component Definition
const SchoolYearSelect: FC<Props & PartialSelectProps> = ({
  hidePreviousYears,
  includeAllYearsOption,
  previousYearsOnly,
  ...props
}) => {
  const currentSchoolYear = useGetOrganizationSchoolYear();
  const isDFA = useSelector(isDistrictAdmin);

  const { data } = useGetOrganizationSchoolYearOptions({
    includeAllYearsOption,
  });

  const options = useMemo(() => {
    if (!data) {
      // A district admin user is getting school year data,
      //  so we'll display the current school year and the next school year
      if (isDFA) {
        return [
          {
            id: currentSchoolYear,
            label: displaySchoolYearString(currentSchoolYear),
          },
          // Display next year date, too
          {
            id: currentSchoolYear + 1,
            label: displaySchoolYearString(currentSchoolYear + 1),
          },
        ];
      }

      return [];
    }

    if (previousYearsOnly) {
      return data.organizationSchoolYearSelectOptions
        .filter((option) => option.id < currentSchoolYear);
    }

    if (hidePreviousYears) {
      return data.organizationSchoolYearSelectOptions
        .filter((option) => option.id >= currentSchoolYear);
    }

    return data.organizationSchoolYearSelectOptions;
  }, [
    currentSchoolYear,
    data,
    hidePreviousYears,
    isDFA,
    previousYearsOnly,
  ]);

  return (
    <CustomSelect
      label="School Year"
      name="schoolYearEnding"
      options={options}
      {...props}
    />
  );
};

export default SchoolYearSelect;
