// External Dependencies
import { FC } from 'react';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { PermissionAction } from 'components/shared/PermissionEditPage/PermissionFormGroup';
import {
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface Props {
  actions?: PermissionAction[];
  label?: string;
  permission: keyof GQL.IPermissions;
  permissions: Exclude<GQL.IPermissions, '__typename'>;
}

// Component Definition
const PermissionDataDisplay: FC<Props> = ({
  actions = ['write', 'read', 'edit', 'delete'],
  label,
  permission,
  permissions,
}) => {
  const allowedActions = Object.entries(permissions[permission])
    .reduce((
      acc: string[],
      curr,
    ) => {
      if (actions?.includes(curr[0] as PermissionAction) && curr[1].allowed) {
        acc.push(startCase(curr[0]));
      }

      return acc;
    }, []);

  const actionString = allowedActions.join(', ');

  return (
    <ShowPageDataDisplay
      label={label ?? startCase(permission)}
      value={actionString}
    />
  );
};

export default PermissionDataDisplay;
