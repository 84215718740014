// External Dependencies
import { useMemo } from 'react';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { useGetGenders } from 'gql/queries';

// Hook Definition
export const useGetGenderOptions = () => {
  const genderData = useGetGenders();

  const genderOptions = useMemo(
    () => genderData.data?.genders.map((gender) => ({
      id: gender.id,
      label: startCase(gender.label),
      value: startCase(gender.label),
    })) ?? [],
    [genderData.data],
  );

  return useMemo(() => ({
    loading: genderData.loading,
    options: genderOptions,
  }), [genderData.loading, genderOptions]);
};
