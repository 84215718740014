// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import linkifyHtml from 'linkify-html';
import styled from 'styled-components';

// Local Typings
interface Props {
  content: string;
  type?: 'location';
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.location-href': {
    color: theme.palette.grey[700],
  },
}));

// Component Definition
const EventDetail: FC<Props> = ({
  content,
  type,
}) => {
  // To try to match Google Calendar's UI, we change a location's anchor
  //  tag color from the stock browser "blue" to a dark grey
  const locationIsUrl = type === 'location' && content.startsWith('http');

  const textContent = locationIsUrl
    ? (
      <Typography variant="body2">
        <a
          className="location-href"
          href={content}
        >
          {content}
        </a>
      </Typography>
    ) : (
      <Typography
        color="textSecondary"
        component="div"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(content),
        }}
        sx={{ whiteSpace: 'pre-wrap' }}
        variant="body2"
      />
    );

  return (
    <StyledRoot>
      <Box>
        {textContent}
      </Box>
    </StyledRoot>
  );
};

export default EventDetail;
