// External Dependencies
import {
  CreateFundraiserCreditAssignmentRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { FC, MouseEvent, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';
import { UserRoles } from '@presto-assistant/api_types';
import { getFullName } from '@presto-assistant/api_types/utils';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Internal Dependencies
import { EnhancedAlert, SaveButton } from 'components/shared';
import { SimpleUserWithRole, useGetMember } from 'gql/queries';
import CustomInput from 'components/shared/CustomInput';
import ShowPageDataDisplay from 'components/shared/ShowPageDataDisplay';
import UserTypeAhead from 'components/shared/TypeAheads/UserTypeAhead';

// Local Typings
interface Props {
  assignedMemberIds: string[];
  isSubmitting: boolean;
  memberId?: string | null;
  onClose: () => void;
  requiredAmountInCents: number | null;
}

// Component Definition
const AddAssignmentForm: FC<Props> = ({
  assignedMemberIds,
  isSubmitting,
  memberId,
  onClose,
  requiredAmountInCents,
}) => {
  const formikContext = useFormikContext<CreateFundraiserCreditAssignmentRequest>();

  const handleSelectUser = useCallback((user: SimpleUserWithRole | null) => {
    if (!user) {
      formikContext.setFieldValue('memberId', '');
      return;
    }

    formikContext.setFieldValue('memberId', user.id);
  // do not add formikContext as a dependency, it will cause an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    formikContext.resetForm();
    onClose();
  // do not add formikContext as a dependency, it will cause an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const handleClickSubmit = useCallback((evt: MouseEvent) => {
    evt.preventDefault();

    if (assignedMemberIds.includes(formikContext.values.memberId)) {
      formikContext.setFieldError('memberId', 'This member is already assigned to this fundraiser credit.');
    } else {
      formikContext.submitForm();
    }
  }, [assignedMemberIds, formikContext]);

  const {
    data: memberData,
    loading: isMemberLoading,
  } = useGetMember(memberId);

  const memberName = memberData?.user ? getFullName({
    firstName: memberData.user.firstName,
    lastName: memberData.user.lastName,
  }) : '';

  return (
    <Form>
      <DialogContent>
        {!isMemberLoading && !memberData && (
          <UserTypeAhead
            onSelectUser={handleSelectUser}
            roleId={UserRoles.Student.toString()}
          />
        )}

        {memberName && (
          <ShowPageDataDisplay
            label="Member"
            value={memberName}
          />
        )}

        <Collapse in={!!formikContext.errors.memberId}>
          <EnhancedAlert
            severity="error"
            sx={{ marginTop: 1 }}
          >
            {formikContext.errors.memberId}
          </EnhancedAlert>
        </Collapse>

        {requiredAmountInCents === null && (
          <CustomInput
            isCurrency
            label="Amount"
            name="amountInCents"
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>

        <SaveButton
          color="primary"
          isSaving={isSubmitting}
          onClick={handleClickSubmit}
        >
          Submit
        </SaveButton>
      </DialogActions>
    </Form>
  );
};

export default AddAssignmentForm;
