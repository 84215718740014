// External Dependencies
import {
  Box,
  CircularProgress,
  List,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import styled from 'styled-components';

// Internal Dependencies
import {
  ShowCard,
  UserRelationshipListItem,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useGetRelationshipsByMemberId, useIsFamilyMember } from 'gql/queries';

// Local Dependencies
import RelationshipCreateForm from './RelationshipCreateForm';

// Local Typings
interface Props {
  memberId: string;
  memberRoleId: GQL.IUser['role']['id'];
}

// Local Variables
const StyledShowCard = styled(ShowCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const RelationshipsCard: FC<Props> = ({
  memberId,
  memberRoleId,
}) => {
  const isMobileScreen = useSelector(isMobileScreenSize);
  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const isFamilyMember = useIsFamilyMember(memberId);

  const variables: GQL.IUserRelationshipsByMemberIdOnQueryArguments = {
    memberId,
  };

  const { data, loading } = useGetRelationshipsByMemberId(variables);

  // We only show the confirmed relationships
  const confirmedRelationships = data?.userRelationshipsByMemberId
    .filter((relationship) => relationship.isConfirmed);

  return (
    <Box mb={2}>
      <StyledShowCard
        icon={PeopleIcon}
        title="Relationships"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading
          ? (
            <Box
              display="flex"
              justifyContent="center"
              margin={2}
            >
              <CircularProgress />
            </Box>
          ) : confirmedRelationships && confirmedRelationships.length > 0
            ? (
              <List dense={isMobileScreen}>
                {confirmedRelationships?.map((relationship) => {
                  const isSender = relationship.sender.id === memberId;

                  const relationshipTypeData = isSender
                    ? relationship.recipient
                    : relationship.sender;

                  const relationshipType = isSender
                    ? relationship.recipientType
                    : relationship.senderType;

                  return (
                    <UserRelationshipListItem
                      canDelete={!isFamilyMember}
                      key={relationship.id}
                      relationshipId={relationship.id}
                      relationshipType={relationshipType}
                      userData={relationshipTypeData}
                    />
                  );
                })}
              </List>
            )
            : (
              <Typography
                color="textSecondary"
                sx={{ marginBottom: 2 }}
              >
                No relationships added yet
              </Typography>
            )}
        {memberId && canEditUsers && (
          <RelationshipCreateForm
            memberRoleId={memberRoleId}
            userId={memberId}
          />
        )}
      </StyledShowCard>
    </Box>
  );
};

export default RelationshipsCard;
