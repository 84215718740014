// External Dependencies
import {
  Box,
  Divider,
} from '@mui/material';
import { FC } from 'react';
import CommunicationIcon from '@mui/icons-material/Chat';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
  EnhancedAlert,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import AdditionalCcEmails from './AdditionalCcEmails';
import EmailSettings from './EmailSettings';

// Component Definition
const CommunicationSettings: FC = () => {
  // EmailSettings uses organization, so we want to be sure this loads before rendering
  const { loading: isLoadingOrganization } = useGetOrganization();

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.SETTINGS}`,
      }}
      isLoading={isLoadingOrganization}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <ShowCard
          icon={CommunicationIcon}
          title="Communication Settings"
        >
          <EnhancedAlert sx={{ mb: 2 }}>
            CC settings are only applied when emails are sent to members in your organization.
            {' '}
            An email from a member to a director will not include addresses from the CC list.
          </EnhancedAlert>

          <EmailSettings />

          <Box marginY={2}>
            <Divider />
          </Box>

          <AdditionalCcEmails />
        </ShowCard>
      </Container>
    </Page>
  );
};

export default CommunicationSettings;
