// External Dependencies
import { ComponentType, FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Internal Dependencies
import { getToken } from 'utils/cookies';
import FourOhFour from 'pages/FourOhFour';

// Local Typings
interface Props {
  children?: ReactNode;
  hasPermission?: boolean;
}

export interface AuthRouteProps {
  // The route component to render
  component: ComponentType<any>;
  hasPermission?: boolean;
  path: string;
}

// Component Definition
const Auth: FC<Props> = ({ children, hasPermission }) => {
  const isLoggedIn = Boolean(getToken());

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    return (
      <Navigate
        replace
        to="/login"
      />
    );
  }

  // Show 404 page if the user does not have permission
  if (hasPermission === false) {
    return <FourOhFour />;
  }

  // Render children (component) or Outlet for nested routes
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : <Outlet />;
};

export default Auth;
