// External Dependencies
import {
  Box, CardContent, Chip, TableCell, TableHead, TableRow, Typography, alpha,
} from '@mui/material';
import styled from 'styled-components';

// Internal Dependencies
import { AddButton } from 'components/shared';
import { PropsWithTheme } from 'types/styled';

export const StyledAddButton = styled(AddButton)({
  fontSize: 15,
  padding: '5px 15px 5px 12px',
});

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.stripeBlue['300'], 0.1),
  fontSize: theme.spacing(2),
  margin: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} 0`,
  padding: theme.spacing(0.5),
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
}));

export const StyledFooterCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.0em',
  lineHeight: 1,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const StyledTotalCell = styled(StyledFooterCell)({
  fontWeight: 500,
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
}));

export const StyledTableRow = styled(TableRow)((
  {
    $cellVerticalAlign = 'top',
    theme,
  }: PropsWithTheme<{
    $cellVerticalAlign?: 'middle' | 'top';
  }>,
) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 13,
  },

  '& td': {
    verticalAlign: $cellVerticalAlign,
  },
})) as any;

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

export const StyledTableCellTextBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0),
})) as typeof Box;
