// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateGroup } from 'gql/mutations';

// Local Dependencies
import GroupsForm from '../shared/GroupsForm';

// Component Definition
const GroupsNew: FC = () => {
  const navigate = useNavigate();
  const groupsParams = useSelector(tableQueryParams('groups'));

  const pathToGroupsIndexTable = `/${PATHS.GROUPS}${groupsParams}`;

  const navigateToShow = useCallback(() => {
    navigate(pathToGroupsIndexTable);
  }, [navigate, pathToGroupsIndexTable]);

  const [createGroup] = useCreateGroup(
    {
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateGroupInput,
  ) =>
    createGroup({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'All Groups',
        path: pathToGroupsIndexTable,
      }}
    >
      <GroupsForm
        initialValues={{
          label: '',
          userIds: [],
        }}
        onSubmit={handleSubmitForm}
      />
    </Page>
  );
};

export default GroupsNew;
