// External Dependencies
import { createUniformSchema } from '@presto-assistant/api_types/schemas/uniform';

// Internal Dependencies
import { mergeYupSchemas } from 'utils';
import { yup } from 'utils/yup';

const districtOnlySchema = yup.object().shape({
  orgTypeId: yup
    .string()
    .required('Required'),
  organizationId: yup
    .string()
    .required('Required'),
});

export const createDistrictUniformSchema = mergeYupSchemas(
  createUniformSchema,
  districtOnlySchema,
);
