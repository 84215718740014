// External Dependencies
import { FC } from 'react';
import { useDebounce } from 'use-debounce/lib';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { SimpleInventoryItem, useGetInventoryItemsTypeAhead } from 'gql/queries';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  // Intentionally `any` type
  // We don't care what the caller does with this
  onSelectInventoryItem: (uniform: SimpleInventoryItem | null) => any;
}

// Component Definition
const InventoryTypeAhead: FC<Props> = ({
  onSelectInventoryItem,
}) => {
  const textField = useTextField('');
  const [searchValue] = useDebounce(textField.value, 500);

  const {
    data,
    loading,
  } = useGetInventoryItemsTypeAhead(searchValue);

  const isLoading = loading || searchValue.trim() !== textField.value.trim();

  const handleChange = (_evt: any, inventoryItem: SimpleInventoryItem | null) => {
    onSelectInventoryItem(inventoryItem);
  };

  return (
    <Autocomplete
      disablePortal
      filterOptions={(inventoryItem) => inventoryItem}
      getOptionDisabled={(inventoryItem) => Boolean(inventoryItem.activeCheckout)}
      getOptionLabel={(inventoryItem) =>
        `${inventoryItem.label}, ${inventoryItem.brand} ${inventoryItem.model || ''}${inventoryItem.serialNumber ? `${', Serial No. '}${inventoryItem.serialNumber}` : ''}`}
      isOptionEqualToValue={(inventoryItem, value) => inventoryItem.id === value.id}
      loading={isLoading}
      onChange={handleChange}
      options={data?.inventoryItems.data ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Search Inventory"
          variant="filled"
        />
      )}
      renderOption={(props, option) => {
        const isCheckedOut = Boolean(option.activeCheckout);

        return (
          <ListItem {...props}>
            <ListItemText
              primary={`${option.label}${isCheckedOut ? ' (Checked Out)' : ''}`}
              secondary={(
                <>
                  <Typography
                    color="textPrimary"
                    component="span"
                    variant="body2"
                  >
                    {option.brand}{option.model && ` ${option.model || ''}`}
                  </Typography>
                  {option.serialNumber && `${', Serial No. '}${option.serialNumber}`}
                </>
              )}
            />
          </ListItem>
        );
      }}
    />
  );
};

export default InventoryTypeAhead;
