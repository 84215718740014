// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import ArrowTopRightThickIcon from 'mdi-material-ui/ArrowTopRightThick';

// Internal Dependencies
import { ListItemWithSecondaryAction } from 'components/shared';
import { useBecomeUser } from 'hooks/useBecomeUser';

// Local Typings
interface Props {
  organizationId: string;
  userId: string;
}

// Component Definition
const BecomeThisMemberListItem: FC<Props> = ({ organizationId, userId }) => {
  const handleBecomeMember = useBecomeUser(organizationId);

  const handlePressBecomeThisMember = useCallback(() =>
    handleBecomeMember(userId), [handleBecomeMember, userId]);

  const secondaryAction = useMemo(() => ({
    buttonIcon: <ArrowTopRightThickIcon />,
    buttonText: 'Switch',
    onClick: handlePressBecomeThisMember,
  }), [handlePressBecomeThisMember]);

  return (
    <ListItemWithSecondaryAction
      primaryText="Become This Member"
      secondaryAction={secondaryAction}
      secondaryText="Temporarily see a read-only view as this member."
    />
  );
};

export default BecomeThisMemberListItem;
