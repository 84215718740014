// External Dependencies
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';

// Internal Dependencies
import { isDirector } from 'state/self/selectors';
import { useQueryEnhanced } from 'utils/lib/graphql';

export const GET_DASHBOARD_METRICS = gql`
  query DashboardMetrics {
    dashboardMetrics {
      gradeCounts {
        count
        grade
      }
      userOrgRoleCounts {
        byGrade {
          count
          grade
        }
        id
        label
        total
      }
      userRoleCounts {
        count
        role {
          id
          label
        }
      }
    }
  }
`;

export const GET_GETTING_STARTED_COUNTS = gql`
  query GettingStartedCounts {
    dashboardMetrics {
      directorCount
      emailCount
      inventoryCount
      libraryCount
      uniformCount
      userRoleCounts {
        count
        role {
          id
          label
        }
      }
    }
  }
`;

export const GET_ITEMS_NEEDING_ATTENTION = gql`
  query ItemsNeedingAttention {
    itemsNeedingAttention {
      id
      message
      url
    }
  }
`;

export const useGetDashboardMetrics = () => {
  const isUserDirector = useSelector(isDirector);

  return useQueryEnhanced<{
    dashboardMetrics: GQL.IDashboardMetrics;
  }>(
    GET_DASHBOARD_METRICS,
    {
      // A parent with elevated permissions should not be able
      //  hit this endpoint
      skip: !isUserDirector,
    },
  );
};

export const useGetGettingStartedCounts = () => {
  const isUserDirector = useSelector(isDirector);

  return useQueryEnhanced<{
    dashboardMetrics: GQL.IDashboardMetrics;
  }>(
    GET_GETTING_STARTED_COUNTS,
    {
      // A parent with elevated permissions should not be able
      //  hit this endpoint
      skip: !isUserDirector,
    },
  );
};

export const useGetItemsNeedingAttention = () =>
  useQueryEnhanced<{
    itemsNeedingAttention: GQL.IItemNeedingAttention[];
  }>(GET_ITEMS_NEEDING_ATTENTION);
