// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { ReportTypes } from '@presto-assistant/api_types';
import {
  districtFormResponsesReportSchema,
  districtInventoryAuditReportSchema,
  financialTransactionReportSchema,
} from '@presto-assistant/api_types/schemas/reports';
import { useFormikContext } from 'formik';

// Internal Dependencies
import { CustomInput, CustomSelect, DatePickerField } from 'components/shared';
import { SelectOption } from 'components/shared/CustomSelect';
import { useDistrictForms } from 'utils/api/forms';
import { useGetDistrictInventoryAudits } from 'utils/api/district';
import DistrictOrganizationSelect from 'components/shared/Selectors/DistrictOrganizationSelect';

// Local Typings
interface Props {
  reportTypeId: ReportTypes;
  setYupSchema: (schema: any | null) => void;
}

// Component Definition
const DynamicFormFields: FC<Props> = ({
  reportTypeId,
  setYupSchema,
}) => {
  const formikContext = useFormikContext();

  const {
    data: districtInventoryAuditsData,
  } = useGetDistrictInventoryAudits();

  const {
    data: districtFormsData,
  } = useDistrictForms();

  const inventoryAuditOptions = useMemo<SelectOption[]>(() => {
    return districtInventoryAuditsData?.data.data.map((audit) => ({
      id: audit.id,
      label: audit.label,
    })) ?? [];
  }, [districtInventoryAuditsData]);

  const formOptions = useMemo<SelectOption[]>(() => {
    return districtFormsData?.filter((form) => form.publishedAt)
      .map((form) => ({
        id: form.id,
        label: form.title,
      })) ?? [];
  }, [districtFormsData]);

  useEffect(() => {
    const firstFormId = formOptions[0]?.id;
    const firstInventoryAuditId = inventoryAuditOptions[0]?.id;

    switch (reportTypeId) {
      case ReportTypes.DistrictFinancialTransactionReport:
        formikContext.setFieldValue('payload', {
          endDateUtc: null,
          label: '',
          startDateUtc: null,
        });
        break;
      case ReportTypes.DistrictInventoryAuditReport:
        formikContext.setFieldValue('payload', {
          inventoryAuditId: firstInventoryAuditId ?? '',
          label: '',
          organizationId: null,
        });
        break;
      case ReportTypes.DistrictFormResponsesReport:
        formikContext.setFieldValue('payload', {
          formId: firstFormId ?? '',
          label: '',
        });
        break;
      default:
        formikContext.setFieldValue('payload', {});
    }
  // intentionally omitting formikContext from dependency array, as it causes an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formOptions,
    inventoryAuditOptions,
    reportTypeId,
  ]);

  useEffect(() => {
    switch (reportTypeId) {
      case ReportTypes.DistrictFinancialTransactionReport:
        setYupSchema(financialTransactionReportSchema);
        break;
      case ReportTypes.DistrictInventoryAuditReport:
        setYupSchema(districtInventoryAuditReportSchema);
        break;
      case ReportTypes.DistrictFormResponsesReport:
        setYupSchema(districtFormResponsesReportSchema);
        break;
      default:
        setYupSchema(null);
    }
  }, [reportTypeId, setYupSchema]);

  const fields = useMemo(() => {
    switch (reportTypeId) {
      case ReportTypes.DistrictFinancialTransactionReport:
        return (
          <>
            <CustomInput
              label="Report Name"
              name="payload.label"
              required
            />

            <DatePickerField
              label="Start Date"
              name="payload.startDateUtc"
              required
            />

            <DatePickerField
              label="End Date"
              name="payload.endDateUtc"
            />
          </>
        );
      case ReportTypes.DistrictInventoryAuditReport:
        return (
          <>
            <CustomSelect
              label="Inventory Audit"
              name="payload.inventoryAuditId"
              options={inventoryAuditOptions}
              required
            />

            <CustomInput
              label="Report Name"
              name="payload.label"
              required
            />

            <DistrictOrganizationSelect
              helperText="Optionally select an organization to view a partial report."
              name="payload.organizationId"
              orgTypeId={null}
              required={false}
            />
          </>
        );
      case ReportTypes.DistrictFormResponsesReport:
        return (
          <>
            <CustomSelect
              label="Form"
              name="payload.formId"
              options={formOptions}
              required
            />

            <CustomInput
              label="Report Name"
              name="payload.label"
              required
            />
          </>
        );
      default:
        return null;
    }
  }, [
    formOptions,
    inventoryAuditOptions,
    reportTypeId,
  ]);

  return fields;
};

export default DynamicFormFields;
