// Internal Dependencies
import { AuthRouteProps } from 'components/shared/Auth';
import { PATHS } from 'utils/constants/routes';
import FindOrganization from 'pages/FindOrganization';

export const useGetOrphanUserRoutes = (): AuthRouteProps[] => {
  const generalRoutes: AuthRouteProps[] = [
    {
      component: FindOrganization,
      path: PATHS.FIND_ORGANIZATION,
    },
  ];

  return [
    ...generalRoutes,
  ];
};
