// External Dependencies
import {
  InventoryAuditAbsenceReasons,
  OrgTypes,
} from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { SelectOption } from 'components/shared/CustomSelect';
import { dateColumn } from 'utils/lib/tableColumns';
import { getFullName } from 'utils';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetDistrictInventoryCategories } from 'utils/api/inventory';

// Local Typings
// This is taken from the part of the API response interface
export interface NotFoundItem {
  absenceReasonId: number;
  absenceReasonNote: string;
  auditedAt: string;
  auditedByMember: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  };
  inventoryItem: {
    brand: string | null;
    categoryId: number;
    id: string;
    label: string;
    model: string | null;
    organizationLabel: string | null;
    serialNumber: string | null;
  };
}

// This reconfigured interface helps us type the "field" property
interface NotFoundColumn extends NotFoundItem {
  inventoryItemBrand: NotFoundColumn['inventoryItem']['brand'];
  inventoryItemCategoryId: NotFoundColumn['inventoryItem']['categoryId'];
  inventoryItemLabel: NotFoundColumn['inventoryItem']['label'];
  inventoryItemModel: NotFoundColumn['inventoryItem']['model'];
  inventoryItemOrganizationLabel: NotFoundColumn['inventoryItem']['organizationLabel'];
  inventoryItemSerialNumber: NotFoundColumn['inventoryItem']['serialNumber'];
}

// Local Variables
const mappedAbsenceReasons = mapEnum(InventoryAuditAbsenceReasons);

export const useColumns = () => {
  const {
    data: categoryData,
  } = useGetDistrictInventoryCategories();

  const categoryOptions = useMemo(() => {
    return categoryData?.data.data.map((category) => ({
      label: `(${toTitleCase(OrgTypes[category.organizationTypeId])}) ${category.label}`,
      value: category.id,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [categoryData]);

  const absenceReasonOptions: SelectOption[] = useMemo(() =>
    mappedAbsenceReasons.map((absenceReason) => ({
      id: absenceReason.id,
      label: toTitleCase(absenceReason.label),
      value: toTitleCase(absenceReason.label),
    })), []);

  return useMemo(() => {
    const columns: DataGridColDef<NotFoundColumn>[] = [
      {
        field: 'inventoryItemLabel',
        headerName: 'Item Name',
        valueGetter: (params) => params.row.inventoryItem.label,
      },
      {
        field: 'inventoryItemBrand',
        headerName: 'Brand',
        valueGetter: (params) => params.row.inventoryItem.brand,
      },
      {
        field: 'inventoryItemCategoryId',
        headerName: 'Category',
        minWidth: 144,
        type: 'singleSelect',
        valueFormatter: (params) => params.value,
        valueGetter: (params) => (categoryOptions
          ? categoryOptions.find((option) =>
            option.value === params.row.inventoryItem.categoryId)?.label
          : ''),
        valueOptions: categoryOptions,
      },
      {
        field: 'absenceReasonId',
        headerName: 'Not Found Reason',
        minWidth: 160,
        type: 'singleSelect',
        valueFormatter: (params) => params.value,
        valueGetter: (params) => absenceReasonOptions.find((option) =>
          option.id === params.row.absenceReasonId)?.label,
        valueOptions: absenceReasonOptions,
      },
      {
        field: 'inventoryItemOrganizationLabel',
        headerName: 'Organization',
        minWidth: 200,
        valueGetter: (params) => params.row.inventoryItem.organizationLabel,
      },
      dateColumn({
        field: 'auditedAt',
        headerName: 'Audit Date',
        minWidth: 108,
      }),
      {
        field: 'absenceReasonNote',
        headerName: 'Note',
        minWidth: 300,
      },
      {
        field: 'auditedByMember',
        headerName: 'Audited By',
        minWidth: 240,
        valueGetter: (params) =>
          `${getFullName(params.row.auditedByMember)} (${params.row.auditedByMember.email})`,
      },
      {
        field: 'inventoryItemModel',
        headerName: 'Model',
        valueGetter: (params) => params.row.inventoryItem.model,
      },
      {
        field: 'inventoryItemSerialNumber',
        headerName: 'Serial #',
        valueGetter: (params) => params.row.inventoryItem.serialNumber,
      },
    ];

    return columns;
  }, [absenceReasonOptions, categoryOptions]);
};
