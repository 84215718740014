// External Dependencies
import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { navigateSearch } from 'utils/lib/navigate_search';
import { open } from 'state/ui/paymentAlertDialog/actions';
import { parseSearch } from 'utils';
import { useGetMyFinancialFeesIndex } from 'gql/queries';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useMyFilesSelfData } from 'hooks/useMyFilesSelfData';
import ChildSelect from 'components/shared/Selectors/ChildSelect';
import DialogPaymentAlert from 'components/shared/DialogPaymentAlert';
import MyFinancialStatementButton from 'components/shared/MyFinancialStatementButton';
import TableV2, { CreateActionColumnAction, createActionsColumn } from 'components/shared/TableV2';

// Local Dependencies
import { useCanMakePayments } from 'hooks/useCanMakePayments';
import { useColumns } from './hooks';
import Filters from './Filters';

// Local Variables
const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

// Component Definition
const MyFinancialFeesTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data,
    loading,
  } = useGetMyFinancialFeesIndex();

  const canMakePayments = useCanMakePayments();

  const { search } = useLocation();

  const parsedSearch = useMemo(() => parseSearch(search), [search]);

  const selfData = useMyFilesSelfData();
  const isStudent = selfData?.isStudent;

  const handleOpenPaymentAlertDialog = useCallback((hasNoFeesToPayOnline: boolean) => {
    dispatch(open(hasNoFeesToPayOnline));
  }, [dispatch]);

  const handleNavigateToOnlinePaymentForm = useCallback(() => {
    navigate(`/${PATHS.STUDENT_PAYMENTS_NEW}`);
  }, [navigate]);

  const extraColumns = useMemo(() => {
    const moreActions: CreateActionColumnAction<GQL.IFinancialFeeIndexItem>[] = canMakePayments
      ? [{
        action: (row) => {
          const shouldSeePaymentAlertDialog = isStudent || !row.onlinePaymentsEnabled;

          return shouldSeePaymentAlertDialog
            ? handleOpenPaymentAlertDialog(!row.onlinePaymentsEnabled)
            : handleNavigateToOnlinePaymentForm();
        },
        icon: <MoneyIcon />,
        isDisabled: (row) => row.balanceDueInCents === 0,
        text: 'Make a Payment',
      }]
      : [];

    const actionColumn = createActionsColumn<GQL.IFinancialFeeIndexItem>(moreActions);

    return actionColumn ? [actionColumn] : [];
  }, [
    canMakePayments,
    isStudent,
    handleNavigateToOnlinePaymentForm,
    handleOpenPaymentAlertDialog,
  ]);

  const columns = useColumns(extraColumns);

  // Parent user needs to check for relationships in this org
  const hasRelationships = useHasRelationships();

  const handleChangeUserId = useCallback((userId: string) => {
    navigateSearch(
      navigate,
      {
        ...parsedSearch,
        page: 1,
        userId,
      },
    );
  }, [navigate, parsedSearch]);

  let selectElement;

  // if parent with relationships, show the select
  //  and default to the parent's MyFinancialFees table
  if (!isStudent && hasRelationships) {
    selectElement = (
      <EnhancedCard sx={{ mb: 2 }}>
        <CardContent>
          <SectionSubtitle
            component="h2"
            variant="subtitle1"
          >
            Select a person
          </SectionSubtitle>

          <ChildSelect
            handleChangeUserId={handleChangeUserId}
            userId={parsedSearch.userId ?? selfData?.selfId}
          />
        </CardContent>
      </EnhancedCard>
    );
  }

  return (
    <>
      {selectElement}

      <TableV2<GQL.IFinancialFeeIndexItem>
        Filters={<Filters />}
        columns={columns}
        data={data?.myFinancialFeesIndex.data ?? []}
        fullCount={data?.myFinancialFeesIndex.fullCount}
        isLoading={loading}
        paperProps={{ variant: 'outlined' }}
        reduxStateKey="myFinancialFees"
        withSearch
        zeroStateMessage="Fees assigned from your directors will show here."
      />
      <DialogPaymentAlert />

      <Box sx={{ marginTop: 2 }}>
        <MyFinancialStatementButton />
      </Box>
    </>
  );
};

export default MyFinancialFeesTable;
