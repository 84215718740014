// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import gql from 'graphql-tag';

// Internal Dependencies
import {
  GET_NON_RETURNING_USERS,
  GET_RETURNING_GROUPS,
} from 'gql/queries';
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface AdvanceEndOfYearStepResponse {
  advanceEndOfYearStep: {
    id: string;
    maxCompletedEndOfYearStep: number;
  }
}

export interface CreateNonReturningUsersResponse {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

interface CreateReturningGroupsResponse {
  returningGroups: GQL.IReturningGroup[];
}

const CREATE_RETURNING_GROUPS = gql`
  mutation CreateReturningGroups(
    $input: [CreateReturningGroupsInput!]!
  ) {
    createReturningGroups (
      input: $input
    ) {
      group {
        id
        label
        userCount
      }
      id
      shouldRetainUsers
    }
  }
`;

export const useCreateReturningGroups = (
  options?: MutationOptions<
    CreateReturningGroupsResponse,
    GQL.ICreateReturningGroupsOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateReturningGroupsResponse,
  GQL.ICreateReturningGroupsOnMutationArguments
>(
  CREATE_RETURNING_GROUPS,
  {
    refetchQueries: () => [getOperationName(GET_RETURNING_GROUPS) as string],
    ...options,
  },
);

export const CREATE_NON_RETURNING_USERS = gql`
  mutation CreateNonReturningUsers(
    $input: CreateNonReturningUsersInput!
  ) {
    createNonReturningUsers(
      input: $input
    ) {
      email
      id
      firstName
      lastName
    }
  }
`;

export const useCreateNonReturningUsers = (
  options?: MutationOptions<
    CreateNonReturningUsersResponse,
    GQL.ICreateNonReturningUsersOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateNonReturningUsersResponse,
  GQL.ICreateNonReturningUsersOnMutationArguments
>(
  CREATE_NON_RETURNING_USERS,
  {
    refetchQueries: () => [getOperationName(GET_NON_RETURNING_USERS) as string],
    ...options,
  },
);

export const ADVANCE_END_OF_YEAR_STEP = gql`
  mutation AdvanceEndOfYearStep(
    $step: Int!
  ) {
    advanceEndOfYearStep(
      step: $step
    ) {
      id
      maxCompletedEndOfYearStep
    }
  }
`;

export const useAdvanceEndOfYearStep = (
  options?: MutationOptions<
    AdvanceEndOfYearStepResponse,
    GQL.IAdvanceEndOfYearStepOnMutationArguments
  >,
) => useMutationEnhanced<
  AdvanceEndOfYearStepResponse,
  GQL.IAdvanceEndOfYearStepOnMutationArguments
>(
  ADVANCE_END_OF_YEAR_STEP,
  {
    ...options,
    clearCachePredicates: ['organization'],
  },
);

const COMPLETE_END_OF_YEAR_PROCESS = gql`
  mutation CompleteEndOfYearProcess {
    completeEndOfYearProcess
  }
`;

export const useCompleteEndOfYearProcess = (
  options?: MutationOptions<
    boolean,
    null
  >,
) => useMutationEnhanced(
  COMPLETE_END_OF_YEAR_PROCESS,
  options,
);
