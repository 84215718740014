// External Dependencies
import { Component, ErrorInfo, ReactElement } from 'react';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
} from 'components/shared';
import { formatDateTime } from 'utils/lib/date_helpers';
import { getGradientBackground } from 'utils/lib/getGradientBackground';

// Local Dependencies
import { SHORT_APP_NAME } from 'utils/constants';
import Footer from './Footer';
import SkewedBackground from './SkewedBackground';
import packageJson from '../../../package.json';

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.MuiCardHeader-root': {
    margin: 0,
    padding: 0,
  },
  '.MuiCardHeader-title': {
    background: getGradientBackground(theme),
    color: theme.palette.mode === 'dark'
      ? theme.palette.common.black : theme.palette.common.white,
    padding: theme.spacing(2.5, 2),
    textTransform: 'uppercase',
  },
  '.emailLink': {
    marginTop: 16,
  },
  '.errorContent': {
    color: theme.palette.error.main,
    fontSize: '1rem',
  },
  '.errorMessage': {
    fontWeight: 600,
  },
  '.heading': {
    margin: '48px 0',
  },
  display: 'flex',
  flexDirection: 'column',
  main: {
    zIndex: 2,
  },
}));

// Local Typings
interface Props {
  children: ReactElement;
}
interface State {
  error: Error;
  hasError: boolean;
}

// Component Definition
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: {
        message: '',
        name: '',
        stack: '',
      },
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.log('ErrorBoundary : error', error);
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;
    const { version } = packageJson;
    const userAgent = window.navigator?.userAgent;
    const { href } = window.location;

    const rowSeparator = '\r\n\r\n';
    const date = formatDateTime(new Date());
    const supportEmail = 'mailto:support@presto-assistant.com';
    const subject = 'We uncovered an error in Presto Assistant.';

    const data: [string, string | undefined | null][] = [
      ['Please briefly describe what you were doing before this error occured', `${rowSeparator}${rowSeparator}${rowSeparator}${rowSeparator}${rowSeparator}`],
      ['*** Please keep all the following data for the Presto team to better assist and understand the error', ''],
      ['WEB VERSION', version],
      ['USER AGENT', userAgent],
      ['ERROR MESSAGE', error.message],
      ['ERROR STACK', error.stack],
      ['ERROR OCCURRED', date],
      ['URL', href],
    ];

    const body = data.map(([k, v]) => `${k}: ${v}`).join(rowSeparator);

    const decodedEmailHref = `${supportEmail}?subject=${subject}&body=${body}`;

    const emailHref = encodeURI(decodedEmailHref);

    if (hasError) {
      return (
        <StyledContainer maxWidth="md">
          <SkewedBackground />

          <main>
            <Typography
              className="heading"
              component="h1"
              variant="h3"
            >
              Something went wrong.
              <span
                aria-label="disappointed emoji"
                role="img"
              >
                😥
              </span>
            </Typography>

            <EnhancedAlert
              severity="warning"
              title="Oh no"
            >
              <Typography>
                We hate when this happens.
                Get in touch to help us get this fixed as soon as we can.
              </Typography>

              <Typography
                className="emailLink"
                sx={{
                  display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 3,
                }}
                variant="subtitle2"
              >
                <Button
                  href={emailHref}
                  startIcon={<SendIcon fontSize="small" />}
                  variant="outlined"
                >
                  Send Email to {SHORT_APP_NAME}
                </Button>

                <Button
                  href="/dashboard"
                  variant="outlined"
                >
                  Go to dashboard
                </Button>
              </Typography>
            </EnhancedAlert>

            <EnhancedCard sx={{ mt: 3 }}>
              <CardHeader
                component="h2"
                title="Error Details"
                titleTypographyProps={{
                  variant: 'h5',
                }}
              />

              <CardContent>
                <Typography gutterBottom>
                  The error message below might help us figure out what happened:
                </Typography>

                <blockquote className="errorContent">
                  <code className="errorMessage">{error.message}</code>
                  <div>
                    <code>{error.stack}</code>
                  </div>
                </blockquote>
              </CardContent>
            </EnhancedCard>
          </main>

          <Footer
            hideLinks
            showHelpLink
          />
        </StyledContainer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
