// External Dependencies
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { getToken } from 'utils/cookies';
import { isDistrictAdmin as isDistrictAdminSelector, tokenData } from 'state/self/selectors';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  userRole: 'organizationUser' | 'districtAdmin' | 'orphanUser';
}

// Component Definition
const RoleRouter: FC<Props> = ({ userRole }) => {
  const location = useLocation();
  const isLoggedIn = !!getToken();
  const isDistrictAdmin = useSelector(isDistrictAdminSelector);
  const hasTokenData = !!useSelector(tokenData);
  const { self } = useSelfQuery();

  // Redirect to home if not logged in
  if (!isLoggedIn) {
    const queryString = getSearchWithRedirectUrl();
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={`/?${queryString}`}
      />
    );
  }

  // Return null while waiting for token data or self query
  if (!self || !hasTokenData) {
    return null;
  }

  const isOrganizationUser = Boolean(self.currentOrgId);
  const isOrphanUser = !isDistrictAdmin && !isOrganizationUser;

  // Handle routes based on user role
  if (isDistrictAdmin && userRole === 'districtAdmin') {
    return <Outlet />;
  }

  if (isOrganizationUser && userRole === 'organizationUser') {
    return <Outlet />;
  }

  if (isOrphanUser && userRole === 'orphanUser') {
    return <Outlet />;
  }

  // Redirect based on role if not matched with userRole
  if (isDistrictAdmin) {
    return (
      <Navigate
        replace
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`}
      />
    );
  }

  if (isOrganizationUser) {
    return (
      <Navigate
        replace
        to={`/${PATHS.DASHBOARD}`}
      />
    );
  }

  return (
    <Navigate
      replace
      to={`/${PATHS.MISSING_ORGANIZATION}/${PATHS.FIND_ORGANIZATION}`}
    />
  );
};

export default RoleRouter;
