import gql from 'graphql-tag';

export const INVENTORY_CATEGORY_OPTIONS_QUERY = gql`
  query InventoryCategorySelectOptions($organizationTypeId: ID!) {
    inventoryCategories(organizationTypeId: $organizationTypeId) {
      id
      label
    }
  }
`;
