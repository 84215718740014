// External Dependencies
import { AxiosResponse } from 'axios';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material/';
import { FC, useState } from 'react';

// Internal Dependencies
import { PaperAirplaneSvg } from 'components/shared';
import { recoverPassword } from 'utils/api';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import EnhancedDialog from './EnhancedDialog';
import Form from './Form';
import SaveButton from './SaveButton';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const RecoverPasswordDialog: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const emailField = useTextField('');
  const [resError, setResError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleResetState = () => {
    setResError('');
    setSuccessMessage('');
    emailField.onReset();
  };

  const handleSubmit = async () => {
    setIsSending(true);

    try {
      setResError('');
      const response: AxiosResponse = await recoverPassword({
        email: emailField.value.trim(),
      });

      if (response.status === 404) {
        setResError(response.data.error);
      } else if (response.status === 422) {
        setResError(response.data.errors.email[0]);
      } else {
        setSuccessMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      if ((error as AxiosResponse).status === 422) {
        setResError((error as AxiosResponse).data.errors.email[0]);
      } else {
        setResError('Unable to submit email.');
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <EnhancedDialog
      TransitionProps={{
        onExited: handleResetState,
      }}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
    >
      {successMessage ? (
        <>
          <DialogTitle>
            {successMessage}
          </DialogTitle>
          <DialogContent>
            <PaperAirplaneSvg />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            Recover Password
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Box mb={3}>
                <DialogContentText>
                  Enter your email below, and we&apos;ll email
                  you instructions to reset your password.
                </DialogContentText>
              </Box>
              <TextField
                {...emailField}
                error={!!resError}
                fullWidth
                helperText={resError}
                id="forgot-password-email"
                label="Email Address"
                name="email"
                variant="filled"
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={emailField.value.trim().length === 0}
                isSaving={isSending}
              >
                Submit
              </SaveButton>
            </DialogActions>
          </Form>
        </>
      )}
    </EnhancedDialog>
  );
};

export default RecoverPasswordDialog;
