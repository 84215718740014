// External Dependencies
import {
  Box, Button, Card, CircularProgress, List, ListItem, ListItemText, Paper,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import {
  ConfirmationDialog, Flex, Subtitle, ZeroState,
} from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { useGetMyOrganizationRequests } from 'gql/queries';
import { useRespondToDistrictInvitation } from 'gql/mutations';

// Component Definition
const InvitationsToDfaList: FC = () => {
  const [input, setInput] = useState<GQL.IRespondToInvitationToDfaInput | null>(null);
  const [organizationName, setOrganization] = useState('this organization');

  const dispatch = useDispatch();

  const handleResetInput = useCallback(() => {
    setInput(null);
  }, [setInput]);

  const handleCompleted = useCallback(() => {
    dispatch(addNotification(`Invitation ${input?.isAccepting ? 'accepted!' : 'rejected.'}`, 'success'));
    handleResetInput();
  }, [input, setInput]);

  const [mutation] = useRespondToDistrictInvitation({ onCompleted: handleCompleted });

  const handleConfirm = () => {
    if (input) {
      mutation({
        variables: { input },
      });
    }
  };

  const {
    data,
    loading,
  } = useGetMyOrganizationRequests();

  const handleClickConfirmButton = useCallback((invitationId: string) => () => {
    setInput({
      invitationId,
      isAccepting: true,
    });
  }, [setInput]);

  const handleClickDeclineButton = useCallback((invitationId: string) => () => {
    setInput({
      invitationId,
      isAccepting: false,
    });
  }, [setInput]);

  const selectedRequest = useMemo(
    () => (input?.invitationId
      ? data?.myOrganizationRequests.find((request) => request.id === input.invitationId)
      : null),
    [input],
  );

  useEffect(() => {
    if (selectedRequest) {
      setOrganization(selectedRequest.organization.label);
    }
  }, [selectedRequest]);

  if (loading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  if ((data?.myOrganizationRequests.length ?? 0) === 0) {
    return (
      <Card>
        <ZeroState message="You have no pending invitations at this time." />
      </Card>
    );
  }

  return (
    <>
      <Subtitle>
        Organization requests
      </Subtitle>

      <Paper variant="outlined">
        <List>
          {data?.myOrganizationRequests.map((request, index) => (
            <ListItem
              divider={data.myOrganizationRequests.length - 1 > index}
              key={request.id}
            >
              <ListItemText
                primary={request.organization.label}
                secondary={`${request.organization.entityType.label} ${request.organization.organizationType.label} - ${request.invitedBy.email}`}
              />

              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-end"
                ml={3}
              >
                <Button
                  color="primary"
                  onClick={handleClickDeclineButton(request.id)}
                  size="small"
                  sx={{ m: 0.5 }}
                  variant="outlined"
                >
                  Decline
                </Button>

                <Button
                  color="primary"
                  onClick={handleClickConfirmButton(request.id)}
                  size="small"
                  sx={{ m: 0.5 }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>

      <ConfirmationDialog
        confirmButtonAction={handleConfirm}
        declineButtonAction={handleResetInput}
        description={`Claim ${organizationName} as belonging to your district.`}
        handleClose={handleResetInput}
        open={!!input?.isAccepting}
        title="Confirm organization request?"
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirm}
        declineButtonAction={handleResetInput}
        description={`Decline ${organizationName} as belonging to your district.`}
        handleClose={handleResetInput}
        open={input?.isAccepting === false}
        title="Decline organization request?"
      />
    </>
  );
};

export default InvitationsToDfaList;
