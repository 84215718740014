// External Dependencies
import { GridPreProcessEditCellProps } from '@mui/x-data-grid-pro';
import {
  InventoryConditions,
  InventoryFundingSources,
  InventoryQualities,
} from '@presto-assistant/api_types';
import { InventoryIndexResponseItem } from '@presto-assistant/api_types/api/v1/inventory';
import { createAndUpdateInventoryItemSchema } from '@presto-assistant/api_types/schemas/inventoryItem';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { currencyFromCentsColumn, dateColumn } from 'utils/lib/tableColumns';
import { displayDynamicFieldCell } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { mapEnum } from 'utils/lib/map_enum';
import {
  renderCheckboxCell,
  renderEditCell,
  renderSelectEditInputCell,
} from 'components/shared/TableDataGrid/helpers';
import { runValidationSchema } from 'utils';
import { selectEditModeTable } from 'state/table/selectors';
import {
  useGetDistrictOrganizations,
  useGetDynamicFields,
  useGetInventoryCategoryOptions,
} from 'gql/queries';

// Local Dependencies
import QuickCheckoutButton from './QuickCheckoutButton';

// Local Variables
const emptyOption = {
  id: '',
  label: '',
};

// Add starting empty value for edit mode
const conditionDataWithEmptyOption = [
  emptyOption,
  ...mapEnum(InventoryConditions),
];
const qualityDataWithEmptyOption = [
  emptyOption,
  ...mapEnum(InventoryQualities),
];

export const useColumns = ({
  extraColumns,
  onClickQuickCheckout,
  tableResource,
}: {
  extraColumns?: DataGridColDef<InventoryIndexResponseItem>[];
  onClickQuickCheckout: (inventoryItemId: string) => void;
  tableResource: 'inventoryItems';
}) => {
  const currentEditModeTable = useSelector(selectEditModeTable);

  const isEditModeActive = currentEditModeTable === tableResource;

  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'inventory_items',
  });

  const {
    data,
  } = useGetDistrictOrganizations();

  const {
    data: inventoryCategoryData,
  } = useGetInventoryCategoryOptions();

  const canEditInventoryBasicInfo = useSelector(hasPermission('inventory', 'edit'));
  const canEditInventoryStatusInfo = useSelector(hasPermission('inventoryStatusInfo', 'edit'));
  const canEditInventoryLocationInfo = useSelector(hasPermission('inventoryLocationInfo', 'edit'));
  const canEditInventoryPurchaseInfo = useSelector(hasPermission('inventoryPurchaseInfo', 'edit'));

  const mappedInventoryCategoryOptions = useMemo(() =>
    inventoryCategoryData?.inventoryCategories.map(
      (option) => ({
        id: option.id,
        label: option.label,
        value: option.label,
      }),
    ), [inventoryCategoryData]);

  const mappedInventoryConditionOptions = useMemo(() =>
    conditionDataWithEmptyOption.map(
      (option) => ({
        id: option.id,
        label: option.label,
        value: option.label,
      }),
    ), []);

  const mappedInventoryQualityOptions = useMemo(() =>
    qualityDataWithEmptyOption.map(
      (option) => ({
        id: option.id,
        label: option.label,
        value: option.label,
      }),
    ), []);

  const mappedInventoryFundingSourceOptions = useMemo(() =>
    mapEnum(InventoryFundingSources).map((option) => ({
      label: toTitleCase(option.label),
      value: option.id,
    })), []);

  const preProcessEditCellProps = async (
    params: GridPreProcessEditCellProps,
    fieldName: keyof GQL.IUpdateInventoryItemInput,
  ) => {
    const value = params.props.value!.toString();

    // Validate like Formik does
    const errorMessage: any = await runValidationSchema<GQL.IUpdateInventoryItemInput>(
      value,
      fieldName,
        createAndUpdateInventoryItemSchema as any,
    );

    return {
      ...params.props,
      error: errorMessage[fieldName as keyof GQL.IUpdateInventoryItemInput],
    };
  };

  return useMemo(() => {
    const organizations = data?.districtOrganizations ?? [];

    const basicInfoColumns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        editable: canEditInventoryBasicInfo,
        field: 'categoryLabel',
        headerName: 'Category',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: mappedInventoryCategoryOptions ?? [],
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => params.row.categoryLabel,
        valueOptions: mappedInventoryCategoryOptions ?? [],
        valueSetter: (params) => {
          const foundInventoryCategory = mappedInventoryCategoryOptions?.find(
            (option) => option.label === params.value,
          );

          return {
            ...params.row,
            categoryId: foundInventoryCategory
              ? parseInt(foundInventoryCategory.id, 10)
              : params.row.categoryId,
            categoryLabel: foundInventoryCategory?.label ?? params.value,
          };
        },
        width: 292,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'label',
        headerName: 'Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'label',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'brand',
        headerName: 'Brand',
        renderEditCell,
        width: 292,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'model',
        headerName: 'Model',
        renderEditCell,
        width: 292,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'serialNumber',
        headerName: 'Serial #',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'customBarcode',
        headerName: 'Barcode',
        renderEditCell,
        width: 292,
      },
      {
        editable: canEditInventoryBasicInfo,
        field: 'districtNumber',
        headerName: 'District #',
        renderEditCell,
        width: 160,
      },
    ];

    const statusInfoColumns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        editable: canEditInventoryStatusInfo,
        field: 'conditionLabel',
        headerName: 'Condition',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: mappedInventoryConditionOptions ?? [],
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => params.row.conditionLabel,
        valueOptions: mappedInventoryConditionOptions ?? [],
        valueSetter: (params) => {
          const foundInventoryCondition = mappedInventoryConditionOptions?.find(
            (option) => option.label === params.value,
          );

          const updatedConditionId = foundInventoryCondition
            ? parseInt(foundInventoryCondition.id, 10)
            : params.row.conditionId;

          return {
            ...params.row,
            conditionId: updatedConditionId || null,
            conditionLabel: foundInventoryCondition?.label ?? params.value,
          };
        },
        width: 160,
      },
      currencyFromCentsColumn({
        field: 'currentValueInCents',
        headerName: 'Current Value',
      }),
      {
        editable: canEditInventoryStatusInfo,
        field: 'accessories',
        headerName: 'Accessories',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryStatusInfo,
        field: 'qualityLabel',
        headerName: 'Quality',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: mappedInventoryQualityOptions ?? [],
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => params.row.qualityLabel,
        valueOptions: mappedInventoryQualityOptions ?? [],
        valueSetter: (params) => {
          const foundInventoryQuality = mappedInventoryQualityOptions?.find(
            (option) => option.label === params.value,
          );

          return {
            ...params.row,
            qualityId: foundInventoryQuality
              ? parseInt(foundInventoryQuality.id, 10)
              : params.row.qualityId,
            qualityLabel: foundInventoryQuality?.label ?? params.value,
          };
        },
        width: 160,
      },
      {
        editable: canEditInventoryStatusInfo,
        field: 'isMissing',
        headerName: 'Is Missing',
        renderCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        renderEditCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        type: 'boolean',
        width: 80,
      },
      {
        editable: canEditInventoryStatusInfo,
        field: 'comments',
        headerName: 'Comments',
        renderEditCell,
        width: 160,
      },
      {
        field: 'checkedOutTo',
        headerName: 'Checked Out To',
        minWidth: 200,
        renderCell: (params) => {
          return params.value || (
            <QuickCheckoutButton
              inventoryItemId={params.row.id}
              onClick={onClickQuickCheckout}
            />
          );
        },
        valueGetter: ((params) => {
          // This handles the case where a student does not have an email address
          // ex John Smith () will become John Smith
          return params.row.checkedOutTo?.replaceAll('()', '').trim();
        }),
      },
    ];

    const locationInfoColumns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        editable: canEditInventoryLocationInfo,
        field: 'location',
        headerName: 'Location',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryLocationInfo,
        field: 'caseNumber',
        headerName: 'Case #',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryLocationInfo,
        field: 'locker',
        headerName: 'Locker',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryLocationInfo,
        field: 'lockNumber',
        headerName: 'Lock Number',
        renderEditCell,
        width: 160,
      },
      {
        editable: canEditInventoryLocationInfo,
        field: 'combination',
        headerName: 'Combination',
        renderEditCell,
        width: 160,
      },
      ...(organizations.length > 1 ? [{
        field: 'onLoanToOrganizationLabel',
        headerName: 'On Loan To',
        type: 'singleSelect',
        valueOptions: organizations
          .map((o) => ({
            label: o.label,
            value: o.label,
          })) ?? [],
      }] : []) as DataGridColDef<InventoryIndexResponseItem>[],
    ];

    const purchaseInfoColumns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        editable: canEditInventoryPurchaseInfo,
        field: 'purchaseYear',
        headerName: 'Purchase Year',
        renderEditCell,
        type: 'number',
        // the value formatter is used to prevent the "type: number" cell from rendering as 2,021
        valueFormatter: (params) => params.value,
        width: 160,
      },
      dateColumn({
        editable: canEditInventoryPurchaseInfo,
        field: 'purchasedAt',
        headerName: 'Purchase Date',
      }),
      {
        editable: canEditInventoryPurchaseInfo,
        field: 'purchaseOrderNumber',
        headerName: 'Purchase Order Number',
        renderEditCell,
        width: 160,
      },
      currencyFromCentsColumn({
        field: 'purchaseValueInCents',
        headerName: 'Purchase Value',
      }),
      {
        field: 'inventoryFundingSourceId',
        headerName: 'Funding Source',
        type: 'singleSelect',
        valueOptions: mappedInventoryFundingSourceOptions,
        width: 80,
      },
    ];

    const dynamicFieldInfoColumns: DataGridColDef<InventoryIndexResponseItem>[] = [
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<DataGridColDef<InventoryIndexResponseItem>>((field) => ({
          field: field.dynamicFieldRef as keyof InventoryIndexResponseItem,
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return [
      ...basicInfoColumns,
      ...statusInfoColumns,
      ...locationInfoColumns,
      ...purchaseInfoColumns,
      ...dynamicFieldInfoColumns,
    ];
  }, [
    canEditInventoryBasicInfo,
    canEditInventoryLocationInfo,
    canEditInventoryPurchaseInfo,
    canEditInventoryStatusInfo,
    data?.districtOrganizations,
    dynamicFieldData?.dynamicFields,
    extraColumns,
    isEditModeActive,
    mappedInventoryConditionOptions,
    mappedInventoryCategoryOptions,
    mappedInventoryQualityOptions,
    mappedInventoryFundingSourceOptions,
    onClickQuickCheckout,
  ]);
};
