// External Dependencies
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToMyForms = (): JSX.Element => {
  const myFormsParams = useSelector(tableQueryParams('myForms'));

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View forms that are assigned to you
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.MY_FORMS}${myFormsParams}`}>
        My Forms
      </ForwardButton>
    </Box>
  );
};

export default LinkToMyForms;
