// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Local Typings
interface Props {
  withGrid?: boolean;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.background': {
    backgroundColor: theme.palette.simpleBackground,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    height: 872,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: -300,
    transform: 'skewY(-12deg)',
    zIndex: 0,
  },
  '.backgroundStripe': {
    height: 48,
    width: '100%',
  },
  '.backgroundStripeLeftShifted': {
    left: -96,
    position: 'relative',
  },
  '.boxLightBlue': {
    backgroundColor: theme.palette.stripeBlue['500'],
  },
  '.boxRoot': {
    boxSizing: 'border-box',
  },
  '.gridGapColumn48': {
    marginLeft: -24,
    marginRight: -24,
  },
  '.gridItemColumnSpan4': {
    gridColumnEnd: 'span 4',
  },
  '.gridItemColumnStart7': {
    gridColumnStart: 7,
  },
  '.gridItemGap': {
    height: '100%',
  },
  '.gridItemGapColumn48': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  '.gridRoot': {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
  '.rightGrid': {
    position: 'absolute',
    right: -256,
    top: 384,
  },
  width: '100%',
}));

// Component Definition
const SkewedBackground: FC<Props> = ({ withGrid }) => {
  const grid = (
    <div className="gridRoot rightGrid gridGapColumn48">
      <div
        className="gridItemColumnStart7 gridItemColumnSpan4"
        style={{ gridRow: '1 / span 1' }}
      >
        <div className="gridItemGap gridItemGapColumn48">
          <div className="backgroundStripe backgroundStripeLeftShifted boxRoot boxLightBlue" />
        </div>
      </div>
    </div>
  );

  return (
    <StyledRoot id="skewed-background">
      <div className="background">
        {withGrid && grid}
      </div>
    </StyledRoot>
  );
};

export default SkewedBackground;
