// External Dependencies
import {
  CardHeader,
  CardHeaderProps,
} from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Local Typings
export type Title = 'Administrator' | 'Assistant Director' | 'Director' | 'District Assistant' | 'Parent' | 'Student';

interface Props extends CardHeaderProps {
  subheader: string;
  title: Title;
}

// Local Variables
const StyledCardHeader = styled(CardHeader)<Props>(({ theme }) => ({
  '.MuiCardHeader-root': {
    padding: 0,
  },

  '.MuiTypography-h5': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
    fontSize: '2em',
    fontWeight: 400,
    margin: '0.5em 0',
  },
}));

// Component Definition
const SignupCardHeader: FC<Props> = ({
  subheader,
  title,
}) => (
  <StyledCardHeader
    subheader={subheader}
    title={title}
    titleTypographyProps={{
      component: 'h2' as 'span' | undefined, // Not sure why we need to cast to this type 🤔
      variant: 'h5',
    }}
  />
);

export default SignupCardHeader;
