// External Dependencies
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { SubscriberAddButton } from 'components/shared';

// Local Typings
interface Props {
  userFullName: string;
  userId: string;
}

// Local Variables
const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '60%',
  },
});

// Component Definition
const AddPaymentListItem = ({
  userFullName,
  userId,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText
        primary="Add a new payment"
        secondary={`You can add another payment for ${userFullName} from this page.`}
        secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
      />

      <ListItemSecondaryAction>
        <SubscriberAddButton
          label="Payment"
          to={`/${PATHS.FINANCIAL_PAYMENTS_NEW}?userId=${userId}`}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AddPaymentListItem;
