// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateResponse {
  email: string;
  id: string;
}
interface CreateData {
  createDistrictAssistant: CreateResponse;
}
interface DeleteData {
  deleteDistrictAssistant: boolean;
}

// Local Variables
const CREATE_DISTRICT_ASSISTANT = gql`
  mutation CreateDistrictAssistant(
    $input: CreateDistrictAssistantInput!
  ) {
    createDistrictAssistant(input: $input) {
      email
      id
    }
  }
`;

const DELETE_DISTRICT_ASSISTANT = gql`
  mutation DeleteDistrictAssistant(
    $userId: ID
  ) {
    deleteDistrictAssistant(userId: $userId)
  }
`;

export const useCreateDistrictAssistant = (
  options?: MutationOptions<
    CreateData,
    GQL.ICreateDistrictAssistantOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateData,
  GQL.ICreateDistrictAssistantOnMutationArguments
>(
  CREATE_DISTRICT_ASSISTANT,
  options,
);

export const useDeleteDistrictAssistant = (
  options?: MutationOptions<
    DeleteData,
    GQL.IDeleteDistrictAssistantOnMutationArguments
  >,
) => useMutationEnhanced<
  DeleteData,
  GQL.IDeleteDistrictAssistantOnMutationArguments
>(
  DELETE_DISTRICT_ASSISTANT,
  options,
);
