// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import FolderIcon from '@mui/icons-material/Folder';

// Internal Dependencies
import { Flex } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFilesDirectoriesIndex } from 'gql/queries';
import TableV2, { TableColumn } from 'components/shared/TableV2';

// Component Definition
const FileDirectoriesTable: FC = () => {
  const theme = useTheme();

  const canWriteFiles = useSelector(hasPermission('s3Uploads', 'write'));

  const {
    data,
    loading,
  } = useGetFilesDirectoriesIndex();

  const directoryAccessor = ({
    directory,
  }: GQL.IFileDirectoryIndexItem) => (
    <Flex>
      <FolderIcon
        fontSize="small"
        htmlColor={theme.palette.grey[500]}
        sx={{ mr: 1.5 }}
      />

      {directory}
    </Flex>
  );

  const columns: TableColumn<GQL.IFileDirectoryIndexItem>[] = [
    {
      Header: 'Directory',
      accessor: (item) => directoryAccessor(item),
      sortBy: 'directory',
    },
    {
      Header: 'File Count',
      accessor: (item) => item.fileCount,
      sortBy: 'fileCount',
    },
  ];

  const addButtonProps = {
    label: 'Files',
    to: `/${PATHS.FILES}/new`,
  };

  return (
    <TableV2<GQL.IFileDirectoryIndexItem>
      addButtonProps={canWriteFiles ? addButtonProps : null}
      clickRowTo={(row) => `/${PATHS.FILES}/${row.directory}`}
      columns={columns}
      data={data?.fileDirectories.data ?? []}
      fullCount={data?.fileDirectories.fullCount}
      isLoading={loading}
      reduxStateKey="fileDirectories"
      withSearch
    />
  );
};

export default FileDirectoriesTable;
