// External Dependencies
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props {
  children: any;
  mode?: 'error' | 'warning';
}

// Local Variables
const StyledCard = styled(Card)(({ theme }) => ({
  '.cardContent': {
    display: 'flex',
  },
  '.error': {
    borderLeft: `4px solid ${theme.palette.error.main}`,
  },
  '.icon': {
    height: theme.spacing(3),
    paddingRight: theme.spacing(2),
    width: theme.spacing(3),
  },
  '.root': {
    minWidth: 275,
  },
}));

// TODO: Remove this component once all alerts use `<EnhancedAlert>`

// Component Definition
const Alert: FC<Props> = ({
  children,
  mode,
}) => {
  const theme = useTheme();

  return (
    <StyledCard
      className={
        clsx(
          'root',
          mode === 'error' && 'error',
          mode === 'warning' && 'warning',
        )
      }
    >
      <CardContent className="cardContent">
        {mode === 'error' && (
          <HighlightOffOutlinedIcon
            className="icon"
            htmlColor={theme.palette.alertColors.error.icon}
          />
        )}

        {mode === 'warning' && (
          <InfoOutlinedIcon
            className="icon"
            htmlColor={theme.palette.alertColors.warning.icon}
          />
        )}

        <Typography>
          {children}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default Alert;
