// External Dependencies
import * as Sentry from '@sentry/react';

// Internal Dependencies
import { IS_PRODUCTION, IS_STAGING } from 'utils/constants/app';
import packageJson from '../../package.json';

export const initializeSentry = () => {
  const { version } = packageJson;

  const additionalConfig = {} as Sentry.BrowserOptions;

  const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
  const enableBrowserTracing = process.env.REACT_APP_ENABLE_BROWSER_TRACING;
  const enableSessionReplay = process.env.REACT_APP_ENABLE_SESSION_REPLAY;

  if (!sentryDSN) {
    return;
  }

  const integrations = [];

  if (enableBrowserTracing) {
    const browserTracingConfig = new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for
      //  which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'local',
        // allows staging and production
        /^https:\/\/app\.(?:stg-presto|presto)-assistant\.com$/,
      ],
    });

    integrations.push(browserTracingConfig);

    // Performance Monitoring
    additionalConfig.tracesSampleRate = 1.0; //  Capture 100% of the transactions
  }

  if (enableSessionReplay) {
    const replayConfig = new Sentry.Replay({
      blockAllMedia: false,
      maskAllText: false,
    });

    integrations.push(replayConfig);

    // Session Replay
    // Only capture replays when an error occurs
    // Set the sample rate. You may want to change it to 100% while
    //  in development and then sample at a lower rate in production.
    additionalConfig.replaysSessionSampleRate = 0.1;
    // If you're not already sampling the entire session, change the
    //  sample rate to 100% when sampling sessions where errors occur.
    additionalConfig.replaysOnErrorSampleRate = 1.0;
  }

  Sentry.init({
    dsn: sentryDSN,
    enabled: IS_PRODUCTION || IS_STAGING,
    environment: process.env.REACT_APP_APPLICATION_ENVIRONMENT ?? 'local',
    integrations,
    maxBreadcrumbs: 50,
    release: version,
    tracesSampleRate: 0.3,
    ...additionalConfig,
  });
};
