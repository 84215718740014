// External Dependencies
import {
  Box,
  Collapse,
  List,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  Checkbox,
  ShowPageDataDisplay, StyledLink,
} from 'components/shared';
import { convertCentsToDollars, displayPriceStringFromDollarAmount, pluralize } from 'utils';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  fees: GQL.IFinancialFeeIndexItem[];
  userName: string;
}

// Local Variables
export const StyledStrong = styled.strong({ fontSize: '1.25rem' });

function comparator<T extends object>(a: T, b: T, key: keyof T) {
  return +(a[key] < b[key]) || +(a[key] === b[key]) - 1;
}

// Component Definition
const FeesList: FC<Props> = ({
  fees,
  userName,
}) => {
  const {
    isOpen: isShowingOnlyThisYearsFees,
    toggleIsOpen: toggleIsShowingOnlyThisYearsFees,
  } = useIsOpen(true);

  const {
    isOpen: isShowingOnlyFeesWithBalance,
    toggleIsOpen: toggleIsShowingOnlyFeesWithBalance,
  } = useIsOpen(true);

  const {
    isOpen: isViewingFees,
    toggleIsOpen: toggleViewFees,
  } = useIsOpen();

  const currentSchoolYearEnding = useGetOrganizationSchoolYear();

  const unpaidFeeCount = useMemo(() => fees
    .filter((fee) => fee.balanceDueInCents > 0)
    .length, [fees]);

  const filteredFees = useMemo(
    () => fees
      .filter((fee) =>
        !isShowingOnlyFeesWithBalance || fee.balanceDueInCents > 0)
      .filter((fee) =>
        !isShowingOnlyThisYearsFees || fee.schoolYearEnding === currentSchoolYearEnding)
      .sort((a, b) =>
        comparator(a, b, 'schoolYearEnding') || comparator(a, b, 'balanceDueInCents')),
    [
      currentSchoolYearEnding,
      fees,
      isShowingOnlyFeesWithBalance,
      isShowingOnlyThisYearsFees,
    ],
  );

  return (
    <>
      <Box
        alignItems="baseline"
        display="flex"
        mb={2}
      >
        <Typography component="p">
          {userName} has
          {' '}
          <StyledStrong>{unpaidFeeCount}</StyledStrong>
          {' '}
          {pluralize(unpaidFeeCount, 'unpaid fee')}.
        </Typography>

        <Box ml={1}>
          <StyledLink onClick={toggleViewFees}>
            {isViewingFees ? 'Hide' : 'View'}
          </StyledLink>
        </Box>
      </Box>

      <Collapse
        in={isViewingFees}
        mountOnEnter
      >
        <Box
          display="flex"
          flexDirection="column"
          marginBottom={2}
        >
          <Checkbox
            checked={isShowingOnlyThisYearsFees}
            label="Current Year Only"
            onChange={toggleIsShowingOnlyThisYearsFees}
          />

          <Checkbox
            checked={isShowingOnlyFeesWithBalance}
            label="Hide Paid Fees"
            onChange={toggleIsShowingOnlyFeesWithBalance}
          />
        </Box>

        <List>
          {filteredFees.map((fee) => (
            <li key={fee.id}>
              <ShowPageDataDisplay
                label={fee.financialItemLabel}
                value={`${displayPriceStringFromDollarAmount(
                  convertCentsToDollars(
                    fee.balanceDueInCents,
                  ),
                )} remaining`}
              />
            </li>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default FeesList;
