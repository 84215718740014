// External Dependencies
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';

// Internal Dependencies
import { CustomCheckbox } from 'components/shared';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
}

// Component Definition
const CheckboxBlock = ({
  disabled,
  formBlock,
}: Props): JSX.Element => {
  return (
    <CustomCheckbox
      disabled={disabled}
      label={formBlock.label}
      name="response"
      required={formBlock.isRequired}
    />
  );
};

export default CheckboxBlock;
