// External Dependencies
import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

// Internal Dependencies
import {
  Container, EnhancedCard, Flex, SaveButton, Subtitle,
} from 'components/shared';
import { ROLES, ROLE_IDS } from 'utils/constants';
import { SimpleUser } from 'gql/queries';
import { UserTypeAhead } from 'components/shared/TypeAheads';

// Local Typings
interface Props {
  isSubmitting?: boolean;
  onSelectUser: (user: SimpleUser) => any;
  submitButtonText?: string;
}

// Component Definition
const SelectUser: FC<Props> = ({
  isSubmitting,
  onSelectUser,
  submitButtonText = 'Next',
}) => {
  const [user, setUser] = useState<SimpleUser | null>(null);

  const handleClickButton = () => {
    if (user) {
      onSelectUser(user);
    }
  };

  return (
    <Container maxWidth={500}>
      <Subtitle>
        Start typing and select a member
      </Subtitle>

      <EnhancedCard>
        <CardContent>
          <UserTypeAhead
            disabled={isSubmitting}
            onSelectUser={setUser}
            roleId={ROLE_IDS[ROLES.STUDENT]}
          />
        </CardContent>

        <Box
          mb={1}
          mr={1}
        >
          <Flex justifyContent="flex-end">
            <SaveButton
              color="primary"
              disabled={!user}
              isSaving={isSubmitting}
              onClick={handleClickButton}
              variant="contained"
            >
              {submitButtonText}
            </SaveButton>
          </Flex>
        </Box>
      </EnhancedCard>
    </Container>
  );
};

export default SelectUser;
