// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

export const SUBMIT_FEEDBACK = gql`
  mutation Feedback(
    $input: CreateFeedbackInput!
  ) {
    submitFeedback(input: $input)
  }
`;

export const useSubmitFeedback = (
  options?: MutationOptions<
    boolean,
    GQL.ISubmitFeedbackOnMutationArguments
  >,
) =>
  useMutationEnhanced<
  boolean,
    GQL.ISubmitFeedbackOnMutationArguments
  >(
    SUBMIT_FEEDBACK,
    options,
  );
