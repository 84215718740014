// External Dependencies
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page, ShowCardHeader } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFormAsDirector } from 'gql/queries/form-queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import AssignmentDetailsCard from './AssignmentDetailsCard';
import DetailsCard from '../components/DetailsCard';
import ResponsesTable from './ResponsesTable';

// Component Definition
const FormShow = (): JSX.Element => {
  const navigate = useNavigate();

  const formsParams = useSelector(tableQueryParams('forms'));

  const { id } = useParamsWithId();

  const {
    data: formData,
  } = useGetFormAsDirector(id);

  const form = useMemo(() => {
    return formData?.form;
  }, [formData]);

  useEffect(() => {
    if (form && !form.publishedAt) {
      navigate(`/forms/${form.id}/edit`, { replace: true });
    }
  }, [form, navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'Forms',
        path: `/${PATHS.FORMS}${formsParams}`,
      }}
    >
      {form && (
        <>
          <Container maxWidth="md">
            <>
              <Box marginTop={2}>
                <DetailsCard
                  form={form}
                  isEditMode={false}
                />
              </Box>

              <Box marginTop={2}>
                <AssignmentDetailsCard
                  form={form}
                />
              </Box>
            </>
          </Container>

          <Container maxWidth="lg">
            <Box marginTop={2}>
              <ShowCardHeader
                sx={{ marginBottom: 1 }}
                title="Responses"
              />

              <ResponsesTable formId={form.id} />
            </Box>
          </Container>
        </>
      )}
    </Page>
  );
};

export default FormShow;
