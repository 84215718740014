import gql from 'graphql-tag';

export const CREATE_S3_FILES = gql`
  mutation CreateS3Files(
    $fileNames: [String]!
    $files: [Upload]!
  ) {
    createS3Files(
      fileNames: $fileNames
      files: $files
    )
  }
`;
