// External Dependencies
import {
  DistrictFormsIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/form';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { useDistrictForms } from 'utils/api/forms';
import { useIsOpen } from 'hooks/useIsOpen';
import ConfirmDeleteFormDialog from 'pages/Forms/components/ConfirmDeleteFormDialog';
import ConfirmUnpublishFormDialog from 'pages/Forms/components/ConfirmUnpublishFormDialog';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}/${id}`;

// Component Definition
const AdminFormsTable = (): JSX.Element => {
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);

  const {
    isOpen: isConfirmDeleteDialogOpen,
    toggleIsOpen: toggleConfirmDeleteDialog,
  } = useIsOpen();
  const {
    isOpen: isConfirmUnpublishDialogOpen,
    toggleIsOpen: toggleConfirmUnpublishDialog,
  } = useIsOpen();

  const {
    data,
    isLoading,
  } = useDistrictForms();

  const handleClickDelete = useCallback((id: string) => {
    setSelectedFormId(id);
    toggleConfirmDeleteDialog();
  }, [toggleConfirmDeleteDialog]);

  const handleClickUnpublish = useCallback((id: string) => {
    setSelectedFormId(id);
    toggleConfirmUnpublishDialog();
  }, [toggleConfirmUnpublishDialog]);

  const actionsColumn = useMemo<GridColDef<DistrictFormsIndexResponseItem> | null>(() => {
    return createDataGridActionsColumn<DistrictFormsIndexResponseItem>([
      {
        action: (row) => handleClickUnpublish(row.id),
        icon: <RemoveIcon />,
        isDisabled: (row) => !row.publishedAt,
        text: 'Unpublish',
      },
      {
        action: (row) => handleClickDelete(row.id),
        icon: <DeleteIcon />,
        text: 'Delete',
      },
    ]);
  }, [handleClickDelete, handleClickUnpublish]);

  const columnArgs = useMemo(() => ({
    actionsColumn,
  }), [actionsColumn]);

  const tableColumns = useColumns(columnArgs);

  return (
    <>
      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            addButtonProps={{
              label: 'Form',
              to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS_NEW}`,
            }}
            clickRowTo={handleTableClickRow}
            columns={tableColumns}
            getRowId={(row: DistrictFormsIndexResponseItem) => row.id}
            loading={isLoading}
            rows={data}
            tableResource="districtForms"
            withSearch
            zeroStateMessage="Assign a new form to members across the district."
          />
        </DataGridContainer>
      </EnhancedCard>

      <ConfirmDeleteFormDialog
        formId={selectedFormId}
        isOpen={isConfirmDeleteDialogOpen}
        onClose={toggleConfirmDeleteDialog}
      />

      <ConfirmUnpublishFormDialog
        formId={selectedFormId}
        isOpen={isConfirmUnpublishDialogOpen}
        onClose={toggleConfirmUnpublishDialog}
      />
    </>
  );
};

export default AdminFormsTable;
