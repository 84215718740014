// External Dependencies
import {
  GridEditInputCell,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

// Local Variables
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

/*
 * Must be used in a MUI DataGrid context.
 *
 * Use the EditInputCell when a cell has validation that
 *  can trigger an error message. This component will show
 *  the error message in a red tooltip just below the cell.
 */

// Component Definition
const EditInputCell = (props: GridRenderEditCellParams) => {
  // MUI DataGrid is assigning a React element object to `type`.
  // That's not great, so we pull it out to avoid any console errors.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, type, ...otherProps } = props;

  return (
    <StyledTooltip
      open={!!error}
      title={error ?? ''}
    >
      <div>
        <GridEditInputCell
          error={Boolean(error)}
          {...otherProps}
        />
      </div>
    </StyledTooltip>
  );
};

export default EditInputCell;
