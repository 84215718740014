// External Dependencies
import { InputBaseComponentProps } from '@mui/material/InputBase';

// Internal Dependencies
import {
  CustomSelect,
  District,
} from 'components/shared';

// Local Typings
interface Props {
  allowUnassignedOption?: boolean;
  inputProps?: InputBaseComponentProps;
  orgTypeId: string | null;
  unassignedOptionText?: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const OrganizationSelect = ({
  allowUnassignedOption,
  inputProps,
  orgTypeId,
  unassignedOptionText = '(Unassigned)',
}: Props): JSX.Element => (
  <District>
    {({ district }) => {
      const { organizations } = district;

      const orgsMatchingOrgTypeId = organizations.filter(
        (org) =>
          orgTypeId === null
          || org?.organizationType?.id === orgTypeId,
      );

      if (allowUnassignedOption) {
        orgsMatchingOrgTypeId.unshift({
          id: '-1',
          label: unassignedOptionText,
        } as GQL.IOrganization);
      }

      return (
        <CustomSelect
          inputProps={inputProps}
          label="Organization *"
          name="organizationId"
          options={orgsMatchingOrgTypeId}
          required
        />
      );
    }}
  </District>
);

export default OrganizationSelect;
