// External Dependencies
import { FC, useCallback } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
  CustomInput,
  ErrorMessage,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  error: string | undefined;
  initialValues?: Partial<GQL.ICreateDistrictAssistantInput>;
  isLoading: boolean;
  onSubmit?: (values: GQL.ICreateDistrictAssistantInput) => Promise<void>;
  validationSchema?: any;
}

// Local Variables
const defaultInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
};

// Component Definition
const DistrictAssistantForm: FC<Props> = ({
  error,
  initialValues = {},
  isLoading,
  onSubmit,
  validationSchema,
}) => {
  const navigate = useNavigate();
  const districtAssistantsParams = useSelector(tableQueryParams('districtAssistants'));

  const districtAssistantsTablePath = `/${PATHS.DISTRICT_ADMIN}/${PATHS.DISTRICT_ASSISTANTS}?${districtAssistantsParams}`;

  const isEditing = Boolean(initialValues.email);

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(districtAssistantsTablePath);
  }, [districtAssistantsTablePath, navigate]);

  const handleFormikSubmit = async (
    values: GQL.ICreateDistrictAssistantInput,
    formikProps: FormikHelpers<GQL.ICreateDistrictAssistantInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  return (
    <Container maxWidth={CONTAINER_WIDTH}>
      <Formik<GQL.ICreateDistrictAssistantInput>
        initialValues={{
          ...defaultInitialValues,
          ...initialValues,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          touched,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Box mb={2}>
                <ShowCard
                  icon={AccountCircleIcon}
                  title="Profile Info"
                >
                  <CustomInput
                    label="First Name *"
                    name="firstName"
                  />

                  <CustomInput
                    label="Middle Name"
                    name="middleName"
                  />

                  <CustomInput
                    label="Last Name *"
                    name="lastName"
                  />
                </ShowCard>
              </Box>

              <Box mb={2}>
                <ShowCard
                  icon={CellphoneWirelessIcon}
                  title="Contact Info"
                >
                  <CustomInput
                    label="Email *"
                    name="email"
                  />
                </ShowCard>
              </Box>

              <FormRouteActions
                context="District Assistant"
                isEditing={isEditing}
                isFormTouched={isFormTouched}
                isSubmitting={isLoading}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />

              {error && (
                <Box marginY={2}>
                  <ErrorMessage
                    condition={!!error}
                    message={error}
                  />
                </Box>
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default DistrictAssistantForm;
