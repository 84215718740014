// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
} from 'components/shared';

// Component Definition
const ChecklistItemsFormUI: FC = () => (
  <>
    <CustomInput
      label="Checklist Item"
      name="label"
    />

    <Box marginLeft={1}>
      <CustomCheckbox
        label="Required"
        name="isRequired"
      />
    </Box>
  </>
);

export default ChecklistItemsFormUI;
