// External Dependencies
import { QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

const GET_DISTRICT_INVITATIONS = gql`
  query DistrictInvitations {
    districtInvitations {
      accepted
      activationCode
      createdAt
      district {
        admin {
          email
          firstName
          id
          lastName
        }
        id
        label
      }
      id
      recipientEmail
    }
  }
`;

export interface DistrictInvitation {
  accepted: boolean | null;
  activationCode: string;
  createdAt: string;
  district: {
    admin: {
      email: string;
      firstName: string;
      id: string;
      lastName: string;
    };
    id: string;
    label: string;
  };
  id: string;
  recipientEmail: string;
}

export const useGetDistrictInvitations = (
  options?: QueryHookOptions,
) => useQueryEnhanced<{
  districtInvitations: DistrictInvitation[];
}>(
  GET_DISTRICT_INVITATIONS,
  options,
);
