// External Dependencies
import {
  InventoryAuditsByDistrictIdResponseItem,
} from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';

// Local Variables
export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<InventoryAuditsByDistrictIdResponseItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
      },
      dateColumn({
        field: 'dueDate',
        headerName: 'Due Date',
      }),
      {
        field: 'numberOfOrganizationsAssigned',
        headerName: '# Assigned',
        type: 'number',
      },
      {
        field: 'numberOfOrganizationsCompleted',
        headerName: '# Completed',
        type: 'number',
      },
    ];

    return columns;
  }, []);
};
