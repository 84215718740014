// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import ScheduledEmailsTable from './ScheduledEmailsTable';

// Component Definition
const ScheduledEmails: FC = () => (
  <>
    <Subtitle>
      Scheduled Emails
    </Subtitle>
    <EnhancedCard>
      <ScheduledEmailsTable />
    </EnhancedCard>
  </>
);

export default ScheduledEmails;
