// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { useGetPaymentTypes } from 'gql/queries';

// Local Typings
interface PaymentTypeOptionArgs {
  removeOnlineOption?: boolean;
}

type UseGetPaymentTypeOptionsArgs = PaymentTypeOptionArgs | undefined;

// Hook Definition
export const useGetPaymentTypeOptions = (
  args?: UseGetPaymentTypeOptionsArgs,
) => {
  const { removeOnlineOption } = args ?? {};

  const {
    data: paymentTypesData,
    loading: paymentTypesLoading,
  } = useGetPaymentTypes();

  return useMemo(() => {
    // Some parts of the app like entering or editing payments should not
    // show the "Online" payment type option
    const filteredTypes = paymentTypesData?.financialPaymentTypes
      .filter((paymentType) =>
        !removeOnlineOption || paymentType.id !== FinancialPaymentTypes.Stripe.toString());

    const options = (filteredTypes?.map((option) => ({
      id: option.id,
      label: Number(option.id) === FinancialPaymentTypes.Stripe ? 'Online' : option.label,
      value: option.label,
    })) ?? []);

    options
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      loading: paymentTypesLoading,
      options,
    };
  }, [
    paymentTypesData?.financialPaymentTypes,
    paymentTypesLoading,
    removeOnlineOption,
  ]);
};
