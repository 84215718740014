// External Dependencies
import { FC } from 'react';
import { FinancialCreditClosureTypes } from '@presto-assistant/api_types';
import DonateIcon from '@mui/icons-material/Handshake';
import ReturnIcon from 'mdi-material-ui/ArrowULeftBottom';

// Internal Dependencies
import { CLOSE_FINANCIAL_CREDIT } from 'gql/mutations';
import { ListItemWithSecondaryAction } from 'components/shared';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Typings
interface Props {
  closureTypeId: FinancialCreditClosureTypes;
  creditId: string;
}

// Component Definition
const CloseCreditListItem: FC<Props> = ({
  closureTypeId,
  creditId,
}) => {
  const {
    handleClose,
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const formatPayload: DeleteDialogV2Props<GQL.ICloseFinancialCreditOnMutationArguments>['formatPayload'] = (note) => {
    const input: GQL.ICloseFinancialCreditInput = {
      closureTypeId,
      note: note ?? '',
    };

    return {
      id: creditId,
      input,
    };
  };

  const closureType = FinancialCreditClosureTypes[closureTypeId];

  const actionVerb = closureTypeId === FinancialCreditClosureTypes.Donated
    ? 'Donate' : 'Return';

  return (
    <>
      <ListItemWithSecondaryAction
        primaryText={`${actionVerb} credit`}
        secondaryAction={{
          buttonIcon: closureTypeId === FinancialCreditClosureTypes.Donated
            ? <DonateIcon /> : <ReturnIcon />,
          buttonText: closureType,
          onClick: toggleIsOpen,
        }}
        secondaryText={closureTypeId === FinancialCreditClosureTypes.Donated
          ? 'Donate the remainder of this credit to the organization.'
          : 'Return the remainder of this credit to the member.'}
      />

      <DeleteDialogV2<GQL.ICloseFinancialCreditOnMutationArguments>
        actionVerb={actionVerb}
        clearCachePredicates={['financialCredits', 'financialCreditsIndex', 'financialCreditOverview', 'itemsNeedingAttention']}
        context={['credit']}
        descriptionText={closureTypeId === FinancialCreditClosureTypes.Donated
          ? 'This will donate the remainder of this credit to the organization.'
          : 'This will return the remainder of this credit to the member.'}
        formatPayload={formatPayload}
        isOpen={isOpen}
        mutation={CLOSE_FINANCIAL_CREDIT}
        onClose={handleClose}
        withNote
      />
    </>
  );
};

export default CloseCreditListItem;
