// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedContainer,
  ShowStudentInventoryCheckoutUI,
} from 'components/shared';
import { useGetMyInventoryCheckouts } from 'gql/queries';

// Component Definition
const MyInventoryCheckouts: FC = () => {
  const { data } = useGetMyInventoryCheckouts();

  if (!data) {
    return null;
  }

  const { inventoryCheckouts } = data.myInventoryCheckouts;

  return (
    <EnhancedContainer>
      <ShowStudentInventoryCheckoutUI
        inventoryCheckoutsData={inventoryCheckouts}
      />
    </EnhancedContainer>
  );
};

export default MyInventoryCheckouts;
