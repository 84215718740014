// External Dependencies
import { globalEditResources } from '@presto-assistant/api_types/api/v1/globalEdit';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useGetGlobalEditContract = (resource: typeof globalEditResources[number] | null) => {
  const queryFn = useCallback(async () => {
    return apiClient.v1.globalEdit.contract(resource!);
  }, [resource]);

  return useQuery(
    ['globalEditContract', resource],
    queryFn,
    {
      enabled: Boolean(resource),
    },
  );
};

export const useUpdateGlobalEdit = () => {
  const mutationFn = useCallback((payload: {
    changes: Parameters<typeof apiClient.v1.globalEdit.update>[0]['changes'],
    ids: Parameters<typeof apiClient.v1.globalEdit.update>[0]['ids'],
    resource: Parameters<typeof apiClient.v1.globalEdit.update>[0]['resource'],
  }) => {
    return apiClient.v1.globalEdit.update({
      changes: payload.changes,
      ids: payload.ids,
      resource: payload.resource,
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};
