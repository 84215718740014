// External Dependencies
import styled from 'styled-components';

// Local Typings
interface CardWrapperProps {
  fullWidth?: boolean;
}

// Local Variables
const gap = 2;

export const SettingsWrapper = styled.div(({ theme }) => ({
  alignItems: 'stretch',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(gap),
  justifyContent: 'space-between',
}));

export const SettingsCardWrapper = styled.div<CardWrapperProps>(({
  fullWidth,
  theme,
}) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: '100%',
  },
  '& > div': {
    flex: 1,
  },
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexWrap: 'wrap',
  minWidth: fullWidth ? undefined : `calc(50% - ${theme.spacing(gap / 2)})`,
}));
