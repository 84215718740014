/* eslint-disable graphql/template-strings */
// External Dependencies
import { useEffect } from 'react';
import gql from 'graphql-tag';

// Internal Dependencies
import {
  DeletedFilters,
} from 'pages/DistrictAdmin/AdminDeletedInventory/All/AdminDeletedInventoryTable/Filters';
import { LazyQueryResult } from '@apollo/client';
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DistrictDeletedInventoryIndexResponse {
  districtDeletedInventoryItemsIndex: GQL.IDistrictDeletedInventoryIndexItemAll;
}
interface DistrictInventoryIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
  where?: GQL.IDistrictInventoryIndexWhereConditions;
}
interface InventoryCheckout {
  active: boolean;
  checkinDate: string | null;
  checkoutDate: string;
  dueDate: string;
  id: string;
  user: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  };
}

export interface SimpleInventoryItem {
  activeCheckout: InventoryCheckout;
  brand: string;
  id: string;
  label: string;
  model: string;
  serialNumber: string;
}

// Local Variables
const getDistrictDeletedAdditionalVariables: UseGetTableQueryArgs['getAdditionalVariables'] = (search) => {
  const filters: DeletedFilters = {
    organizationId: search.organizationId ?? null,
    organizationTypeId: search.organizationTypeId ?? null,
  };

  const where = {
    organizationId: filters.organizationId ?? undefined,
    organizationTypeId: filters.organizationTypeId
      ? Number(filters.organizationTypeId)
      : undefined,
  };

  return { where };
};

/* TODO: Add this "deleted" data back in later when API says it's cool

      deletedAt
      deletedBy {
        ${simpleUserQuery}
      }
      deletedNote
*/
export const GET_INVENTORY_ITEM = gql`
  query InventoryItem(
    $id: ID!
  ) {
    inventoryItem(
      id: $id
    ) {
      accessories
      caseNumber
      checkouts {
        active
        checkinDate
        checkoutDate
        dueDate
        id
        item {
          id
          label
        }
        organization {
          id
          label
        }
        user {
          email
          firstName
          id
          lastName
        }
      }
      combination
      comments
      currentValueInCents
      customBarcode
      districtNumber
      inventoryFundingSourceId
      isMissing
      itemNumber
      location
      lockNumber
      locker
      onLoanToOrganization {
        id
        label
      }
      purchaseOrderNumber
      purchaseValueInCents
      purchaseYear
      quantity
      quality {
        id
        label
      }
      brand
      category {
        id
        label
      }
      condition {
        id
        label
      }
      id
      label
      model
      organization {
        id
        label
      }
      organizationType {
        id
        label
      }
      purchasedAt
      serialNumber
      systemBarcode

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

export const GET_DELETED_INVENTORY_ITEM = gql`
  query DeletedInventoryItem(
    $id: ID!
  ) {
    deletedInventoryItem(
      id: $id
    ) {
      accessories
      caseNumber
      checkouts {
        active
        checkinDate
        checkoutDate
        dueDate
        id
        item {
          id
          label
        }
        user {
          email
          firstName
          id
          lastName
        }
      }
      combination
      comments
      currentValueInCents
      customBarcode
      deletedAt
      deletedBy {
        email
        firstName
        lastName
      }
      deletedNote
      districtNumber
      isMissing
      itemNumber
      location
      lockNumber
      locker
      onLoanToOrganization {
        id
        label
      }
      purchaseOrderNumber
      purchaseValueInCents
      quantity
      quality {
        id
      }
      brand
      category {
        id
        label
      }
      condition {
        id
        label
      }
      id
      label
      model
      organization {
        id
        label
      }
      organizationType {
        id
        label
      }
      purchasedAt
      serialNumber
      systemBarcode

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

export const GET_INVENTORY_ITEMS = gql`
  query InventoryItems($queryParams: QueryParams, $where: InventoryWhereConditions) {
    inventoryItems(queryParams: $queryParams, where: $where) {
      data {
        accessories
        brand
        caseNumber
        category {
          id
          label
        }
        combination
        comments
        condition {
          id
          label
        }
        currentValueInCents
        customBarcode
        districtNumber
        id
        inventoryFundingSourceId
        isMissing
        itemNumber
        label
        location
        lockNumber
        locker
        model
        onLoanToOrganization {
          id
          label
        }
        organization {
          id
          label
        }
        organizationType {
          id
          label
        }
        purchaseOrderNumber
        purchaseValueInCents
        purchasedAt
        quality {
          id
        }
        quantity
        serialNumber
      }
      fullCount
    }
  }
`;

export const GET_DELETED_INVENTORY_ITEMS = gql`
  query DeletedInventoryItems(
    $queryParams: QueryParams
    $where: InventoryWhereConditions
  ) {
    deletedInventoryItems(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        brand
        category {
          id
          label
        }
        condition {
          id
          label
        }
        customBarcode
        deletedAt
        deletedBy {
          id
          firstName
          lastName
        }
        deletedNote
        districtNumber
        id
        inventoryFundingSourceId
        isMissing
        label
        model
        organization {
          id
          label
        }
        organizationType {
          id
          label
        }
        purchasedAt
        serialNumber
      }
      fullCount
    }
  }
`;

export const GET_INVENTORY_ITEMS_INDEX = gql`
  query InventoryItemsIndex(
    $queryParams: IndexQueryParams
    $where: InventoryIndexWhereConditions
  ) {
    inventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
      data {
        accessories
        brand
        caseNumber
        category {
          id
          label
        }
        checkedOutTo
        combination
        comments
        condition {
          id
          label
        }
        currentValueInCents
        customBarcode
        districtNumber
        id
        inventoryFundingSourceId
        isMissing
        itemNumber
        label
        location
        lockNumber
        locker
        model
        onLoanToOrganization {
          id
          label
        }
        purchasedAt
        purchaseOrderNumber
        purchaseValueInCents
        quality {
          id
          label
        }
        serialNumber
        systemBarcode

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
    }
  }
`;
export const GET_INVENTORY_ITEMS_INDEX_REPORT = gql`
  query InventoryItemsIndexReport(
    $queryParams: IndexQueryParams
    $where: InventoryIndexWhereConditions
  ) {
    inventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
    }
  }
`;

export const GET_DISTRICT_DELETED_INVENTORY_ITEMS_INDEX = gql`
  query DistrictDeletedInventoryItemsIndex(
    $queryParams: IndexQueryParams
    $where: DistrictDeletedInventoryIndexWhereConditions
  ) {
    districtDeletedInventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
      data {
        brand
        caseNumber
        category {
          id
          label
        }
        condition {
          id
          label
        }
        customBarcode
        deletedAt
        deletedBy
        deletedNote
        districtNumber
        id
        inventoryFundingSourceId
        isMissing
        label
        model
        organization {
          id
          label
        }
        serialNumber
        systemBarcode

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
    }
  }
`;

export const GET_DISTRICT_DELETED_INVENTORY_ITEMS_INDEX_REPORT = gql`
  query DistrictDeletedInventoryItemsIndexReport(
    $queryParams: IndexQueryParams
    $where: DistrictDeletedInventoryIndexWhereConditions
  ) {
    districtDeletedInventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
    }
  }
`;

export const GET_DISTRICT_INVENTORY_ITEMS_INDEX = gql`
  query DistrictInventoryItemsIndex(
    $queryParams: IndexQueryParams
    $where: DistrictInventoryIndexWhereConditions
  ) {
    districtInventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
      data {
        brand
        caseNumber
        category {
          id
          label
        }
        checkedOutTo
        condition {
          id
          label
        }
        customBarcode
        districtNumber
        id
        inventoryFundingSourceId
        isMissing
        label
        model
        onLoanToOrganization {
          id
          label
        }
        organization {
          entityType {
            id
            label
          }
          id
          label
        }
        serialNumber
        systemBarcode

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
    }
  }
`;

export const GET_DISTRICT_INVENTORY_ITEMS_INDEX_REPORT = gql`
  query DistrictInventoryItemsIndexReport(
    $queryParams: IndexQueryParams
    $where: DistrictInventoryIndexWhereConditions
  ) {
    districtInventoryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
    }
  }
`;

export const FRAGMENT_SIMPLE_INVENTORY_ITEM = gql`
  fragment SimpleInventoryItemFragment on InventoryItem {
    activeCheckout {
      active
      checkinDate
      checkoutDate
      dueDate
      id
      user {
        email
        firstName
        id
        lastName
      }
    }
    brand
    checkouts {
      active
      id
    }
    id
    label
    model
    serialNumber
  }
`;

const GET_SIMPLE_INVENTORY_ITEMS_TYPEAHEAD = gql`
  query SimpleInventoryItemsTypeAhead($searchString: String!) {
    inventoryItems(queryParams: { page: 1, limit: 8, q: $searchString }) {
      data {
        ...SimpleInventoryItemFragment
      }
    }
  }
  ${FRAGMENT_SIMPLE_INVENTORY_ITEM}
`;

const GET_SIMPLE_INVENTORY_ITEM = gql`
  query SimpleInventoryItem($inventoryItemId: ID!) {
    inventoryItem(id: $inventoryItemId) {
      ...SimpleInventoryItemFragment
    }
  }
  ${FRAGMENT_SIMPLE_INVENTORY_ITEM}
`;

export const useGetInventoryItemsTypeAhead = (
  searchString: string,
) => useQueryEnhanced<{
  inventoryItems: { data: SimpleInventoryItem[] };
}>(
  GET_SIMPLE_INVENTORY_ITEMS_TYPEAHEAD,
  {
    fetchPolicy: 'network-only',
    variables: { searchString },
  },
);

export const useGetSimpleInventoryItem = (
  inventoryItemId?: string,
) => useQueryEnhanced<{
  inventoryItem: SimpleInventoryItem;
}>(
  GET_SIMPLE_INVENTORY_ITEM,
  {
    skip: !inventoryItemId,
    variables: { inventoryItemId },
  },
);

export const useGetInventoryItem = (id: string | null) =>
  useQueryEnhanced<{
    inventoryItem: GQL.IInventoryItem;
  }>(GET_INVENTORY_ITEM, {
    skip: !id,
    variables: { id },
  });

export const useGetDeletedInventoryItem = (id: string) =>
  useQueryEnhanced<{
    deletedInventoryItem: GQL.IInventoryItem;
  }>(GET_DELETED_INVENTORY_ITEM, {
    variables: { id },
  });

/* District Admin Deleted Items */
export const useGetDistrictDeletedInventoryIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<DistrictDeletedInventoryIndexResponse, DistrictInventoryIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<DistrictDeletedInventoryIndexResponse, DistrictInventoryIndexVariables>({
    getAdditionalVariables: getDistrictDeletedAdditionalVariables,
    gqlQuery,
    options,
    tableResource: 'districtDeletedInventoryItems',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictDeletedInventoryIndexItems = () => {
  const [query, values] = useGetDistrictDeletedInventoryIndexItemsQuery(
    GET_DISTRICT_DELETED_INVENTORY_ITEMS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useDownloadDistrictDeletedInventoryIndexItems = () =>
  useGetDistrictDeletedInventoryIndexItemsQuery(
    GET_DISTRICT_DELETED_INVENTORY_ITEMS_INDEX_REPORT,
    { exportReport: true },
  );
