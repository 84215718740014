// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CartIcon from 'mdi-material-ui/Cart';
import DeleteIcon from '@mui/icons-material/Delete';

// Internal Dependencies
import { DELETE_FINANCIAL_ITEM } from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import {
  createDataGridActionsColumn,
} from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialItemsIndex } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialItemsTableZeroState from './FinancialItemsTableZeroState';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Local Variables
const clickRowTo = (id: string) => `/${PATHS.FINANCIAL_ITEMS}/${id}`;

// Component Definition
const FinancialItemsTable: FC<Props> = ({
  schoolYearEnding,
}) => {
  const navigate = useNavigate();

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleDeleteDialog,
  } = useIsOpen();

  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const canDeleteFinances = useSelector(hasPermission('finances', 'delete'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));

  const {
    data,
    isLoading,
  } = useGetFinancialItemsIndex(schoolYearEnding);

  const handleNavigateToPaymentPage = useCallback((row: GQL.IFinancialItemIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_NEW}?financial_item_id=${row.id}`);
  }, [navigate]);

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IFinancialItemIndexItem) => {
    setSingleItemDeleteId(row.id);
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  const handleNavigateToFeePage = useCallback((row: GQL.IFinancialItemIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_FEES_NEW}?financial_item_id=${row.id}`);
  }, [navigate]);

  const formatDeleteFinancialItemPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteFinancialItemOnMutationArguments>['formatPayload']>((note) => ({
    deletedNote: note ?? '',
    id: singleItemDeleteId ?? '',
  }), [singleItemDeleteId]);

  const extraColumns = useMemo<DataGridColDef<GQL.IFinancialItemIndexItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IFinancialItemIndexItem>([
        {
          action: handleNavigateToPaymentPage,
          icon: <AttachMoney />,
          text: 'Add a Payment',
        },
        {
          action: handleNavigateToFeePage,
          icon: <CartIcon />,
          isDisabled: (row) => !row.isActive,
          text: 'Add a Fee',
        },
        ...(canDeleteFinances ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <DeleteIcon />,
          text: 'Delete',
        }] : []),
      ]) as DataGridColDef<GQL.IFinancialItemIndexItem>;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteFinances,
      handleOpenSingleItemDeleteDialog,
      handleNavigateToFeePage,
      handleNavigateToPaymentPage,
    ],
  );

  const columnArgs = useMemo(() => ({
    extraColumns,
  }), [
    extraColumns,
  ]);

  const columns = useColumns(columnArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWriteFinances ? {
            label: 'Financial Item',
            to: `/${PATHS.FINANCIAL_ITEMS_NEW}`,
          } : null}
          clickRowTo={clickRowTo}
          columns={columns}
          components={{
            NoRowsOverlay: FinancialItemsTableZeroState,
          }}
          key={schoolYearEnding}
          loading={isLoading}
          rows={data}
          tableResource={`financialItems-${schoolYearEnding}`}
          withSearch
        />
      </DataGridContainer>

      <DeleteDialogV2<GQL.IDeleteFinancialItemOnMutationArguments>
        clearCachePredicates={['financialItemsIndex']}
        context={['financial item']}
        formatPayload={formatDeleteFinancialItemPayload}
        hasSelection={false}
        isOpen={isDeleteDialogOpen}
        mutation={DELETE_FINANCIAL_ITEM}
        onClose={toggleDeleteDialog}
        withNote
      />
    </>
  );
};

export default FinancialItemsTable;
