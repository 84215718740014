// External Dependencies
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';
import { useGetOrganizationEntityTypes } from 'gql/queries';
import CustomCheckboxGroup from 'components/shared/CustomCheckboxGroup';

// Local Dependencies
import { StyledFormLabel } from './StyledFormLabel';

/*
 * This component should be used in a Formik context.
 *
 */

// Component Definition
const CampusTypeCheckboxes = (): JSX.Element => {
  const {
    data: entityTypeOptions,
    loading,
  } = useGetOrganizationEntityTypes();

  // We need four checkboxes, one for each campus type:
  //  College/University, High, Middle, and Elementary
  const checkboxData = useMemo(() =>
    entityTypeOptions?.organizationEntityTypes.map((entityType) => ({
      label: toTitleCase(entityType.label),
      value: entityType.id,
    })) || [], [entityTypeOptions]);

  return (
    <EnhancedCard>
      <EnhancedCardContent>
        <StyledFormLabel component="legend">
          Campus Type
        </StyledFormLabel>

        <Box marginTop={1.5}>
          {loading ? (
            <Box>
              <Skeleton height={42} />
              <Skeleton height={42} />
              <Skeleton height={42} />
              <Skeleton height={42} />
            </Box>
          ) : (
            <CustomCheckboxGroup
              checkboxData={checkboxData}
              name="organizationEntityTypeIds"
            />
          )}
        </Box>
      </EnhancedCardContent>
    </EnhancedCard>
  );
};

export default CampusTypeCheckboxes;
