import { useEffect } from 'react';

export const useAddScriptTag = (src: string, cb: () => void) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      cb();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [cb, src]);
};
