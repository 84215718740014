/* eslint-disable graphql/template-strings */
// External Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Dependencies
import { useGetOrganization } from './organization-queries';

// GQL Definitions
export const INVENTORY_CATEGORY_OPTIONS_QUERY = gql`
  query InventoryCategorySelectOptions($organizationTypeId: ID!) {
    inventoryCategories(organizationTypeId: $organizationTypeId) {
      id
      label
    }
  }
`;

export const useGetInventoryCategoryOptions = (organizationTypeId?: string) => {
  const { data } = useGetOrganization();

  const orgTypeId = data?.organization?.organizationType.id ?? organizationTypeId;

  return useQueryEnhanced<{
    inventoryCategories: GQL.IInventoryCategory[];
  }>(INVENTORY_CATEGORY_OPTIONS_QUERY, {
    skip: !orgTypeId,
    variables: {
      organizationTypeId: orgTypeId,
    },
  });
};
