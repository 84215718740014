// External Dependencies
function CalendarSvg(props: any) {
  return (
    <svg
      enableBackground="new 0 0 64 64"
      viewBox="0 0 696.53 678.67"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs
        xmlns="http://www.w3.org/2000/svg"
        id="defs207"
      />
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="g202"
      >
        <g id="g193">
          <g id="g12">
            <defs id="defs5">
              <path
                id="SVGID_1_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0H89.299      C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use
                xlinkHref="#SVGID_1_"
                overflow="visible"
                id="use7"
              />
            </clipPath>
            <path
              clipPath="url(#SVGID_2_)"
              fill="mediumpurple"
              d="M607.231,0H89.299C59.696,0,35.719,23.977,35.719,53.579l1.027,339.335     h624.064V53.579C660.811,23.977,636.834,0,607.231,0z"
              id="path10"
            />
          </g>
          <g id="g30">
            <defs id="defs15">
              <path
                id="SVGID_3_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0H89.299      C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_4_">
              <use
                xlinkHref="#SVGID_3_"
                overflow="visible"
                id="use17"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_4_)"
              id="g28"
            >
              <g id="g24">
                <path
                  fill="indigo"
                  d="M642.951,107.158H53.579C23.977,107.158,0,131.135,0,160.738c0,2.5,0.179,4.956,0.491,7.322       l35.228,224.854h625.091l35.228-224.854c0.313-2.411,0.491-4.867,0.491-7.322C696.53,131.135,672.553,107.158,642.951,107.158z       "
                  id="path22"
                />
              </g>
              <path
                opacity="0.5"
                fill="#2A56C6"
                d="M642.951,107.158H53.579C23.977,107.158,0,131.135,0,160.738      c0,2.5,0.179,4.956,0.491,7.322l35.228,224.854h625.091l35.228-224.854c0.313-2.411,0.491-4.867,0.491-7.322      C696.53,131.135,672.553,107.158,642.951,107.158z"
                id="path26"
              />
            </g>
          </g>
          <g id="g40">
            <defs id="defs33">
              <path
                id="SVGID_5_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0H89.299      C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_6_">
              <use
                xlinkHref="#SVGID_5_"
                overflow="visible"
                id="use35"
              />
            </clipPath>
            <path
              clipPath="url(#SVGID_6_)"
              fill="mediumslateblue"
              d="M678.536,621.385l-17.726-228.471H35.719L17.994,621.385     c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652c29.603,0,53.579-23.977,53.579-53.579     C678.67,623.841,678.626,622.635,678.536,621.385z"
              id="path38"
            />
          </g>
          <g id="g55">
            <defs id="defs43">
              <path
                id="SVGID_7_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0H89.299      C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_8_">
              <use
                xlinkHref="#SVGID_7_"
                overflow="visible"
                id="use45"
              />
            </clipPath>

            <linearGradient
              id="SVGID_9_"
              gradientUnits="userSpaceOnUse"
              x1="3735.4238"
              y1="-1499.7716"
              x2="4613.7563"
              y2="-2378.1042"
              gradientTransform="matrix(0.3333 0 0 -0.3333 -1047.1566 -266.2198)"
            >
              <stop
                offset="0"
                style={{
                  stopColor: '#1A237E',
                  stopOpacity: 0.2,
                }}
                id="stop48"
              />
              <stop
                offset="1"
                style={{
                  stopColor: '#1A237E',
                  stopOpacity: 0.2,
                }}
                id="stop50"
              />
            </linearGradient>
            <polygon
              clipPath="url(#SVGID_8_)"
              fill="url(#SVGID_9_)"
              points="35.719,392.914 321.475,678.67 678.67,678.67 678.67,392.914         "
              id="polygon53"
            />
          </g>
          <g id="g65">
            <defs id="defs58">
              <path
                id="SVGID_10_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_11_">
              <use
                xlinkHref="#SVGID_10_"
                overflow="visible"
                id="use60"
              />
            </clipPath>
            <path
              clipPath="url(#SVGID_11_)"
              fill="#FFFFFF"
              fillOpacity="0.2"
              d="M53.579,111.623h589.372     c28.977,0,52.552,23.039,53.49,51.793c0.045-0.893,0.089-1.786,0.089-2.679c0-29.603-23.977-53.579-53.579-53.579H53.579     C23.977,107.158,0,131.135,0,160.738c0,0.893,0.045,1.786,0.089,2.679C1.027,134.662,24.602,111.623,53.579,111.623z"
              id="path63"
            />
          </g>
          <g id="g75">
            <defs id="defs68">
              <path
                id="SVGID_12_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_13_">
              <use
                xlinkHref="#SVGID_12_"
                overflow="visible"
                id="use70"
              />
            </clipPath>
            <path
              clipPath="url(#SVGID_13_)"
              fill="#1A237E"
              fillOpacity="0.2"
              d="M625.091,674.205H71.439     c-28.977,0-52.552-23.039-53.49-51.793c-0.045,0.893-0.089,1.786-0.089,2.679c0,29.603,23.977,53.579,53.579,53.579h553.652     c29.603,0,53.579-23.977,53.579-53.579c0-0.893-0.045-1.786-0.089-2.679C677.643,651.166,654.069,674.205,625.091,674.205z"
              id="path73"
            />
          </g>
          <g id="g85">
            <defs id="defs78">
              <path
                id="SVGID_14_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_15_">
              <use
                xlinkHref="#SVGID_14_"
                overflow="visible"
                id="use80"
              />
            </clipPath>
            <path
              clipPath="url(#SVGID_15_)"
              fill="#FFFFFF"
              fillOpacity="0.2"
              d="M607.231,0H89.299C59.696,0,35.719,23.977,35.719,53.579     v4.242C35.853,28.352,59.785,4.465,89.299,4.465h517.933c29.603,0,53.579,23.977,53.579,53.579v-4.465     C660.811,23.977,636.834,0,607.231,0z"
              id="path83"
            />
          </g>
          <g id="g111">
            <defs id="defs88">
              <path
                id="SVGID_16_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_17_">
              <use
                xlinkHref="#SVGID_16_"
                overflow="visible"
                id="use90"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_17_)"
              id="g109"
            >
              <g id="g107">
                <defs id="defs94">
                  <path
                    id="SVGID_18_"
                    d="M642.951,107.158H53.579C23.977,107.158,0,131.135,0,160.738c0,2.5,0.179,4.956,0.491,7.322        l35.228,224.854h625.091l35.228-224.854c0.313-2.411,0.491-4.867,0.491-7.322C696.53,131.135,672.553,107.158,642.951,107.158        z"
                  />
                </defs>
                <clipPath id="SVGID_19_">
                  <use
                    xlinkHref="#SVGID_18_"
                    overflow="visible"
                    id="use96"
                  />
                </clipPath>
                <g
                  clipPath="url(#SVGID_19_)"
                  id="g105"
                >
                  <path
                    fill="#E1E1E1"
                    d="M295.847,389.075c20.315-8.394,46.48-30.719,46.48-64.34c0-51.793-33.264-83.628-89.299-83.628        c-49.918,0-90.147,32.683-90.861,80.369h36.255c0.536-27.861,19.378-50.766,54.562-50.766        c33.889,0,52.999,17.636,52.999,53.222s-27.236,52.017-60.455,52.017c-13.038,0-25.316,0-25.316,0v28.397        c0,0,14.02,0,25.316,0c48.846,0,64.92,25.361,64.92,54.785c0,38.488-23.396,56.258-57.285,56.258        c-29.513,0-56.392-15.761-57.196-50.99h-36.523c1.563,56.749,49.338,80.369,93.719,80.369c51.257,0,93.54-29.87,93.54-86.441        C346.792,412.694,314.287,394.477,295.847,389.075z M503.109,241.107l-105.73,37.952v34.112l75.904-25.361v256.912h35.72        V241.107H503.109z"
                    id="path99"
                  />
                  <g
                    opacity="0.5"
                    id="g103"
                  >
                    <path
                      fill="#C2C2C2"
                      d="M295.847,389.075c20.315-8.394,46.48-30.719,46.48-64.34c0-51.793-33.264-83.628-89.299-83.628         c-49.918,0-90.147,32.683-90.861,80.369h36.255c0.536-27.861,19.378-50.766,54.562-50.766         c33.889,0,52.999,17.636,52.999,53.222s-27.236,52.017-60.455,52.017c-13.038,0-25.316,0-25.316,0v28.397         c0,0,14.02,0,25.316,0c48.846,0,64.92,25.361,64.92,54.785c0,38.488-23.396,56.258-57.285,56.258         c-29.513,0-56.392-15.761-57.196-50.99h-36.523c1.563,56.749,49.338,80.369,93.719,80.369c51.257,0,93.54-29.87,93.54-86.441         C346.792,412.694,314.287,394.477,295.847,389.075z M503.109,241.107l-105.73,37.952v34.112l75.904-25.361v256.912h35.72         V241.107H503.109z"
                      id="path101"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g131">
            <defs id="defs114">
              <path
                id="SVGID_20_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_21_">
              <use
                xlinkHref="#SVGID_20_"
                overflow="visible"
                id="use116"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_21_)"
              id="g129"
            >
              <g id="g127">
                <defs id="defs120">
                  <path
                    id="SVGID_22_"
                    d="M678.536,621.385l-17.726-228.471H35.719L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706        c0,29.603,23.977,53.579,53.579,53.579h553.652c29.603,0,53.579-23.977,53.579-53.579        C678.67,623.841,678.626,622.635,678.536,621.385z"
                  />
                </defs>
                <clipPath id="SVGID_23_">
                  <use
                    xlinkHref="#SVGID_22_"
                    overflow="visible"
                    id="use122"
                  />
                </clipPath>
                <path
                  clipPath="url(#SVGID_23_)"
                  fill="#EEEEEE"
                  d="M295.847,389.075c20.315-8.394,46.48-30.719,46.48-64.34       c0-51.793-33.264-83.628-89.299-83.628c-49.918,0-90.147,32.683-90.861,80.369h36.255       c0.536-27.861,19.378-50.766,54.562-50.766c33.889,0,52.999,17.636,52.999,53.222s-27.236,52.017-60.455,52.017       c-13.038,0-25.316,0-25.316,0v28.397c0,0,14.02,0,25.316,0c48.846,0,64.92,25.361,64.92,54.785       c0,38.488-23.396,56.258-57.285,56.258c-29.513,0-56.392-15.761-57.196-50.99h-36.523       c1.563,56.749,49.338,80.369,93.719,80.369c51.257,0,93.54-29.87,93.54-86.441       C346.792,412.694,314.287,394.477,295.847,389.075z M503.109,241.107l-105.73,37.952v34.112l75.904-25.361v256.912h35.72       V241.107H503.109z"
                  id="path125"
                />
              </g>
            </g>
          </g>
          <g id="g154">
            <defs id="defs134">
              <path
                id="SVGID_24_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_25_">
              <use
                xlinkHref="#SVGID_24_"
                overflow="visible"
                id="use136"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_25_)"
              id="g152"
            >

              <linearGradient
                id="SVGID_26_"
                gradientUnits="userSpaceOnUse"
                x1="3676.2822"
                y1="-958.4171"
                x2="3780.4939"
                y2="-1062.6288"
                gradientTransform="matrix(0.3333 0 0 -0.3333 -1047.1566 -266.2198)"
              >
                <stop
                  offset="0"
                  style={{
                    stopColor: '#212121',
                    stopOpacity: 0.2,
                  }}
                  id="stop139"
                />
                <stop
                  offset="1"
                  style={{
                    stopColor: '#212121',
                    stopOpacity: 0.2,
                  }}
                  id="stop141"
                />
              </linearGradient>
              <polygon
                fill="url(#SVGID_26_)"
                points="194.269,107.158 159.666,72.511 197.529,34.648 270.084,107.158     "
                id="polygon144"
              />
              <circle
                fill="#EEEEEE"
                cx="178.597"
                cy="53.579"
                r="26.79"
                id="circle146"
              />
              <path
                fill="#FFFFFF"
                fillOpacity="0.4"
                d="M178.597,31.255c14.065,0,25.539,10.805,26.656,24.557      c0.089-0.759,0.134-1.473,0.134-2.232c0-14.779-12.011-26.79-26.79-26.79s-26.79,12.011-26.79,26.79      c0,0.759,0.045,1.473,0.134,2.232C153.058,42.06,164.533,31.255,178.597,31.255z"
                id="path148"
              />
              <path
                fill="#212121"
                fillOpacity="0.1"
                d="M205.253,55.812c-1.116,13.752-12.636,24.557-26.656,24.557      s-25.539-10.805-26.656-24.557c-0.089,0.759-0.134,1.473-0.134,2.232c0,14.779,12.011,26.79,26.79,26.79      s26.79-12.011,26.79-26.79C205.387,57.285,205.342,56.571,205.253,55.812z"
                id="path150"
              />
            </g>
          </g>
          <g id="g168">
            <defs id="defs157">
              <path
                id="SVGID_27_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_28_">
              <use
                xlinkHref="#SVGID_27_"
                overflow="visible"
                id="use159"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_28_)"
              id="g166"
            >
              <rect
                x="31.255"
                y="392.914"
                fill="#FFFFFF"
                fillOpacity="0.05"
                width="634.021"
                height="4.465"
                id="rect162"
              />
              <rect
                x="31.255"
                y="388.449"
                fill="#1A237E"
                fillOpacity="0.05"
                width="634.021"
                height="4.465"
                id="rect164"
              />
            </g>
          </g>
          <g id="g191">
            <defs id="defs171">
              <path
                id="SVGID_29_"
                d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579C660.811,23.977,636.834,0,607.231,0      H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322      l35.228,224.854l0,0L17.994,621.385c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652      c29.603,0,53.579-23.977,53.579-53.579c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854      C696.352,165.694,696.53,163.238,696.53,160.738z"
              />
            </defs>
            <clipPath id="SVGID_30_">
              <use
                xlinkHref="#SVGID_29_"
                overflow="visible"
                id="use173"
              />
            </clipPath>
            <g
              clipPath="url(#SVGID_30_)"
              id="g189"
            >

              <linearGradient
                id="SVGID_31_"
                gradientUnits="userSpaceOnUse"
                x1="4694.375"
                y1="-958.5712"
                x2="4785.7544"
                y2="-1049.9506"
                gradientTransform="matrix(0.3333 0 0 -0.3333 -1047.1566 -266.2198)"
              >
                <stop
                  offset="0"
                  style={{
                    stopColor: '#212121',
                    stopOpacity: 0.2,
                  }}
                  id="stop176"
                />
                <stop
                  offset="1"
                  style={{
                    stopColor: '#212121',
                    stopOpacity: 0.2,
                  }}
                  id="stop178"
                />
              </linearGradient>
              <polygon
                fill="url(#SVGID_31_)"
                points="533.605,107.158 499.001,72.511 536.864,34.648 591.559,107.158     "
                id="polygon181"
              />
              <circle
                fill="#EEEEEE"
                cx="517.933"
                cy="53.579"
                r="26.79"
                id="circle183"
              />
              <path
                fill="#FFFFFF"
                fillOpacity="0.4"
                d="M517.933,31.255c14.065,0,25.539,10.805,26.656,24.557      c0.045-0.759,0.134-1.473,0.134-2.232c0-14.779-12.011-26.79-26.79-26.79c-14.779,0-26.79,12.011-26.79,26.79      c0,0.759,0.045,1.473,0.134,2.232C492.393,42.06,503.868,31.255,517.933,31.255z"
                id="path185"
              />
              <path
                fill="#212121"
                fillOpacity="0.1"
                d="M544.588,55.812c-1.116,13.752-12.636,24.557-26.656,24.557      c-14.02,0-25.539-10.805-26.656-24.557c-0.045,0.759-0.134,1.473-0.134,2.232c0,14.779,12.011,26.79,26.79,26.79      c14.779,0,26.79-12.011,26.79-26.79C544.722,57.285,544.678,56.571,544.588,55.812z"
                id="path187"
              />
            </g>
          </g>
        </g>

        <radialGradient
          id="SVGID_32_"
          cx="3285.2671"
          cy="-843.7314"
          r="2682.4927"
          gradientTransform="matrix(0.3333 0 0 -0.3333 -1047.1566 -266.2198)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.33"
            style={{
              stopColor: '#FFFFFF',
            }}
            id="stop195"
          />
          <stop
            offset="1"
            style={{
              stopColor: '#FFFFFF',
              stopOpacity: 0,
            }}
            id="stop197"
          />
        </radialGradient>
        <path
          fill="url(#SVGID_32_)"
          fillOpacity="0.05"
          d="M696.53,160.738c0-23.307-14.913-43.087-35.719-50.454V53.579   C660.811,23.977,636.834,0,607.231,0H89.299C59.696,0,35.719,23.977,35.719,53.579l0.134,56.615   C15.002,117.517,0,137.341,0,160.738c0,2.5,0.179,4.956,0.491,7.322l35.228,224.854l0,0L17.994,621.385   c-0.089,1.25-0.134,2.456-0.134,3.706c0,29.603,23.977,53.579,53.579,53.579h553.652c29.603,0,53.579-23.977,53.579-53.579   c0-1.25-0.045-2.456-0.134-3.706l-17.726-228.471l35.228-224.854C696.352,165.694,696.53,163.238,696.53,160.738z"
          id="path200"
        />
      </g>

    </svg>
  );
}

export default CalendarSvg;
