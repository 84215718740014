// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import {
  useGetOrganizationSchoolYearOptions,
} from 'gql/queries';

export const useColumns = (extraColumns: GridColDef[]) => {
  const {
    data: schoolYearEndingData,
  } = useGetOrganizationSchoolYearOptions();

  return useMemo(() => {
    const columns: GridColDef<GQL.IChecklistIndexItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 180,
      },
      {
        field: 'memberCount',
        headerName: 'Member Count',
        type: 'number',
      },
      {
        field: 'itemCount',
        headerName: 'Checklist Item Count',
        type: 'number',
      },
      {
        field: 'schoolYearEnding',
        headerName: 'School Year',
        type: 'singleSelect',
        valueGetter: (params) => {
          const { schoolYearEnding } = params.row;

          if (schoolYearEnding) {
            return displaySchoolYearString(schoolYearEnding);
          }
          return '';
        },
        valueOptions: schoolYearEndingData?.organizationSchoolYearSelectOptions.map((year) => ({
          label: year.label,
          value: year.label,
        })),
      },
      // {
      //   field: 'emailRemindersEnabled',
      //   headerName: 'Email Reminders',
      //   type: 'boolean',
      //   valueGetter: (params) => params.row.emailRemindersEnabled,
      // },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    schoolYearEndingData?.organizationSchoolYearSelectOptions,
  ]);
};
