// External Dependencies
import { FC } from 'react';
import TransferIcon from '@mui/icons-material/CompareArrows';

// Internal Dependencies
import {
  ListItemWithSecondaryAction,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  creditId: string;
  userFullName: string;
}

// Component Definition
const TransferCreditListItem: FC<Props> = ({
  creditId,
  userFullName,
}) => (
  <ListItemWithSecondaryAction
    primaryText="Transfer credit"
    secondaryAction={{
      buttonIcon: <TransferIcon />,
      buttonText: 'Transfer',
      to: `/${PATHS.FINANCIAL_CREDITS}/${creditId}/transfers/new`,
    }}
    secondaryText={`Transfer a portion of this credit to a family member of ${userFullName}.`}
  />
);

export default TransferCreditListItem;
