// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const addPreviewData = createActionCreator(
  'ADD_FINANCIAL_PAYMENT_FILE_UPLOAD_DIALOG_PREVIEW_DATA',
  (resolve) => (csvUploadPreview: GQL.ICSVUploadPreview) =>
    resolve(csvUploadPreview),
);

export const close = createActionCreator(
  'CLOSE_FINANCIAL_PAYMENT_FILE_UPLOAD_DIALOG',
);

export const open = createActionCreator('OPEN_FINANCIAL_PAYMENT_FILE_UPLOAD_DIALOG');

export const reset = createActionCreator('RESET_FINANCIAL_PAYMENT_FILE_UPLOAD_DIALOG');

export const updateStep = createActionCreator(
  'UPDATE_FINANCIAL_PAYMENT_FILE_UPLOAD_DIALOG_STEP',
  (resolve) => (step: number) => resolve(step),
);
