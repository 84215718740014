// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

// Internal Dependencies
import { AdultData, StudentData } from 'gql/queries';
import { Flex, ShowPageDataDisplay } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import SubscriberOnlyButton from 'components/shared/SubscriberOnlyButton';

// Local Typings
interface Props {
  userData: AdultData | StudentData | undefined;
}

// Component Definition
const ShowUserContactData: FC<Props> = ({ userData }) => {
  const navigate = useNavigate();

  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));

  const dispatch = useDispatch();

  const handleClickEmailMember = useCallback(() => {
    if (userData) {
      // Add user id to the recipient ids for a new email
      dispatch(updateRecipients([userData.id]));
      navigate(`/${PATHS.EMAIL_NEW}`);
    }
  }, [dispatch, navigate, userData]);

  if (!userData) {
    return null;
  }

  const {
    addressOne,
    addressTwo,
    city,
    email,
    phoneNumber,
    secondaryEmail,
    secondaryPhoneNumber,
    state,
    zipcode,
  } = userData;

  return (
    <>
      <ShowPageDataDisplay
        label="Email"
        value={email}
      />
      <ShowPageDataDisplay
        label="Secondary Email"
        value={secondaryEmail}
      />
      <ShowPageDataDisplay
        label="Phone Number"
        value={phoneNumber}
      />
      <ShowPageDataDisplay
        label="Secondary Phone Number"
        value={secondaryPhoneNumber}
      />
      <ShowPageDataDisplay
        label="Address 1"
        value={addressOne}
      />
      <ShowPageDataDisplay
        label="Address 2"
        value={addressTwo}
      />
      <ShowPageDataDisplay
        label="City"
        value={city}
      />
      <Box display="flex">
        <Box
          minWidth="25%"
          mr={5}
        >
          <ShowPageDataDisplay
            label="State"
            value={state?.label}
          />
        </Box>

        <ShowPageDataDisplay
          label="Zip Code"
          value={zipcode}
        />
      </Box>

      {canEmailMembers && email && (
        <Flex justifyContent="flex-end">
          <SubscriberOnlyButton
            onClick={handleClickEmailMember}
            variant="outlined"
          >
            Email Member
          </SubscriberOnlyButton>
        </Flex>
      )}
    </>
  );
};

export default ShowUserContactData;
