// External Dependencies
import { FC, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { DashboardCard } from 'components/shared';

// Local Dependencies
import HighestBalanceItem from './HighestBalanceItem';

// Local Typings
interface Props {
  itemsWithHighestBalance: GQL.IItemWithBalance[] | undefined;
}

const INITIAL_DISPLAY_COUNT = 4;

// Component Definition
const ItemWithHighestBalanceCard: FC<Props> = ({
  itemsWithHighestBalance,
}) => {
  const [showMore, setShowMore] = useState(false);

  const itemsToShow = useMemo(() => {
    return showMore
      ? itemsWithHighestBalance
      : itemsWithHighestBalance?.slice(0, INITIAL_DISPLAY_COUNT);
  }, [itemsWithHighestBalance, showMore]);

  return (
    <DashboardCard
      content={itemsToShow?.length ? (
        <>
          <TransitionGroup component={List}>
            {itemsToShow?.map((item, index) => {
              const isLast = (index === itemsToShow.length - 1);

              return (
                <Collapse
                  component="li"
                  in
                  key={item.id}
                >
                  <HighestBalanceItem
                    isLast={isLast}
                    item={item}
                  />
                </Collapse>
              );
            })}
          </TransitionGroup>
          {(itemsWithHighestBalance?.length ?? 0) > INITIAL_DISPLAY_COUNT && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setShowMore(!showMore)}
                size="small"
              >
                {showMore ? 'Show Less' : 'Show More'}
              </Button>
            </Box>
          )}
        </>

      ) : (
        <Typography
          color="textSecondary"
          variant="body2"
        >
          No items with outstanding fees
        </Typography>
      )}
      title="Items with Highest Balance"
    />
  );
};

export default ItemWithHighestBalanceCard;
