// External Dependencies
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { CREATE_DISTRICT_INVITATION } from 'gql/mutations';
import { EnhancedDialog } from 'components/shared';
import { GET_DISTRICT } from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Local Dependencies
import InvitationForm from './InvitationForm';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const InviteOrganizationDialog = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleCompleteMutation = useCallback(() => {
    dispatch(addNotification('Invitation Sent!', 'success'));
    onClose();
  }, [dispatch, onClose]);

  const [mutation] = useMutationEnhanced(CREATE_DISTRICT_INVITATION, {
    onCompleted: handleCompleteMutation,
    refetchQueries: [{ query: GET_DISTRICT }],
  });

  return (
    <EnhancedDialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
    >
      <InvitationForm
        mutation={mutation}
        onClose={onClose}
      />
    </EnhancedDialog>
  );
};

export default InviteOrganizationDialog;
