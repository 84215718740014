// Internal Dependencies
import { GET_DISTRICT } from 'gql/queries';
import { clearTokens } from 'utils/cookies';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface ChildrenProps {
  district: GQL.IDistrict;
}
interface Props {
  children: (props: ChildrenProps) => any;
}

// Component Definition
const District = ({ children }: Props): JSX.Element | null => {
  const {
    data,
    error,
    loading,
  } = useQueryEnhanced<{ district: GQL.IDistrict }>(GET_DISTRICT);

  if (loading) {
    return null;
  }

  // if there was an error, log them out
  if (error) {
    clearTokens();
    return null;
  }

  if (data) {
    return children(data);
  }

  return null;
};

export default District;
