// External Dependencies
import SvgIcon from '@mui/material/SvgIcon';
import styled, { useTheme } from 'styled-components';

// Local Variables
const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  height: 42,
  width: theme.spacing(20),
}));

// Component Definition
const PrestoLogoTextPrestoOnlySvg = (props: any) => {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      htmlColor={theme.palette.logo}
      viewBox="0 0 150 30"
      {...props}
    >
      <g>
        <path
          d="M0,30.93V8.47C0,3.43,3.12,0,7.84,0S16,3.43,16,8.47V12.7C16,17.65,12.79,21,8,21H5.57v10c0,.54-.27.8-.76.8H.8A.71.71,0,0,1,0,30.93ZM7.62,15.82c1.78,0,2.72-1,2.72-2.94V8.07c0-1.74-.89-2.81-2.41-2.81S5.57,6.33,5.57,8.07v7.75Z"
        />
        <path
          d="M27.73,30.93V8.47c0-5,3.21-8.47,7.93-8.47s8.11,3.43,8.11,8.47v3.88a8.13,8.13,0,0,1-3.3,7L44.8,30.93c.13.49-.27.8-.76.8H39.72c-.49,0-.63-.31-.81-.75L35.44,20.77h-2.1V30.93c0,.54-.26.8-.75.8H28.53A.7.7,0,0,1,27.73,30.93Zm7.66-15.29c1.83,0,2.77-1.11,2.77-3.12V8.07c0-1.74-.94-2.81-2.45-2.81s-2.37,1.07-2.37,2.81v7.57Z"
        />
        <path
          d="M55.77,23.53V8.69c0-4.95,3.21-8.33,8-8.33h4.68a.71.71,0,0,1,.8.75V4.68a.73.73,0,0,1-.8.8h-4c-1.92,0-3.08,1.34-3.08,3.39V13.5h6a.73.73,0,0,1,.8.81v3.38a.71.71,0,0,1-.8.81h-6v4.9c0,2.05,1.16,3.39,3.08,3.39h4a.71.71,0,0,1,.8.75v3.61a.71.71,0,0,1-.8.76H63.79C59,31.91,55.77,28.53,55.77,23.53Z"
        />
        <path
          d="M80.24,23c0-.54.27-.8.76-.8h4a.73.73,0,0,1,.8.75c.09,2.77,1,4.1,2.63,4.1s2.36-1,2.36-2.58c0-6.15-10.29-8.74-10.29-17.12C80.51,2.76,83.58,0,88.13,0S95.8,3.34,96,8.87c0,.53-.26.8-.75.8h-4a.73.73,0,0,1-.8-.8c-.09-2.54-.85-3.7-2.23-3.7A2,2,0,0,0,86,7.35c0,5.49,10.39,8.12,10.39,17.12,0,4.81-3.25,7.8-8.07,7.8S80.42,28.75,80.24,23Z"
        />
        <path
          d="M122.36,1.29V4.9c0,.49-.26.76-.8.76h-4.23V30.93c0,.54-.27.8-.76.8h-4c-.49,0-.76-.26-.76-.8V5.66h-4.28a.69.69,0,0,1-.76-.76V1.29a.69.69,0,0,1,.76-.76h14C122.1.53,122.36.8,122.36,1.29Z"
        />
        <path
          d="M132.49,23.71V8.56c0-5.13,3.34-8.52,8.2-8.52s8.15,3.39,8.15,8.38V23.85c0,5-3.3,8.37-8.15,8.37S132.49,28.84,132.49,23.71Zm10.74.54V8a2.52,2.52,0,0,0-2.54-2.81c-1.56,0-2.59,1.12-2.59,3v16c0,1.83,1,2.9,2.59,2.9A2.47,2.47,0,0,0,143.23,24.25Z"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default PrestoLogoTextPrestoOnlySvg;
