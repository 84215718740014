// External Dependencies
import { FormBlockTypes } from '@presto-assistant/api_types';
import { FormResponsesResponseItem } from '@presto-assistant/api_types/api/v1/form';
import { getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';
import camelCase from 'lodash.camelcase';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { useGetFormAsDirector } from 'gql/queries/form-queries';
import {
  useGradeColDef,
  useGroupsColumn,
  usePrimaryRoleColDef,
} from 'components/shared/TableDataGrid/hooks';

export const useColumns = (formId: string) => {
  const {
    data: formData,
  } = useGetFormAsDirector(formId);

  // our API converts all keys to camelcase, so the dashes are stripped from the ids
  const formBlockWithNormalizedIds = useMemo(
    () => {
      const formBlocks = formData?.form?.formBlocks ?? [];

      return formBlocks.map((formBlock) => ({
        ...formBlock,
        id: camelCase(formBlock.id),
      }));
    },
    [formData],
  );

  // Remove any form blocks that have the label Heading or Paragraph.
  // These types of blocks cannot have responses.
  const formBlockWithNormalizedIdsWithResponses = useMemo(
    () => formBlockWithNormalizedIds.filter((formBlock) => (
      parseInt(formBlock.formBlockType.id, 10) !== FormBlockTypes.Heading
      && parseInt(formBlock.formBlockType.id, 10) !== FormBlockTypes.Paragraph
    )),
    [formBlockWithNormalizedIds],
  );

  const gradeCol = useGradeColDef<FormResponsesResponseItem>({
    field: 'assignedMemberGrade',
    valueGetter: (params) => params.row.assignedMemberGrade,
  });

  const groupsCol = useGroupsColumn<FormResponsesResponseItem>({
    field: 'groups',
    groupIdsSelector: (row) => (row.groups ?? []).map((g) => g.id),
  });

  const primaryRoleCol = usePrimaryRoleColDef<FormResponsesResponseItem>({
    editable: false,
    field: 'memberPrimaryRoleId',
    primaryRoleIdSelector: (row) => row.memberPrimaryRoleId,
    valueGetter: (params) => params.row.memberPrimaryRoleId,
  });

  return useMemo<DataGridColDef<FormResponsesResponseItem>[]>(() => {
    return [
      {
        field: 'assignedMemberEmail',
        headerName: 'Assigned To',
        minWidth: 200,
        valueGetter: (params) => {
          if (params.row.assignedMemberId) {
            return getFullNameWithEmail({
              email: params.row.assignedMemberEmail ?? '',
              firstName: params.row.assignedMemberFirstName ?? '',
              lastName: params.row.assignedMemberLastName ?? '',
            });
          }

          return null;
        },
      },
      gradeCol,
      ...(groupsCol ? [groupsCol] : []),
      ...(primaryRoleCol ? [primaryRoleCol] : []),
      dateTimeColumn({
        field: 'submittedAt',
        headerName: 'Submitted At',
      }),
      {
        field: 'submittedByMemberEmail',
        headerName: 'Submitted By',
        minWidth: 200,
        valueGetter: (params) => {
          if (params.row.submittedByMemberId) {
            return getFullNameWithEmail({
              email: params.row.submittedByMemberEmail ?? '',
              firstName: params.row.submittedByMemberFirstName ?? '',
              lastName: params.row.submittedByMemberLastName ?? '',
            });
          }

          return null;
        },
      },
      ...formBlockWithNormalizedIdsWithResponses.map<
        DataGridColDef<FormResponsesResponseItem>
      >((formBlock) => ({
        field: `responses.${formBlock.id}` as keyof FormResponsesResponseItem,
        headerName: `${formBlock.label} Response`,
        valueGetter: (params) => {
          const responses = params.row.responses ?? {};

          return responses[formBlock.id];
        },
      })),
    ];
  }, [
    formBlockWithNormalizedIdsWithResponses,
    gradeCol,
    groupsCol,
    primaryRoleCol,
  ]);
};
