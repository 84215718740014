// External Dependencies
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

// Local Typings
interface Props {
  ariaLabel?: string;
  autoComplete?: TextFieldProps['autoComplete'];
  autoFocus?: boolean;
  disableFuture?: boolean;
  error?: boolean;
  helperText?: string;
  label: string;
  maxDate?: Moment | null;
  minDate?: Moment | null;
  name: string;
  onChange?: (value: Moment, keyboardInputValue: string) => void;
  readOnly?: boolean;
  renderInput?: (props: TextFieldProps) => React.ReactNode
  required?: boolean;
  value?: any;
}

// Local Variables
const StyledTextField = styled(TextField)(({ theme }) => ({
  '.input': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
  },
  '.label': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
  },
  margin: '8px 0',
  width: '100%',
}));

// Component Definition
const DatePickerField: FC<Props> = ({
  ariaLabel = 'Change date',
  autoComplete,
  autoFocus = false,
  disableFuture = true,
  error,
  helperText,
  label,
  maxDate = new Date(`01/01/${new Date().getFullYear() + 10}`),
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  readOnly,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderInput,
  required,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  ...datePickerProps
}) => {
  // Must be used in a Formik context
  const {
    handleBlur,
    setFieldValue,
  } = useFormikContext();

  const [field, meta] = useField(name);
  const hasError = Boolean(meta.error);
  const isTouched = Boolean(meta.touched);

  let helperOrErrorText = helperText;

  if (isTouched && hasError) {
    helperOrErrorText = meta.error;
  }

  const handleChange = (date: unknown): void => {
    let newValue = date;

    // If the date is a valid moment, we convert it to a date string
    if (moment(date as Moment).isValid()) {
      newValue = (date as Moment).startOf('day').toISOString();
    }

    setFieldValue(field.name, newValue, false);
  };

  return (
    <DesktopDatePicker
      maxDate={disableFuture ? new Date() : maxDate}
      inputFormat="MM/DD/YYYY"
      label={label}
      onChange={handleChange}
      readOnly={readOnly}
      renderInput={(props) => {
        const { inputProps } = props;

        // To add the aria-label, we merge our value with the picker values
        const inputPropsWithPickerValues = {
          ...inputProps,
          'aria-label': ariaLabel,
        };

        return (
          <StyledTextField
            {...props}
            autoFocus={autoFocus}
            error={error || (isTouched && hasError)}
            helperText={helperOrErrorText}
            InputLabelProps={{
              classes: { root: 'label' },
            }}
            InputProps={{
              autoComplete,
              classes: { root: 'input' },
              inputProps: inputPropsWithPickerValues,
            }}
            name={name}
            onBlur={handleBlur}
            placeholder="12/31/1984"
            required={required}
            variant="filled"
          />
        );
      }}
      value={field.value}
      {...datePickerProps}
    />
  );
};

export default DatePickerField;
