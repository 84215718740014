// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { MemberFundraiserCreditAssignment } from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { UserRoles } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { getFullName } from 'utils';
import { hasPermission } from 'state/self/selectors';
import {
  useDeleteFundraiserCreditAssignment,
  useGetFundraiserCreditAssignments,
} from 'utils/api/financialFundraiserCredit';
import { useGetAllMembers, useGetFinancialFundraiserCredit } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DialogPermissionRequired from 'components/shared/DialogPermissionRequired';

// Local Dependencies
import {
  TableRow,
  UseColumnsArgs,
  useColumns,
} from './hooks';
import AddAssignmentDialog from './AddAssignmentDialog';

// Local Typings
interface Props {
  financialFundraiserCreditId: string;
}

// Local Variables
const getRowId = (row: TableRow) => row.memberId;

// Component Definition
const FinancialFundraiserCreditMembersTable: FC<Props> = ({
  financialFundraiserCreditId,
}) => {
  const dispatch = useDispatch();

  const canWritePayments = useSelector(hasPermission('payments', 'write'));

  const [rowToAssign, setRowToAssign] = useState<TableRow | null>(null);
  const [rowToDelete, setRowToDelete] = useState<TableRow | null>(null);

  const {
    isOpen: isAssignmentDialogOpen,
    toggleIsOpen: toggleIsAssignmentDialogOpen,
  } = useIsOpen();

  const handleClickAssign = useCallback((row: TableRow) => {
    setRowToAssign(row);
    toggleIsAssignmentDialogOpen();
  }, [toggleIsAssignmentDialogOpen]);

  const handleCloseAssignmentDialog = useCallback(() => {
    setRowToAssign(null);
    toggleIsAssignmentDialogOpen();
  }, [toggleIsAssignmentDialogOpen]);

  const handleClearRowToDelete = useCallback(() => {
    setRowToDelete(null);
  }, []);

  const columnArgs = useMemo<UseColumnsArgs>(() => ({
    handleClickAssign,
    handleClickDelete: setRowToDelete,
  }), [handleClickAssign]);

  const {
    data: allMembersData,
    loading: isMemberDataLoading,
  } = useGetAllMembers();

  const {
    data: assignmentData,
    isLoading: isFinancialFundraiserCreditAssignmentsLoading,
  } = useGetFundraiserCreditAssignments(financialFundraiserCreditId);

  const {
    data,
  } = useGetFinancialFundraiserCredit(financialFundraiserCreditId);

  const {
    mutate: deleteFundraiserCreditAssignment,
  } = useDeleteFundraiserCreditAssignment(financialFundraiserCreditId);

  const handleConfirmDelete = useCallback(() => {
    if (!rowToDelete?.assignmentId) {
      return;
    }

    deleteFundraiserCreditAssignment({
      fundraiserCreditAssignmentId: rowToDelete.assignmentId,
    }, {
      onSuccess: () => {
        dispatch(addNotification('Assignment deleted successfully', 'success'));
        setRowToDelete(null);
      },
    });
  }, [
    deleteFundraiserCreditAssignment,
    dispatch,
    rowToDelete,
  ]);

  const financialFundraiserCredit = data?.financialFundraiserCredit;

  const requiredAmountInCents = useMemo(() => {
    if (!financialFundraiserCredit) {
      return null;
    }

    return financialFundraiserCredit.isIndividualized
      ? null : financialFundraiserCredit.amountInCents;
  }, [financialFundraiserCredit]);

  const assignedMemberIds = useMemo(() => {
    return assignmentData?.data?.fundraiserCreditAssignments
      .map((assignment) => assignment.memberId) ?? [];
  }, [assignmentData]);

  const assignmentDataLookupByMemberId = useMemo<
    Record<string, MemberFundraiserCreditAssignment>>(() => {
      return assignmentData?.data?.fundraiserCreditAssignments?.reduce((acc, assignment) => {
        const { memberId } = assignment;

        return {
          ...acc,
          [memberId]: assignment,
        };
      }, {}) ?? {};
    }, [assignmentData]);

  const tableData = useMemo<TableRow[]>(() => {
    return allMembersData?.allMembers.map((member) => {
      const fundraiserCreditAssignmentDetails = assignmentDataLookupByMemberId[member.id];

      return {
        amountInCents: fundraiserCreditAssignmentDetails?.amountInCents ?? null,
        assignmentId: fundraiserCreditAssignmentDetails?.id ?? null,
        groups: member.groups,
        isFundraiserCreditAssigned: Boolean(fundraiserCreditAssignmentDetails),
        memberEmail: member.email,
        memberId: member.id,
        memberName: getFullName(member),
        primaryRoleLabel: member.primaryRole?.label ?? null,
        roleLabel: member.roleLabel,
      };
    }) ?? [];
  }, [
    assignmentDataLookupByMemberId,
    allMembersData,
  ]);

  const isLoading = isMemberDataLoading || isFinancialFundraiserCreditAssignmentsLoading;

  const columns = useColumns(columnArgs);

  const studentMembers = useMemo(
    () => allMembersData?.allMembers
      .filter((member) => member.roleLabel === UserRoles[UserRoles.Student]) ?? [],
    [allMembersData],
  );

  const uniqueStudentsAssignedCount = useMemo(() => {
    const studentMemberIds = studentMembers.map((member) => member.id);
    const feeStudentMemberIds = assignedMemberIds
      .filter((memberId) => studentMemberIds.includes(memberId)) ?? [];

    return new Set(feeStudentMemberIds).size;
  }, [
    assignedMemberIds,
    studentMembers,
  ]);

  const studentCount = studentMembers.length;

  return (
    <>
      <EnhancedCard>
        <Box
          paddingLeft={2}
          paddingTop={2}
        >
          {isLoading
            ? <CircularProgress size={26} />
            : (
              <Typography
                color="textSecondary"
                gutterBottom
              >
                Assigned to {uniqueStudentsAssignedCount} of {studentCount} students
              </Typography>
            )}
        </Box>

        <DataGridContainer>
          <TableDataGrid
            addButtonProps={{
              label: 'Assignment',
              onClick: toggleIsAssignmentDialogOpen,
            }}
            columns={columns}
            getRowId={getRowId}
            loading={isLoading}
            rows={tableData}
            tableResource="financialItemMemberFees"
            withSearch
          />
        </DataGridContainer>
      </EnhancedCard>

      <AddAssignmentDialog
        assignedMemberIds={assignedMemberIds}
        financialFundraiserCreditId={financialFundraiserCreditId}
        isOpen={isAssignmentDialogOpen}
        memberId={rowToAssign?.memberId ?? null}
        onClose={handleCloseAssignmentDialog}
        requiredAmountInCents={requiredAmountInCents}
      />

      <DialogPermissionRequired
        isOpen={rowToDelete !== null && !canWritePayments}
        onClose={handleClearRowToDelete}
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmDelete}
        description="This will remove the fundraiser credit from the student."
        handleClose={handleClearRowToDelete}
        open={rowToDelete !== null && canWritePayments}
        title="Delete this assignment?"
      />
    </>
  );
};

export default FinancialFundraiserCreditMembersTable;
