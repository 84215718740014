// External Dependencies
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useParsedSearch } from 'hooks/useParsedSearch';
import SchoolYearDataGridFilter from 'components/shared/SchoolYearDataGridFilter';

// Local Dependencies
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import DataGridFilterCard from 'components/shared/DataGridFilterCard';
import FinancialFeesTable from './FinancialFeesTable';
import LinkToMyFees from './LinkToMyFees';
import OnlinePaymentsAlert from '../../shared/OnlinePaymentsAlert';

// Component Definition
const FinancialFees = (): JSX.Element => {
  // We only show the link to MyFees if the director has relationships
  const hasRelationships = useHasRelationships();
  const orgCurrentSchoolYearEnding: number = useGetOrganizationSchoolYear();

  const search = useParsedSearch();
  const { schoolYearEnding = orgCurrentSchoolYearEnding } = search;

  const defaultDataGridFilter = useMemo(() => {
    return '{"items":[{"field":"isActiveMember","operator":"is","id":53238,"value":"true"}],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}';
  }, []);

  const getPathOnFilterChange = useCallback((sye: number | string) => {
    const queryString = (Number(sye) !== Number(schoolYearEnding))
      ? stringifyQueryParams({
        ...search,
        data_grid_filters: defaultDataGridFilter,
        school_year_ending: sye,
      })
      : stringifyQueryParams({
        ...search,
        school_year_ending: sye,
      });

    return `/${PATHS.FINANCIAL_FEES}?${queryString}`;
  }, [search, schoolYearEnding, defaultDataGridFilter]);

  return (
    <>
      <OnlinePaymentsAlert />

      <Subtitle>
        Financial Fees
      </Subtitle>

      <DataGridFilterCard>
        <SchoolYearDataGridFilter
          getPathOnFilterChange={getPathOnFilterChange}
          schoolYearEndingFromUrl={Number(schoolYearEnding)}
        />
      </DataGridFilterCard>

      <Box
        marginBottom={4}
        marginTop={2}
      >
        <EnhancedCard>
          <FinancialFeesTable schoolYearEnding={Number(schoolYearEnding)} />
        </EnhancedCard>
      </Box>

      {hasRelationships && <LinkToMyFees />}
    </>
  );
};

export default FinancialFees;
