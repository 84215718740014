// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  addPreviewData, close, open, reset, updateStep,
} from './actions';

// Local Typings
interface ICurrentStepAction {
  payload: number;
  type: string;
}

interface IPreviewDataAction {
  payload: GQL.ICSVUploadPreview;
  type: string;
}

export interface UncategorizedFinancialFeeFileUploadDialogState {
  currentStep: number;
  isOpen: boolean;
  previewData: GQL.ICSVUploadPreview | {};
}

// Reducers
const CURRENT_STEP_INITIAL_STATE: UncategorizedFinancialFeeFileUploadDialogState['currentStep'] = 1;
const currentStep = createReducer(
  CURRENT_STEP_INITIAL_STATE,
  (handleAction) => [
    handleAction(
      updateStep,
      (state, { payload }: ICurrentStepAction) => payload,
    ),
    handleAction([reset, logoutCurrentUser], () => CURRENT_STEP_INITIAL_STATE),
  ],
);

const isOpen = createReducer(false, (handleAction) => [
  handleAction([close, logoutCurrentUser], () => false),
  handleAction(open, () => true),
]);

const PREVIEW_DATA_INITIAL_STATE: UncategorizedFinancialFeeFileUploadDialogState['previewData'] = {};
const previewData = createReducer(
  PREVIEW_DATA_INITIAL_STATE,
  (handleAction) => [
    handleAction(
      addPreviewData,
      (state, { payload }: IPreviewDataAction) => payload,
    ),
    handleAction([reset, logoutCurrentUser], () => PREVIEW_DATA_INITIAL_STATE),
  ],
);

export default combineReducers<UncategorizedFinancialFeeFileUploadDialogState>({
  currentStep,
  isOpen,
  previewData,
});
