// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { useGetInventoryAudits } from 'utils/api/inventory';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.INVENTORY_AUDITS}/${id}`;

// Component Definition
const InventoryTable = (): JSX.Element => {
  const {
    data,
    isLoading,
  } = useGetInventoryAudits();

  const columns = useColumns();

  const rows = useMemo(() => data?.data.data || [], [data]);

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleTableClickRow}
        columns={columns}
        loading={isLoading}
        rows={rows}
        tableResource="inventoryAudits"
        withSearch
        zeroStateMessage="There are no inventory audits at this time."
      />
    </DataGridContainer>
  );
};

export default InventoryTable;
