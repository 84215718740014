// External Dependencies
import { Box } from '@mui/material';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PersonIcon from '@mui/icons-material/Person';

// Internal Dependencies
import {
  DashboardCard,
  DialogPeoplePicker,
  FinancialStatementDialog,
  SplitButton,
} from 'components/shared';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';
import { useDownloadFinancialStatements } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import DownloadBalanceSheetButton from 'components/shared/DownloadBalanceSheetButton';

// Local Variables
const StatementCard: FC = () => {
  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const dispatch = useDispatch();

  const {
    isOpen: isAllStatementsPreviewOpen,
    toggleIsOpen: toggleIsAllStatementsPreviewOpen,
  } = useIsOpen();

  const [
    downloadFinancialStatements,
    {
      loading: isDownloadingStatements,
    },
  ] = useDownloadFinancialStatements();

  const handleDownloadFile = useCallback((userIds?: string[]) => {
    downloadFinancialStatements({
      variables: {
        input: userIds
          ? {
            userIds,
          }
          : {},
        withDownload: true,
      },
    });
  }, [downloadFinancialStatements]);

  const handleDownloadAll = useCallback(() => {
    handleDownloadFile();
  }, [handleDownloadFile]);

  const handleOpenPeoplePicker = useCallback(() => {
    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUserIds.length) {
      handleDownloadFile(selectedUserIds);
    }
  }, [handleDownloadFile, selectedUserIds]);

  return (
    <>
      <DashboardCard
        content={(
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={1.5}
          >
            <DownloadBalanceSheetButton variant="outlined" />

            <SplitButton
              buttons={[
                {
                  label: 'Select Individual Members',
                  onClick: handleOpenPeoplePicker,
                  startIcon: <PersonIcon />,
                },
              ]}
              isLoading={isDownloadingStatements}
              primaryButtonProps={{
                label: 'All statements',
                onClick: handleDownloadAll,
                startIcon: <CloudDownloadIcon />,
              }}
              secondaryButtonAriaLabel="Select download option"
            />
          </Box>
        )}
        horizontallyCenterContent
        title="Downloads"
        verticallyCenterContent
      />

      <FinancialStatementDialog
        downloadFileName="Financial statements"
        isOpen={isAllStatementsPreviewOpen}
        onClose={toggleIsAllStatementsPreviewOpen}
      />

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={setSelectedUserIds}
        tableResource="peoplePickerStatementCard"
        title="Download Statements"
      />
    </>
  );
};

export default StatementCard;
