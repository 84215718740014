// External Dependencies
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { SelectOneUser } from 'components/shared';

// Local Typings
interface Props {
  studentId?: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.sectionSubtitle': {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  padding: theme.spacing(1.5, 3),
}));

// Component Definition
const PaymentFormStudent = ({
  studentId,
}: Props): JSX.Element => {
  return (
    <StyledCardContent>
      <Typography
        className="sectionSubtitle"
        component="h2"
        variant="subtitle1"
      >
        Select a member
      </Typography>

      <SelectOneUser userId={studentId} />
    </StyledCardContent>
  );
};

export default PaymentFormStudent;
