// External Dependencies
import { CreateVancoFinancialPaymentPayload } from '@presto-assistant/api_types/api/v1/financialPayment';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { useTanstackMutation } from 'utils/api/index';

export const useCreateVancoPayment = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: CreateVancoFinancialPaymentPayload) => {
    return apiClient.v1.financialPayment.createVancoPayment(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('financialPayment');
      evictApolloCache('myFinancialFees');
      evictApolloCache('myFinancialPayments');
      evictApolloCache('myFinancialFeesAll');
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};
