import { FormResponsesResponse, FormResponsesResponseItem } from '@presto-assistant/api_types/api/v1/form';
import { apiClient } from 'utils/apiClient';
import { useCallback, useMemo } from 'react';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';

export const useGetFormResponses = (formId: string) => {
  const dataSelector = useMemo(() => {
    return (response: FormResponsesResponse) => response.data;
  }, []);

  const request = useCallback((queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.form.formResponses({
      formId,
      queryParams,
    });
  }, [formId]);

  return useInfinitePaginatedListQuery<
    FormResponsesResponse,
    FormResponsesResponseItem
  >({
    dataSelector,
    pageSize: 500,
    queryKey: ['formResponses', formId],
    request,
  });
};
