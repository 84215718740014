import { useLazyQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

export interface CalendarListItem {
  activatedAt: string | null;
  backgroundColor: string;
  foregroundColor: string;
  id: string;
  isActive: boolean;
  summary: string;
}

export interface GoogleCalendarEventDate {
  date: string | null;
  dateTime: string | null;
}

export interface GoogleCalendarEvent {
  description: string | null;
  end: GoogleCalendarEventDate;
  googleCalendarId: string;
  id: string;
  location: string | null;
  start: GoogleCalendarEventDate;
  summary: string | null;
}

interface CalendarListResponse {
  googleCalendarList: CalendarListItem[];
}

interface OAuthURLResponse {
  googleCalendarOAuthUrl: string;
}

interface GoogleCalendarEventsResponse {
  googleCalendarEventsRange: GoogleCalendarEvent[];
}

export const GET_CALENDAR_LIST = gql`
  query GoogleCalendarList {
    googleCalendarList {
        activatedAt
        backgroundColor
        foregroundColor
        id
        isActive
        summary
    }
  }
`;

const GET_GOOGLE_CALENDAR_OAUTH_URL = gql`
  query GoogleCalendarOAuthUrl {
    googleCalendarOAuthUrl
  }
`;

const GET_GOOGLE_CALENDAR_EVENTS = gql`
  query GoogleCalendarEventsRange(
    $endDate: Float!
    $startDate: Float!
  ) {
    googleCalendarEventsRange(
      endDate: $endDate
      startDate: $startDate
    ) {
      description
      id
      googleCalendarId
      start {
        date
        dateTime
      }
      end {
        date
        dateTime
      }
      location
      summary
    }
  }
`;

export const useGetCalendarList = () =>
  useLazyQueryEnhanced<CalendarListResponse>(GET_CALENDAR_LIST);

export const useGetGoogleCalendarOAuthUrl = () =>
  useLazyQueryEnhanced<OAuthURLResponse>(
    GET_GOOGLE_CALENDAR_OAUTH_URL,
    {
      onCompleted: (data) => {
        if (data.googleCalendarOAuthUrl) {
          window.location.href = data.googleCalendarOAuthUrl;
        }
      },
    },
  );

export const useGetGoogleCalendarEvents = ({
  endDate,
  startDate,
}: {
  endDate: number;
  startDate: number;
}) =>
  useLazyQueryEnhanced<GoogleCalendarEventsResponse>(
    GET_GOOGLE_CALENDAR_EVENTS,
    {
      variables: {
        endDate,
        startDate,
      },
    },
  );
