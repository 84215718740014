// External Dependencies
import { Collapse } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Local Typings
interface Props {
  errorMessage?: string;
}

// Component Definition
const ChecklistErrorAlert: FC<Props> = ({ errorMessage }) => (
  <Collapse in={Boolean(errorMessage)}>
    <EnhancedAlert
      severity="error"
      sx={{ marginBottom: 1 }}
    >
      {errorMessage}
    </EnhancedAlert>
  </Collapse>
);

export default ChecklistErrorAlert;
