export const imageFileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'gif',
  'heic',
];

export const UPLOAD_FILE_TYPES = {
  allImages: 'image/*',
  csv: '.csv, text/csv',
  jpeg: 'image/jpeg',
  msoffice: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  msword: 'application/msword',
  pdf: 'application/pdf',
  png: 'image/png',
  text: 'text/plain',
};

export const ONE_MEGABYTE_IN_BYTES = 1_000_000;
