// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { GET_SELF, useGetDistrict, useGetSelf } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { getFullName } from 'utils';
import { useClaimMyOrganization } from 'gql/mutations';

// Component Definition
const AdminOrganizationsClaim: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [orgClaimId, setOrgClaimId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const districtData = useGetDistrict();

  const self = useGetSelf();

  const district = useMemo(() => districtData.data?.district, [districtData.data]);

  const userOrgData = useMemo(() => self.data?.self.userOrgData, [self.data]);

  const districtId = district?.id;
  const districtName = district?.label;
  const selfAuthUserId = self.data?.self.authUserId;

  const myOrgs = useMemo(
    () => userOrgData?.filter((userOrg) =>
      userOrg.organization.creatorId === selfAuthUserId
      && (!userOrg.district || userOrg.district?.id === districtId)),
    [districtId, selfAuthUserId, userOrgData],
  );

  const handleNavigateToDashboard = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`);
  }, [navigate]);

  useEffect(() => {
    if (orgClaimId) {
      setIsOpen(true);
    }
  }, [orgClaimId]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleCompleted = useCallback(() => {
    dispatch(addNotification('Organization claimed!', 'success'));
    handleClose();
  }, [dispatch, handleClose]);

  const [
    claimMyOrganization,
    {
      loading: isSubmitting,
    },
  ] = useClaimMyOrganization({
    clearCachePredicates: ['districtOrganizations'],
    onCompleted: handleCompleted,
    refetchQueries: () => [getOperationName(GET_SELF) as string],
  });

  const handleClearOrgClaim = useCallback(() => {
    setOrgClaimId(null);
  }, []);

  const handleClickClaimButton = useCallback(
    (organizationId: string) => () => setOrgClaimId(organizationId),
    [],
  );

  const handleClickConfirm = () => {
    claimMyOrganization({
      variables: {
        organizationId: orgClaimId,
      },
    });
  };

  const claimOrganization = useMemo(
    () => myOrgs?.find((org) => org.organizationId === orgClaimId),
    [myOrgs, orgClaimId],
  );

  return (
    <Container maxWidth="md">
      {!self.data || !myOrgs?.length ? (
        <>
          <Box mb={2}>
            <EnhancedAlert severity="warning">
              No organizations to claim.
            </EnhancedAlert>
          </Box>

          <Button
            color="primary"
            onClick={handleNavigateToDashboard}
            variant="outlined"
          >
            Back to Dashboard
          </Button>
        </>
      ) : (
        <>
          <Subtitle>
            Your organizations
          </Subtitle>

          <Box mb={1.5}>
            <EnhancedAlert>
              <Typography
                gutterBottom
                variant="body2"
              >
                Choose which of your organizations you wish
                to associate with <strong>{districtName}</strong>.
              </Typography>

              <Typography
                gutterBottom
                variant="body2"
              >
                When an organization belongs to a district,{' '}
                the District Administrator and District Assistants
                will have the ability to view and manage all inventory.
              </Typography>
            </EnhancedAlert>
          </Box>

          <EnhancedCard>
            <List>
              {myOrgs?.map((userOrg) => (
                <ListItem key={userOrg.id}>
                  <ListItemIcon>
                    <BusinessIcon htmlColor={theme.palette.prestoPrimaryLight} />
                  </ListItemIcon>
                  <ListItemText
                    primary={userOrg.organization.label}
                    secondary={(
                      <>
                        <Typography>
                          {userOrg.organization.entityType.label}{' '}
                          {userOrg.organization.organizationType.label}
                        </Typography>

                        <Typography>
                          Director: {userOrg.organization.creator
                          ? getFullName(userOrg.organization.creator) : 'No director found'}
                        </Typography>
                      </>
                    )}
                  />

                  <Box>
                    {userOrg.district?.id === districtId ? (
                      <Typography>
                        <strong>Claimed!</strong>
                      </Typography>
                    ) : (
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleClickClaimButton(userOrg.organizationId)}
                        variant="outlined"
                      >
                        Claim
                      </Button>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </EnhancedCard>
        </>
      )}

      <ConfirmationDialog
        canBeUndone={false}
        confirmButtonAction={handleClickConfirm}
        declineButtonAction={handleClose}
        description="This action will allow all inventory items for this organization to be managed by the district."
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        onExited={handleClearOrgClaim}
        open={isOpen}
        title={`Are you sure you want to claim ${claimOrganization?.organization.label ?? 'this organization'}?`}
      />
    </Container>
  );
};

export default AdminOrganizationsClaim;
