// External Dependencies
import { Ref, forwardRef } from 'react';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Settings from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Dependencies
import EnhancedCard from './EnhancedCard';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.stripeBlue['300'],
}));

// Component Definition
const IntegrationsCard = forwardRef((
  props: Props,
  ref: Ref<HTMLDivElement>,
): JSX.Element => {
  const { children } = props;

  return (
    <EnhancedCard
      id="integrations"
      ref={ref}
    >
      <CardHeader
        avatar={(
          <StyledAvatar>
            <Settings aria-label="Integrations" />
          </StyledAvatar>
        )}
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            Integrations
          </Typography>
        )}
      />

      <CardContent>
        {children}
      </CardContent>
    </EnhancedCard>
  );
});

export default IntegrationsCard;
