// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  ShowPageDataDisplay,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';

// Local Dependencies
import EnhancedDataCard from './EnhancedDataCard';

// Local Typings
interface Props {
  inventoryCheckoutData: GQL.IInventoryCheckout;
}

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const InventoryDataCard: FC<Props> = ({
  inventoryCheckoutData,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));

  const {
    checkoutDate,
    dueDate,
    id: inventoryCheckoutId,
    item,
  } = inventoryCheckoutData;

  const handleClickEditIconButton = useCallback(() => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/${inventoryCheckoutId}/edit`);
  }, [inventoryCheckoutId, navigate]);

  const action = inventoryCheckoutId && canEditInventoryCheckouts && (
    <IconButton
      aria-label="Edit"
      onClick={handleClickEditIconButton}
      size="large"
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  return (
    <EnhancedDataCard
      backgroundColor={theme.palette.inventory.dataCard}
    >
      <CardHeader
        action={action}
        title="Inventory item"
      />
      <CardContent>
        <ShowPageDataDisplay
          label="Name"
          value={item.label}
        />
        <ShowPageDataDisplay
          label="Brand"
          value={item.brand}
        />
        <ShowPageDataDisplay
          label="Model"
          value={item.model}
        />
        <ShowPageDataDisplay
          label="Locker"
          value={item.locker}
        />
        <ShowPageDataDisplay
          label="Lock Number"
          value={item.lockNumber}
        />
        <ShowPageDataDisplay
          label="Combination"
          value={item.combination}
        />
        <ShowPageDataDisplay
          label="Serial Number"
          value={item.serialNumber}
        />
        <ShowPageDataDisplay
          label="Barcode"
          value={item.customBarcode}
        />
        <ShowPageDataDisplay
          label="Checkout Date"
          value={checkoutDate}
        />
        <ShowPageDataDisplay
          label="Due Date"
          value={dueDate}
        />
      </CardContent>
    </EnhancedDataCard>
  );
};

export default InventoryDataCard;
