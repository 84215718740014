// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn } from 'utils/lib/tableColumns';

// Local Typings
// This is taken from the part of the API response interface
export interface AssignedOrganization {
  completedAt: string | null;
  numberOfInventoryItems: number;
  numberOfInventoryItemsAudited: number;
  numberOfInventoryItemsFound: number;
  numberOfInventoryItemsNotFound: number;
  organization: {
      id: string;
      label: string;
  };
}

// Local Variables
export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<AssignedOrganization>[] = [
      {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 236,
        valueGetter: (params) => params.row.organization.label,
      },
      dateColumn({
        field: 'completedAt',
        headerName: 'Completed Date',
      }),
      {
        field: 'numberOfInventoryItems',
        headerName: 'Total Items',
        type: 'number',
      },
      {
        field: 'numberOfInventoryItemsAudited',
        headerName: '# Audited',
        type: 'number',
      },
      {
        field: 'numberOfInventoryItemsFound',
        headerName: '# Found',
        type: 'number',
      },
      {
        field: 'numberOfInventoryItemsNotFound',
        headerName: '# Not Found',
        type: 'number',
      },
    ];

    return columns;
  }, []);
};
