// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import AdminFormsTable from './AdminFormsTable';
import InventoryCheckoutFormAlert from '../shared/InventoryCheckoutFormAlert';

// Component Definition
const AdminForms = (): JSX.Element => {
  return (
    <>
      <Subtitle>District Forms</Subtitle>

      <InventoryCheckoutFormAlert />

      <AdminFormsTable />
    </>
  );
};

export default AdminForms;
