// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFinancialFeesTable from './MyFinancialFeesTable';

// Component Definition
const MyFinancialFees = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        My Fees
      </Subtitle>

      <MyFinancialFeesTable />
    </>
  );
};

export default MyFinancialFees;
