// External Dependencies
import {
  FC,
  useMemo,
} from 'react';
import Container from '@mui/system/Container';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

// Internal Dependencies
import { FinancialPaymentsForTransactionIdResponseItem } from '@presto-assistant/api_types/api/v1/financialTransaction';
import {
  ShowCard,
  ShowPageDataDisplay,
  UserLabelWithLink,
} from 'components/shared';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
  formatDateTime,
} from 'utils';
import { renderPaymentType } from 'pages/Finances/FinancialPayments/shared/StripeStatusChip';
import { useGetMember } from 'gql/queries';

// Local Typings
interface Props {
  payments: FinancialPaymentsForTransactionIdResponseItem[]
}

// Component Definition
const TransactionPaymentsDetailsCard: FC<Props> = ({
  payments,
}) => {
  const {
    data: createdByUser,
    loading,
  } = useGetMember(payments[0].createdByMemberId);

  const totalAmountPaid = useMemo(() => {
    const totalInCents = payments.reduce((n, { amountInCents }) => n + amountInCents, 0);
    const totalInDollars = convertCentsToDollars(totalInCents);

    return displayPriceStringFromDollarAmount(totalInDollars);
  }, [payments]);

  const dateCreated = useMemo(() => {
    return formatDateTime(payments[0].createdAt);
  }, [payments]);

  return (
    <Container sx={{ mb: 2 }}>
      <ShowCard
        icon={LocalAtmIcon}
        isLoading={loading}
        title="All Payments from Transaction"
      >
        {createdByUser && (
          <ShowPageDataDisplay
            label="Created By"
            value={<UserLabelWithLink user={createdByUser.user} />}
          />
        )}

        <ShowPageDataDisplay
          label="Date Created"
          value={dateCreated}
        />

        <ShowPageDataDisplay
          label="Total Amount Paid"
          value={totalAmountPaid}
        />

        <ShowPageDataDisplay
          label="Payment Type"
          value={renderPaymentType({
            financialPaymentTypeId: payments[0]?.financialPaymentTypeId,
            financialPaymentTypeLabel: payments[0]?.financialPaymentTypeLabel,
            stripePaymentStatusId: payments[0]?.stripePaymentStatusId,
          })}
        />
      </ShowCard>
    </Container>
  );
};

export default TransactionPaymentsDetailsCard;
