// Internal Dependencies
import { useGetDistrict } from 'gql/queries';
import DistrictDataUI from 'components/shared/Settings/DistrictDataUI';

// Component Definition
const AdminSettingsDistrictData = (): JSX.Element | null => {
  const { data } = useGetDistrict();

  if (!data) {
    return null;
  }

  return <DistrictDataUI district={data.district} />;
};

export default AdminSettingsDistrictData;
