// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  FormResponsesResponseItem,
} from '@presto-assistant/api_types/api/v1/form';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetSelf } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import MemberToolbarActionDialogs, { useMemberToolbarActions } from 'pages/People/Students/All/StudentsTable/MemberToolbarActionDialogs';

// Local Dependencies
import { useColumns } from './hooks';
import { useGetFormResponses } from '../hooks';

// Local Typings
interface Props {
  formId: string;
}

// Component Definition
const ResponsesTable: FC<Props> = ({
  formId,
}) => {
  const navigate = useNavigate();

  const { data: selfData } = useGetSelf();

  const selfMemberId = selfData?.self?.id;

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedRowIds,
    setSelectedRowIds,
  ] = useState<GridRowSelectionModel>([]);

  const {
    data,
    isLoading,
  } = useGetFormResponses(formId);

  const selfAssignmentId = useMemo<string | undefined>(() => {
    return data?.find((row) => row.assignedMemberId === selfMemberId)?.formAssignmentId;
  }, [data, selfMemberId]);

  const handleClickRespond = useCallback(() => {
    navigate(`/${PATHS.MY_FORMS}/${selfAssignmentId}`);
  }, [navigate, selfAssignmentId]);

  const isAssignedToSelfAndNotSubmitted = useMemo<boolean>(() => {
    return data?.some((row) =>
      row.assignedMemberId === selfMemberId && !row.submittedAt) ?? false;
  }, [data, selfMemberId]);

  const selectedMemberIds = useMemo<string[]>(() => {
    return data?.filter((row) => selectedRowIds.includes(row.formAssignmentId))
      .map((row) => row.assignedMemberId) ?? [];
  }, [data, selectedRowIds]);

  const filteredMemberIds = useMemo<string[]>(() => {
    return data?.filter((row) => filteredRowIds.includes(row.formAssignmentId))
      .map((row) => row.assignedMemberId) ?? [];
  }, [data, filteredRowIds]);

  const tableColumns = useColumns(formId);

  const {
    isSendStatementDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    onCloseAssignMembersToGroupDialog,
    onCloseSendStatementDialog,
    toolbarActions,
  } = useMemberToolbarActions({
    filteredMemberIds,
    selectedMemberIds,
    withImports: false,
  });

  return (
    <>
      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            addButtonProps={isAssignedToSelfAndNotSubmitted ? {
              label: 'Respond',
              onClick: handleClickRespond,
              variant: 'contained',
            } : undefined}
            checkboxSelection
            columns={tableColumns}
            getRowId={(row: FormResponsesResponseItem) => row.formAssignmentId}
            loading={isLoading}
            onFilter={setFilteredRowIds}
            onSelectionModelChange={setSelectedRowIds}
            rows={data}
            selectionModel={selectedRowIds}
            toolbarActions={toolbarActions}
            withSearch
            zeroStateMessage="Assign this form to members to see responses."
          />
        </DataGridContainer>
      </EnhancedCard>

      <MemberToolbarActionDialogs
        isSendStatementDialogOpen={isSendStatementDialogOpen}
        isUpdatingStudents={isUpdatingStudents}
        memberIdsToAssignToGroup={memberIdsToAssignToGroup}
        memberIdsToSendStatementsTo={memberIdsToSendStatementsTo}
        onCloseAssignMembersToGroupDialog={onCloseAssignMembersToGroupDialog}
        onCloseSendStatementDialog={onCloseSendStatementDialog}
      />
    </>
  );
};

export default ResponsesTable;
