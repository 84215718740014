// External Dependencies
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FactCheckIcon from '@mui/icons-material/FactCheck';

// Internal Dependencies
import { DialogPeoplePicker, ShowCard, ShowPageDataDisplay } from 'components/shared';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import { open } from 'state/ui/peoplePickerDialog/actions';
import { useUpdateFormMemberAssignments } from 'gql/mutations/form-mutations';
import FormResponsesProgressBar from 'pages/Forms/components/DetailsCard/FormResponsesProgressBar';

// Local Typings
interface Props {
  form: GQL.IForm;
}

// Component Definition
const AssignmentDetailsCard = ({
  form,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const [
    updateFormMemberAssignments,
  ] = useUpdateFormMemberAssignments();

  const selectedMemberIds = useMemo(() => (
    form?.assignedMembers?.map((member) => member.id) ?? []
  ), [form]);

  const handleUpdateSelectedMembers = useCallback((selectedIds: string[]) => {
    updateFormMemberAssignments({
      variables: {
        input: {
          formId: form.id,
          memberIds: selectedIds,
        },
      },
    });
  }, [form.id, updateFormMemberAssignments]);

  const handleClickOpenDialogPeoplePicker = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  return (
    <>
      <ShowCard
        cardActions={(
          <CardActions>
            <Button
              onClick={handleClickOpenDialogPeoplePicker}
              variant="outlined"
            >
              Assign Members
            </Button>
          </CardActions>
        )}
        icon={FactCheckIcon}
        title="Assignment Details"
      >
        <CardContent>
          <ShowPageDataDisplay
            label="# of Assigned Members"
            type="number"
            value={form?.assignedMembers?.length ?? 0}
          />

          <Box marginTop={2}>
            <FormResponsesProgressBar form={form} />
          </Box>
        </CardContent>
      </ShowCard>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateSelectedMembers}
        preSelectedIds={selectedMemberIds}
        tableResource="peoplePickerForm"
        title="Select Members"
      />
    </>
  );
};

export default AssignmentDetailsCard;
