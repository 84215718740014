// External Dependencies
import { FC, ReactNode } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props {
  item1: string | ReactNode;
  item2?: string | ReactNode;
  item3?: string | ReactNode;
}

// Local Variables
const StyledList = styled(List)(({ theme }) => ({
  '.MuiListItem-gutters': {
    padding: theme.spacing(0, 1),
  },

  '.MuiListItem-root': {
    alignItems: 'flex-start',
    display: 'flex',
  },

  '.MuiListItemIcon-root': {
    minWidth: theme.spacing(5),
    transform: 'translateY(4px)',
  },
}));

// Component Definition
const SignupInfoList: FC<Props> = ({
  item1,
  item2,
  item3,
}) => {
  const theme = useTheme();

  const listItemCheckIcon = (
    <ListItemIcon>
      <DoneIcon htmlColor={theme.palette.prestoSecondaryDark} />
    </ListItemIcon>
  );

  return (
    <StyledList>
      <ListItem>
        {listItemCheckIcon}
        <ListItemText primary={item1} />
      </ListItem>

      {item2 && (
        <ListItem>
          {listItemCheckIcon}
          <ListItemText primary={item2} />
        </ListItem>
      )}

      {item3 && (
        <ListItem>
          {listItemCheckIcon}
          <ListItemText primary={item3} />
        </ListItem>
      )}
    </StyledList>
  );
};

export default SignupInfoList;
