// External Dependencies
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';
import { sendToStripe } from 'utils/api';
import { useGetOrganization } from 'gql/queries';

export const useSendToStripe = () => {
  const dispatch = useDispatch();

  const {
    data: orgData,
  } = useGetOrganization();

  const district = orgData?.organization.district;

  const hasDistrictEnabledOnlinePayments = district?.areOnlinePaymentsEnabled;

  const [isSending, setIsSending] = useState(false);

  const handleAlertUser = useCallback(() => {
    if (!district) {
      dispatch(addNotification('You must belong to a district to enable online payments.', 'error'));
    } else {
      dispatch(addNotification('Your district has not enabled online payments. Please ask your administrator to enable this feature.', 'error'));
    }
  }, [district, hasDistrictEnabledOnlinePayments]);

  const handleSendToStripe = useCallback(async () => {
    setIsSending(true);

    try {
      const response = await sendToStripe();

      if (response?.data?.url) {
        window.location.href = response.data.url;
      } else if (response?.data?.error) {
        dispatch(addNotification(response.data.error, 'error'));
      }
    } catch (err) {
      console.error('There was an error while syncing with stripe.', err);
    }
  }, []);

  return useMemo(() => ({
    handleClickStripeButton: hasDistrictEnabledOnlinePayments
      ? handleSendToStripe
      : handleAlertUser,
    isSending,
  }), [
    handleAlertUser,
    handleSendToStripe,
    isSending,
  ]);
};
