// External Dependencies
import {
  Box,
  Button,
  CardContent,
  Grow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  OnboardingStepTitle,
  PaperAirplaneSvg,
} from 'components/shared';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import DialogResendVerificationEmail from './DialogResendVerificationEmail';
import OnboardingProgress from './OnboardingProgress';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.description': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: theme.spacing(1.5),
    },
    margin: '16px 24px',
  },
  '.emailText': {
    fontSize: '1.2em',
    fontWeight: 500,
    margin: '4px 0',
  },
  '.sendInviteButton': {
    '&:disabled': {
      background: theme.palette.grey[300],
      cursor: 'not-allowed',
    },
    marginTop: 12,
  },
  '.sentAgainText': {
    color: theme.palette.success.dark,
    fontFamily: 'BioRhyme',
    fontWeight: 500,
    letterSpacing: '1px',
    marginTop: 16,
    transform: 'rotate(-2deg)',
  },
}));

const emailHref = `
  mailto:support@presto-assistant.com?subject=Verify%20email%20in%20onboarding%20in%20Presto%20Assistant
`;

// Component Definition
const OnboardingVerifyEmail = () => {
  const [isEmailSentAgain, setIsEmailSentAgain] = useState(false);
  const [
    isDialogResendVerificationEmailOpen,
    setIsDialogResendVerificationEmailOpen,
  ] = useState(false);

  // Self
  const { loading: isLoadingSelf, self } = useSelfQuery();

  if (isLoadingSelf) {
    return <OnboardingProgress />;
  }

  const toggleResendVerificationEmailDialog = () =>
    setIsDialogResendVerificationEmailOpen(!isDialogResendVerificationEmailOpen);

  return (
    <>
      <OnboardingStepTitle title="Verify Email" />

      <Grow in={!isLoadingSelf}>
        <StyledCardContent>
          <Typography
            component="h3"
            className="description"
            variant="subtitle1"
          >
            Please verify your email to complete registration.
          </Typography>

          <PaperAirplaneSvg />

          <Typography className="description">
            To make sure we&apos;ve got the correct email address, we&apos;ve sent an email to:
          </Typography>

          <Typography className="emailText">{self?.authUserEmail}</Typography>

          <Typography className="description">
            If you have not received the email after a few minutes, please check
            your spam folder.
          </Typography>

          <Box mt={3}>
            <Button
              classes={{
                outlinedPrimary: 'sendInviteButton',
              }}
              color="primary"
              onClick={toggleResendVerificationEmailDialog}
              type="button"
              variant="contained"
            >
              Resend email
            </Button>

            {isEmailSentAgain && (
              <Typography
                className="sentAgainText"
                variant="subtitle1"
              >
                Email sent again!
              </Typography>
            )}
          </Box>

          <Box mt={2}>
            <Button
              classes={{
                outlinedPrimary: 'sendInviteButton',
              }}
              color="primary"
              href={emailHref}
              size="small"
              variant="outlined"
            >
              Contact support
            </Button>
          </Box>
        </StyledCardContent>
      </Grow>

      <DialogResendVerificationEmail
        isOpen={isDialogResendVerificationEmailOpen}
        onClose={toggleResendVerificationEmailDialog}
        selfEmail={self?.authUserEmail}
        setIsEmailSentAgain={setIsEmailSentAgain}
      />
    </>
  );
};

export default OnboardingVerifyEmail;
