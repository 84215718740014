// External Dependencies
import { ReactElement } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import DialogFileUploadActions from 'components/shared/DialogFileUpload/DialogFileUploadActions';
import DialogFileUploadContent, {
  DialogFileUploadContentProps,
} from 'components/shared/DialogFileUpload/DialogFileUploadContent';
import EnhancedDialog from 'components/shared/EnhancedDialog';

// Local Typings
export interface Props extends DialogFileUploadContentProps {
  dialogTitle: string;
  disabled?: boolean;
  hideDialogActions?: boolean;
  hideDialogTitle?: boolean;
  id: string;
  isOpen: boolean;
  isUploading: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
  onClose: () => void;
  submitButton?: ReactElement | null;
}

// Local Variables
const StyledDialogFileUploadContent = styled(DialogFileUploadContent)(({
  theme,
}) => ({
  paddingTop: theme.spacing(2),
}));

// Component Definition
const DialogFileUpload = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  children,
  description,
  dialogTitle = '',
  disabled = false,
  hideDialogActions,
  hideDialogTitle,
  hideDropzone,
  id,
  isOpen,
  isUploading,
  maxWidth = 'sm',
  onClose,
  onFileUpload,
  submitButton,
  uploadDropzoneKey,
}: Props): JSX.Element => {
  return (
    <EnhancedDialog
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      open={isOpen}
    >
      {!hideDialogTitle && (
        <DialogTitle>{dialogTitle}</DialogTitle>
      )}

      <StyledDialogFileUploadContent
        acceptedFileTypes={acceptedFileTypes}
        description={description}
        disabled={disabled}
        dividers={!hideDialogActions}
        hideDropzone={hideDropzone}
        onFileUpload={onFileUpload}
        uploadDropzoneKey={uploadDropzoneKey}
      >
        {children}
      </StyledDialogFileUploadContent>

      {!hideDialogActions && (
        <DialogFileUploadActions
          acceptedFileTypes={acceptedFileTypes}
          disabled={disabled}
          id={id}
          isUploading={isUploading}
          key={uploadDropzoneKey}
          onClose={onClose}
          onFileUpload={onFileUpload}
          submitButton={submitButton}
        />
      )}
    </EnhancedDialog>
  );
};

export default DialogFileUpload;
