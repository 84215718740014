// Internal Dependencies
import { ALERT_IDS } from 'utils/constants/bannerAlerts';

export const bannerAlertItems = (state: any) =>
  state?.bannerAlerts?.data?.items;
export const hasBannerAlerts = (state: any) =>
  state?.bannerAlerts?.data?.items?.length > 0;

export const isGoogleCalAlertOpen = (state: any) => {
  if (hasBannerAlerts(state)) {
    return bannerAlertItems(state).some((alert: any) =>
      alert.id === ALERT_IDS.NO_G_CAL_ALERT);
  }
  return undefined;
};
