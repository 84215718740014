// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateResponse {
  id: string;
}

interface CreateData {
  createUniform: CreateResponse;
}
interface CreateDistrictData {
  districtCreateUniform: CreateResponse;
}

interface CreateUniformCategoryResponse {
  createUniformCategory: {
    id: string;
    label: string;
  };
}

interface CreateUniformStyleResponse {
  createUniformStyle: {
    id: string;
    label: string;
  };
}

interface UpdateResponse {
  id: string;
}

interface UpdateData {
  updateUniform: UpdateResponse;
}

interface UpdateDistrictData {
  districtUpdateUniform: UpdateResponse;
}

// Local Variables
export const CREATE_UNIFORM = gql`
  mutation CreateUniform(
    $input: CreateUniformInput!
  ) {
    createUniform(
      input: $input
    ) {
      id
    }
  }
`;

export const DISTRICT_CREATE_UNIFORM = gql`
  mutation DistrictCreateUniform(
    $input: DistrictCreateUniformInput!
  ) {
    districtCreateUniform(
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_UNIFORMS = gql`
  mutation DeleteUniforms(
    $selection: Selection!
    $deletedNote: String!
  ) {
    deleteUniforms(
      selection: $selection
      deletedNote: $deletedNote
    )
  }
`;

export const UPDATE_UNIFORM = gql`
  mutation UpdateUniform(
    $id: ID!
    $input: UpdateUniformInput!
  ) {
    updateUniform(
      id: $id
      input: $input
    ) {
      id
    }
  }
`;

export const DISTRICT_UPDATE_UNIFORM = gql`
  mutation DistrictUpdateUniform(
    $id: ID!
    $input: DistrictUpdateUniformInput!
  ) {
    districtUpdateUniform(
      id: $id
      input: $input
    ) {
      id
    }
  }
`;

const CREATE_UNIFORM_CATEGORY = gql`
  mutation CreateUniformCategory (
    $input: CreateUniformCategoryInput!
  ) {
    createUniformCategory(
      input: $input
    ) {
      id
      label
    }
}
`;

const CREATE_UNIFORM_STYLE = gql`
  mutation CreateUniformStyle (
    $input: CreateUniformStyleInput!
  ) {
    createUniformStyle(
      input: $input
    ) {
      id
      label
    }
}
`;

export const useCreateUniform = (
  options?: MutationOptions<CreateData, GQL.ICreateUniformOnMutationArguments>,
) => useMutationEnhanced<CreateData, GQL.ICreateUniformOnMutationArguments>(
  CREATE_UNIFORM,
  options,
);

export const useDistrictCreateUniform = (
  options?: MutationOptions<
    CreateDistrictData,
    GQL.IDistrictCreateUniformOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateDistrictData,
  GQL.IDistrictCreateUniformOnMutationArguments
>(
  DISTRICT_CREATE_UNIFORM,
  options,
);

export const useUpdateUniform = (
  options?: MutationOptions<UpdateData, GQL.IUpdateUniformOnMutationArguments>,
) => useMutationEnhanced<UpdateData, GQL.IUpdateUniformOnMutationArguments>(
  UPDATE_UNIFORM,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'history',
    ],
  },
);

export const useDeleteUniforms = (
  options?: MutationOptions<
    UpdateData,
    GQL.IDeleteUniformsOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateData,
  GQL.IDeleteUniformsOnMutationArguments
>(
  DELETE_UNIFORMS,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'history',
    ],
  },
);

export const useDistrictUpdateUniform = (
  options?: MutationOptions<
    UpdateDistrictData,
    GQL.IDistrictUpdateUniformOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateDistrictData,
  GQL.IDistrictUpdateUniformOnMutationArguments
>(
  DISTRICT_UPDATE_UNIFORM,
  options,
);

export const useCreateUniformCategory = (
  options?: MutationOptions<
    CreateUniformCategoryResponse,
    GQL.ICreateUniformCategoryOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateUniformCategoryResponse,
    GQL.ICreateUniformCategoryOnMutationArguments
  >(CREATE_UNIFORM_CATEGORY, options);

export const useCreateUniformStyle = (
  options?: MutationOptions<
    CreateUniformStyleResponse,
    GQL.ICreateUniformStyleOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateUniformStyleResponse,
    GQL.ICreateUniformStyleOnMutationArguments
  >(CREATE_UNIFORM_STYLE, options);
