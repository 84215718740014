// External Dependencies
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';
import styled from 'styled-components';

// Internal Dependencies
import { CONTACT_INFO } from 'utils/constants/app';
import {
  ConfirmationDialog,
  StyledLink,
  SupportLink,
} from 'components/shared';
import { StyledStrong } from 'components/shared/StyledStrong';
import { addNotification } from 'state/notifications/actions';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useRedeemLicense } from 'gql/mutations';

// Local Typings
export interface SingleOrgLicenseData {
  singleLicenseNumberOfMonths: GQL.ILicense['numberOfMonths'];
  singleLicenseRedemptionCode: GQL.ILicense['redemptionCode'];
}
interface Props {
  isOpen: boolean;
  numberOfAvailableLicenses: number | undefined;
  onClose: () => void;
  singleOrgData: GQL.IDistrictOrganizationIndexItem | null;
  singleOrgLicenseData: SingleOrgLicenseData | null;
}

// Local Variables
const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  ul: {
    'li:first-child': {
      marginBottom: theme.spacing(1),
    },
    marginBottom: 0,
  },
})) as typeof DialogContentText;

const SUPPORT_EMAIL_HREF = `
  ${CONTACT_INFO.MAILTO_SUPPORT}?subject=Purchase%20a%20Presto%20License
`;

// Component Definition
const RedeemLicenseConfirmationDialog = ({
  isOpen,
  numberOfAvailableLicenses,
  onClose,
  singleOrgData,
  singleOrgLicenseData,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const handleCompleted = useCallback(() => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));

    dispatch(addNotification('License redeemed!', 'success'));

    onClose();
  }, [dispatch, onClose]);

  const [
    redeemLicense,
    { loading: isSubmitting },
  ] = useRedeemLicense({
    onCompleted: handleCompleted,
  });

  const handleSubmit = useCallback(() => {
    redeemLicense({
      variables: {
        input: {
          organizationId: singleOrgData?.id,
          redemptionCode: singleOrgLicenseData?.singleLicenseRedemptionCode ?? '',
        },
      },
    });
  }, [redeemLicense, singleOrgLicenseData, singleOrgData]);

  const hasAvailableLicenses = numberOfAvailableLicenses && numberOfAvailableLicenses > 0;
  const hasActiveLicense = singleOrgData?.subscriptionDaysRemaining
    && singleOrgData.subscriptionDaysRemaining > 0;

  let title = 'Redeem Available License?';

  if (!hasAvailableLicenses) {
    title = 'No Available Licenses';
  }

  let descriptionText: JSX.Element | string = (
    <>
      <DialogContentText paragraph>
        {hasActiveLicense ? (
          <>
            The {singleOrgData.label} organization has{' '}
            <StyledStrong>{singleOrgData.subscriptionDaysRemaining}</StyledStrong>{' '}
            days until the current license expires. Adding a new license will{' '}
            extend the current subscription.
          </>
        ) : (
          <>
            The {singleOrgData?.label} organization does not have a license right now.
          </>
        )}
      </DialogContentText>

      <DialogContentText paragraph>
        Your district has{' '}
        <StyledStrong>{numberOfAvailableLicenses ?? 0}</StyledStrong>{' '}
        available licenses to redeem.
      </DialogContentText>

      <DialogContentText>
        <strong>Would you like to redeem a{' '}
          {singleOrgLicenseData?.singleLicenseNumberOfMonths}-month{' '}
          license for {singleOrgData?.label}?
        </strong>
      </DialogContentText>
    </>
  );

  if (!hasAvailableLicenses) {
    descriptionText = (
      <>
        <DialogContentText paragraph>
          You have no available licenses to redeem.
        </DialogContentText>

        <StyledDialogContentText
          component="section"
          sx={{ paddingBottom: 0 }}
        >
          <ul>
            <li>
              <SupportLink
                buttonText="Learn how to purchase a license"
                slug="purchase-a-presto-license"
              />
            </li>
            <li>
              <StyledLink
                href={SUPPORT_EMAIL_HREF}
                openInNewTab
              >
                Contact us for more information.
              </StyledLink>
            </li>
          </ul>
        </StyledDialogContentText>
      </>
    );
  }

  let confirmButtonText = 'Yes, Redeem License';

  if (!hasAvailableLicenses) {
    confirmButtonText = 'Ok';
  }

  return (
    <ConfirmationDialog
      confirmButtonAction={hasAvailableLicenses
        ? handleSubmit
        : onClose}
      confirmButtonText={confirmButtonText}
      declineButtonAction={onClose}
      declineButtonText="Not Now"
      description={descriptionText}
      handleClose={onClose}
      hideDeclineButton={!hasAvailableLicenses}
      isSubmitting={isSubmitting}
      open={isOpen}
      title={title}
      useCustomText
    />
  );
};

export default RedeemLicenseConfirmationDialog;
