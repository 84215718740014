// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import CSVImporter from 'components/CSVImporter';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogUniformFileUpload: FC<Props> = ({
  isOpen,
  onClose,
}) => (
  <CSVImporter
    context="uniform"
    csvFileInfoResource="dfaUniforms"
    dialogTitle="Add Uniforms"
    isAdmin
    insertMutationName="dfaInsertUniformCsvFileToDB"
    insertMutationOptions={{
      clearCachePredicates: ['districtUniformItemsIndex'],
    }}
    isOpen={isOpen}
    onClose={onClose}
    requiredColumnsHelperText={'Find the "organizationCode" for any organization by clicking on a row in the organizations table. You can quickly copy the code to add it to your CSV file.'}
    uploadMutationName="dfaCsvUniformUpload"
  />
);

export default DialogUniformFileUpload;
