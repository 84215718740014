// External Dependencies
import { GetInventoryAuditResponse } from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { ConfirmationDialog, EnhancedAlert } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { pluralize } from 'utils';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMarkInventoryAuditAsComplete } from 'utils/api/inventory';

// Local Typings
interface Props {
  inventoryAuditId: string;
  isComplete: boolean;
  itemsRemaining: number;
}

// Component Definition
const MarkAsComplete = ({
  inventoryAuditId,
  isComplete,
  itemsRemaining,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const {
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const {
    isLoading: isMarkingAsComplete,
    mutate: markAsComplete,
  } = useMarkInventoryAuditAsComplete();

  const handleConfirmMarkAsComplete = useCallback(() => {
    markAsComplete({
      params: { id: inventoryAuditId },
    }, {
      onSuccess: (res) => {
        dispatch(addNotification('Inventory audit has been marked as complete.', 'success'));

        queryClient.setQueryData<{ data: GetInventoryAuditResponse }>(['inventoryAudit', inventoryAuditId], { data: res.data });

        toggleIsOpen();
      },
    });
  }, [
    dispatch,
    inventoryAuditId,
    markAsComplete,
    queryClient,
    toggleIsOpen,
  ]);

  if (isComplete) {
    return null;
  }

  return (
    <>
      <Button
        onClick={toggleIsOpen}
        variant="contained"
      >
        Mark as Complete
      </Button>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmMarkAsComplete}
        declineButtonAction={toggleIsOpen}
        description={(
          <>
            {itemsRemaining > 0 && (
              <EnhancedAlert
                severity="warning"
                sx={{ marginBottom: 2 }}
              >
                {itemsRemaining} {pluralize(itemsRemaining, 'inventory item has', 'inventory items have')} not been assessed.
              </EnhancedAlert>
            )}

            <DialogContentText>
              Are you sure you want to mark this audit as complete?
              Your organization will not be able to make any changes after you confirm.
            </DialogContentText>
          </>
        )}
        handleClose={toggleIsOpen}
        isSubmitting={isMarkingAsComplete}
        open={isOpen}
        title="Mark as complete?"
        useCustomText
      />
    </>
  );
};

export default MarkAsComplete;
