// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { DELETE_UNIFORMS } from 'gql/mutations';
import { GET_UNIFORMS_INDEX } from 'gql/queries';
import { SELECTION_TYPES } from 'utils/constants';
import { getOperationName } from '@apollo/client/utilities';
import { hasPermission } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DangerZone, { DangerZoneItemProps } from 'components/shared/DangerZone';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Typings
interface Props {
  organizationLabel: string;
  uniformId?: string;
  uniformIndexUrl: string;
}

// Local Variables
const deleteUniformContext: [string] = ['uniform'];

// Component Definition
const UniformDangerZone: FC<Props> = ({
  organizationLabel,
  uniformId,
  uniformIndexUrl,
}) => {
  const navigate = useNavigate();

  const {
    isOpen: isRemoveUniformConfirmationDialogOpen,
    toggleIsOpen: toggleUniformConfirmationDialogIsOpen,
  } = useIsOpen();

  const canRemoveUniforms = useSelector(hasPermission('uniforms', 'delete'));
  const isMobileWidth = useSelector(isMobileScreenSize);

  const handleSuccess = useCallback(() => {
    navigate(uniformIndexUrl);
  }, [navigate, uniformIndexUrl]);

  const handleCloseDeleteUniformDialog = () => {
    toggleUniformConfirmationDialogIsOpen();
  };

  const handleOpenDeleteUniformDialog = () => {
    toggleUniformConfirmationDialogIsOpen();
  };

  const formatDeleteUniformPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteUniformsOnMutationArguments>['formatPayload']>((note) => ({

    deletedNote: note ?? '',
    selection: {
      ids: [uniformId ?? ''],
      mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
      queryParams: {},
    },

  }), [uniformId]);

  const items: DangerZoneItemProps[] = [];
  if (canRemoveUniforms) {
    items.push({
      buttonText: isMobileWidth ? 'Remove' : 'Remove uniform',
      description: `Remove this uniform item from the ${organizationLabel} organization. This does not delete the item from your district.`,
      onClick: handleOpenDeleteUniformDialog,
      title: 'Remove uniform',
    });
  }

  return (
    <>
      <Box mt={4}>
        <DangerZone items={items} />
      </Box>

      <DeleteDialogV2<GQL.IDeleteUniformsOnMutationArguments>
        actionVerb="Remove"
        clearCachePredicates={['uniforms']}
        context={deleteUniformContext}
        formatPayload={formatDeleteUniformPayload}
        hasSelection={false}
        isOpen={isRemoveUniformConfirmationDialogOpen}
        mutation={DELETE_UNIFORMS}
        onClose={handleCloseDeleteUniformDialog}
        onSuccess={handleSuccess}
        refetchQueries={() => [getOperationName(GET_UNIFORMS_INDEX) as string]}
        withNote
      />
    </>
  );
};

export default UniformDangerZone;
