// External Dependencies
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedDialog, SaveButton } from 'components/shared/index';
import {
  acceptedFileTypes,
  useOrganizationLogoUploader,
} from 'components/shared/OrganizationLogo/hooks';
import { close } from 'state/ui/organizationLogoUploadDialog/actions';
import { isOpen } from 'state/ui/organizationLogoUploadDialog/selectors';
import DialogFileUploadContent from 'components/shared/DialogFileUpload/DialogFileUploadContent';
import Flex from 'components/shared/Flex';
import ImageCrop from 'components/shared/ImageCrop';

// Component Definition
const OrganizationLogoUploaderDialog: FC = () => {
  const isDialogOpen = useSelector(isOpen);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(close());
  }, [close]);

  const {
    editorRef,
    file,
    handleChangeScale,
    handleClickInput,
    handleClickSave,
    hiddenInput,
    isSubmitting,
    scale,
    setFile,
    src,
  } = useOrganizationLogoUploader();

  return (
    <>
      <EnhancedDialog open={isDialogOpen}>
        <DialogTitle>Upload Organization Logo</DialogTitle>

        {file ? (
          <DialogContent>
            <Flex justifyContent="center">
              <ImageCrop
                editorRef={editorRef}
                handleChangeScale={handleChangeScale}
                scale={scale}
                src={src}
              />
            </Flex>
          </DialogContent>
        ) : (
          <DialogFileUploadContent
            acceptedFileTypes={acceptedFileTypes}
            onFileUpload={setFile}
          >
            <DialogContentText>
              Your organization logo will be visible to all members in your organization.
            </DialogContentText>
          </DialogFileUploadContent>
        )}

        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
          >
            Cancel
          </Button>

          {file ? (
            <SaveButton
              key="upload-file"
              isSaving={isSubmitting}
              onClick={handleClickSave}
            >
              Upload File
            </SaveButton>
          ) : (
            <SaveButton
              key="select-file"
              onClick={handleClickInput}
            >
              Select File
            </SaveButton>
          )}
        </DialogActions>
      </EnhancedDialog>

      {hiddenInput}
    </>
  );
};

export default OrganizationLogoUploaderDialog;
