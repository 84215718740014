// External Dependencies
import { AlertProps } from '@mui/material/Alert';
import { createActionCreator } from 'deox';

// Internal Dependencies
import { ContextData } from 'types/context';

// Actions
export const addNotification = createActionCreator(
  'ADD_NOTIFICATION',
  (resolve) => (
    label: string,
    severity: AlertProps['severity'] = 'error',
    autoHideDuration: number | null = 5000,
  ) => {
    const payload: ContextData.INotificationDetail = {
      autoHideDuration,
      id: Date.now(),
      label,
      severity,
    };
    return resolve(payload);
  },
);

export const removeNotification = createActionCreator(
  'REMOVE_NOTIFICATION',
  (resolve) => (id: number) => resolve(id),
);

export const clearAllNotifications = createActionCreator(
  'CLEAR_ALL_NOTIFICATIONS',
);
