// External Dependencies
import { Typography } from '@mui/material/';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const DoWeSellIt = () => (
  <>
    <InfoTitle>
      Do we sell your information?
    </InfoTitle>

    <InfoSection>
      <Typography>
        Absolutely not. Never have, never will.
      </Typography>
    </InfoSection>
  </>
);

export default DoWeSellIt;
