// External Dependencies
import { FC } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// Internal Dependencies
import {
  // CustomCheckbox,
  CustomInput,
  // EnhancedAlert,
  ShowCard,
} from 'components/shared';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
interface Props {
  // emailRemindersEnabled: GQL.IChecklist['emailRemindersEnabled'] | undefined;
  inputSize: TextFieldProps['size'];
}

// Component Definition
const ChecklistInfoFormUI: FC<Props> = ({
  // emailRemindersEnabled,
  inputSize,
}) => {
  const formElements = (
    <>
      <SchoolYearSelect
        fullWidth={false}
        name="schoolYearEnding"
        required
        size={inputSize}
        variant="filled"
      />
      <CustomInput
        label="Checklist Name *"
        name="label"
        size={inputSize}
      />
      {/* <Box marginLeft={1}>
        <CustomCheckbox
          label="Enable Email Reminders"
          name="emailRemindersEnabled"
        />
      </Box> */}
    </>
  );

  return (
    <ShowCard
      canEdit
      icon={DoneAllIcon}
      title="Checklist Info"
    >
      {formElements}

      {/* <Collapse in={emailRemindersEnabled}>
        <Box marginTop={1.5}>
          <EnhancedAlert>
            Email reminders will automatically be sent 3 days
            before the checklist&apos;s due date.
          </EnhancedAlert>
        </Box>
      </Collapse> */}
    </ShowCard>
  );
};

export default ChecklistInfoFormUI;
