import Chip, { ChipProps } from '@mui/material/Chip';

export const minColumnWidth = 190;

export const memberStatuses = {
  connected: 'Connected',
  welcomeEmailNotSent: 'Welcome Email Not Sent',
  welcomeEmailSent: 'Welcome Email Sent',
} as const;

export const memberStatusOptions = [
  {
    label: memberStatuses.connected,
    value: memberStatuses.connected,
  },
  {
    label: memberStatuses.welcomeEmailSent,
    value: memberStatuses.welcomeEmailSent,
  },
  {
    label: memberStatuses.welcomeEmailNotSent,
    value: memberStatuses.welcomeEmailNotSent,
  },
];

export const getMemberStatusValue = ({
  authUserEmail,
  receivedWelcomeEmailAt,
  rowId,
}: {
  authUserEmail: string | null;
  receivedWelcomeEmailAt: string | null;
  rowId: string | null;
}) => {
  if (!rowId) {
    return null;
  }

  if (authUserEmail) {
    return memberStatuses.connected;
  }

  if (receivedWelcomeEmailAt) {
    return memberStatuses.welcomeEmailSent;
  }

  return memberStatuses.welcomeEmailNotSent;
};

export const renderMemberStatusCell = (value: string) => {
  let color: ChipProps['color'] = 'success';

  if (value === memberStatuses.welcomeEmailNotSent) {
    color = 'error';
  } else if (value === memberStatuses.welcomeEmailSent) {
    color = 'warning';
  }

  if (!value) {
    return null;
  }

  return (
    <Chip
      color={color}
      label={value}
      size="small"
      variant="outlined"
    />
  );
};
