// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { ConfirmationDialog, Select } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useGetGroup, useGetGroupsAll } from 'gql/queries';
import { useUpdateGroup } from 'gql/mutations';

// Local Typings
interface Props {
  memberIds: string[];
  onClose: () => void;
}

// Component Definition
const AssignToGroupDialog: FC<Props> = ({
  memberIds,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [selectedGroupId, setSelectedGroupId] = useState('');

  const {
    data: groupsData,
  } = useGetGroupsAll();

  const {
    data: selectedGroupData,
    loading: isLoadingSelectedGroup,
  } = useGetGroup(selectedGroupId);

  useEffect(() => {
    const firstGroup = groupsData?.groups.data[0];

    if (firstGroup) {
      setSelectedGroupId(firstGroup.id);
    }
  }, [groupsData]);

  const handleChange = useCallback((event) => {
    setSelectedGroupId(event.target.value);
  }, []);

  const [
    updateGroup,
    {
      loading: isUpdatingGroup,
    },
  ] = useUpdateGroup({
    onCompleted: () => {
      dispatch(addNotification('Members added to group!', 'success'));

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));

      onClose();
    },
  });

  const handleConfirm = useCallback(() => {
    const selectedGroup = selectedGroupData?.group;

    if (selectedGroup) {
      updateGroup({
        variables: {
          id: selectedGroup.id,
          input: {
            label: selectedGroup.label,
            userIds: [
              ...selectedGroup.users.userList.map((member) => member.id),
              ...memberIds,
            ],
          },
        },
      });
    }
  }, [memberIds, selectedGroupData, updateGroup]);

  return (
    <ConfirmationDialog
      confirmButtonAction={handleConfirm}
      confirmButtonText="Assign"
      declineButtonAction={onClose}
      description={(
        <Select
          label="Group"
          name="Group"
          onChange={handleChange}
          options={groupsData?.groups.data.map((group) => ({
            id: group.id,
            label: group.label,
          })) ?? []}
          required
          value={selectedGroupId}
        />
      )}
      handleClose={onClose}
      isConfirmButtonDisabled={!selectedGroupData?.group}
      isSubmitting={isUpdatingGroup || isLoadingSelectedGroup}
      open={Boolean(memberIds.length)}
      title={`Assign ${memberIds.length} ${pluralize(memberIds.length, 'Member')} to Group`}
    />
  );
};

export default AssignToGroupDialog;
