const formatCCExp = (ccExp: string) => {
  const cleaned = (`${ccExp}`).replace(/\D/g, '');
  const match = cleaned.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);

  if (match) {
    return `${match[1]}/${match[2]}`;
  }

  const ccRegex = /^$|(0|1)$|^(0[1-9]|1[0-2])$|^((0[1-9]|1[0-2])\/)$|^((0[1-9]|1[0-2])\/?[0-9])$|^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

  const isValid = ccRegex.test(ccExp);

  if (isValid) {
    return ccExp;
  }

  return cleaned;
};

export default formatCCExp;
