// Internal Dependencies
import * as yupJS from 'yup';

export const mergeYupSchemas = (...schemas: any) => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (
      mergedSchemas: yupJS.ObjectSchema,
      schema: yupJS.ObjectSchema,
    ) => mergedSchemas.concat(schema),
    first,
  );

  return merged;
};
