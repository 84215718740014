// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useMutationEnhanced } from 'utils/lib/graphql';

const GET_DISTRICT_INVENTORY_REPORT = gql`
  mutation CreateDistrictInventoryReport($queryParams: DistrictInventoryReportQueryParams!) {
    createDistrictInventoryReport(queryParams: $queryParams)
  }
`;

const GET_INVENTORY_REPORT = gql`
  mutation CreateInventoryReport($queryParams: InventoryReportQueryParams!) {
    createInventoryReport(queryParams: $queryParams)
  }
`;

const GET_STUDENT_REPORT = gql`
  mutation CreateStudentReport($queryParams: StudentReportQueryParams!) {
    createStudentReport(queryParams: $queryParams)
  }
`;

export const useGetDistrictInventoryReport = (
  queryParams: GQL.IDistrictInventoryReportQueryParams,
) =>
  useMutationEnhanced<{
    districtInventoryReport: boolean;
  }, GQL.ICreateDistrictInventoryReportOnMutationArguments>(
    GET_DISTRICT_INVENTORY_REPORT,
    {
      variables: {
        queryParams,
      },
    },
  );

export const useGetInventoryReport = (queryParams: GQL.IInventoryReportQueryParams) =>
  useMutationEnhanced<{
    inventoryReport: boolean;
  }, GQL.ICreateInventoryReportOnMutationArguments>(
    GET_INVENTORY_REPORT,
    {
      variables: {
        queryParams,
      },
    },
  );

export const useGetStudentReport = (queryParams: GQL.IStudentReportQueryParams) =>
  useMutationEnhanced<{
    studentReport: boolean;
  }, GQL.ICreateStudentReportOnMutationArguments>(
    GET_STUDENT_REPORT,
    {
      variables: {
        queryParams,
      },
    },
  );
