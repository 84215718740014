// External Dependencies
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useMyGroups = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.group.myGroups();
  }, []);

  return useQuery({
    queryFn,
  });
};
