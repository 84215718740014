// External Dependencies
import {
  Box,
  CardContent,
  Collapse,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import startCase from 'lodash.startcase';
import styled from 'styled-components';

// Internal Dependencies
import { ALL_NUMERIC_GRADE_OPTIONS } from 'utils/constants/entityTypeOptions';
import {
  EnhancedCard,
  StandaloneSelect,
  StyledLink,
  Subtitle,
} from 'components/shared';
import { getOrdinalNumber } from 'utils';
import { useUpdateGraduatingGrade } from 'gql/mutations';

// Local Dependencies
import { StyledStrong } from '../shared/styles';

// Local Typings
interface Props {
  graduatingGrade: number | null;
  hideTitle?: boolean;
  label: string;
  maxWidth?: string;
}

// Local Variables
const EnhancedStyledLink = styled(StyledLink)({
  fontSize: 15,
});

// Component Definition
const EndOfYearGraduatingGrade: FC<Props> = ({
  graduatingGrade,
  hideTitle,
  label,
  maxWidth = '40%',
}) => {
  const [showGradeSelect, setShowGradeSelect] = useState(false);

  const [
    updateGraduatingGrade,
  ] = useUpdateGraduatingGrade({
    clearCachePredicates: ['returningStudents'],
    onCompleted: () => setShowGradeSelect(false),
  });

  const handlePressChangeGradeSelectLink = () => {
    setShowGradeSelect(true);
  };

  // eslint-disable-next-line no-nested-ternary
  const formattedGrade = graduatingGrade === 0
    ? 'Kindergarten'
    : graduatingGrade && graduatingGrade > -1
      ? `${getOrdinalNumber(graduatingGrade)} grade`
      : graduatingGrade;

  const handleChangeGraduatingGrade = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    const numericGrade = Number(value);

    // If the user selects the "empty" option but already has a grade,
    //  we show them the grade they already have
    if (graduatingGrade && value === 'empty') {
      setShowGradeSelect(false);
    } else {
      updateGraduatingGrade({
        variables: {
          graduatingGrade: numericGrade,
        },
      });
    }
  };

  return (
    <Box mb={2}>
      {!hideTitle && <Subtitle>Graduating Grade</Subtitle>}

      <EnhancedCard>
        <CardContent>
          <Collapse
            in={Boolean(formattedGrade && !showGradeSelect)}
          >
            {formattedGrade && (
              <Typography>
                {startCase(label)} graduating grade: {' '}
                <StyledStrong>
                  {formattedGrade}
                </StyledStrong>{' '}
                <EnhancedStyledLink onClick={handlePressChangeGradeSelectLink}>
                  Change
                </EnhancedStyledLink>
              </Typography>
            )}
          </Collapse>

          <Collapse in={graduatingGrade === null || showGradeSelect}>
            <Typography>Which grade is graduating?</Typography>

            <Box
              marginTop={1}
              maxWidth={maxWidth}
            >
              <StandaloneSelect
                id="select-graduating-grade"
                label="Graduating Grade"
                name="graduatingGrade"
                onChange={handleChangeGraduatingGrade}
                options={ALL_NUMERIC_GRADE_OPTIONS}
                value={graduatingGrade || ''}
              />
            </Box>
            <Box marginTop={1.5}>
              <Typography
                color="textSecondary"
                marginTop={1.5}
                variant="body2"
              >
                Select the highest grade at your campus.
              </Typography>
            </Box>
          </Collapse>

        </CardContent>
      </EnhancedCard>
    </Box>
  );
};

export default EndOfYearGraduatingGrade;
