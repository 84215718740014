// External Dependencies
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';

// Internal Dependencies
import {
  Container,
  CustomInput,
  EnhancedCard,
  Flex,
  FormActions,
  StateSelector,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { trimValues } from 'utils/lib/trimValues';

// Local Typings
interface Props {
  districtInfo?: GQL.IDistrict;
  onSubmit?: (values: GQL.IUpdateDistrictInput) => Promise<void>;
}

// Component Definition
const AdminEditDistrictInfoForm = ({
  districtInfo,
  onSubmit,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const initialValues = {
    addressOne: districtInfo?.addressOne ?? '',
    addressTwo: districtInfo?.addressTwo ?? '',
    city: districtInfo?.city ?? '',
    label: districtInfo?.label ?? '',
    phoneNumber: districtInfo?.phoneNumber ?? '',
    stateId: districtInfo?.state.id ?? '',
    zipcode: districtInfo?.zipcode ?? '',
  };

  const handleFormikSubmit = async (
    values: GQL.IUpdateDistrictInput,
    formikProps: FormikHelpers<GQL.IUpdateDistrictInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(trimValues(values));

    setSubmitting(false);
  };

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}`);
  }, [navigate]);

  return (
    <Container maxWidth={500}>
      <Subtitle>
        Edit District Info
      </Subtitle>

      <EnhancedCard>
        <Formik<GQL.IUpdateDistrictInput>
          initialValues={initialValues}
          onSubmit={handleFormikSubmit}
        >
          {({
            handleSubmit,
            isSubmitting,
            touched,
          }) => {
            const isFormTouched = Object.keys(touched).length > 0;

            return (
              <Form onSubmit={handleSubmit}>
                <CardContent>
                  <CustomInput
                    label="District Name"
                    name="label"
                  />
                  <CustomInput
                    label="Address 1"
                    name="addressOne"
                  />
                  <CustomInput
                    label="Address 2"
                    name="addressTwo"
                  />
                  <CustomInput
                    label="City"
                    name="city"
                  />

                  <Flex
                    alignItems="baseline"
                    flexWrap="nowrap"
                  >
                    <StateSelector sx={{ mr: 2 }} />

                    <CustomInput
                      label="Zip Code"
                      name="zipcode"
                      variant="filled"
                    />
                  </Flex>

                  <CustomInput
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                  />
                </CardContent>

                <FormActions
                  context="District Info"
                  isEditing
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={handlePressCancelOrBackButton}
                />
              </Form>
            );
          }}
        </Formik>
      </EnhancedCard>
    </Container>
  );
};

export default AdminEditDistrictInfoForm;
