// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { DeleteRelationshipButton, UserAvatar } from 'components/shared';
import { Relationship } from 'gql/queries';
import { getFullName } from 'utils';
import { hasPermission } from 'state/self/selectors';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  relationship: Relationship;
}

// Local Variables
const StyledTypography = styled(Typography)({
  // We are setting a `<span>` to block to avoid HTML warnings
  //  since a `<p>` cannot have a nested `<div>`
  display: 'block',
  lineHeight: 1.6,
}) as typeof Typography;

// Component Definition
const RelationshipListItem = ({
  relationship,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { self } = useSelfQuery();

  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const handleClickRelationship = useCallback((memberId: string) => () => {
    navigate(`/members/${memberId}`);
  }, [navigate]);

  const {
    recipient,
    recipientType,
    sender,
    senderType,
  } = relationship;

  const isSender = self?.id === sender.id;
  const otherUser = isSender ? recipient : sender;
  const otherUserRelationshipType = isSender ? recipientType : senderType;
  const otherUserEmail = isSender ? relationship.recipientEmail : sender.email;

  // Stop event propagation to prevent the parent list item from being clicked
  const handleClickDeleteRelationship = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <ListItemButton
      alignItems="flex-start"
      key={relationship.id}
      onClick={(!canReadUsers && relationship.isPending)
        ? undefined
        : handleClickRelationship(otherUser?.id ?? '')}
    >
      <ListItemAvatar>
        <UserAvatar iconName="face" />
      </ListItemAvatar>

      <ListItemText
        primary={otherUserRelationshipType.label}
        secondary={(
          <Box
            component="span"
            pt={0.5}
          >
            <StyledTypography
              color="textPrimary"
              component="span"
              variant="body2"
            >
              {otherUser ? getFullName(otherUser) : ''}
            </StyledTypography>
            {otherUserEmail}
          </Box>
        )}
      />

      <ListItemSecondaryAction onClick={handleClickDeleteRelationship}>
        <DeleteRelationshipButton relationshipId={relationship.id} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default RelationshipListItem;
