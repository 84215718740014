// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  History, Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialCredit } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import ShowFinancialCreditData from './ShowFinancialCreditData';

// Component Definition
const CreditsShowPage: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetFinancialCredit(id!);

  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const canEditCredits = useSelector(hasPermission('payments', 'edit'));

  const isCreditEditable = data?.financialCredit.isEditable;

  const editPath = canEditCredits && isCreditEditable?.status
    ? `/${PATHS.FINANCIAL_CREDITS}/${id}/edit` : undefined;

  return (
    <Page
      backButtonProps={{
        label: 'Credits',
        path: `/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={!data || loading}
    >
      <ShowFinancialCreditData
        credit={data?.financialCredit}
        editPath={editPath}
      />

      {data?.financialCredit && (
        <History
          id={data.financialCredit.id}
          resource="financialCredits"
        />
      )}
    </Page>
  );
};

export default CreditsShowPage;
