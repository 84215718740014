// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { convertCentsToDollarsAllowNull } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';

export const useColumns = () =>
  useMemo<GridColDef<GQL.IFinancialFundraiserCreditIndexItem>[]>(() => [
    {
      field: 'label',
      headerName: 'Name',
    },
    {
      field: 'financialAccountLabel',
      headerName: 'Account',
    },
    {
      field: 'amountInCents',
      headerName: 'Amount',
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(
        convertCentsToDollarsAllowNull(params.value),
      ),
    },
    {
      field: 'assignedCount',
      headerName: 'Members Assigned',
      type: 'number',
    },
  ], []);
