// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFinancialPaymentsTable from './MyFinancialPaymentsTable';

// Component Definition
const MyFinancialPayments = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        My Payments
      </Subtitle>

      <MyFinancialPaymentsTable />
    </>
  );
};

export default MyFinancialPayments;
