// External Dependencies
import { FC } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UserRoles } from '@presto-assistant/api_types';

// Internal Dependencies
import { TableDataGrid } from 'components/shared';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { mapEnum } from 'utils/lib/map_enum';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import EmailRecipientsTableZeroState
  from 'pages/Communication/EmailShow/EmailRecipientsTable/EmailRecipientsTableZeroState';

// Local Typings
interface Props {
  recipients: GQL.IEmailShowRecipient[];
}

// Local Variables
const tableColumns: GridColDef<GQL.IEmailShowRecipient>[] = [
  {
    field: 'firstName',
    headerName: 'First',
  },
  {
    field: 'lastName',
    headerName: 'Last',
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 180,
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) => {
      if (params.row.firstOpenedAt) {
        return 'Opened';
      }

      if (params.row.deliveredAt) {
        return 'Delivered';
      }

      return 'Pending';
    },
  },
  {
    field: 'memberRoleLabel',
    headerName: 'Role',
    minWidth: 80,
    type: 'singleSelect',
    valueOptions: mapEnum(UserRoles).map((option) => ({
      label: option.label,
      value: option.label,
    })),
  },
  dateTimeColumn({
    field: 'processedAt',
    headerName: 'Processed',
  }),
  dateTimeColumn({
    field: 'deliveredAt',
    headerName: 'Delivered',
  }),
  dateTimeColumn({
    field: 'firstOpenedAt',
    headerName: 'First Opened',
  }),
  dateTimeColumn({
    field: 'lastOpenedAt',
    headerName: 'Last Opened',
  }),
];

const noop = () => {};

// Component Definition
const EmailRecipientsTable: FC<Props> = ({ recipients }) => (
  <DataGridContainer>
    <TableDataGrid
      columns={tableColumns}
      components={{
        NoRowsOverlay: EmailRecipientsTableZeroState,
      }}
      onUpdateParams={noop}
      rows={recipients}
      withSearch
    />
  </DataGridContainer>
);

export default EmailRecipientsTable;
