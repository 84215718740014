// External Dependencies
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants/app';
import { AlertButtonProps } from 'components/AlertButtons/AlertButton';
import { ContextData } from 'types/context';
import ContinueEndOfYearProcess from 'components/AlertButtons/ContinueEndOfYearProcess';
import GoogleCalendarButton from 'components/AlertButtons/GoogleCalendarButton';
import StripeOrgSignUpButton from 'components/AlertButtons/StripeOrgSignUpButton';
import ViewIntegrationsSettingsButton from 'components/AlertButtons/ViewIntegrationsSettingsButton';

// Local Typings
interface Alerts {
  CONTINUE_END_OF_YEAR_PROCESS: ContextData.IAlertDetail;
  EMAIL_DELIVERY_INSIGHTS_ALERT: ((alertButtonProps: AlertButtonProps)
    => ContextData.IAlertDetail);
  NO_G_CAL_ALERT: ContextData.IAlertDetail;
  NO_PAYMENT_PROVIDER_ALERT: ContextData.IAlertDetail;
  NO_STRIPE_ALERT: ContextData.IAlertDetail;
}

// Local Variables
export const ALERT_TYPES = {
  ADD_ALERT: 'ADD_ALERT',
  CLEAR_ALERTS: 'CLEAR_ALERTS',
  REMOVE_ALERT: 'REMOVE_ALERT',
};

export const ALERT_IDS = {
  CONTINUE_END_OF_YEAR_PROCESS: 'CONTINUE_END_OF_YEAR_PROCESS',
  EMAIL_DELIVERY_INSIGHTS_ALERT: 'EMAIL_DELIVERY_INSIGHTS_ALERT',
  EMAIL_SIGNATURE_ALERT: 'EMAIL_SIGNATURE_ALERT',
  NO_G_CAL_ALERT: 'NO_G_CAL_ALERT',
  NO_PAYMENT_PROVIDER_ALERT: 'NO_PAYMENT_PROVIDER_ALERT',
  NO_STRIPE_ALERT: 'NO_STRIPE_ALERT',
};

export const ALERTS: Alerts = {
  CONTINUE_END_OF_YEAR_PROCESS: {
    CTAButton: ContinueEndOfYearProcess,
    id: ALERT_IDS.CONTINUE_END_OF_YEAR_PROCESS,
    label:
      'You have started the "End of Year" process. Pick up where you left off.',
    level: 'warning',
  },
  EMAIL_DELIVERY_INSIGHTS_ALERT: (alertButtonProps) => ({
    alertButtonProps,
    id: ALERT_IDS.EMAIL_DELIVERY_INSIGHTS_ALERT,
    label:
      (
        <Box
          alignItems="center"
          component="span"
          display="flex"
        >
          <Chip
            color="primary"
            component="span"
            label="NEW"
            size="small"
            sx={{
              marginRight: 2,
            }}
            variant="filled"
          />

          <Typography
            component="span"
            fontSize="inherit"
          >
            Email delivery insights are here! Delivery status and open rates are
            {' '}
            available for all emails sent through {APP_NAME} on or after 1/26/2023.
          </Typography>
        </Box>
      ),
    level: 'warning',
  }),
  NO_G_CAL_ALERT: {
    CTAButton: GoogleCalendarButton,
    id: ALERT_IDS.NO_G_CAL_ALERT,
    label:
      'You have not set up Google calendar. Sync a calendar to share events with members in your organization.',
    level: 'warning',
  },
  NO_PAYMENT_PROVIDER_ALERT: {
    CTAButton: ViewIntegrationsSettingsButton,
    id: ALERT_IDS.NO_PAYMENT_PROVIDER_ALERT,
    label:
      'Your organization is currently not set up to receive payments online. We use Stripe and Vanco for all online transactions to safeguard your data.',
    level: 'warning',
  },
  NO_STRIPE_ALERT: {
    CTAButton: StripeOrgSignUpButton,
    id: ALERT_IDS.NO_STRIPE_ALERT,
    label:
      'Your organization is currently not set up to receive payments online. We use Stripe to process all online payments and keep your data safe and secure.',
    level: 'warning',
  },
};
