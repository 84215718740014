// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';
import { getFullName } from 'utils';

// Local Variables
export const useColumns = (extraColumns: GridColDef[]) =>
  useMemo(() => {
    const columns: GridColDef<GQL.ISimpleUser>[] = [
      {
        field: 'role',
        headerName: 'Role',
        valueGetter: (params) => params.row.role.label,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
      },
      {
        field: 'email',
        headerName: 'Email',
      },
      {
        field: 'familyMembers',
        headerName: 'Family Members',
        valueGetter: (params) => {
          const members = params.row.relationships.map((rel) => {
            const { recipient, sender } = rel;

            if (recipient?.id === params.row?.id) {
              return sender;
            }

            return recipient;
          });

          const memberNames = members
            .filter(Boolean)
            .map((member) => getFullName(member!))
            .join('; ');

          return displayCell(memberNames);
        },
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [extraColumns]);
