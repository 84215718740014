// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { useHasRelationships } from 'hooks/useHasRelationships';

// Local Dependencies
import FileDirectoriesTable from './FileDirectoriesTable';
import LinkToMyFiles from './LinkToMyFiles';

// Component Definition
const FilesAll: FC = () => {
  // We only need to show the link to MyFiles if the director has relationships
  const hasRelationships = useHasRelationships();

  return (
    <>
      <Subtitle>
        Files
      </Subtitle>

      <EnhancedCard>
        <FileDirectoriesTable />
      </EnhancedCard>

      {hasRelationships && <LinkToMyFiles />}
    </>
  );
};

export default FilesAll;
