// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import styled from 'styled-components';

// Internal Dependencies
import { ContextData } from 'types/context';
import {
  bannerAlertItems as bannerAlertItemsSelector,
} from 'state/bannerAlerts/selectors';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/lib/local_storage';
import { removeBannerAlert } from 'state/bannerAlerts/actions';

// Local Dependencies
import EnhancedBanner from './EnhancedBanner';

// Local Typings
interface Props {
  alert: ContextData.IAlertDetail;
}

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '0.9em',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1em',
  },
}));

// Component Definition
const AlertBanner = ({ alert }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const bannerAlertItems = useSelector(bannerAlertItemsSelector);

  const {
    CTAButton, alertButtonProps, id, label,
  } = alert;

  // TODO: refactor later when more alerts pass through here
  const isAlertOpen = bannerAlertItems?.some((
    bannerAlert: ContextData.IAlertDetail,
  ) => id === bannerAlert.id);

  const handleClose = () => {
    dispatch(removeBannerAlert(id));

    const currentLocalStorageAlertValue = getLocalStorageItem('viewedAlerts');

    // Convert to an array of all viewed alert elements
    const splitCurrentLocalStorageAlertValue = currentLocalStorageAlertValue?.split(',');

    // Add the current item into the array
    if (!splitCurrentLocalStorageAlertValue?.includes(id)) {
      // eslint-disable-next-line no-unused-expressions
      splitCurrentLocalStorageAlertValue?.push(id);
    }

    // Convert back to a string for local storage
    const newLocalStorageAlertValue = splitCurrentLocalStorageAlertValue?.join() ?? id;

    setLocalStorageItem('viewedAlerts', newLocalStorageAlertValue);
  };

  return (
    <EnhancedBanner
      action={CTAButton ? (
        <CTAButton
          key="action"
          {...alertButtonProps}
        />
      ) : null}
      isAlertOpen={isAlertOpen}
      onClose={handleClose}
    >
      <StyledBox component="span">
        {label}
      </StyledBox>
    </EnhancedBanner>
  );
};

export default AlertBanner;
