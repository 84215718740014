// External Dependencies
import { FC, useCallback, useState } from 'react';
import { createUserSchema } from '@presto-assistant/api_types/schemas/user';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateUser } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMemberLookup } from 'gql/queries';
import DialogConfirmExistingMember from 'pages/People/shared/DialogConfirmExistingMember';

// Local Dependencies
import AdultForm from '../../shared/AdultForm';

// Component Definition
const ParentsNew: FC = () => {
  const navigate = useNavigate();

  const [payload, setPayload] = useState<GQL.ICreateUserInput | null>(null);

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.PARENTS}/${id}`);
  }, [navigate]);

  const parentsParams = useSelector(tableQueryParams('parents'));

  const [createParent, { error }] = useCreateUser(
    {
      clearCachePredicates: ['parentsIndex', 'dashboardMetrics'],
      onCompleted: (data) => navigateToShow(data.createUser.id),
    },
  );

  const checkForExistingMemberInThisOrganization = useMemberLookup();

  const handleCreateParent = () => {
    if (payload) {
      createParent({
        variables: {
          input: payload,
        },
      });
    }
  };

  const handleSubmit = async (values: GQL.ICreateUserInput) => {
    const localPayload = {
      ...trimValues(values) as GQL.ICreateUserInput,
      primarySpokenLanguageId: values.primarySpokenLanguageId
        ? Number(values.primarySpokenLanguageId)
        : 1,
    };

    setPayload(localPayload);

    const { data: existingMemberData } = await checkForExistingMemberInThisOrganization({
      memberEmail: values.email,
    });

    const isExistingMember = Boolean(existingMemberData.memberLookup.length);

    if (isExistingMember) {
      handleToggleIsConfirmationDialogOpen();
    } else {
      await createParent({
        variables: {
          input: localPayload,
        },
      });
    }
  };

  return (
    <Page
      backButtonProps={{
        label: 'Parents',
        path: `/${PATHS.PARENTS}${parentsParams}`,
      }}
      error={error}
    >
      <AdultForm
        onSubmit={handleSubmit}
        validationSchema={createUserSchema}
      />

      <DialogConfirmExistingMember
        isEditing={false}
        isOpen={isConfirmationDialogOpen}
        onSubmit={handleCreateParent}
        onToggle={handleToggleIsConfirmationDialogOpen}
      />
    </Page>
  );
};

export default ParentsNew;
