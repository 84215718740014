// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const FinancialItemsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="Use the button at the top to manually add a new financial item."
  />
);

export default FinancialItemsTableZeroState;
