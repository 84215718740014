// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasFeatureFlag } from 'state/self/selectors';
import { useGetFinancialPaymentProviderAccounts } from 'utils/api/financialPaymentProviderAccount';
import { useGetOrganization } from 'gql/queries';

// Hook Definition
export const useHasEnabledOnlinePaymentsForOrg = () => {
  const {
    data: orgData,
  } = useGetOrganization();

  const {
    data: financialPaymentProviderAccounts,
  } = useGetFinancialPaymentProviderAccounts();

  const hasMultiplePaymentProviderAccountsFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const areOnlinePaymentsEnabledForOrg = Boolean(orgData?.organization.areOnlinePaymentsEnabled);
  const hasPaymentProviderAccounts = Boolean(financialPaymentProviderAccounts?.data.data.length);

  return hasMultiplePaymentProviderAccountsFeatureFlag
    ? hasPaymentProviderAccounts
    : areOnlinePaymentsEnabledForOrg;
};
