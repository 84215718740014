// External Dependencies
import {
  Box, Button, CardContent,
} from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import {
  Container, EnhancedCard, Flex, Subtitle,
} from 'components/shared';
import { SimpleUniform } from 'gql/queries';
import { UniformTypeAhead } from 'components/shared/TypeAheads';

// Local Typings
interface Props {
  onSelectUniform: (uniform: SimpleUniform) => any;
}

// Component Definition
const SelectUniform: FC<Props> = ({
  onSelectUniform,
}) => {
  const [uniform, setUniform] = useState<SimpleUniform | null>(null);

  const handleClickButton = () => {
    if (uniform) {
      onSelectUniform(uniform);
    }
  };

  return (
    <Container maxWidth={500}>
      <Subtitle>
        Select Uniform
      </Subtitle>

      <EnhancedCard>
        <CardContent>
          <UniformTypeAhead onSelectUniform={setUniform} />
        </CardContent>

        <Box
          mb={1}
          mr={1}
        >
          <Flex justifyContent="flex-end">
            <Button
              color="primary"
              disabled={!uniform}
              onClick={handleClickButton}
              variant="contained"
            >
              Next
            </Button>
          </Flex>
        </Box>
      </EnhancedCard>
    </Container>
  );
};

export default SelectUniform;
