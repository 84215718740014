export const getAllowedPermissionIds = (
  permissions: GQL.IPermissions,
): number[] => {
  const allowedPermissionsIds: number[] = [];

  Object.values(permissions).forEach((permissionSet: GQL.IPermissionActions) => {
    const permissionDetails: GQL.IPermissionDetails[] = Object.values(permissionSet);

    const newAllowedPermissionIds: number[] = permissionDetails
      .filter(({ allowed }) => allowed)
      .map(({ permissionId }) => permissionId);

    allowedPermissionsIds.push(...newAllowedPermissionIds);
  });

  return allowedPermissionsIds;
};
