// External Dependencies
import { CheckCircle } from '@mui/icons-material';
import { FC } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { Flex } from 'components/shared';
import { SHORT_APP_NAME } from 'utils/constants';

// Local Typings
interface Props {
  isMigration: boolean;
  onClickDone: () => void;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    width: '100%',
  },

  borderRadius: 100, // a high number that will ensure round corners regardless of button height
  fontSize: 18,
  maxWidth: 300,
  padding: theme.spacing(1.5, 0),
  width: '60%',
}));

// Component Definition
const SuccessStep: FC<Props> = ({
  isMigration,
  onClickDone,
}) => (
  <Flex
    alignContent="center"
    flexDirection="column"
    gap={2}
    width="100%"
  >
    <Typography
      align="center"
      variant="h4"
    >
      Import Successful!
    </Typography>

    <CheckCircle
      color="success"
      sx={{ fontSize: 100 }}
    />

    {isMigration && (
      <Typography align="center">
        A {SHORT_APP_NAME} team member will review
        your data and contact you if there are any issues.
      </Typography>
    )}

    <StyledButton
      onClick={onClickDone}
      variant="contained"
    >
      Done
    </StyledButton>
  </Flex>
);

export default SuccessStep;
