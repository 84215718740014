/* eslint-disable graphql/template-strings */
// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import { useEffect } from 'react';
import gql from 'graphql-tag';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface UniformCheckout {
  active: boolean;
  checkinDate: string | null;
  checkoutDate: string;
  dueDate: string;
  id: string;
  user: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    middleName: string | null;
  };
}
interface UniformIndexResponse {
  uniformsIndex: GQL.IUniformIndexItemAll;
}
interface DistrictUniformsIndexResponse {
  districtUniformItemsIndex: GQL.IDistrictUniformIndexItemAll;
}
interface DistrictUniformsIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
  where?: GQL.IDistrictUniformIndexWhereConditions;
}

// Local Variables
const getDistrictAdditionalVariables: UseGetTableQueryArgs['getAdditionalVariables'] = (search) => {
  const filters: GQL.IDistrictUniformIndexWhereConditions = {
    categoryId: search.categoryId ?? null,
    isCheckedOut: search.isCheckedOut ?? null,
    organizationId: search.organizationId ?? null,
    organizationTypeId: search.organizationTypeId ?? null,
  };

  const where = {
    categoryId: filters.categoryId ?? null,
    isCheckedOut: filters.isCheckedOut ?? null,
    organizationId: filters.organizationId ?? undefined,
    organizationTypeId: filters.organizationTypeId
      ? Number(filters.organizationTypeId)
      : undefined,
  };

  return { where };
};

export interface UniformShowResponse extends DynamicFields {
  activeCheckout: UniformCheckout;
  category: GQL.IUniformCategory;
  color: GQL.IUniform['color'];
  comments: GQL.IUniform['comments'];
  condition: GQL.IUniform['condition'];
  customBarcode: GQL.IUniform['customBarcode'];
  id: string;
  organization?: GQL.IOrganization;
  purchaseValueInCents: GQL.IUniform['purchaseValueInCents'];
  purchasedAt: GQL.IUniform['purchasedAt'];
  size: string;
  systemBarcode: GQL.IUniform['systemBarcode'];
  uniformNumber: string;
  uniformStyle: {
    id: string;
    label: string;
  } | null;
  uniformType: {
    id: number;
    label: string;
  };
}

export interface SimpleUniform {
  activeCheckout: { id: string } | null;
  category: {
    id: string;
    label: string;
  } | null;
  id: string;
  size: string;
  uniformNumber: string;
  uniformType: {
    id: string;
    label: string;
  };
}

export const GET_UNIFORMS = gql`
  query Uniforms($queryParams: QueryParams) {
    uniforms(queryParams: $queryParams) {
      data {
        color
        comments
        condition {
          id
          label
        }
        id
        purchaseValueInCents
        purchasedAt
        size
        uniformNumber
        uniformStyle {
          id
          label
        }
        uniformType {
          id
          label
        }
      }
      fullCount
    }
  }
`;

export const GET_UNIFORM = gql`
  query Uniform($id: ID!) {
    uniform(id: $id) {
      activeCheckout {
        active
        checkinDate
        checkoutDate
        dueDate
        id
        user {
          email
          firstName
          id
          lastName
        }
      }
      category {
        id
        label
      }
      color
      comments
      condition {
        id
        label
      }
      customBarcode
      id
      organization {
        id
        label
        organizationType {
          id
          label
        }
      }
      purchaseValueInCents
      purchasedAt
      size
      systemBarcode
      uniformNumber
      uniformStyle {
        id
        label
      }
      uniformType {
        id
        label
      }

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

export const FRAGMENT_SIMPLE_UNIFORM = gql`
  fragment SimpleUniformFragment on Uniform {
    activeCheckout {
      id
    }
    category {
      id
      label
    }
    condition {
      id
      label
    }
    id
    size
    uniformNumber
    uniformStyle {
      id
      label
    }
    uniformType {
      id
      label
    }
  }
`;

const GET_SIMPLE_UNIFORMS_TYPEAHEAD = gql`
  query SimpleUniformsTypeAhead($searchString: String!) {
    uniforms(queryParams: { page: 1, limit: 8, q: $searchString }) {
      data {
        ...SimpleUniformFragment
      }
    }
  }
  ${FRAGMENT_SIMPLE_UNIFORM}
`;

const GET_SIMPLE_UNIFORM = gql`
  query SimpleUniform($uniformId: ID!) {
    uniform(id: $uniformId) {
      ...SimpleUniformFragment
    }
  }
  ${FRAGMENT_SIMPLE_UNIFORM}
`;

export const GET_UNIFORMS_INDEX = gql`
  query UniformsIndex(
    $queryParams: IndexQueryParams
    $where: UniformIndexWhereConditions
  ) {
    uniformsIndex(queryParams: $queryParams, where: $where) {
      data {
        category {
          id
          label
        }
        checkedOutTo
        color
        comments
        condition
        customBarcode
        id
        number
        purchaseValueInCents
        purchasedAt
        size
        style
        type

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
      fullCount
    }
  }
`;

export const GET_UNIFORMS_INDEX_REPORT = gql`
  query UniformsIndexReport($queryParams: IndexQueryParams) {
    uniformsIndex(queryParams: $queryParams) {
      fullCount
    }
  }
`;

export const GET_DISTRICT_UNIFORM_ITEMS_INDEX = gql`
  query DistrictUniformItemsIndex(
    $queryParams: IndexQueryParams
    $where: DistrictUniformIndexWhereConditions
  ) {
    districtUniformItemsIndex(queryParams: $queryParams, where: $where) {
      data {
        category {
          id
          label
        }
        color
        comments
        condition
        customBarcode
        id
        number
        organization {
          id
          label
        }
        purchaseValueInCents
        purchasedAt
        size
        style
        type

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
      fullCount
    }
  }
`;

export const GET_DISTRICT_UNIFORMS_INDEX_REPORT = gql`
  query DistrictUniformsIndexReport(
    $queryParams: IndexQueryParams
    $where: DistrictUniformIndexWhereConditions
  ) {
    districtUniformItemsIndex(queryParams: $queryParams, where: $where) {
      fullCount
    }
  }
`;

export const useGetUniform = (id: string | null) =>
  useQueryEnhanced<{
    uniform: UniformShowResponse;
  }>(GET_UNIFORM, { skip: !id, variables: { id } });

export const useGetUniformsTypeAhead = (searchString: string) =>
  useQueryEnhanced<{
    uniforms: { data: SimpleUniform[] };
  }>(GET_SIMPLE_UNIFORMS_TYPEAHEAD, {
    fetchPolicy: 'network-only',
    variables: { searchString },
  });

export const useGetSimpleUniform = (uniformId?: string) =>
  useQueryEnhanced<{
    uniform: SimpleUniform;
  }>(GET_SIMPLE_UNIFORM, {
    skip: !uniformId,
    variables: { uniformId },
  });

export const useGetUniformIndex = () =>
  usePaginatedListQuery<UniformIndexResponse, GQL.IUniformIndexItem>({
    dataSelector: (res) => res.uniformsIndex.data,
    fullCountSelector: (res) => res.uniformsIndex.fullCount,
    query: GET_UNIFORMS_INDEX,
  });

export const useGetDistrictUniformIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
  () => void,
  LazyQueryResult<DistrictUniformsIndexResponse, DistrictUniformsIndexVariables>
] => {
  const { apiRequest, values } = useGetTableQuery<
    DistrictUniformsIndexResponse,
    DistrictUniformsIndexVariables
  >({
    getAdditionalVariables: getDistrictAdditionalVariables,
    gqlQuery,
    options,
    tableResource: 'districtUniforms',
  });

  return [apiRequest, values];
};

export const useGetDistrictUniformIndexItems = () => {
  const [query, values] = useGetDistrictUniformIndexItemsQuery(
    GET_DISTRICT_UNIFORM_ITEMS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useDownloadDistrictUniformsIndexItems = () =>
  useGetDistrictUniformIndexItemsQuery(GET_DISTRICT_UNIFORMS_INDEX_REPORT, {
    exportReport: true,
  });
