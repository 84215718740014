// External Dependencies
import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';

// Local Dependencies
import UploadDropzone from '../UploadDropzone';

// Local Typings
export interface DialogFileUploadContentProps {
  acceptedFileTypes?: string[];
  children: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  dividers?: DialogContentProps['dividers'];
  hideDropzone?: boolean;
  onFileUpload: (file: any) => void;
  rejectedDropErrorMessage?: string;
  uploadDropzoneKey?: string;
}

// Component Definition
const DialogFileUploadContent = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  children,
  description,
  disabled,
  dividers,
  hideDropzone,
  onFileUpload,
  rejectedDropErrorMessage = 'File type not supported. Please upload a CSV file.',
  uploadDropzoneKey,
  ...otherProps
}: DialogFileUploadContentProps): JSX.Element => {
  const handleDrop = (files: File[]) => onFileUpload(files[0]);

  return (
    <DialogContent
      dividers={dividers}
      {...otherProps}
    >
      {description ? <Box marginBottom={2}>{description}</Box> : null}

      {children}

      <Collapse in={!hideDropzone}>
        <UploadDropzone
          acceptedFileTypes={acceptedFileTypes}
          disabled={disabled}
          handleDrop={handleDrop}
          key={uploadDropzoneKey}
          marginTop={1}
          rejectedDropErrorMessage={rejectedDropErrorMessage}
        />
      </Collapse>
    </DialogContent>
  );
};

export default DialogFileUploadContent;
