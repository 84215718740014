// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Formik, FormikHelpers } from 'formik';
import { createFinancialDepositSchema } from '@presto-assistant/api_types/schemas/financialDeposits';
import { useSelector } from 'react-redux';

// Internal Dependencies
// import { clearPaymentIds } from 'state/ui/paymentPickerDepositForm/actions';
import { getParamFormattedDate } from 'utils';
import { getPaymentIds } from 'state/ui/paymentPickerDepositForm/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialAllPayments } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import DepositFormFormikForm, { CustomSetter } from './DepositFormFormikForm';
import DialogPaymentPicker from './DialogPaymentPicker';

// Local Typings
interface Props {
  onSubmit?: (values: GQL.ICreateFinancialDepositInput) => void;
}

// Local Variables
const initialValues: GQL.ICreateFinancialDepositInput = {
  depositDate: getParamFormattedDate(new Date()),
  depositNumber: '',
  paymentIds: [],
};

// Component Definition
const DepositForm: FC<Props> = ({
  onSubmit,
}) => {
  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  // These ids are used to update the Formik form's `paymentIds` field
  const [formPaymentIds, setFormPaymentIds] = useState<CustomSetter[]>([]);

  const paymentIds = useSelector(getPaymentIds);

  const paymentPickerDepositFormParams = useSelector(tableQueryParams('paymentPickerDepositForm'));

  const parsedParams = useParsedSearch(paymentPickerDepositFormParams);

  const {
    data,
    loading: isLoadingAllFinancialPayments,
  } = useGetFinancialAllPayments(parsedParams);

  // Used to update the Formik form's `paymentIds` field
  useEffect(() => {
    setFormPaymentIds([
      {
        fieldName: 'paymentIds',
        fieldValue: paymentIds,
      },
    ]);
  }, [paymentIds]);

  const handleFormikSubmit = useCallback(async (
    values: GQL.ICreateFinancialDepositInput,
    formikProps: FormikHelpers<GQL.ICreateFinancialDepositInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  }, [onSubmit]);

  return (
    <>
      <Formik<GQL.ICreateFinancialDepositInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={createFinancialDepositSchema}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
        }) => (
          <DepositFormFormikForm
            errors={errors}
            formPaymentIds={formPaymentIds}
            isSubmitting={isSubmitting}
            onOpenPaymentPickerDialog={handleOpen}
            onSubmit={handleSubmit}
            paymentIds={paymentIds}
            setFieldValue={setFieldValue}
            touched={touched}
          />
        )}
      </Formik>

      <DialogPaymentPicker
        isOpen={isOpen}
        isLoading={isLoadingAllFinancialPayments}
        onClose={handleClose}
        paymentsData={data?.financialPaymentsIndex?.data}
        title="Select Undeposited Payments"
      />
    </>
  );
};

export default DepositForm;
