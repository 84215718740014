// External Dependencies
import { FC } from 'react';

// Local Dependencies
import FinancialFundraiserCreditsTable from './FinancialFundraiserCreditsTable';

// Component Definition
const FinancialFundraiserCreditsAll: FC = () => {
  return (
    <div>
      <FinancialFundraiserCreditsTable />
    </div>
  );
};

export default FinancialFundraiserCreditsAll;
