import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
interface SimpleOrganizationRole {
  id: string;
  label: string;
}
interface OrganizationRolesResponse {
  organizationRoles: SimpleOrganizationRole[];
}

export const GET_ORGANIZATION_ROLES = gql`
  query OrganizationRoles {
    organizationRoles {
      id
      label
    }
  }
`;

export const useGetOrganizationRoles = () =>
  useQueryEnhanced<OrganizationRolesResponse>(GET_ORGANIZATION_ROLES);
