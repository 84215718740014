// External Dependencies
import { useCallback } from 'react';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DataGridFilterCard from 'components/shared/DataGridFilterCard';
import SchoolYearDataGridFilter from 'components/shared/SchoolYearDataGridFilter';

// Local Dependencies
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import FinancialPaymentsTable from './FinancialPaymentsTable';
import LinkToMyPayments from './LinkToMyPayments';
import OnlinePaymentsAlert from '../../shared/OnlinePaymentsAlert';

// Component Definition
const FinancialPayments = (): JSX.Element => {
  const hasRelationships = useHasRelationships();
  const orgCurrentSchoolYearEnding: number = useGetOrganizationSchoolYear();

  const search = useParsedSearch();
  const { schoolYearEnding = orgCurrentSchoolYearEnding } = search;

  const getPathOnFilterChange = useCallback((sye: number) => {
    const queryString = stringifyQueryParams({
      ...search,
      school_year_ending: sye,
    });

    return `/${PATHS.FINANCIAL_PAYMENTS}?${queryString}`;
  }, [search]);

  return (
    <>
      <OnlinePaymentsAlert />

      <Subtitle>
        Financial Payments
      </Subtitle>

      <DataGridFilterCard>
        <SchoolYearDataGridFilter
          getPathOnFilterChange={getPathOnFilterChange}
          schoolYearEndingFromUrl={Number(schoolYearEnding)}
        />
      </DataGridFilterCard>

      <Box
        marginBottom={4}
        marginTop={2}
      >
        <EnhancedCard>
          <FinancialPaymentsTable schoolYearEnding={Number(schoolYearEnding)} />
        </EnhancedCard>
      </Box>

      {hasRelationships && <LinkToMyPayments />}
    </>
  );
};

export default FinancialPayments;
