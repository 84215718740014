// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import {
  useCallback, useMemo,
} from 'react';
import { useNavigate } from 'react-router';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { useUpdateUser } from 'gql/mutations';

// Local Variables
const USER_ROLES = {
  PARENT: 'parent',
  STUDENT: 'student',
};

interface Props {
  isOpen: boolean;
  memberId?: string;
  memberRoleId?: UserRoles;
  onClose: () => void;
  returnToOnSuccess: string;
}

const SwitchParentAndStudentRolesDialog = ({
  isOpen,
  memberId,
  memberRoleId,
  onClose,
  returnToOnSuccess,

}: Props): JSX.Element => {
  const navigate = useNavigate();

  const isStudent = useMemo(() => {
    return memberRoleId === UserRoles.Student;
  }, [memberRoleId]);

  const handleCompleted = useCallback(() => {
    navigate(returnToOnSuccess);
  }, [navigate, returnToOnSuccess]);

  const [updateUser, { loading }] = useUpdateUser(
    {
      clearCachePredicates: [
        'dashboardMetrics',
        'parentsIndex',
        'studentsIndex',
        'user',
      ],
      onCompleted: handleCompleted,
    },
  );

  const handleConfirmSwitchRole = () => {
    if (memberId) {
      updateUser({
        variables: {
          id: memberId,
          input: {
            roleId: isStudent ? UserRoles.Adult : UserRoles.Student,
          },
        },
      });
    }
  };

  const { oppositeRoleText, userRoleText } = useMemo(() => {
    if (memberRoleId === UserRoles.Student) {
      return { oppositeRoleText: USER_ROLES.PARENT, userRoleText: USER_ROLES.STUDENT };
    }

    return { oppositeRoleText: USER_ROLES.STUDENT, userRoleText: USER_ROLES.PARENT };
  }, [memberRoleId]);

  const switchRoleModalPermissionsText = useMemo(() => {
    if (isStudent) {
      return "This action will remove the student's grade value.";
    }

    return 'This will remove any permissions granted to the parent.';
  }, [isStudent]);

  return (
    <ConfirmationDialog
      canBeUndone
      confirmButtonAction={handleConfirmSwitchRole}
      confirmButtonText="Yes, Switch"
      declineButtonAction={onClose}
      description={(
        <DialogContentText component="div">
          <Typography paragraph>
            Are you sure you want to switch this member&apos;s role from
            {' '}<strong>{userRoleText.toUpperCase()}</strong>{' '}to
            {' '}<strong>{oppositeRoleText.toUpperCase()}</strong>?
          </Typography>

          <Typography paragraph>
            This should only be used in case this person accidentally signed
            up with the wrong type of user profile.
          </Typography>

          <Typography>
            {switchRoleModalPermissionsText}
          </Typography>
        </DialogContentText>
      )}
      handleClose={onClose}
      isSubmitting={loading}
      open={isOpen}
      title={`Switch to a ${oppositeRoleText}`}
      useCustomText
    />
  );
};

export default SwitchParentAndStudentRolesDialog;
