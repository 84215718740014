// External Dependencies
import { CheckIfMemberExistsResponse } from '@presto-assistant/api_types/api/v1/member';
import { Form, Formik, FormikErrors } from 'formik';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomInput,
  EnhancedAlert,
  Flex,
  SaveButton,
} from 'components/shared';
import { getErrorMessage } from 'utils/api';
import { trimValues } from 'utils/lib/trimValues';
import { useCheckIfMemberExists } from 'utils/api/members';
import { useGetRelationships } from 'gql/queries';

// Local Typings
export interface OnCompletedEmailStepArgs {
  doesMemberExist: boolean;
  email: string;
  userRoleId: number | null;
}
interface Props {
  doesMemberExist: OnCompletedEmailStepArgs['doesMemberExist'];
  initialValues: FormValues;
  onCompleted: (args: OnCompletedEmailStepArgs) => void;
  readOnly?: boolean;
  selfEmail: string | undefined;
}
interface FormValues {
  email: string;
}

// Component Definition
const Email = ({
  doesMemberExist,
  initialValues,
  onCompleted,
  readOnly,
  selfEmail,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<FormikErrors<FormValues> | undefined>(undefined);
  const [email, setEmail] = useState('');

  // We use the relationship data to check if the entered email is already a relationship.
  // It's easier to calcualte this inside of Formik further down to properly disable
  //  the button before the user makes a network request.
  const { data: relationshipData } = useGetRelationships();

  const handleCompleted = useCallback((
    data: CheckIfMemberExistsResponse,
    emailFromValues: string,
  ) => {
    const args: OnCompletedEmailStepArgs = {
      doesMemberExist: data.data.memberExists,
      email: emailFromValues,
      userRoleId: data.data.userRoleId,
    };

    onCompleted(args);
  }, [onCompleted]);

  const {
    isLoading,
    mutate: checkIfMemberExists,
  } = useCheckIfMemberExists();

  const handleFormikSubmit = useCallback(async (values: FormValues) => {
    const emailFromValues = values.email.trim().toLowerCase();
    setEmail(emailFromValues);

    checkIfMemberExists(
      trimValues(values) as FormValues,
      {
        onError: (error) => {
          setErrors(getErrorMessage(error));
        },
        onSuccess: (response) => {
          handleCompleted(response.data, emailFromValues);
        },
      },
    );
  }, [checkIfMemberExists, handleCompleted]);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialErrors={errors}
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => {
        const enteredSelfEmail = Boolean(values.email && selfEmail === values.email);

        // If the entered email is already a relationship, we display an alert
        // to the user to let them know that the relationship already exists.
        const relationshipAlreadyExists = relationshipData?.userRelationships
          .some((relationship) => (
            relationship.sender.email === values.email
          || relationship.recipient?.email === values.email
          ));

        return (
          <Form
            onSubmit={handleSubmit}
          >
            <Box m={1.5}>
              <Typography>
                Enter the email address for the new family member.
              </Typography>
            </Box>

            <CustomInput
              InputProps={{ readOnly }}
              autoFocus
              label="Email"
              name="email"
              required
            />

            <Collapse in={!readOnly}>
              <Flex
                justifyContent="flex-end"
                marginTop={1}
              >
                <SaveButton
                  color="primary"
                  disabled={!values.email || enteredSelfEmail || relationshipAlreadyExists}
                  isSaving={isLoading}
                  variant="contained"
                >
                  Next
                </SaveButton>
              </Flex>
            </Collapse>

            <Collapse in={enteredSelfEmail}>
              <Box
                margin={1.5}
                marginTop={2.5}
              >
                <EnhancedAlert
                  severity="error"
                  v3Style
                >
                  You cannot add yourself as a family member.
                </EnhancedAlert>
              </Box>
            </Collapse>

            <Collapse in={relationshipAlreadyExists && !enteredSelfEmail}>
              <Box
                margin={1.5}
                marginTop={2.5}
              >
                <EnhancedAlert
                  severity="error"
                  v3Style
                >
                  You have already added this person as a family member.
                </EnhancedAlert>
              </Box>
            </Collapse>

            <Collapse
              in={!enteredSelfEmail
                && !relationshipAlreadyExists
                && email === values.email
                && doesMemberExist}
            >
              <Box
                margin={1.5}
                marginTop={2.5}
              >
                <EnhancedAlert
                  severity="success"
                  v3Style
                >
                  {email} already has a {APP_NAME} account.
                  You can add them to your family.
                </EnhancedAlert>
              </Box>
            </Collapse>

            <Collapse
              in={Boolean((email && email === values.email)
                && !enteredSelfEmail
                && !doesMemberExist)}
            >
              <Box
                margin={1.5}
                marginTop={2.5}
              >
                <EnhancedAlert
                  severity="info"
                  v3Style
                >
                  {email} does not have a {APP_NAME} account.
                </EnhancedAlert>
              </Box>
            </Collapse>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Email;
