// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import gql from 'graphql-tag';

// Internal Dependencies
import { GET_CALENDAR_LIST, GET_ORGANIZATION } from 'gql/queries';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateCalendarsResponse {
  createCalendars: boolean;
}

const CREATE_GOOGLE_CALENDARS = gql`
  mutation CreateCalendars($input: CreateCalendarsInput!) {
    createCalendars(input: $input)
  }
`;

const REVOKE_GOOGLE_CALENDAR = gql`
  mutation RevokeGoogleCalendarToken { 
    revokeGoogleCalendarToken
  }
`;

export const useCreateGoogleCalendars = () =>
  useMutationEnhanced<
    CreateCalendarsResponse,
    GQL.ICreateCalendarsOnMutationArguments
  >(
    CREATE_GOOGLE_CALENDARS,
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['googleCalendarEvents'],
      refetchQueries: () => [getOperationName(GET_CALENDAR_LIST) as string],
    },
  );

export const useRevokeCalendarToken = (onCompleted: () => void) =>
  useMutationEnhanced(REVOKE_GOOGLE_CALENDAR, {
    awaitRefetchQueries: true,
    clearCachePredicates: ['organization'],
    onCompleted,
    refetchQueries: () => [getOperationName(GET_ORGANIZATION) as string],
  });
