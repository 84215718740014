// External Dependencies
import { Field, useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import styled from 'styled-components';

// Local Dependencies
import CustomCheckboxGroupCheckbox from './CustomCheckboxGroupCheckbox';

// Local Typings
interface CheckboxDatum {
  label: string;
  value: string | number;
}

interface Props {
  checkboxData: CheckboxDatum[];
  name: string;
}

// Local Variables
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.label': {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0.5),
    },
    marginLeft: theme.spacing(1),
  },
}));

/*
 * This component should be used in a Formik context.
 *
 */

// Component Definition
const CustomCheckboxGroup = ({
  checkboxData,
  name,
}: Props): JSX.Element => {
  const { values } = useFormikContext();

  return (
    <FormGroup role="group">
      {checkboxData.map((datum) => (
        <StyledFormControlLabel
          classes={{ label: 'label' }}
          control={(
            <Field
              checked={(values as any).name?.includes(datum.value)}
              component={CustomCheckboxGroupCheckbox}
              label={datum.label}
              name={name}
              type="checkbox"
              value={typeof datum.value === 'number'
                ? datum.value.toString()
                : datum.value}
            />
          )}
          key={datum.value}
          label={datum.label}
        />
      ))}
    </FormGroup>
  );
};

export default CustomCheckboxGroup;
