// External Dependencies
import { FC } from 'react';
import { QueryLazyOptions } from '@apollo/client';

// Local Dependencies
import { StyledChip, StyledCircularProgress } from './styles';
import BottomContainer from './BottomContainer';

// Local Typings
interface Props {
  isLoading: boolean;
  query: (options?: QueryLazyOptions<GQL.IHistoryOnQueryArguments> | undefined) => void;
}

// Component Definition
const ShowHistoryButton: FC<Props> = ({
  isLoading,
  query,
}) => {
  const handleClickShowHistory = () => {
    query();
  };

  return (
    <BottomContainer>
      {isLoading
        ? (
          <StyledCircularProgress
            size={24}
            thickness={6}
          />
        )
        : (
          <StyledChip
            clickable
            label="Show History"
            onClick={handleClickShowHistory}
          />
        )}
    </BottomContainer>
  );
};

export default ShowHistoryButton;
