// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, StyledLink } from 'components/shared';
import {
  PATHS,
  TITLES,
} from 'utils/constants/routes';
import { isDistrictAdmin } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';

// Local Variables
const StyledListItem = styled.li(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

// Component Definition
const FourOhFour: FC = () => {
  const isMobileScreen = useSelector(isMobileScreenSize);
  const isDistrictAdminUser = useSelector(isDistrictAdmin);

  return (
    <EnhancedCard>
      <Typography
        sx={{ p: 3 }}
        variant={isMobileScreen ? 'h6' : 'h4'}
      >
        This is not the page you are looking for...
      </Typography>

      <Divider />

      <Box p={3}>
        <Typography>
          Try these popular pages instead:
        </Typography>

        <ul>
          <StyledListItem>
            <StyledLink href={`${isDistrictAdminUser ? `/${PATHS.DISTRICT_ADMIN}` : ''}/${PATHS.DASHBOARD}`}>
              {TITLES[PATHS.DASHBOARD]}
            </StyledLink>
          </StyledListItem>

          <StyledListItem>
            <StyledLink href={`${isDistrictAdminUser ? `/${PATHS.DISTRICT_ADMIN}` : ''}/${PATHS.PROFILE}`}>
              {TITLES[PATHS.PROFILE]}
            </StyledLink>
          </StyledListItem>

          {!isDistrictAdminUser && (
            <StyledListItem>
              <StyledLink href={`/${PATHS.COMMUNICATION}`}>
                {TITLES[PATHS.COMMUNICATION]}
              </StyledLink>
            </StyledListItem>
          )}
        </ul>
      </Box>
    </EnhancedCard>
  );
};

export default FourOhFour;
