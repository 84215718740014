// External Dependencies
import {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import styled from 'styled-components';

// Local Variables
const StyledLabel = styled.label({
  visibility: 'hidden',
});
const StyledInput = styled.input<InputHTMLAttributes<HTMLInputElement>>({
  cursor: 'pointer',
  height: '100%',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1,
});

export const useFileUploader = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  disabled,
  inputId,
  onFileUpload,
}: {
  acceptedFileTypes?: string[];
  disabled?: boolean;
  inputId: string;
  onFileUpload: (file: File) => void;
}) => {
  const clickInput = useCallback(() => {
    const input = document.getElementById(inputId);
    input?.click();
  }, [inputId]);

  const handleFileChange = useCallback((file: File) => {
    onFileUpload(file);
  }, [onFileUpload]);

  const handleClickInput = useCallback(() => {
    clickInput();
  }, [clickInput]);

  const handleDrop = useCallback(
    (files: File[]) => handleFileChange(files[0]),
    [handleFileChange],
  );

  const hiddenInput = useMemo(() => (
    <StyledLabel htmlFor={inputId}>
      <StyledInput
        accept={acceptedFileTypes?.join(', ')}
        disabled={disabled}
        hidden
        id={inputId}
        multiple={false}
        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
          const { files } = e.target;

          if (files) {
            await handleFileChange(files[0]);
          }
        }}
        type="file"
      />
    </StyledLabel>

  ), [acceptedFileTypes, disabled, inputId, handleFileChange]);

  return useMemo(() => ({
    handleClickInput,
    handleDrop,
    hiddenInput,
  }), [
    handleClickInput,
    handleDrop,
    hiddenInput,
  ]);
};
