// External Dependencies
import { FeatureFlag, OrganizationEntityTypes } from '@presto-assistant/api_types';
import {
  GridColDef,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  ALL_NUMERIC_GRADE_OPTIONS,
} from 'utils/constants/entityTypeOptions';
import { getGradeOptions } from 'components/shared/GradeSelect';
import { hasFeatureFlag } from 'state/self/selectors';

// Local Variables
export const useColumns = (
  organizationData?: GQL.IOrganization,
  possibleFeederOrganizations?: GQL.IShallowOrganization[],
): GridColDef[] => {
  const showAllNumericGrades = useSelector(hasFeatureFlag(FeatureFlag.AllGradeOptions));

  const isCollegeOrUniversity = organizationData?.entityType.id
    === OrganizationEntityTypes.College.toString();
  const hasHighSchoolSeniors = organizationData?.graduatingGrade === 12;

  const selectOptions = useMemo(
    () => (showAllNumericGrades
      ? ALL_NUMERIC_GRADE_OPTIONS
      : getGradeOptions(Number(organizationData?.entityType.id))),
    [organizationData, showAllNumericGrades],
  );

  // We need to add one to each grade level for the "next year grade"
  const mappedSelectOptions = useMemo(() => selectOptions.map((option) => {
    const numericValue = parseInt(option.id, 10);

    return {
      id: isCollegeOrUniversity ? String(numericValue) : String(numericValue + 1),
      label: isCollegeOrUniversity ? option.label : String(numericValue + 1),
      // MUI uses `value` for the React `key` in the list of options
      value: isCollegeOrUniversity ? option.label : String(numericValue + 1),
    };
  }), [isCollegeOrUniversity, selectOptions]);

  // Remove the first option if it's 13th grade
  if (parseInt(mappedSelectOptions[0].id, 10) === 13) {
    mappedSelectOptions.shift();
  }

  const updateGradeRow = useCallback(
    (params: GridValueSetterParams) => ({
      ...params.row,
      grade: isCollegeOrUniversity
        ? String(selectOptions.find((option) => option.label === params.value).id ?? '')
        : params.value,
    }),
    [isCollegeOrUniversity, selectOptions],
  );

  const updateSuccessorOrgRow = useCallback(
    (params: GridValueSetterParams) => ({
      ...params.row,
      organizationLabel: params.value,
    }),
    [],
  );

  return useMemo(() => {
    const columns: GridColDef[] = [
      {
        editable: true,
        field: 'firstName',
        headerName: 'First Name',
        width: 160,
      },
      {
        editable: true,
        field: 'lastName',
        headerName: 'Last Name',
        width: 160,
      },
      {
        editable: true,
        field: 'email',
        headerName: 'Email',
        width: 240,
      },
      {
        editable: true,
        field: 'grade',
        headerName: 'Next Year Grade',
        type: 'singleSelect',
        valueGetter: (params) => (isCollegeOrUniversity
          ? selectOptions.find((option) => option.id === String(params.row.grade))?.label ?? ''
          : String(params.row.grade ?? '')),
        valueOptions: mappedSelectOptions,
        valueSetter: updateGradeRow,
        width: 80,
      },
      ...(!isCollegeOrUniversity && !hasHighSchoolSeniors ? [{
        editable: true,
        field: 'successorOrganizationLabel',
        headerName: 'Successor Organization',
        type: 'singleSelect',
        valueGetter: (params) => params.row.organizationLabel,
        valueOptions: (possibleFeederOrganizations?.map(
          (possibleOrganization: GQL.IShallowOrganization) => ({
            label: possibleOrganization.label,
            value: possibleOrganization.label,
          }),
        )) ?? [],
        valueSetter: updateSuccessorOrgRow,
        width: 180,
      } as GridColDef] : []),
    ];

    return columns;
    // TODO: #2645 - Make sure all of these values are properly memoized
  }, [
    hasHighSchoolSeniors,
    isCollegeOrUniversity,
    mappedSelectOptions,
    possibleFeederOrganizations,
    selectOptions,
    updateGradeRow,
    updateSuccessorOrgRow,
  ]);
};
