// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
interface EndOfYearOutstandingItemsResponse {
  financialFeesOverview: GQL.IFinancialFeeOverview;
  inventoryCheckoutCount: number;
  uniformCheckoutCount: number;
}
interface EndOfYearOutstandingItemsOnQueryArguments {
  schoolYearEnding: number | undefined;
}

export const GET_RETURNING_GROUPS = gql`
  query ReturningGroups {
    returningGroups {
      group {
        id
        label
        userCount
      }
      id
      shouldRetainUsers
    }
  }
`;

export const useGetReturningGroups = () =>
  useQueryEnhanced<{ returningGroups: GQL.IReturningGroup[] }>(
    GET_RETURNING_GROUPS,
  );

export const GET_RETURNING_STUDENTS = gql`
  query ReturningStudents {
    returningStudents {
      email
      firstName
      grade
      id
      lastName
      organizationId
      organizationLabel
    }
  }
`;

export const useGetReturningStudents = () =>
  useQueryEnhanced<{ returningStudents: GQL.IReturningStudent[] }>(
    GET_RETURNING_STUDENTS,
  );

export const GET_NON_RETURNING_USERS = gql`
  query NonReturningUsers {
    nonReturningUsers {
      email
      id
      firstName
      lastName
    }
  }
`;

export const useGetNonReturningUsers = () =>
  useQueryEnhanced<{ nonReturningUsers: GQL.IUser[] }>(
    GET_NON_RETURNING_USERS,
  );

const GET_END_OF_YEAR_OUTSTANDING_ITEMS = gql`
  query GetEndOfYearOutstandingItems(
    $schoolYearEnding: Int!
  ) {
    financialFeesOverview(
      schoolYearEnding: $schoolYearEnding
    ) {
      totalNumberOfOutstandingFees
      totalNumberOfUsersWithOutstandingBalance
      totalOutstandingBalanceInCents
    }
    inventoryCheckoutCount
    uniformCheckoutCount
  }
`;

export const useGetEndOfYearOutstandingItems = (
  args: EndOfYearOutstandingItemsOnQueryArguments,
) =>
  useQueryEnhanced<
    EndOfYearOutstandingItemsResponse,
    EndOfYearOutstandingItemsOnQueryArguments
  >(
    GET_END_OF_YEAR_OUTSTANDING_ITEMS,
    {
      skip: !args.schoolYearEnding,
      variables: args,
    },
  );
