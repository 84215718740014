// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  creditFromManualEntryInCents: number;
  creditFromOverpaymentInCents: number;
}

// Component Definition
const AvailableMemberCreditCard: FC<Props> = ({
  creditFromManualEntryInCents,
  creditFromOverpaymentInCents,
}) => (
  <DashboardCard
    content={(
      <>
        <ShowPageDataDisplay
          label="Credit from Overpayment"
          type="currency"
          value={creditFromOverpaymentInCents}
        />

        <ShowPageDataDisplay
          label="Credit from Manual Entry"
          type="currency"
          value={creditFromManualEntryInCents}
        />
      </>
    )}
    title="Current Year Credits"
  />
);

export default AvailableMemberCreditCard;
