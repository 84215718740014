// External Dependencies
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

// Internal Dependencies
import { parseSearch } from 'utils';

// Hook Definition
export const useParsedSearch = <T = Record<string, any>>(params?: string) => {
  const { search } = useLocation();
  return useMemo(() => parseSearch<T>(params ?? search), [params, search]) as T;
};
