// External Dependencies
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { GroupsInput, PrimaryRoleInput } from 'pages/People/Students/All/StudentsTable/hooks';
import { TableResource, updateTableQueryParams } from 'state/table/actions';
import { useGetGradeOptions } from 'hooks/useGetGradeOptions';
import { useGetGroupsAll, useGetOrganizationRoles } from 'gql/queries';
import { useIsCollegeOrUniversity } from 'hooks/useIsCollegeOrUniversity';

// Local Dependencies
import {
  gridFilterModelSelector,
  gridQuickFilterValuesSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { renderSelectEditInputCell } from './helpers';

// Hook Definitions
export const useUpdateParams = (
  tableResource: TableResource | undefined,
  search: string,
) => {
  const dispatch = useDispatch();

  return useEffect(() => {
    if (tableResource) {
      dispatch(updateTableQueryParams({
        key: tableResource,
        value: search,
      }));
    }
  }, [dispatch, search, tableResource]);
};

export const useGradeColDef: <T>(args: {
  editable?: boolean;
  field: keyof T;
  valueGetter?: DataGridColDef<T>['valueGetter'];
}) => DataGridColDef<T> = ({
  editable,
  field,
  valueGetter,
}) => {
  const isCollegeOrUniversity = useIsCollegeOrUniversity();

  const gradeOptions = useGetGradeOptions();
  const gradeOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...gradeOptions.options,
  ], [gradeOptions.options]);

  const getValue = valueGetter ?? ((params) => params.row[field]);

  return {
    editable: editable ?? false,
    field,
    headerName: 'Grade',
    renderEditCell: (params) => renderSelectEditInputCell({
      options: gradeOptionsWithEmptyOption,
      params,
    }),
    type: 'singleSelect',
    valueGetter: (params) => (isCollegeOrUniversity
      ? gradeOptions.options.find((option) => option.id === String(getValue(params)))?.label ?? ''
      : getValue(params) ?? ''),
    valueOptions: gradeOptions.options.map((option) => ({
      label: option.label,
      value: isCollegeOrUniversity ? option.label : Number(option.id),
    })),
    valueSetter: (params) => ({
      ...params.row,
      // For college/university, we need check against the label string
      // eslint-disable-next-line no-nested-ternary
      grade: isCollegeOrUniversity
        ? gradeOptions.options?.find((option) =>
          option.label === params.value ?? '')?.id ?? ''
        // For K-12, we can use the value unless selected the empty option
        : params.value ? Number(params.value) : null,
    }),
    width: 80,
  };
};

export const useGetFilterCounts = () => {
  const apiRef = useGridApiContext();

  // Current content in the quick filter i.e. search bar
  const currentQuickFilterValue = useGridSelector(apiRef, gridQuickFilterValuesSelector);
  const hasQuickFilterValue = currentQuickFilterValue && currentQuickFilterValue.length > 0;

  // Current state for all applied filters
  const currentFilterModelValue = useGridSelector(apiRef, gridFilterModelSelector);
  const appliedFilterCount = currentFilterModelValue.items.length;

  return useMemo(() => ({
    appliedFilterCount,
    currentQuickFilterValue,
    hasQuickFilterValue,
  }), [appliedFilterCount, currentQuickFilterValue, hasQuickFilterValue]);
};

export const useGroupsColumn: <T>(args: {
  field: keyof T;
  groupIdsSelector: (row: T) => string[];
}) => DataGridColDef<T> | null = ({
  field,
  groupIdsSelector,
}) => {
  const groups = useGetGroupsAll();

  const groupLabels = useMemo(
    () => groups.data?.groups?.data?.reduce((acc, group) => {
      acc[group.id] = group.label;

      return acc;
    }, {} as Record<string, string>),
    [groups.data?.groups?.data],
  );

  if (!groups.data?.groups?.data?.length) {
    return null;
  }

  return {
    field,
    filterOperators: [
      {
        InputComponent: GroupsInput(),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = groupIdsSelector(params.row);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroups.length) {
              return true;
            }

            return groupIds.some((id) => selectedGroupIds.includes(id));
          };
        },
        label: 'is any of',
        value: 'isAnyOf',
      },
      {
        InputComponent: GroupsInput(),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = groupIdsSelector(params.row);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroupIds.length) {
              return true;
            }

            return selectedGroupIds.every((id) => groupIds.includes(id));
          };
        },
        label: 'is all of',
        value: 'isAllOf',
      },
      {
        InputComponent: GroupsInput(),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = groupIdsSelector(params.row);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroupIds.length) {
              return true;
            }

            return selectedGroupIds.every((id) => !groupIds.includes(id));
          };
        },
        label: 'is not in',
        value: 'isNotIn',
      },
    ],
    headerName: 'Groups',
    valueGetter: (params) => {
      const groupIdsForRow = groupIdsSelector(params.row);
      const groupLabelsForRow = groupIdsForRow
        .map((id) => groupLabels?.[id] ?? '')
        .filter(Boolean);

      return groupLabelsForRow.join('; ');
    },
  };
};

export const usePrimaryRoleColDef: <T>(args: {
  editable?: boolean;
  field: keyof T;
  primaryRoleIdSelector: (row: T) => number | null | undefined;
  valueGetter?: DataGridColDef<T>['valueGetter'];
}) => DataGridColDef<T> = ({
  editable,
  field,
  primaryRoleIdSelector,
  valueGetter,
}) => {
  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  const primaryRoleOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...organizationRoleData?.organizationRoles ?? [],
  ], [organizationRoleData?.organizationRoles]);

  return {
    editable,
    field,
    filterOperators: [
      {
        InputComponent: PrimaryRoleInput,
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          if (Array.isArray(filterItem.value) && !filterItem.value.length) {
            return null;
          }

          return (params): boolean => {
            const primaryRoleId = primaryRoleIdSelector(params.row);

            if (!primaryRoleId) {
              return false;
            }

            const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
            const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

            if (!selectedPrimaryRoleIds.length) {
              return false;
            }

            return selectedPrimaryRoleIds.includes(primaryRoleId);
          };
        },
        label: 'is any of',
        value: 'isAnyOf',
      },
      {
        InputComponent: PrimaryRoleInput,
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const primaryRoleId = primaryRoleIdSelector(params.row);

            if (!primaryRoleId) {
              return true;
            }

            const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
            const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

            if (!selectedPrimaryRoleIds.length) {
              return false;
            }

            return !selectedPrimaryRoleIds.includes(primaryRoleId);
          };
        },
        label: 'is not in',
        value: 'isNotIn',
      },
    ],
    headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
    renderCell: (params) => {
      const primaryRole = organizationRoleData?.organizationRoles
        .find((role) => role.id.toString() === params.value?.toString()) ?? null;

      return primaryRole?.label ?? '';
    },
    renderEditCell: (params) => renderSelectEditInputCell({
      options: primaryRoleOptionsWithEmptyOption,
      params,
    }),
    type: 'singleSelect',
    valueGetter,
    valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
      label: role.label,
      value: role.id,
    })),
    valueSetter: (params) => ({
      ...params.row,
      primaryRole: (organizationRoleData?.organizationRoles
        .find((role) => role.label === params.value) as GQL.IPrimaryRole | undefined) ?? null,
    }),
  };
};
