// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import gql from 'graphql-tag';

// Internal Dependencies
import { GET_MY_ORGANIZATIONS_REQUESTS, GET_MY_SENT_INVITATIONS_TO_DFA } from 'gql/queries';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface RespondToInvitationArgs {
  onCompleted: () => void;
}
interface InviteDistrictArgs {
  onCompleted: () => void;
}

export const RESPOND_TO_DISTRICT_INVITATION = gql`
  mutation RespondToDistrictInvitation(
    $input: RespondToInvitationToDfaInput!
  ) {
    respondToInvitationToDfa(
      input: $input
    )
  }
`;

export const INVITE_DISTRICT = gql`
  mutation InviteDistrict(
    $input: InviteDistrictInput!
  ) {
    inviteDistrict(
      input: $input
    )
  }
`;

export const RESEND_INVITATION = gql`
  mutation ResendInvitationToDfa(
    $invitationId: ID!
  ) {
    resendInvitationToDfa(invitationId: $invitationId)
  }
`;

export const useRespondToDistrictInvitation = (args: RespondToInvitationArgs) =>
  useMutationEnhanced<
    { respondToInvitationToDfa: boolean },
    GQL.IRespondToInvitationToDfaOnMutationArguments
  >(
    RESPOND_TO_DISTRICT_INVITATION,
    {
      clearCachePredicates: ['districtOrganizationsIndex'],
      onCompleted: args.onCompleted,
      refetchQueries: () => [getOperationName(GET_MY_ORGANIZATIONS_REQUESTS) as string],
    },
  );

export const useInviteDistrict = (args: InviteDistrictArgs) =>
  useMutationEnhanced<
    { invitedDistrict: boolean },
    GQL.IInviteDistrictOnMutationArguments
  >(
    INVITE_DISTRICT,
    {
      onCompleted: args.onCompleted,
      refetchQueries: () => [getOperationName(GET_MY_SENT_INVITATIONS_TO_DFA) as string],
    },
  );

export const useResendInvitationToDfa = (args: InviteDistrictArgs) =>
  useMutationEnhanced<
    { resendInvitationToDfa: boolean },
    GQL.IResendDistrictInvitationOnMutationArguments
  >(
    RESEND_INVITATION,
    {
      onCompleted: args.onCompleted,
      refetchQueries: () => [getOperationName(GET_MY_SENT_INVITATIONS_TO_DFA) as string],
    },
  );
