// External Dependencies
import { Form, Formik } from 'formik';
import { ReportTypes } from '@presto-assistant/api_types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Internal Dependencies
import {
  EnhancedCard, Flex, SaveButton, ShowCardHeader,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isDistrictAdmin } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateFormDetails } from 'gql/mutations/form-mutations';

// Local Dependencies
import FormInputs from './FormInputs';
import NotifyOnAssignmentAlert from '../NotifyOnAssignmentAlert';
import PreviewFormDrawer from '../PreviewFormDrawer';
import ReadOnlyFields from './ReadOnlyFields';

// Local Typings
interface Props {
  form: GQL.IForm;
  isEditMode: boolean;
}

interface FormValues {
  assignTo?: string[];
  organizationEntityTypeIds?: string[];
  organizationTypeIds?: string[];
  schoolYearEnding: number;
  title: string;
}

const DetailsCard = ({
  form,
  isEditMode,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const isDfa = useSelector(isDistrictAdmin);

  const {
    isOpen: isEditing,
    toggleIsOpen: toggleIsEditing,
  } = useIsOpen();

  const {
    handleClose: handleClosePreviewDrawer,
    handleOpen: handleOpenPreviewDrawer,
    isOpen: isPreviewDrawerOpen,
  } = useIsOpen();

  const [
    updateFormDetails,
    {
      loading: isUpdatingFormDetails,
    },
  ] = useUpdateFormDetails({
    clearCachePredicates: ['formIndex'],
    onCompleted: () => {
      toggleIsEditing();
    },
  });

  const theme = useTheme();

  const handleClickCancel = useCallback((resetForm: () => void) => () => {
    toggleIsEditing();
    resetForm();
  }, [toggleIsEditing]);

  const handleClickGetReport = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}/new?report_type_id=${ReportTypes.DistrictFormResponsesReport}`);
  }, [navigate]);

  const handleFormikSubmit = useCallback((values: FormValues) => {
    updateFormDetails({
      variables: {
        id: form.id,
        input: {
          assignTo: values.assignTo ?? [],
          organizationEntityTypeIds: (values.organizationEntityTypeIds ?? []).map(Number),
          organizationTypeIds: (values.organizationTypeIds ?? []).map(Number),
          schoolYearEnding: Number(values.schoolYearEnding),
          title: values.title.trim(),
        },
      },
    });
  }, [form.id, updateFormDetails]);

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          assignTo: form.assignTo ?? [],
          organizationEntityTypeIds: (form.organizationEntityTypeIds ?? []).map((n) =>
            n.toString()),
          organizationTypeIds: (form.organizationTypeIds ?? []).map((n) => n.toString()),
          schoolYearEnding: form.schoolYearEnding,
          title: form.title,
        }}
        onSubmit={handleFormikSubmit}
      >
        {({
          handleSubmit,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <EnhancedCard>
              <ShowCardHeader
                avatar={(
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.showPage.people,
                    }}
                  >
                    <AssignmentIcon />
                  </Avatar>
                )}
                onClickEditIconButton={(isEditing || !isEditMode) ? undefined : toggleIsEditing}
                title="Form Details"
              />

              {form.shouldNotifyOnAssignment && (
                <CardContent>
                  <NotifyOnAssignmentAlert />
                </CardContent>
              )}

              <Collapse in>
                <CardContent>
                  {isEditing ? <FormInputs form={form} /> : <ReadOnlyFields form={form} />}
                </CardContent>
              </Collapse>

              <Collapse in={isEditing}>
                <CardActions sx={{ visibility: isEditing ? 'inherit' : 'hidden' }}>
                  <Flex
                    gap={1}
                    justifyContent="flex-end"
                    width="100%"
                  >
                    <Button onClick={handleClickCancel(resetForm)}>
                      Cancel
                    </Button>

                    <SaveButton isSaving={isUpdatingFormDetails}>
                      Save
                    </SaveButton>
                  </Flex>
                </CardActions>
              </Collapse>
            </EnhancedCard>

            <Box
              display="flex"
              gap={1}
              justifyContent="flex-end"
              marginTop={2}
            >
              <Button
                onClick={handleOpenPreviewDrawer}
                size="small"
                startIcon={<VisibilityIcon />}
                variant="outlined"
              >
                Preview Form
              </Button>

              {isDfa && form.publishedAt && (
                <Button
                  color="primary"
                  onClick={handleClickGetReport}
                  size="small"
                  variant="contained"
                >
                  Get Report
                </Button>
              )}
            </Box>
          </Form>
        )}
      </Formik>

      {form && (
        <PreviewFormDrawer
          formData={form}
          isOpen={isPreviewDrawerOpen}
          onClose={handleClosePreviewDrawer}
        />
      )}
    </>
  );
};

export default DetailsCard;
