// External Dependencies
import { InventoryFundingSources } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';

// Internal Dependencies
import { CustomInput, CustomSelect, DatePickerField } from 'components/shared';
import { mapEnum } from 'utils/lib/map_enum';

// Local Typings
interface Props {
  inputSize: 'small' | 'medium';
}

// Local Variables
const fundingSourceData = mapEnum(InventoryFundingSources).map((option) => ({
  id: option.id,
  label: toTitleCase(option.label),
}));

// Component Definition
const InventoryPurchaseInfoFormFields = ({ inputSize }: Props): JSX.Element => {
  return (
    <>
      <CustomInput
        label="Purchase Year"
        name="purchaseYear"
        size={inputSize}
        type="number"
      />
      <DatePickerField
        disableFuture={false}
        label="Purchase Date"
        name="purchasedAt"
      />
      <CustomInput
        label="Purchase Order Number"
        name="purchaseOrderNumber"
        size={inputSize}
      />
      <CustomInput
        label="Purchase Value"
        name="purchaseValue"
        size={inputSize}
        type="number"
      />
      <CustomSelect
        label="Funding Source"
        name="inventoryFundingSourceId"
        options={fundingSourceData}
      />
    </>
  );
};

export default InventoryPurchaseInfoFormFields;
