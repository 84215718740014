// External Dependencies
import {
  FC, ReactElement, useEffect,
} from 'react';
import { useFormikContext } from 'formik';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import { ShowCardProps } from '../ShowCard';

// Local Typings
interface Props {
  children: ReactElement;
  dynamicFields?: GQL.IDynamicField[];
  isAdmin: boolean;
  item: DynamicFields | undefined;
  showCardProps: Partial<ShowCardProps>;
}

// Component Definition
const DynamicFormFieldsFormikWrapper: FC<Props> = ({
  children,
  dynamicFields,
  item,
}) => {
  const canUseDynamicFields = useCanUseDynamicFields();

  const { setValues, values } = useFormikContext<DynamicFields>();

  // If the dynamic fields change, we want to apply these changes to the
  //  formik values to remove any stale data.
  // Only an organization with a district can use dynamic fields
  useEffect(() => {
    if (canUseDynamicFields) {
      const prunedDynamicFields = (dynamicFields ?? [])
        .reduce<Record<string, string>>((prev, curr) => ({
          ...prev,
          [curr.dynamicFieldRef]: (item as any)?.[curr.dynamicFieldRef],
        }), {}) as unknown as DynamicFields;

      setValues({
        ...values,
        ...applyDynamicFields(undefined),
        ...applyDynamicFields(prunedDynamicFields),
      });
    }
  }, [canUseDynamicFields, dynamicFields, item]);

  return children;
};

export default DynamicFormFieldsFormikWrapper;
