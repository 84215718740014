import {
  TableColumn, createActionsColumn, displayCell, displayDynamicFieldCell,
} from 'components/shared/TableV2';
import { useGetDynamicFields } from 'gql/queries';
import { useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

export const useColumns = (
  onDelete: (row: GQL.IDistrictUniformIndexItem) => void,
  organizationTypeId: string | undefined,
) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    organizationTypeId,
    tableRef: 'uniforms',
  }, {
    skip: !organizationTypeId,
  });

  return useMemo(() => {
    const columns: TableColumn<GQL.IDistrictUniformIndexItem>[] = [
      {
        Header: 'Type',
        accessor: (row) => displayCell(row.type),
        sortBy: 'type',
      },
      {
        Header: 'Category',
        accessor: (row) => displayCell(row.category?.label),
        sortBy: 'categoryLabel',
      },
      {
        Header: 'Uniform #',
        accessor: (row) => displayCell(row.number),
        sortBy: 'number',
      },
      {
        Header: 'Style',
        accessor: (row) => displayCell(row.style),
      },
      {
        Header: 'Size',
        accessor: (row) => displayCell(row.size),
        sortBy: 'size',
      },
      {
        Header: 'Assigned To',
        accessor: (row) => displayCell(row.organization?.label),
        sortBy: 'organizationLabel',
      },
      {
        Header: 'Color',
        accessor: (row) => displayCell(row.color),
      },
      {
        Header: 'Condition',
        accessor: (row) => displayCell(row.condition),
      },
      {
        Header: 'Purchase Date',
        accessor: (row) => displayCell(row.purchasedAt, { format: 'date' }),
      },
      {
        Header: 'Purchase Price',
        accessor: (row) => displayCell(row.purchaseValueInCents, { format: 'money' }),
      },
      {
        Header: 'Barcode',
        accessor: (row) => displayCell(row.customBarcode),
      },
      {
        Header: 'Comments',
        accessor: (row) => displayCell(row.comments),
      },
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<TableColumn<GQL.IDistrictUniformIndexItem>>((field) => ({
          Header: field.label,
          accessor: (row) => displayDynamicFieldCell(field, row),
          sortBy: field.dynamicFieldRef,
        })),
    ];

    const actionsColumn = createActionsColumn<GQL.IDistrictUniformIndexItem>([
      {
        action: onDelete,
        icon: <DeleteIcon />,
        text: 'Delete',
      },
    ]);

    if (actionsColumn) {
      columns.push(actionsColumn);
    }

    return columns;
  }, [
    onDelete,
    dynamicFieldData,
  ]);
};
