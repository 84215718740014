// External Dependencies
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material/';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const WhoDoWeCollectFrom = () => (
  <>
    <InfoTitle>
      Who do we collect data from?
    </InfoTitle>

    <InfoSection>
      <Typography>
        This data is entered by up to four parties:
      </Typography>

      <List dense>
        <ListItem>
          <ListItemText primary="Teachers" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Parents" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Students" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Parent volunteers with proper permissions" />
        </ListItem>
      </List>
    </InfoSection>
  </>
);

export default WhoDoWeCollectFrom;
