// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

export const useColumns = (
  extraColumns?: GridColDef<GQL.IDirectorIndex>[],
): GridColDef<GQL.IDirectorIndex>[] => useMemo(() => {
  const columns: GridColDef<GQL.IDirectorIndex>[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'title',
      headerName: 'Title',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
