// External Dependencies
import Box from '@mui/material/Box';

// Internal Dependencies
import { NavMenuItem as MUINavMenuItem } from 'components/shared';

// Local Typings
export interface NavMenuItemProps {
  text: string;
  to: string;
}
type Props = {
  onClose: () => void;
} & NavMenuItemProps;

// Component Definition
const NavMenuItem = ({
  onClose,
  text,
  to,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      role="menu"
    >
      <MUINavMenuItem
        isActive
        isFullWidth
        onClick={onClose}
        text={text}
        to={to}
        {...otherProps}
      />
    </Box>
  );
};

export default NavMenuItem;
