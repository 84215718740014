// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { pluralize } from 'utils';

// Local Dependencies
import { Props as ZeroStateProps } from '../ZeroState';
import { useGetFilterCounts } from './hooks';
import TableDataGridZeroState from './TableDataGridZeroState';

// When the user has no results using filter or search, we use the shared
//  TableDataGridZeroState, without a contact us link, adding a custom message

// Component Definition
const TableDataGridZeroResultsState: FC<ZeroStateProps> = ({
  ...otherProps
}) => {
  const {
    appliedFilterCount,
    currentQuickFilterValue,
    hasQuickFilterValue,
  } = useGetFilterCounts();

  const hasFilterApplied = appliedFilterCount > 0;

  const message = (
    <Typography>
      No results found
      {hasQuickFilterValue ? (
        <>
          {' '}for <strong>{currentQuickFilterValue}</strong>
        </>
      ) : ''}
      {hasFilterApplied ? ` for selected ${pluralize(appliedFilterCount, 'filter')}` : ''}
    </Typography>
  );

  return (
    <TableDataGridZeroState
      excludeNeedHelp
      message={message}
      {...otherProps}
    />
  );
};

export default TableDataGridZeroResultsState;
