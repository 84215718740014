// External Dependencies
import { LibraryIndexResponseItem } from '@presto-assistant/api_types/api/v1/library';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { displayDynamicFieldCell } from 'components/shared/TableV2';
import { useGetDynamicFields } from 'gql/queries';

// Local Dependencies
import {
  useGetGradeLevelSelectOptions,
  useGetLibraryCategorySelectOptions,
  useGetLibraryInstrumentationSelectOptions,
} from '../../shared/data';

export const useColumns = (extraColumns?: DataGridColDef<LibraryIndexResponseItem>[]) => {
  const {
    data: categoryData,
  } = useGetLibraryCategorySelectOptions({ isAdmin: false });

  const {
    data: instrumentationData,
  } = useGetLibraryInstrumentationSelectOptions({ isAdmin: false });

  const {
    data: gradeLevelData,
  } = useGetGradeLevelSelectOptions();

  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'library_items',
  });

  const hasDynamicFieldNamedComments = dynamicFieldData?.dynamicFields.some((dynamicField) => dynamicField.label === 'Comments');

  return useMemo(() => {
    const columns: DataGridColDef<LibraryIndexResponseItem>[] = [
      {
        editable: true,
        field: 'categoryLabel',
        headerName: 'Category',
        type: 'singleSelect',
        valueGetter: (params) =>
          categoryData?.libraryCategories.find((category) =>
            category.label === params.row.categoryLabel)?.label,
        valueOptions: categoryData?.libraryCategories?.map((category) => ({
          label: category.label,
          value: category.label,
        })),
        width: 160,
      },
      {
        editable: true,
        field: 'instrumentationLabel',
        headerName: 'Voice/Instrumentation',
        minWidth: 200,
        type: 'singleSelect',
        valueGetter: (params) =>
          instrumentationData?.libraryInstrumentations.find((instrumentation) =>
            instrumentation.label === params.row.instrumentationLabel)?.label,
        valueOptions: instrumentationData?.libraryInstrumentations?.map((instrumentation) => ({
          label: instrumentation.label,
          value: instrumentation.label,
        })),
        width: 200,
      },
      {
        editable: true,
        field: 'title',
        headerName: 'Title',
        width: 160,
      },
      {
        editable: true,
        field: 'composer',
        headerName: 'Composer',
        width: 160,
      },
      {
        editable: true,
        field: 'arranger',
        headerName: 'Arranger',
        width: 292,
      },
      {
        editable: true,
        field: 'number',
        headerName: 'Number',
        width: 292,
      },
      {
        editable: true,
        field: 'year',
        headerName: 'Publication Date',
        width: 248,
      },
      {
        editable: true,
        field: 'grade',
        headerName: 'Grade',
        type: 'singleSelect',
        valueGetter: (params) =>
          gradeLevelData?.libraryGradeLevels.find((option) =>
            option.value === params.row.grade)?.value,
        valueOptions: gradeLevelData?.libraryGradeLevels?.map((level) => ({
          label: level.description
            ? `Grade ${level.value} (${level.description})`
            : `Grade ${level.value}`,
          value: level.value,
        })),
        width: 80,
      },
      {
        field: 'accompaniment',
        headerName: 'Accompaniment',
      },
      {
        field: 'author',
        headerName: 'Author',
        width: 160,
      },
      {
        field: 'conditionLabel',
        headerName: 'Condition',
        width: 160,
      },
      {
        field: 'language',
        headerName: 'Language',
        width: 160,
      },
      {
        field: 'lastPerformance',
        headerName: 'Last Performance',
        width: 160,
      },
      {
        field: 'majorWork',
        headerName: 'Major Work',
      },
      {
        field: 'numberOfCopies',
        headerName: 'Number of Copies',
        type: 'number',
      },
      {
        field: 'isOutOfPrint',
        headerName: 'Out of Print',
        type: 'boolean',
      },
      {
        field: 'period',
        headerName: 'Period',
        width: 160,
      },
      {
        field: 'priceInCents',
        headerName: 'Price',
        type: 'number',
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(params.value),
        valueGetter: (params) =>
          convertCentsToDollars(params.row.priceInCents),
        width: 160,
      },
      {
        field: 'publisher',
        headerName: 'Publisher',
        width: 160,
      },
      {
        field: 'isSolo',
        headerName: 'Solo',
        type: 'boolean',
      },
      {
        field: 'stateCode',
        headerName: 'State Code',
        width: 160,
      },
      ...(!hasDynamicFieldNamedComments ? [{
        editable: true,
        field: 'comments' as (keyof LibraryIndexResponseItem),
        headerName: 'Comments',
      }] : []),
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<DataGridColDef<LibraryIndexResponseItem>>((field) => ({
          field: field.dynamicFieldRef as (keyof LibraryIndexResponseItem),
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    categoryData?.libraryCategories,
    dynamicFieldData?.dynamicFields,
    extraColumns,
    gradeLevelData?.libraryGradeLevels,
    hasDynamicFieldNamedComments,
    instrumentationData?.libraryInstrumentations,
  ]);
};
