// External Dependencies
import { Form } from 'formik';
import { FormEvent } from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import {
  CustomInput,
  EnhancedCard,
  EnhancedContainer,
  FormActions,
  MoneyInput,
  ShowCard,
} from 'components/shared';
import { useFormikTextField } from 'hooks/useFormikTextField';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';

// Local Typings
interface Props {
  initialValues: GQL.IUpdateFinancialCreditInput;
  isFormTouched: boolean;
  isSubmitting: boolean;
  onClickCancel: () => void;
  onSubmit: (evt: FormEvent<HTMLFormElement>) => void;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const EditForm = ({
  initialValues,
  isFormTouched,
  isSubmitting,
  onClickCancel,
  onSubmit,
}: Props): JSX.Element => {
  const amountField = useFormikTextField('amountInCents', initialValues.amountInCents.toString());

  return (
    <Form onSubmit={onSubmit}>
      <EnhancedContainer>
        <ShowCard
          icon={LocalAtmIcon}
          title="Credit Info"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
          >
            <TextField
              InputProps={{
                inputComponent: MoneyInput as any,
                inputProps: {
                  'aria-label': 'Amount',
                },
                startAdornment,
              }}
              fullWidth
              label="Amount *"
              variant="filled"
              {...amountField}
            />

            <FinancialAccountSelect />

            <CustomInput
              helperText="This note will be visible on a financial statement."
              label="Note *"
              maxRows={5}
              minRows={2}
              multiline
              name="note"
            />
          </Box>
        </ShowCard>

        <Box marginTop={2}>
          <EnhancedCard>
            <FormActions
              context="Credit"
              isEditing
              isFormTouched={isFormTouched}
              isSubmitting={isSubmitting}
              onPressCancelOrBackButton={onClickCancel}
            />
          </EnhancedCard>
        </Box>
      </EnhancedContainer>
    </Form>
  );
};

export default EditForm;
