// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import AdminLicensesTable from './AdminLicensesTable';

// Component Definition
const AdminLicenses: FC = () => (
  <>
    <Subtitle>
      Licenses
    </Subtitle>

    <EnhancedCard sx={{ mb: 4 }}>
      <AdminLicensesTable />
    </EnhancedCard>
  </>
);

export default AdminLicenses;
