// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  libraryItem: GQL.ILibraryItem;
}

// Component Definition
const ShowLibraryAdditionalData: FC<Props> = ({
  libraryItem,
}) => {
  if (!libraryItem) {
    return null;
  }

  return (
    <>
      <ShowPageDataDisplay
        label="Accompaniment"
        value={libraryItem.accompaniment}
      />

      <ShowPageDataDisplay
        label="Author"
        value={libraryItem.author}
      />

      <ShowPageDataDisplay
        label="Copyright Year"
        type="number"
        value={libraryItem.copyrightYear}
      />

      <ShowPageDataDisplay
        label="Language"
        value={libraryItem.language}
      />

      <ShowPageDataDisplay
        label="Last Performance"
        value={libraryItem.lastPerformance}
      />

      <ShowPageDataDisplay
        label="Number of Copies"
        type="number"
        value={libraryItem.numberOfCopies}
      />

      <ShowPageDataDisplay
        label="Out of Print"
        type="boolean"
        value={libraryItem.isOutOfPrint}
      />

      <ShowPageDataDisplay
        label="Period"
        value={libraryItem.period}
      />

      <ShowPageDataDisplay
        label="Price"
        type="currency"
        value={libraryItem.priceInCents}
      />

      <ShowPageDataDisplay
        label="Publisher"
        value={libraryItem.publisher}
      />

      <ShowPageDataDisplay
        label="Solo"
        type="boolean"
        value={libraryItem.isSolo}
      />

      <ShowPageDataDisplay
        label="State Code"
        value={libraryItem.stateCode}
      />

      <ShowPageDataDisplay
        label="Major Work"
        value={libraryItem.majorWork}
      />
    </>
  );
};

export default ShowLibraryAdditionalData;
