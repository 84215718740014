// External Dependencies
import { getFullName } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn, dateTimeColumn } from 'utils/lib/tableColumns';

// Local Dependencies
export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<GQL.IMyFormsIndexItem>[] = [
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 160,
        width: 240,
      },
      {
        field: 'assignedTo' as keyof GQL.IMyFormsIndexItem,
        headerName: 'Assigned To',
        valueGetter: ({ row }) => getFullName({
          firstName: row.assignedToMemberFirstName,
          lastName: row.assignedToMemberLastName,
        }),
        width: 160,
      },
      dateColumn({
        field: 'updatedAt',
        headerName: 'Updated',
      }),
      dateColumn({
        field: 'publishedAt',
        headerName: 'Published',
      }),
      dateTimeColumn({
        field: 'submittedAt',
        headerName: 'Submitted',
      }),
      {
        field: 'submittedByMemberId',
        headerName: 'Submitted By',
        valueGetter: ({ row }) => (row.submittedByMemberId
          ? getFullName({
            firstName: row.submittedByMemberFirstName ?? '',
            lastName: row.submittedByMemberLastName ?? '',
          })
          : null),
        width: 160,
      },
    ];

    return columns;
  }, []);
};
