// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import ChecklistsTable from './ChecklistsTable';

// Component Definition
const Checklists: FC = () => (
  <>
    <Subtitle>
      Checklists
    </Subtitle>

    <EnhancedCard>
      <ChecklistsTable />
    </EnhancedCard>
  </>
);

export default Checklists;
