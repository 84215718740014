// External Dependencies
import { Form, Formik } from 'formik';
import { MouseEvent, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisiblityIcon from '@mui/icons-material/Visibility';
import VisiblityOffIcon from '@mui/icons-material/VisibilityOff';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomFormButton,
  CustomInput,
  EnhancedAlert,
} from 'components/shared';
import { validateEmail, validatePasswordLength } from 'utils';

// Local Typings
interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface Props {
  loginError: string;
  onLogin: (data: FormValues) => void;
}

// Local Variables
const StyledForm = styled(Form)(({ theme }) => ({
  '.customButton': {
    margin: 10,
    padding: '6px 48px',
  },
  '.emailField': {
    marginBottom: theme.spacing(2),
  },
  '.errorAlert': {
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
  '.errorContainer': {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  '.formActions': {
    position: 'relative',
  },
  '.formWrapper': {
    marginBottom: theme.spacing(2),
  },
  '.image': {
    margin: '32px 0',
  },
  '.progress': {
    position: 'absolute',
    right: 22,
    top: 16,
  },
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 368,
  padding: '24px 24px 8px',
}));

// Component Definition
const LoginForm = ({
  loginError = '',
  onLogin,
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const initialValues: FormValues = {
    email: process.env.REACT_APP_LOGIN_EMAIL || '',
    password: process.env.REACT_APP_LOGIN_PASSWORD || '',
    rememberMe: false,
  };

  const handleValidate = (values: any) => {
    const errors: any = {};

    if (values?.email && !validateEmail(values.email.trim())) {
      errors.email = 'Invalid email';
    }

    if (values?.password && !validatePasswordLength(values.password.trim())) {
      errors.password = 'Password must be at least 8 characters long';
    }

    const nonRequiredKeys = ['rememberMe'];

    // map over values and make all required
    Object.entries(values).forEach(([k, v]) => {
      if (!v && !nonRequiredKeys.includes(k)) {
        errors[k] = 'Required';
      }
    });

    return errors;
  };

  const handleSubmit = async (values: FormValues, formikProps: any) => {
    const { setSubmitting } = formikProps;

    // Trim the email and password values before shipping to the API
    const trimmedValues = {
      ...values,
      email: values.email.trim(),
      password: values.password.trim(),
    };

    try {
      await onLogin(trimmedValues);
    } catch (error) {
      console.log('There was an error submitting the login form', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      {({ isSubmitting, values }) => (
        <StyledForm>
          <div className="formWrapper">
            <CustomInput
              autoComplete="username"
              id="login-email"
              label="Email"
              name="email"
              rootStyles="emailField"
              testId="login-email"
              variant="outlined"
            />
            <CustomInput
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <VisiblityIcon /> : <VisiblityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
              id="login-password"
              label="Password"
              name="password"
              testId="login-password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
            />
          </div>

          <CustomCheckbox
            label="Keep me signed in"
            name="rememberMe"
          />

          <section className="formActions">
            <CustomFormButton
              buttonText="Sign in"
              className="customButton"
              data-testid="login-submit-button"
              disabled={isSubmitting}
            />
            {isSubmitting && (
              <CircularProgress
                classes={{ root: 'progress' }}
                size={24}
                thickness={5}
              />
            )}
          </section>

          <Collapse
            className="errorContainer"
            in={Boolean(loginError && values.email && values.password)}
          >
            <EnhancedAlert
              severity="error"
              title="Login Error"
            >
              {loginError}
            </EnhancedAlert>
          </Collapse>
        </StyledForm>
      )}
    </Formik>
  );
};

export default LoginForm;
