// External Dependencies
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { EnhancedDialog } from 'components/shared';
import {
  close,
} from 'state/ui/inventoryMissingSerialNumberDialog/actions';
import {
  form,
  isOpen as isOpenSelector,
} from 'state/ui/inventoryMissingSerialNumberDialog/selectors';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  isEditing?: boolean;
}

// Component Definition
const InventoryItemMissingSerialNumberConfirmDialog: FC<Props> = ({
  isEditing,
}) => {
  const dispatch = useDispatch();
  const { handleSubmitForm } = useSelector(form);
  const isOpen = useSelector(isOpenSelector);

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleClose = () => {
    dispatch(close());
  };

  const handleCloseDialog = (
    _event: object,
    reason: string,
  ) => {
    // We don't allow the user to click away from the success dialog
    // They will always be sent back to the correct "next" page
    if (['backdropClick', 'escapeKeyDown'].includes(reason)) {
      return null;
    }

    return handleClose();
  };

  const handleClickSubmitButton = () => {
    try {
      handleSubmitForm();
    } catch (err) {
      console.log('There was an error while submtting from the missing serial number dialog', err);
    } finally {
      handleClose();
    }
  };

  return (
    <EnhancedDialog
      aria-describedby="serial-number-missing-description"
      aria-labelledby="serial-number-missing-title"
      maxWidth="sm"
      onClose={handleCloseDialog}
      open={isOpen}
    >
      <DialogTitle id="serial-number-missing-title">
        Serial Number missing
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="serial-number-missing-description">
          We noticed that you {isEditing ? 'removed the' : 'did not add a'}{' '}
          serial number. We will generate a system ID in place of the serial
          number.
        </DialogContentText>
        <DialogContentText id="serial-number-missing-description">
          Would you like to {isEditing ? 'update' : 'add'} this item without a
          serial number?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={handleClose}
        >
          No{!isMobileOrTabletScreen && ', I have a Serial Number'}
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleClickSubmitButton}
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </EnhancedDialog>
  );
};

export default InventoryItemMissingSerialNumberConfirmDialog;
