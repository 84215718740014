// External Dependencies
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { TableResource, updateTableSelections } from 'state/table/actions';

export function useClearTableSelection(tableResource: TableResource) {
  const dispatch = useDispatch();

  const clearTableSelection = () => {
    dispatch(updateTableSelections({
      key: tableResource,
      value: {
        ids: [],
        selectionType: 'SelectedOne',
      },
    }));
  };

  return clearTableSelection;
}
