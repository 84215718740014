// External Dependencies
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import styled from 'styled-components';

// Internal Dependencies
import { CustomInput } from 'components/shared';
import { CustomInputProps } from 'components/shared/CustomInput';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface Props extends CustomInputProps {
  hideTooltip?: boolean;
  tooltipTitle: string;
}

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  '.helpIcon': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25em',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1.3em',
    },
    alignSelf: 'center',
    marginLeft: 8,
  },
  '.input': {
    marginBottom: 16,
  },
  '.paragraphText': {
    alignSelf: 'flex-start',
    fontSize: '0.75em',
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  },
  '.tooltipText': {
    fontSize: 11.5,
    textAlign: 'center',
    width: 150,
  },
}));

// Component Definition
const CustomInputWithTooltip: FC<Props> = ({
  hideTooltip,
  size = 'medium',
  tooltipTitle,
  ...otherProps
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const textElement = (
    <Typography className="paragraphText">
      {tooltipTitle}
    </Typography>
  );

  return (
    <StyledBox
      display="flex"
      flexDirection={isMobileOrTabletScreen ? 'column' : 'row'}
      flexWrap="nowrap"
      width="100%"
    >
      {isMobileOrTabletScreen && !hideTooltip && textElement}
      <CustomInput
        {...otherProps}
        size={size}
      />
      {!isMobileOrTabletScreen && !hideTooltip && (
        <Tooltip
          classes={{
            tooltip: 'tooltipText',
          }}
          title={tooltipTitle}
        >
          <IconButton
            className="helpIcon"
            size="large"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
    </StyledBox>
  );
};

export default CustomInputWithTooltip;
