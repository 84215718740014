// External Dependencies
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { getFullName } from 'utils';

// Local Typings
interface Props {
  inventoryItem: GQL.IInventoryItem;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.checkout-history-data-grid': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  '.data-grid-container': {
    '& .MuiDataGrid-columnHeader': {
      fontWeight: 500,
    },

    margin: theme.spacing(1),
  },

  marginTop: theme.spacing(2),
}));

const NoRowsOverlay = (): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <Typography
      color="textSecondary"
      variant="body2"
    >
      This item has never been checked out.
    </Typography>
  </Box>
);

const columns: GridColDef[] = [
  { field: 'checkoutDate', flex: 1, headerName: 'Checkout Date' },
  { field: 'checkinDate', flex: 1, headerName: 'Checkin Date' },
  {
    field: 'active', flex: 1, headerName: 'Is Checked Out', type: 'boolean',
  },
  {
    field: 'user', flex: 1, headerName: 'Checked Out To', valueGetter: ({ row }) => getFullName(row.user),
  },
];

// Component Definition
const InventoryCheckoutHistoryList = ({
  inventoryItem,
}: Props): JSX.Element => {
  const hasCheckouts = inventoryItem.checkouts.length > 0;

  return (
    <StyledRoot>
      <div className="data-grid-container">
        <DataGridPro
          autoHeight
          className="checkout-history-data-grid"
          columns={columns}
          disableRowSelectionOnClick
          hideFooter={!hasCheckouts}
          rows={inventoryItem.checkouts}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
        />
      </div>
    </StyledRoot>
  );
};

export default InventoryCheckoutHistoryList;
