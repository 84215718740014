// External Dependencies
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  condition?: boolean;
  message?: string;
}

// Component Definition
const ErrorMessage = ({
  condition,
  message,
}: Props): JSX.Element | null => {
  if (!condition) {
    return null;
  }

  return (
    <Typography color="error">
      {message}
    </Typography>
  );
};

export default ErrorMessage;
