// External Dependencies
import { OrgTypes } from '@presto-assistant/api_types';
import BugleIcon from 'mdi-material-ui/Bugle';
import DramaMasksIcon from 'mdi-material-ui/DramaMasks';
import GuitarIcon from 'mdi-material-ui/GuitarAcoustic';
import PianoIcon from 'mdi-material-ui/Piano';
import ShoeBalletIcon from 'mdi-material-ui/ShoeBallet';
import Typography from '@mui/material/Typography';
import ViolinIcon from 'mdi-material-ui/Violin';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  Flex,
  StyledLink,
} from 'components/shared';

// Local Typings
interface Props {
  onClickAddRole: () => void;
  orgTypeId: OrgTypes;
  readOnly?: boolean;
}

// Local Variables
const StyledFlex = styled(Flex)({
  '.emptyStateText': {
    margin: '16px 0',
    maxWidth: '70%',
    textAlign: 'center',
  },
  '.icon': {
    height: 72,
    width: 72,
  },
});

const EMPTY_STATE_ICONS = {
  [OrgTypes.Band]: BugleIcon,
  [OrgTypes.Choir]: PianoIcon,
  [OrgTypes.Dance]: ShoeBalletIcon,
  [OrgTypes.Orchestra]: ViolinIcon,
  [OrgTypes.Theater]: DramaMasksIcon,
  [OrgTypes.Mariachi]: GuitarIcon,
  [OrgTypes.Guitar]: GuitarIcon,
};

// Component Definition
const RoleEmptyState = ({
  onClickAddRole,
  orgTypeId,
  readOnly,
}: Props): JSX.Element => {
  const theme = useTheme();

  const Icon = EMPTY_STATE_ICONS[orgTypeId];

  return (
    <StyledFlex
      alignContent="center"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      padding={4}
    >
      <Icon
        className="icon"
        htmlColor={theme.palette.grey[400]}
      />

      <Typography
        className="emptyStateText"
        sx={{
        }}
        variant="body1"
      >
        No roles
      </Typography>

      {!readOnly && (
        <Typography
          className="emptyStateText"
          variant="body1"
        >
          <StyledLink onClick={onClickAddRole}>
            Add a role
          </StyledLink>
        </Typography>
      )}
    </StyledFlex>
  );
};

export default RoleEmptyState;
