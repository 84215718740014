// External Dependencies
import { MutationResult } from '@apollo/client';
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import { updateOrganizationSchema } from '@presto-assistant/api_types/schemas/organization';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  CustomSelect,
  DialogForm,
  Flex,
} from 'components/shared';
import {
  GET_ORGANIZATION,
  GET_SELF,
} from 'gql/queries';
import { UPDATE_ORGANIZATION } from 'gql/mutations';
import { close } from 'state/ui/editOrganizationDialog/actions';
import { getStates } from 'utils/api';
import { isOpen } from 'state/ui/editOrganizationDialog/selectors';
import { updateById } from 'gql/actions';
import { useMutationEnhanced, useQueryEnhanced } from 'utils/lib/graphql';
import SuccessorOrganizationSelect from 'components/shared/Selectors/SuccessorOrganizationSelect';

// Local Variables
const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  '.firstInRow': {
    marginRight: theme.spacing(1.5),
  },
  '.orgCodeText': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  },
}));

// Component Definition
const DialogEditOrganization = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const isDialogOpen = useSelector(isOpen);

  const [stateOptions, setStateOptions] = useState(undefined);

  const getStateOptions = async () => {
    const response = await getStates();
    const { data } = response;

    if (data?.states) {
      setStateOptions(data.states);
    }
  };

  useEffect(() => {
    getStateOptions();
  }, []);

  const handleClose = () => {
    dispatch(close());
  };

  const {
    data,
    error: orgDataError,
    loading: isLoadingOrgData,
  } = useQueryEnhanced<{ organization: GQL.IOrganization }>(GET_ORGANIZATION);

  const [
    updateOrganization,
    { loading: isUpdatingOrganization },
  ] = useMutationEnhanced<MutationResult>(UPDATE_ORGANIZATION, {
    clearCachePredicates: ['financial'],
    onCompleted: handleClose,
    refetchQueries: [
      { query: GET_SELF },
      { query: GET_ORGANIZATION },
    ],
  });

  if (orgDataError || isLoadingOrgData || !data?.organization) {
    return null;
  }

  const {
    organization: {
      addressOne,
      addressTwo,
      city,
      code,
      entityType,
      id: organizationId,
      label,
      phoneNumber,
      stateId,
      successorOrganization,
      zipcode,
    },
  } = data;

  const isCollegeOrUniversity = entityType.id === OrganizationEntityTypes.College.toString();

  return (
    <StyledDialogForm
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        open: isDialogOpen,
      }}
      initialValues={{
        addressOne,
        addressTwo: addressTwo || '',
        city,
        code,
        label,
        phoneNumber,
        stateId,
        successorOrganizationId: successorOrganization?.id ?? '',
        zipcode,
      } as GQL.IUpdateOrganizationOnMutationArguments}
      isSubmitting={isUpdatingOrganization}
      nonRequiredValues={['addressTwo', 'successorOrganizationId']}
      onClose={handleClose}
      onSubmit={(values) =>
        updateById({
          id: organizationId,
          mutation: updateOrganization,
          onCloseDialog: handleClose,
          values: {
            ...values as GQL.IUpdateOrganizationOnMutationArguments,
            // intentionally using || instead of ??
            successorOrganizationId: (values as GQL.IUpdateOrganizationOnMutationArguments)
              .successorOrganizationId || null,
          },
        })}
      title="Update Organization Info"
      validationSchema={updateOrganizationSchema}
    >
      <CustomInput
        label="Organization Name"
        name="label"
      />

      <Flex columnGap={2}>
        <Box
          flexGrow={1}
        >
          <CustomInput
            label="Address 1"
            name="addressOne"
          />
        </Box>

        <Box
          flexGrow={1}
        >
          <CustomInput
            label="Address 2"
            name="addressTwo"
          />
        </Box>
      </Flex>

      <Flex columnGap={2}>
        <Box flexGrow={1}>
          <CustomInput
            label="City"
            name="city"
            rootStyles="firstInRow"
          />
        </Box>

        {stateOptions && (
          <Box flexGrow={1}>
            <CustomSelect
              label="State"
              name="stateId"
              options={stateOptions}
              required
              variant="filled"
            />
          </Box>
        )}
      </Flex>

      <Flex columnGap={2}>
        <Box flexGrow={1}>
          <CustomInput
            label="Zip Code"
            name="zipcode"
            rootStyles="firstInRow"
            variant="filled"
          />
        </Box>

        <Box flexGrow={1}>
          <CustomInput
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            variant="filled"
          />
        </Box>
      </Flex>

      <Divider sx={{ marginY: 2 }} />

      <Typography className="orgCodeText">
        {`Provide this code to parents, students, and
          staff for use when joining this organization.`}
      </Typography>

      <CustomInput
        label="Organization Code"
        name="code"
        variant="filled"
      />

      {!isCollegeOrUniversity && (
        <>
          <Divider sx={{ marginY: 2 }} />

          <SuccessorOrganizationSelect />
        </>
      )}
    </StyledDialogForm>
  );
};

export default DialogEditOrganization;
