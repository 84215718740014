// External Dependencies
import { useEffect } from 'react';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import { ConfirmationDialog, EnhancedIconButton } from 'components/shared';
import { useDeleteRelationship } from 'gql/mutations/relationship-mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  relationshipId: string;
}

// Component Definition
const DeleteRelationshipButton = ({
  relationshipId,
}: Props): JSX.Element => {
  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: handleToggleIsDeleteDialogOpen,
  } = useIsOpen();

  const deleteNoteField = useTextField();

  const [
    deleteRelationship,
    {
      loading: isSubmitting,
    },
  ] = useDeleteRelationship({
    onCompleted: handleToggleIsDeleteDialogOpen,
  });

  const handleConfirmDelete = () => {
    deleteRelationship({
      variables: {
        input: {
          id: relationshipId,
          note: deleteNoteField.value,
        },
      },
    });
  };

  useEffect(() => {
    if (!isDeleteDialogOpen) {
      deleteNoteField.onReset();
    }
  // Purposefullly omitting deleteNoteField to avoid re-render on key press
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNoteField.onReset, isDeleteDialogOpen]);

  return (
    <>
      <EnhancedIconButton
        icon={<RemoveIcon />}
        onClick={handleToggleIsDeleteDialogOpen}
        tooltip="Remove relationship"
      />

      <ConfirmationDialog
        canBeUndone={false}
        confirmButtonAction={handleConfirmDelete}
        declineButtonAction={handleToggleIsDeleteDialogOpen}
        deletedNoteInputProps={deleteNoteField}
        description="Both users will remain in this organization if you remove the relationship."
        handleClose={handleToggleIsDeleteDialogOpen}
        isSubmitting={isSubmitting}
        open={isDeleteDialogOpen}
        title="Remove this relationship?"
      />
    </>
  );
};

export default DeleteRelationshipButton;
