// Internal Dependencies
import {
  EnhancedCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrictInventoryAudits } from 'utils/api/district';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGrid from 'components/shared/TableDataGrid';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS}/${id}`;

// Component Definition
const AdminInventoryAuditsTable = (): JSX.Element => {
  const {
    data,
    isLoading,
  } = useGetDistrictInventoryAudits();

  const tableColumns = useColumns();

  return (
    <EnhancedCard>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            label: 'District Inventory Audit',
            to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_AUDITS_NEW}`,
          }}
          clickRowTo={handleTableClickRow}
          columns={tableColumns}
          loading={isLoading}
          rows={data?.data.data ?? []}
          tableResource="inventoryAudits"
          withSearch
          zeroStateMessage="Assign a new inventory audit to all directors."
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default AdminInventoryAuditsTable;
