// External Dependencies
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  Typography,
} from '@mui/material';
import {
  FC,
  useCallback,
  useState,
} from 'react';
import { createCcEmailSchema } from '@presto-assistant/api_types/schemas/email';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import {
  AddButton,
  ConfirmationDialog,
  CustomInput,
  ListItemWithSecondaryAction,
  SaveButton,
} from 'components/shared';
import { Form, Formik } from 'formik';
import { useCreateCcEmail, useDeleteCcEmail } from 'gql/mutations';
import { useGetCcEmailList } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Component Definition
const AdditionalCcEmails: FC = () => {
  const [deleteEmail, setDeleteEmail] = useState<GQL.ICcEmail | null>(null);

  const {
    isOpen: isAddEmailDialogOpen,
    toggleIsOpen: toggleIsAddEmailDialogOpen,
  } = useIsOpen();
  const {
    isOpen: isDeleteEmailDialogOpen,
    toggleIsOpen: toggleIsDeleteEmailDialogOpen,
  } = useIsOpen();

  const { data: ccEmailListData } = useGetCcEmailList();

  const ccEmails = ccEmailListData?.ccEmailList;

  const [
    createCcEmail,
  ] = useCreateCcEmail({
    onCompleted: toggleIsAddEmailDialogOpen,
  });

  const [
    deleteCcEmail,
  ] = useDeleteCcEmail({
    onCompleted: toggleIsDeleteEmailDialogOpen,
  });

  const handleSubmitAddEmail = useCallback((input: GQL.ICreateCcEmailInput) => {
    createCcEmail({
      variables: {
        input,
      },
    });
  }, []);

  const handleClickRemove = useCallback((emailId: string) => () => {
    const deletingEmail = ccEmails?.find((email) => email.id === emailId);

    if (deletingEmail) {
      setDeleteEmail(deletingEmail);
      toggleIsDeleteEmailDialogOpen();
    }
  }, [ccEmails]);

  const handleConfirmRemoveEmail = useCallback(() => {
    if (deleteEmail) {
      deleteCcEmail({
        variables: {
          id: deleteEmail.id,
        },
      });
    }
  }, [deleteEmail]);

  return (
    <>
      <Typography>
        Additional CC Emails
      </Typography>

      {ccEmails?.length ? (
        <List>
          {ccEmails?.map((ccEmail) => (
            <ListItemWithSecondaryAction
              key={ccEmail.id}
              primaryText={ccEmail.email}
              secondaryAction={{
                buttonIcon: <RemoveIcon />,
                buttonText: 'Remove',
                onClick: handleClickRemove(ccEmail.id),
              }}
            />
          ))}
        </List>
      ) : (
        <Typography
          color="textSecondary"
          sx={{ m: 2 }}
          variant="body2"
        >
          You have no additional CC emails
        </Typography>
      )}

      <AddButton
        label="CC Email"
        onClick={toggleIsAddEmailDialogOpen}
      />

      <Dialog
        fullWidth
        open={isAddEmailDialogOpen}
        maxWidth="sm"
      >
        <DialogTitle>
          Add CC Email
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            All emails sent within the organization will also include this email.
          </DialogContentText>
        </DialogContent>

        <Formik<GQL.ICreateCcEmailInput>
          initialValues={{
            email: '',
          }}
          onSubmit={handleSubmitAddEmail}
          validationSchema={createCcEmailSchema}
        >
          {() => (
            <Form>
              <DialogContent>
                <CustomInput
                  autoFocus
                  label="Email"
                  name="email"
                />
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  onClick={toggleIsAddEmailDialogOpen}
                  variant="outlined"
                >
                  Cancel
                </Button>

                <SaveButton>
                  Add Email
                </SaveButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmRemoveEmail}
        declineButtonAction={toggleIsDeleteEmailDialogOpen}
        description={`Are you sure you want to remove ${deleteEmail?.email ?? 'this email'} from the default CC list?`}
        handleClose={toggleIsDeleteEmailDialogOpen}
        open={isDeleteEmailDialogOpen}
        title="Remove Email"
      />
    </>
  );
};

export default AdditionalCcEmails;
