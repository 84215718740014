// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryLocationData = ({ inventoryItemData }: Props): JSX.Element | null => {
  if (!inventoryItemData) {
    return null;
  }

  const {
    caseNumber,
    combination,
    location,
    lockNumber,
    locker,
  } = inventoryItemData;

  return (
    <>
      <ShowPageDataDisplay
        label="Location"
        value={location}
      />
      <ShowPageDataDisplay
        label="Case Number"
        value={caseNumber}
      />
      <ShowPageDataDisplay
        label="Locker"
        value={locker}
      />
      <ShowPageDataDisplay
        label="Lock Number"
        value={lockNumber}
      />
      <ShowPageDataDisplay
        label="Combination"
        value={combination}
      />
    </>
  );
};

export default ShowInventoryLocationData;
