// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

const GET_DISTRICT_ASSISTANTS = gql`
  query DistrictAssistants(
    $queryParams: IndexQueryParams
  ) {
    districtAssistants(
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
      }
      fullCount
    }
  }
`;

interface DistrictAssistantsResponse {
  districtAssistants: GQL.IDistrictAssistantsIndexAll;
}

export interface DistrictAssistant {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export const useGetDistrictAssistantsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      DistrictAssistantsResponse,
      GQL.IPendingMemberIndexOnQueryArguments
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    DistrictAssistantsResponse,
    GQL.IPendingMemberIndexOnQueryArguments
  >({
    gqlQuery,
    options,
    tableResource: 'districtAssistants',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictAssistantsIndex = () =>
  usePaginatedListQuery<
    DistrictAssistantsResponse,
    GQL.IDistrictAssistantIndex
  >({
    dataSelector: (res) => res.districtAssistants.data,
    fullCountSelector: (res) => res.districtAssistants.fullCount,
    query: GET_DISTRICT_ASSISTANTS,
  });
