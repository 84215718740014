// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  clearForm,
  updateForm,
} from './actions';

// Local Typings
export interface EditInventoryItemFormState {
  inventoryItem: GQL.IInventoryItem | {};
}

// Reducers
const FORM_INITIAL_STATE: EditInventoryItemFormState['inventoryItem'] = {};
const inventoryItem = createReducer(FORM_INITIAL_STATE, (handleAction) => [
  handleAction(updateForm, (state, { payload }: ReduxData.IAction) => payload),
  handleAction([
    clearForm,
    logoutCurrentUser,
  ], () => FORM_INITIAL_STATE),
]);

export default combineReducers<EditInventoryItemFormState>({
  inventoryItem,
});
