// External Dependencies
import { FC } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
} from 'components/shared';
import { END_OF_YEAR_PROCESS_DEADLINE } from 'utils/constants';

// Local Typings
interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
}

// Local Variables
const StyledStrong = styled.strong({
  whiteSpace: 'pre',
});

// Component Definition
const FinishLaterConfirmDialog: FC<Props> = ({
  isOpen,
  onConfirm,
  onRequestClose,
}) => (
  <ConfirmationDialog
    confirmButtonAction={onConfirm}
    confirmButtonText="Yes, Finish Later"
    declineButtonAction={onRequestClose}
    description={(
      <div id="alert-dialog-description">
        <DialogContentText>
          You can return to this step at any time before you complete the{' '}
          <strong>End Of Year</strong> process.
        </DialogContentText>

        <EnhancedAlert
          severity="warning"
          sx={{ mt: 2 }}
        >
          The <strong>End of Year</strong> process should
          be completed by{' '}
          <StyledStrong>{END_OF_YEAR_PROCESS_DEADLINE}</StyledStrong>{' '}
          each year.
        </EnhancedAlert>
      </div>
    )}
    handleClose={onRequestClose}
    open={isOpen}
    title="Finish Later?"
    useCustomText
  />
);

export default FinishLaterConfirmDialog;
