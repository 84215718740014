// External Dependencies
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { close } from 'state/ui/uncategorizedFinancialFeeUploadDialog/actions';
import {
  isOpen as isUncategorizedFinancialFeeUploadDialogOpenSelector,
} from 'state/ui/uncategorizedFinancialFeeUploadDialog/selectors';
import CSVImporter from 'components/CSVImporter';

// Component Definition
const DialogUncategorizedFinancialFeeFileUpload: FC = () => {
  const dispatch = useDispatch();

  const isUncategorizedFinancialFeeUploadDialogOpen = useSelector(
    isUncategorizedFinancialFeeUploadDialogOpenSelector,
  );

  const handleCloseDialogFinancialFeeFileUpload = () => {
    dispatch(close());
  };

  return (
    <CSVImporter
      context="financial-fee"
      csvFileInfoResource="uncategorizedFinancialFees"
      description={(
        <EnhancedAlert severity="warning">
          {/* eslint-disable-next-line max-len */}
          These fees will belong to one-off financial items that cannot be assigned to other members.
        </EnhancedAlert>
      )}
      dialogTitle="Add Uncategorized Fees"
      insertMutationName="insertUncategorizedFeeCsvFileToDB"
      insertMutationOptions={{
        clearCachePredicates: ['financialFees', 'financialItems'],
      }}
      isOpen={isUncategorizedFinancialFeeUploadDialogOpen}
      onClose={handleCloseDialogFinancialFeeFileUpload}
      uploadMutationName="csvUncategorizedFinancialFeeUpload"
    />
  );
};

export default DialogUncategorizedFinancialFeeFileUpload;
