// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const FinancialPaymentsTableZeroState = (): JSX.Element => {
  return (
    <TableDataGridZeroState
      message={(
        <>
          Any payments made through {APP_NAME} will appear here.
        </>
      )}
    />
  );
};

export default FinancialPaymentsTableZeroState;
