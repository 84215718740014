// External Dependencies
import { useState } from 'react';

function useSelectField(initialValue) {
  const [value, setValue] = useState(initialValue);

  function handleSelect(evt) {
    setValue(evt.target.value);
  }

  return {
    onChange: handleSelect,
    value,
  };
}

export default useSelectField;
