// External Dependencies
import {
  CircularProgress,
  InputBaseComponentProps,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { CustomSelect } from 'components/shared';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Dependencies
import { UNIFORM_CATEGORY_OPTIONS_QUERY } from './data';

// Local Typings
interface Props {
  inputProps?: InputBaseComponentProps;
  orgTypeId: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const UniformCategorySelect: FC<Props> = ({
  inputProps,
  orgTypeId,
}) => {
  const { data, error, loading } = useQueryEnhanced<{
    uniformCategories: GQL.IUniformCategory[];
  }>(UNIFORM_CATEGORY_OPTIONS_QUERY, {
    variables: { organizationTypeId: orgTypeId },
  });

  if (loading) {
    return (
      <CircularProgress
        size={24}
        sx={{ mb: 3, mt: 3 }}
        thickness={4}
      />
    );
  }

  if (!data) {
    return null;
  }

  if (error) {
    console.log('There was an error loading uniform category data');
  }

  return (
    <CustomSelect
      inputProps={inputProps}
      label="Category *"
      name="categoryId"
      options={data?.uniformCategories}
      required={false}
    />
  );
};

export default UniformCategorySelect;
