// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DialogPeoplePicker } from 'components/shared';
import { getEndingSchoolYear } from 'utils/lib/date_helpers';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import { useGetFinancialItem, useGetOrganization } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import FinancialItemMemberFeesTable from 'pages/Finances/shared/FinancialItemMemberFeesTable';

// Local Dependencies
import { feeSchema } from './schema';
import FeeFormFormikForm, { CustomSetter, FeeFormValues } from './FeeFormFormikForm';

// Local Typings
interface Props {
  onSubmit?: (values: GQL.ICreateManyFinancialFeesInput) => void;
}

// Component Definition
const FeeForm: FC<Props> = ({
  onSubmit,
}) => {
  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const { data: orgData } = useGetOrganization();

  const { financialItemId } = useParsedSearch();

  const { data: financialItemData } = useGetFinancialItem(financialItemId);

  const financialItem = financialItemData?.financialItem;

  const initialValues: FeeFormValues = useMemo(() => ({
    financialItemId: financialItem?.id ?? '',
    schoolYearEnding: financialItem?.schoolYearEnding
      ?? orgData?.organization?.currentSchoolYearEnding
      ?? getEndingSchoolYear(),
    userIds: [],
  }), [financialItem, orgData]);

  // Used to pre-populate the DialogPeoplePicker
  const [rawUserIds, setRawUserIds] = useState<string[]>([]);
  // Used to track selected members from DialogPeoplePicker
  const [userIds, setUserIds] = useState<CustomSetter[]>([]);

  const handleUpdateSelectedMembers = useCallback((selectedIds: string[]) => {
    // Used to pre-populate the DialogPeoplePicker
    setRawUserIds(selectedIds);

    // Used to add values to the userIds field in the formik form
    setUserIds([
      {
        fieldName: 'userIds',
        fieldValue: selectedIds,
      },
    ]);
  }, []);

  const handleRemoveUserId = useCallback((userId: string) => () => {
    const userIdIndex = rawUserIds.findIndex((uId) => uId === userId);

    const clonedRawUserIds = [...rawUserIds];

    if (userIdIndex > -1) {
      clonedRawUserIds.splice(userIdIndex, 1);
    }

    handleUpdateSelectedMembers(clonedRawUserIds);
  }, [handleUpdateSelectedMembers, rawUserIds]);

  const handleRemoveMultipleMemberIds = useCallback((memberIdsToRemove: string[]) => () => {
    const clonedAndFilteredRawMemberIds = [...rawUserIds].filter(
      (userId) => !memberIdsToRemove.includes(userId),
    );

    handleUpdateSelectedMembers(clonedAndFilteredRawMemberIds);
  }, [handleUpdateSelectedMembers, rawUserIds]);

  const handleFormikSubmit = useCallback(async (
    values: FeeFormValues,
    formikProps: FormikHelpers<FeeFormValues>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.({
      financialItemId: values.financialItemId,
      userIds: values.userIds,
    });

    setSubmitting(false);
  }, [onSubmit]);

  return (
    <>
      <Formik<FeeFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={feeSchema}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values: formikValues,
        }) => (
          <>
            <FeeFormFormikForm
              errors={errors}
              formikValues={formikValues}
              isSubmitting={isSubmitting}
              onRemoveMultipleMemberIds={handleRemoveMultipleMemberIds}
              onRemoveUserId={handleRemoveUserId}
              onSubmit={handleSubmit}
              rawUserIds={rawUserIds}
              setFieldValue={setFieldValue}
              touched={touched}
              userIds={userIds}
            />

            {formikValues.financialItemId && (
              <FinancialItemMemberFeesTable financialItemId={formikValues.financialItemId} />
            )}
          </>
        )}
      </Formik>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateSelectedMembers}
        preSelectedIds={rawUserIds}
        tableResource="peoplePickerFeeForm"
        title="Select Members"
      />
    </>
  );
};

export default FeeForm;
