import styled from 'styled-components';

// Component Definition
const InfoSection = styled.section(({ theme }) => ({
  color: theme.palette.grey[800],
  marginBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  textAlign: 'left',
}));

export default InfoSection;
