// External Dependencies
import { Chip, ChipProps, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { CONTACT_INFO } from 'utils/constants';
import { useGetOrganizationSubscriptionDetails } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import StyledLink from 'components/shared/StyledLink';

// Local Dependencies
import RedeemLicenseDialog from './RedeemLicenseDialog';

// Local Typings
interface Props {
  onlyShowIfActionNeeded: boolean;
}

// Local Variables
const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  height: theme.spacing(3),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const getPaymentDeadlineText = (daysUntilPaymentIsDue: number) => {
  switch (daysUntilPaymentIsDue) {
    case 0:
      return null;
    case 1:
      return 'Payment due today';
    default:
      return `Payment due in ${daysUntilPaymentIsDue} days`;
  }
};

const getLicenseExpirationText = (daysUntilLicenseExpires: number | null) => {
  switch (daysUntilLicenseExpires) {
    case null:
      return null;
    case 0:
      return 'License is expired';
    case 1:
      return 'License expires today';
    default:
      return `License exp in ${daysUntilLicenseExpires} days`;
  }
};

const getStatusLabelText = ({
  daysUntilCurrentLicenseExpires,
  daysUntilPaymentIsDue,
  isFreeTrial,
}: {
  daysUntilCurrentLicenseExpires: number;
  daysUntilPaymentIsDue: number;
  isFreeTrial: boolean;
}) => {
  if (isFreeTrial) {
    return 'Free Trial';
  }

  return getPaymentDeadlineText(daysUntilPaymentIsDue)
    ?? getLicenseExpirationText(daysUntilCurrentLicenseExpires);
};

// Component Definition
const SubStatusBadge: FC<Props> = ({
  onlyShowIfActionNeeded,
}) => {
  const {
    handleClose: onCloseRedeemLicenseDialog,
    handleOpen: onOpenRedeemLicenseDialog,
    isOpen: isRedeemLicenseDialogOpen,
  } = useIsOpen(false);

  const { data } = useGetOrganizationSubscriptionDetails();

  if (!data) {
    return null;
  }

  const {
    organization: {
      active,
      daysUntilCurrentLicenseExpires,
      daysUntilPaymentIsDue,
      isFreeTrial,
    },
  } = data;

  if (!active) {
    return (
      <>
        <StyledChip
          color="error"
          label="Organization Inactive"
        />

        <Typography variant="caption">
          Contact
          {' '}
          <StyledLink
            href={CONTACT_INFO.MAILTO_BILLING}
            openInNewTab
          >
            Presto Billing
          </StyledLink>
        </Typography>

        <Typography variant="caption">
          or
          {' '}
          <StyledLink onClick={onOpenRedeemLicenseDialog}>
            redeem a license
          </StyledLink>
        </Typography>

        <RedeemLicenseDialog
          isOpen={isRedeemLicenseDialogOpen}
          onClose={onCloseRedeemLicenseDialog}
        />
      </>
    );
  }

  const isLicenseAboutToExpire = daysUntilCurrentLicenseExpires !== null
    && daysUntilCurrentLicenseExpires <= 30;

  if (!daysUntilPaymentIsDue && !isLicenseAboutToExpire && onlyShowIfActionNeeded) {
    return null;
  }

  let chipColor: ChipProps['color'] = 'secondary';

  if (isLicenseAboutToExpire) {
    chipColor = daysUntilCurrentLicenseExpires <= 7 ? 'error' : 'warning';
  } else if (!daysUntilPaymentIsDue) {
    chipColor = 'default';
  }

  return (
    <>
      <StyledChip
        color={chipColor}
        label={getStatusLabelText({
          daysUntilCurrentLicenseExpires,
          daysUntilPaymentIsDue,
          isFreeTrial,
        })}
      />

      {isFreeTrial && (
        <Typography variant="caption">
          {getPaymentDeadlineText(daysUntilPaymentIsDue)}
        </Typography>
      )}

      <Typography variant="caption">
        <StyledLink onClick={onOpenRedeemLicenseDialog}>
          {(daysUntilCurrentLicenseExpires ?? 0) > 0 ? 'Redeem another license' : 'Redeem license'}
        </StyledLink>
      </Typography>

      <RedeemLicenseDialog
        isOpen={isRedeemLicenseDialogOpen}
        onClose={onCloseRedeemLicenseDialog}
      />
    </>
  );
};

export default SubStatusBadge;
