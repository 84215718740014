// External Dependencies
import { FC } from 'react';

// Local Dependencies
import { ZeroState } from '..';

// TODO: once we have DFA dynamic field forms built,
// link to that page here

// Component Definition
const DistrictZeroState: FC = () => (
  <ZeroState
    maxTextWidth="90%"
    message="Add dynamic fields for use by organizations."
  />
);

export default DistrictZeroState;
