// Internal Dependencies
import { yup } from 'utils/yup';

export const groupsSchema = yup.object().shape({
  label: yup
    .string()
    .max(255, 'Max length for Group Name is 255 characters')
    .required('Required'),
  userIds: yup
    .array().of(yup.string()),
});
