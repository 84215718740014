// Internal Dependencies
import { ROLES, ROLE_IDS } from 'utils/constants';
import { useGetSelf } from 'gql/queries';

export const useMyFilesSelfData = () => {
  const { data } = useGetSelf();

  if (!data?.self) {
    return null;
  }

  return {
    firstName: data.self.firstName,
    isStudent: data?.self?.role?.id === ROLE_IDS[ROLES.STUDENT],
    lastName: data.self.lastName,
    selfId: data.self.id,
  };
};
