// External Dependencies
import {
  FinancialTransactionReportResponseItem,
} from '@presto-assistant/api_types/api/v1/financialTransaction';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  currencyFromCentsColumn,
  dateColumn,
  dateTimeColumn,
} from 'utils/lib/tableColumns';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import {
  useGetFinancialAccounts,
  useGetOrganizationSchoolYearOptions,
} from 'gql/queries';

// Local Variables
export const useColumns = (
  extraColumns: DataGridColDef<FinancialTransactionReportResponseItem>[],
) => {
  const {
    data: schoolYearEndingData,
  } = useGetOrganizationSchoolYearOptions();
  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  const financialAccountOptions = useMemo(() => {
    return financialAccountsData?.financialAccounts.map((account) => ({
      id: account.id,
      label: account.label,
      value: account.id,
    }));
  }, [financialAccountsData]);

  return useMemo(() => {
    const columns: DataGridColDef<FinancialTransactionReportResponseItem>[] = [
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Transaction Date',
      }),
      dateColumn({
        field: 'paymentDate',
        headerName: 'Payment Date',
      }),
      currencyFromCentsColumn({
        field: 'paymentAmountInCents',
        headerName: 'Payment Amount',
      }),
      currencyFromCentsColumn({
        field: 'creditAppliedInCents',
        headerName: 'Credit Applied',
      }),
      currencyFromCentsColumn({
        field: 'creditGeneratedInCents',
        headerName: 'Credit Generated',
      }),
      currencyFromCentsColumn({
        field: 'totalAppliedInCents',
        headerName: 'Total Applied',
        hideable: false,
      }),
      {
        field: 'financialAccountId',
        headerName: 'Account',
        renderCell: (params) => params.row.financialAccountLabel,
        type: 'singleSelect',
        valueGetter: (params) => params.row.financialAccountId,
        valueOptions: financialAccountOptions,
        width: 160,
      },
      {
        field: 'itemLabel',
        headerName: 'Item',
        minWidth: 160,
      },
      currencyFromCentsColumn({
        field: 'itemPriceInCents',
        headerName: 'Item Price',
      }),
      {
        field: 'itemSchoolYearEnding',
        headerName: 'Item School Year',
        minWidth: 160,
        renderCell: (params) => {
          return displaySchoolYearString(params.row.itemSchoolYearEnding);
        },
        type: 'singleSelect',
        valueOptions: schoolYearEndingData?.organizationSchoolYearSelectOptions?.map((option) => ({
          label: option.label,
          value: option.id,
        })) ?? [],
      },
      {
        field: 'memberFirstName',
        headerName: 'First Name',
        minWidth: 160,
      },
      {
        field: 'memberLastName',
        headerName: 'Last Name',
        minWidth: 160,
      },
      {
        field: 'paymentType',
        headerName: 'Payment Type',
        minWidth: 160,
      },
      {
        field: 'createdByMemberFirstName',
        headerName: 'Created By First Name',
        minWidth: 180,
      },
      {
        field: 'createdByMemberLastName',
        headerName: 'Created By Last Name',
        minWidth: 180,
      },
      {
        field: 'checkNumber',
        headerName: 'Check Number',
        minWidth: 180,
      },
      {
        field: 'stripeChargeId',
        headerName: 'Stripe Charge ID',
        minWidth: 180,
      },
      {
        field: 'stripePaymentId',
        headerName: 'Stripe Payment ID',
        minWidth: 180,
      },
      {
        field: 'stripePaymentIntentId',
        headerName: 'Stripe Payment Intent ID',
        minWidth: 180,
      },
      {
        field: 'stripeTransferId',
        headerName: 'Stripe Transfer ID',
        minWidth: 180,
      },
      {
        field: 'vancoPaymentId',
        headerName: 'Vanco Payment ID',
        minWidth: 180,
      },
      {
        field: 'vancoPaymentRef',
        headerName: 'Vanco Payment Ref',
        minWidth: 180,
      },
      {
        field: 'transactionId',
        headerName: 'Transaction ID',
        minWidth: 180,
      },
      {
        field: 'note',
        headerName: 'Note',
        minWidth: 250,
      },
      currencyFromCentsColumn({
        field: 'groupedTransactionTotalInCents',
        headerName: 'Grouped Transaction Total',
        minWidth: 200,
      }),
      currencyFromCentsColumn({
        field: 'groupedTransactionTotalPlusCreditAppliedInCents',
        headerName: 'Grouped Transaction Total + Credit',
        minWidth: 250,
      }),
      ...extraColumns,
    ];

    return columns;
  }, [
    extraColumns,
    financialAccountOptions,
    schoolYearEndingData?.organizationSchoolYearSelectOptions,
  ]);
};
