// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasPermission } from 'state/self/selectors';

export const useCanQueryFinancialAccounts = () => {
  const canReadFinancialAccounts = useSelector(hasPermission('financialAccounts', 'read'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));
  const canWritePayments = useSelector(hasPermission('payments', 'write'));
  const canEditFinances = useSelector(hasPermission('finances', 'edit'));
  const canEditPayments = useSelector(hasPermission('payments', 'edit'));

  const hasPermissionToQueryFinancialAccounts = canReadFinancialAccounts
    || canReadFinances
    || canReadPayments
    || canWriteFinances
    || canWritePayments
    || canEditFinances
    || canEditPayments;

  return hasPermissionToQueryFinancialAccounts;
};
