// External Dependencies
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Grow,
  Typography,
} from '@mui/material/';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomCheckbox,
  CustomFormButton,
  EnhancedAlert,
  Flex,
  OnboardingStepTitle,
  PrivacyAndTermsLinks,
} from 'components/shared';
import { useAcceptTerms } from 'gql/mutations';

// Local Typings
interface FormValues {
  hasAcceptedTerms: boolean;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.cardContent': {
    '&:last-child': {
      paddingBottom: 12,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  '.h6': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: `${theme.spacing(1.5)} auto`,
    },
    fontSize: '1em',
    margin: '16px auto',
    textAlign: 'center',
  },
  '.section': {
    textAlign: 'left',
  },
}));

// Component Definition
const OnboardingAcceptTerms = () => {
  const [submitError, setSubmitError] = useState('');

  const [
    acceptTerms,
    { loading: isSubmittingAcceptTerms },
  ] = useAcceptTerms();

  // TODO: Find userOrgData where current user is admin
  //  in order to pre-fill the form
  //  This would only happen if some of the values were deleted
  //  Since we are working on new users in Feb 2020, this can wait
  const initialValues: FormValues = {
    hasAcceptedTerms: false,
  };

  const handleSubmitForm = async (values: FormValues) => {
    try {
      acceptTerms({
        variables: {
          input: {
            hasAcceptedTerms: values.hasAcceptedTerms,
          },
        },
      });
    } catch (err) {
      setSubmitError(
        'There was an error accepting terms. Please try again.',
      );
    }
  };

  const validate = (values: FormValues) => {
    if (!values.hasAcceptedTerms) {
      setSubmitError('You must accept terms and privacy policy.');
    }
  };

  return (
    <>
      <OnboardingStepTitle title="Acknowledge Terms" />

      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validate={validate}
      >
        {(formikProps) => {
          const { handleSubmit, isSubmitting } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in>
                <StyledCardContent>
                  <Typography
                    className="h6"
                    variant="h6"
                  >
                    Terms of Service and Privacy Policy
                  </Typography>

                  <section className="section">
                    <Flex>
                      <CustomCheckbox
                        label={`I have read and agree to the ${APP_NAME} Privacy Policy and Terms of Service.`}
                        name="hasAcceptedTerms"
                      />
                    </Flex>
                  </section>

                  <Box my={2}>
                    <PrivacyAndTermsLinks />
                  </Box>

                  <Collapse in={Boolean(submitError)}>
                    <EnhancedAlert severity="error">
                      {submitError}
                    </EnhancedAlert>
                  </Collapse>

                  {isSubmitting || isSubmittingAcceptTerms ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText="Next"
                      className="button"
                      disabled={isSubmitting || isSubmittingAcceptTerms}
                      key="onboarding-org-info-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingAcceptTerms;
