// External Dependencies
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Dependencies
import EnhancedAlert from './EnhancedAlert';
import SupportLink from './SupportLink';

// Local Variables
const StyledEnhancedAlert = styled(EnhancedAlert)(({ theme }) => ({
  '.indent-section': {
    marginLeft: theme.spacing(0),
  },
  '.strong': {
    fontWeight: 500,
  },
}));

// Component Definition
const DistrictIsRequiredAlert = (): JSX.Element => {
  return (
    <StyledEnhancedAlert
      severity="warning"
      title="District Required"
    >
      <>
        <Typography
          component="div"
          paragraph
        >
          Your organization is not part of a district. Let&apos;s get that set up first.
        </Typography>

        <Typography
          className="strong"
          component="div"
        >
          Have a district Fine Arts Administrator?
        </Typography>

        <List dense>
          <ListItem>
            <ListItemText
              primary={(
                <>
                  <Typography
                    className="strong"
                    component="strong"
                  >
                    Yes
                  </Typography>

                  <Typography component="span">
                    {' '}—{' '}
                    Invite that person to follow the steps in{' '}
                    <SupportLink
                      buttonText="this support article"
                      slug="create-a-new-district"
                    />.
                  </Typography>
                </>
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={(
                <>
                  <Typography
                    className="strong"
                    component="strong"
                  >
                    No
                  </Typography>

                  <Typography component="span">
                    {' '}—{' '}
                    You should follow the steps in{' '}
                    <SupportLink
                      buttonText="this unlisted support article"
                      isUnlisted
                      slug="create-a-new-district-as-a-director"
                    />.
                  </Typography>
                </>
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={(
                <Typography>
                  Later you can switch or add administrators for your district
                </Typography>
              )}
            />
          </ListItem>
        </List>
      </>
    </StyledEnhancedAlert>
  );
};

export default DistrictIsRequiredAlert;
