import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

const GridWrapper: FC<PropsWithChildren<any>> = ({ children }) => (
  <Box
    columnGap={8}
    display="grid"
    gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
  >
    {children}
  </Box>
);

export default GridWrapper;
