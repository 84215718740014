// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Stepper } from 'components/shared';

// Local Typings
interface Props {
  activeStep: number;
  maxAvailableStep: number;
  onSetActiveStep: (step: number) => void;
}

// Local Variables
const stepLabels = ['Info', 'Members', 'Items'];

// Component Definition
const ChecklistFormStepper: FC<Props> = ({
  activeStep,
  maxAvailableStep,
  onSetActiveStep,
}) => (
  <Stepper
    activeStep={activeStep}
    maxAvailableStep={maxAvailableStep}
    onSetActiveStep={onSetActiveStep}
    stepLabels={stepLabels}
  />
);

export default ChecklistFormStepper;
