// Internal Dependencies
import { State } from 'state/reducers';

// Selectors
export const useDenseTable = (state: State) => {
  const shouldUseDenseTable = state.ui.quickSettingsMenu.useDenseTable;

  return typeof shouldUseDenseTable === 'string'
    ? JSON.parse(shouldUseDenseTable)
    : shouldUseDenseTable;
};

export const useThemePreference = (state: State) =>
  state.ui.quickSettingsMenu.themePreference;
