// External Dependencies
import { makeStyles } from '@mui/styles';

// Local Typings
interface Props {
  children: any;
}

// Local Variables
const useStyles = makeStyles({
  rowContainer: {
    '& > div': {
      flexGrow: 1,
      marginLeft: 8,
      marginRight: 8,
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: 24,
  },
});

// Component Definition
const FormRow = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      {children}
    </div>
  );
};

export default FormRow;
