// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { getLocalStorageItem } from 'utils/lib/local_storage';

// Local Dependencies
import { updateTableDensity, updateTheme } from './actions';

// Local Typings
export type ThemeOptions = 'light' | 'dark' | 'midnight' | 'slate';
export interface QuickSettingsMenuState {
  themePreference: ThemeOptions;
  useDenseTable: boolean;
}

// Local Variables
export const THEME = 'theme';
export const USE_DARK_MODE = 'useDarkMode';
export const USE_DENSE_TABLE = 'useDenseTable';

const getInitialTableDensity = () => {
  const tableDensity = getLocalStorageItem(USE_DENSE_TABLE);

  return tableDensity ?? false;
};
const getInitialTheme = (): ThemeOptions => {
  const theme = getLocalStorageItem(THEME) as ThemeOptions | undefined;

  const devicePrefersDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches;

  const fallbackTheme = devicePrefersDarkMode ? 'dark' : 'light';

  return theme ?? fallbackTheme;
};

// Reducers
const themePreference = createReducer(
  getInitialTheme(),
  (handleAction) => [
    handleAction(
      updateTheme,
      (
        _state,
        {
          payload,
        }: {
          payload: ThemeOptions;
          type: string;
        },
      ) => payload,
    ),
  ],
);
const useDenseTable = createReducer(
  getInitialTableDensity(),
  (handleAction) => [
    handleAction(
      updateTableDensity,
      (_state, { payload }: ReduxData.IAction) => payload,
    ),
  ],
);

export default combineReducers<QuickSettingsMenuState>({
  themePreference,
  useDenseTable,
});
