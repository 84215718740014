// External Dependencies
import { DistrictLibraryIndexResponseItem } from '@presto-assistant/api_types/api/v1/library';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { useGetDistrictOrganizations } from 'gql/queries';

export const useColumns = ({
  extraColumns,
}: {
  extraColumns?: DataGridColDef<DistrictLibraryIndexResponseItem>[];
}) => {
  const {
    data: organizationData,
  } = useGetDistrictOrganizations();

  const organizations = useMemo(() => {
    return organizationData?.districtOrganizations ?? [];
  }, [organizationData]);

  const organizationOptions = useMemo(() => {
    return organizations.map((organization) => ({
      label: organization.label,
      value: organization.id,
    }));
  }, [organizations]);

  return useMemo(() => {
    const columns: DataGridColDef<DistrictLibraryIndexResponseItem>[] = [
      {
        field: 'organizationId',
        headerName: 'Organization Assigned To',
        minWidth: 292,
        renderCell: (params) => params.row.organizationLabel,
        type: 'singleSelect',
        valueGetter: (params) => params.row.organizationId,
        valueOptions: organizationOptions,
      },
      {
        field: 'categoryLabel',
        headerName: 'Category',
      },
      {
        field: 'instrumentationLabel',
        headerName: 'Voice/Instrumentation',
        width: 160,
      },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 292,
      },
      {
        field: 'composer',
        headerName: 'Composer',
      },
      {
        field: 'arranger',
        headerName: 'Arranger',
      },
      {
        field: 'number',
        headerName: 'Number',
      },
      {
        field: 'year',
        headerName: 'Publication Date',
        type: 'number',
        valueFormatter: (params) => params.value ?? '',
      },
      {
        field: 'grade',
        headerName: 'Grade',
        type: 'number',
      },
      {
        field: 'comments', // TODO: deal with dynamic fields named comments
        headerName: 'Comments',
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    organizationOptions,
  ]);
};
