// External Dependencies
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

// Internal Dependencies
import {
  ListItemWithSecondaryAction,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  userFullName: string;
  userId: string;
}

// Component Definition
const AddCreditListItem: FC<Props> = ({
  userFullName,
  userId,
}) => (
  <ListItemWithSecondaryAction
    primaryText="Add a new credit"
    secondaryAction={{
      buttonIcon: <AddIcon />,
      buttonText: 'Credit',
      to: `/${PATHS.FINANCIAL_CREDITS_NEW}?userId=${userId}`,
    }}
    secondaryText={`You can add another credit for ${userFullName} from this page.`}
  />
);

export default AddCreditListItem;
