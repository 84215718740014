// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import PaymentFormByItem from '../shared/PaymentFormByItem';
import PaymentFormByUser from '../shared/PaymentFormByUser';
import PaymentFormGateway from '../shared/PaymentFormGateway';

// Component Definition
const FinancialPaymentNew = ():JSX.Element => {
  const parsedSearch = useParsedSearch();

  // We allow other parts of the app to send a memberId to kick off a new payment
  const {
    financialItemId,
    userId,
  } = parsedSearch;

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialPaymentsParams = useSelector(tableQueryParams(`financialPayments-${schoolYearEnding}`));

  return (
    <Page
      backButtonProps={{
        label: 'Payments',
        path: `/${PATHS.FINANCIAL_PAYMENTS}${financialPaymentsParams}`,
      }}
    >
      {!financialItemId && !userId && (
        <PaymentFormGateway />
      )}

      {userId && (
        <PaymentFormByUser userId={userId} />
      )}

      {financialItemId && (
        <PaymentFormByItem financialItemId={financialItemId} />
      )}
    </Page>
  );
};

export default FinancialPaymentNew;
