// External Dependencies
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialFundraiserIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';

// Component Definition
const FinancialFundraiserCreditsTable = () => {
  const columns = useColumns();

  const hasPermissionToAddPayments = useSelector(hasPermission('payments', 'write'));

  const handleTableClickRow = useCallback((id: string) => {
    // Applys a default filter of students to the FinancialFundraiserCreditMembersTable in the url
    const model = {
      items: [
        {
          field: 'roleLabel',
          operator: 'is',
          value: 'Student',
        },
      ],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [
        '',
      ],
    };

    const filterModel = encodeURIComponent(JSON.stringify(model));
    return `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${id}?dataGridFilters=${filterModel}`;
  }, []);

  const {
    data,
    isLoading,
  } = useGetFinancialFundraiserIndex();

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={hasPermissionToAddPayments ? {
          label: 'Fundraiser Credit',
          to: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS_NEW}`,
        } : null}
        clickRowTo={handleTableClickRow}
        columns={columns}
        components={{
          NoRowsOverlay: TableDataGridZeroState,
        }}
        loading={isLoading}
        rows={data}
        tableResource="financialFundraiserCredits"
        withSearch
      />
    </DataGridContainer>
  );
};

export default FinancialFundraiserCreditsTable;
