// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Typings
interface Props {
  bouncedEmailData: GQL.IUser['bouncedEmail'] | undefined;
}

// Local Variables
const StyledEnhancedAlert = styled(EnhancedAlert)(({ theme }) => ({
  '.MuiCardContent-root': {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },

  marginBottom: theme.spacing(2),
}));

// Component Definition
const BouncedEmailAlert: FC<Props> = ({ bouncedEmailData }) => {
  const navigate = useNavigate();

  const handleClickManageSuppressedEmails = useCallback(() => {
    navigate(`/${PATHS.EMAIL_SUPPRESSIONS}?q=${encodeURIComponent(bouncedEmailData?.email ?? '')}`);
  }, [bouncedEmailData?.email, navigate]);

  if (!bouncedEmailData) {
    return null;
  }

  return (
    <StyledEnhancedAlert
      action={(
        <Button
          key="manageEmailAction"
          onClick={handleClickManageSuppressedEmails}
          sx={{ mr: 1 }}
        >
          Manage
        </Button>
      )}
      severity="error"
      title="Bounced Email"
    >
      The email address for this user ({bouncedEmailData.email}) has bounced due to:

      <Box marginTop={0.5}>
        <CardContent>
          <code>
            {bouncedEmailData.reason}
          </code>
        </CardContent>
      </Box>
    </StyledEnhancedAlert>
  );
};

export default BouncedEmailAlert;
