// External Dependencies
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { useTheme } from 'styled-components';
import startCase from 'lodash.startcase';

// Local Typings
interface Props<T> {
  data: T[] | undefined;
  tooltipProps?: TooltipProps<any, any>;
  xAxisProps?: XAxisProps;
  xKey: string;
  yAxisProps?: YAxisProps;
  yKey: string;
}

// Component Definition
function AreaLineChart<T>({
  data,
  tooltipProps,
  xAxisProps,
  xKey,
  yAxisProps,
  yKey,
}: Props<T>) {
  const theme = useTheme();

  return (
    <ResponsiveContainer
      height={300}
      width="100%"
    >
      <ComposedChart data={data}>
        <defs>
          <linearGradient
            id="gradient-color"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="5%"
              stopColor={theme.palette.prestoPrimaryDark}
              stopOpacity={0.5}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.prestoPrimaryLight}
              stopOpacity={0.01}
            />
          </linearGradient>
        </defs>

        <XAxis
          dataKey={xKey}
          {...xAxisProps}
        />
        <YAxis
          allowDecimals={false}
          {...yAxisProps}
        />
        <CartesianGrid
          stroke={theme.palette.grey[300]}
          strokeDasharray="3 3"
        />
        <Tooltip {...tooltipProps} />

        {/* prevents weird animation with line coming in from right side */}
        {/* https://stackoverflow.com/questions/49756463/rechart-animation-for-linechart-bringing-in-lines-from-right-side#answer-50050246 */}
        {(data?.length ?? 0) > 0 && (
          <Line
            animationDuration={500}
            dataKey={yKey}
            dot={false}
            name={startCase(yKey)}
            stroke={theme.palette.prestoPrimaryDark}
            type="monotone"
          />
        )}
        <Area
          animationDuration={500}
          dataKey={yKey}
          fill="url(#gradient-color)"
          fillOpacity={1}
          strokeWidth={2}
          tooltipType="none"
          type="monotone"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default AreaLineChart;
