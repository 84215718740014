// External Dependencies
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  EnhancedAlert,
  OnlinePaymentsLearnMoreLink,
} from 'components/shared';
import { useGetOrganization } from 'gql/queries';

// Component Definition
const OnlinePaymentsAlert = (): JSX.Element => {
  const { data: organizationData } = useGetOrganization();

  const organization = organizationData?.organization;
  const district = organization?.district;

  const areDistrictOnlinePaymentsEnabled = Boolean(
    organizationData?.organization.district?.areOnlinePaymentsEnabled,
  );

  return (
    <EnhancedAlert
      isTip
      severity="info"
    >
      <Typography variant="body2">
        Set up Online Payments for
        {' '}
        {areDistrictOnlinePaymentsEnabled
          ? organization?.label
          : (
            <>
              {[district?.label, organization?.label].filter(Boolean).join(' and ')}
            </>
          )}
        {' '}
        using Stripe or Vanco.
        <OnlinePaymentsLearnMoreLink />
      </Typography>
    </EnhancedAlert>
  );
};

export default OnlinePaymentsAlert;
