// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  clearForm,
  updateForm,
} from './actions';

// Local Typings
export interface EditFinancialPaymentItemFormState {
  financialPaymentItem: GQL.IFinancialItem | {};
}

// Reducers
const FORM_INITIAL_STATE: EditFinancialPaymentItemFormState['financialPaymentItem'] = {};
const financialPaymentItem = createReducer(FORM_INITIAL_STATE, (handleAction) => [
  handleAction(updateForm, (state, { payload }: ReduxData.IAction) => payload),
  handleAction([
    clearForm,
    logoutCurrentUser,
  ], () => FORM_INITIAL_STATE),
]);

export default combineReducers<EditFinancialPaymentItemFormState>({
  financialPaymentItem,
});
