// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface OrganizationRequest {
  id: GQL.IInvitationToDfa['id'];
  invitedAt: GQL.IInvitationToDfa['invitedAt'];
  invitedBy: GQL.IInvitationToDfa['invitedBy'];
  organization: GQL.IInvitationToDfa['organization'];
}
export interface Invitation {
  dfaEmail: GQL.IInvitationToDfa['dfaEmail'];
  id: GQL.IInvitationToDfa['id'];
  invitedAt: GQL.IInvitationToDfa['invitedAt'];
  invitedBy: GQL.IInvitationToDfa['invitedBy'];
  organization: GQL.IInvitationToDfa['organization'];
}
interface GetMyOrganizationResponse {
  myOrganizationRequests: OrganizationRequest[];
}
interface GetMySentInvitationsToDfaResponse {
  mySentInvitationsToDfa: Invitation[];
}

export const GET_MY_ORGANIZATIONS_REQUESTS = gql`
  query MyOrganizationRequests {
    myOrganizationRequests {
      id
      invitedAt
      invitedBy {
        id
        email
        firstName
        lastName
      }
      organization {
        entityType {
          id
          label
        }
        id
        label
        organizationType {
          id
          label
        }
      }
    }
  }
`;

export const GET_MY_SENT_INVITATIONS_TO_DFA = gql`
  query MySentInvitationsToDfa {
    mySentInvitationsToDfa {
      dfaEmail
      id
      invitedAt
      invitedBy {
        id
        email
        firstName
        lastName
      }
      organization {
        id
        label
      }
    }
  }
`;

export const useGetMyOrganizationRequests = () =>
  useQueryEnhanced<GetMyOrganizationResponse>(GET_MY_ORGANIZATIONS_REQUESTS);

export const useGetMySentInvitationsToDfa = () =>
  useQueryEnhanced<GetMySentInvitationsToDfaResponse>(GET_MY_SENT_INVITATIONS_TO_DFA);
