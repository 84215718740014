// External Dependencies
import { MutationEnhancedOptions, useMutationEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
interface ImportResponseData {
  createImport: boolean;
}

// Local Variables
export const CREATE_IMPORT = gql`
  mutation CreateImport(
    $input: CreateImportInput!
  ) {
    createImport(
      input: $input
    )
  }
`;

export const useCreateImport = (
  options?: MutationEnhancedOptions<
    ImportResponseData,
    GQL.ICreateImportOnMutationArguments
  >,
) => useMutationEnhanced<
  ImportResponseData,
  GQL.ICreateImportOnMutationArguments
>(
  CREATE_IMPORT,
  options,
);
