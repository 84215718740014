// External Dependencies
import { FC } from 'react';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import { ConfirmationDialog, EnhancedIconButton } from 'components/shared';
import { SELECTION_TYPES } from 'utils/constants';
import { useIsOpen } from 'hooks/useIsOpen';
import { useRemoveMembersFromSingleGroup } from 'gql/mutations';

// Local Typings
interface Props {
  groupId: string;
  groupName: string;
  memberName: string;
  userId: string;
}

// Component Definition
const RemoveFromGroupButton: FC<Props> = ({
  groupId,
  groupName,
  memberName,
  userId,
}) => {
  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: handleToggleIsDeleteDialogOpen,
  } = useIsOpen();

  const [
    removeMemberFromGroup,
    {
      loading: isSubmitting,
    },
  ] = useRemoveMembersFromSingleGroup({
    onCompleted: handleToggleIsDeleteDialogOpen,
  });

  const handleConfirmDelete = () => {
    removeMemberFromGroup({
      variables: {
        id: groupId,
        selection: {
          ids: [userId],
          mutationFlag: SELECTION_TYPES.SELECTED_ONE as GQL.MutationFlag,
          queryParams: {},
        },
      },
    });
  };

  return (
    <>
      <EnhancedIconButton
        icon={<RemoveIcon />}
        onClick={handleToggleIsDeleteDialogOpen}
        tooltip="Remove from group"
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmDelete}
        confirmButtonText="Yes, Remove"
        declineButtonAction={handleToggleIsDeleteDialogOpen}
        description={`This will remove ${memberName} from ${groupName}.`}
        handleClose={handleToggleIsDeleteDialogOpen}
        isSubmitting={isSubmitting}
        open={isDeleteDialogOpen}
        title="Remove from group?"
      />
    </>
  );
};

export default RemoveFromGroupButton;
