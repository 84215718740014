// External Dependencies
import { FinancialTransactionReportResponseItem } from '@presto-assistant/api_types/api/v1/financialTransaction';
import { GridRowId } from '@mui/x-data-grid-pro';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { apiClient } from 'utils/apiClient';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { formatParamDate, getDaysAgo } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { navigateSearch } from 'utils/lib/navigate_search';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialTransactionsTableZeroState from './FinancialTransactionsTableZeroState';

// Local Typings
interface Props {
  onChangeTotalInCents: (totalInCents: number) => void;
}
type DateSelection = '7' | '30' | '60' | 'all';

// Local Variables
const handleGetRowId = (row: FinancialTransactionReportResponseItem) => `${row.type}-${row.paymentId}`;
const dataSelector = (res: FinancialTransactionReportResponseItem[]) => res;

// Component Definition
const FinancialTransactionsTable = ({
  onChangeTotalInCents,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const parsedSearch = useParsedSearch();

  const searchDateSelection = parsedSearch.dateSelection;

  const [dateSelection, setDateSelection] = useState<DateSelection>(searchDateSelection?.toString() ?? '7');
  const [filteredRowIds, setFilteredRowIds] = useState<GridRowId[]>([]);

  const startDateUtc = useMemo(() => {
    if (dateSelection !== 'all') {
      return formatParamDate(getDaysAgo(Number(dateSelection)), { isUTC: true });
    }

    return null;
  }, [dateSelection]);

  const handleSetDateSelection = useCallback((value: DateSelection) => () => {
    setDateSelection(value);
  }, []);

  useEffect(() => {
    if (searchDateSelection !== dateSelection) {
      navigateSearch(navigate, { dateSelection }, { replace: true });
    }
  }, [dateSelection, navigate, searchDateSelection]);

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.financialTransaction.report({
      ...queryParams,
      startDateUtc,
    });
  }, [startDateUtc]);

  const {
    data,
    isLoading,
  } = useInfinitePaginatedListQuery<
    FinancialTransactionReportResponseItem[],
    FinancialTransactionReportResponseItem
  >({
    dataSelector,
    pageSize: 500,
    queryKey: [`financialTransactionReport-${dateSelection}`],
    request: fetchData,
  });

  const canReadMembers = useSelector(hasPermission('users', 'read'));

  const canReadFinances = useSelector(hasPermission('finances', 'read'));

  const handleClickViewPayment = useCallback((row: FinancialTransactionReportResponseItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}/${row.paymentId}`);
  }, [navigate]);

  const handleClickViewMember = useCallback((row: FinancialTransactionReportResponseItem) => {
    navigate(`/${PATHS.MEMBERS}/${row.memberId}`);
  }, [navigate]);

  const handleClickViewTransaction = useCallback((row: FinancialTransactionReportResponseItem) => {
    navigate(`/${PATHS.FINANCIAL_TRANSACTIONS}/${row.transactionId}`);
  }, [navigate]);

  const extraColumns = useMemo<DataGridColDef<FinancialTransactionReportResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<FinancialTransactionReportResponseItem>([
        {
          action: handleClickViewPayment,
          icon: <LocalAtmIcon />,
          text: 'View Payment',
        },
        ...(canReadFinances ? [{
          action: handleClickViewTransaction,
          icon: <RequestQuoteIcon />,
          text: 'View Financial Transaction',
        }] : []),
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View Member',
        }] : []),
      ]) as DataGridColDef<FinancialTransactionReportResponseItem>;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canReadFinances,
      canReadMembers,
      handleClickViewPayment,
      handleClickViewMember,
      handleClickViewTransaction,
    ],
  );

  const columns = useColumns(extraColumns);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .filter((row) => filteredRowIds.includes(handleGetRowId(row)));
  }, [data, filteredRowIds]);

  const totalInCents = useMemo(() => {
    return filteredData
      .reduce((acc, row) => acc + row.paymentAmountInCents, 0);
  }, [filteredData]);

  useEffect(() => {
    onChangeTotalInCents(totalInCents);
  }, [onChangeTotalInCents, totalInCents]);

  return (
    <>
      <ButtonGroup
        size="small"
        sx={{ margin: 1 }}
      >
        <Button
          onClick={handleSetDateSelection('7')}
          variant={dateSelection === '7' ? 'contained' : 'outlined'}
        >
          7 Days
        </Button>

        <Button
          onClick={handleSetDateSelection('30')}
          variant={dateSelection === '30' ? 'contained' : 'outlined'}
        >
          30 Days
        </Button>

        <Button
          onClick={handleSetDateSelection('60')}
          variant={dateSelection === '60' ? 'contained' : 'outlined'}
        >
          60 Days
        </Button>

        <Button
          onClick={() => setDateSelection('all')}
          variant={dateSelection === 'all' ? 'contained' : 'outlined'}
        >
          All
        </Button>
      </ButtonGroup>

      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          components={{
            NoRowsOverlay: FinancialTransactionsTableZeroState,
          }}
          getRowId={handleGetRowId}
          loading={isLoading}
          onFilter={setFilteredRowIds}
          rows={data ?? []}
          tableResource="financialTransactions"
          withSearch
        />
      </DataGridContainer>
    </>
  );
};

export default FinancialTransactionsTable;
