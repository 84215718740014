// External Dependencies
import { FC } from 'react';
import { Genders } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  DatePickerField,
  GenderSelect,
  GradeSelect,
  ShirtSizeSelect,
  SpokenLanguageSelect,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';
import SuccessorOrganizationSelect from 'components/shared/Selectors/SuccessorOrganizationSelect';

// Local Dependencies
import { StudentValues } from '../Students/shared/StudentForm';

// Local Typings
interface Props {
  genderId?: StudentValues['genderId'];
  isDfa?: boolean;
  isDirector?: boolean;
  isEditing?: boolean;
  isStudent?: boolean;
}

// Component Definition
const ProfileInfoFields: FC<Props> = ({
  genderId,
  isDfa,
  isDirector = false,
  isEditing,
  isStudent,
}) => {
  const {
    data: organizationData,
  } = useGetOrganization();

  const successorOrganization = organizationData?.organization.successorOrganization;

  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  return (
    <>
      <CustomInput
        label="First Name *"
        name="firstName"
      />

      <CustomInput
        label="Middle Name"
        name="middleName"
      />

      <CustomInput
        label="Last Name *"
        name="lastName"
      />

      {isDirector && !isDfa && (
        <CustomInput
          label="Title"
          name="title"
          placeholder="Director"
        />
      )}

      {!isDfa && (
        <>
          <GenderSelect />

          <Collapse in={genderId === Genders.Other.toString()}>
            <CustomInput
              label="Other Gender Label"
              name="otherGenderLabel"
              variant="filled"
            />
          </Collapse>
        </>
      )}

      {!isDfa && <SpokenLanguageSelect />}

      {isStudent && canEditUsers && !isDfa && <GradeSelect />}

      {!isDfa && (
        <DatePickerField
          label="Date of Birth"
          name="dateOfBirth"
        />
      )}

      {!isDfa && <ShirtSizeSelect />}

      {(isStudent && canEditUsers) ? (
        <>
          <CustomInput
            label="Student ID"
            name="studentInfo.studentId"
          />

          <CustomInput
            label="Previous Campus"
            name="previousCampus"
          />

          <Box marginLeft={1}>
            <CustomCheckbox
              label="Is Eligible?"
              name="isEligible"
            />
          </Box>

          {/* TODO: we can remove the isEditing condition when the */}
          {/* API allows successorOrganizationId on create */}
          {isEditing && successorOrganization && (
            <SuccessorOrganizationSelect
              helperText="Select a successor organization for this student."
            />
          )}

          <CustomInput
            label="Lesson Teacher"
            name="privateLessonTeacher"
          />
        </>
      ) : null}

      {!isDfa && (
        <CustomInput
          label="Allergies"
          name="allergies"
        />
      )}
    </>
  );
};

export default ProfileInfoFields;
