// External Dependencies
import { FC, useState } from 'react';
import {
  Grow,
  IconButton,
  TextField,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

// Local Typings
interface Props {
  context?: string;
  disableSearch?: boolean;
  onClearSearch: () => void;
  onSearch: (value: string) => void;
  searchTerm?: string | null;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.iconButton': {
    fontSize: '1.25rem',
    padding: 8,
  },
  '.input': {
    boxSizing: 'border-box',
    flex: 1,
  },

  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  width: 224,

  [theme.breakpoints.down('lg')]: {
    width: 200,
  },
  [theme.breakpoints.down('md')]: {
    width: 150,
  },
}));

// Component Definition
const ToolbarSearch: FC<Props> = ({
  context = '',
  disableSearch = false,
  onClearSearch,
  onSearch,
  searchTerm = '',
}) => {
  const [searchValue, setSearchValue] = useState(searchTerm);
  const [searchInputOpen, setSearchInputOpen] = useState(Boolean(searchTerm));

  const handleBlur = () => {
    if (!searchValue) {
      setSearchInputOpen(false);
    }
  };

  const handleToggleSearchInput = () => {
    if (searchInputOpen) {
      onClearSearch();
      setSearchValue('');
    }
    return setSearchInputOpen(!searchInputOpen);
  };

  const [debouncedCallback] = useDebouncedCallback(
    (value: string) => {
      onSearch(encodeURIComponent(value));
    },
    800,
  );

  const handleUpdateSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncedCallback(value);
  };

  const placeholder = `Search${context && ` ${context}`}`;

  return (
    <StyledRoot>
      <Grow
        in={searchInputOpen}
        style={{ transformOrigin: 'center right' }}
        unmountOnExit
        {...(searchInputOpen ? { timeout: 300 } : {})}
      >
        <TextField
          autoFocus
          className="input"
          inputProps={{ 'aria-label': placeholder }}
          margin="none"
          onBlur={handleBlur}
          onChange={handleUpdateSearchTerm}
          placeholder={placeholder}
          size="small"
          value={searchValue}
        />
      </Grow>

      <IconButton
        aria-label="Search"
        className="iconButton"
        disabled={disableSearch}
        onClick={handleToggleSearchInput}
        size="large"
      >
        {searchInputOpen
          ? <CloseIcon fontSize="small" />
          : <SearchIcon fontSize="small" />}
      </IconButton>
    </StyledRoot>
  );
};

export default ToolbarSearch;
