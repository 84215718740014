// External Dependencies
import { FC, ReactElement } from 'react';
import { useField } from 'formik';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import styled from 'styled-components';

// Local Typings
interface Props {
  alignHoverEdge?: boolean;
  className?: string;
  helperText?: string;
  label: string | ReactElement;
  name: string;
}

// Local Variables
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.label': {
    marginLeft: theme.spacing(1),
  },
}));

// Component Definition
const CustomCheckbox: FC<Props & CheckboxProps> = ({
  alignHoverEdge,
  className,
  helperText,
  inputProps = {},
  label,
  name,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(name);

  const hasError = !!meta.error;
  const isTouched = !!meta.touched;

  return (
    <FormControl
      className={className}
      component="fieldset"
      error={isTouched && hasError}
      required
    >
      <StyledFormControlLabel
        classes={{
          label: 'label',
        }}
        control={(
          <Checkbox
            color="primary"
            {...field}
            {...props}
            checked={field.value === true || field.value === 'true'} // sometimes the value is a string, such as dynamic form blocks
            inputProps={{
              ...inputProps,
              'aria-readonly': readOnly,
              readOnly,
            }}
            onChange={readOnly ? undefined : field.onChange}
          />
        )}
        label={label}
        sx={{ marginLeft: alignHoverEdge ? 0 : undefined }}
      />

      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}

      {isTouched && hasError && (
        <FormHelperText error>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomCheckbox;
