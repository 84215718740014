// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount, getFullName } from 'utils';
import { useGetFees } from 'gql/queries';
import useTextField from 'hooks/useTextField';

// Local Variables
const StyledAutocomplete = styled(Autocomplete)({
  '& input': {
    textOverflow: 'initial',
  },
}) as typeof Autocomplete;

// Local Typings
interface Props {
  fetchPolicy?: WatchQueryFetchPolicy;
  financialItemId: string;
  hiddenIds?: string[];
  onSelectFee: (fee: GQL.IFinancialFee | null) => any;
  selectedId?: string;
  textFieldProps?: Partial<TextFieldProps>;
}

// Component Definition
const FinancialFeeTypeAhead: FC<Props> = ({
  fetchPolicy,
  financialItemId,
  hiddenIds = [],
  onSelectFee,
  selectedId,
  textFieldProps,
}) => {
  const textField = useTextField('');

  const {
    getFees,
    results: {
      data: allFeesData,
      loading: isLoading,
    },
  } = useGetFees({ fetchPolicy });

  const handleChange = (_evt: any, fee: GQL.IFinancialFee | null) => {
    onSelectFee(fee);
  };

  const defaultFee = useMemo(
    () => allFeesData?.financialFees.data
      .find((fee) => fee.id === selectedId),
    [allFeesData?.financialFees, selectedId],
  );

  useEffect(() => {
    getFees({ variables: { where: { financialItemId } } });
  }, [getFees, financialItemId]);

  const trimmedTextValue = textField.value.trim().toLowerCase();

  return (
    <StyledAutocomplete
      disablePortal
      filterOptions={(fee) => fee}
      getOptionDisabled={(fee) => fee.remainingBalanceInCents <= 0}
      getOptionLabel={(fee) => `${getFullName(fee.user)} - ${fee.user.email}`}
      key={defaultFee?.id ?? 'no-fee'}
      loading={isLoading}
      onChange={handleChange}
      options={(allFeesData?.financialFees.data ?? [])
        .filter((option: GQL.IFinancialFee) => {
          if (hiddenIds.includes(option.id)) {
            return false;
          }

          // Do not show paid fees unless they specifically type for it
          if (!trimmedTextValue && option.remainingBalanceInCents <= 0) {
            return false;
          }

          const { user: { email, firstName, lastName } } = option;

          const matches = [
            email.toLowerCase(),
            `${firstName} ${lastName}`.toLowerCase(),
            `${lastName} ${firstName}`.toLowerCase(),
          ];

          return matches.some((match) => match.includes(trimmedTextValue));
        }).slice(0, 12)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textField}
          label="Search Users"
          variant="filled"
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const remainingPriceString = displayPriceStringFromDollarAmount(
          convertCentsToDollars(option.remainingBalanceInCents),
        );

        const userFullName = getFullName(option.user);

        return (
          <ListItem {...props}>
            <ListItemText
              primary={userFullName}
              secondary={(
                <>
                  {remainingPriceString} remaining
                  <br />
                  {option.user.email}
                </>
              )}
            />
          </ListItem>
        );
      }}
      value={defaultFee}
    />
  );
};

export default FinancialFeeTypeAhead;
