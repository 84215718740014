// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { setLocalStorageItem } from 'utils/lib/local_storage';

// Local Dependencies
import {
  THEME, ThemeOptions, USE_DENSE_TABLE,
} from './reducers';

// Actions
export const updateTableDensity = createActionCreator(
  'UPDATE_TABLE_DENSITY',
  (resolve) => (updatedTableDensity: boolean) => {
    setLocalStorageItem(USE_DENSE_TABLE, JSON.stringify(updatedTableDensity));
    return resolve(updatedTableDensity);
  },
);

export const updateTheme = createActionCreator(
  'UPDATE_THEME',
  (resolve) => (updatedTheme: ThemeOptions) => {
    setLocalStorageItem(THEME, updatedTheme);
    return resolve(updatedTheme);
  },
);
