// External Dependencies
import {
  FocusEvent, KeyboardEvent, useCallback,
} from 'react';
import Autocomplete, {
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField, { FilledTextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props extends Partial<FilledTextFieldProps> {
  chips: string[];
  onUpdateChips: (chips: string[]) => void;
}
// Local Variables
const StyledAutocomplete = styled(Autocomplete)({
  '.MuiAutocomplete-popper': {
    display: 'none',
  },
});

// Component Definition
const InputWithChips = ({
  chips,
  onUpdateChips,
  ...textFieldProps
}: Props): JSX.Element => {
  const textField = useTextField('');

  const handleDelete = useCallback((
    item: string,
    onDelete: ((event: any) => void),
  ) => () => {
    // Remove the chip from the chips array in Formik state.
    onUpdateChips(chips.filter((chip) => chip !== item));

    // Execute the onDelete function from the getTagProps function.
    // This removes the chip from the UI.
    onDelete(item);
  }, [chips, onUpdateChips]);

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.trim()) {
      onUpdateChips([...chips, value.trim()]);
    }

    textField.onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips, onUpdateChips, textField.onReset]);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { value } = event.currentTarget;

      if (value && !chips.includes(value)) {
        onUpdateChips([...chips, value]);
      }

      textField.onReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips, onUpdateChips, textField.onReset]);

  return (
    <StyledAutocomplete
      disableClearable
      disablePortal
      freeSolo
      id="form-multiple-choice-options"
      multiple
      // Prevent the Autocomplete's popover from appearing.
      // The popover makes this feature much more complicated.
      open={false}
      options={chips}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            onBlur: handleBlur,
            onKeyDown: handleKeyDown,
            ...params.InputProps,
            ...textField,
          }}
          helperText="Press enter to add an option."
          variant="filled"
          {...textFieldProps}
          onKeyDown={(event: any) => {
            // Prevent Backspace or Delete from removing the chips.
            // It's a nice feature, but not fun when mixed with the Formik state.
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation();
            }
          }}
        />
      )}
      renderTags={(values: unknown[], getTagProps: AutocompleteRenderGetTagProps) => {
        return values.map((value: unknown, index: number) => {
          const tagProps = getTagProps({ index });

          if (typeof value !== 'string') {
            return null;
          }

          return (
            // The `key` is provided by the getTagProps function
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={value}
              {...tagProps}
              onDelete={handleDelete(value, tagProps.onDelete)}
            />
          );
        });
      }}
    />
  );
};

export default InputWithChips;
