// External Dependencies
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  id: '',
};

// Component Definition
const Form = ({
  children,
  id,
  onSubmit,
}) => {
  function handleSubmit(evt) {
    evt.preventDefault();
    onSubmit();
  }

  return (
    <form
      id={id || undefined}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
