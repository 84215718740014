// External Dependencies
import {
  CreateFundraiserCreditAssignmentRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { FC, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';
import { convertCentsToDollars, convertDollarsToCents } from 'utils';
import { useCreateFundraiserCreditAssignment } from 'utils/api/financialFundraiserCredit';

// Local Dependencies
import AddAssignmentForm from './AddAssignmentForm';

// Local Typings
interface Props {
  assignedMemberIds: string[];
  financialFundraiserCreditId: string;
  isOpen: boolean;
  memberId: string | null;
  onClose: () => void;
  requiredAmountInCents: number | null;
}

// Component Definition
const AddAssignmentDialog: FC<Props> = ({
  assignedMemberIds,
  financialFundraiserCreditId,
  isOpen,
  memberId,
  onClose,
  requiredAmountInCents,
}) => {
  const dispatch = useDispatch();

  const {
    isLoading: isSubmitting,
    mutate: createFundraiserCreditAssignment,
  } = useCreateFundraiserCreditAssignment(financialFundraiserCreditId);

  const handleSuccess = useCallback(() => {
    dispatch(addNotification('Successfully added assignment', 'success'));
    onClose();
  }, [dispatch, onClose]);

  const handleFormikSubmit = useCallback((values: CreateFundraiserCreditAssignmentRequest) => {
    createFundraiserCreditAssignment({
      amountInCents: convertDollarsToCents(values.amountInCents),
      memberId: values.memberId,
    }, {
      onSuccess: handleSuccess,
    });
  }, [createFundraiserCreditAssignment, handleSuccess]);

  const initialValues = useMemo<CreateFundraiserCreditAssignmentRequest>(() => {
    return {
      // this will be converted back to cents on submission
      amountInCents: convertCentsToDollars(requiredAmountInCents ?? 0),
      memberId: memberId ?? '',
    };
  }, [memberId, requiredAmountInCents]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Add Assignment
      </DialogTitle>

      <DialogContent sx={{ marginTop: 0 }}>
        <DialogContentText>
          Assign a member to this fundraiser credit.
        </DialogContentText>
      </DialogContent>

      <Formik<CreateFundraiserCreditAssignmentRequest>
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
      >
        {() => (
          <AddAssignmentForm
            assignedMemberIds={assignedMemberIds}
            isSubmitting={isSubmitting}
            memberId={memberId}
            onClose={onClose}
            requiredAmountInCents={requiredAmountInCents}
          />
        )}
      </Formik>
    </Dialog>
  );
};

export default AddAssignmentDialog;
