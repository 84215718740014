// External Dependencies
import { Ref, forwardRef } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import styled from 'styled-components';

// Local Dependencies
import EnhancedCardContent from './EnhancedCardContent';

// Local Typings
interface Props extends CardProps {
  useCardContent?: boolean;
  useMaterialV3?: boolean;
}
interface StyledCardProps {
  $useMaterialV3: boolean;
}

// Local Variables
const StyledCard = styled(Card)<StyledCardProps>(({
  $useMaterialV3,
  theme,
}) => ({
  borderRadius: $useMaterialV3
    ? 12
    : theme.shape.borderRadius,
}));

// Component Definition
const EnhancedCard = forwardRef((
  props: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    children,
    useCardContent,
    useMaterialV3 = false,
    ...otherProps
  } = props;

  const childrenNode = useCardContent ? (
    <EnhancedCardContent>
      {children}
    </EnhancedCardContent>
  ) : children;

  return (
    <StyledCard
      {...otherProps}
      $useMaterialV3={useMaterialV3}
      ref={ref}
      variant="outlined"
    >
      {childrenNode}
    </StyledCard>
  );
});

export default EnhancedCard;
