// External Dependencies
import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import styled, { keyframes } from 'styled-components';

// Local Variables
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AnimatedCached = styled(AutorenewIcon)`
  animation: ${rotate} 1200ms linear infinite;
`;

// Component Definition
const RefreshingIcon: FC<SvgIconProps> = ({ ...otherProps }) =>
  <AnimatedCached {...otherProps} />;

export default RefreshingIcon;
