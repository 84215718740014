// External Dependencies
import SvgIcon from '@mui/material/SvgIcon';
import styled, { useTheme } from 'styled-components';

// Local Variables
const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(20),
}));

// Component Definition
const PrestoLogoTextAssistantOnly = (props: any) => {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      htmlColor={theme.palette.logo}
      viewBox="160 0 230 24"
      {...props}
    >
      <g>
        <path
          d="M162.17,31.29V8.16c0-4.82,3-8,7.36-8s7.35,3.21,7.35,8V31.29a.41.41,0,0,1-.44.44h-1.79a.41.41,0,0,1-.44-.44V20.41h-9.36V31.29a.42.42,0,0,1-.45.44h-1.78A.42.42,0,0,1,162.17,31.29Zm12-13.37V8.16c0-3.48-1.7-5.53-4.68-5.53s-4.68,2.05-4.68,5.53v9.76Z"
        />
        <path
          d="M190.17,23.71a.41.41,0,0,1,.44-.44h1.79a.41.41,0,0,1,.44.44c.14,3.92,1.78,6,4.82,6s4.72-1.6,4.72-4.68c0-7.62-12-9.31-12-18,0-4.15,2.85-6.91,7.18-6.91,4.05,0,6.86,3.3,7.08,8.24a.42.42,0,0,1-.44.45h-1.79a.42.42,0,0,1-.44-.45c-.18-3.92-1.74-5.75-4.41-5.75-2.95,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.39,7.18S190.3,28.79,190.17,23.71Z"
        />
        <path
          d="M217.18,23.71a.42.42,0,0,1,.45-.44h1.78a.42.42,0,0,1,.45.44c.13,3.92,1.78,6,4.81,6s4.72-1.6,4.72-4.68c0-7.62-12-9.31-12-18,0-4.15,2.86-6.91,7.18-6.91,4.06,0,6.86,3.3,7.09,8.24a.43.43,0,0,1-.45.45h-1.78a.43.43,0,0,1-.45-.45c-.18-3.92-1.74-5.75-4.41-5.75-2.94,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.4,7.18S217.32,28.79,217.18,23.71Z"
        />
        <path
          d="M246.16.53h1.78a.42.42,0,0,1,.44.45V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V1A.43.43,0,0,1,246.16.53Z"
        />
        <path
          d="M262,23.71a.41.41,0,0,1,.44-.44h1.78a.42.42,0,0,1,.45.44c.13,3.92,1.78,6,4.81,6s4.73-1.6,4.73-4.68c0-7.62-12-9.31-12-18,0-4.15,2.85-6.91,7.17-6.91,4.06,0,6.87,3.3,7.09,8.24a.42.42,0,0,1-.44.45h-1.79a.42.42,0,0,1-.44-.45c-.18-3.92-1.74-5.75-4.42-5.75-2.94,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.4,7.18S262.16,28.79,262,23.71Z"
        />
        <path
          d="M302.1,1v1.6a.43.43,0,0,1-.45.45H296.3V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V3h-5.35a.42.42,0,0,1-.44-.45V1a.42.42,0,0,1,.44-.45h13.37A.43.43,0,0,1,302.1,1Z"
        />
        <path
          d="M314.45,31.29V8.16c0-4.82,2.94-8,7.35-8s7.36,3.21,7.36,8V31.29a.42.42,0,0,1-.45.44h-1.78a.42.42,0,0,1-.45-.44V20.41h-9.36V31.29a.41.41,0,0,1-.44.44h-1.79A.41.41,0,0,1,314.45,31.29Zm12-13.37V8.16c0-3.48-1.69-5.53-4.68-5.53s-4.68,2.05-4.68,5.53v9.76Z"
        />
        <path
          d="M343.6,31.29V8.11c0-4.68,2.9-8,7.36-8s7.35,3.34,7.35,8V31.29a.42.42,0,0,1-.45.44h-1.78a.41.41,0,0,1-.44-.44V8.11c0-3.3-1.83-5.53-4.68-5.53s-4.68,2.23-4.68,5.53V31.29a.42.42,0,0,1-.45.44h-1.78A.42.42,0,0,1,343.6,31.29Z"
        />
        <path
          d="M383.81,1v1.6a.43.43,0,0,1-.45.45H378V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V3H370a.42.42,0,0,1-.44-.45V1A.42.42,0,0,1,370,.53h13.37A.43.43,0,0,1,383.81,1Z"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default PrestoLogoTextAssistantOnly;
