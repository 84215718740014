// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface ChecklistIndexResponse {
  checklists: GQL.IChecklistIndexItemAll;
}

// Query Definitions
const GET_CHECKLISTS = gql`
  query Checklists {
    checklists {
      data {
        id
        itemCount
        label
        memberCount
        schoolYearEnding
      }
      fullCount
    }
  }
`;

export const GET_CHECKLIST = gql`
  query Checklist($id: ID!) {
    checklist(id: $id) {
      checklistItems {
        id
        isRequired
        label
      }
      emailRemindersEnabled
      id
      label
      memberIds
      memberItemDetails {
        checklistItems {
          completedAt
          id
          isRequired
          itemId
          label
        }
        id
        member {
          id
          email
          firstName
          grade
          groupIds
          lastName
          primaryRoleId
          primaryRoleLabel
          roleId
          roleLabel
        }
      }
      schoolYearEnding
    }
  }
`;

export const useGetChecklists = () =>
  usePaginatedListQuery<ChecklistIndexResponse, GQL.IChecklistIndexItem>({
    dataSelector: (res) => res.checklists.data,
    fullCountSelector: (res) => res.checklists.fullCount,
    query: GET_CHECKLISTS,
  });

export const useGetChecklist = (id: string) =>
  useQueryEnhanced<{
    checklist: GQL.IChecklist;
}>(GET_CHECKLIST, { variables: { id } });
