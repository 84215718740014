// External Dependencies
import { JWTPayload } from '@presto-assistant/api_types';
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { getToken } from 'utils/cookies';
import { parseJwt } from 'utils/lib/token';

// Local Dependencies
import { addSelfData, logoutCurrentUser } from './actions';

// Local Typings
interface Action {
  payload: GQL.ISelf;
  type: string;
}

export interface SelfState {
  data: GQL.ISelf | null;
  tokenData: JWTPayload | null;
}

// Local Variables
const SELF_INITIAL_STATE: SelfState = {
  data: null,
  tokenData: null,
};

// Reducers
const data = createReducer(SELF_INITIAL_STATE.data, (handleAction) => [
  handleAction(addSelfData, (state, { payload }: Action) => payload),
  handleAction(logoutCurrentUser, () => SELF_INITIAL_STATE.data),
]);
const tokenData = createReducer(SELF_INITIAL_STATE.tokenData, (handleAction) => [
  handleAction(addSelfData, () => {
    const token = getToken();

    const parsedToken: JWTPayload | null = token ? parseJwt(token) : null;

    return parsedToken;
  }),
  handleAction(logoutCurrentUser, () => SELF_INITIAL_STATE.tokenData),
]);

export default combineReducers<SelfState>({ data, tokenData });
