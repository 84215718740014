// External Dependencies
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AccountEditIcon from 'mdi-material-ui/AccountEdit';
import AccountPlusIcon from 'mdi-material-ui/AccountPlus';
import AddIcon from '@mui/icons-material/Add';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { MoreActionsItem } from 'components/shared/DataTable/ToolbarMoreActionsIconMenu';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { tableQueryParams } from 'state/table/selectors';
import {
  useGetDistrictOrganizationIndex,
  useGetLicensesIndex,
} from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import AdminOrganizationsTableZeroState from './AdminOrganizationsTableZeroState';
import CreateOrganizationPopoverButton from './CreateOrganizationPopoverButton';
import EditCreatorDialog from './EditCreatorDialog';
import RedeemLicenseConfirmationDialog, {
  SingleOrgLicenseData,
} from './RedeemLicenseConfirmationDialog';

// Local Typings
type LicenseDuration = GQL.ILicensesIndex['numberOfMonths'];
type AvailableLicensesByDuration = {
  [key in LicenseDuration]: GQL.ILicensesIndex[];
}

// Local Variables
const pinnedColumns = ['label'];

// Component Definition
const AdminOrganizationsTable = (): JSX.Element => {
  const [editCreatorOrgId, setEditCreatorOrgId] = useState<string | null>(null);
  const [
    singleOrganizationToRedeemData,
    setSingleOrganizationToRedeemData,
  ] = useState<GQL.IDistrictOrganizationIndexItem | null>(null);
  const [
    singleOrganizationLicenseData,
    setSingleOrganizationLicenseData,
  ] = useState<SingleOrgLicenseData | null>(null);

  const handleClearEditCreatorOrgId = useCallback(() => {
    setEditCreatorOrgId(null);
  }, []);

  const districtDirectorsParams = useSelector(
    tableQueryParams('districtOrganizationsDirectors'),
  );

  const {
    data: districtOrganizationsData,
    loading: isLoadingDistrictOrganizationsData,
  } = useGetDistrictOrganizationIndex({
    isUsingDataGrid: true,
  });

  const {
    data,
    isLoading: isLoadingLicensesData,
  } = useGetLicensesIndex();

  // Find all available licenses for this district:
  // - Not active licenses, meaning they are not currently being used
  // - Unredeemed licenses, never been used
  const availableLicenses: GQL.ILicensesIndex[] = useMemo(() => {
    return data?.filter((license) => !license.isActive && !license.redeemedAt) ?? [];
  }, [data]);

  // Right now we offer licenses of these durations (license.numberOfMonths):
  //  6 months, 12 months, 24 months, 36 months, or 60 months
  // We'll map over all licenses and group them by duration.
  // Each array will contain all available licenses of that duration.
  const availableLicensesByDuration: AvailableLicensesByDuration = useMemo(() => {
    const licensesByDuration: AvailableLicensesByDuration = {};

    availableLicenses.forEach((license) => {
      if (!licensesByDuration[license.numberOfMonths]) {
        licensesByDuration[license.numberOfMonths] = [];
      }
      licensesByDuration[license.numberOfMonths].push(license);
    });
    return licensesByDuration;
  }, [availableLicenses]);

  const isLoading = isLoadingDistrictOrganizationsData || isLoadingLicensesData;

  const handleTableClickRow = useCallback(
    (
      id: string,
    ) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${id}${districtDirectorsParams}`,
    [districtDirectorsParams],
  );

  const editCreatorOrg = useMemo(() => {
    return districtOrganizationsData?.districtOrganizationsIndex.data.find(
      (o) => o.id === editCreatorOrgId,
    ) ?? null;
  }, [districtOrganizationsData, editCreatorOrgId]);

  const handleOpenConfirmationDialog = useCallback((
    singleOrgData: GQL.IDistrictOrganizationIndexItem,
  ) => {
    setSingleOrganizationToRedeemData(singleOrgData);
  }, []);

  const handleCloseRedeemConfirmationDialog = useCallback(() => {
    setSingleOrganizationToRedeemData(null);
  }, []);

  const handleOpenEditDirectorDialog = useCallback((row: GQL.IDistrictOrganizationIndexItem) => {
    setEditCreatorOrgId(row.id);
  }, []);

  /* Create a memoized array of Redeem License extra columm action elements,
   *  based on the available licenses in availableLicensesByDuration
   */
  const redeemLicenseExtraColumns = useMemo<
    MoreActionsItem<GQL.IDistrictOrganizationIndexItem>[]
  >(() => {
    return Object.keys(availableLicensesByDuration).map((duration) => {
      const durationNumber = parseInt(duration, 10);

      return {
        action: (row: GQL.IDistrictOrganizationIndexItem) => {
          setSingleOrganizationLicenseData({
            singleLicenseNumberOfMonths: durationNumber,
            singleLicenseRedemptionCode:
              availableLicensesByDuration[durationNumber][0].redemptionCode,
          });
          handleOpenConfirmationDialog(row);
        },
        icon: <AddIcon />,
        text: `Redeem License (${duration} months)`,
      };
    });
  }, [availableLicensesByDuration, handleOpenConfirmationDialog]);

  const extraColumns = useMemo<DataGridColDef<GQL.IDistrictOrganizationIndexItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<
        GQL.IDistrictOrganizationIndexItem
      >([
        {
          action: handleOpenEditDirectorDialog,
          icon: <AccountPlusIcon />,
          shouldRender: (row: GQL.IDistrictOrganizationIndexItem) => !row.directorFirstName,
          text: 'Assign Director',
        },
        {
          action: handleOpenEditDirectorDialog,
          icon: <AccountEditIcon />,
          shouldRender: (row: GQL.IDistrictOrganizationIndexItem) => Boolean(row.directorFirstName),
          text: 'Change Director',
        },
        ...redeemLicenseExtraColumns,
        ...(!redeemLicenseExtraColumns.length
          ? [
            {
              action: handleOpenConfirmationDialog,
              icon: <AddIcon />,
              text: 'Redeem License',
            },
          ]
          : []),
      ]);

      return (actionsColumn
        ? [actionsColumn]
        : []
      ) as DataGridColDef<GQL.IDistrictOrganizationIndexItem>[];
    },
    [
      handleOpenConfirmationDialog,
      handleOpenEditDirectorDialog,
      redeemLicenseExtraColumns,
    ],
  );

  const createButtonElement = useMemo(() => <CreateOrganizationPopoverButton />, []);

  const columns = useColumns({
    extraColumns,
  });

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: AdminOrganizationsTableZeroState,
          }}
          customAddButton={createButtonElement}
          leftPinnedColumns={pinnedColumns}
          loading={isLoading}
          rows={districtOrganizationsData?.districtOrganizationsIndex.data ?? []}
          tableResource="districtOrganizations"
          withSearch
        />
      </DataGridContainer>

      <EditCreatorDialog
        onClose={handleClearEditCreatorOrgId}
        organization={editCreatorOrg}
      />
      <RedeemLicenseConfirmationDialog
        isOpen={Boolean(singleOrganizationToRedeemData?.id)}
        numberOfAvailableLicenses={availableLicenses?.length}
        onClose={handleCloseRedeemConfirmationDialog}
        singleOrgData={singleOrganizationToRedeemData}
        singleOrgLicenseData={singleOrganizationLicenseData}
      />
    </>
  );
};

export default AdminOrganizationsTable;
