// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useCreateFinancialCreditTransfer } from 'gql/mutations';

// Local Dependencies
import { useGetFinancialCredit } from 'gql/queries';
import CreditTransferForm from './CreditTransferForm';

// Component Definition
const FinancialCreditsTransfersNew: FC = () => {
  const navigate = useNavigate();
  const { creditId } = useParams<{ creditId: string; }>();
  const showPath = `/${PATHS.FINANCIAL_CREDITS}/${creditId}`;

  const {
    data: financialCreditData,
    loading: isFinancialCreditLoading,
  } = useGetFinancialCredit(creditId!);

  const navigateToShow = useCallback(() => {
    navigate(showPath);
  }, [navigate, showPath]);

  const [createFinancialCreditTransfer] = useCreateFinancialCreditTransfer(
    {
      clearCachePredicates: [
        'financialCredit',
        'financialCredits',
        'financialCreditsIndex',
      ],
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateFinancialCreditTransferInput,
  ) =>
    createFinancialCreditTransfer({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'Credit',
        path: showPath,
      }}
      isLoading={isFinancialCreditLoading}
    >
      {financialCreditData?.financialCredit && (
        <CreditTransferForm
          financialCredit={financialCreditData.financialCredit}
          onSubmit={handleSubmitForm}
        />
      )}
    </Page>
  );
};

export default FinancialCreditsTransfersNew;
