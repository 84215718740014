// External Dependencies
import { FC } from 'react';
import { ImportResources } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { close } from 'state/ui/libraryFileUploadDialog/actions';
import { isOpen } from 'state/ui/libraryFileUploadDialog/selectors';
import { useGetGettingStartedCounts } from 'gql/queries';
import CSVImporter from 'components/CSVImporter';

// Component Definition
const DialogLibraryFileUpload: FC = () => {
  const dispatch = useDispatch();

  const isLibraryFileUploadDialogOpen = useSelector(isOpen);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  // eslint-disable-next-line max-len
  const hasAtLeastOneLibraryItem = (gettingStartedCountsData?.dashboardMetrics?.libraryCount ?? 0) > 0;

  const handleCloseDialogLibraryFileUpload = () => {
    dispatch(close());
  };

  return (
    <CSVImporter
      canMigrateFromAnotherSystem
      context="library"
      csvFileInfoResource="libraryItems"
      dialogTitle="Add Library Items"
      importResourceId={ImportResources.Library}
      insertMutationName="insertLibraryCsvFileToDB"
      insertMutationOptions={{
        clearCachePredicates: [
          'libraryItems',
          ...!hasAtLeastOneLibraryItem ? ['dashboardMetrics'] : [],
        ],
      }}
      isOpen={isLibraryFileUploadDialogOpen}
      onClose={handleCloseDialogLibraryFileUpload}
      uploadMutationName="csvLibraryUpload"
    />
  );
};

export default DialogLibraryFileUpload;
