// External Dependencies
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

// Internal Dependencies
import { FinancialStatementDialog } from 'components/shared';
import { isStudent as isStudentSelector } from 'state/self/selectors';
import { useGetMyRelativeIds } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import useSelfQuery from 'hooks/useSelfQuery';

// Component Definition
const MyFinancialStatementButton = (): JSX.Element => {
  const { self } = useSelfQuery();
  const isStudent = useSelector(isStudentSelector);

  const {
    isOpen: isFinancialStatementDialogOpen,
    toggleIsOpen: toggleIsFinancialStatementDialogOpen,
  } = useIsOpen();

  const relativeIds = useGetMyRelativeIds({ confirmedOnly: true, includeSelfId: true });

  const userIds = useMemo<string[]>(() => {
    if (isStudent && self?.id) {
      return [self.id];
    }

    return relativeIds;
  }, [isStudent, relativeIds, self]);

  return (
    <>
      <Box>
        <Button
          color="primary"
          onClick={toggleIsFinancialStatementDialogOpen}
          size="small"
          startIcon={<DownloadIcon />}
          variant="outlined"
        >
          Download statement
        </Button>

      </Box>

      {self && (
        <FinancialStatementDialog
          downloadFileName="My financial statement"
          isGettingFamilyStatements
          isOpen={isFinancialStatementDialogOpen}
          onClose={toggleIsFinancialStatementDialogOpen}
          userIds={userIds}
        />
      )}
    </>
  );
};

export default MyFinancialStatementButton;
