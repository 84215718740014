// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import { ConfirmationDialog, EnhancedAlert, Flex } from 'components/shared';
import {
  CreateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { displayPriceStringFromDollarAmount, pluralize } from 'utils';
import { useImportFundraiserCreditAmounts } from 'utils/api/financialFundraiserCredit';
import { useIsOpen } from 'hooks/useIsOpen';
import BadRowTable from 'components/CSVImporter/FilePreview/BadRowTable';
import GoodRowTable from 'components/CSVImporter/FilePreview/GoodRowTable';
import UploadDropzone from 'components/shared/UploadDropzone';

// Local Typings
interface Props {
  errors: FormikErrors<CreateFinancialFundraiserCreditRequest>;
  isIndividualized: boolean;
  touched: FormikTouched<CreateFinancialFundraiserCreditRequest>;
}

// Component Definition
const IndividualizedCreditFormFields: FC<Props> = ({
  errors,
  isIndividualized,
  touched,
}) => {
  const formikContext = useFormikContext<CreateFinancialFundraiserCreditRequest>();

  const {
    isOpen: isPreviewingData,
    toggleIsOpen: toggleIsPreviewingData,
  } = useIsOpen();

  const {
    data: importFundraiserCreditAmountsData,
    isSuccess: isImportFundraiserCreditAmountsSuccess,
    mutate: importFundraiserCreditAmounts,
    reset: resetImportFundraiserCreditAmounts,
  } = useImportFundraiserCreditAmounts();

  const importResponse = importFundraiserCreditAmountsData?.data.data;

  const badRows = importResponse?.badRows;
  const validData = importResponse?.validData;

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const handleDrop = useCallback(async (droppedFiles: File[]) => {
    try {
      setIsUploadingFiles(true);

      const formData = new FormData();

      formData.append('file', droppedFiles[0]);

      importFundraiserCreditAmounts(formData);
    } finally {
      setIsUploadingFiles(false);
    }
  }, [importFundraiserCreditAmounts]);

  useEffect(() => {
    formikContext.setFieldValue('assignments', []);
  // don't use formikContext in this dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndividualized]);

  useEffect(() => {
    if (validData?.length && !badRows?.length) {
      const assignments: CreateFinancialFundraiserCreditRequest['assignments'] = validData
        .map((row) => {
          return {
            amountInCents: row.amountInCents,
            memberId: row.memberId,
          };
        });

      formikContext.setFieldValue('assignments', assignments);
    } else {
      formikContext.setFieldValue('assignments', []);
    }
  // don't use formikContext in this dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badRows, validData]);

  return (
    <>
      <Collapse in={isIndividualized}>
        <Collapse in={!isImportFundraiserCreditAmountsSuccess}>
          <UploadDropzone
            acceptedFileTypes={['text/csv']}
            disabled={isUploadingFiles}
            handleDrop={handleDrop}
            helperText="Upload a CSV file with the following columns: Student ID, Amount"
            marginBottom={1}
            marginTop={1}
            rejectedDropErrorMessage="Unable to upload files"
          />
        </Collapse>

        <Collapse in={Boolean(errors.assignments?.length && touched.assignments)}>
          <EnhancedAlert
            severity="error"
            sx={{ marginY: 1 }}
          >
            {errors.assignments}
          </EnhancedAlert>
        </Collapse>

        <Collapse in={Boolean(badRows?.length)}>
          <EnhancedAlert
            action={(
              <Button
                color="error"
                key="reset-button-error"
                onClick={resetImportFundraiserCreditAmounts}
                size="small"
              >
                Reset
              </Button>
            )}
            severity="error"
            sx={{ marginY: 1 }}
          >
            The following rows contain errors.
          </EnhancedAlert>

          <BadRowTable
            context="Import"
            previewData={{
              badRows: badRows ?? [],
              fileId: '',
              headers: importResponse?.headers ?? [],
            }}
          />
        </Collapse>

        <Collapse in={Boolean(validData?.length && !badRows?.length)}>
          <EnhancedAlert
            action={(
              <Button
                color="success"
                key="preview-button"
                onClick={toggleIsPreviewingData}
              >
                Preview
              </Button>
            )}
            severity="success"
            sx={{ marginY: 1 }}
          >
            Assigning {validData?.length} {pluralize(validData?.length, 'member')} to this fundraiser credit.
          </EnhancedAlert>

          <Flex justifyContent="flex-end">
            <Button
              color="success"
              key="reset-button-success"
              onClick={resetImportFundraiserCreditAmounts}
              size="small"
            >
              Reset
            </Button>
          </Flex>
        </Collapse>
      </Collapse>

      <ConfirmationDialog
        confirmButtonAction={toggleIsPreviewingData}
        description={(
          <GoodRowTable
            context="Imports"
            previewData={{
              headers: ['Student ID', 'Member Name', 'Member Email', 'Amount'],
              rows: (validData ?? []).map((row) => {
                const {
                  amountInCents,
                  memberEmail,
                  memberName,
                  studentId,
                } = row;

                return [
                  studentId,
                  memberName,
                  memberEmail,
                  displayPriceStringFromDollarAmount(
                    convertCentsToDollars(amountInCents),
                  ),
                ];
              }),
            }}
          />
        )}
        handleClose={toggleIsPreviewingData}
        hideDeclineButton
        maxWidth="md"
        open={isPreviewingData}
        title={`Assigning Fundraiser Credit to ${validData?.length} ${pluralize(validData?.length, 'Member')}`}
        useCustomText
      />
    </>
  );
};

export default IndividualizedCreditFormFields;
