// External Dependencies
import {
  Avatar,
  Box,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import { FinancialCreditClosureTypes, StripeStatus } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  ShowCardHeader,
  ShowPageDataDisplay,
  UserLabelWithLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount, formatDate,
  formatDateTime,
  getFullName,
} from 'utils';
import { hasPermission } from 'state/self/selectors';
import LabelWithLink from 'components/shared/LabelWithLink';

// Local Dependencies
import AddCreditListItem from './AddCreditListItem';
import CloseCreditListItem from './CloseCreditListItem';
import TransferCreditListItem from './TransferCreditListItem';

// Local Typings
interface Props {
  credit: GQL.IFinancialCredit | undefined;
  editPath?: string | undefined;
}

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.actions-subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },

  '.avatar': {
    backgroundColor: theme.palette.showPage.people,
  },
  width: 512,
}));
const StyledStrong = styled.strong({ fontSize: '1.2em' });

const title = 'Credit Info';

// Component Definition
const ShowFinancialCreditData: FC<Props> = ({
  credit,
  editPath,
}) => {
  const canCreateFinancialCredits = useSelector(hasPermission('payments', 'write'));
  const canEditCredits = useSelector(hasPermission('payments', 'edit'));

  const creditTransferredTotal = useMemo(
    () =>
      (credit?.creditTransfers ?? [])
        .reduce((prev, curr) => prev + curr.amountInCents, 0),
    [credit],
  );

  const creditAppliedTotal = useMemo(
    () =>
      (credit?.creditApplications ?? [])
        .filter((application) => !application.financialPayment.refundedAt)
        .reduce((prev, curr) => prev + curr.amountInCents, 0),
    [credit],
  );

  if (!credit) {
    return null;
  }

  const isCreditEditable = credit.isEditable;

  const {
    amountInCents: creditAmountInCents,
    closedAt,
    creditRemainingInCents,
    financialAccount,
    note,
    originalFinancialPayment,
    transferredFromCredit,
    user,
    user: {
      firstName,
      id: userId,
      lastName,
      middleName,
    },
  } = credit;

  const fullName = getFullName({ firstName, lastName, middleName });

  const didRefundFail = originalFinancialPayment?.stripeRefundStatusId === StripeStatus.Failed;

  const isRefunded = originalFinancialPayment?.refundedAt
    && originalFinancialPayment?.refundedBy
    && !didRefundFail;

  const isClosed = Boolean(closedAt);

  return (
    <Grid container>
      <StyledContainer>
        {canEditCredits && !isCreditEditable.status && (
          <Box mb={2}>
            <EnhancedAlert>
              {isCreditEditable.reason}
            </EnhancedAlert>
          </Box>
        )}

        <Box mb={2}>
          <EnhancedCard>
            {originalFinancialPayment?.refundedBy && isRefunded && (
              <EnhancedAlert
                severity="error"
                title="Credit refunded"
              >
                Issued by: {getFullName(originalFinancialPayment.refundedBy)}
                {' '}on{' '}
                {formatDate(originalFinancialPayment.refundedAt)}
                <br />
                Reason: {originalFinancialPayment.refundedNote}
              </EnhancedAlert>
            )}

            <ShowCardHeader
              avatar={(
                <Avatar className="avatar">
                  <LocalAtmIcon aria-label={title} />
                </Avatar>
              )}
              editPath={editPath}
              title={title}
            />
            <CardContent>
              <ShowPageDataDisplay
                label="Name"
                value={<UserLabelWithLink user={user} />}
              />

              <ShowPageDataDisplay
                label="Account"
                value={financialAccount ? (
                  <LabelWithLink
                    label={financialAccount?.label}
                    to={`/${PATHS.FINANCIAL_ACCOUNTS}/${financialAccount.id}`}
                  />
                ) : null}
              />

              <ShowPageDataDisplay
                label="Note"
                value={note}
              />

              {transferredFromCredit && (
                <ShowPageDataDisplay
                  label="Transferred From"
                  value={(
                    <LabelWithLink
                      label={getFullName(transferredFromCredit.user)}
                      linkText="View Credit"
                      to={`/${PATHS.FINANCIAL_CREDITS}/${transferredFromCredit.id}`}
                    />
                  )}
                />
              )}
            </CardContent>

            <Divider />

            <CardContent>
              <Box
                alignItems="flex-end"
                display="flex"
                flexDirection="column"
                width="100%"
              >
                <ShowPageDataDisplay
                  align="right"
                  label="Initial Amount"
                  type="currency"
                  value={creditAmountInCents}
                />

                <ShowPageDataDisplay
                  align="right"
                  label="Amount Applied"
                  type="currency"
                  value={creditAppliedTotal}
                />

                <ShowPageDataDisplay
                  align="right"
                  label="Amount Transferred"
                  type="currency"
                  value={creditTransferredTotal}
                />

                <ShowPageDataDisplay
                  align="right"
                  label="Total Credit Remaining"
                  value={(
                    <StyledStrong>
                      {displayPriceStringFromDollarAmount(
                        convertCentsToDollars(creditRemainingInCents),
                      )}
                    </StyledStrong>
                  )}
                />
              </Box>
            </CardContent>

            {credit.creditApplications.length > 0 && (
              <>
                <Divider />

                <CardContent>
                  <ShowPageDataDisplay
                    label="Applications"
                    value={(
                      <List>
                        {credit.creditApplications.map((application) => {
                          const {
                            financialPayment,
                          } = application;

                          const {
                            financialFee: {
                              financialItem,
                            },
                          } = financialPayment;

                          return (
                            <ListItem key={application.id}>
                              <ListItemText
                                primary={(
                                  <LabelWithLink
                                    label={(
                                      <>
                                        {application.financialPayment.refundedAt
                                          ? 'Refunded'
                                          : displayPriceStringFromDollarAmount(
                                            convertCentsToDollars(application.amountInCents),
                                          )}
                                        {' '}&#8212;{' '}
                                        {financialItem.label}
                                      </>
                                    )}
                                    to={`/${PATHS.FINANCIAL_PAYMENTS}/${financialPayment.id}`}
                                  />
                                )}
                                secondary={`Payment entered by ${getFullName(application.createdBy)}`}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  />
                </CardContent>
              </>
            )}

            {credit.creditTransfers.length > 0 && (
              <>
                <Divider />

                <CardContent>
                  <ShowPageDataDisplay
                    label="Transfers"
                    value={(
                      <List>
                        {credit.creditTransfers.map((transfer) => (
                          <ListItem key={transfer.destinationCredit.id}>
                            <ListItemText
                              primary={(
                                <LabelWithLink
                                  label={(
                                    <>
                                      {displayPriceStringFromDollarAmount(
                                        convertCentsToDollars(transfer.amountInCents),
                                      )}
                                      {' '}&#8212;{' '}
                                      {getFullName(transfer.destinationCredit.user)}
                                    </>
                                  )}
                                  to={`/${PATHS.FINANCIAL_CREDITS}/${transfer.destinationCredit.id}`}
                                />
                              )}
                              secondary={`Transferred by ${getFullName(transfer.createdBy)} at ${formatDateTime(transfer.createdAt)}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  />
                </CardContent>
              </>
            )}

            {canCreateFinancialCredits && (
              <>
                <Divider />

                <CardContent>
                  <Typography
                    className="actions-subtitle"
                    component="h6"
                  >
                    User Actions
                  </Typography>

                  <List>
                    <AddCreditListItem
                      userFullName={fullName}
                      userId={userId}
                    />

                    {canEditCredits && !isRefunded && credit.creditRemainingInCents > 0 && (
                      <TransferCreditListItem
                        creditId={credit.id}
                        userFullName={fullName}
                      />
                    )}

                    {!isClosed && credit.creditRemainingInCents > 0 && (
                      <>
                        <CloseCreditListItem
                          closureTypeId={FinancialCreditClosureTypes.Donated}
                          creditId={credit.id}
                        />

                        <CloseCreditListItem
                          closureTypeId={FinancialCreditClosureTypes.Returned}
                          creditId={credit.id}
                        />
                      </>
                    )}
                  </List>
                </CardContent>
              </>
            )}
          </EnhancedCard>
        </Box>
      </StyledContainer>
    </Grid>
  );
};

export default ShowFinancialCreditData;
