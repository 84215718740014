// External Dependencies
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

// Local Typings
interface SectionTitleProps {
  title: string;
}

// Local Variables
const useStyles = makeStyles({
  root: {
    marginBottom: 8,
    marginLeft: 16,
  },
});

// Component Definition
const SectionTitle = ({ title }: SectionTitleProps) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      variant="subtitle2"
    >
      {title}
    </Typography>
  );
};

export default SectionTitle;
