// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { SupportLink } from 'components/shared';

// Component Definition
const OnlinePaymentsLearnMoreLink: FC = () => (
  <SupportLink slug="set-up-online-payments" />
);

export default OnlinePaymentsLearnMoreLink;
