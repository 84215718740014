import { useMemo } from 'react';
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';
import useSelfQuery from 'hooks/useSelfQuery';

export interface Relationship {
  createdAt: GQL.IUserRelationship['createdAt'];
  id: GQL.IUserRelationship['id'];
  isConfirmed: GQL.IUserRelationship['isConfirmed'];
  isPending: GQL.IUserRelationship['isPending'];
  isRejected: GQL.IUserRelationship['isRejected'];
  recipient: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    middleName: string | null;
    phoneNumber: string;
  } | null;
  recipientEmail: GQL.IUserRelationship['recipientEmail'];
  recipientType: GQL.IRelationshipType;
  sender: Pick<GQL.IUserRelationship['sender'], 'id' | 'firstName' | 'lastName' | 'middleName' | 'email' | 'phoneNumber'>;
  senderType: GQL.IRelationshipType;
  updatedAt: GQL.IUserRelationship['updatedAt'];
}

// Fragment Definitions
export const FRAGMENT_USER_RELATIONSHIP = gql`
  fragment RelationshipFragment on UserRelationship {
    createdAt
    id
    isConfirmed
    isPending
    isRejected
    recipient {
      id
      firstName
      lastName
      email
      phoneNumber
    }
    recipientEmail
    recipientType {
      id
      label
    }
    sender {
      id
      firstName
      lastName
      email
      phoneNumber
    }
    senderType {
      id
      femaleLabel
      label
      maleLabel
    }
    updatedAt
  }
`;

export const GET_MY_CHILDREN_UNDER_THIRTEEN = gql`
  query MyChildrenUnderThirteen {
    myChildrenUnderThirteen {
        email
        firstName
        id
        lastName
        parentalConsent {
            consentedAt
            consentedBy {
                email
                firstName
                id
                lastName
            }
        }
    }
  }
`;

// Query Definitions
export const GET_USER_RELATIONSHIP_TYPES = gql`
  query RelationshipTypes {
    relationshipTypes {
      id
      label
      maleLabel
      femaleLabel
    }
  }
`;

export const GET_USER_RELATIONSHIPS = gql`
  query UserRelationships {
    userRelationships {
      ...RelationshipFragment
    }
  }
  ${FRAGMENT_USER_RELATIONSHIP}
`;

const GET_USER_RELATIONSHIPS_BY_MEMBER_ID = gql`
  query UserRelationshipsByMemberId(
    $memberId: String!
) {
    userRelationshipsByMemberId(
      memberId: $memberId
    ) {
      ...RelationshipFragment
    }
  }
  ${FRAGMENT_USER_RELATIONSHIP}
`;

export const useGetRelationshipTypes = () =>
  useQueryEnhanced<{
    relationshipTypes: Array<Pick<GQL.IRelationshipType, 'id' | 'label' | 'maleLabel' | 'femaleLabel'>>;
  }>(GET_USER_RELATIONSHIP_TYPES);

export const useGetRelationships = () =>
  useQueryEnhanced<{
    userRelationships: Relationship[];
  }>(GET_USER_RELATIONSHIPS);

export const useIsFamilyMember = (memberId: string) => {
  const {
    data: relationshipsData,
  } = useGetRelationships();

  return relationshipsData?.userRelationships
    .some((relationship) => relationship.sender.id === memberId
      || relationship.recipient?.id === memberId);
};

export const useGetRelationshipsByMemberId = (
  variables: GQL.IUserRelationshipsByMemberIdOnQueryArguments,
) =>
  useQueryEnhanced<{
    userRelationshipsByMemberId: Relationship[];
  }>(
    GET_USER_RELATIONSHIPS_BY_MEMBER_ID,
    {
      skip: !variables.memberId,
      variables,
    },
  );

export const useGetMyRelativeIds = ({
  confirmedOnly,
  includeSelfId,
}: {
  confirmedOnly: boolean;
  includeSelfId: boolean;
}) => {
  const { self } = useSelfQuery();
  const { data: relationshipsData } = useGetRelationships();

  return useMemo<string[]>(() => [...new Set([
    ...(self && includeSelfId ? [self.id] : []),
    ...((relationshipsData?.userRelationships ?? [])
      .filter((ur) => (confirmedOnly ? ur.isConfirmed : true))
      .flatMap((ur) => [ur.recipient?.id, ur.sender.id]))
      .filter(Boolean) as string[],
  ])], [relationshipsData, self]);
};

export const useGetMyChildrenUnderThirteen = () =>
  useQueryEnhanced<{
    myChildrenUnderThirteen: GQL.IChildUnderThirteen[];
  }>(
    GET_MY_CHILDREN_UNDER_THIRTEEN,
  );
