import cloneDeep from 'lodash.clonedeep';

export const trimValues = <T = Record<string, any>>(values: T) => {
  const clonedValues: any = cloneDeep(values);

  Object.entries(clonedValues).forEach(([key, value]) => {
    if (!value) {
      clonedValues[key] = value;
    } else if (typeof value === 'string') {
      clonedValues[key] = value.trim();
    } else if (typeof value === 'object') {
      clonedValues[key] = trimValues(value);
    }
  });

  return clonedValues as T;
};
