// External Dependencies
import {
  Button,
  ButtonProps,
} from '@mui/material';
import { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { formButton } from 'utils/constants/styles';

// Local Typings
interface Props {
  buttonText: string;
  className?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  labelClasses?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
}

// Local Variables
const useStyles = makeStyles({
  formButton,
  root: {
    margin: '10px 10px 10px 0',
  },
});

// Component Definition
const CustomFormButton: FC<Props & ButtonProps> = ({
  buttonText,
  className,
  color = 'primary',
  labelClasses,
  size,
  type = 'submit',
  variant = 'contained',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.formButton, className)}
      classes={{ root: clsx(classes.root, labelClasses) }}
      color={color}
      size={size}
      type={type}
      variant={variant}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

export default CustomFormButton;
