import {
  DistrictFormsIndexResponseItem, DistrictFormsResponse,
  DownloadFormBlockFileRequest,
} from '@presto-assistant/api_types/api/v1/form';
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useTanstackMutation } from 'utils/api/index';

export const useDownloadFormBlockFile = () => {
  const mutationFn = useCallback((payload: {
    body: DownloadFormBlockFileRequest['body'],
    params: DownloadFormBlockFileRequest['params'],
  }) => {
    return apiClient.v1.form.downloadFormBlockFile({
      body: payload.body,
      params: payload.params,
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};

export const useDistrictForms = () => {
  const dataSelector = useCallback((response: DistrictFormsResponse) => response.data, []);

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.form.districtIndex({
      queryParams,
    });
  }, []);

  const {
    data,
    isLoading,
  } = useInfinitePaginatedListQuery<
    DistrictFormsResponse,
    DistrictFormsIndexResponseItem
  >({
    dataSelector,
    pageSize: 500,
    queryKey: ['districtForms'],
    request: fetchData,
  });

  return {
    data,
    isLoading,
  };
};

export const useDeleteForm = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const mutationFn = useCallback((formId: string) => {
    return apiClient.v1.form.deleteForm({
      params: {
        formId,
      },
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('district');
      evictApolloCache('form');
      evictApolloCache('formIndex');
      queryClient.invalidateQueries(['district']);
      queryClient.invalidateQueries(['districtForms']);
      queryClient.invalidateQueries(['form']);

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });
};

export const useUnpublishForm = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const mutationFn = useCallback((formId: string) => {
    return apiClient.v1.form.unpublishForm({
      params: {
        formId,
      },
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('district');
      evictApolloCache('form');
      evictApolloCache('formIndex');
      queryClient.invalidateQueries(['district']);
      queryClient.invalidateQueries(['districtForms']);
      queryClient.invalidateQueries(['form']);

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });
};
