// External Dependencies
import { memo } from 'react';
import { useTheme } from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

// Component Definition
const DataGridCheckIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <CheckIcon
      fontSize="small"
      htmlColor={theme.palette.text.secondary}
    />
  );
};

export default memo(DataGridCheckIcon);
