// External Dependencies
import {
  CardContent,
  CardHeader,
} from '@mui/material';
import { FC } from 'react';
import { useTheme } from 'styled-components';

// Internal Dependencies
import {
  ShowPageDataDisplay,
} from 'components/shared';

// Local Dependencies
import EnhancedDataCard from './EnhancedDataCard';

// Local Typings
interface Props {
  brand: string | null;
  label: string | null;
  model: string;
  serialNumber: string | null;
}

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const InventorySimpleDataCard: FC<Props> = ({
  brand,
  label,
  model,
  serialNumber,
}) => {
  const theme = useTheme();

  return (
    <EnhancedDataCard
      backgroundColor={theme.palette.inventory.dataCard}
    >
      <CardHeader title="Inventory item" />
      <CardContent>
        <ShowPageDataDisplay
          label="Name"
          value={label}
        />
        <ShowPageDataDisplay
          label="Brand"
          value={brand}
        />
        <ShowPageDataDisplay
          label="Model"
          value={model}
        />
        <ShowPageDataDisplay
          label="Serial Number"
          value={serialNumber}
        />
      </CardContent>
    </EnhancedDataCard>
  );
};

export default InventorySimpleDataCard;
