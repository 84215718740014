// External Dependencies
import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { ConfirmationDialog } from 'components/shared';
import { close } from 'state/ui/paymentAlertDialog/actions';
import { getPaymentAlertDialogState } from 'state/ui/paymentAlertDialog/selectors';

// Component Definition
const DialogStudentPayment: FC = () => {
  const dispatch = useDispatch();

  const {
    hasNoFeesToPayOnline,
    isOpen: isDialogOpen,
  } = useSelector(getPaymentAlertDialogState, shallowEqual);

  const handleClose = () => {
    dispatch(close());
  };

  let title = 'Students cannot make payments';
  let description = (
    <>
      Only adults can make online payments. Please ask an
      adult relative to sign in to their {APP_NAME}{' '}
      view to make a payment for you.
    </>
  );

  if (hasNoFeesToPayOnline) {
    title = 'Pay In Person';
    description = (
      <>
        Some {APP_NAME} fees cannot be paid online. Please ask
        your director for details on how to pay in person.
      </>
    );
  }

  return (
    <ConfirmationDialog
      confirmButtonAction={handleClose}
      description={(
        <DialogContentText>
          {description}
        </DialogContentText>
      )}
      handleClose={handleClose}
      hideDeclineButton
      open={isDialogOpen}
      title={title}
      useCustomText
    />
  );
};

export default DialogStudentPayment;
