// External Dependencies
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grow,
  Typography,
} from '@mui/material/';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomCheckbox,
  CustomFormButton,
  EnhancedAlert,
  OnboardingStepTitle,
  StyledLink,
} from 'components/shared';
import { DOWNLOADS } from 'utils/constants/routes';
import { useAgreeToAcceptableUsePolicy, useDistrictAgreeToAcceptableUsePolicy } from 'gql/mutations';
import { useGetDistrict, useGetOrganization } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import DataInfo from './DataInfo';

// Local Typings
interface FormValues {
  hasAcceptedTerms: boolean;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },

  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

// Component Definition
const OnboardingAcceptableUseAgreement = () => {
  const [submitError, setSubmitError] = useState('');

  const {
    loading: isSelfLoading,
    self,
  } = useSelfQuery();
  const [
    acceptTerms,
    { loading: isSubmittingAcceptTerms },
  ] = useAgreeToAcceptableUsePolicy();
  const [
    acceptDistrictTerms,
    { loading: isSubmittingDistrictAcceptTerms },
  ] = useDistrictAgreeToAcceptableUsePolicy();

  const {
    data: organizationData,
  } = useGetOrganization({ skip: isSelfLoading || !self?.currentOrgId });
  const {
    data: districtData,
  } = useGetDistrict({ skip: isSelfLoading || Boolean(self?.currentOrgId) });

  const isUserDistrictAdmin = !!districtData;

  // TODO: Find userOrgData where current user is admin
  //  in order to pre-fill the form
  //  This would only happen if some of the values were deleted
  //  Since we are working on new users in Feb 2020, this can wait
  const initialValues: FormValues = {
    hasAcceptedTerms: false,
  };

  const handleSubmitForm = async (values: FormValues) => {
    if (!values.hasAcceptedTerms) {
      return;
    }

    if (isUserDistrictAdmin) {
      acceptDistrictTerms();
    } else {
      acceptTerms();
    }
  };

  const validate = (values: FormValues) => {
    if (!values.hasAcceptedTerms) {
      setSubmitError(`Please agree to include ${APP_NAME} in your AUP before proceeding.`);
    } else {
      setSubmitError('');
    }
  };

  const orgName = organizationData?.organization.label;
  const orgDistrictName = organizationData?.organization?.district?.label;
  const districtLabel = districtData?.district.label;
  const districtName = <strong>{districtLabel}</strong>;

  const orgDistrictLabel = orgDistrictName
    ? (
      <>
        <strong>{orgName}</strong> and <strong>{orgDistrictName}</strong>
      </>
    )
    : <strong>{orgName}</strong>;

  const hasLoadedName = Boolean(orgName || districtLabel);
  const entityName = isUserDistrictAdmin ? districtName : orgDistrictLabel;
  const isSubmitting = isSubmittingAcceptTerms || isSubmittingDistrictAcceptTerms;

  return (
    <>
      <OnboardingStepTitle title="Acceptable Use Policy" />

      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validate={validate}
      >
        {(formikProps) => {
          const { handleSubmit } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in>
                <StyledCardContent>
                  <Box
                    component="section"
                    my={2}
                  >
                    {hasLoadedName ? (
                      <Typography align="center">
                        It is the responsibility of
                        {' '}
                        {entityName}
                        {' '}
                        to communicate to parents and students the following
                        {' '}
                        criteria in its district&apos;s technology acceptable use policy.
                      </Typography>
                    ) : <CircularProgress />}
                  </Box>

                  <Box my={2}>
                    <Divider />
                  </Box>

                  <DataInfo />

                  <Box my={2}>
                    <Divider />
                  </Box>

                  <Box
                    component="section"
                    textAlign="left"
                    px={2}
                  >
                    <Typography sx={{ mb: 2 }}>
                      <StyledLink
                        href={DOWNLOADS.ACCEPTABLE_USE_POLICY_AGREEMENT}
                        openInNewTab
                      >
                        Download this information
                      </StyledLink>
                      {' '}
                      now or do it later from the settings page.
                    </Typography>

                    <CustomCheckbox
                      label={`I agree to include ${APP_NAME} in our district's Technology Acceptable Use Policy.`}
                      name="hasAcceptedTerms"
                    />
                  </Box>

                  <Collapse in={Boolean(submitError)}>
                    <EnhancedAlert severity="error">
                      {submitError}
                    </EnhancedAlert>
                  </Collapse>

                  {isSubmitting ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText="Next"
                      className="button"
                      disabled={isSubmitting}
                      key="onboarding-org-info-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingAcceptableUseAgreement;
