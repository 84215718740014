// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateResponse {
  id: string;
}

interface UpdateResponse {
  id: string;
}

interface UpdateData {
  updateLibraryItem: UpdateResponse;
}

interface UpdateDistrictData {
  districtUpdateLibraryItem: UpdateResponse;
}
interface CreateLibraryItemData {
  createLibraryItem: CreateResponse;
}
interface CreateDistrictLibraryItemData {
  districtCreateLibraryItem: CreateResponse;
}

interface CreateLibraryCategoryInput {
  label: string;
}
interface DistrictCreateLibraryCategoryInput {
  label: string;
  organizationId: string;
}
interface CreateLibraryInstrumentationInput {
  label: string;
}
interface DistrictCreateLibraryInstrumentationInput {
  label: string;
  organizationId: string;
}

const CREATE_LIBRARY_ITEM = gql`
  mutation CreateLibraryItem(
    $input: CreateLibraryItemInput!
  ) {
    createLibraryItem(
      input: $input
    ) {
      id
    }
  }
`;

const DISTRICT_CREATE_LIBRARY_ITEM = gql`
  mutation DistrictCreateLibraryItem(
    $input: DistrictCreateLibraryItemInput!
  ) {
    districtCreateLibraryItem(
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_LIBRARY_ITEMS = gql`
  mutation DeleteLibraryItems(
    $deletedNote: String!
    $selection: Selection!
  ) {
    deleteLibraryItems (
      deletedNote: $deletedNote
      selection: $selection
    )
  }
`;

export const UPDATE_LIBRARY_ITEM = gql`
  mutation UpdateLibraryItem(
    $id: ID!
    $input: UpdateLibraryItemInput!
  ) {
    updateLibraryItem (
      id: $id
      input: $input
    ) {
      arranger
      categoryId
      composer
      gradeLevel {
        description
        id
        value
      }
      id
      number
      title
      year

      accompaniment
      author
      conditionId
      hasAccompaniment
      isMajorWork
      isOutOfPrint
      isSolo
      language
      lastPerformance
      majorWork
      numberOfCopies
      period
      priceInCents
      publisher
      stateCode

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

export const DISTRICT_UPDATE_LIBRARY_ITEM = gql`
  mutation DistrictUpdateLibraryItem(
    $id: ID!
    $input: DistrictUpdateLibraryItemInput!
  ) {
    districtUpdateLibraryItem(
      id: $id
      input: $input
    ) {
      id
    }
  }
`;

const CREATE_LIBRARY_CATEGORY = gql`
  mutation CreateLibraryCategory(
    $label: String!
  ) {
    createLibraryCategory(
      label: $label
    ) {
      id
      label
    }
  }
`;

const DISTRICT_CREATE_LIBRARY_CATEGORY = gql`
  mutation DistrictCreateLibraryCategory(
    $input: DfaCreateLibraryCategoryInput!
  ) {
    dfaCreateLibraryCategory(
      input: $input
    ) {
      id
      label
    }
  }
`;

const CREATE_LIBRARY_INSTRUMENTATION = gql`
  mutation CreateLibraryInstrumentation(
    $input: CreateLibraryInstrumentationInput!
  ) {
    createLibraryInstrumentation(
      input: $input
    ) {
      id
      label
    }
  }
`;

const DISTRICT_CREATE_LIBRARY_INSTRUMENTATION = gql`
  mutation DistrictCreateLibraryInstrumentation(
    $input: DfaCreateLibraryInstrumentationInput!
  ) {
    dfaCreateLibraryInstrumentation(
      input: $input
    ) {
      id
      label
    }
  }
`;

export const useCreateLibraryItem = (
  options: MutationOptions<
    CreateLibraryItemData,
    GQL.ICreateLibraryItemOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateLibraryItemData,
    GQL.ICreateLibraryItemOnMutationArguments
  >(CREATE_LIBRARY_ITEM, options);

export const useUpdateLibraryItem = (
  options?: MutationOptions<
    UpdateData,
    GQL.IUpdateLibraryItemOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateData,
  GQL.IUpdateLibraryItemOnMutationArguments
>(
  UPDATE_LIBRARY_ITEM,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'history',
    ],
  },
);

export const useDistrictCreateLibraryItem = (
  options?: MutationOptions<
    CreateDistrictLibraryItemData,
    GQL.IDistrictCreateLibraryItemOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateDistrictLibraryItemData,
  GQL.IDistrictCreateLibraryItemOnMutationArguments
>(
  DISTRICT_CREATE_LIBRARY_ITEM,
  options,
);

export const useDistrictUpdateLibraryItem = (
  options?: MutationOptions<
    UpdateDistrictData,
    GQL.IDistrictUpdateLibraryItemOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateDistrictData,
  GQL.IDistrictUpdateLibraryItemOnMutationArguments
>(
  DISTRICT_UPDATE_LIBRARY_ITEM,
  options,
);

export const useCreateLibraryCategory = (
  options: MutationOptions<
    CreateLibraryCategoryInput,
    GQL.ICreateLibraryCategoryOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateLibraryCategoryInput,
    GQL.ICreateLibraryCategoryOnMutationArguments
  >(CREATE_LIBRARY_CATEGORY, options);

export const useDistrictCreateLibraryCategory = (
  options: MutationOptions<
    DistrictCreateLibraryCategoryInput,
    GQL.IDfaCreateLibraryCategoryOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    DistrictCreateLibraryCategoryInput,
    GQL.IDfaCreateLibraryCategoryOnMutationArguments
  >(DISTRICT_CREATE_LIBRARY_CATEGORY, options);

export const useCreateLibraryInstrumentation = (
  options: MutationOptions<
    CreateLibraryInstrumentationInput,
    GQL.ICreateLibraryInstrumentationOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateLibraryInstrumentationInput,
    GQL.ICreateLibraryInstrumentationOnMutationArguments
  >(CREATE_LIBRARY_INSTRUMENTATION, options);

export const useDistrictCreateLibraryInstrumentation = (
  options: MutationOptions<
    DistrictCreateLibraryInstrumentationInput,
    GQL.IDfaCreateLibraryInstrumentationOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    DistrictCreateLibraryInstrumentationInput,
    GQL.IDfaCreateLibraryInstrumentationOnMutationArguments
  >(DISTRICT_CREATE_LIBRARY_INSTRUMENTATION, options);
