// External Dependencies
import * as Sentry from '@sentry/react';
import { LoginResponse } from '@presto-assistant/api_types/api/auth/login';
import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { formatYupError } from '@presto-assistant/api_types/utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';

// Internal Dependencies
import { ApiData } from 'types/data';
import { ValidationError } from 'yup';
import { addNotification } from 'state/notifications/actions';
import {
  checkToRememberUser,
  clearTokens,
  getToken,
  setTokenInStorage,
  shouldRememberUser,
} from 'utils/cookies';
import { selfId as selfIdSelector } from 'state/self/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useLocation, useNavigate } from 'react-router-dom';
import client from 'gql/client';

// Local Typings
interface BecomeUserPayload {
  organizationId: string;
  userId: string;
}

interface CustomUseMutationOptions {
  suppressErrorNotification?: boolean;
}

// Begin API Actions
async function sendRequest<T = any>({
  data = null,
  endpoint,
  method = 'GET',
}: {
  data?: any;
  endpoint: string;
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';
}): Promise<AxiosResponse<T>> {
  const redirectUrlHeader = 'x-redirect-url';

  // if dev, log the data being sent to the server
  if (process.env.NODE_ENV === 'development') {
    console.log('endpoint:', endpoint, { data }); // eslint-disable-line
  }
  const { REACT_APP_API_URL } = process.env;
  const token = getToken();
  const rememberMe = shouldRememberUser();
  const url = `${REACT_APP_API_URL}${endpoint}`;
  try {
    const response: AxiosResponse<T> = await axios({
      data,
      headers: {
        'X-Access-Token': token,
        'X-Remember-Me': rememberMe,
      },
      method,
      url,
    });

    if (response.headers[redirectUrlHeader]
      && window.location.href !== response.headers[redirectUrlHeader]) {
      window.location.href = response.headers[redirectUrlHeader];
    }

    return response;
  } catch (err: any) {
    if (process.env.NODE_ENV === 'development') {
      console.log({ err }); // eslint-disable-line
    }

    if (err.response?.headers[redirectUrlHeader]) {
      window.location.href = err.response?.headers[redirectUrlHeader];
    }

    return err.response;
  }
}

async function postRequest<T>({
  data,
  endpoint,
}: {
  data?: any;
  endpoint: string;
}) {
  return sendRequest<T>({
    data,
    endpoint,
    method: 'POST',
  });
}

async function putRequest<T>({
  data,
  endpoint,
}: {
  data?: any;
  endpoint: string;
}) {
  return sendRequest<T>({
    data,
    endpoint,
    method: 'PUT',
  });
}

// get methods
export const getHealthStatus = async () => {
  const endpoint = '/open/health';
  return sendRequest({ endpoint });
};

export const getMyOrgCode = async () => {
  const endpoint = '/api/v1/organizations/my_org_code';
  return sendRequest({ endpoint });
};

export const getOrgCount = async () => {
  const endpoint = '/auth/number_of_orgs';
  return sendRequest({ endpoint });
};

export const getPricingPlans = async () => {
  const endpoint = '/open/pricing_plans';
  return sendRequest({ endpoint });
};

export const getStates = async () => {
  const endpoint = '/auth/states';
  return sendRequest({ endpoint });
};

export const getUserRoles = async () => {
  const endpoint = '/open/roles';
  return sendRequest({ endpoint });
};

export const handleTokenResponse = (response: AxiosResponse) => {
  if (response?.status === 200) {
    const { headers } = response;
    const token = headers && headers['x-access-token'];
    setTokenInStorage(token);
  } else {
    clearTokens();
  }
};

// post methods
export const becomeUser = async (data: BecomeUserPayload) => {
  const endpoint = '/auth/become_user';
  const response = await postRequest({
    data,
    endpoint,
  });

  if (response.status === 200) {
    handleTokenResponse(response);
  }

  return response;
};

export const checkEmail = async (data: { email: string }) => {
  const endpoint = '/auth/check_email_availability';
  const response = await postRequest<{ emailAvailable: boolean }>({
    data: trimValues(data),
    endpoint,
  });
  return response;
};

export const checkOrgCode = async (data: { code: string }) => {
  const endpoint = '/auth/check_org_code';
  const response = await postRequest<{ organization: ApiData.Organization }>({
    data,
    endpoint,
  });
  return response;
};

export const login = async (data: any) => {
  const cleanData = { ...data };

  checkToRememberUser(cleanData.rememberMe);

  delete cleanData.rememberMe;

  const endpoint = '/auth/login';
  const response = await postRequest<LoginResponse>({
    data: cleanData,
    endpoint,
  });
  handleTokenResponse(response);

  if (response?.data?.alertFlagId) {
    const sleep = (ms: number) => new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

    // there's a weird bug I haven't figured out where the
    // request to get self fails without this
    // if there is an alert flag on the user
    await sleep(2_000);
  }

  client.clearStore();

  return response;
};

export const getLibraryItems = async ({
  limit,
  page,
}: {
  limit: number;
  page: number;
}) => {
  const endpoint = `/api/v1/library?page=${page}&limit=${limit}`;
  return sendRequest<GQL.ILibraryIndexItemAll>({ endpoint });
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const selfId = useSelector(selfIdSelector);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  // eslint-disable-next-line consistent-return
  return useCallback(async () => {
    try {
      if (selfId) {
        const newPath = `${pathname}${search || '?'}&self_id=${selfId}`;

        await navigate(newPath, { replace: true });
      }

      // Unset the member id in Sentry
      Sentry.setUser(null);

      const endpoint = '/auth/logout';

      const response = await postRequest({ endpoint });

      clearTokens();

      client.clearStore();
      queryClient.clear();

      return response;
    } catch (error) {
      clearTokens();

      client.clearStore();
      queryClient.clear();

      window.location.href = window.location.origin;
    }
  }, [
    navigate,
    pathname,
    queryClient,
    search,
    selfId,
  ]);
};

export const recoverPassword = async (data: any) => {
  const endpoint = '/auth/recover_password';
  const response = await postRequest({ data, endpoint });
  return response;
};

export const redeemSso = async (data: { ssoToken: string }) => {
  const endpoint = '/auth/redeem_sso_token';
  const response = await postRequest({ data, endpoint });
  handleTokenResponse(response);

  return response;
};

export const refreshToken = async () => {
  const endpoint = '/auth/refresh_token';
  const response = await postRequest({ endpoint });
  handleTokenResponse(response);

  return response;
};

export const resetPassword = async (data: any) => {
  const endpoint = '/auth/reset_password';
  const response = await postRequest({ data, endpoint });
  handleTokenResponse(response);
  return response;
};

export const switchToOrgAsDfa = async (data: {
  organizationId: string,
  url?: string,
}) => {
  const endpoint = '/auth/switch_to_org_as_dfa';
  const response = await postRequest({ data, endpoint });
  handleTokenResponse(response);

  if (data.url) {
    window.location.href = data.url;
  } else {
    window.location.reload();
  }

  return response;
};

export const sendToStripe = async () => {
  const endpoint = '/api/v1/organizations/stripe_verification';
  const response: AxiosResponse = await postRequest<{ url: string }>({ endpoint });
  return response;
};

export const signUp = async (data: ApiData.SignupPayload) => {
  const cleanData = trimValues({ ...data });

  const endpoint = '/auth/signup';
  const response = await postRequest<ApiData.SignupResponse>({
    data: cleanData,
    endpoint,
  });

  handleTokenResponse(response);
  client.clearStore();
  return response;
};

export const unbecomeUser = async () => {
  const endpoint = '/auth/unbecome_user';
  const response = await postRequest({
    endpoint,
  });

  if (response.status === 200) {
    handleTokenResponse(response);
  }

  return response;
};

export const verifyRenew = async (token: string) => {
  const endpoint = '/auth/verify_renew';
  const response = await postRequest({
    data: { token },
    endpoint,
  });

  return response;
};

// put methods
export const toggleOrganization = async (
  memberId: string | null,
  dispatch: React.Dispatch<any>,
  nextPath = '',
  suppressErrorNotification = false,
  // eslint-disable-next-line consistent-return
): Promise<void | { error: string; }> => {
  const endpoint = '/api/v1/users/switch_organization';
  const data = { memberId };
  const response = await putRequest({ data, endpoint });

  if ((response.data as any)?.error) {
    const toggleOrganizationError = (response.data as any).error;

    if (!suppressErrorNotification) {
      dispatch(addNotification(toggleOrganizationError, 'error'));
    }

    // Return the error to the caller in case it needs to be handled there.
    return { error: toggleOrganizationError };
  }

  handleTokenResponse(response);
  window.location.href = `${window.location.origin}${nextPath}`;
};

export const verifyEmail = async ({
  activationCode,
  token,
}: {
  activationCode: string;
  token: string;
}) => {
  const endpoint = `/auth/verify?token=${token}&activation_code=${activationCode}`;
  const response = await putRequest({ endpoint });
  handleTokenResponse(response);
  return response;
};

export const getErrorMessage = (error: any) => {
  if (error?.response?.data?.error) {
    const responseError = error.response.data.error;

    if (typeof responseError === 'string') {
      return responseError;
    }

    if (typeof responseError === 'object') {
      return responseError.message;
    }

    return error.response.data.error;
  }

  return error.message || 'Something went wrong';
};

export const useTanstackMutation = <TData, TError, TVariables, TContext>({
  suppressErrorNotification,
  ...options
}: UseMutationOptions<TData, TError, TVariables, TContext> & CustomUseMutationOptions) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line deprecate/function
  return useMutation({
    ...options,
    onError: (error, variables, context) => {
      if (!suppressErrorNotification) {
        const responseError = (error as any)?.response?.data?.error;

        if ((responseError as ValidationError)?.inner) {
          const formattedErrors = formatYupError(responseError as unknown as ValidationError);

          const errorMessages = Object.values(formattedErrors);

          errorMessages.forEach((errorMessage) => {
            dispatch(addNotification(errorMessage, 'error'));
          });
        } else {
          dispatch(addNotification(getErrorMessage(error), 'error'));
        }
      }

      if (options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
