// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  CustomInput,
  Flex,
  StateSelector,
} from 'components/shared';

// Component Definition
const ContactInfoFields: FC = () => (
  <>
    <CustomInput
      label="Email *"
      name="email"
    />
    <CustomInput
      label="Secondary Email"
      name="secondaryEmail"
    />
    <CustomInput
      label="Phone Number"
      name="phoneNumber"
      type="tel"
    />
    <CustomInput
      label="Secondary Phone Number"
      name="secondaryPhoneNumber"
      type="tel"
    />
    <CustomInput
      label="Address 1"
      name="addressOne"
    />
    <CustomInput
      label="Address 2"
      name="addressTwo"
    />
    <CustomInput
      label="City"
      name="city"
    />

    <Flex
      alignItems="baseline"
      flexWrap="nowrap"
    >
      <StateSelector
        sx={{
          marginRight: 2,
          minWidth: '25%',
        }}
      />
      <CustomInput
        label="Zip Code"
        name="zipcode"
      />
    </Flex>
  </>
);

export default ContactInfoFields;
