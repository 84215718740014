// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  getDistrictAdminOrganizationIdFromPathname,
} from 'utils';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import AdultsWithPermissionsTableUI from './AdultsWithPermissionsTableUI';

// Component Definition
const AdultsWithPermissionsTable: FC = () => {
  const organizationId = getDistrictAdminOrganizationIdFromPathname();
  const districtDirectorsParams = useSelector(
    tableQueryParams('districtOrganizationsDirectors'),
  );

  return (
    <>
      <BackToButton
        context="Organization Details"
        navigateTo={`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${organizationId}${districtDirectorsParams}`}
      />

      <Box
        component="section"
        my={2}
      >
        <Subtitle>
          Adults with Permissions
        </Subtitle>

        <EnhancedCard>
          <AdultsWithPermissionsTableUI />
        </EnhancedCard>
      </Box>
    </>
  );
};

export default AdultsWithPermissionsTable;
