// External Dependencies
import { FC, HTMLProps } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styled from 'styled-components';

// Local Typings
interface Props extends StyledSpanProps {
  ['aria-label']?: HTMLProps<HTMLElement>['aria-label'];
  role?: HTMLProps<HTMLElement>['role'];
}
interface StyledSpanProps {
  children: any;
  download?: boolean;
  href?: string;
  light?: boolean;
  noWrap?: boolean;
  onClick?: (evt: React.MouseEvent | React.KeyboardEvent) => void;
  openInNewTab?: boolean;
  rootClasses?: string;
}

// Local Variables
const StyledSpan = styled.span<StyledSpanProps>(({ light, noWrap, theme }) => ({
  '&:hover': {
    background: theme.palette.link,
    cursor: 'pointer',
  },
  borderBottom: `1px solid ${theme.palette.link}`,
  boxShadow: `inset 0 -2px 0px 0px ${theme.palette.link}`,
  color: `${light ? theme.palette.link : 'inherit'}`,
  padding: '4px 1px 0px',
  textDecoration: 'none',
  transition: 'all 0.15s ease-out',
  whiteSpace: noWrap ? 'pre' : undefined,
}));

const StyledOpenInNewTabIcon = styled(OpenInNewIcon)({
  color: 'inherit',
  fontSize: '0.8em',
  marginLeft: '0.25em',
}) as typeof OpenInNewIcon;

// Component Definition
const StyledLink: FC<Props> = ({
  'aria-label': ariaLabel,
  children,
  download,
  href,
  noWrap,
  onClick,
  openInNewTab,
  role,
  rootClasses,
  ...otherProps
}) => (href ? (
  <StyledSpan
    as="a"
    aria-label={ariaLabel}
    className={rootClasses}
    download={download}
    href={href}
    noWrap={noWrap}
    rel="noopener noreferrer"
    role={role}
    target={openInNewTab ? '_blank' : undefined}
    {...otherProps}
  >
    {children}
    {openInNewTab && <StyledOpenInNewTabIcon />}
  </StyledSpan>
) : (
  <StyledSpan
    aria-label={ariaLabel}
    className={rootClasses}
    onClick={onClick}
    onKeyPress={onClick}
    role={role || 'button'}
    tabIndex={0}
    {...otherProps}
  >
    {children}
  </StyledSpan>
));

export default StyledLink;
