// External Dependencies
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash.isequal';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { ConfirmationDialog, Flex, SaveButton } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { isCreator } from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';
import { useCreateGoogleCalendars, useRevokeCalendarToken } from 'gql/mutations';
import { useGetCalendarList, useGetOrganization } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import GoogleCalendarButton from 'components/AlertButtons/GoogleCalendarButton';

// Local Typings
interface Props {
  onSuccess?: () => void;
}

// Component Definition
const GoogleCalendarSettings: FC<Props> = ({ onSuccess }) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [activeIds, setActiveIds] = useState<string[]>([]);

  const isMobileScreen = useSelector(isMobileScreenSize);

  const dispatch = useDispatch();

  const {
    handleClose: handleCloseRevokeConfirmationDialog,
    isOpen: isRevokeConfirmationDialogOpen,
    toggleIsOpen: toggleRevokeConfirmationDialog,
  } = useIsOpen(false);
  const theme = useTheme();

  const isUserCreator = useSelector(isCreator);

  const handleSuccess = () => {
    handleCloseRevokeConfirmationDialog();
    dispatch(addNotification('Google calendar successfully revoked.', 'success'));
    // eslint-disable-next-line no-unused-expressions
    onSuccess?.();
  };

  const [
    revokeGoogleCalendarToken,
    {
      loading: isRevoking,
    },
  ] = useRevokeCalendarToken(handleSuccess);

  const [
    calendarListQuery,
    {
      data: calendarListData,
      loading: isCalendarListLoading,
    },
  ] = useGetCalendarList();

  const { data } = useGetOrganization();

  const [
    setCalendars,
    { loading: isSaving },
  ] = useCreateGoogleCalendars();

  const handleSaveChanges = () => {
    setCalendars({
      variables: {
        input: {
          calendarIds: checkedIds,
        },
      },
    });
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = evt.target;

    const newIds = [...checkedIds];

    const idIndex = newIds.indexOf(id);

    if (idIndex > -1) {
      newIds.splice(idIndex, 1);
    } else {
      newIds.push(id);
    }

    setCheckedIds(newIds);
  };

  useEffect(() => {
    if (isUserCreator && data?.organization.hasGoogleCalendar && calendarListQuery) {
      calendarListQuery();
    }
  }, [isUserCreator, data?.organization, calendarListQuery]);

  useEffect(() => {
    if (calendarListData) {
      const activeResponseIds = calendarListData.googleCalendarList
        .filter((calendar) => calendar.isActive)
        .map((calendar) => calendar.id);

      setCheckedIds(activeResponseIds);
      setActiveIds(activeResponseIds);
    }
  }, [calendarListData]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        my={2}
      >
        <Box>
          <Typography>
            Google Calendar: <strong>{data?.organization.hasGoogleCalendar ? 'ON' : 'OFF'}</strong>
          </Typography>

          <Typography
            color="textSecondary"
            sx={{ m: 1 }}
          >
            Members can stay up-to-date for upcoming events.
          </Typography>
        </Box>

        {data?.organization.hasGoogleCalendar ? (
          <Button
            color="primary"
            key="revoke"
            onClick={toggleRevokeConfirmationDialog}
            size={isMobileScreen ? 'small' : 'medium'}
            variant="outlined"
          >
            {isMobileScreen ? 'Revoke' : 'Revoke Access'}
          </Button>
        ) : (
          <GoogleCalendarButton />
        )}
      </Box>

      {data?.organization.hasGoogleCalendar && calendarListData && (
        <Box
          mx={4}
          my={2}
        >
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            The selected calendars are visible to all{' '}
            {APP_NAME} members of your organization.
          </Typography>

          <List dense>
            {calendarListData.googleCalendarList.map((calendar, index) => (
              <ListItem
                divider={index !== calendarListData!.googleCalendarList.length - 1}
                key={calendar.id}
              >
                <ListItemIcon>
                  <CalendarIcon
                    fontSize="small"
                    htmlColor={calendar.backgroundColor ?? theme.palette.prestoPrimary}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={calendar.summary}
                  sx={{ mr: 2 }}
                />

                <ListItemSecondaryAction>
                  <Checkbox
                    checked={checkedIds.includes(calendar.id)}
                    id={calendar.id}
                    inputProps={{
                      'aria-label': calendar.summary,
                    }}
                    onChange={handleChange}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <Flex justifyContent="flex-end">
            <SaveButton
              disabled={isEqual(activeIds.sort(), checkedIds.sort())}
              isSaving={isSaving || isCalendarListLoading}
              onClick={handleSaveChanges}
            >
              Save
            </SaveButton>
          </Flex>
        </Box>
      )}

      <ConfirmationDialog
        canBeUndone
        confirmButtonAction={revokeGoogleCalendarToken}
        confirmButtonText="Yes, Remove"
        declineButtonAction={toggleRevokeConfirmationDialog}
        description="Are you sure you want to remove Google Calendar from this organization? You can add it back later if you change your mind."
        handleClose={toggleRevokeConfirmationDialog}
        isSubmitting={isRevoking}
        open={isRevokeConfirmationDialogOpen}
        title="Remove Google Calendar?"
      />
    </>
  );
};

export default GoogleCalendarSettings;
