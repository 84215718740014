// External Dependencies
import { FC, useMemo } from 'react';

// Internal Dependencies
import { useGetSpokenLanguages } from 'gql/queries/spoken-languages-queries';

// Local Dependencies
import CustomSelect from './CustomSelect';

// Local Typings
interface Props {
  required?: boolean;
}
// Component Definition
const SpokenLanguageSelect: FC<Props> = ({
  required = true,
}) => {
  const { data, loading } = useGetSpokenLanguages();

  const options = useMemo(
    () => data?.spokenLanguages.map((primarySpokenLanguage) => ({
      id: parseInt(primarySpokenLanguage.id, 10),
      label: primarySpokenLanguage.label,
    })) ?? [],
    [data],
  );

  if (!options.length) {
    return null;
  }

  return (
    <CustomSelect
      isLoading={loading}
      label={`Primary Spoken Language${required ? ' *' : ''}`}
      name="primarySpokenLanguageId"
      options={options}
      required={required}
    />
  );
};

export default SpokenLanguageSelect;
