// External Dependencies
import { ListDynamicFieldsResponseItem } from '@presto-assistant/api_types/api/v1/dynamicField';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { parseSearch } from 'utils';
import { useGetAllDistrictDynamicFields } from 'utils/api/dynamicField';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import DialogDeleteDynamicField from './DialogDeleteDynamicField';

// Component Definition
const DynamicFieldsTable = (): JSX.Element => {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const { search } = useLocation();
  const navigate = useNavigate();

  const parsedSearch = parseSearch(search);

  const hasParams = !!parsedSearch.organizationTypeId && !!parsedSearch.tableRef;

  const {
    data,
    isLoading,
  } = useGetAllDistrictDynamicFields();

  const handleNavigateToEdit = useCallback((row: ListDynamicFieldsResponseItem) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}/${row.id}/edit`);
  }, [navigate]);

  const handleOpenDeleteDialog = useCallback((row: ListDynamicFieldsResponseItem) => {
    setDeleteId(row.id);
  }, []);

  const extraColumns = useMemo<DataGridColDef<ListDynamicFieldsResponseItem>[]>(() => {
    const actionsColumn = createDataGridActionsColumn<ListDynamicFieldsResponseItem>([
      {
        action: handleNavigateToEdit,
        icon: <EditIcon />,
        text: 'Edit',
      },
      {
        action: handleOpenDeleteDialog,
        icon: <DeleteIcon />,
        text: 'Delete',
      },
    ]);

    return (actionsColumn
      ? [actionsColumn]
      : []
    ) as DataGridColDef<ListDynamicFieldsResponseItem>[];
  }, [handleOpenDeleteDialog, handleNavigateToEdit]);

  const columns = useColumns(extraColumns);

  const handleClearDynamicFieldId = useCallback(() => {
    setDeleteId(null);
  }, []);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            disabled: !hasParams,
            label: 'Dynamic Field',
            to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}/new${search}`,
          }}
          columns={columns}
          loading={isLoading}
          rows={data?.data.data ?? null}
          tableResource="dynamicFields"
          withSearch
          zeroStateMessage="No dynamic fields for this table and organization type."
        />
      </DataGridContainer>

      <DialogDeleteDynamicField
        dynamicFieldId={deleteId}
        onClearDynamicFieldId={handleClearDynamicFieldId}
      />
    </>
  );
};

export default DynamicFieldsTable;
