// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { EnhancedAlert, EnhancedCard, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isDirector } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import MyFormsTable from './MyFormsTable';

// Component Definition
const MyForms = (): JSX.Element => {
  const navigate = useNavigate();

  const formsParams = useSelector(tableQueryParams('forms'));

  const isUserDirector = useSelector(isDirector);

  const handleClickViewAllForms = useCallback(() => {
    navigate(`/${PATHS.FORMS}${formsParams}`);
  }, [formsParams, navigate]);

  return (
    <>
      {isUserDirector && (
        <EnhancedAlert
          action={(
            <Button
              onClick={handleClickViewAllForms}
            >
              View All Forms
            </Button>
          )}
          sx={{ marginBottom: 2 }}
        >
          Viewing only forms that are assigned to you
        </EnhancedAlert>
      )}

      <Subtitle>
        My Forms
      </Subtitle>

      <EnhancedCard>
        <MyFormsTable />
      </EnhancedCard>
    </>
  );
};

export default MyForms;
