// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import {
  GET_INVENTORY_ITEM,
  SimpleUser,
  useGetSimpleInventoryItem,
} from 'gql/queries';
import { SelectUser } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useCreateInventoryCheckout } from 'gql/mutations';

// Local Typings
interface Props {
  inventoryItemId: string | null;
  onClose: () => void;
}

// Local Variables
const StyledDialog = styled(Dialog)({
  '.MuiPaper-root': {
    overflow: 'visible',
  },
});

// Component Definition
const QuickCheckoutDialog: FC<Props> = ({
  inventoryItemId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const {
    data: initialInventoryItem,
    loading: isInitialInventoryItemLoading,
  } = useGetSimpleInventoryItem(inventoryItemId ?? undefined);

  const [
    createInventoryCheckout,
    {
      loading: isCreatingInventoryCheckout,
    },
  ] = useCreateInventoryCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['inventoryItemsIndex'],
      onCompleted: () => {
        dispatch(updateIsPaginatedListDataLoaded({
          isPaginatedListDataLoaded: false,
        }));

        dispatch(
          addNotification('Inventory item checked out!', 'success'),
        );

        onClose();
      },
      refetchQueries: () => [
        { query: GET_INVENTORY_ITEM, variables: { id: inventoryItemId } },
      ],
    },
  );

  const handleSubmit = useCallback(async (member: SimpleUser | null) => {
    if (inventoryItemId && member) {
      createInventoryCheckout({
        variables: {
          checkoutDate: moment().toISOString(),
          dueDate: '',
          itemId: inventoryItemId,
          userId: member.id,
        },
      });
    }
  }, [createInventoryCheckout, inventoryItemId]);

  const brand = initialInventoryItem?.inventoryItem.brand ?? '';
  const label = initialInventoryItem?.inventoryItem.label ?? '';
  const serialNumber = initialInventoryItem?.inventoryItem.serialNumber ?? '';

  return (
    <StyledDialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={Boolean(inventoryItemId)}
    >
      <DialogTitle>
        Inventory Checkout
      </DialogTitle>

      <DialogContent sx={{ width: '100%' }}>
        {(isInitialInventoryItemLoading) && <CircularProgress />}

        {initialInventoryItem && (
          <DialogContentText paragraph>
            {brand} {label} {serialNumber && ` (${serialNumber})`}
          </DialogContentText>
        )}

        {initialInventoryItem && (
          <SelectUser
            isSubmitting={isCreatingInventoryCheckout}
            onSelectUser={handleSubmit}
            submitButtonText="Check Out"
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default QuickCheckoutDialog;
