// External Dependencies
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomInput,
  CustomInputWithTooltip,
  CustomSelect,
  InventoryCategorySelect,
  OrganizationSelect,
  StyledLink,
} from 'components/shared';
import { useGetOrganizationTypes } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import DialogAddInventoryCategory from './DialogAddInventoryCategory';

// Local Typings
interface Props {
  hasNoSerialNumber?: boolean;
  inputSize: 'small' | 'medium';
  isAdmin?: boolean;
  isNewItem: boolean;
  orgTypeId?: string;
}

// Local Variables
const useStyles = makeStyles({
  input: {
    marginBottom: 16,
  },
});

// Component Definition
const InventoryBasicInfoFormFields = ({
  hasNoSerialNumber,
  inputSize,
  isAdmin,
  isNewItem,
  orgTypeId,
}: Props): JSX.Element => {
  const classes = useStyles();

  const {
    isOpen: isAddInventoryCategoryDialogOpen,
    toggleIsOpen: toggleAddInventoryCategoryDialog,
  } = useIsOpen();

  const handleClickAddCategory = () => {
    toggleAddInventoryCategoryDialog();
  };

  // TODO: skip this query if user is not District Admin
  const { data: orgTypeData } = useGetOrganizationTypes();

  const serialNumberField = !isNewItem && hasNoSerialNumber ? (
    <CustomInputWithTooltip
      label="Serial Number"
      name="serialNumber"
      size={inputSize}
      tooltipTitle="This unique serial number was added by Presto"
    />
  ) : (
    <CustomInput
      label="Serial Number"
      name="serialNumber"
      rootStyles={classes.input}
      size={inputSize}
      variant="filled"
    />
  );

  // For now, only directors can add custom inventory categories
  const showInventoryCategoryLink = !isAdmin;

  return (
    <>
      <Grid
        container
        direction="column"
      >
        {isAdmin && orgTypeId && orgTypeData && (
          <CustomSelect
            fullWidth={false}
            label="Organization Type"
            name="orgTypeId"
            options={orgTypeData.organizationTypes}
            required
          />
        )}
        {isAdmin && orgTypeId && (
          <OrganizationSelect
            allowUnassignedOption
            orgTypeId={orgTypeId}
            unassignedOptionText="(Not on Loan)"
          />
        )}
        {orgTypeId && (
          <InventoryCategorySelect orgTypeId={orgTypeId} />
        )}

        {showInventoryCategoryLink && (
          <Box
            mx={1.5}
            my={1}
          >
            <StyledLink onClick={handleClickAddCategory}>
              Add Inventory Category
            </StyledLink>
          </Box>
        )}

        <CustomInput
          label="Name *"
          name="label"
          rootStyles={classes.input}
          size={inputSize}
        />
        <CustomInput
          label="Brand"
          name="brand"
          rootStyles={classes.input}
          size={inputSize}
        />
        <CustomInput
          label="Model"
          name="model"
          rootStyles={classes.input}
          size={inputSize}
        />
        {serialNumberField}
        <CustomInputWithTooltip
          label="Custom Barcode"
          name="customBarcode"
          size={inputSize}
          tooltipTitle="Scan an item now to add this barcode to the form"
        />
        <CustomInput
          label="District Number"
          name="districtNumber"
          rootStyles={classes.input}
          size={inputSize}
        />
      </Grid>

      {!isAdmin && (
        <DialogAddInventoryCategory
          isOpen={isAddInventoryCategoryDialogOpen}
          onClose={toggleAddInventoryCategoryDialog}
        />
      )}
    </>
  );
};

export default InventoryBasicInfoFormFields;
