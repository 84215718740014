// External Dependencies
import { FC } from 'react';
import { FeatureFlag, OrganizationEntityTypes } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  ALL_NUMERIC_GRADE_OPTIONS,
  COLLEGE_GRADE_OPTIONS,
  ELEMENTARY_SCHOOL_GRADE_OPTIONS,
  HIGH_SCHOOL_GRADE_OPTIONS,
  MIDDLE_SCHOOL_GRADE_OPTIONS,
} from 'utils/constants/entityTypeOptions';
import { Organization } from 'components/shared';
import { hasFeatureFlag } from 'state/self/selectors';

// Local Dependencies
import CustomSelect from './CustomSelect';

// Local Functions
export function getGradeOptions(
  entityTypeId: number,
  showAllNumericGrades?: boolean,
) {
  if (showAllNumericGrades) {
    return ALL_NUMERIC_GRADE_OPTIONS;
  }

  let options;
  switch (entityTypeId) {
    case OrganizationEntityTypes.HighSchool:
      options = HIGH_SCHOOL_GRADE_OPTIONS;
      break;
    case OrganizationEntityTypes.MiddleSchool:
      options = MIDDLE_SCHOOL_GRADE_OPTIONS;
      break;
    case OrganizationEntityTypes.ElementarySchool:
      options = ELEMENTARY_SCHOOL_GRADE_OPTIONS;
      break;
    case OrganizationEntityTypes.College:
      options = COLLEGE_GRADE_OPTIONS;
      break;
    default:
      options = HIGH_SCHOOL_GRADE_OPTIONS;
      break;
  }
  return options;
}

// Local Typings
interface Props {
  readOnly?: boolean;
}

// Component Definition
const GradeSelect: FC<Props> = ({ readOnly }) => {
  const showAllNumericGrades = useSelector(hasFeatureFlag(FeatureFlag.AllGradeOptions));

  return (
    <Organization>
      {({ organization }) => {
        const { entityType: { id: entityTypeId } } = organization;

        const selectOptions = showAllNumericGrades
          ? ALL_NUMERIC_GRADE_OPTIONS
          : getGradeOptions(Number(entityTypeId));

        return (
          <CustomSelect
            inputProps={{ readOnly }}
            label="Grade"
            name="grade"
            options={selectOptions}
            required={false}
          />
        );
      }}
    </Organization>
  );
};

export default GradeSelect;
