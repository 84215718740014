// External Dependencies
import { CardContent } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Component Definition
const DataGridFilterCard: FC = ({ children }) => (
  <EnhancedCard sx={{ display: 'inline-block' }}>
    <CardContent sx={{ '&:last-child': { paddingY: 0 }, padding: 1 }}>
      {children}
    </CardContent>
  </EnhancedCard>
);

export default DataGridFilterCard;
