// External Dependencies
import { FC, useCallback, useState } from 'react';

// Internal Dependencies
import { TableDataGrid } from 'components/shared';
import { useGetDistrictOrganizationIndex, useGetLicensesIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import RedeemLicenseDialog
  from 'components/shared/MainContentContainer/SideNav/RedeemLicenseDialog';

// Local Dependencies
import { useColumns } from './hooks';
import AdminLicensesTableZeroState from './AdminLicensesTableZeroState';

// Component Definition
const AdminLicensesTable: FC = () => {
  const {
    data,
    isLoading,
  } = useGetLicensesIndex();

  const {
    data: organizationData,
  } = useGetDistrictOrganizationIndex({
    isUsingDataGrid: true,
  });

  const [selectedLicense, setSelectedLicense] = useState<GQL.ILicensesIndex | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSetLicense = useCallback((license: GQL.ILicensesIndex) => {
    setSelectedLicense(license);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleUnsetLicense = useCallback(() => {
    setSelectedLicense(null);
  }, []);

  const columns = useColumns(
    {
      handleSetLicense,
      organizationData: organizationData?.districtOrganizationsIndex.data ?? [],
    },
  );

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          components={{
            NoRowsOverlay: AdminLicensesTableZeroState,
          }}
          loading={isLoading}
          rows={data ?? []}
          tableResource="districtLicenses"
          withSearch
        />
      </DataGridContainer>

      <RedeemLicenseDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onExited={handleUnsetLicense}
        redemptionCode={selectedLicense?.redemptionCode}
      />
    </>

  );
};

export default AdminLicensesTable;
