// External Dependencies
import {
  Calendar,
  CalendarProps,
  Event,
  momentLocalizer,
  stringOrDate,
} from 'react-big-calendar';
import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import {
  GoogleCalendarEvent, GoogleCalendarEventDate, useGetGoogleCalendarEvents,
} from 'gql/queries';
import { LoadingOverlay } from 'components/shared';

// Local Dependencies
import { grey } from '@mui/material/colors';
import EventDialog from '../EventDialog';
import EventUI from './EventUI';

// Local Variables
const endOfMonth = moment().endOf('month')
  .add(2, 'days')
  .format('YYYY-MM-DD');
const startOfMonth = moment().startOf('month')
  .subtract(2, 'days')
  .format('YYYY-MM-DD');

// Local Typings
export type CalendarEvent = Event & Omit<GoogleCalendarEvent, 'end' | 'start'>;

// Local Variables
const localizer = momentLocalizer(moment);

const getEventDateTime = (evtDate: GoogleCalendarEventDate): Date | undefined => {
  if (evtDate.dateTime) {
    return new Date(evtDate.dateTime);
  }

  if (evtDate.date) {
    return new Date(moment(evtDate.date).toISOString());
  }

  return undefined;
};

export const convertGoogleEventToCalendarEvent = (evt: GoogleCalendarEvent): CalendarEvent => ({
  ...evt,
  allDay: !evt.start.dateTime,
  end: getEventDateTime(evt.end),
  start: getEventDateTime(evt.start),
  title: evt.summary ?? '',
});

const normalizeEvents = (googleEvents: GoogleCalendarEvent[]): CalendarEvent[] =>
  googleEvents.map(convertGoogleEventToCalendarEvent);

const CalendarWrapper = styled.div(({
  theme,
}) => ({
  height: 600,
  position: 'relative',

  // eslint-disable-next-line sort-keys
  '& .rbc-event': {
    backgroundColor: 'transparent', // remove the default bg color from the library
    border: 'none',
    padding: 0,
  },
  '& .rbc-event-label': {
    display: 'none',
  },
  '& .rbc-events-container': {
    marginRight: 0,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.grey[50],
  },
  '& .rbc-toolbar button': {
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  '& .rbc-toolbar button.rbc-active': {
    color: grey[900],
  },
}));

const EventCalendar: FC = () => {
  const [event, setEvent] = useState<CalendarEvent | null>(null);

  const [dateRange, setDateRange] = useState({
    end: endOfMonth,
    start: startOfMonth,
  });

  const [
    queryEvents,
    {
      data,
      loading,
    },
  ] = useGetGoogleCalendarEvents({
    endDate: new Date(dateRange.end ?? endOfMonth).getTime(),
    startDate: new Date(dateRange.start ?? startOfMonth).getTime(),
  });

  useEffect(() => {
    queryEvents();
  }, [dateRange, queryEvents]);

  const handleClearEvent = () => setEvent(null);

  const events = normalizeEvents(data?.googleCalendarEventsRange ?? []);

  const handleRangeChange: CalendarProps['onRangeChange'] = (newDateRange) => {
    // day or week change
    if (Array.isArray(newDateRange)) {
      const { 0: start, length, [length - 1]: end } = newDateRange;

      const range = {
        end,
        start,
      };

      setDateRange({
        end: moment(range.end).add(1, 'day').format('YYYY-MM-DD'),
        start: moment(range.start).format('YYYY-MM-DD'),
      });
      // month change
    } else {
      const range = (Array.isArray(newDateRange) ? {
        end: newDateRange[1] ?? newDateRange[0],
        start: newDateRange[0],
      } : newDateRange) as {
        end: stringOrDate;
        start: stringOrDate;
      };

      setDateRange({
        end: moment(range.end).add(1, 'day').format('YYYY-MM-DD'),
        start: moment(range.start).format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <CalendarWrapper>
      {event && (
        <EventDialog
          event={event}
          onClose={handleClearEvent}
        />
      )}

      <Calendar<CalendarEvent>
        components={{
          event: EventUI,
        }}
        endAccessor="end"
        events={events}
        localizer={localizer}
        onRangeChange={handleRangeChange}
        onSelectEvent={setEvent}
        views={['month', 'week', 'day']}
      />

      {loading && <LoadingOverlay />}
    </CalendarWrapper>
  );
};

export default EventCalendar;
