// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { useGetFormsIndex } from 'gql/queries/form-queries';
import { useIsOpen } from 'hooks/useIsOpen';
import ConfirmDeleteFormDialog from 'pages/Forms/components/ConfirmDeleteFormDialog';
import ConfirmUnpublishFormDialog from 'pages/Forms/components/ConfirmUnpublishFormDialog';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FormsTableZeroState from './FormsTableZeroState';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.FORMS}/${id}`;

// Component Definition
const FormsTable = (): JSX.Element => {
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);

  const {
    isOpen: isConfirmDeleteDialogOpen,
    toggleIsOpen: toggleConfirmDeleteDialog,
  } = useIsOpen();
  const {
    isOpen: isConfirmUnpublishDialogOpen,
    toggleIsOpen: toggleConfirmUnpublishDialog,
  } = useIsOpen();

  const {
    data,
    isLoading,
  } = useGetFormsIndex();

  const handleClickDelete = useCallback((id: string) => {
    setSelectedFormId(id);
    toggleConfirmDeleteDialog();
  }, [toggleConfirmDeleteDialog]);

  const handleClickUnpublish = useCallback((id: string) => {
    setSelectedFormId(id);
    toggleConfirmUnpublishDialog();
  }, [toggleConfirmUnpublishDialog]);

  const actionsColumn = useMemo<GridColDef<GQL.IFormIndexItem> | null>(() => {
    return createDataGridActionsColumn<GQL.IFormIndexItem>([
      {
        action: (row) => handleClickUnpublish(row.id),
        icon: <RemoveIcon />,
        isDisabled: (row) => !row.publishedAt || Boolean(row.districtId),
        text: 'Unpublish',
      },
      {
        action: (row) => handleClickDelete(row.id),
        icon: <DeleteIcon />,
        isDisabled: (row) => Boolean(row.districtId),
        text: 'Delete',
      },
    ]);
  }, [handleClickDelete, handleClickUnpublish]);

  const columnArgs = useMemo(() => ({
    actionsColumn,
  }), [actionsColumn]);

  const columns = useColumns(columnArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            label: 'Form',
            to: `/${PATHS.FORMS_NEW}`,
          }}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: FormsTableZeroState,
          }}
          loading={isLoading}
          rows={data}
          tableResource="forms"
          withSearch
        />
      </DataGridContainer>

      <ConfirmDeleteFormDialog
        formId={selectedFormId}
        isOpen={isConfirmDeleteDialogOpen}
        onClose={toggleConfirmDeleteDialog}
      />

      <ConfirmUnpublishFormDialog
        formId={selectedFormId}
        isOpen={isConfirmUnpublishDialogOpen}
        onClose={toggleConfirmUnpublishDialog}
      />
    </>
  );
};

export default FormsTable;
