// Internal Dependencies
import { SELECTION_TYPES } from 'utils/constants';

// Begin Actions
export const createItem = async (variables, mutation, callback) => {
  try {
    await mutation({
      variables,
    });
    return callback();
  } catch (error) {
    console.error('There was an error creating this item.', { error });
    // TODO: trigger an alert
    return () => {};
  }
};

export const deleteItems = async ({
  deletedNote,
  excludedIds,
  mutation,
  onClose,
  onRemoveSelectionAll,
  queryParams,
  selectedIds,
  selectionType,
  singleId,
}) => {
  const isSelectedAllExcept = selectionType === SELECTION_TYPES.SELECTED_ALL_EXCEPT;
  const newQueryParams = { ...queryParams };

  if (isSelectedAllExcept) {
    delete newQueryParams.limit;
  }

  const singleIdList = [singleId];
  const selectionIdList = isSelectedAllExcept ? excludedIds : selectedIds;

  try {
    console.log('mutation is running', { deletedNote });

    await mutation({
      variables: {
        ...(deletedNote && { deletedNote }),
        selection: {
          ids: singleId ? singleIdList : selectionIdList,
          mutationFlag: singleId ? SELECTION_TYPES.SELECTED_ONE : selectionType,
          queryParams: newQueryParams,
        },
      },
    });
    onClose();
    onRemoveSelectionAll();
  } catch (error) {
    console.log('There was an error deleting your items.');
    console.error({ error });
  }
};

export const deleteSingleItem = async (
  mutation,
  singleId,
  selectedIds,
  selectionType,
  queryParams,
  onClose,
  onRemoveSelectionAll,
) => {
  const newQueryParams = { ...queryParams };

  try {
    await mutation({
      variables: {
        id: selectionType === SELECTION_TYPES.SELECTED_ONE ? selectedIds[0] : singleId,
        queryParams: newQueryParams,
      },
    });
    onClose();
    onRemoveSelectionAll();
  } catch (error) {
    console.log('There was an error deleting your item.');
    console.error({ error });
  }
};

export const updateById = async ({
  id,
  mutation,
  onCloseDialog,
  values,
}) => {
  try {
    await mutation({
      variables: {
        ...values,
        id,
      },
    });
    return onCloseDialog();
  } catch (error) {
    console.error('There was an error updating this info.', { error });
    // TODO: trigger an alert
    return () => {};
  }
};

export const removeMembersFromGroup = async ({
  excludedIds,
  groupId,
  mutation,
  onClose,
  onRemoveSelectionAll,
  queryParams,
  selectedIds,
  selectionType,
  singleId,
}) => {
  const isSelectedAllExcept = selectionType === SELECTION_TYPES.SELECTED_ALL_EXCEPT;
  const newQueryParams = { ...queryParams };

  if (isSelectedAllExcept) {
    delete newQueryParams.limit;
  }

  const singleIdList = [singleId];
  const selectionIdList = isSelectedAllExcept ? excludedIds : selectedIds;

  try {
    await mutation({
      variables: {
        id: groupId,
        selection: {
          ids: singleId ? singleIdList : selectionIdList,
          mutationFlag: singleId ? SELECTION_TYPES.SELECTED_ONE : selectionType,
          queryParams: newQueryParams,
        },
      },
    });
    onClose();
    onRemoveSelectionAll();
  } catch (error) {
    console.log('There was an error removing members from this group.');
    console.error({ error });
  }
};
