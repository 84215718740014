// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useGetSuppressedEmailsIndex } from 'gql/queries';

// Component Definition
const SuppressedEmailAlert: FC = () => {
  const navigate = useNavigate();

  const suppressedEmailsParams = useSelector(tableQueryParams('suppressedEmails'));

  const {
    fullCount,
  } = useGetSuppressedEmailsIndex();

  const handleClickManageSuppressedEmails = useCallback(() => {
    navigate(`/${PATHS.EMAIL_SUPPRESSIONS}/${suppressedEmailsParams}`);
  }, [navigate, suppressedEmailsParams]);

  if (fullCount === 0) {
    return null;
  }

  return (
    <EnhancedAlert
      action={(
        <Button
          key="manageEmailAction"
          onClick={handleClickManageSuppressedEmails}
          sx={{ mr: 1 }}
        >
          Manage
        </Button>
      )}
      severity="error"
      sx={{ mb: 2 }}
    >
      There {pluralize(fullCount, 'is', 'are')} {fullCount} {pluralize(fullCount, 'member')} not receiving emails.
    </EnhancedAlert>
  );
};

export default SuppressedEmailAlert;
