// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  Container,
  Flex,
  SkewedBackground,
} from 'components/shared';

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.card-container': {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    zIndex: 1,
  },

  '.title': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    fontSize: '2.5rem',
    marginBottom: theme.spacing(3),
    zIndex: 1,
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(9),
  },
  paddingTop: theme.spacing(11),
}));

// Component Definition
const SignupRoleCardsContainer: FC = ({ children }) => (
  <StyledContainer maxWidth="100%">
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="space-around"
    >
      <SkewedBackground withGrid />

      {children}
    </Flex>
  </StyledContainer>
);

export default SignupRoleCardsContainer;
