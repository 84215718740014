// External Dependencies
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';

// Local Typings
interface Props {
  children: React.ReactNode;
  hasNoPadding?: boolean;
  hasTopBorder?: boolean;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '&.top-border': {
    borderTop: `10px solid ${theme.palette.prestoPrimary}`,
  },
  borderRadius: 8,
}));

// Component Definition
const FormSectionCard = ({
  children,
  hasNoPadding,
  hasTopBorder,
}: Props): JSX.Element => {
  const content = hasNoPadding ? children : (
    <EnhancedCardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {children}
    </EnhancedCardContent>
  );

  return (
    <StyledEnhancedCard
      className={clsx(hasTopBorder && 'top-border')}
    >
      {content}
    </StyledEnhancedCard>
  );
};

export default FormSectionCard;
