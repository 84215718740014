// External Dependencies
import { FC } from 'react';
import { List } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { Subtitle } from 'components/shared';
import { isMobileScreenSize } from 'state/device/selectors';

// Local Dependencies
import DangerZoneItem, { DangerZoneItemProps } from './DangerZoneItem';
import EnhancedCard from '../EnhancedCard';

// Local Typings
interface Props {
  items: DangerZoneItemProps[];
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  borderColor: theme.palette.error.main,
}));

// Component Definition
const DangerZone: FC<Props> = ({
  items,
}) => {
  const isMobileWidth = useSelector(isMobileScreenSize);

  return (
    <>
      <Subtitle>
        Danger Zone
      </Subtitle>

      <StyledEnhancedCard variant="outlined">
        <List dense={isMobileWidth}>
          {items.map((item, index) => (
            <DangerZoneItem
              divider={index < items.length - 1}
              isMobileWidth={isMobileWidth}
              key={item.title}
              {...item}
            />
          ))}
        </List>
      </StyledEnhancedCard>
    </>
  );
};

export type { DangerZoneItemProps } from './DangerZoneItem';

export default DangerZone;
