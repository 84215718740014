// External Dependencies
import { PureComponent } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

// Local Variables
const styles = {
  cancelButton: {
    marginRight: 4,
  },
  noPadding: {
    padding: 0,
  },
  progress: {
    margin: '0px 32px 12px',
  },
};

// Component Definition
class DialogFormContent extends PureComponent {
  render() {
    const {
      cancelText,
      children,
      classes,
      disableContentPadding,
      extraActions,
      hasError,
      hideCancelButton,
      hideSubmitButton,
      isSubmitting,
      onClose,
      subTitle,
      submitText,
      title,
    } = this.props;

    const dialogActions = [];

    if (!hideCancelButton) {
      dialogActions.push(
        <Button
          classes={{
            textPrimary: classes.cancelButton,
          }}
          color="primary"
          key="dialog-form-cancel-button"
          onClick={onClose}
        >
          {cancelText}
        </Button>,
      );
    }

    if (extraActions && extraActions.length > 0) {
      dialogActions.push(extraActions);
    }

    if (!hideSubmitButton) {
      dialogActions.push(
        <Button
          color="primary"
          disabled={hasError}
          key="dialog-form-submit-button"
          type="submit"
          variant="contained"
        >
          {submitText}
        </Button>,
      );
    }

    return (
      <>
        <DialogTitle id="form-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent
          classes={{
            root: disableContentPadding && classes.noPadding,
          }}
        >
          {subTitle && (
            <DialogContentText>
              {subTitle}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          {isSubmitting
            ? (
              <CircularProgress
                className={classes.progress}
                size={24}
                thickness={6}
              />
            ) : dialogActions}
        </DialogActions>
      </>
    );
  }
}

DialogFormContent.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
  disableContentPadding: PropTypes.bool,
  extraActions: PropTypes.node,
  hasError: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DialogFormContent.defaultProps = {
  cancelText: 'Cancel',
  disableContentPadding: false,
  extraActions: null,
  hasError: false,
  hideCancelButton: false,
  hideSubmitButton: false,
  subTitle: null,
  submitText: 'Save',
};

export default withStyles(styles)(DialogFormContent);
