// External Dependencies
import { DistrictEntitiesResponse } from '@presto-assistant/api_types/api/v1/district';
import { GridColDef, ValueOptions } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';
import { getFullName } from 'utils';
import { useGetDistrictEntitiesByAdminId } from 'utils/api/district';
import {
  useGetOrganizationTypes,
} from 'gql/queries';

// Local Variables
export const useColumns = ({
  extraColumns,
}: {
  extraColumns: GridColDef<GQL.IDistrictOrganizationIndexItem>[];
}) => {
  const { data: districtEntitiesData } = useGetDistrictEntitiesByAdminId();
  const { data: organizationTypesData } = useGetOrganizationTypes();

  const entityTypeOptions = useMemo<ValueOptions[]>(() =>
    (districtEntitiesData?.data as DistrictEntitiesResponse)?.districtEntities.map((org) => ({
      label: org.label,
      value: org.label,
    })) ?? [], [districtEntitiesData]);

  const organizationTypeOptions = useMemo<ValueOptions[]>(() =>
    organizationTypesData?.organizationTypes.map((org) => ({
      label: org.label,
      value: org.label,
    })) ?? [], [organizationTypesData]);

  return useMemo(() => {
    const columns: GridColDef<GQL.IDistrictOrganizationIndexItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 300,
      },
      {
        field: 'entityType',
        filterable: entityTypeOptions?.length > 1,
        headerName: 'Campus Type',
        type: 'singleSelect',
        valueOptions: entityTypeOptions,
      },
      {
        field: 'organizationType',
        headerName: 'Type',
        type: 'singleSelect',
        valueOptions: organizationTypeOptions,
      },
      {
        field: 'directorFirstName',
        headerName: 'Director Name',
        minWidth: 180,
        valueGetter: (params) => {
          const {
            directorFirstName,
            directorLastName,
          } = params.row;

          const hasDirectorData = Boolean(directorFirstName && directorLastName);

          return hasDirectorData
            ? getFullName({
              firstName: directorFirstName ?? '',
              lastName: directorLastName ?? '',
              middleName: '',
            })
            : undefined;
        },
      },
      {
        field: 'directorEmail',
        headerName: 'Director Email',
        valueGetter: (params) => {
          return displayCell(params.row.directorEmail || undefined);
        },
      },
      {
        field: 'hasActiveLicense',
        headerName: 'Has License',
        type: 'boolean',
      },
      {
        field: 'subscriptionDaysRemaining',
        headerName: 'License Days Left',
        type: 'number',
      },
      {
        field: 'isCurrentlyFreeTrial',
        headerName: 'Current Free Trial',
        type: 'boolean',
      },
      {
        field: 'freeTrialDaysRemaining',
        headerName: 'Free Trial Days Left',
        type: 'number',
      },
      ...extraColumns,
    ];

    return columns;
  }, [entityTypeOptions, extraColumns, organizationTypeOptions]);
};
