// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { AuthRouteProps } from 'components/shared/Auth';
import { PATHS } from 'utils/constants/routes';
import {
  hasFeatureFlag,
  hasPermission,
  isCreator,
  isDirector,
  isDistrictAdmin,
  isParent,
} from 'state/self/selectors';
import { showFinancialDeposits } from 'utils/constants';
import { useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';
import Calendar from 'pages/Calendar';
import ChecklistFormWrapper from 'pages/Checklists/shared/ChecklistFormWrapper';
import Checklists from 'pages/Checklists/All';
import ChecklistsShow from 'pages/Checklists/Show';
import Communication from 'pages/Communication';
import CommunicationSettings from 'pages/Settings/CommunicationSettings';
import Dashboard from 'pages/Dashboard';
import DirectorCreateOrganization from 'pages/Organizations/New/DirectorCreateOrganization';
import Directors from 'pages/People/Directors/All';
import DirectorsEdit from 'pages/People/Directors/Edit';
import DirectorsNew from 'pages/People/Directors/New';
import DirectorsShow from 'pages/People/Directors/Show';
import DistrictLibraryAll from 'pages/Library/DistrictLibraryAll';
import DistrictNew from 'pages/District/New';
import EditDirectorPermissions from 'pages/People/Directors/Show/EditDirectorPermissions';
import EditParentPermissions from 'pages/People/Parents/Show/EditParentPermissions';
import EmailDraft from 'pages/Communication/EmailDraft';
import EmailDrafts from 'pages/Communication/EmailDrafts';
import EmailNew from 'pages/Communication/EmailNew';
import EmailShow from 'pages/Communication/EmailShow';
import EmailSignature from 'pages/Profile/EmailSignature';
import EndOfYear from 'pages/EndOfYear';
import EndOfYearSuccess from 'pages/EndOfYear/EndOfYearSuccess';
import Feedback from 'pages/Feedback';
import Files from 'pages/Files/All';
import FilesByDirectory from 'pages/Files/AllByDirectory';
import FilesEdit from 'pages/Files/Edit';
import FilesNew from 'pages/Files/New';
import FilesShow from 'pages/Files/Show';
import FinancialAccountsEdit from 'pages/Finances/FinancialAccounts/Edit';
import FinancialAccountsNew from 'pages/Finances/FinancialAccounts/New';
import FinancialAccountsShow from 'pages/Finances/FinancialAccounts/Show';
import FinancialCredits from 'pages/Finances/FinancialCredits/All';
import FinancialCreditsEdit from 'pages/Finances/FinancialCredits/Edit';
import FinancialCreditsNew from 'pages/Finances/FinancialCredits/New';
import FinancialCreditsShow from 'pages/Finances/FinancialCredits/Show';
import FinancialCreditsTransfersNew from 'pages/Finances/FinancialCredits/Transfers/New';
import FinancialDeposits from 'pages/Finances/FinancialDeposits/All';
import FinancialDepositsNew from 'pages/Finances/FinancialDeposits/New';
import FinancialDepositsShow from 'pages/Finances/FinancialDeposits/Show';
import FinancialFees from 'pages/Finances/FinancialFees/All';
import FinancialFeesNew from 'pages/Finances/FinancialFees/New';
import FinancialFeesUncategorizedNew from 'pages/Finances/FinancialFees/UncategorizedNew';
import FinancialFundraiserCreditsAll from 'pages/Finances/FinancialFundraiserCredits/All';
import FinancialFundraiserCreditsEdit from 'pages/Finances/FinancialFundraiserCredits/Edit';
import FinancialFundraiserCreditsNew from 'pages/Finances/FinancialFundraiserCredits/New';
import FinancialFundraiserCreditsShow from 'pages/Finances/FinancialFundraiserCredits/Show';
import FinancialItems from 'pages/Finances/FinancialItems/All';
import FinancialItemsEdit from 'pages/Finances/FinancialItems/Edit';
import FinancialItemsNew from 'pages/Finances/FinancialItems/New';
import FinancialItemsShow from 'pages/Finances/FinancialItems/Show';
import FinancialOverview from 'pages/Finances/FinancialOverview';
import FinancialPaymentEdit from 'pages/Finances/FinancialPayments/Edit';
import FinancialPaymentNew from 'pages/Finances/FinancialPayments/New';
import FinancialPaymentProvidersNew from 'pages/Finances/FinancialPaymentProviders/New';
import FinancialPayments from 'pages/Finances/FinancialPayments/All';
import FinancialPaymentsShow from 'pages/Finances/FinancialPayments/Show';
import FinancialSettings from 'pages/Settings/FinancialSettings';
import FinancialTransactions from 'pages/Finances/FinancialTransactions/All';
import FinancialTransactionsShow from 'pages/Finances/FinancialTransactions/Show';
import Forms from 'pages/Forms/All';
import FormsEdit from 'pages/Forms/Edit';
import FormsNew from 'pages/Forms/New';
import FormsShow from 'pages/Forms/Show';
import GettingStarted from 'pages/GettingStarted';
import GoogleCalendarVerification from 'pages/Integrations/GoogleCalendarVerification';
import Groups from 'pages/Groups/All';
import GroupsEdit from 'pages/Groups/Edit';
import GroupsNew from 'pages/Groups/New';
import GroupsShow from 'pages/Groups/Show';
import InactiveMembers from 'pages/People/InactiveMembers/All';
import Inventory from 'pages/Inventory/All';
import InventoryAuditShow from 'pages/InventoryAudits/Show';
import InventoryAudits from 'pages/InventoryAudits/All';
import InventoryCheckoutsBarcodeScan from 'pages/InventoryCheckouts/New/InventoryCheckoutsBarcodeScan';
import InventoryCheckoutsEdit from 'pages/InventoryCheckouts/Edit';
import InventoryCheckoutsNew from 'pages/InventoryCheckouts/New';
import InventoryEdit from 'pages/Inventory/Edit';
import InventoryNew from 'pages/Inventory/New';
import InventoryShow from 'pages/Inventory/Show';
import JoinNewOrganization from 'pages/JoinNewOrganization';
import JoinNewOrganizationSuccess from 'pages/JoinNewOrganization/Success';
import Library from 'pages/Library/All';
import LibraryEdit from 'pages/Library/Edit';
import LibraryNew from 'pages/Library/New';
import LibraryShow from 'pages/Library/Show';
import MembersEdit from 'pages/Members/Edit';
import MembersShow from 'pages/Members/Show';
import MyFiles from 'pages/MyFiles';
import MyFinancialCredits from 'pages/Finances/MyFinancialCredits/All';
import MyFinancialFees from 'pages/Finances/MyFinancialFees/All';
import MyFinancialPayments from 'pages/Finances/MyFinancialPayments/All';
import MyFinancialPaymentsNew from 'pages/Finances/MyFinancialPayments/New';
import MyForms from 'pages/Forms/MyForms/All';
import MyFormsShow from 'pages/Forms/MyForms/Show';
import MyGroups from 'pages/Groups/MyGroups';
import MyInventoryCheckouts from 'pages/MyInventoryCheckouts';
import MyUniformCheckouts from 'pages/MyUniformCheckouts';
import Parents from 'pages/People/Parents/All';
import ParentsEdit from 'pages/People/Parents/Edit';
import ParentsNew from 'pages/People/Parents/New';
import ParentsShow from 'pages/People/Parents/Show';
import PendingMembers from 'pages/People/PendingMembers/All';
import PreviousYearMembers from 'pages/People/PreviousYearMembers/All';
import Profile from 'pages/Profile';
import RelationshipsNew from 'pages/Relationships/New';
import ScheduledEmails from 'pages/Communication/ScheduledEmails';
import Settings from 'pages/Settings';
import StripeSettingsPage from 'pages/Settings/StripeSettingsPage';
import StripeVerification from 'pages/Integrations/StripeVerification';
import Students from 'pages/People/Students/All';
import StudentsEdit from 'pages/People/Students/Edit';
import StudentsNew from 'pages/People/Students/New';
import StudentsShow from 'pages/People/Students/Show';
import SuppressedEmailsList from 'pages/Communication/SuppressedEmailsList';
import UniformCheckoutsBarcodeScan from 'pages/UniformCheckouts/New/UniformCheckoutsBarcodeScan';
import UniformCheckoutsEdit from 'pages/UniformCheckouts/Edit';
import UniformCheckoutsNew from 'pages/UniformCheckouts/New';
import UniformEdit from 'pages/Uniforms/Edit';
import UniformNew from 'pages/Uniforms/New';
import UniformShow from 'pages/Uniforms/Show';
import Uniforms from 'pages/Uniforms/All';
import VancoSettingsPage from 'pages/Settings/VancoSettingsPage';
import useCanEditPermissions from 'hooks/useCanEditPermissions';

export const useGetOrganizationUserRoutes = (): AuthRouteProps[] => {
  const canEditPermissions = useCanEditPermissions();

  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));
  const hasMultiplePaymentProvidersFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const {
    hasPaymentProcessor,
  } = useIsOnlinePaymentsFeatureAvailable();
  const isOnlinePaymentsFeatureAvailable = hasPaymentProcessor;
  const isUserCreator = useSelector(isCreator);
  const isUserDirector = useSelector(isDirector);
  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isUserParent = useSelector(isParent);

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));
  const canEditFinances = useSelector(hasPermission('finances', 'edit'));

  const canReadFinancialAccounts = useSelector(hasPermission('financialAccounts', 'read'));
  const canWriteFinancialAccounts = useSelector(hasPermission('financialAccounts', 'write'));
  const canEditFinancialAccounts = useSelector(hasPermission('financialAccounts', 'edit'));

  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const canWritePayments = useSelector(hasPermission('payments', 'write'));
  const canEditPayments = useSelector(hasPermission('payments', 'edit'));

  const canReadInventory = useSelector(hasPermission('inventory', 'read'));
  const canWriteInventory = useSelector(hasPermission('inventory', 'write'));
  const canEditInventoryBasicInfo = useSelector(hasPermission('inventory', 'edit'));
  const canEditInventoryLocationInfo = useSelector(hasPermission('inventoryLocationInfo', 'edit'));
  const canEditInventoryPurchaseInfo = useSelector(hasPermission('inventoryPurchaseInfo', 'edit'));
  const canEditInventoryStatusInfo = useSelector(hasPermission('inventoryStatusInfo', 'edit'));
  const canEditInventory = canEditInventoryBasicInfo
    || canEditInventoryLocationInfo
    || canEditInventoryPurchaseInfo
    || canEditInventoryStatusInfo;

  const canWriteInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'write'));
  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));

  const canReadLibrary = useSelector(hasPermission('library', 'read'));
  const canWriteLibrary = useSelector(hasPermission('library', 'write'));
  const canEditLibrary = useSelector(hasPermission('library', 'edit'));

  const canReadGroups = useSelector(hasPermission('groups', 'read'));
  const canWriteGroups = useSelector(hasPermission('groups', 'write'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));

  const canReadFiles = useSelector(hasPermission('s3Uploads', 'read'));
  const canWriteFiles = useSelector(hasPermission('s3Uploads', 'write'));
  const canEditFiles = useSelector(hasPermission('s3Uploads', 'edit'));

  const canReadUsers = useSelector(hasPermission('users', 'read'));
  const canWriteUsers = useSelector(hasPermission('users', 'write'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const canReadUniforms = useSelector(hasPermission('uniforms', 'read'));
  const canWriteUniforms = useSelector(hasPermission('uniforms', 'write'));
  const canEditUniforms = useSelector(hasPermission('uniforms', 'edit'));

  const canWriteUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'write'));
  const canEditUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'edit'));

  const checklistsRoutes: AuthRouteProps[] = [
    {
      component: Checklists,
      hasPermission: isUserDirector,
      path: PATHS.CHECKLISTS,
    },
    {
      component: ChecklistFormWrapper,
      hasPermission: isUserDirector,
      path: PATHS.CHECKLISTS_NEW,
    },
    {
      component: ChecklistsShow,
      hasPermission: isUserDirector,
      path: `${PATHS.CHECKLISTS}/:id`,
    },
    {
      component: ChecklistFormWrapper,
      hasPermission: isUserDirector,
      path: `${PATHS.CHECKLISTS}/:id/edit`,
    },
    {
      component: ChecklistFormWrapper,
      hasPermission: isUserDirector,
      path: `${PATHS.CHECKLISTS}/:id/edit/members`,
    },
    {
      component: ChecklistFormWrapper,
      hasPermission: isUserDirector,
      path: `${PATHS.CHECKLISTS}/:id/edit/items`,
    },
  ];
  const emailRoutes: AuthRouteProps[] = [
    {
      component: SuppressedEmailsList,
      path: PATHS.EMAIL_SUPPRESSIONS,
    },
    {
      component: EmailDrafts,
      path: PATHS.EMAIL_DRAFTS,
    },
    {
      component: EmailDraft,
      path: `${PATHS.EMAIL_DRAFTS}/:id`,
    },
    {
      component: ScheduledEmails,
      path: `${PATHS.EMAIL_SCHEDULED}`,
    },
    {
      component: EmailNew,
      path: PATHS.EMAIL_NEW,
    },
    {
      component: EmailShow,
      path: `${PATHS.EMAIL}/:id`,
    },
    {
      component: Communication,
      path: PATHS.COMMUNICATION,
    },
  ];
  const financesRoutes: AuthRouteProps[] = [
    {
      component: FinancialItems,
      hasPermission: hasFinancesFeatureFlag && canReadFinances,
      path: PATHS.FINANCIAL_ITEMS,
    },
    {
      component: FinancialItemsNew,
      hasPermission: hasFinancesFeatureFlag && canWriteFinances,
      path: PATHS.FINANCIAL_ITEMS_NEW,
    },
    {
      component: FinancialItemsShow,
      hasPermission: hasFinancesFeatureFlag && canReadFinances,
      path: `${PATHS.FINANCIAL_ITEMS}/:id`,
    },
    {
      component: FinancialItemsEdit,
      hasPermission: hasFinancesFeatureFlag && canEditFinances,
      path: `${PATHS.FINANCIAL_ITEMS}/:id/edit`,
    },
    {
      component: FinancialFees,
      hasPermission: hasFinancesFeatureFlag && canReadFinances,
      path: PATHS.FINANCIAL_FEES,
    },
    {
      component: FinancialFeesNew,
      hasPermission: hasFinancesFeatureFlag && canWriteFinances,
      path: PATHS.FINANCIAL_FEES_NEW,
    },
    {
      component: FinancialFundraiserCreditsAll,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: PATHS.FINANCIAL_FUNDRAISER_CREDITS,
    },
    {
      component: FinancialFundraiserCreditsNew,
      hasPermission: hasFinancesFeatureFlag && canWritePayments,
      path: PATHS.FINANCIAL_FUNDRAISER_CREDITS_NEW,
    },
    {
      component: FinancialFundraiserCreditsShow,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: `${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/:id`,
    },
    {
      component: FinancialFundraiserCreditsEdit,
      hasPermission: hasFinancesFeatureFlag && canEditPayments,
      path: `${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/:id/edit`,
    },
    {
      component: FinancialFeesUncategorizedNew,
      hasPermission: hasFinancesFeatureFlag && canWriteFinances,
      path: PATHS.FINANCIAL_FEES_UNCATEGORIZED_NEW,
    },
    {
      component: MyFinancialFees,
      path: PATHS.STUDENT_FEES,
    },
    {
      component: FinancialOverview,
      hasPermission: hasFinancesFeatureFlag && canReadPayments && canReadFinances,
      path: PATHS.FINANCIAL_OVERVIEW,
    },
    {
      component: FinancialPayments,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: PATHS.FINANCIAL_PAYMENTS,
    },
    {
      component: MyFinancialPayments,
      path: PATHS.STUDENT_PAYMENTS,
    },
    {
      component: FinancialTransactions,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: PATHS.FINANCIAL_TRANSACTIONS,
    },
    {
      component: FinancialTransactionsShow,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: `${PATHS.FINANCIAL_TRANSACTIONS}/:id`,
    },
    {
      component: MyFinancialPaymentsNew,
      hasPermission: isUserParent && isOnlinePaymentsFeatureAvailable,
      path: `${PATHS.STUDENT_PAYMENTS_NEW}`,
    },
    {
      component: FinancialPaymentNew,
      hasPermission: hasFinancesFeatureFlag && canWritePayments,
      path: PATHS.FINANCIAL_PAYMENTS_NEW,
    },
    {
      component: FinancialPaymentEdit,
      hasPermission: hasFinancesFeatureFlag && canEditPayments,
      path: `${PATHS.FINANCIAL_PAYMENTS}/:id/edit`,
    },
    {
      component: FinancialPaymentsShow,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: `${PATHS.FINANCIAL_PAYMENTS}/:id`,
    },
    {
      component: FinancialPaymentProvidersNew,
      hasPermission: hasFinancesFeatureFlag
        && hasMultiplePaymentProvidersFeatureFlag
        && canWriteFinancialAccounts,
      path: PATHS.FINANCIAL_PAYMENTS_PROVIDERS_NEW,
    },
    {
      component: FinancialAccountsShow,
      hasPermission: hasFinancesFeatureFlag && canReadFinancialAccounts,
      path: `${PATHS.FINANCIAL_ACCOUNTS}/:id`,
    },
    {
      component: FinancialAccountsNew,
      hasPermission: hasFinancesFeatureFlag && canWriteFinancialAccounts,
      path: PATHS.FINANCIAL_ACCOUNTS_NEW,
    },
    {
      component: FinancialAccountsEdit,
      hasPermission: hasFinancesFeatureFlag && canEditFinancialAccounts,
      path: `${PATHS.FINANCIAL_ACCOUNTS}/:id/edit`,
    },
    {
      component: FinancialCredits,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: PATHS.FINANCIAL_CREDITS,
    },
    {
      component: FinancialCreditsNew,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: PATHS.FINANCIAL_CREDITS_NEW,
    },
    {
      component: FinancialCreditsShow,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: `${PATHS.FINANCIAL_CREDITS}/:id`,
    },
    {
      component: FinancialCreditsEdit,
      hasPermission: hasFinancesFeatureFlag && canEditPayments,
      path: `${PATHS.FINANCIAL_CREDITS}/:id/edit`,
    },
    {
      component: FinancialCreditsTransfersNew,
      hasPermission: hasFinancesFeatureFlag && canReadPayments,
      path: `${PATHS.FINANCIAL_CREDITS}/:creditId/transfers/new`,
    },
    {
      component: MyFinancialCredits,
      path: PATHS.STUDENT_CREDITS,
    },
  ];

  if (showFinancialDeposits) {
    financesRoutes.push(
      {
        component: FinancialDeposits,
        hasPermission: hasFinancesFeatureFlag && canReadPayments,
        path: PATHS.FINANCIAL_DEPOSITS,
      },
      {
        component: FinancialDepositsNew,
        hasPermission: hasFinancesFeatureFlag && canReadPayments,
        path: PATHS.FINANCIAL_DEPOSITS_NEW,
      },
      {
        component: FinancialDepositsShow,
        hasPermission: hasFinancesFeatureFlag && canReadPayments,
        path: `${PATHS.FINANCIAL_DEPOSITS}/:id`,
      },
    );
  }

  const calendarRoutes: AuthRouteProps[] = [
    {
      component: Calendar,
      path: PATHS.CALENDAR,
    },
    {
      component: GoogleCalendarVerification,
      hasPermission: isUserDirector,
      path: PATHS.GOOGLE_CALENDAR_VERIFICATION,
    },
  ];
  const districtRoutes: AuthRouteProps[] = [
    {
      component: DistrictNew,
      hasPermission: isUserCreator,
      path: `${PATHS.DISTRICTS}/new`,
    },
  ];
  const endOfYearRoutes: AuthRouteProps[] = [
    {
      component: EndOfYear,
      hasPermission: isUserDirector,
      path: `${PATHS.END_OF_YEAR}`,
    },
    {
      component: EndOfYearSuccess,
      hasPermission: isUserDirector,
      path: `${PATHS.END_OF_YEAR_COMPLETED}`,
    },
  ];
  const formsRoutes: AuthRouteProps[] = [
    {
      component: Forms,
      hasPermission: isUserDirector,
      path: PATHS.FORMS,
    },
    {
      component: FormsEdit,
      hasPermission: isUserDirector,
      path: `${PATHS.FORMS}/:id/edit`,
    },
    {
      component: FormsNew,
      hasPermission: isUserDirector,
      path: `${PATHS.FORMS_NEW}`,
    },
    {
      component: FormsShow,
      path: `${PATHS.FORMS}/:id`,
    },
    {
      component: MyForms,
      path: PATHS.MY_FORMS,
    },
    {
      component: MyFormsShow,
      path: `${PATHS.MY_FORMS}/:id`,
    },
  ];
  const groupRoutes: AuthRouteProps[] = [
    {
      component: Groups,
      hasPermission: canReadGroups,
      path: PATHS.GROUPS,
    },
    {
      component: GroupsNew,
      hasPermission: canWriteGroups,
      path: PATHS.GROUPS_NEW,
    },
    {
      component: GroupsShow,
      hasPermission: canReadGroups,
      path: `${PATHS.GROUPS}/:id`,
    },
    {
      component: GroupsEdit,
      hasPermission: canEditGroups,
      path: `${PATHS.GROUPS}/:id/edit`,
    },
    {
      component: MyGroups,
      path: PATHS.MY_GROUPS,
    },
  ];
  const inventoryRoutes: AuthRouteProps[] = [
    {
      component: Inventory,
      hasPermission: canReadInventory,
      path: PATHS.INVENTORY,
    },
    {
      component: InventoryNew,
      hasPermission: canWriteInventory,
      path: PATHS.INVENTORY_NEW,
    },
    {
      component: InventoryShow,
      hasPermission: canReadInventory,
      path: `${PATHS.INVENTORY}/:id`,
    },
    {
      component: InventoryEdit,
      hasPermission: canEditInventory,
      path: `${PATHS.INVENTORY}/:id/edit`,
    },
    {
      component: InventoryCheckoutsNew,
      hasPermission: canWriteInventoryCheckouts,
      path: `${PATHS.INVENTORY_CHECKOUTS}/new`,
    },
    {
      component: InventoryCheckoutsEdit,
      hasPermission: canEditInventoryCheckouts,
      path: `${PATHS.INVENTORY_CHECKOUTS}/:id/edit`,
    },
    {
      component: InventoryCheckoutsBarcodeScan,
      hasPermission: canWriteUniformCheckouts,
      path: `${PATHS.INVENTORY_CHECKOUTS}/${PATHS.BARCODE_SCAN}`,
    },
    {
      component: MyInventoryCheckouts,
      path: PATHS.STUDENT_INVENTORY,
    },
    {
      component: InventoryAudits,
      path: PATHS.INVENTORY_AUDITS,
    },
    {
      component: InventoryAuditShow,
      path: `${PATHS.INVENTORY_AUDITS}/:id`,
    },
  ];
  const libraryRoutes: AuthRouteProps[] = [
    {
      component: Library,
      hasPermission: canReadLibrary,
      path: PATHS.LIBRARY,
    },
    {
      component: DistrictLibraryAll,
      hasPermission: canReadLibrary,
      path: PATHS.LIBRARY_DISTRICT,
    },
    {
      component: LibraryNew,
      hasPermission: canWriteLibrary,
      path: PATHS.LIBRARY_NEW,
    },
    {
      component: LibraryShow,
      hasPermission: canReadLibrary,
      path: `${PATHS.LIBRARY}/:id`,
    },
    {
      component: LibraryEdit,
      hasPermission: canEditLibrary,
      path: `${PATHS.LIBRARY}/:id/edit`,
    },
  ];
  const fileRoutes: AuthRouteProps[] = [
    {
      component: Files,
      hasPermission: canReadFiles,
      path: PATHS.FILES,
    },
    {
      component: FilesByDirectory,
      hasPermission: canReadFiles,
      path: `${PATHS.FILES}/:directory`,
    },
    {
      component: FilesShow,
      hasPermission: canReadFiles,
      path: `${PATHS.FILES}/:directory/:id`,
    },
    {
      component: FilesEdit,
      hasPermission: canEditFiles,
      path: `${PATHS.FILES}/:directory/:id/edit`,
    },
    {
      component: FilesNew,
      hasPermission: canWriteFiles,
      path: `${PATHS.FILES}/new`,
    },
    {
      component: MyFiles,
      path: `${PATHS.MY_FILES}`,
    },
  ];

  const organizationsRoutes = [
    {
      component: DirectorCreateOrganization,
      hasPermission: isUserDirector,
      path: `${PATHS.ORGANIZATIONS}/new`,
    },
  ];

  const peopleRoutes: AuthRouteProps[] = [
    {
      component: MembersShow,
      path: `${PATHS.MEMBERS}/:id`,
    },
    {
      component: MembersEdit,
      path: `${PATHS.MEMBERS}/:id/edit`,
    },
    {
      component: Parents,
      hasPermission: canReadUsers,
      path: PATHS.PARENTS,
    },
    {
      component: ParentsNew,
      hasPermission: canWriteUsers,
      path: `${PATHS.PARENTS}/new`,
    },
    {
      component: ParentsEdit,
      path: `${PATHS.PARENTS}/:id/edit`,
    },
    {
      component: EditParentPermissions,
      hasPermission: canEditPermissions,
      path: `${PATHS.PARENTS}/:id/edit/${PATHS.PERMISSIONS}`,
    },
    {
      component: ParentsShow,
      path: `${PATHS.PARENTS}/:id`,
    },
    {
      component: Directors,
      path: PATHS.DIRECTORS,
    },
    {
      component: DirectorsNew,
      hasPermission: canWriteUsers,
      path: `${PATHS.DIRECTORS}/new`,
    },
    {
      component: DirectorsEdit,
      hasPermission: canEditUsers || isUserDirector,
      path: `${PATHS.DIRECTORS}/:id/edit`,
    },
    {
      component: EditDirectorPermissions,
      hasPermission: canEditPermissions,
      path: `${PATHS.DIRECTORS}/:id/edit/${PATHS.PERMISSIONS}`,
    },
    {
      component: DirectorsShow,
      hasPermission: canReadUsers,
      path: `${PATHS.DIRECTORS}/:id`,
    },
    {
      component: Students,
      hasPermission: canReadUsers,
      path: PATHS.STUDENTS,
    },
    {
      component: StudentsNew,
      hasPermission: canWriteUsers,
      path: `${PATHS.STUDENTS}/new`,
    },
    {
      component: StudentsEdit,
      path: `${PATHS.STUDENTS}/:id/edit`,
    },
    {
      component: StudentsShow,
      path: `${PATHS.STUDENTS}/:id`,
    },
    {
      component: InactiveMembers,
      hasPermission: canReadUsers,
      path: PATHS.INACTIVE_MEMBERS,
    },
    {
      component: PreviousYearMembers,
      hasPermission: canReadUsers,
      path: `${PATHS.PREVIOUS_YEAR_MEMBERS}/:schoolYear`,
    },
    {
      component: PendingMembers,
      hasPermission: canReadUsers,
      path: PATHS.PENDING_MEMBERS,
    },
  ];
  const profileRoutes: AuthRouteProps[] = [
    {
      component: EmailSignature,
      path: PATHS.EMAIL_SIGNATURE,
    },
    {
      component: RelationshipsNew,
      path: `${PATHS.RELATIONSHIPS}/new`,
    },
    {
      component: Profile,
      path: PATHS.PROFILE,
    },
    {
      component: Settings,
      hasPermission: isDistrictAdminUser || isUserDirector,
      path: PATHS.SETTINGS,
    },
    {
      component: CommunicationSettings,
      path: PATHS.COMMUNICATION_SETTINGS,
    },
    {
      component: FinancialSettings,
      path: PATHS.FINANCIAL_SETTINGS,
    },
    {
      component: StripeSettingsPage,
      path: PATHS.STRIPE_SETTINGS,
    },
    {
      component: VancoSettingsPage,
      path: PATHS.VANCO_SETTINGS,
    },
  ];
  const uniformRoutes: AuthRouteProps[] = [
    {
      component: Uniforms,
      hasPermission: canReadUniforms,
      path: PATHS.UNIFORMS,
    },
    {
      component: UniformNew,
      hasPermission: canWriteUniforms,
      path: `${PATHS.UNIFORMS}/new`,
    },
    {
      component: UniformShow,
      hasPermission: canReadUniforms,
      path: `${PATHS.UNIFORMS}/:id`,
    },
    {
      component: UniformEdit,
      hasPermission: canEditUniforms,
      path: `${PATHS.UNIFORMS}/:id/edit`,
    },
    {
      component: UniformCheckoutsNew,
      hasPermission: canWriteUniformCheckouts,
      path: `${PATHS.UNIFORM_CHECKOUTS}/new`,
    },
    {
      component: UniformCheckoutsEdit,
      hasPermission: canEditUniformCheckouts,
      path: `${PATHS.UNIFORM_CHECKOUTS}/:id/edit`,
    },
    {
      component: UniformCheckoutsBarcodeScan,
      hasPermission: canWriteUniformCheckouts,
      path: `${PATHS.UNIFORM_CHECKOUTS}/${PATHS.BARCODE_SCAN}`,
    },
    {
      component: MyUniformCheckouts,
      path: PATHS.STUDENT_UNIFORMS,
    },
  ];
  const generalRoutes: AuthRouteProps[] = [
    {
      component: Dashboard,
      path: PATHS.DASHBOARD,
    },
    {
      component: GettingStarted,
      hasPermission: isUserDirector,
      path: PATHS.GETTING_STARTED,
    },
    {
      component: StripeVerification,
      hasPermission: isUserDirector,
      path: PATHS.STRIPE_VERIFICATION,
    },
    {
      component: Feedback,
      hasPermission: isUserDirector,
      path: PATHS.FEEDBACK,
    },
    {
      component: JoinNewOrganization,
      path: PATHS.JOIN_NEW_ORGANIZATION,
    },
    {
      component: JoinNewOrganizationSuccess,
      path: `/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}`,
    },
  ];

  return [
    ...calendarRoutes,
    ...checklistsRoutes,
    ...districtRoutes,
    ...endOfYearRoutes,
    ...formsRoutes,
    ...groupRoutes,
    ...generalRoutes,
    ...fileRoutes,
    ...libraryRoutes,
    ...inventoryRoutes,
    ...organizationsRoutes,
    ...peopleRoutes,
    ...profileRoutes,
    ...uniformRoutes,
    ...emailRoutes,
    ...financesRoutes,
  ];
};
