// External Dependencies
import * as Sentry from '@sentry/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactElement, useEffect } from 'react';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { onboardingRedirectUser } from 'utils/lib/onboarding_redirect_user';
import { updateDocumentTitle } from 'utils';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import OnboardingContainer from './OnboardingContainer';

// Local Typings
interface Props {
  children?: ReactElement | null;
}

// Component Definition
const OnboardingWrapper = ({ children = null }: Props) => {
  const { loading, self } = useSelfQuery();
  const navigate = useNavigate();

  useEffect(() => {
    // The split pathname will look like this [ "", "onboarding", "user_profile_info" ]
    const splitPathname = window.location.pathname.split('/');

    updateDocumentTitle(startCase(splitPathname[2]));
  // We want to update the title if the pathname changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const { pathname } = useLocation();

  // This is here to allow the user to refresh the page and
  //  and possibly travel to another onboarding step or the app
  // It will also check for next onboarding stage if a step
  //  refetches the Self data and it has changed
  useEffect(() => {
    const queryString = window.location.search;

    async function handleCheckOnboarding() {
      if (self) {
        // Set the member id in Sentry
        Sentry.setUser({ id: self.id });

        onboardingRedirectUser(
          navigate,
          self.id,
          self.currentOrgId,
          self.hasDistrict,
          self.onboarding.stages,
          self.alertFlagId,
          pathname,
          // if they are coming from the member claim link and are already going through onboarding
          // we do not want to send them to the member claim page when they finish
          {
            ignoreQueryString: queryString.includes(PATHS.JOIN_NEW_ORGANIZATION),
          },
        );
      } else {
        // Clear the member id in Sentry
        Sentry.setUser(null);
      }
    }

    if (self?.onboarding.isComplete && !self?.hasCompletedOnboarding) {
      navigate(`${PATHS.ONBOARDING_COMPLETE}${window.location.search}`);
    } else if (!loading && self) {
      handleCheckOnboarding();
    }
  }, [loading, navigate, pathname, self]);

  return (
    <OnboardingContainer>
      {children ?? <Outlet />}
    </OnboardingContainer>
  );
};

export default OnboardingWrapper;
