// External Dependencies
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';
import { unbecomeUser } from 'utils/api';

export const useUnbecomeUser = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    const response = await unbecomeUser();

    const { error } = response.data as any;

    if (response.status === 200) {
      window.location.href = window.location.origin;
    } else if (error) {
      dispatch(addNotification(error, 'error'));
    }
  }, []);
};
