// External Dependencies
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

// Local Dependencies
import ShowFormCard from './ShowFormCard';

// Local Typings
interface Props {
  formData: GQL.IForm;
  isOpen: boolean;
  onClose: () => void;
}

// Local Variables
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.drawer-paper': {
    [theme.breakpoints.down('md')]: {
      minWidth: '70%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '50%',
    },
    backgroundColor: theme.palette.mainContentBackground,
    minWidth: '90%',
    padding: theme.spacing(2, 0, 2),
  },
}));

// Component Definition
const PreviewFormDrawer = ({
  formData,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  return (
    <StyledDrawer
      PaperProps={{
        className: 'drawer-paper',
      }}
      anchor="right"
      onClose={onClose}
      open={isOpen}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: 'absolute',
          right: 28,
          top: 20,
        }}
      >
        <CloseIcon />
      </IconButton>

      <ShowFormCard formData={formData} />
    </StyledDrawer>
  );
};

export default PreviewFormDrawer;
