// External Dependencies
import {
  CardContent, IconButton,
} from '@mui/material';
import { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { EnhancedCard, ShowCardHeader, ShowPageDataDisplay } from 'components/shared';

// Local Typings
export interface CardDetails {
  brand: string;
  expMonth: string;
  expYear: string;
  lastFour: string;
  paymentMethodToken: string;
}
interface Props {
  cardDetails: CardDetails | null;
  onEdit: () => void;
}

// Component Definition
const PaymentMethodCard: FC<Props> = ({
  cardDetails,
  onEdit,
}) => (cardDetails ? (
  <EnhancedCard>
    <ShowCardHeader
      action={(
        <IconButton
          aria-label="Edit payment method"
          onClick={onEdit}
          size="large"
        >
          <EditIcon />
        </IconButton>
      )}
      title="Payment Method"
    />

    <CardContent>
      <ShowPageDataDisplay
        label="Card Type"
        value={startCase(cardDetails.brand)}
      />

      <ShowPageDataDisplay
        label="Last Four"
        value={cardDetails.lastFour}
      />

      <ShowPageDataDisplay
        label="Exp"
        value={`${cardDetails.expMonth} / ${cardDetails.expYear}`}
      />
    </CardContent>
  </EnhancedCard>
) : null);

export default PaymentMethodCard;
