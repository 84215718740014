// External Dependencies
import {
  Button, ListItem, ListItemText, Typography,
} from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { Invitation } from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import { formatDateTime } from 'utils';
import { useResendInvitationToDfa } from 'gql/mutations';

// Local Typings
interface Props {
  divider: boolean;
  invitation: Invitation;
}

// Local Variables
const oneHour = 1000 * 60 * 60;

// Component Definition
const InvitationListItem: FC<Props> = ({ divider, invitation }) => {
  const dispatch = useDispatch();

  const [resendInvitation] = useResendInvitationToDfa({
    onCompleted: () => {
      dispatch(addNotification('Invitation resent!', 'success'));
    },
  });

  const handleClick = (invitationId: string) => () => {
    resendInvitation({
      variables: {
        invitationId,
      },
    });
  };

  const now = Date.now();
  const canResend = now - Number(invitation.invitedAt) > oneHour;

  return (
    <ListItem divider={divider}>
      <ListItemText
        primary={invitation.dfaEmail}
        secondary={formatDateTime(invitation.invitedAt)}
      />

      {canResend ? (
        <Button
          color="primary"
          onClick={handleClick(invitation.id)}
        >
          Resend
        </Button>
      ) : (
        <Typography color="textSecondary">
          Sent!
        </Typography>
      )}
    </ListItem>
  );
};

export default InvitationListItem;
