// External Dependencies
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { FC, useMemo } from 'react';
import htmlToDraft from 'html-to-draftjs';
import styled from 'styled-components';

// Local Typings
interface Props {
  editorState: any;
  isSimpleToolbar?: boolean;
  isToolbarHidden?: boolean;
  minEditorHeight?: string | number;
  onEditorStateChange: (newEditorState: any) => void;
  placeholder?: string;
}

// Local Variables
export const htmlToDraftBlocks = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return EditorState.createWithContent(contentState);
};

const StyledSpan = styled.span<{ minHeight: string | number }>(({
  minHeight,
  theme,
}) => ({
  '.colorPickerPopup': {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    // This size fits five color swatches across the popup
    width: 140,
  },
  '.dropDown': {
    maxHeight: theme.spacing(14),
  },
  '.editor': {
    minHeight,
    overflow: 'initial',
  },
  '.editorToolbar': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[300],
    borderColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: '6px 4px 2px',
  },
  '.editorWrapper': {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: theme.spacing(1),
  },
  '.emojiPopup': {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    // This size fits five emoji across the popup
    width: 148,
  },
  '.innerToolbar': {
    flexWrap: 'nowrap',
  },
  '.linkPopup': {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    // This size fits link inputs nicely
    width: 200,
  },
  '.rdw-dropdown-optionwrapper': {
    backgroundColor: theme.palette.grey[200],
  },
  '.rdw-dropdown-optionwrapper:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '.rdw-dropdown-wrapper': {
    backgroundColor: theme.palette.grey[50],
  },
  '.rdw-dropdown-wrapper:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '.rdw-dropdownoption-active': {
    backgroundColor: theme.palette.grey[300],
  },
  '.rdw-dropdownoption-highlighted': {
    backgroundColor: theme.palette.grey[400],
  },
  '.rdw-emoji-modal, .rdw-link-modal, .rdw-colorpicker-modal': {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[200],
    boxShadow: `0px 0px 4px ${theme.palette.grey[500]}`,
    zIndex: 10_000,
  },
  '.rdw-link-modal-btn': {
    backgroundColor: theme.palette.common.white,
  },
  '.rdw-link-modal-btn:disabled': {
    backgroundColor: theme.palette.grey[300],
  },
  '.rdw-option-wrapper': {
    '& img': theme.palette.mode === 'dark' ? {
      filter: 'invert(1)',
    } : {},

    backgroundColor: theme.palette.grey[50],
  },
  '.toolbarIcon': {
    borderRadius: theme.shape.borderRadius,
  },
}));

// Local Functions
const WysiwygEditor: FC<Props> = ({
  editorState,
  isSimpleToolbar,
  isToolbarHidden = true,
  minEditorHeight = '20vh',
  onEditorStateChange,
  placeholder = 'Type your message...',
}) => {
  const toolbarOptions = useMemo(() => {
    const options = isSimpleToolbar
      ? [
        'inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link',
      ]
      : [
        'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history',
      ];

    return {
      blockType: {
        className: 'innerToolbar',
        dropdownClassName: 'dropDown',
      },
      colorPicker: {
        className: 'innerToolbar',
        popupClassName: 'colorPickerPopup',
      },
      emoji: {
        popupClassName: 'emojiPopup',
      },
      fontFamily: {
        className: 'innerToolbar',
        dropdownClassName: 'dropDown',
      },
      fontSize: {
        className: 'innerToolbar',
        dropdownClassName: 'dropDown',
      },
      inline: {
        bold: {
          className: 'toolbarIcon',
        },
        className: 'innerToolbar',
        italic: {
          className: 'toolbarIcon',
        },
        options: isSimpleToolbar
          ? ['bold', 'italic', 'underline']
          : ['bold', 'italic', 'underline', 'strikethrough'],
        strikethrough: {
          className: 'toolbarIcon',
        },
        underline: {
          className: 'toolbarIcon',
        },
      },
      link: {
        popupClassName: 'linkPopup',
      },
      list: {
        options: ['unordered', 'ordered'],
      },
      options,
    };
  }, [isSimpleToolbar]);

  return (
    <StyledSpan minHeight={minEditorHeight}>
      <Editor
        editorClassName="editor"
        editorState={editorState}
        handlePastedText={() => false}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        spellCheck
        toolbar={toolbarOptions}
        toolbarClassName="editorToolbar"
        toolbarHidden={isToolbarHidden}
        toolbarStyle={{
          display: isToolbarHidden ? 'none' : 'flex',
          transition: 'display 0s linear 0ms',
        }}
        wrapperClassName="editorWrapper"
      />
    </StyledSpan>
  );
};

export default WysiwygEditor;
