// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface OrgEntityTypesResponse {
  organizationEntityTypes: GQL.IOrganizationEntityType[];
}

export const GET_ORGANIZATION_ENTITY_TYPES = gql`
  query OrganizationEntityTypes {
    organizationEntityTypes {
        id
        label
    }
  }
`;

export const useGetOrganizationEntityTypes = () =>
  useQueryEnhanced<OrgEntityTypesResponse>(
    GET_ORGANIZATION_ENTITY_TYPES,
  );
