// External Dependencies
import { FormBlockTypes } from '@presto-assistant/api_types';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useDebounce } from 'use-debounce';
import { useEffect, useMemo } from 'react';

// Local Dependencies
import CheckboxBlock from './CheckboxBlock';
import InitialsBlock from './InitialsBlock';
import SelectBlock from './SelectBlock';
import SignatureBlock from './SignatureBlock';
import TextBlock from './TextBlock';

// Local Typings
interface Props {
  disabled?: boolean;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
  onFormBlockSubmit: (values: GQL.ISubmitFormBlockResponseInput) => void;
  setIsFormDirty: (isFormDirty: boolean) => void;
  values: GQL.ISubmitFormBlockResponseInput;
}

// Component Definition
const FormContent = ({
  disabled,
  formBlock,
  onFormBlockSubmit,
  setIsFormDirty,
  values,
}: Props): JSX.Element | null => {
  const initialResponse = useMemo(() => {
    return formBlock.response ?? '';
    // we don't want to update the initial response when the form block changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [debouncedResponse] = useDebounce(values.response, 500);

  const isFormDirty = useMemo(() => {
    return values.response.toString() !== formBlock.response?.toString();
  }, [formBlock, values]);

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [isFormDirty, setIsFormDirty]);

  useEffect(() => {
    if (debouncedResponse !== initialResponse) {
      onFormBlockSubmit({
        ...values,
        response: debouncedResponse.toString(),
      });
    }
  // we don't want to submit on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedResponse, initialResponse]);

  const content = useMemo(() => {
    const formBlockTypeId = Number(formBlock.formBlockType.id);

    switch (formBlockTypeId) {
      case FormBlockTypes.Text:
        return (
          <TextBlock
            disabled={disabled}
            formBlock={formBlock}
          />
        );
      case FormBlockTypes.TextArea:
        return (
          <TextBlock
            disabled={disabled}
            formBlock={formBlock}
            isTextArea
          />
        );
      case FormBlockTypes.Select:
        return (
          <SelectBlock
            disabled={disabled}
            formBlock={formBlock}
          />
        );
      case FormBlockTypes.Boolean:
        return (
          <CheckboxBlock
            disabled={disabled}
            formBlock={formBlock}
          />
        );
      case FormBlockTypes.Date:
        return (
          <TextBlock
            disabled={disabled}
            formBlock={formBlock}
            type="date"
          />
        );
      case FormBlockTypes.Number:
        return (
          <TextBlock
            disabled={disabled}
            formBlock={formBlock}
            type="number"
          />
        );
      case FormBlockTypes.Signature:
        return (
          <SignatureBlock
            disabled={disabled}
            formBlock={formBlock}
          />
        );
      case FormBlockTypes.Initials:
        return (
          <InitialsBlock
            disabled={disabled}
            formBlock={formBlock}
          />
        );
      default:
        return null;
    }
  }, [disabled, formBlock]);

  return content;
};

export default FormContent;
