// External Dependencies
import { Link } from 'react-router-dom';
import { ShieldAccount } from 'mdi-material-ui';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  Flex,
  PrestoLogoTextAssistantOnly,
  PrestoLogoTextPrestoOnlySvg,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isFullAccessDfa } from 'state/self/selectors';
import { useGetSelf } from 'gql/queries';
import Heading1 from 'components/shared/Heading1';
import OrganizationLogo from 'components/shared/OrganizationLogo/OrganizationLogo';

// Local Dependencies
import SideNavLinks from './SideNavLinks';
import SubStatusBadge from './SubStatusBadge';

// Local Typings
interface SideNavProps {
  onLinkClick?: () => void;
}

// Local Variables
const StyledRoot = styled.section(({ theme }) => ({
  '.link': {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  '.list': {
    overflowY: 'auto',
    width: '100%',
  },
  '.titleContainer': {
    display: 'flex',
    height: theme.spacing(13),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  alignItems: 'center',
  background: theme.palette.background.default,
  borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'baseline',
  minWidth: theme.spacing(30),
}));

// Component Definition
const SideNav = ({ onLinkClick }: SideNavProps): JSX.Element | null => {
  const {
    data,
    error,
    loading,
  } = useGetSelf();

  const isFullAccessAdminUser = useSelector(isFullAccessDfa);

  const {
    currentOrgId,
    districtMemberships,
    hasDistrict,
    id: memberId,
    role,
    userOrgData,
  } = data?.self ?? {};

  const isMemberIdFoundInUserOrgs = userOrgData?.some(
    (org: GQL.IUserOrganization) => org?.member?.id === memberId,
  );

  // This is a DFA who is signed in under a specific organization
  // hasDistrict means they have a member profile as a DFA or DFA asst
  // not having a member id in the user orgs means they are not a member of the org
  const loggedInAsDistrictAdmin = hasDistrict && !isMemberIdFoundInUserOrgs;

  const currentOrg = userOrgData?.find(
    (org: GQL.IUserOrganization) => org?.organizationId === currentOrgId
        && (org?.member?.id === memberId || loggedInAsDistrictAdmin),
  );

  // We check if the current user is an admin in the current org
  const isAdminInCurrentOrg = currentOrg?.admin;

  const loggedInAsDirector = (role?.label === 'Adult' && isAdminInCurrentOrg) || isFullAccessAdminUser;

  // orphan user
  if (!loggedInAsDistrictAdmin && !currentOrg) {
    return null;
  }

  const districtMember = loggedInAsDistrictAdmin
    ? districtMemberships?.find((d) => d.id === memberId)
    : null;

  const districtLabel = districtMember?.district?.label;

  if (!data || error || loading) {
    return null;
  }

  return (
    <StyledRoot>
      <div className="titleContainer">
        <Heading1>
          <Link
            aria-label={`${APP_NAME} Logo.`}
            className="link"
            onClick={onLinkClick}
            to={`/${PATHS.DASHBOARD}`}
          >
            <PrestoLogoTextPrestoOnlySvg />
            <PrestoLogoTextAssistantOnly />
          </Link>
        </Heading1>
      </div>

      <OrganizationLogo />

      <div>
        <Chip
          component="div"
          label={(
            <Flex
              gap={0.5}
              paddingY={0.5}
            >
              {loggedInAsDistrictAdmin || isFullAccessAdminUser
                ? (
                  <ShieldAccount
                    color="primary"
                    fontSize="small"
                  />
                ) : null}

              <Typography
                align="center"
                component="p"
                lineHeight={1}
                maxWidth={200}
                variant="caption"
                whiteSpace="break-spaces"
              >
                {currentOrg?.organization?.label ?? districtLabel}
              </Typography>
            </Flex>
          )}
          size="small"
          sx={{ height: '100%' }}
          variant="outlined"
        />
      </div>

      {(loggedInAsDirector || isFullAccessAdminUser) && <SubStatusBadge onlyShowIfActionNeeded />}

      <List
        className="list"
        component="nav"
      >
        <SideNavLinks onLinkClick={onLinkClick} />
      </List>
    </StyledRoot>
  );
};

export default SideNav;
