// External Dependencies
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Dependencies
import { StyledCardContent } from './styles';
import PaymentFormItem from './PaymentFormItem';
import PaymentFormStudent from './PaymentFormStudent';

// Component Definition
const PaymentFormGateway = (): JSX.Element => {
  return (
    <EnhancedCard>
      <PaymentFormStudent />

      <StyledCardContent>
        <Typography>
          — or —
        </Typography>
      </StyledCardContent>

      <StyledCardContent>
        <PaymentFormItem />
      </StyledCardContent>
    </EnhancedCard>
  );
};

export default PaymentFormGateway;
