// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToMyGroups: FC = () => {
  const myGroupsParams = useSelector(tableQueryParams('myGroups'));

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View groups you are a member of
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.MY_GROUPS}${myGroupsParams}`}>
        My Groups
      </ForwardButton>
    </Box>
  );
};

export default LinkToMyGroups;
