// External Dependencies
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import TableCell from '@mui/material/TableCell';
import styled from 'styled-components';

// Internal Dependencies
import { CustomSelect, MoneyInput } from 'components/shared';
import { FinancialFeeTypeAhead } from 'components/shared/TypeAheads';
import { convertCentsToDollars, displayPriceStringFromDollarAmount, getParamFormattedDate } from 'utils';
import { useFormikTextField } from 'hooks/useFormikTextField';
import { useGetCreditsByUserId, useGetFees } from 'gql/queries';
import { useGetPaymentTypeOptions } from 'hooks/useGetPaymentTypeOptions';

// Local Dependencies
import { StyledTableCellTextBox, StyledTableRow } from '../styles';
import PaymentFormTableInput from '../PaymentFormTableInput';

// Local Typings
interface Props {
  financialAccountId: string;
  financialFeeId: string;
  financialItemId: string;
  onClickRemove: () => void;
  rowIndex: number;
  selectedFeeIds: string[];
}

// Local Variables
const StyledCustomSelect = styled(CustomSelect)(({ theme }) => ({
  '.MuiNativeSelect-select': {
    padding: theme.spacing(1),
  },
}));
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const PaymentsByItemTableRow = ({
  financialAccountId,
  financialFeeId,
  financialItemId,
  onClickRemove,
  rowIndex,
  selectedFeeIds,
}: Props): JSX.Element => {
  const amountField = useFormikTextField(`payments[${rowIndex}].amountInCents`, '0.00');
  const checkNumberField = useFormikTextField(`payments[${rowIndex}].checkNumber`);
  const creditCardAccountHolderField = useFormikTextField(`payments[${rowIndex}].creditCardAccountHolder`);
  const creditCardExpField = useFormikTextField(`payments[${rowIndex}].creditCardExp`, '', { format: 'ccExp' });
  const creditCardLastFourField = useFormikTextField(`payments[${rowIndex}].creditCardLastFour`, '', { max: 4 });
  const creditField = useFormikTextField(`payments[${rowIndex}].creditAppliedAmountInCents`, '0.00');
  const datePaidField = useFormikTextField(`payments[${rowIndex}].datePaid`, getParamFormattedDate(new Date()));
  const noteField = useFormikTextField(`payments[${rowIndex}].note`, '');
  const otherLabelField = useFormikTextField(`payments[${rowIndex}].otherLabel`, '');
  const referenceNumberField = useFormikTextField(`payments[${rowIndex}].referenceNumber`, '');

  const { options: financialPaymentTypeOptions } = useGetPaymentTypeOptions({
    removeOnlineOption: true,
  });

  const {
    errors, setFieldValue, submitCount, values,
  } = useFormikContext();

  const handleSelectFee = useCallback((fee: GQL.IFinancialFee | null) => {
    setFieldValue(`payments[${rowIndex}].financialFeeId`, fee?.id ?? '');
  }, [rowIndex, setFieldValue]);

  const {
    getFees,
    results: { data: allFeesData },
  } = useGetFees({ fetchPolicy: 'cache-first' });

  useEffect(() => {
    getFees({ variables: { where: { financialItemId } } });
  }, [getFees, financialItemId]);

  const fee = useMemo(() => allFeesData?.financialFees.data.find(
    (f) => f.id === financialFeeId,
  ), [allFeesData, financialFeeId]);

  const feeError = (errors as any)?.payments?.[rowIndex]?.financialFeeId;

  const rowValue = (values as any)?.payments?.[rowIndex] as GQL.ICreateFinancialPaymentInput;

  const paymentTypeValue = rowValue?.financialPaymentTypeId;

  const {
    data: userCreditsData,
  } = useGetCreditsByUserId(fee?.user.id ?? '');

  const creditTotalRemainingInCents = useMemo(
    () => (userCreditsData?.financialCreditsByUserId ?? [])
      .filter((c) => {
        const matchesAccount = !!c.financialAccount && c.financialAccount.id === financialAccountId;
        const isClosed = Boolean(c.closedAt);

        return matchesAccount && !isClosed;
      })
      .reduce((prev, curr) => prev + (curr.isRefunded
        ? 0 : curr.creditRemainingInCents), 0),
    [financialAccountId, userCreditsData],
  );

  const isCheck = paymentTypeValue === FinancialPaymentTypes.Check.toString();
  const isCreditCard = paymentTypeValue === FinancialPaymentTypes.CreditCard.toString();
  const isOtherPaymentType = paymentTypeValue === FinancialPaymentTypes.Other.toString();

  useEffect(() => {
    if (!isCreditCard) {
      setFieldValue(`payments[${rowIndex}].creditCardLastFour`, '');
      setFieldValue(`payments[${rowIndex}].creditCardExp`, '');
      setFieldValue(`payments[${rowIndex}].creditCardAccountHolder`, '');
    }
  }, [isCreditCard, rowIndex, setFieldValue]);

  useEffect(() => {
    if (!isCheck) {
      setFieldValue(`payments[${rowIndex}].checkNumber`, '');
    }
  }, [isCheck, rowIndex, setFieldValue]);

  useEffect(() => {
    if (!isOtherPaymentType) {
      setFieldValue(`payments[${rowIndex}].otherLabel`, '');
      setFieldValue(`payments[${rowIndex}].referenceNumber`, '');
    }
  }, [isOtherPaymentType, rowIndex, setFieldValue]);

  const financialFeeTypeAhead = useMemo(() => (
    <FinancialFeeTypeAhead
      fetchPolicy="cache-first"
      financialItemId={financialItemId}
      hiddenIds={selectedFeeIds}
      onSelectFee={handleSelectFee}
      selectedId={financialFeeId}
      textFieldProps={{
        error: Boolean(submitCount > 0 && feeError),
        helperText: submitCount > 0 && feeError,
        label: '',
        margin: 'none',
        size: 'small',
        variant: 'outlined',
      }}
    />
  ), [
    financialFeeId,
    financialItemId,
    handleSelectFee,
    feeError,
    selectedFeeIds,
    submitCount,
  ]);

  return (
    <StyledTableRow>
      <TableCell>
        <Box marginY={0.5}>
          <IconButton
            color="primary"
            onClick={onClickRemove}
            size="small"
          >
            <RemoveIcon />
          </IconButton>
        </Box>
      </TableCell>

      <TableCell>
        {financialFeeTypeAhead}
      </TableCell>

      <TableCell align="right">
        <PaymentFormTableInput
          InputProps={{
            inputProps: {
              'aria-label': 'date paid',
            },
          }}
          type="date"
          {...datePaidField}
        />
      </TableCell>

      <TableCell>
        <StyledCustomSelect
          inputProps={{
            'aria-label': 'Payment type',
          }}
          label=""
          margin="dense"
          name={`payments[${rowIndex}].financialPaymentTypeId`}
          options={financialPaymentTypeOptions}
          required
          size="small"
          variant="outlined"
        />
      </TableCell>

      <TableCell align="right">
        <StyledTableCellTextBox>
          {fee
            ? displayPriceStringFromDollarAmount(
              convertCentsToDollars(fee?.remainingBalanceInCents),
            )
            : '—'}
        </StyledTableCellTextBox>
      </TableCell>

      <TableCell align="right">
        <PaymentFormTableInput
          InputProps={{
            inputComponent: MoneyInput as any,
            inputProps: {
              'aria-label': 'amount',
            },
            startAdornment,
          }}
          {...amountField}
        />
      </TableCell>

      <TableCell align="right">
        <StyledTableCellTextBox>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(creditTotalRemainingInCents),
          )}
        </StyledTableCellTextBox>
      </TableCell>

      <TableCell align="right">
        <PaymentFormTableInput
          InputProps={{
            inputComponent: MoneyInput as any,
            inputProps: {
              'aria-label': 'Credit applied',
            },
            startAdornment,
          }}
          {...creditField}
        />
      </TableCell>

      <TableCell>
        {isCheck ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Check number',
              },
            }}
            {...checkNumberField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        {isCreditCard ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Credit card last four',
              },
            }}
            {...creditCardLastFourField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        {isCreditCard ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Credit card exp',
              },
            }}
            {...creditCardExpField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        {isCreditCard ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Credit card account holder',
              },
            }}
            {...creditCardAccountHolderField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        {isOtherPaymentType ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Payment type name',
              },
            }}
            {...otherLabelField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        {isOtherPaymentType ? (
          <PaymentFormTableInput
            InputProps={{
              inputProps: {
                'aria-label': 'Reference number',
              },
            }}
            {...referenceNumberField}
          />
        ) : <StyledTableCellTextBox>—</StyledTableCellTextBox>}
      </TableCell>

      <TableCell>
        <PaymentFormTableInput
          InputProps={{
            inputProps: {
              'aria-label': 'Note',
            },
          }}
          {...noteField}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default PaymentsByItemTableRow;
