// External Dependencies
import { useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import EmailArrowRightIcon from 'mdi-material-ui/EmailArrowRight';
import List from '@mui/material/List';
import OfficeBuildingPlusIcon from 'mdi-material-ui/OfficeBuildingPlus';
import Popover, { PopoverProps } from '@mui/material/Popover';
import SearchIcon from '@mui/icons-material/Search';

// Internal Dependencies
import {
  EnhancedMenuItem,
  NavMenuItem,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import FindOrganizationDialog from './FindOrganizationDialog';
import InviteOrganizationDialog from './InviteOrganizationDialog';

// Local Variables
const anchorOrigin: PopoverProps['anchorOrigin'] = {
  horizontal: 'right',
  vertical: 'top',
};

// Component Definition
const CreateOrganizationPopoverButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    isOpen: isInviteNewOrgDialogOpen,
    toggleIsOpen: toggleInviteNewOrgDialogOpen,
  } = useIsOpen();
  const {
    isOpen: isFindExistingOrgDialogOpen,
    toggleIsOpen: toggleFindExistingOrgDialogOpen,
  } = useIsOpen();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'select-create-organization-popover' : undefined;

  const handlePressInviteNewOrgItem = useCallback(() => {
    toggleInviteNewOrgDialogOpen();
    handleClosePopover();
  }, [handleClosePopover, toggleInviteNewOrgDialogOpen]);

  const handlePressFindExistingOrgItem = useCallback(() => {
    toggleFindExistingOrgDialogOpen();
    handleClosePopover();
  }, [handleClosePopover, toggleFindExistingOrgDialogOpen]);

  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        startIcon={<AddIcon />}
        variant="outlined"
      >
        Organization
      </Button>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        onClose={handleClosePopover}
        open={open}
      >
        <List disablePadding>
          <NavMenuItem
            icon={OfficeBuildingPlusIcon}
            onClick={handleClosePopover}
            text="Create a new organization"
            to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/new`}
          />

          <EnhancedMenuItem
            icon={EmailArrowRightIcon}
            onClick={handlePressInviteNewOrgItem}
            text="Invite a new organization"
          />

          <EnhancedMenuItem
            icon={SearchIcon}
            onClick={handlePressFindExistingOrgItem}
            text="Find an existing organization"
          />
        </List>
      </Popover>

      <InviteOrganizationDialog
        isOpen={isInviteNewOrgDialogOpen}
        onClose={toggleInviteNewOrgDialogOpen}
      />

      <FindOrganizationDialog
        isOpen={isFindExistingOrgDialogOpen}
        onClose={toggleFindExistingOrgDialogOpen}
      />
    </>
  );
};

export default CreateOrganizationPopoverButton;
