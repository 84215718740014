// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import LibraryTable from 'pages/DistrictAdmin/AdminLibrary/All/LibraryTable';

// Component Definition
const DistrictLibraryAll = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        District Library Items
      </Subtitle>

      <EnhancedCard>
        <LibraryTable />
      </EnhancedCard>
    </>
  );
};

export default DistrictLibraryAll;
