// External Dependencies
import {
  Box, CardContent, Collapse, Typography,
} from '@mui/material';
import { useCallback } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  OnboardingStepTitle,
  SaveButton,
} from 'components/shared';
import { useGetOrganization } from 'gql/queries';
import {
  useSkipSuccessorOrganizationOnboardingStep,
} from 'gql/mutations';
import EndOfYearGraduatingGrade from 'pages/EndOfYear/EndOfYearMembers/EndOfYearGraduatingGrade';
import EndOfYearSuccessorOrg from 'pages/EndOfYear/EndOfYearMembers/EndOfYearSuccessorOrg';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.subtitle': {
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

// Component Definition
const OnboardingMoreOrganizationInfo = () => {
  const { data: organizationData } = useGetOrganization();

  const [
    skipSuccessorOrganizationOnboardingStep,
    {
      loading: isSkipping,
    },
  ] = useSkipSuccessorOrganizationOnboardingStep();

  const handlePressNextButton = useCallback(() => {
    skipSuccessorOrganizationOnboardingStep();
  }, [skipSuccessorOrganizationOnboardingStep]);

  if (!organizationData?.organization) {
    return null;
  }

  const {
    graduatingGrade,
    label,
    successorOrganization,
  } = organizationData.organization;

  const hasHighSchoolSeniors = graduatingGrade === 12;

  return (
    <>
      <OnboardingStepTitle title="More Organization Info" />

      <StyledCardContent>
        <Typography
          className="subtitle"
          component="h3"
          variant="h6"
        >
          {label}
        </Typography>

        <Box
          component="section"
          mb={3}
          mt={2}
          paddingX={4}
        >
          <Typography
            color="textSecondary"
            paragraph
          >
            Let&apos;s get some more information about your organization. These details will make
            {' '}
            the end-of-year process much smoother.
          </Typography>

          <Typography
            color="textSecondary"
            paragraph
          >
            You can always edit this
            {' '}
            later from your organization&apos;s settings&nbsp;page.
          </Typography>
        </Box>

        <EndOfYearGraduatingGrade
          graduatingGrade={graduatingGrade}
          hideTitle
          label={label}
          maxWidth="100%"
        />

        <Collapse in={Boolean(graduatingGrade !== null && !hasHighSchoolSeniors)}>
          <EndOfYearSuccessorOrg
            hideTitle
            successorOrganization={successorOrganization}
          />
        </Collapse>

        <Box
          display="flex"
          justifyContent="center"
          gap={1}
          marginTop={2}
        >
          <SaveButton
            className="button"
            isSaving={isSkipping}
            onClick={handlePressNextButton}
            size="large"
          >
            Next
          </SaveButton>
        </Box>
      </StyledCardContent>
    </>
  );
};

export default OnboardingMoreOrganizationInfo;
