// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowTopRightThickIcon from 'mdi-material-ui/ArrowTopRightThick';
import KeyIcon from 'mdi-material-ui/Key';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableColumn, displayCell } from 'components/shared/TableV2';
import { TableV2 } from 'components/shared';
import { createActionsColumn } from 'components/shared/TableV2/helpers';
import {
  getDistrictAdminOrganizationIdFromPathname,
} from 'utils';
import { switchToOrgAsDfa } from 'utils/api';
import { useBecomeUser } from 'hooks/useBecomeUser';
import { useGetDistrictDirectorsIndex } from 'gql/queries';

// Local Variables
const keyIcon = <KeyIcon />;
const arrowTopRightThickIcon = <ArrowTopRightThickIcon />;

// Component Definition
const AdminDirectorsTableUI: FC = () => {
  const navigate = useNavigate();
  const [isSwitchingToOrg, setIsSwitchingToOrg] = useState(false);

  const organizationId = useMemo(getDistrictAdminOrganizationIdFromPathname, []);

  const {
    data,
    loading,
  } = useGetDistrictDirectorsIndex(organizationId);

  const columns: TableColumn<GQL.IDistrictDirectorsIndex>[] = useMemo(() => [
    {
      Header: 'First Name',
      accessor: (row) => displayCell(row.firstName),
      sortBy: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: (row) => displayCell(row.lastName),
      sortBy: 'lastName',
    },
    {
      Header: 'Title',
      accessor: (row) => displayCell(row.title),
      sortBy: 'title',
    },
    {
      Header: 'Email',
      accessor: (row) => displayCell(row.email),
      sortBy: 'email',
    },
  ], []);

  const handleClickEditPermissions = useCallback((director: GQL.IDistrictDirectorsIndex) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${organizationId}/${PATHS.DIRECTORS}/${director.id}/edit/${PATHS.PERMISSIONS}`);
  }, [navigate, organizationId]);

  const handlePressAddDirector = async () => {
    setIsSwitchingToOrg(true);

    try {
      await switchToOrgAsDfa({
        organizationId,
        url: `/${PATHS.DIRECTORS}/new`,
      });
    } catch (error) {
      setIsSwitchingToOrg(false);
    }
  };

  const handleBecomeUser = useBecomeUser(organizationId);

  const actionsColumn = createActionsColumn<GQL.IDistrictDirectorsIndex>([
    {
      action: handleClickEditPermissions,
      icon: keyIcon,
      text: 'Edit Permissions',
    },
    {
      action: (row) => handleBecomeUser(row.id),
      icon: arrowTopRightThickIcon,
      text: 'Become User',
    },
  ]);

  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return (
    <TableV2<GQL.IDistrictDirectorsIndex>
      addButtonProps={{
        disabled: isSwitchingToOrg,
        label: 'Director',
        onClick: handlePressAddDirector,
      }}
      columns={columns}
      data={data?.districtDirectorsIndex.data ?? []}
      fullCount={data?.districtDirectorsIndex.fullCount}
      isLoading={loading}
      reduxStateKey="districtOrganizationsDirectors"
      withSearch
      zeroStateMessage={'No directors found. Press the "Add Director" button to switch to this orgnization and add a new director.'}
    />
  );
};

export default AdminDirectorsTableUI;
