// External Dependencies
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { CustomInput } from 'components/shared';

// Local Typings
interface Props {
  inputSize: 'small' | 'medium';
}

// Local Variables
const useStyles = makeStyles({
  content: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  firstCard: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 16,
  },
});

// Component Definition
const InventoryLocationInfoFormFields = ({
  inputSize,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <CustomInput
        label="Location"
        name="location"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Case Number"
        name="caseNumber"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Locker"
        name="locker"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Lock Number"
        name="lockNumber"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Combination"
        name="combination"
        rootStyles={classes.input}
        size={inputSize}
      />
    </>
  );
};

export default InventoryLocationInfoFormFields;
