// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  EnhancedAlert, StyledLink,
} from 'components/shared';
import { useGetOrganization } from 'gql/queries';
import { vancoUrl } from 'utils/constants/urls';

// Component Definition
const VancoBillingAlert: FC = () => {
  const { data: orgData } = useGetOrganization();

  if (!orgData) {
    return null;
  }

  return (
    <Collapse in={Boolean(orgData)}>
      <Box marginBottom={2}>
        <EnhancedAlert
          severity="info"
          title="Billing Details"
        >
          <Typography
            gutterBottom
            variant="body2"
          >
            A non-refundable fee of 3.49% is included
            in the total for each online transaction.
            {' '}
            Secure payment provided by
            {' '}
            <StyledLink
              href={vancoUrl}
              openInNewTab
            >
              Vanco
            </StyledLink>.
          </Typography>
        </EnhancedAlert>
      </Box>
    </Collapse>
  );
};

export default VancoBillingAlert;
