// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToAdultsWithPermissions: FC = () => {
  const districtParentsWithPermissionsParams = useSelector(
    tableQueryParams('districtOrganizationsParentsWithPermissions'),
  );

  const adultsWithPermissionsPath = `${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}${districtParentsWithPermissionsParams}`;

  return (
    <Box
      marginLeft={2}
      marginTop={4}
    >
      <Typography
        role="heading"
        sx={{ marginBottom: 2 }}
        variant="body2"
      >
        View all adults in this organization with expanded permssions
      </Typography>

      <ForwardButton navigateTo={adultsWithPermissionsPath}>
        Adults With Permissions
      </ForwardButton>
    </Box>
  );
};

export default LinkToAdultsWithPermissions;
