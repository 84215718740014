// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';
import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';

// Local Typings
interface Props {
  code: string;
}

// Component Definition
const RedemptionCode: FC<Props> = ({ code }) => (
  <>
    <CopyToClipboardButton
      successMessage="Code copied!"
      textToCopy={code}
      tooltipTitle="Copy redemption code"
    />

    {displayCell(code)}
  </>
);

export default RedemptionCode;
