// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

const EmailTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <>
        Any email you send from {APP_NAME} will appear here.
      </>
    )}
  />
);

export default EmailTableZeroState;
