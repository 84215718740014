// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface LibaryCategoriesOnQueryArguments {
  isAdmin?: boolean;
}
interface LibaryInstrumentationsOnQueryArguments {
  isAdmin?: boolean;
}

const GRADE_LEVEL_SELECT_OPTIONS_QUERY = gql`
  query GradeLevelSelectOptons {
    libraryGradeLevels {
      description
      id
      value
    }
  }
`;

const LIBRARY_CATEGORIES_SELECT_OPTIONS_QUERY = gql`
  query LibraryCategoriesSelectOptons {
    libraryCategories {
      id
      label
    }
  }
`;

const LIBRARY_INSTRUMENTATIONS_SELECT_OPTIONS_QUERY = gql`
  query LibraryInstrumentationsSelectOptons {
    libraryInstrumentations {
      id
      label
    }
  }
`;

export const useGetGradeLevelSelectOptions = () =>
  useQueryEnhanced<{
    libraryGradeLevels: Array<GQL.ILibraryGradeLevel>
  }>(GRADE_LEVEL_SELECT_OPTIONS_QUERY);

export const useGetLibraryCategorySelectOptions = (
  args: LibaryCategoriesOnQueryArguments,
) =>
  useQueryEnhanced<{
    libraryCategories: Array<GQL.ILibraryCategory>
  }>(
    LIBRARY_CATEGORIES_SELECT_OPTIONS_QUERY,
    {
      skip: args.isAdmin,
    },
  );

export const useGetLibraryInstrumentationSelectOptions = (
  args: LibaryInstrumentationsOnQueryArguments,
) =>
  useQueryEnhanced<{
    libraryInstrumentations: Array<GQL.ILibraryInstrumentation>
  }>(
    LIBRARY_INSTRUMENTATIONS_SELECT_OPTIONS_QUERY,
    {
      skip: args.isAdmin,
    },
  );
