// External Dependencies
import {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

// Internal Dependencies
import { Container } from 'components/shared';
import { parseSearch } from 'utils';

// Local Dependencies
import EndOfYearGroups from './EndOfYearGroups';
import EndOfYearItems from './EndOfYearItems';
import EndOfYearMembers from './EndOfYearMembers';
import EndOfYearReview from './EndOfYearReview';
import EndOfYearStepper from './shared/EndOfYearStepper';

// Local Typings
interface Props {
  maxCompletedStep: number;
}

// Local Variables
const endOfYearSteps = [
  <EndOfYearItems key="items" />,
  <EndOfYearMembers key="members" />,
  <EndOfYearGroups key="groups" />,
  <EndOfYearReview key="review" />,
];

// Component Definition
const EndOfYearWrapper: FC<Props> = ({ maxCompletedStep }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const activeStep = useMemo(() => {
    const parsedSearch = parseSearch(search);

    return parsedSearch.step;
  }, [search]);

  // MUI Stepper expects a zero-based index but we track steps
  //  with positive integers to make it easier to talk about
  const handleSetActiveStep = useCallback((newStep: number) => {
    navigate(`?step=${newStep}`);
  }, [navigate]);

  const maxAvailableStep = maxCompletedStep + 1;

  useEffect(() => {
    handleSetActiveStep(Math.min(activeStep ?? maxAvailableStep, maxAvailableStep));
  }, [activeStep, handleSetActiveStep, maxAvailableStep]);

  return (
    <>
      <EndOfYearStepper
        activeStep={activeStep}
        maxAvailableStep={maxAvailableStep}
        onSetActiveStep={handleSetActiveStep}
      />

      <Container maxWidth={600}>
        <Box my={2}>
          {endOfYearSteps[activeStep - 1]}
        </Box>
      </Container>
    </>
  );
};

export default EndOfYearWrapper;
