// External Dependencies
import { FC } from 'react';
import { Formik } from 'formik';

// Local Dependencies
import SchoolYearForm from './SchoolYearForm';

// Local Typings
interface Props {
  getPathOnFilterChange: (schoolYearEnding: number) => string;
  previousYearsOnly?: boolean;
  schoolYearEndingFromUrl: number;
}

// Local Variables
const noop = () => null;

// Component Definition
const SchoolYearDataGridFilter: FC<Props> = ({
  getPathOnFilterChange,
  previousYearsOnly,
  schoolYearEndingFromUrl,
}) => (
  <Formik
    initialValues={{ schoolYearEnding: schoolYearEndingFromUrl }}
    onSubmit={noop as any}
  >
    {({ values }) => (
      <SchoolYearForm
        getPathOnFilterChange={getPathOnFilterChange}
        previousYearsOnly={previousYearsOnly}
        schoolYearFormValues={values}
      />
    )}
  </Formik>
);

export default SchoolYearDataGridFilter;
