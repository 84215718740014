// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

const ADD_ATTACHMENT_TO_EMAIL_DRAFT_MUTATION = gql`
  mutation AddAttachmentToEmailDraft($input: AddAttachmentToEmailDraftInput!) {
    addAttachmentToEmailDraft(input: $input) {
      id
      attachments {
        fileName
        id
        s3Url
      }
    }
  }
`;

const REMOVE_ATTACHMENT_FROM_EMAIL_DRAFT_MUTATION = gql`
  mutation RemoveAttachmentFromEmailDraft($input: RemoveAttachmentFromEmailDraftInput!) {
    removeAttachmentFromEmailDraft(input: $input) {
      id
      attachments {
        fileName
        id
        s3Url
      }
    }
  }
`;

const CANCEL_SCHEDULED_SEND_EMAIL = gql`
  mutation CancelScheduledSendEmail($id: ID!) {
    cancelScheduledSendEmail(id: $id) {
      id
      scheduledSendAt
    }
  }
`;

const CREATE_EMAIL_DRAFT_MUTATION = gql`
  mutation CreateEmailDraft {
    createEmailDraft {
      id
    }
  }
`;

const DISCARD_EMAIL_DRAFT_MUTATION = gql`
  mutation DiscardEmailDraft($id: ID!) {
    discardEmailDraft(id: $id)
  }
`;

const SEND_EMAIL_MUTATION = gql`
  mutation SendEmail($id: ID!) {
    sendEmail(id: $id) {
      id
    }
  }
`;

const SCHEDULED_SEND_EMAIL_MUTATION = gql`
  mutation ScheduledSendEmail($id: ID!, $input: ScheduledSendEmailInput!) {
    scheduledSendEmail(id: $id, input: $input) {
      id
    }
  }
`;

const UPDATE_EMAIL_DRAFT_RECIPIENTS_MUTATION = gql`
  mutation UpdateEmailDraftRecipients($id: ID!, $input: UpdateEmailDraftRecipientsInput!) {
    updateEmailDraftRecipients(id: $id, input: $input) {
      id
      recipients {
        email
        firstName
        lastName
      }
    }
  }
`;

// Return only the id so the user's experience of drafting an email is not interrupted
const UPDATE_EMAIL_DRAFT_MUTATION = gql`
  mutation UpdateEmailDraft($id: ID!, $input: UpdateEmailDraftInput!) {
    updateEmailDraft(id: $id, input: $input) {
      id
      lastSavedAt
    }
  }
`;

export const CREATE_EMAIL = gql`
  mutation CreateEmail(
    $input: CreateEmailInput!
  ) {
    createEmail(
      input: $input
    ) {
      body
      createdAt
      id
      recipients {
        id
        firstName
        lastName
      }
      subject
    }
  }
`;

const CREATE_CC_EMAIL = gql`
  mutation CreateCcEmail(
    $input: CreateCcEmailInput!
  ) {
    createCcEmail(input: $input) {
      email
      id
    }
  }
`;

export const CREATE_ATTACHMENT_PRESIGNED_URL = gql`
  mutation CreateAttachmentPresignedUrl($input: CreatePresignedUrlInput!) {
    createAttachmentPresignedUrl(
      input: $input
    ) {
      getUrl
      putUrl
    }
  }
`;

const DELETE_CC_EMAIL = gql`
  mutation DeleteCcEmail(
    $id: ID!
  ) {
    deleteCcEmail(id: $id) {
      email
      id
    }
  }
`;

export const useAddAttachmentToEmailDraftMutation = (
  options?: MutationOptions<
    { addAttachmentToEmailDraft: GQL.IEmailShow },
    { input: GQL.IAddAttachmentToEmailDraftInput }
  >,
) => useMutationEnhanced<
    { addAttachmentToEmailDraft: GQL.IEmailShow },
    { input: GQL.IAddAttachmentToEmailDraftInput }
  >(
    ADD_ATTACHMENT_TO_EMAIL_DRAFT_MUTATION,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
      ],
    },
  );

export const useRemoveAttachmentFromEmailDraftMutation = (
  options?: MutationOptions<
    { removeAttachmentFromEmailDraft: GQL.IEmailShow },
    { input: GQL.IRemoveAttachmentFromEmailDraftInput }
  >,
) => useMutationEnhanced<
    { removeAttachmentFromEmailDraft: GQL.IEmailShow },
    { input: GQL.IRemoveAttachmentFromEmailDraftInput }
  >(
    REMOVE_ATTACHMENT_FROM_EMAIL_DRAFT_MUTATION,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
      ],
    },
  );

export const useCancelScheduledSendEmail = (
  options?: MutationOptions<
    { cancelScheduledSendEmail: GQL.IEmailShow },
    GQL.ICancelScheduledSendEmailOnMutationArguments
  >,
) => useMutationEnhanced<
  { cancelScheduledSendEmail: GQL.IEmailShow },
  GQL.ICancelScheduledSendEmailOnMutationArguments
  >(
    CANCEL_SCHEDULED_SEND_EMAIL,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
        'emailsIndex',
        'emailScheduledIndex',
      ],
    },
  );

export const useCreateEmailDraft = (
  options?: MutationOptions<
    { createEmailDraft: GQL.IEmailShow },
    null
  >,
) => useMutationEnhanced<
  { createEmailDraft: GQL.IEmailShow },
    null
  >(
    CREATE_EMAIL_DRAFT_MUTATION,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
      ],
    },
  );

export const useDiscardEmailDraft = (
  options?: MutationOptions<
    { discardDraft: GQL.IEmailShow },
    GQL.IDiscardEmailDraftOnMutationArguments
  >,
) => useMutationEnhanced<
  { discardDraft: GQL.IEmailShow },
  GQL.IDiscardEmailDraftOnMutationArguments
  >(DISCARD_EMAIL_DRAFT_MUTATION, {
    ...options,
    clearCachePredicates: [
      ...options?.clearCachePredicates ?? [],
      'emailDraftsIndex',
    ],
  });

export const useSendEmail = (
  options?: MutationOptions<
    { sendEmail: GQL.IEmailShow },
    { id: string; }
  >,
) => useMutationEnhanced<
  { sendEmail: GQL.IEmailShow },
  { id: string; }
>(SEND_EMAIL_MUTATION, options);

export const useScheduledSendEmail = (
  options?: MutationOptions<
    { scheduledSendEmail: GQL.IEmailShow },
    GQL.IScheduledSendEmailOnMutationArguments
  >,
) => useMutationEnhanced<
  { scheduledSendEmail: GQL.IEmailShow },
  GQL.IScheduledSendEmailOnMutationArguments
>(SCHEDULED_SEND_EMAIL_MUTATION, options);

export const useUpdateEmailDraftRecipients = (
  options?: MutationOptions<
    { updateEmailDraftRecipients: GQL.IEmailShow },
    { id: string; input: GQL.IUpdateEmailDraftRecipientsInput; }
  >,
) => useMutationEnhanced<
    { updateEmailDraftRecipients: GQL.IEmailShow },
    { id: string; input: GQL.IUpdateEmailDraftRecipientsInput; }
  >(
    UPDATE_EMAIL_DRAFT_RECIPIENTS_MUTATION,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
      ],
    },
  );

export const useUpdateEmailDraft = (
  options?: MutationOptions<
    { updateEmailDraft: GQL.IEmailShow },
    { id: string; input: GQL.IUpdateEmailDraftInput; }
  >,
) => useMutationEnhanced<
    { updateEmailDraft: GQL.IEmailShow },
    { id: string; input: GQL.IUpdateEmailDraftInput; }
  >(
    UPDATE_EMAIL_DRAFT_MUTATION,
    {
      ...options,
      clearCachePredicates: [
        ...options?.clearCachePredicates ?? [],
        'emailDraftsIndex',
      ],
    },
  );

export const useCreateEmail = (
  options?: MutationOptions<
    GQL.IEmailShow,
    GQL.ICreateEmailOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    GQL.IEmailShow,
    GQL.ICreateEmailOnMutationArguments
  >(CREATE_EMAIL, options);

export const useCreateAttachmentPresignedUrl = (
  options?: MutationOptions<
    { createAttachmentPresignedUrl: GQL.IPresignedUrl },
    GQL.ICreateAttachmentPresignedUrlOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { createAttachmentPresignedUrl: GQL.IPresignedUrl },
    GQL.ICreateAttachmentPresignedUrlOnMutationArguments
  >(CREATE_ATTACHMENT_PRESIGNED_URL, options);

export const useCreateCcEmail = (
  options?: MutationOptions<
    { createCcEmail: GQL.ICcEmail[] },
    GQL.ICreateCcEmailOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { createCcEmail: GQL.ICcEmail[] },
    GQL.ICreateCcEmailOnMutationArguments
  >(CREATE_CC_EMAIL, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'ccEmailList',
    ],
  });

export const useDeleteCcEmail = (
  options?: MutationOptions<
    { deleteCcEmail: GQL.ICcEmail[] },
    GQL.IDeleteCcEmailOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { deleteCcEmail: GQL.ICcEmail[] },
    GQL.IDeleteCcEmailOnMutationArguments
  >(DELETE_CC_EMAIL, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'ccEmailList',
    ],
  });
