// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useGetChecklist } from 'gql/queries';
import { useUpdateChecklist } from 'gql/mutations';

// Local Dependencies
import ChecklistInfoForm from '../shared/ChecklistInfoForm';

// Local Typings
interface Props {
  checklistId: string;
}

// Component Definition
const EditChecklistInfoForm = ({ checklistId }: Props): JSX.Element => {
  const navigate = useNavigate();

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  // For tiny phone screens, we reduce padding on the inputs
  const inputSize = isMobileOrTabletScreen ? 'small' : 'medium';

  const handleNavigateToMembersStep = useCallback((id: string) => {
    navigate(`/${PATHS.CHECKLISTS}/${id}/edit/members?step=2`);
  }, [navigate]);

  const {
    data: checklistData,
    error: getChecklistDataError,
  } = useGetChecklist(checklistId!);

  const [
    updateChecklist,
    {
      error: updateChecklistDataError,
      loading: isUpdatingChecklist,
    },
  ] = useUpdateChecklist(
    {
      clearCachePredicates: ['checklists'],
      onCompleted: (data) => handleNavigateToMembersStep(data.updateChecklist.id),
    },
  );

  const handleSubmit = useCallback((
    values: GQL.ICreateChecklistInput,
  ) => {
    updateChecklist({
      variables: {
        id: checklistId,
        input: {
          ...values,
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  }, [checklistId, updateChecklist]);

  return (
    <ChecklistInfoForm
      checklistData={checklistData?.checklist}
      checklistDataError={getChecklistDataError?.message || updateChecklistDataError?.message}
      inputSize={inputSize}
      loading={isUpdatingChecklist}
      onSubmit={handleSubmit}
    />
  );
};

export default EditChecklistInfoForm;
