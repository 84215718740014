export function isPasswordValid(str) {
  const minPasswordLength = 8;
  const letter = /[a-zA-Z]/;
  const number = /[0-9]/;
  const space = /[\s]/;
  const hasLetter = letter.test(str);
  const hasNumber = number.test(str);
  const hasNoSpaces = !space.test(str) && str.length > 0;
  const isMinLength = str.trim().length >= minPasswordLength;
  const isValid = hasLetter && hasNumber && isMinLength && hasNoSpaces;
  const strengthGuide = {
    hasLetter: {
      helperMessage: 'Must contain at least one letter',
      isValid: hasLetter,
    },
    hasNoSpaces: {
      helperMessage: 'May not include spaces',
      isValid: hasNoSpaces,
    },
    hasNumber: {
      helperMessage: 'Must contain at least one number',
      isValid: hasNumber,
    },
    isMinLength: {
      helperMessage: `Must be at least ${minPasswordLength} characters`,
      isValid: isMinLength,
    },
  };

  return {
    isValid,
    strengthGuide,
  };
}
