// External Dependencies
import { BaseTextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { useMemo } from 'react';

// Local Typings
export type UseFormikInputErrorsResult = Pick<BaseTextFieldProps, 'error' | 'helperText'>;

export const useFormikInputErrors = (name: string): UseFormikInputErrorsResult => {
  const { 1: meta } = useField(name);

  return useMemo<UseFormikInputErrorsResult>(() => {
    const errorMessage = meta.error;
    const isTouched = Boolean(meta.touched);

    return {
      error: isTouched && Boolean(errorMessage),
      helperText: isTouched ? errorMessage : undefined,
    };
  }, [meta, name]);
};
