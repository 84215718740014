// External Dependencies
import { ButtonProps } from '@mui/material';
import { FC, useState } from 'react';

// Local Dependencies
import {
  DiscardConfirmDialog,
  Flex,
  FormActionButtons,
} from 'components/shared';

// Local Typings
interface Props {
  cancelButtonText?: string;
  context: string;
  isButtonDisabled?: boolean;
  isEditing?: boolean;
  isFormTouched: boolean;
  isSubmitting: boolean;
  onPressCancelOrBackButton: () => void;
  onPressSubmit?: () => void;
  submitButtonText?: string;
  type?: ButtonProps['type'];
}

// Component Definition
const FormActions: FC<Props> = ({
  cancelButtonText,
  context,
  isButtonDisabled = false,
  isEditing = false,
  isFormTouched,
  isSubmitting,
  onPressCancelOrBackButton,
  onPressSubmit,
  submitButtonText,
  type,
}) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const handlePressCancelButton = () => {
    if (isFormTouched) {
      setIsCancelDialogOpen(true);
    } else {
      onPressCancelOrBackButton();
    }
  };

  const handleRequestClose = () => {
    setIsCancelDialogOpen(false);
  };

  return (
    <>
      <Flex justifyContent="flex-end">
        <FormActionButtons
          cancelButtonText={cancelButtonText}
          context={context}
          isButtonDisabled={isButtonDisabled}
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          onClickCancelButton={handlePressCancelButton}
          onPressSubmit={onPressSubmit}
          submitButtonText={submitButtonText}
          type={type}
        />
      </Flex>

      <DiscardConfirmDialog
        isOpen={isCancelDialogOpen}
        onConfirm={onPressCancelOrBackButton}
        onRequestClose={handleRequestClose}
      />
    </>
  );
};

export default FormActions;
