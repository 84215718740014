import { EnhancedCard, Subtitle } from 'components/shared';
import EmailTable from './EmailTable';

const AdminCommunicationEmailAll = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Emails
      </Subtitle>

      <EnhancedCard>
        <EmailTable />
      </EnhancedCard>
    </>
  );
};

export default AdminCommunicationEmailAll;
