// External Dependencies
import { CreateReportRequest } from '@presto-assistant/api_types/api/v1/report';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useCreateReport = () => {
  const mutationFn = useCallback((payload: CreateReportRequest['body']) => {
    return apiClient.v1.report.create(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};
