// External Dependencies
import { FC } from 'react';
import {
  IconButton, List, ListItem, Typography,
} from '@mui/material';
import Icon from '@mui/icons-material/CloudDownloadOutlined';

// Local Typings
interface Props {
  attachments: GQL.IEmailAttachment[];
}

// Component Definition
const EmailFooter: FC<Props> = ({ attachments }) => (
  <>
    <Typography
      component="h3"
      variant="subtitle1"
    >
      Attachments
    </Typography>

    <List>
      {attachments.map((attachment) => (
        <ListItem key={attachment.id}>
          <IconButton
            download
            href={attachment.s3Url}
            size="large"
          >
            <Icon />
          </IconButton>
          {attachment.fileName}
        </ListItem>
      ))}
    </List>
  </>
);

export default EmailFooter;
