// External Dependencies
import { DistrictEmailsResponseItem } from '@presto-assistant/api_types/api/v1/communication';
import { useMemo } from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { capitalize } from 'utils';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import {
  useGetDistrictOrganizations,
  useGetOrganizationEntityTypes,
  useGetOrganizationTypes,
} from 'gql/queries';

export const useColumns = () => {
  const {
    data: organizationData,
  } = useGetDistrictOrganizations();

  const organizations = useMemo(() => {
    return organizationData?.districtOrganizations ?? [];
  }, [organizationData]);

  const organizationOptions = useMemo(() => {
    return organizations.map((organization) => ({
      label: organization.label,
      value: organization.id,
    }));
  }, [organizations]);

  const {
    data: orgTypeData,
  } = useGetOrganizationTypes();

  const {
    data: entityTypeOptions,
  } = useGetOrganizationEntityTypes();

  const mappedOrgTypes = useMemo(() =>
    orgTypeData?.organizationTypes.map((orgType) => ({
      id: orgType.id,
      label: orgType.label,
      value: orgType.id,
    })) || [], [orgTypeData]);

  const mappedOrganizationEntityTypes = useMemo(() => {
    return entityTypeOptions?.organizationEntityTypes.map((entityType) => {
      return {
        id: entityType.id,
        label: entityType.label,
        value: entityType.id,
      };
    }) || [];
  }, [entityTypeOptions]);

  return useMemo(() => {
    const columns: DataGridColDef<DistrictEmailsResponseItem>[] = [
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          let color: ChipProps['color'] = 'success';

          if (params.row.status === 'sent') {
            color = 'success';
          } else if (params.row.status === 'draft') {
            color = 'info';
          } else if (params.row.status === 'scheduled') {
            color = 'warning';
          }

          return (
            <Chip
              color={color}
              label={capitalize(params.row.status)}
              size="small"
              variant="outlined"
            />
          );
        },
        sortable: false,
        type: 'singleSelect',
        valueOptions: [
          { label: 'Draft', value: 'draft' },
          { label: 'Sent', value: 'sent' },
          { label: 'Scheduled', value: 'scheduled' },
        ],
        width: 160,
      },
      {
        field: 'subject',
        headerName: 'Subject',
        width: 160,
      },
      {
        field: 'attachmentCount',
        headerName: 'Attachments',
        type: 'number',
        width: 160,
      },
      {
        field: 'recipientCount',
        headerName: 'Recipients',
        type: 'number',
        width: 160,
      },
      {
        field: 'organizationId',
        headerName: 'Organization',
        minWidth: 292,
        renderCell: (params) => params.row.organizationLabel,
        sortable: false,
        type: 'singleSelect',
        valueGetter: (params) => params.row.organizationId,
        valueOptions: organizationOptions,
      },
      {
        field: 'organizationTypeId',
        headerName: 'Organization Type',
        minWidth: 160,
        renderCell: (params) => {
          const organizationType = mappedOrgTypes
            .find((orgType) => Number(orgType.id) === params.row.organizationTypeId);

          return organizationType?.label;
        },
        sortable: false,
        type: 'singleSelect',
        valueOptions: mappedOrgTypes,
      },
      {
        field: 'organizationEntityTypeId',
        filterable: mappedOrganizationEntityTypes?.length > 1,
        headerName: 'Campus Type',
        renderCell: (params) => {
          const entityType = mappedOrganizationEntityTypes
            .find((et) => Number(et.id) === params.row.organizationEntityTypeId);

          return entityType?.label;
        },
        sortable: false,
        type: 'singleSelect',
        valueOptions: mappedOrganizationEntityTypes,
      },
      {
        field: 'senderEmail',
        headerName: 'Sender Email',
        width: 292,
      },
      {
        field: 'senderName',
        headerName: 'Sender Name',
        width: 292,
      },
      {
        field: 'senderType',
        headerName: 'Sender Type',
        renderCell: (params) => capitalize(params.row.senderType),
        type: 'singleSelect',
        valueOptions: [
          { label: 'Director', value: 'director' },
          { label: 'Parent', value: 'parent' },
          { label: 'Student', value: 'student' },
        ],
        width: 292,
      },
      dateTimeColumn({
        field: 'submittedAt',
        headerName: 'Submitted At',
      }),
      dateTimeColumn({
        field: 'scheduledSendAt',
        headerName: 'Scheduled For',
      }),
      dateTimeColumn({
        field: 'sentAt',
        headerName: 'Sent At',
      }),
    ];

    return columns;
  }, [
    mappedOrgTypes,
    mappedOrganizationEntityTypes,
    organizationOptions,
  ]);
};
