// External Dependencies
import { Route } from 'react-router-dom';
import Auth, { AuthRouteProps } from 'components/shared/Auth';

// Helper function to map routes
export const mapRoutes = (route: AuthRouteProps, index: number): JSX.Element => (
  <Route
    element={(
      <Auth hasPermission={route.hasPermission}>
        <route.component />
      </Auth>
    )}
    key={`${route.path}-${index}`}
    path={route.path}
  />
);

// Render the routes array
export const renderRoutes = (routes: AuthRouteProps[]) => routes.map(mapRoutes);
