// External Dependencies
import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Local Variables
const StyledBox = styled(Box)({
  backgroundColor: '#ffffffdd',
  zIndex: 100,
});

// Component Definition
const LoadingOverlay: FC = () => (
  <StyledBox
    alignItems="center"
    bottom={0}
    display="flex"
    justifyContent="center"
    left={0}
    position="absolute"
    right={0}
    top={0}
  >
    <CircularProgress />
  </StyledBox>
);

export default LoadingOverlay;
