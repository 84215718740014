// External Dependencies
import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { darken, lighten } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import ExitIcon from '@mui/icons-material/ExitToApp';
import styled from 'styled-components';

// Internal Dependencies
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useUnbecomeUser } from 'hooks/useUnbecomeUser';

// Local Typings
interface Props {
  isPrestoAdmin: boolean;
}

// Local Variables
const BANNER_HEIGHT = 48;

const StyledSection = styled.section(({ theme }) => ({
  '& + main': {
    marginTop: BANNER_HEIGHT,
  },
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? darken(theme.palette.prestoPrimaryLight, 0.9)
    : lighten(theme.palette.prestoPrimaryLight, 0.94),
  borderBottom: theme.palette.mode === 'dark'
    ? `4px solid ${theme.palette.prestoPrimaryLight}`
    : `4px solid ${theme.palette.prestoPrimary}`,
  boxShadow: `3px 0 5px ${theme.palette.prestoPrimary}`,
  boxSizing: 'border-box',
  display: 'flex',
  height: BANNER_HEIGHT,
  justifyContent: 'space-between',
  padding: theme.spacing(0, 4),
  position: 'fixed',
  width: '100%',
  zIndex: theme.zIndex.appBar,
}));

const StyledTypography = styled(Typography)({
  fontWeight: 500,
});

// Component Definition
const ReadonlyBanner: FC<Props> = ({
  isPrestoAdmin,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleUnbecomeUser = useUnbecomeUser();

  return (
    <StyledSection>
      <StyledTypography>
        {isPrestoAdmin ? 'Viewing as Presto Admin' : 'Viewing in Read-Only Mode'}
      </StyledTypography>

      {!isPrestoAdmin && (
        <Button
          color="primary"
          onClick={handleUnbecomeUser}
          startIcon={<ExitIcon />}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          variant="outlined"
        >
          Exit
        </Button>
      )}
    </StyledSection>
  );
};

export default ReadonlyBanner;
