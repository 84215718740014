// External Dependencies
import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material/';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const DoWeShareIt = () => (
  <>
    <InfoTitle>
      Do we share your information?
    </InfoTitle>

    <InfoSection>
      <Typography>
        {/* eslint-disable-next-line max-len */}
        {APP_NAME} stores this data using only the services necessary. We do not share your information outside of the services we use. These services include:
      </Typography>

      <List dense>
        <ListItem>
          <ListItemText primary="AWS and Heroku (database)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Firebase (database for audit logs)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Sendgrid (email sender)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Stripe (online payment processing)" />
        </ListItem>
      </List>
    </InfoSection>
  </>
);

export default DoWeShareIt;
