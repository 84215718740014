// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  BackToButton,
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import { PATHS } from 'utils/constants/routes';
import PendingMembersTable from './PendingMembersTable';

// Component Definition
const PendingMembers = (): JSX.Element => (
  <>
    <Box mb={2}>
      <BackToButton
        context="Dashboard"
        navigateTo={`/${PATHS.DASHBOARD}`}
      />
    </Box>

    <Subtitle>
      Pending Members
    </Subtitle>

    <Box mb={2}>
      <EnhancedAlert>
        Anyone attempting to join this organization using
        your &quot;Organization Code&quot; will show up here.
        <Box mt={1}>
          <Typography variant="body2">
            Once approved, members will be visible in your Students or Parents data immediately.
          </Typography>
        </Box>
      </EnhancedAlert>
    </Box>

    <EnhancedCard>
      <PendingMembersTable />
    </EnhancedCard>
  </>
);

export default PendingMembers;
