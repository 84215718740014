import { DataGridColDef } from 'types/dataGrid';
import { convertCentsToDollarsAllowNull } from '@presto-assistant/api_types/utils';
import { displayCell } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function getReadFinancesColumns<T>(): DataGridColDef<T>[] {
  return [
    {
      align: 'right',
      field: 'creditsTotalInCents' as keyof T,
      headerName: 'Credit Available *',
      minWidth: 130,
      // Convert back to cents to pass to displayCell correctly
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      // Convert to dollars for intuitive filtering
      valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
    },
    {
      align: 'right',
      field: 'feesAssignedInCents' as keyof T,
      headerName: 'Fees Assigned (YTD) *',
      minWidth: 170,
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
    },
  ];
}

function getReadPaymentsColumns<T>(): DataGridColDef<T>[] {
  return [
    {
      align: 'right',
      field: 'paymentsTotalInCents' as keyof T,
      headerName: 'Fees Paid (YTD) *',
      minWidth: 150,
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
    },
    {
      align: 'right',
      field: 'balanceDueInCents' as keyof T,
      headerName: 'Fees Due (YTD) *',
      minWidth: 150,
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
    },
    {
      align: 'right',
      field: 'previousYearsBalanceDueInCents' as keyof T,
      headerName: 'Fees Due (Previous Years) *',
      minWidth: 200,
      renderCell: (params) => renderMoneyCell(params.value),
      type: 'number',
      valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
    },
  ];
}

export function useFinancialMaterializedViewColumns<T>(): DataGridColDef<T>[] {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  return useMemo<DataGridColDef<T>[]>(() => {
    const readFinancesColumns = getReadFinancesColumns<T>();
    const readPaymentsColumns = getReadPaymentsColumns<T>();

    return [
      ...(canReadFinances ? readFinancesColumns : []),
      ...(canReadPayments ? readPaymentsColumns : []),
    ];
  }, [canReadFinances, canReadPayments]);
}
