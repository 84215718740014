// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { hasPermission } from 'state/self/selectors';
import { useGetDashboardMetrics } from 'gql/queries';

// Local Typings
interface Props {
  inventoryItemId: string;
  onClick: (inventoryItemId: string) => void;
}

// Component Definition
const QuickCheckoutButton = ({
  inventoryItemId,
  onClick,
}: Props): JSX.Element | null => {
  const canCheckoutInventory = useSelector(hasPermission('inventoryCheckouts', 'write'));

  const { data } = useGetDashboardMetrics();

  const handleClick = useCallback((evt) => {
    evt.stopPropagation();

    onClick(inventoryItemId);
  }, [
    inventoryItemId,
    onClick,
  ]);

  if (!canCheckoutInventory) {
    return null;
  }

  const studentCountData = data?.dashboardMetrics.userRoleCounts
    .find((userRoleCount) => userRoleCount.role.id === UserRoles.Student.toString());

  const studentCount = studentCountData?.count;

  return (
    <Button
      disabled={studentCount === 0}
      onClick={handleClick}
      size="small"
    >
      Quick Checkout
    </Button>
  );
};

export default QuickCheckoutButton;
