// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetUniform } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import UniformCheckoutDetails from '../shared/UniformCheckoutDetails';
import UniformForm from '../shared/UniformForm';

// Component Definition
const UniformShowPage: FC = () => {
  const { id } = useParamsWithId();

  const canUseDynamicFields = useCanUseDynamicFields();

  const uniformParams = useSelector(tableQueryParams('uniforms'));

  const canEditUniforms = useSelector(hasPermission('uniforms', 'edit'));
  const canReadUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'read'));

  const {
    data,
    error,
    loading,
  } = useGetUniform(id!);

  return (
    <Page
      backButtonProps={{
        label: 'Uniforms',
        path: `/${PATHS.UNIFORMS}${uniformParams}`,
      }}
      editButtonPath={canEditUniforms ? `/${PATHS.UNIFORMS}/${id}/edit` : ''}
      error={error}
      isLoading={!data || loading}
    >
      {data && (
        <>
          <UniformForm
            canUseDynamicFields={canUseDynamicFields}
            readOnly
            title="Uniform"
            uniform={data?.uniform}
          />
          {canReadUniformCheckouts && (
            <UniformCheckoutDetails uniform={data.uniform} />
          )}
        </>
      )}

      {data?.uniform && (
        <History
          id={data.uniform.id}
          resource="uniforms"
        />
      )}
    </Page>
  );
};

export default UniformShowPage;
