// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const FinancialCreditsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <>
        Data populates in this table when members overpay for fees
        or a director manually adds a credit.
      </>
    )}
  />
);

export default FinancialCreditsTableZeroState;
