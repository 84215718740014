// External Dependencies
import Container from '@mui/material/Container';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';

// Internal Dependencies
import DistrictSettingsForm from './DistrictSettingsForm';

// Component Definition
const DistrictSettings = (): JSX.Element => {
  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}`,
      }}
    >
      <Container maxWidth="md">
        <DistrictSettingsForm />
      </Container>
    </Page>
  );
};

export default DistrictSettings;
