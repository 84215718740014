// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { ContextData } from 'types/context';

// Actions
export const addBannerAlert = createActionCreator(
  'ADD_BANNER_ALERT',
  (resolve) => (bannerAlert: ContextData.IAlertDetail) => resolve(bannerAlert),
);

// Clear all banner alerts
export const clearAllBannerAlerts = createActionCreator(
  'CLEAR_ALL_BANNER_ALERTS',
);

export const removeBannerAlert = createActionCreator(
  'REMOVE_BANNER_ALERT',
  (resolve) => (id: string) => resolve(id),
);

// Used to clear alerts from all parts of state tree
//  that have alert data
export const clearAllAlerts = createActionCreator(
  'CLEAR_ALL_ALERTS',
);
