// External Dependencies
import { DISTRICT_FRAGMENT } from 'gql/queries';
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface UpdateResponse {
  id: string;
}

interface UpdateData {
  updateDistrict: UpdateResponse;
}

interface UpdateOnlinePaymentSettingsData {
  updateDistrictOnlinePaymentsSetting: UpdateResponse;
}

interface UpdateSettingsData {
  updateDistrictSettings: UpdateResponse;
}

export const CREATE_DISTRICT = gql`
  mutation CreateDistrict(
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $label: String!
    $phoneNumber: String!
    $stateId: ID!
    $zipcode: String!
  ) {
    createDistrict(
      addressOne: $addressOne,
      addressTwo: $addressTwo,
      city: $city,
      label: $label,
      phoneNumber: $phoneNumber,
      stateId: $stateId,
      zipcode: $zipcode
    ) {
      ...DistrictFragment
    }
  },
  ${DISTRICT_FRAGMENT}
`;

export const UPDATE_DISTRICT = gql`
  mutation UpdateDistrict(
    $input: UpdateDistrictInput!
  ) {
    updateDistrict(
      input: $input
    ) {
      ...DistrictFragment
    }
  },
  ${DISTRICT_FRAGMENT}
`;

const UPDATE_DISTRICT_ONLINE_PAYMENT_SETTINGS = gql`
  mutation UpdateDistrictOnlinePaymentsSetting(
    $input: UpdateDistrictOnlinePaymentsSettingInput!
  ) {
    updateDistrictOnlinePaymentsSetting(
      input: $input
    ) {
      ...DistrictFragment
    }
  },
  ${DISTRICT_FRAGMENT}
`;

const UPDATE_DISTRICT_SETTINGS = gql`
  mutation UpdateDistrictSettings(
    $input: UpdateDistrictSettingsInput!
  ) {
    updateDistrictSettings(
      input: $input
    ) {
      ...DistrictFragment
    }
  },
  ${DISTRICT_FRAGMENT}
`;

interface CreateDistrictData {
  data: GQL.IDistrict;
}

export function useCreateDistrict(
  options?: MutationOptions<
    CreateDistrictData,
    GQL.ICreateDistrictOnMutationArguments
  >,
) {
  return useMutationEnhanced<
    CreateDistrictData,
    GQL.ICreateDistrictOnMutationArguments
  >(CREATE_DISTRICT, options);
}

export const useUpdateDistrict = (
  options?: MutationOptions<
    UpdateData,
    GQL.IUpdateDistrictOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateData,
  GQL.IUpdateDistrictOnMutationArguments
>(UPDATE_DISTRICT, options);

export const useUpdateDistrictOnlinePaymentSettings = (
  options?: MutationOptions<
    UpdateOnlinePaymentSettingsData,
    GQL.IUpdateDistrictOnlinePaymentsSettingOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateOnlinePaymentSettingsData,
  GQL.IUpdateDistrictOnlinePaymentsSettingOnMutationArguments
>(UPDATE_DISTRICT_ONLINE_PAYMENT_SETTINGS, options);

export const useUpdateDistrictSettings = (
  options?: MutationOptions<
    UpdateSettingsData,
    GQL.IUpdateDistrictSettingsOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateSettingsData,
  GQL.IUpdateDistrictSettingsOnMutationArguments
>(UPDATE_DISTRICT_SETTINGS, options);
