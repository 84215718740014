// External Dependencies
import { FC, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { recipientIds as recipientIdsSelector } from 'state/ui/emailNew/selectors';
import { useCreateEmailDraft, useUpdateEmailDraftRecipients } from 'gql/mutations';

// Component Definition
const EmailNew: FC = () => {
  const navigate = useNavigate();

  const recipientIds = useSelector(recipientIdsSelector, shallowEqual);

  const [updateEmailDraftRecipients] = useUpdateEmailDraftRecipients({
    onCompleted: ({ updateEmailDraftRecipients: { id } }) => {
      navigate(`/${PATHS.EMAIL_DRAFTS}/${id}`, { replace: true });
    },
  });

  const [createEmailDraft] = useCreateEmailDraft({
    clearCachePredicates: ['emailDraftsIndex', 'emailsIndex', 'emailScheduledIndex'],
    onCompleted: (data) => {
      const draftId = data.createEmailDraft.id;

      if (recipientIds.length) {
        updateEmailDraftRecipients({
          variables: {
            id: draftId,
            input: {
              recipientIds,
            },
          },
        });
      } else {
        navigate(`/${PATHS.EMAIL_DRAFTS}/${draftId}`, { replace: true });
      }
    },
  });

  useEffect(() => {
    createEmailDraft();
    // We don't want to run this effect again, so we disable the exhaustive-deps rule.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CircularProgress />;
};

export default EmailNew;
