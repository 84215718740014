// External Dependencies
import {
  CreateFinancialFundraiserCreditRequest,
  CreateFundraiserCreditAssignmentRequest,
  UpdateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictQueryCache } from 'utils/lib/graphql';
import { useTanstackMutation } from 'utils/api/index';

export const useCreateFundraiserCredit = () => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: CreateFinancialFundraiserCreditRequest) => {
    return apiClient.v1.financialFundraiserCredit.createFundraiserCredit(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictQueryCache(queryClient, ['financialFundraiserCredits']);
    },
  });
};

export const useCreateFundraiserCreditAssignment = (fundraiserCreditId: string) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: CreateFundraiserCreditAssignmentRequest) => {
    return apiClient.v1.financialFundraiserCredit.createFundraiserCreditAssignment({
      fundraiserCreditId,
      payload,
    });
  }, [fundraiserCreditId]);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['financialFundraiserCreditAssignments', fundraiserCreditId]);
    },
  });
};

export const useDeleteFundraiserCreditAssignment = (fundraiserCreditId: string) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: { fundraiserCreditAssignmentId: string }) => {
    return apiClient.v1.financialFundraiserCredit.deleteFundraiserCreditAssignment({
      fundraiserCreditAssignmentId: payload.fundraiserCreditAssignmentId,
      fundraiserCreditId,
    });
  }, [fundraiserCreditId]);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['financialFundraiserCreditAssignments', fundraiserCreditId]);
    },
  });
};

export const useGetFundraiserCreditAssignments = (fundraiserCreditId: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.financialFundraiserCredit.getFundraiserCreditAssignments({
        fundraiserCreditId,
      });
    },
    queryKey: ['financialFundraiserCreditAssignments', fundraiserCreditId],
  });
};

export const useImportFundraiserCreditAmounts = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: any) => {
    return apiClient.v1.financialFundraiserCredit.importFundraiserCreditAmounts(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};

export const useUpdateFinancialFundraiserCredit = (fundraiserCreditId: string) => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((payload: UpdateFinancialFundraiserCreditRequest['body']) => {
    return apiClient.v1.financialFundraiserCredit.updateFundraiserCredit({
      body: payload,
      params: { fundraiserCreditId },
    });
  }, [fundraiserCreditId]);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictQueryCache(queryClient, ['financialFundraiserCredit', fundraiserCreditId]);
    },
  });
};
