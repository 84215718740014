// External Dependencies
import {
  ChildLikeRelationshipTypeIds,
  ChildPeerRelationshipTypeIds,
  ParentLikeRelationshipTypeIds,
  ParentPeerRelationshipTypeIds,
  UserRoles,
} from '@presto-assistant/api_types';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// Internal Dependencies
import {
  CustomSelect,
  Flex,
  SaveButton,
} from 'components/shared';
import { SelectOption } from 'components/shared/CustomSelect';
import { isDirector } from 'state/self/selectors';
import { mapEnum } from 'utils/lib/map_enum';
import { yup } from 'utils/yup';
import PendingMemberAlert from 'components/shared/PendingMemberAlert';

// Local Typings
interface Props {
  autoFocus?: boolean;
  cancelText?: string;
  isSubmitting: boolean;
  memberRoleId?: GQL.IUser['role']['id'];
  onBack: () => void;
  onCompleted: (values: RelationshipTypeFormValues) => void;
  relationRoleId: number | null;
}
export interface RelationshipTypeFormValues {
  recipientRelationshipTypeId: string;
}

// Local Variables
const schema = yup.object().shape<RelationshipTypeFormValues>({
  recipientRelationshipTypeId: yup.string().required('Required'),
});

// Component Definition
const RelationshipType = ({
  autoFocus,
  cancelText = 'Go Back',
  isSubmitting,
  memberRoleId,
  onBack,
  onCompleted,
  relationRoleId,
}: Props): JSX.Element => {
  // If the user is director/dfa, we don't need to show the pending member alert
  const isDirectorRole = useSelector(isDirector);

  /*
   * We have four types of relationship options:
   *  1. Student adding an adult — e.g., Parent, Step-parent, Guardian
   *  2. Parent adding a student — e.g., Child, Step-child, Ward
   *  3. Student adding a student (child-peer) — e.g., Sibling, Step-sibling
   *  4. Parent adding a parent (adult-peer) — e.g., Spouse
   */

  const relationshipTypeOptions: SelectOption[] = useMemo(() => {
    const isMemberAdult = memberRoleId === UserRoles.Adult.toString();
    const isMemberStudent = memberRoleId === UserRoles.Student.toString();

    // Based on the data we received from "checkIfMemberExists",
    // we might already know the role of the user we are adding as a family member.
    const isRelationAdult = relationRoleId === UserRoles.Adult;
    const isRelationStudent = relationRoleId === UserRoles.Student;

    // 1. Show all options if a member is adding a family member
    const childLikeRelationshipTypeOptions = mapEnum(ChildLikeRelationshipTypeIds);
    const parentLikeRelationshipTypeOptions = mapEnum(ParentLikeRelationshipTypeIds);
    const childPeerRelationshipTypeOptions = mapEnum(ChildPeerRelationshipTypeIds);
    const parentPeerRelationshipTypeOptions = mapEnum(ParentPeerRelationshipTypeIds);

    const options: SelectOption[] = [];

    if (isMemberAdult) {
      if (!isRelationAdult) {
        options.push(...childLikeRelationshipTypeOptions);
      }

      if (!isRelationStudent) {
        options.push(...parentPeerRelationshipTypeOptions);
      }
    }

    if (isMemberStudent) {
      if (!isRelationStudent) {
        options.push(...parentLikeRelationshipTypeOptions);
      }

      if (!isRelationAdult) {
        options.push(...childPeerRelationshipTypeOptions);
      }
    }

    return options;
  }, [
    memberRoleId,
    relationRoleId,
  ]);

  const handleFormikSubmit = (values: RelationshipTypeFormValues) => {
    onCompleted(values);
  };

  return (
    <Formik<RelationshipTypeFormValues>
      enableReinitialize
      initialValues={{
        recipientRelationshipTypeId: '',
      }}
      onSubmit={handleFormikSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <CustomSelect
            autoFocus={autoFocus}
            label="This person is a..."
            name="recipientRelationshipTypeId"
            options={relationshipTypeOptions}
            variant="filled"
          />

          {!isDirectorRole && (
            <Box marginY={2}>
              <PendingMemberAlert />
            </Box>
          )}

          <Box mt={1}>
            <Flex justifyContent="flex-end">
              <Button
                color="primary"
                onClick={onBack}
                sx={{ marginRight: 1 }}
                variant="outlined"
              >
                {cancelText}
              </Button>

              <SaveButton isSaving={isSubmitting}>
                Add Family Member
              </SaveButton>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RelationshipType;
