import { AxiosError } from 'axios';
import { formatYupError } from '@presto-assistant/api_types/utils';

export const convertAxiosErrorToYupError = (error: AxiosError) => {
  const errorData = error.response?.data;

  if (errorData) {
    const formattedError = formatYupError(errorData.error);

    return formattedError;
  }

  return null;
};
