// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MinusCircleOutlineIcon from 'mdi-material-ui/MinusCircleOutline';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { DELETE_CHECKLIST_ITEM, useCreateChecklistItem } from 'gql/mutations';
import {
  EnhancedCard,
  EnhancedContainer,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isMobileScreenSize } from 'state/device/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetChecklist } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';

// Local Dependencies
import { checklistItemSchema } from '../../shared/schema';
import ChecklistErrorAlert from '../../shared/ChecklistErrorAlert';
import ChecklistItemsFormUI from './ChecklistItemsFormUI';
import DialogEditChecklistItem from './DialogEditChecklistItem';
import ShowChecklistBasicInfoCard from '../../shared/ShowChecklistBasicInfoCard';

// Local Typings
interface Props {
  checklistId: string;
}

// Local Variables
const StyledList = styled(List)(({ theme }) => ({
  '&:not(last-child)': {
    marginBottom: theme.spacing(1),
  },

  '.MuiListItemIcon-root': {
    alignSelf: 'flex-start',
    minWidth: 40,
    paddingTop: theme.spacing(1),
  },

  '.MuiListItemText-primary': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    width: '82%',
  },

  '.MuiListItemText-secondary': {
    alignItems: 'flex-end',
    display: 'flex',
  },

  '.checklistItemLabel': {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },

  border: `1px solid ${theme.palette.grey['400']}`,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:not(last-child)': {
    marginBottom: theme.spacing(1),
  },

  alignItems: 'flex-start',
  border: `1px solid ${theme.palette.prestoPrimary}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
}));

const deleteChecklistItemContext: [string] = ['checklist item'];

// Component Definition
const EditChecklistItemsForm: FC<Props> = ({ checklistId }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const [
    checklistItemToEdit,
    setChecklistItemToEdit,
  ] = useState<GQL.IChecklistItem | null>(null);
  const [
    checklistItemToDeleteId,
    setChecklistItemToDeleteId,
  ] = useState<string | null>(null);
  const [
    checklistItemToDeleteLabel,
    setChecklistItemToDeleteLabel,
  ] = useState<string>('');

  const {
    isOpen: isEditChecklistItemDialogOpen,
    toggleIsOpen: toggleEditChecklistItemDialog,
  } = useIsOpen();
  const {
    isOpen: isDeleteChecklistItemDialogOpen,
    toggleIsOpen: toggleDeleteChecklistItemDialog,
  } = useIsOpen();

  const {
    data: checklistData,
    error: getChecklistDataError,
  } = useGetChecklist(checklistId);

  const initialValues: GQL.ICreateChecklistItemInput = {
    checklistId,
    dueDate: null, // Not using for now
    isRequired: false,
    label: '',
  };

  const checklistsParams = useSelector(tableQueryParams('checklists'));

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}${checklistsParams}`);
  }, [checklistsParams, navigate]);

  const handleNavigateToShowPage = useCallback(() => {
    navigate(`/${PATHS.CHECKLISTS}/${checklistId}`);
  }, [checklistId, navigate]);

  const handleOpenEditChecklistItemDialog = useCallback((item) => {
    setChecklistItemToEdit(item);
    toggleEditChecklistItemDialog();
  }, [toggleEditChecklistItemDialog]);

  const handleOpenDeleteChecklistItemDialog = useCallback(({
    id,
    label,
  }) => {
    setChecklistItemToDeleteId(id);
    setChecklistItemToDeleteLabel(label);
    toggleDeleteChecklistItemDialog();
  }, [toggleDeleteChecklistItemDialog]);

  const formatDeleteChecklistItemPayload = useCallback<DeleteDialogV2Props<GQL.IDeleteChecklistItemOnMutationArguments>['formatPayload']>((note) => ({
    input: {
      id: checklistItemToDeleteId ?? '',
      note: note ?? '',
    },
  }), [checklistItemToDeleteId]);

  const [
    createChecklistItem,
    {
      error: createChecklistItemError,
      loading,
    },
  ] = useCreateChecklistItem();

  const handleFormikSubmit = (
    values: GQL.ICreateChecklistItemInput,
    formikProps: FormikHelpers<GQL.ICreateChecklistItemInput>,
  ) => {
    const { resetForm } = formikProps;

    createChecklistItem({
      variables: {
        input: values,
      },
    });

    resetForm();
  };

  const existingChecklistItems = useMemo(
    () =>
      checklistData?.checklist.checklistItems.map((item) => (
        <Collapse
          key={item.id}
        >
          <ListItem>
            <ListItemIcon>
              <CheckIcon
                fontSize={isMobileScreen ? 'small' : 'medium'}
                htmlColor={theme.palette.success.main}
              />
            </ListItemIcon>

            <ListItemText
              primary={item.label}
              secondary={(
                <Typography
                  className="checklistItemLabel"
                  color="textSecondary"
                  variant="body2"
                >
                  {item.isRequired ? 'R' : 'Not r'}equired
                </Typography>
              )}
            />

            <ListItemSecondaryAction>
              <IconButton
                onClick={() => handleOpenEditChecklistItemDialog(item)}
                size={isMobileScreen ? 'small' : 'medium'}
              >
                <EditIcon fontSize={isMobileScreen ? 'small' : 'medium'} />
              </IconButton>

              <IconButton
                onClick={() => handleOpenDeleteChecklistItemDialog({
                  id: item.id,
                  label: item.label,
                })}
                size={isMobileScreen ? 'small' : 'medium'}
              >
                <MinusCircleOutlineIcon fontSize={isMobileScreen ? 'small' : 'medium'} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Collapse>
      )),
    [
      checklistData?.checklist.checklistItems,
      handleOpenEditChecklistItemDialog,
      isMobileScreen,
      handleOpenDeleteChecklistItemDialog,
      theme.palette.success.main,
    ],
  );

  return (
    <>
      <Grid container>
        <EnhancedContainer>
          <Box marginBottom={2}>
            <ShowChecklistBasicInfoCard checklistData={checklistData?.checklist} />
          </Box>

          <ShowCard
            canEdit
            icon={ListAltIcon}
            title="Checklist Items"
          >
            {checklistData?.checklist.checklistItems.length ? (
              <Box>
                <Box
                  marginBottom={1}
                  marginLeft={1}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    Existing checklist items
                  </Typography>
                </Box>

                <StyledList>
                  <TransitionGroup>
                    {existingChecklistItems}
                  </TransitionGroup>
                </StyledList>
              </Box>
            ) : null}

            <Formik<GQL.ICreateChecklistItemInput>
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormikSubmit}
              validationSchema={checklistItemSchema}
            >
              {({
                handleSubmit,
                touched,
                values,
              }) => {
                const isFormTouched = Object.keys(touched).length > 0;
                const hasLabelValue = values.label.length > 0;

                // If the user clicks into the item input field and clicks away,
                //  the form is touched but no value. We will still allow them to
                //  press the "Done" button to finish using the form.
                const shouldUseButtonType = !isFormTouched || (isFormTouched && !hasLabelValue);

                return (
                  <Form onSubmit={handleSubmit}>
                    <Box
                      marginLeft={1}
                      marginTop={2}
                    >
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                      >
                        Add a new checklist item
                      </Typography>
                    </Box>

                    <List>
                      <StyledListItem
                        secondaryAction={(
                          <IconButton
                            aria-label="add-another-item"
                            edge="end"
                            type="submit"
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                            )}
                      >
                        <ChecklistItemsFormUI />
                      </StyledListItem>
                    </List>

                    <ChecklistErrorAlert
                      errorMessage={createChecklistItemError?.message
                        || getChecklistDataError?.message}
                    />

                    <EnhancedCard>
                      <FormActions
                        context="Checklist"
                        isEditing={false}
                        isFormTouched={isFormTouched}
                        isSubmitting={loading}
                        onPressCancelOrBackButton={handlePressCancelOrBackButton}
                        onPressSubmit={handleNavigateToShowPage}
                        submitButtonText="Done"
                        type={shouldUseButtonType ? 'button' : 'submit'}
                      />
                    </EnhancedCard>
                  </Form>
                );
              }}
            </Formik>
          </ShowCard>
        </EnhancedContainer>
      </Grid>

      <DeleteDialogV2<GQL.IDeleteChecklistItemOnMutationArguments>
        actionVerb="Delete"
        clearCachePredicates={['checklists']}
        context={deleteChecklistItemContext}
        descriptionText={`The checklist item "${checklistItemToDeleteLabel}" will be marked as deleted.`}
        formatPayload={formatDeleteChecklistItemPayload}
        isOpen={isDeleteChecklistItemDialogOpen}
        mutation={DELETE_CHECKLIST_ITEM}
        onClose={toggleDeleteChecklistItemDialog}
        withNote
      />

      <DialogEditChecklistItem
        checklistItem={checklistItemToEdit}
        onClose={toggleEditChecklistItemDialog}
        open={isEditChecklistItemDialogOpen}
      />
    </>
  );
};

export default EditChecklistItemsForm;
