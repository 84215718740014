// External Dependencies
import ToolsIcon from 'mdi-material-ui/Tools';
import styled from 'styled-components';

// Internal Dependencies
import { ShowCard } from 'components/shared';

// Local Dependencies
import InventoryRepairList from '../shared/InventoryRepairList';

// Local Typings
interface Props {
  inventoryItemId: string;
}

// Local Variables
const StyledShowCard = styled(ShowCard)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// Component Definition
const InventoryRepairCard = ({
  inventoryItemId,
}: Props): JSX.Element => {
  return (
    <StyledShowCard
      icon={ToolsIcon}
      title="Repair History"
    >
      <InventoryRepairList inventoryItemId={inventoryItemId} />
    </StyledShowCard>
  );
};

export default InventoryRepairCard;
