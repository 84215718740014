// External Dependencies
import {
  Box,
  List,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import DevicesIcon from '@mui/icons-material/Devices';

// Internal Dependencies
import {
  SendWelcomeEmailListItem,
  ShowCard,
} from 'components/shared';
import { isDirector } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';
import { useIsAliasUser } from 'hooks/useIsAliasUser';
import BecomeThisMemberListItem from 'components/shared/BecomeThisMemberListItem';

// Local Dependencies
import LoginActivityListItem from './LoginActivityListItem';
import LoginUsernameListItem from './LoginUsernameListItem';

// Local Typings
interface Props {
  authUserEmail: string | null;
  authUserId: string | null;
  loggedInAt: string | null;
  memberEmail: string;
  name: string;
  receivedWelcomeEmailAt: string | null;
  userId: string;
  userOrgId: string;
  userOrgLoggedInAt: string | null;
}

// Component Definition
const ActivityCard: FC<Props> = ({
  authUserEmail,
  authUserId,
  loggedInAt,
  memberEmail,
  name,
  receivedWelcomeEmailAt,
  userId,
  userOrgId,
  userOrgLoggedInAt,
}) => {
  const isUserDirector = useSelector(isDirector);

  const isAliasUser = useIsAliasUser();

  const { data: orgData } = useGetOrganization();

  const organizationId = orgData?.organization.id;

  return (
    <Box mb={2}>
      <ShowCard
        icon={DevicesIcon}
        title="Activity"
      >
        <List>
          <SendWelcomeEmailListItem
            email={memberEmail}
            name={name}
            receivedWelcomeEmailAt={receivedWelcomeEmailAt}
            userOrgId={userOrgId}
            userOrgLoggedInAt={userOrgLoggedInAt}
          />

          <LoginActivityListItem
            loggedInAt={loggedInAt}
            receivedWelcomeEmailAt={receivedWelcomeEmailAt}
            userOrgLoggedInAt={userOrgLoggedInAt}
          />

          <LoginUsernameListItem loginUsername={authUserEmail} />

          {isUserDirector && !isAliasUser && organizationId && authUserId && (
            <BecomeThisMemberListItem
              organizationId={organizationId}
              userId={userId}
            />
          )}
        </List>

      </ShowCard>
    </Box>
  );
};

export default ActivityCard;
