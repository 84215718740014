// External Dependencies
import { FC } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// Internal Dependencies
import {
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface Props {
  checklistData: GQL.IChecklist | null | undefined;
}

// Component Definition
const ShowChecklistBasicInfoCard: FC<Props> = ({ checklistData }) => {
  if (!checklistData) {
    return null;
  }

  return (
    <ShowCard
      icon={DoneAllIcon}
      readOnly
      title="Checklist Details"
    >
      <ShowPageDataDisplay
        label="Checklist Name"
        value={checklistData.label}
      />
      {/* <ShowPageDataDisplay
          label="Due Date"
          value=""
        />
          <ShowPageDataDisplay
          label="Email Reminders?"
          type="boolean"
          value={checklistData.emailRemindersEnabled}
        /> */}
    </ShowCard>
  );
};

export default ShowChecklistBasicInfoCard;
