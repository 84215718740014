// External Dependencies
import { FC } from 'react';

/*
 * SadCatSvg
 *
 * From Wikimedia Commons
 * https://upload.wikimedia.org/wikipedia/commons/0/07/Emojione_1F63F.svg
 *
 */

// Component Definition
const SadCatSvg: FC = () =>
  (
    <svg
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
    >
      <path
        d="M60.9,2c1.8,13.4,1.5,24.2-1.2,31.1L34.9,14.7C39.8,9.6,49.8,3.5,60.9,2z"
        fill="#dadee0"
      />
      <path
        d="m57.7 32.2l-10.2-13.3c0 0 1.9-7.3 11.6-12.4 2.9 11.6-1.4 25.7-1.4 25.7"
        fill="#f7a4a4"
      />
      <path
        d="m53.8 29.2l-6.3-8.2c0 0 2.6-5 8.6-8.2-3.5 7.5-2.3 16.4-2.3 16.4"
        fill="#f78d8d"
      />
      <path
        d="M3.1,2C1.3,15.4,1.6,26.2,4.3,33.1l24.8-18.5C24.2,9.6,14.2,3.5,3.1,2z"
        fill="#dadee0"
      />
      <path
        d="m6.3 32.2l10.3-13.3c0 0-1.9-7.3-11.6-12.4-3 11.6 1.3 25.7 1.3 25.7"
        fill="#f7a4a4"
      />
      <path
        d="m10.2 29.2l6.4-8.2c0 0-2.6-5-8.6-8.2 3.4 7.5 2.2 16.4 2.2 16.4"
        fill="#f78d8d"
      />
      <path
        d="m58.2 50.8c.8-.9 1.4-1.6 1.7-2.2 1.6-2.6 2.1-5.3 2.1-5.3l-2 .4c0 0 2.3-2.5 1.7-7.8l-2 2.1c0 0 1.9-5.4.3-10.4l-2.2 1.6c0 0 .7-2 .1-3.4l-.9.7c0 0 1.7-4.7.2-6.6-.9 2.8-1.9 2.8-1.9 2.8s1.2-2.1.3-3c-1 1.6-1.8.6-1.8.1.1-.6-.2-1.5 2.7-2-2.3-1.4-5.1-.4-5.1-.4s.1-1.5 2.1-1.4c-1.9-1.6-4.5-.6-4.5-.6-4.1-3.1-9.6-4.9-17.1-4.9-7.4 0-12.9 1.8-17.1 4.9 0 0-2.6-.9-4.5.6 2-.1 2.1 1.4 2.1 1.4s-2.7-1-5.1.4c2.9.4 2.7 1.3 2.7 2 0 .5-.8 1.5-1.8-.1-1 .9.3 3 .3 3s-1 0-1.9-2.8c-1.5 2 .2 6.6.2 6.6l-.9-.7c-.6 1.4.1 3.4.1 3.4l-2-1.6c-1.7 5 .3 10.4.3 10.4l-1.9-2.1c-.7 5.3 1.6 7.8 1.6 7.8l-2-.4c0 0 .5 2.7 2.1 5.3.2.5.8 1.3 1.7 2.2 0 0 0 0 .1.1 4.1 4.1 14.2 11.1 26.1 11.1 11.9 0 22-7 26.1-11.1 0-.1.1-.1.1-.1"
        fill="#dadee0"
      />
      <g fill="#94989b">
        <path
          d="m37.7 10.8c-1.8-.3-3.7-.4-5.7-.4s-3.9.1-5.7.4c.7 3.9 2.8 6.6 5.7 11.6 3-5.1 5-7.8 5.7-11.6"
        />
        <path
          d="m46.8 13.8c-1.9-1.1-4.1-1.9-6.5-2.5.6 2.3 1.9 3.9 3.6 6.9 1.3-2.1 2.2-2.7 2.9-4.4"
        />
        <path
          d="m23.7 11.3c-2.5.6-4.6 1.4-6.5 2.5.7 1.8 1.7 2.3 2.9 4.4 1.8-3.1 3-4.7 3.6-6.9"
        />
      </g>
      <path
        d="m36 38.8c-.8-.9-3.3-1-4-1-.7 0-3.2.1-4 1-.6.7-.1 2.3 1.4 3.8 1 .9 1.9 1.2 2.6 1.2.7 0 1.7-.3 2.6-1.2 1.6-1.5 2-3.2 1.4-3.8"
        fill="#4c5359"
      />
      <path
        d="m24.3 30.8c0 0-.5-4.6-4.5-6-4.5-1.7-7.6 2.4-8.5 4.7-1.2 3.4.3 7.1.8 8 .3.6 3.7 3.2 11.2.7 0 0 1.9-3.8 1-7.4"
        fill="#fff"
      />
      <path
        d="m24.3 30.8c-.4-1.6-1.1-3-2.3-4.1-.5-.5-1.2-1-1.9-1.2-.3-.1-.7-.3-1.1-.3-.2-.1-.6-.2-1-.2-1.5 0-2.8.6-3.9 1.6-1.1 1-1.9 2.3-2.3 3.7-.4 1.4-.3 3 0 4.4.2.7.4 1.5.7 2.2l.1.2c0-.1 0 0 0 0l.1.1c.1.1.2.2.4.3.3.2.6.4.9.5 1.4.6 2.9.8 4.5.8 1.6 0 3.2-.3 4.8-.7-1.5.7-3.1 1.1-4.7 1.4-1.6.2-3.4.2-5-.4-.4-.1-.8-.3-1.2-.6-.2-.1-.4-.3-.6-.4-.1 0-.1-.1-.2-.2l-.1-.1-.1-.1-.2-.3c-.4-.8-.7-1.6-.9-2.4-.4-1.6-.5-3.4-.1-5.1.5-1.7 1.5-3.2 2.8-4.4 1.3-1.1 3.1-1.9 4.9-1.7.4.1.9.1 1.3.3.4.1.8.3 1.2.5.8.4 1.4 1 2 1.6 1.2 1.4 1.8 3 1.9 4.6"
        fill="#b2b6b8"
      />
      <path
        d="m39.7 30.8c0 0 .5-4.6 4.5-6 4.5-1.7 7.6 2.4 8.5 4.7 1.2 3.4-.3 7.1-.8 8-.3.6-3.7 3.2-11.2.7 0 0-1.9-3.8-1-7.4"
        fill="#fff"
      />
      <path
        d="m39.7 30.8c.1-1.6.7-3.2 1.7-4.6.5-.7 1.2-1.2 2-1.6.4-.2.8-.4 1.2-.5.4-.1.9-.2 1.3-.3 1.8-.2 3.6.6 4.9 1.7 1.3 1.2 2.3 2.6 2.8 4.4.4 1.7.3 3.5-.1 5.2-.2.8-.5 1.6-.9 2.4l-.2.3-.1.1v.1c-.1.1-.1.1-.2.2-.2.2-.4.3-.6.4-.4.2-.8.4-1.2.6-1.7.6-3.4.6-5 .4-1.6-.3-3.2-.7-4.7-1.4 1.6.3 3.2.6 4.8.7 1.6 0 3.2-.2 4.5-.8.3-.1.7-.3.9-.5.1-.1.3-.2.4-.3l.1-.1c0 0 0 0 0 0l.1-.2c.3-.7.5-1.4.7-2.2.3-1.5.4-3 0-4.4-.4-1.4-1.2-2.7-2.3-3.7-1.1-1-2.4-1.6-3.9-1.6-.4 0-.7.1-1.1.1-.4.1-.7.2-1.1.3-.7.3-1.3.7-1.9 1.2-1 1.1-1.7 2.6-2.1 4.1"
        fill="#b2b6b8"
      />
      <g fill="#4c5359">
        <circle
          cx="45"
          cy="32.5"
          r="5"
        />
        <circle
          cx="19"
          cy="32.5"
          r="5"
        />
      </g>
      <g fill="#fff">
        <ellipse
          cx="19"
          cy="36"
          rx="2.2"
          ry="2.5"
        /><ellipse
          cx="15.3"
          cy="31.7"
          rx="1.3"
          ry="1.5"
        /><ellipse
          cx="45"
          cy="36"
          rx="2.2"
          ry="2.5"
        /><ellipse
          cx="41.3"
          cy="31.7"
          rx="1.3"
          ry="1.5"
        />
      </g>
      <path
        d="m40.6 49.3c-5.4-2.5-11.8-2.5-17.2 0-1.3.6.3 4.2 1.7 3.5 3.6-1.7 8.9-2.3 13.9 0 1.3.6 3-2.8 1.6-3.5"
        fill="#4c5359"
      />
      <path
        d="m47 37c-4.8 6.5-7.5 12.5-7.5 17.4 0 4.2 3.4 7.6 7.5 7.6 4.1 0 7.5-3.4 7.5-7.6 0-4.9-2.8-11-7.5-17.4"
        fill="#65b1ef"
      />
    </svg>
  );
export default SadCatSvg;
