// External Dependencies
import { CheckIfMemberExistsRequest, UpdateAdminRequest } from '@presto-assistant/api_types/api/v1/member';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictQueryCache } from 'utils/lib/graphql';
import { useTanstackMutation } from 'utils/api/index';

export const useCheckIfMemberExists = () => {
  const mutationFn = useCallback((payload: CheckIfMemberExistsRequest['query']) => {
    return apiClient.v1.member.checkIfMemberExists(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback(({
    body,
    params,
  }: {
    body: UpdateAdminRequest['body'];
    params: UpdateAdminRequest['params'];
  }) => {
    return apiClient.v1.member.updateAdmin({
      body,
      params,
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => evictQueryCache(queryClient, [
      'dashboardMetrics',
      'directors',
      'parents',
      'user',
    ]),
  });
};
