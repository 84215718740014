// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Form, Formik } from 'formik';
import { createOrganizationSchema } from '@presto-assistant/api_types/schemas/organization';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomFormButton,
  CustomInput,
  CustomSelect,
  Flex,
  StateSelector,
} from 'components/shared';
import {
  GET_SELF,
  useGetOrganizationEntityTypes,
  useGetOrganizationTypes,
} from 'gql/queries';
import { checkOrgCode } from 'utils/api';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateOrganization } from 'gql/mutations';
import OnboardingProgress from 'pages/Onboarding/OnboardingProgress';

// Local Typings
interface Props {
  districtStateId?: string;
  isOnboarding?: boolean;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.bodyText': {
    margin: '16px 32px',
  },
  '.button': {
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.formFields': {
    textAlign: 'left',
  },
  '.h6': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: `${theme.spacing(1.5)} auto`,
    },
    fontSize: '1em',
    margin: '16px auto',
    textAlign: 'center',
  },
  '.success': {
    color: theme.palette.prestoSuccess,
  },
  '.zipcode': {
    marginLeft: 16,
    width: '100%',
  },
}));

// Component Definition
const OnboardingOrganizationInfoForm: FC<Props> = ({
  districtStateId = '',
  isOnboarding = false,
}) => {
  const navigate = useNavigate();

  const { data: orgTypeData } = useGetOrganizationTypes();

  const {
    data: entityTypeOptions,
    loading: isLoadingEntityTypeOptions,
  } = useGetOrganizationEntityTypes();

  const isLoading = isLoadingEntityTypeOptions;

  const [orgCodeValue, setOrgCodeValue] = useState('');
  const [isCodeAvailable, setIsCodeAvailable] = useState(true);

  const [debouncedOrgCodeValue] = useDebounce(orgCodeValue, 500);

  // We check the debounced orgCode as the user types
  useEffect(() => {
    async function handleCheckOrgCode() {
      const response = await checkOrgCode({ code: debouncedOrgCodeValue });
      const isCodeTakenByAnotherOrg = Boolean(response?.data.organization);

      setIsCodeAvailable(!isCodeTakenByAnotherOrg);
    }
    if (debouncedOrgCodeValue) {
      handleCheckOrgCode();
    }
  }, [debouncedOrgCodeValue, setIsCodeAvailable]);

  const handleNavigateToDashboard = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const [
    createOrganization,
    { loading: isSubmittingCreateOrganization },
  ] = useCreateOrganization(
    {
      clearCachePredicates: ['self', 'organization'],
      onCompleted: handleNavigateToDashboard,
    },
  );

  if (isLoading) {
    return <OnboardingProgress />;
  }

  const orgInfoInitialValues: GQL.ICreateOrganizationInput = {
    addressOne: '',
    addressTwo: '',
    city: '',
    code: '',
    entityTypeId: '',
    label: '',
    organizationTypeId: '',
    phoneNumber: '',
    planId: '2',
    stateId: districtStateId || '',
    zipcode: '',
  };

  let helperText: string;
  if (debouncedOrgCodeValue && isCodeAvailable) {
    helperText = 'Available';
  } else if (!isCodeAvailable) {
    helperText = 'This code is not available';
  }

  const handleSubmitForm = async (
    values: GQL.ICreateOrganizationInput,
  ) => {
    const updatedValues = {
      ...values,
      // This is hard-coded to the "premium" plan for now
      planId: '2',
    };

    await createOrganization({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_SELF }],
      variables: { input: trimValues(updatedValues) },
    });
  };

  return (
    <Formik
      initialValues={orgInfoInitialValues}
      onSubmit={handleSubmitForm}
      validationSchema={createOrganizationSchema}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <Form onSubmit={handleSubmit}>
            <Grow in={!isLoading}>
              <StyledCardContent>
                <Typography
                  className="h6"
                  component="h3"
                  variant="h6"
                >
                  Help us learn more about your organization.
                </Typography>

                <section className="formFields">
                  <Flex>
                    <CustomInput
                      label="Organization Name *"
                      name="label"
                    />
                    {orgTypeData && (
                      <CustomSelect
                        label="Organization Type *"
                        name="organizationTypeId"
                        options={orgTypeData.organizationTypes}
                      />
                    )}
                    {entityTypeOptions && (
                      <CustomSelect
                        label="Level *"
                        name="entityTypeId"
                        options={entityTypeOptions.organizationEntityTypes}
                      />
                    )}
                    <Typography
                      className="bodyText"
                      variant="body2"
                    >
                      Create a code that parents and students will use to join your organization.
                    </Typography>

                    <CustomInput
                      FormHelperTextProps={{
                        className: isCodeAvailable ? 'success' : '',
                      }}
                      error={!isCodeAvailable}
                      helperText={helperText}
                      label="Organization Code *"
                      name="code"
                      onChangeValue={setOrgCodeValue}
                      placeholder="e.g. texashsband"
                    />
                    <Typography
                      className="h6"
                      component="h3"
                      variant="h6"
                    >
                      Contact information for the organization
                    </Typography>
                    <CustomInput
                      label="Address 1 *"
                      name="addressOne"
                    />
                    <CustomInput
                      label="Address 2"
                      name="addressTwo"
                    />
                    <CustomInput
                      label="City *"
                      name="city"
                    />

                    <Flex
                      alignItems="baseline"
                      flex="1 0 100%"
                      flexWrap="nowrap"
                    >
                      <StateSelector label="State *" />

                      <CustomInput
                        label="Zip Code *"
                        name="zipcode"
                        rootStyles="zipcode"
                      />
                    </Flex>

                    <CustomInput
                      label="Phone Number *"
                      name="phoneNumber"
                      type="tel"
                    />
                  </Flex>
                </section>

                <Box
                  display="flex"
                  justifyContent="center"
                >
                  {isSubmitting || isSubmittingCreateOrganization ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText={isOnboarding ? 'Next' : 'Create New Organization'}
                      className="button"
                      disabled={isSubmitting || isSubmittingCreateOrganization}
                      key="create-org-form-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </Box>
              </StyledCardContent>
            </Grow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OnboardingOrganizationInfoForm;
