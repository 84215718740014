// External Dependencies
import { Link } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import BusinessIcon from '@mui/icons-material/Business';
import styled from 'styled-components';

// Internal Dependencies
import { Flex, SubscriberAddButton } from 'components/shared/index';
import { PATHS } from 'utils/constants/routes';
import { open } from 'state/ui/organizationLogoUploadDialog/actions';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  withUploadButton?: boolean;
}

interface StyledAvatarProps {
  $isAdding?: boolean;
}

// Local Variables
const avatarSize = 100;

const StyledAvatar = styled(Avatar)<StyledAvatarProps>(({
  $isAdding,
  theme,
}) => ({
  height: avatarSize,
  width: avatarSize,
  ...!$isAdding && {
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
  },
}));

// Component Definition
const OrganizationLogo = ({
  withUploadButton,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const openDialog = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const {
    data: organizationData,
  } = useGetOrganization();

  const organization = organizationData?.organization;

  const logoUrl = organization?.logoUrl;

  const avatarWithImage = useMemo(() => (
    <StyledAvatar
      alt="organization-logo"
      src={logoUrl ?? ''}
    />
  ), [logoUrl]);

  if (!logoUrl) {
    return withUploadButton ? (
      <Flex
        alignContent="center"
        flexDirection="column"
        gap={2}
        justifyContent="center"
        marginTop={2}
        width="100%"
      >
        <StyledAvatar
          $isAdding
          alt="organization-logo"
        >
          <BusinessIcon fontSize="large" />
        </StyledAvatar>

        <SubscriberAddButton
          label="Organization Logo"
          onClick={openDialog}
          size="small"
        />
      </Flex>
    ) : null;
  }

  // If the avatar is showing in the organizations settings area,
  //  do not wrap it in a link.
  if (withUploadButton) {
    return (
      <Flex
        justifyContent="center"
      >
        {avatarWithImage}
      </Flex>
    );
  }

  // Wrap the logo in a link to allow the preview of the url in the browser.
  return (
    <Flex
      justifyContent="center"
    >
      <Link to={PATHS.DASHBOARD}>
        {avatarWithImage}
      </Link>
    </Flex>
  );
};

export default OrganizationLogo;
