// External Dependencies
import { FC } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import styled from 'styled-components';

// Local Typings
interface Option {
  id: string;
  label: string | undefined;
}

interface Props {
  fullWidth?: boolean;
  id: string;
  label: string;
  name: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  options: Option[];
  value?: unknown;
}

// Local Variables
const StyledFormControlRoot = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

/*
 * As the name implies, this component should not be used in a Formik context
 * It's meant for any time we need a select that is controlled by local state
 */

// Component Definition
const StandaloneSelect: FC<Props> = ({
  fullWidth = true,
  id,
  label,
  name,
  onChange,
  options,
  value,
}) => (
  <StyledFormControlRoot
    fullWidth={fullWidth}
    variant="filled"
  >
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      labelId={id}
      native
      onChange={onChange}
      inputProps={{
        'aria-label': label,
        name,
      }}
      value={value}
    >
      {options.map(({ id: optionId, label: optionLabel }) => (
        <option
          key={optionId}
          value={optionId}
        >
          {optionLabel}
        </option>
      ))}
    </Select>
  </StyledFormControlRoot>
);

export default StandaloneSelect;
