// External Dependencies
import { FC } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { clearTokens } from 'utils/cookies';
import { logoutCurrentUser } from 'state/self/actions';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface ChildrenProps {
  organization: GQL.IOrganization;
}
interface OrganizationProps {
  children: (props: ChildrenProps) => any;
}

// Component Definition
const Organization: FC<OrganizationProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const {
    data,
    error,
    loading,
  } = useGetOrganization();

  if (loading) {
    return null;
  }

  // if there was an error, log them out
  if (error) {
    clearTokens();
    dispatch(logoutCurrentUser());
    return null;
  }

  if (data) {
    return children(data);
  }

  return null;
};

export default Organization;
