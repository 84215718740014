// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const close = createActionCreator('CLOSE_PAYMENT_ALERT_DIALOG');
export const open = createActionCreator(
  'OPEN_PAYMENT_ALERT_DIALOG',
  (resolve) => (hasNoFeesToPayOnline: boolean) =>
    resolve(hasNoFeesToPayOnline),
);
