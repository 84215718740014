// External Dependencies
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  children: string;
  gutterBottom?: boolean;
}

// Component Definition
const CardSectionTitle = ({
  children,
  gutterBottom,
}: Props): JSX.Element => {
  return (
    <Typography
      component="h3"
      fontSize="1rem"
      fontWeight={500}
      gutterBottom={gutterBottom}
    >
      {children}
    </Typography>
  );
};

export default CardSectionTitle;
