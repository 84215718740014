// External Dependencies
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { UserRoles } from '@presto-assistant/api_types';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { ConfirmationDialog, EnhancedCard, TableDataGrid } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { convertCentsToDollars, getFullName } from 'utils';
import { useCreateManyFinancialFees } from 'gql/mutations';
import { useGetAllMembers, useGetFinancialItem } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { TableRow, useColumns } from './hooks';

// Local Typings
interface Props {
  financialItemId: string;
}

// Local Variables
const getRowId = (row: TableRow) => row.memberId;

// Component Definition
const FinancialItemMemberFeesTable: FC<Props> = ({
  financialItemId,
}) => {
  const {
    data: allMembersData,
    loading: isMemberDataLoading,
  } = useGetAllMembers();

  const {
    data: financialItemData,
    loading: isFinancialItemLoading,
  } = useGetFinancialItem(financialItemId);

  const [
    quickAssignMemberId,
    setQuickAssignMemberId,
  ] = useState<string | null>(null);

  const [
    quickAssignMemberName,
    setQuickAssignMemberName,
  ] = useState<string | null>(null);

  const feeInfo = useMemo(() => {
    return {
      label: financialItemData?.financialItem.label,
      price: convertCentsToDollars(financialItemData?.financialItem.priceInCents),
    };
  }, [financialItemData]);

  const handleCloseQuickAssignDialog = useCallback(() => {
    setQuickAssignMemberId(null);
  }, []);

  const dispatch = useDispatch();

  const [createFinancialFees] = useCreateManyFinancialFees(
    {
      clearCachePredicates: [
        'financialFees',
        'financialFeesIndex',
        'financialFeesOverview',
        'financialItem',
      ],
      onCompleted: () => {
        handleCloseQuickAssignDialog();
        dispatch(
          addNotification(`$${feeInfo.price} fee assigned to ${quickAssignMemberName}`, 'success'),
        );
      },
    },
  );

  const tableData = useMemo<TableRow[]>(() => {
    const feeMemberIds = financialItemData?.financialItem.feesAssigned
      .map((fee) => fee.user.id) ?? [];

    return allMembersData?.allMembers.map((member) => {
      const isFeeAssigned = feeMemberIds.includes(member.id);

      return {
        groups: member.groups,
        isFeeAssigned,
        memberEmail: member.email,
        memberId: member.id,
        memberName: getFullName(member),
        numberOfFeesAssigned: isFeeAssigned
          ? feeMemberIds.filter((memberId) => memberId === member.id).length
          : 0,
        primaryRoleLabel: member.primaryRole?.label ?? null,
        roleLabel: member.roleLabel,
      };
    }) ?? [];
  }, [
    allMembersData,
    financialItemData,
  ]);

  const isLoading = isMemberDataLoading || isFinancialItemLoading;

  const studentMembers = useMemo(
    () => allMembersData?.allMembers
      .filter((member) => member.roleLabel === UserRoles[UserRoles.Student]) ?? [],
    [allMembersData],
  );

  const uniqueStudentsAssignedCount = useMemo(() => {
    const studentMemberIds = studentMembers.map((member) => member.id);
    const feeStudentMemberIds = financialItemData?.financialItem.feesAssigned
      .filter((fee) => studentMemberIds.includes(fee.user.id))
      .map((fee) => fee.user.id) ?? [];

    return new Set(feeStudentMemberIds).size;
  }, [
    financialItemData,
    studentMembers,
  ]);

  const studentCount = studentMembers.length;

  const confirmationDescription = useMemo(() => {
    return (
      <div>
        This will assign <strong>{feeInfo.label}</strong>{' '}
        as a <strong>${feeInfo.price}</strong>{' '}
        fee to {quickAssignMemberName}.
      </div>
    );
  }, [quickAssignMemberName, feeInfo]);

  const handleConfirmQuickAssign = useCallback(() => {
    if (!quickAssignMemberId) {
      return;
    }

    createFinancialFees({
      variables: {
        input: {
          financialItemId,
          userIds: [quickAssignMemberId],
        },
      },
    });
  }, [createFinancialFees, quickAssignMemberId, financialItemId]);

  const handleQuickAssignClick = useCallback((memberId: string, memberName: string): void => {
    setQuickAssignMemberId(memberId);
    setQuickAssignMemberName(memberName);
  }, [setQuickAssignMemberId, setQuickAssignMemberName]);

  const columnArgs = useMemo(() => ({
    onClickQuickAssign: handleQuickAssignClick,
  }), [handleQuickAssignClick]);

  const columns = useColumns(columnArgs);

  return (
    <EnhancedCard>
      <Box
        paddingLeft={2}
        paddingTop={2}
      >
        {isLoading
          ? <CircularProgress size={26} />
          : (
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Assigned to {uniqueStudentsAssignedCount} of {studentCount} students
            </Typography>
          )}
      </Box>

      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          getRowId={getRowId}
          loading={isLoading}
          rows={tableData}
          tableResource="financialItemMemberFees"
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmQuickAssign}
        confirmButtonText="Yes, Assign Fee"
        declineButtonAction={handleCloseQuickAssignDialog}
        description={confirmationDescription}
        handleClose={handleCloseQuickAssignDialog}
        open={Boolean(quickAssignMemberId)}
        title="Assign Fee?"
      />

    </EnhancedCard>
  );
};

export default FinancialItemMemberFeesTable;
