// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  EnhancedAlert,
} from 'components/shared';

// Component Definition
const PendingMemberAlert = (): JSX.Element => {
  return (
    <EnhancedAlert title="Pending Director Approval">
      Once approved, family members will receive an email on how to set up their {APP_NAME} account.
    </EnhancedAlert>
  );
};

export default PendingMemberAlert;
