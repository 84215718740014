// External Dependencies
import { FilterGroup, SortGroup } from '@presto-assistant/api_types/api/shared';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import snakeCase from 'lodash.snakecase';

// Local Dependencies
import { DataGridColDef } from 'types/dataGrid';
import TableDataGrid, {
  TableDataGridProps,
  dataGridFilterKey,
  dataGridPaginationKey,
  dataGridSortKey,
} from '..';

// Local Typings
interface Props extends TableDataGridProps {
  fullCount: number;
}

interface ParsedDataGridQueryString {
  filters: FilterGroup | null;
  pagination: GridPaginationModel;
  sort: SortGroup | null;
}

interface UseParsedDataGridQueryStringOptions {
  columns: DataGridColDef<any>[];
  mapAllKeysToSnakeCase?: boolean;
  mapFilterKeys?: Record<string, string>;
  mapSortKeys?: Record<string, string>;
}

export const useParsedDataGridQueryString = (
  queryString: string,
  options: UseParsedDataGridQueryStringOptions,
): ParsedDataGridQueryString => {
  const params = new URLSearchParams(queryString);

  const filters = params.get(dataGridFilterKey);
  const pagination = params.get(dataGridPaginationKey);
  const sort = params.get(dataGridSortKey);

  return useMemo(() => {
    const parsedPagination: GridPaginationModel = pagination
      ? JSON.parse(pagination)
      : { page: 0, pageSize: 100 };

    // our API is 1-based pagination
    if (parsedPagination) {
      parsedPagination.page += 1;
    }

    const parsedFilters: FilterGroup | null = filters ? JSON.parse(filters) : null;
    let parsedSort: SortGroup | null = sort ? JSON.parse(sort) : null;

    if (parsedFilters) {
      parsedFilters.items = parsedFilters.items.map((filter) => {
        const { field } = filter;

        const column = options.columns.find((col) => col.field === field);

        const columnType = column?.type ?? 'string';

        return {
          ...filter,
          columnType,
        };
      });
    }

    if (parsedSort) {
      parsedSort = parsedSort.map((sortItem) => {
        const { field } = sortItem;

        const column = options.columns.find((col) => col.field === field);

        const columnType = column?.type ?? 'string';

        return {
          ...sortItem,
          columnType,
        };
      });
    }

    if (parsedFilters && options?.mapFilterKeys) {
      parsedFilters.items = parsedFilters.items.map((filter) => {
        const mappedKey = options.mapFilterKeys?.[filter.field];
        return {
          ...filter,
          field: mappedKey ?? filter.field,
        };
      });
    }

    if (parsedFilters && options?.mapAllKeysToSnakeCase) {
      parsedFilters.items = parsedFilters.items.map((filter) => {
        return {
          ...filter,
          field: snakeCase(filter.field),
        };
      });
    }

    if (parsedSort && options?.mapSortKeys) {
      parsedSort = parsedSort.map((sortItem) => {
        const mappedKey = options.mapSortKeys?.[sortItem.field];
        return {
          ...sortItem,
          field: mappedKey ?? sortItem.field,
        };
      });
    }

    if (parsedSort && options?.mapAllKeysToSnakeCase) {
      parsedSort = parsedSort.map((sortItem) => {
        return {
          ...sortItem,
          field: snakeCase(sortItem.field),
        };
      });
    }

    return {
      filters: parsedFilters,
      pagination: parsedPagination,
      sort: parsedSort,
    };
  }, [
    filters,
    options.columns,
    options.mapFilterKeys,
    options.mapSortKeys,
    options.mapAllKeysToSnakeCase,
    pagination,
    sort,
  ]);
};

// Component Definition
const TableDataGridServerSide = ({
  fullCount,
  loading,
  rows,
  ...props
}: Props): JSX.Element => {
  const [data, setData] = useState<TableDataGridProps['rows']>(null);
  const [localFullCount, setLocalFullCount] = useState(fullCount);

  useEffect(() => {
    if (!loading) {
      setLocalFullCount(fullCount);
      setData(rows);
    }
  }, [fullCount, loading, rows]);

  return (
    <TableDataGrid
      {...props}
      hideExport
      loading={loading}
      rows={data}
      serverSide
      serverSideRowCount={localFullCount || 0}
      skipLocalDataFromIndexedDb
    />
  );
};

export default TableDataGridServerSide;
