// External Dependencies
import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

/**
 * This Flex component adds some useful defaults.
 * We use the MUI Box API since they already
 * typed everything so tightly.
 */

// Component Definition
const Flex: FC<BoxProps> = ({
  alignContent = 'flex-start',
  alignItems = 'center',
  children,
  display = 'flex',
  flexDirection = 'row',
  flexGrow = 0,
  flexShrink = 0,
  flexWrap = 'wrap',
  height = 'auto',
  justifyContent = 'flex-start',
  margin = 0,
  padding = 0,
  ...otherProps
}) => (
  <Box
    alignContent={alignContent}
    alignItems={alignItems}
    display={display}
    flexDirection={flexDirection}
    flexGrow={flexGrow}
    flexShrink={flexShrink}
    flexWrap={flexWrap}
    height={height}
    justifyContent={justifyContent}
    margin={margin}
    padding={padding}
    {...otherProps}
  >
    {children}
  </Box>
);

export default Flex;
