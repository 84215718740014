// External Dependencies
import { useCallback, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import EventIcon from '@mui/icons-material/Event';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CalendarEvent, convertGoogleEventToCalendarEvent } from 'pages/Calendar/EventCalendar';
import { CalendarListItem, GoogleCalendarEvent } from 'gql/queries';
import { humanizeDateShort } from 'utils';
import EventDialog from 'pages/Calendar/EventDialog';

// Local Typings
interface Props {
  calendarList: CalendarListItem[];
  events: GoogleCalendarEvent[];
}

// Local Variables
const NUMBER_OF_SHOWN_EVENTS = 3;

// Component Definition
const EventList = ({
  calendarList,
  events,
}: Props): JSX.Element => {
  const [calendarEvent, setCalendarEvent] = useState<CalendarEvent | null>(null);

  const theme = useTheme();

  const handleSetEvent = useCallback((gCalEvent: GoogleCalendarEvent) => () =>
    setCalendarEvent(convertGoogleEventToCalendarEvent(gCalEvent)), []);

  const handleClearEvent = useCallback(() => setCalendarEvent(null), []);

  const shortEventList = useMemo(() => events.slice(0, NUMBER_OF_SHOWN_EVENTS), [events]);

  return (
    <>
      {!events.length && (
        <Typography
          color="textSecondary"
          sx={{ ml: 3 }}
          variant="body2"
        >
          No events scheduled in the upcoming two weeks
        </Typography>
      )}

      {shortEventList.map((event) => {
        const evtCalendar = calendarList
          .find((cal) => cal.id === event.googleCalendarId);

        return (
          <ListItemButton
            alignItems="flex-start"
            component="li"
            key={event.id}
            onClick={handleSetEvent(event)}
            role="listitem"
            sx={{
              paddingY: 1,
            }}
          >
            <ListItemIcon sx={{ marginTop: 0 }}>
              <EventIcon
                fontSize="small"
                htmlColor={evtCalendar?.backgroundColor ?? theme.palette.prestoPrimary}
              />
            </ListItemIcon>

            <ListItemText
              primary={event.summary}
              secondary={`${humanizeDateShort(
                event.start.dateTime ?? event.start.date ?? '',
                { includeTime: !!event.start.dateTime },
              )}${event.start.dateTime ? '' : ' - All Day'}`}
              sx={{ marginY: 0 }}
            />
          </ListItemButton>
        );
      })}

      {calendarEvent && (
        <EventDialog
          event={calendarEvent}
          onClose={handleClearEvent}
        />
      )}
    </>
  );
};

export default EventList;
