// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { SaveButton } from 'components/shared';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { useCreateManyFinancialPayments } from 'gql/mutations';

// Local Dependencies
import PaymentPreviewTableRow from './PaymentPreviewTableRow';

// Local Typings
interface Props {
  onClickCancel: () => void;
  payload: GQL.ICreateFinancialPaymentInput[] | null;
}

// Component Definition
const PaymentFormReviewDialog = ({
  onClickCancel,
  payload,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const navigateToShow = useCallback((id: string) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}/${id}`);
  }, [navigate]);

  const [
    handleCreateFinancialPayment,
    {
      loading: isSubmitting,
    },
  ] = useCreateManyFinancialPayments(
    {
      onCompleted: (data) => navigateToShow(data.createManyFinancialPayments[0].id),
    },
  );

  const handleSubmit = useCallback(() => {
    if (payload) {
      handleCreateFinancialPayment({
        variables: {
          input: payload,
        },
      });
    }
  }, [handleCreateFinancialPayment, payload]);

  const totalPaymentInCents = useMemo(
    () =>
      (payload ?? []).reduce((prev, curr) => prev + curr.amountInCents, 0),
    [payload],
  );

  const totalCreditInCents = useMemo(
    () =>
      (payload ?? []).reduce((prev, curr) => prev + (curr?.creditAppliedAmountInCents ?? 0), 0),
    [payload],
  );

  return (
    <Dialog
      maxWidth="lg"
      open={Boolean(payload)}
    >
      <DialogTitle>Payment Review</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          {/* eslint-disable-next-line max-len */}
          Please review the following payment information. Once the payment is entered, it cannot be deleted.
        </DialogContentText>

        <TableContainer
          component={Paper}
          variant="outlined"
        >
          <Table
            size="small"
          >
            <caption>Payment review</caption>

            <TableHead>
              <TableRow>
                <TableCell width={200}>
                  User
                </TableCell>
                <TableCell width={150}>
                  Item
                </TableCell>
                <TableCell width={150}>
                  Payment Type
                </TableCell>
                <TableCell
                  align="right"
                  width={150}
                >
                  Payment Amount
                </TableCell>
                <TableCell
                  align="right"
                  width={150}
                >
                  Credit Applied
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {payload?.map((paymentInput) => (
                <PaymentPreviewTableRow
                  key={paymentInput.financialFeeId}
                  paymentInput={paymentInput}
                />
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  {displayPriceStringFromDollarAmount(
                    convertCentsToDollars(totalPaymentInCents),
                  )}
                </TableCell>
                <TableCell align="right">
                  {displayPriceStringFromDollarAmount(
                    convertCentsToDollars(totalCreditInCents),
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={onClickCancel}
        >
          Cancel
        </Button>

        <SaveButton
          isSaving={isSubmitting}
          onClick={handleSubmit}
        >
          Submit
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentFormReviewDialog;
