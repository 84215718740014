// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import {
  GET_UNIFORM,
  SimpleUser,
  useGetSimpleUniform,
} from 'gql/queries';
import { SelectUser } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useCreateUniformCheckout } from 'gql/mutations';

// Local Typings
interface Props {
  onClose: () => void;
  uniformId: string | null;
}

// Local Variables
const StyledDialog = styled(Dialog)({
  '.MuiPaper-root': {
    overflow: 'visible',
  },
});

// Component Definition
const QuickCheckoutDialog: FC<Props> = ({
  onClose,
  uniformId,
}) => {
  const dispatch = useDispatch();

  const {
    data: initialUniformItem,
    loading: isInitialUniformLoading,
  } = useGetSimpleUniform(uniformId ?? undefined);

  const [
    createUniformCheckout,
    {
      loading: isCreatingUniformCheckout,
    },
  ] = useCreateUniformCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: () => {
        dispatch(updateIsPaginatedListDataLoaded({
          isPaginatedListDataLoaded: false,
        }));

        dispatch(
          addNotification('Uniform checked out!', 'success'),
        );

        onClose();
      },
      refetchQueries: () => [
        { query: GET_UNIFORM, variables: { id: uniformId } },
      ],
    },
  );

  const handleSubmit = useCallback(async (member: SimpleUser | null) => {
    if (uniformId && member) {
      createUniformCheckout({
        variables: {
          checkoutDate: moment().toISOString(),
          dueDate: '',
          uniformId,
          userId: member.id,
        },
      });
    }
  }, [createUniformCheckout, uniformId]);

  const uniNum = initialUniformItem?.uniform.uniformNumber ?? '';
  const uniType = initialUniformItem?.uniform.uniformType.label ?? '';
  const uniCategory = initialUniformItem?.uniform.category?.label ?? '';
  const uniSize = initialUniformItem?.uniform.size ?? '';

  return (
    <StyledDialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={Boolean(uniformId)}
    >
      <DialogTitle>
        Uniform Checkout
      </DialogTitle>

      <DialogContent sx={{ width: '100%' }}>
        {(isInitialUniformLoading) && <CircularProgress />}

        {initialUniformItem && (
          <DialogContentText paragraph>
            {uniSize} {uniType} {uniCategory} {uniNum && ` (${uniNum})`}
          </DialogContentText>
        )}

        {initialUniformItem && (
          <SelectUser
            isSubmitting={isCreatingUniformCheckout}
            onSelectUser={handleSubmit}
            submitButtonText="Check Out"
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default QuickCheckoutDialog;
