// External Dependencies
import { ChangeEvent, FC, useState } from 'react';
import {
  FormControl, TextField, TextFieldProps,
} from '@mui/material';
import styled from 'styled-components';

// Local Typings
export interface InputProps {
  error?: boolean;
  errorMessage?: string;
  forceError?: boolean;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

// Local Variables
const StyledTextField = styled(TextField)(({ error, margin, theme }) => ({
  '& input, & label, & p': {
    color: error ? theme.palette.error.main : undefined,
  },

  margin: margin === 'none' ? 0 : '8px 0',
}));

// Component Definition
const Input: FC<InputProps & TextFieldProps> = ({
  error,
  errorMessage,
  forceError,
  fullWidth,
  id,
  label,
  ...props
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const handleBlur: TextFieldProps['onBlur'] = (evt) => {
    setIsTouched(true);
    if (props.onBlur) {
      props.onBlur(evt);
    }
  };

  const hasError = (error && isTouched) || forceError;
  const inputId = id ?? label?.toString() ?? '';

  return (
    <FormControl fullWidth={fullWidth}>
      <StyledTextField
        error={hasError}
        helperText={hasError ? errorMessage : null}
        fullWidth={fullWidth}
        label={label}
        id={inputId}
        {...props}
        onBlur={handleBlur}
      />
    </FormControl>
  );
};

export default Input;
