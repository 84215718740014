// External Dependencies
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material/';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';

// Local Dependencies
import InfoSection from '../InfoSection';
import InfoTitle from '../InfoTitle';

// Component Definition
const WhatDoWeCollect = () => (
  <>
    <InfoTitle>
      What do we collect?
    </InfoTitle>

    <InfoSection>
      <Typography>
        {APP_NAME} stores information such as:
      </Typography>

      <List dense>
        <ListItem>
          <ListItemText primary="Name" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Date of Birth" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Gender" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Phone Number" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Address" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Grade" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Student ID" />
        </ListItem>
      </List>
    </InfoSection>
  </>
);

export default WhatDoWeCollect;
