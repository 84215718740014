// External Dependencies
import * as Yup from 'yup';
import { FC, useState } from 'react';

// Internal Dependencies
import { Box } from '@mui/material';
import {
  CustomInput,
  DialogForm,
  EnhancedAlert,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { RESEND_VERIFICATION_EMAIL, useUpdateAuthUser } from 'gql/mutations';
import { useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selfEmail?: string;
  setIsEmailSentAgain: (isEmailSentAgain: boolean) => void;
}

// Local Variables
const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please use a valid email')
    .required('Required'),
});

// Component Definition
const DialogResendVerificationEmail: FC<Props> = ({
  isOpen,
  onClose,
  selfEmail,
  setIsEmailSentAgain,
}) => {
  const [submitError, setSubmitError] = useState('');

  const [updateEmail, { loading: isSubmittingUpdateEmail }] = useUpdateAuthUser();

  const [
    resendVerificationEmail,
    { loading: isSubmittingResendVerificationEmail },
  ] = useMutationEnhanced(RESEND_VERIFICATION_EMAIL);

  const isSubmitting = isSubmittingUpdateEmail || isSubmittingResendVerificationEmail;

  const handleClose = () => {
    onClose();
    setSubmitError('');
  };

  const handleSubmitResendEmail = async (values: { email: string }) => {
    try {
      let resendResponse;

      const emailResponse = await updateEmail({
        refetchQueries: [{ query: GET_SELF }],
        variables: {
          input: {
            email: values.email,
          },
        },
      });

      if (emailResponse) {
        resendResponse = await resendVerificationEmail();
      }

      if (resendResponse?.data.resendVerificationEmail) {
        setIsEmailSentAgain(true);
        handleClose();
      }
    } catch (err) {
      setSubmitError(
        'There was an error resending your email. Please try again.',
      );
    }
  };

  return (
    <DialogForm
      dialogProps={{
        maxWidth: 'xs',
        open: isOpen,
      }}
      initialValues={{
        email: selfEmail ?? '',
      }}
      key="resend-verification-email"
      isSubmitting={isSubmitting}
      onClose={handleClose}
      onSubmit={handleSubmitResendEmail}
      submitText="Send"
      subTitle="Enter your email address below, and we'll resend your verification email right away."
      title="Resend Verification Email?"
      validationSchema={emailSchema}
    >
      <Box marginY={2}>
        <EnhancedAlert>
          Updating your email address will also update your login credentials.
        </EnhancedAlert>
      </Box>

      <CustomInput
        error={Boolean(submitError)}
        helperText={submitError}
        label="Email"
        name="email"
      />
    </DialogForm>
  );
};

export default DialogResendVerificationEmail;
