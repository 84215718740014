// External Dependencies
import { FormBlockTypes, UserRoles } from '@presto-assistant/api_types';
import { getFullName, getFullNameWithEmail } from '@presto-assistant/api_types/utils';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  Page,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { formatDate, formatTime } from 'utils';
import { isDirector } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetMyForm } from 'gql/queries/form-queries';
import { useGetSelf } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useSubmitFinalFormResponses } from 'gql/mutations/form-mutations';

// Local Dependencies
import FormSectionCard from '../../components/FormSectionCard';
import MyFormBlock from './MyFormBlock';

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const formatResponse = (row: GQL.IMyFormBlock) => {
  const formBlockType = Number(row.formBlockType.id);

  switch (formBlockType) {
    case FormBlockTypes.Boolean:
      return row.response === 'true' ? 'Yes' : 'No';
    case FormBlockTypes.Date:
      if (!row.response) {
        return '';
      }

      return formatDate(row.response);
    default:
      return row.response;
  }
};

// Component Definition
const MyFormsShow = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: formAssignmentId } = useParamsWithId();

  const isUserDirector = useSelector(isDirector);

  const {
    isOpen: isConfirmPublishDialogOpen,
    toggleIsOpen: toggleConfirmPublishDialog,
  } = useIsOpen();

  const [dirtyFormBlockByIndex, setDirtyFormBlockByIndex] = useState<boolean[]>([]);

  const {
    data,
  } = useGetMyForm(formAssignmentId);

  const myFormsParams = useSelector(tableQueryParams('myForms'));

  const { data: selfData } = useGetSelf();

  const isStudent = selfData?.self?.role.id === UserRoles.Student.toString();

  useEffect(() => {
    if (data?.myForm?.formBlocks) {
      setDirtyFormBlockByIndex(data.myForm.formBlocks.map(() => false));
    }
  }, [data?.myForm?.formBlocks]);

  const isFormDirty = useMemo(() => {
    return dirtyFormBlockByIndex.some((isDirty) => isDirty);
  }, [dirtyFormBlockByIndex]);

  const handleSetIsFormDirtyWithIndex = useCallback((isDirty: boolean, index: number) => {
    setDirtyFormBlockByIndex((prev) => {
      const next = [...prev];
      next[index] = isDirty;
      return next;
    });
  }, []);

  const [
    submitFinalFormResponses,
  ] = useSubmitFinalFormResponses({
    onCompleted: () => {
      dispatch(addNotification('Form submitted successfully!', 'success'));
      if (isUserDirector) {
        navigate(`/${PATHS.FORMS}/${data?.myForm?.id ?? ''}`);
      } else {
        navigate(`/${PATHS.MY_FORMS}${myFormsParams}`);
      }
    },
  });

  const unansweredRequiredParentQuestions = data?.myForm?.formBlocks?.filter((block) => {
    const needsParentSignature = block.metadata.adultSignature
      && !block.response && block.isRequired;
    const needsParentInitials = block.metadata.adultInitials
      && !block.response && block.isRequired;

    const requiresParentResponse = needsParentSignature || needsParentInitials;

    return requiresParentResponse;
  });

  const requiredParentQuestions = data?.myForm?.formBlocks?.filter((block) => {
    const needsParentSignature = block.metadata.adultSignature && block.isRequired;
    const needsParentInitials = block.metadata.adultInitials && block.isRequired;

    const requiresParentResponse = needsParentSignature || needsParentInitials;

    return requiresParentResponse;
  });

  const hasParentQuestions = (requiredParentQuestions?.length ?? 0) > 0;

  const unanswerParentQuestionIds = unansweredRequiredParentQuestions?.map((block) => block.id);

  const hasUnansweredRequiredQuestions = data?.myForm?.formBlocks?.some((block) => {
    if (isStudent && unanswerParentQuestionIds?.includes(block.id)) {
      return false;
    }

    return !block.response && block.isRequired;
  });

  const confirmButtonText = useMemo(() => {
    if (hasUnansweredRequiredQuestions) {
      return 'Close';
    }

    if (hasParentQuestions && isStudent) {
      return 'Done';
    }

    return 'Submit';
  }, [hasParentQuestions, hasUnansweredRequiredQuestions, isStudent]);

  const handleConfirmSubmit = useCallback(() => {
    if (hasUnansweredRequiredQuestions) {
      toggleConfirmPublishDialog();
      return;
    }

    if (hasParentQuestions && isStudent) {
      navigate(`/${PATHS.MY_FORMS}`);
      return;
    }

    submitFinalFormResponses({
      variables: {
        formAssignmentId: data?.myForm?.assignmentId ?? '',
      },
    });
  }, [
    data?.myForm?.assignmentId,
    hasParentQuestions,
    hasUnansweredRequiredQuestions,
    isStudent,
    navigate,
    submitFinalFormResponses,
    toggleConfirmPublishDialog,
  ]);

  const responsesReviewTable = useMemo(() => {
    const formResponses = data?.myForm?.formBlocks?.filter((block) => {
      const formBlockTypeId = Number(block.formBlockType.id);

      const isHeadingOrParagraph = formBlockTypeId === FormBlockTypes.Heading
        || formBlockTypeId === FormBlockTypes.Paragraph;

      return !isHeadingOrParagraph;
    });

    return (
      <>
        <TableContainer component={EnhancedCard}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Response</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {formResponses?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {row.isRequired ? '* ' : ''}
                    {row.label}
                  </TableCell>

                  <TableCell>
                    {formatResponse(row)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box marginTop={1}>
          <Typography variant="body2">
            * Required
          </Typography>
        </Box>

        {hasParentQuestions && isStudent && !hasUnansweredRequiredQuestions && (
          <EnhancedAlert
            sx={{ marginTop: 2 }}
            title="Adult Must Submit"
          >
            This form has required responses from a parent.
            Please ask your parent to complete and submit this form.
          </EnhancedAlert>
        )}

        {hasUnansweredRequiredQuestions && (
          <EnhancedAlert
            severity="error"
            sx={{ marginTop: 2 }}
          >
            Please answer all required questions before submitting.
          </EnhancedAlert>
        )}
      </>
    );
  }, [data?.myForm, hasParentQuestions, hasUnansweredRequiredQuestions, isStudent]);

  const groupedFormBlocks = useMemo<GQL.IMyFormBlock[][]>(() => {
    const grouped: GQL.IMyFormBlock[][] = [];

    data?.myForm.formBlocks.forEach((block) => {
      const lastGroup = grouped[grouped.length - 1];

      if (!lastGroup) {
        grouped.push([block]);
        return;
      }

      const lastBlock = lastGroup[lastGroup.length - 1];

      if (lastBlock.isFixed && block.isFixed) {
        lastGroup.push(block);
        return;
      }

      grouped.push([block]);
    });

    return grouped;
  }, [data?.myForm.formBlocks]);

  return (
    <Page
      backButtonProps={{
        label: 'My Forms',
        path: `/${PATHS.MY_FORMS}${myFormsParams}`,
      }}
    >
      <StyledContainer maxWidth="md">
        <FormSectionCard
          hasNoPadding
          hasTopBorder
        >
          <EnhancedCardContent>
            <Typography variant="h4">
              {data?.myForm.title ?? ''}
            </Typography>
          </EnhancedCardContent>

          {data?.myForm && (
            <>
              <Divider />

              <EnhancedCardContent>
                <ShowPageDataDisplay
                  label="Assigned To"
                  value={getFullNameWithEmail(data.myForm.assignedTo)}
                />

              </EnhancedCardContent>
            </>
          )}
        </FormSectionCard>

        {groupedFormBlocks.map((group, index) => (
          <FormSectionCard
            key={group.map((block) => block.id).join('—')}
          >
            {group.map((block) => (
              <MyFormBlock
                disabled={data?.myForm.submittedAt !== null}
                formAssignmentId={data?.myForm.assignmentId ?? ''}
                formBlock={block}
                formId={data?.myForm.id ?? ''}
                index={index}
                key={block.id}
                setIsFormDirtyWithIndex={handleSetIsFormDirtyWithIndex}
              />
            ))}
          </FormSectionCard>
        ))}

        {data?.myForm.submittedAt && data?.myForm.submittedBy && (
          <EnhancedAlert
            severity="info"
            sx={{ mt: 2 }}
          >
            This form was submitted by {getFullName(data.myForm.submittedBy)} on
            {' '}
            {formatDate(data.myForm.submittedAt)} at {formatTime(data.myForm.submittedAt)}.
          </EnhancedAlert>
        )}

        {data?.myForm.submittedAt === null && (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              disabled={isFormDirty}
              onClick={toggleConfirmPublishDialog}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        )}
      </StyledContainer>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmSubmit}
        confirmButtonText={confirmButtonText}
        declineButtonAction={hasUnansweredRequiredQuestions
          ? undefined : toggleConfirmPublishDialog}
        description={responsesReviewTable}
        handleClose={toggleConfirmPublishDialog}
        hideDeclineButton={hasUnansweredRequiredQuestions}
        maxWidth="md"
        open={isConfirmPublishDialogOpen}
        title="Are you ready to submit this form?"
        useCustomText
      />
    </Page>
  );
};

export default MyFormsShow;
