// External Dependencies
import {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { getOperationName } from '@apollo/client/utilities';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';

// Internal Dependencies
import { DELETE_GROUPS } from 'gql/mutations';
import { EnhancedIconButton, TableDataGrid } from 'components/shared';
import {
  GET_GROUPS_INDEX,
  GroupsUsersResponse,
  useGetGroupsIndex,
  useGetGroupsUsers,
} from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { SELECTION_TYPES } from 'utils/constants';
import { clearRecipients, updateRecipients } from 'state/ui/emailNew/actions';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import {
  isTableSelectionActive,
  tableQueryParams,
} from 'state/table/selectors';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';

// Local Dependencies
import { useColumns } from './hooks';
import GroupsTableZeroState from './GroupsTableZeroState';

// Component Definition
const GroupsTable: FC = () => {
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedGroupItemIds, setSelectedGroupItemIds] = useState<GridRowSelectionModel>([]);

  const groupShowParams = useSelector(tableQueryParams('group'));

  const {
    isOpen: isDeleteDialogGroupOpen,
    toggleIsOpen: toggleDeleteGroupDialog,
  } = useIsOpen();

  const isSelectionActive = useSelector(isTableSelectionActive('groups'));

  const { data, fullCount, isLoading } = useGetGroupsIndex();

  const handleCompletedGetUsers = useCallback((response: GroupsUsersResponse) => {
    const userIds = response.groupsUsers.map((u) => u.id);
    dispatch(clearRecipients());
    dispatch(updateRecipients(userIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch, navigate]);

  const [getGroupsUsers] = useGetGroupsUsers(handleCompletedGetUsers);

  const canWriteGroups = useSelector(hasPermission('groups', 'write'));
  const canDeleteGroups = useSelector(hasPermission('groups', 'delete'));
  const canWriteEmails = useSelector(hasPermission('emailMembers', 'write'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const handleClickEmailSelectedGroups = useCallback(() => {
    if (!selectedGroupItemIds?.length) {
      return;
    }

    getGroupsUsers({
      variables: {
        selection: {
          ids: selectedGroupItemIds as string[],
          mutationFlag: SELECTION_TYPES.SELECTED_MANY as GQL.MutationFlag,
          queryParams: {},
        },
      },
    });
  }, [getGroupsUsers, selectedGroupItemIds]);

  const handleClickTableRow = canReadUsers
    ? (id: string) => `/${PATHS.GROUPS}/${id}${groupShowParams}`
    : (id: string) => `/${PATHS.GROUPS}/${id}`;

  const handleRemoveSelectionAll = useClearTableSelection('groups');

  const handleOpenSingleItemDeleteDialog = useCallback(
    (row: GQL.IGroupIndexItem) => {
      setSingleItemDeleteId(row.id);
      toggleDeleteGroupDialog();
    },
    [toggleDeleteGroupDialog],
  );

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IGroupIndexItem>([
        ...(canDeleteGroups ? [
          {
            action: handleOpenSingleItemDeleteDialog,
            icon: <DeleteIcon />,
            text: 'Delete',
          },
        ] : []),
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteGroups,
      handleOpenSingleItemDeleteDialog,
    ],
  );

  const columns = useColumns(extraColumns);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];
    if (canWriteEmails) {
      actions.push({
        action: handleClickEmailSelectedGroups,
        icon: <EmailIcon />,
        isDisabled: isSelectionActive,
        text: 'Email selected groups',
      });
    }
    if (canDeleteGroups) {
      actions.push({
        action: toggleDeleteGroupDialog,
        icon: <DeleteIcon />,
        isDisabled: isSelectionActive,
        text: 'Delete selected groups',
      });
    }
    return actions;
  }, [
    canWriteEmails,
    canDeleteGroups,
    handleClickEmailSelectedGroups,
    isSelectionActive,
    toggleDeleteGroupDialog,
  ]);

  const selectionIconElements: React.ReactElement[] = [];

  if (canWriteEmails && canReadUsers) {
    selectionIconElements.unshift(
      <EnhancedIconButton
        icon={<EmailIcon fontSize="small" />}
        key="email-icon-toolbar"
        onClick={handleClickEmailSelectedGroups}
        tooltip="Email group"
      />,
    );
  }
  if (canDeleteGroups) {
    selectionIconElements.push(
      <EnhancedIconButton
        icon={<DeleteIcon fontSize="small" />}
        key="delete-icon-toolbar"
        onClick={toggleDeleteGroupDialog}
        tooltip="Delete group"
      />,
    );
  }

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={
            canWriteGroups
              ? {
                label: 'Group',
                to: `/${PATHS.GROUPS_NEW}`,
              }
              : null
          }
          checkboxSelection={canDeleteGroups}
          clickRowTo={handleClickTableRow}
          columns={columns}
          components={{
            NoRowsOverlay: GroupsTableZeroState,
          }}
          loading={isLoading}
          onSelectionModelChange={setSelectedGroupItemIds}
          rows={data}
          selectionModel={selectedGroupItemIds}
          tableResource="groups"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DeleteDialog
        clearCachePredicates={['groupUsers', 'groups', 'user']}
        context={['group']}
        hasSelection={selectedGroupItemIds?.length > 0}
        idsToDelete={selectedGroupItemIds as string[]}
        isOpen={isDeleteDialogGroupOpen}
        mutation={DELETE_GROUPS}
        onClose={toggleDeleteGroupDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="groups"
        refetchQueries={() => [getOperationName(GET_GROUPS_INDEX) as string]}
        singleItemId={singleItemDeleteId}
        size={fullCount ?? 0}
      />
    </>
  );
};

export default GroupsTable;
