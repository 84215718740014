// External Dependencies
import Box from '@mui/material/Box';

// Local Dependencies
import AskPresto from './AskPresto';

// Component Definition
const Feedback = (): JSX.Element => {
  return (
    <Box marginTop={2}>
      <AskPresto />
    </Box>
  );
};

export default Feedback;
