// External Dependencies
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { useGetDistrictInvitations } from 'gql/queries/district-invitation-queries';
import { useGetOrganization } from 'gql/queries';
import ConfirmDistrictInvitations from 'components/shared/ConfirmDistrictInvitations';

// Local Dependencies
import { EnhancedCard } from '..';
import DistrictCardHeader from './DistrictCardHeader';
import InvitationToDfaForm from './InvitationToDfaForm';

// Component Definition
const NoDistrictUI = (): JSX.Element | null => {
  const { data: orgData } = useGetOrganization();
  const { data } = useGetDistrictInvitations();

  if (!data) {
    return null;
  }

  const { districtInvitations } = data;

  const hasDistrictInvitesToConfirm = districtInvitations
    .some((invitation) => invitation.accepted === null);

  return hasDistrictInvitesToConfirm ? (
    <ConfirmDistrictInvitations title="District Invitations" />
  ) : (
    <EnhancedCard>
      <DistrictCardHeader />

      <CardContent>
        <Typography>
          {orgData?.organization.label} does not have a district.
        </Typography>

        <Box mt={2}>
          <InvitationToDfaForm />
        </Box>
      </CardContent>
    </EnhancedCard>
  );
};

export default NoDistrictUI;
