// External Dependencies
import { StripeStatus } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  EnhancedAlert, History, Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialPayment } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import ShowFinancialPaymentData from './ShowFinancialPaymentData';

// Component Definition
const PaymentsShowPage = (): JSX.Element => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetFinancialPayment(id!);

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialPaymentsParams = useSelector(tableQueryParams(`financialPayments-${schoolYearEnding}`));
  const canEditPayments = useSelector(hasPermission('payments', 'edit'));

  const isPaymentEditable = data?.financialPayment.isEditable;

  const editPath = canEditPayments && isPaymentEditable?.status
    ? `/${PATHS.FINANCIAL_PAYMENTS}/${id}/edit` : undefined;

  const isFailedPayment = data?.financialPayment.stripePaymentStatusId === StripeStatus.Failed;
  const isFailedRefund = data?.financialPayment.stripeRefundStatusId === StripeStatus.Failed;

  return (
    <Page
      backButtonProps={{
        label: 'Payments',
        path: `/${PATHS.FINANCIAL_PAYMENTS}${financialPaymentsParams}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={!data || loading}
    >
      {canEditPayments && !isPaymentEditable?.status && !isFailedPayment && !isFailedRefund && (
        <Box marginBottom={2}>
          <EnhancedAlert>
            {isPaymentEditable?.reason}
          </EnhancedAlert>
        </Box>
      )}

      {(isFailedPayment || isFailedRefund) && (
        <Box marginBottom={2}>
          <EnhancedAlert severity="error">
            {isFailedPayment
              ? 'This online transaction failed. This payment has not been applied to the member\'s fees.'
              : 'This refund failed. You will need to issue a refund to the member with cash.'}
          </EnhancedAlert>
        </Box>
      )}

      <ShowFinancialPaymentData
        editPath={editPath}
        payment={data?.financialPayment}
      />

      {data?.financialPayment && (
        <History
          id={data.financialPayment.id}
          resource="financialPayments"
        />
      )}
    </Page>
  );
};

export default PaymentsShowPage;
