// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { DELETE_UNIFORMS } from 'gql/mutations';
import {
  GET_DISTRICT_UNIFORM_ITEMS_INDEX,
  useDownloadDistrictUniformsIndexItems,
  useGetDistrictUniformIndexItems,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { ToolbarProps } from 'components/shared/DataTable/Toolbar';
import { parseSearch } from 'utils';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialog from 'components/shared/DeleteDialog';
import TableV2 from 'components/shared/TableV2';

// Local Dependencies
import { useColumns } from './hooks';
import DialogUniformFileUpload from './DialogUniformFileUpload';
import Filters from './Filters';

// Component Definition
const AdminUniformTable: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const parsedSearch = useMemo(() => {
    return parseSearch(location.search);
  }, [location.search]);

  const [
    singleItemDeleteId,
    setSingleItemDeleteId,
  ] = useState<string | null>(null);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();

  const {
    handleClose: closeUploadDialog,
    handleOpen: openUploadDialog,
    isOpen: isUploadDialogOpen,
  } = useIsOpen();

  const {
    data,
    loading,
  } = useGetDistrictUniformIndexItems();

  const [
    handleExportUniforms,
    { loading: isExporting },
  ] = useDownloadDistrictUniformsIndexItems();

  const handleRemoveSelectionAll = useClearTableSelection('districtUniforms');

  const handleOpenSingleItemDeleteDialog = useCallback((
    row: GQL.IDistrictUniformIndexItem,
  ) => {
    setSingleItemDeleteId(row.id);
  }, [setSingleItemDeleteId]);

  const handleCloseDeleteDialog = () => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  };

  const handleNavigateToDynamicFields = useCallback(() => {
    const organizationTypeId = parsedSearch.organizationTypeId ?? '1';

    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}?organization_type_id=${organizationTypeId}&table_ref=uniforms`);
  }, [navigate, parsedSearch]);

  const hasAtLeastOneUniformItem = (data?.districtUniformItemsIndex.fullCount ?? 0) > 0;

  const columns = useColumns(
    handleOpenSingleItemDeleteDialog,
    parsedSearch.organizationTypeId,
  );

  const moreActions: ToolbarProps['moreActions'] = [
    {
      action: handleExportUniforms,
      icon: <DownloadIcon />,
      isDisabled: isExporting || (data?.districtUniformItemsIndex.fullCount ?? 0) < 1,
      text: 'Export uniforms',
    },
    {
      action: openUploadDialog,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import uniforms',
    },
    {
      action: handleNavigateToDynamicFields,
      icon: <CategoryIcon />,
      text: 'Dynamic fields',
    },
  ];

  return (
    <>
      <TableV2<GQL.IDistrictUniformIndexItem>
        Filters={<Filters />}
        actions={moreActions}
        addButtonProps={{
          label: 'Uniform',
          to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/new`,
        }}
        clickRowTo={(row) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${row.id}`}
        columns={columns}
        data={data?.districtUniformItemsIndex.data ?? []}
        disableSearch={!parsedSearch.q && !hasAtLeastOneUniformItem}
        fullCount={data?.districtUniformItemsIndex.fullCount}
        isLoading={loading}
        reduxStateKey="districtUniforms"
        withSearch
        zeroStateMessage="Add data to this table by adding individually. Uniforms added by organizations will also appear here."
      />

      <DialogUniformFileUpload
        isOpen={isUploadDialogOpen}
        onClose={closeUploadDialog}
      />

      <DeleteDialog
        context={['item']}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_UNIFORMS}
        onClose={handleCloseDeleteDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="districtUniforms"
        refetchQueries={() => [
          getOperationName(GET_DISTRICT_UNIFORM_ITEMS_INDEX) as string,
        ]}
        singleItemId={singleItemDeleteId}
        size={data?.districtUniformItemsIndex.fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default AdminUniformTable;
