// External Dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { useHasRelationships } from 'hooks/useHasRelationships';

// Local Dependencies
import FinancialCreditsTable from './FinancialCreditsTable';
import LinkToMyCredits from './LinkToMyCredits';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const FinancialCredits = (): JSX.Element => {
  // We only show the link to MyCredits if the user has relationships
  const hasRelationships = useHasRelationships();

  return (
    <>
      <Subtitle>
        Financial Credits
      </Subtitle>

      <Box
        marginBottom={1.5}
        marginLeft={2}
      >
        <StyledTypography
          color="textSecondary"
          variant="body2"
        >
          Credits will appear here when members overpay for fees.
        </StyledTypography>
      </Box>

      <Box marginBottom={4}>
        <EnhancedCard>
          <FinancialCreditsTable />
        </EnhancedCard>
      </Box>

      {hasRelationships && <LinkToMyCredits />}
    </>
  );
};

export default FinancialCredits;
