// eslint-disable-next-line no-shadow
export enum KeyCodes {
  Enter = 13,
  Space = 32,
  Tab = 9,
}

// eslint-disable-next-line no-shadow
export enum EventKeys {
  Enter = 'Enter'
}
