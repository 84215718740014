// External Dependencies
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';

// Internal Dependencies
import { CustomInput, FormActionButtons, ShowCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrict } from 'gql/queries';
import { useUpdateDistrictSettings } from 'gql/mutations';
import CardSectionTitle from 'components/shared/CardSectionTitle';
import CustomCheckbox from 'components/shared/CustomCheckbox';

// Component Definition
const DistrictSettingsForm = (): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToSettings = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}`);
  }, [navigate]);

  const {
    data: districtData,
  } = useGetDistrict();

  const [
    updateDistrictSettings,
  ] = useUpdateDistrictSettings({
    onCompleted: handleNavigateToSettings,
  });

  const handleSubmit = useCallback((values: GQL.IUpdateDistrictSettingsInput) => {
    updateDistrictSettings({
      variables: {
        input: {
          ...values,
          customBarcodeCursor: values.customBarcodeCursor?.toString(),
        },
      },
    });
  }, [updateDistrictSettings]);

  return (
    <Formik<GQL.IUpdateDistrictSettingsInput>
      enableReinitialize
      initialValues={{
        canUseIndividualizedFundraiserCredits:
          districtData?.district.canUseIndividualizedFundraiserCredits,
        customBarcodeCursor: districtData?.district.customBarcodeCursor,
        isDistrictLibraryEnabled: districtData?.district.isDistrictLibraryEnabled,
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <ShowCard
            cardActions={(
              <FormActionButtons
                context="Settings"
                isEditing
                isSubmitting={false}
                onClickCancelButton={handleNavigateToSettings}
              />
            )}
            cardContentProps={{
              sx: {
                padding: 0,
              },
            }}
            icon={SettingsIcon}
            title="District Settings"
          >
            <CardContent>
              <CardSectionTitle gutterBottom>
                Inventory Settings
              </CardSectionTitle>

              <CustomInput
                helperText="If you provide a minimum barcode, Presto will auto-generate serialized barcodes for future items by default."
                label="Minimum barcode value"
                name="customBarcodeCursor"
                type="number"
              />
            </CardContent>

            <Divider />

            <CardContent>
              <CardSectionTitle gutterBottom>
                Library Settings
              </CardSectionTitle>

              <CustomCheckbox
                helperText="If enabled, the district library will be viewable to all organizations in this district"
                label="District library enabled"
                name="isDistrictLibraryEnabled"
              />
            </CardContent>

            <Divider />

            <CardContent>
              <CardSectionTitle gutterBottom>
                Fundraiser Settings
              </CardSectionTitle>

              <CustomCheckbox
                helperText="If enabled, directors can assign indivudal amounts to students for fundraisers credits."
                label="Can use individualized fundraiser credits"
                name="canUseIndividualizedFundraiserCredits"
              />
            </CardContent>
          </ShowCard>
        </Form>
      )}
    </Formik>
  );
};

export default DistrictSettingsForm;
