// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

// Internal Dependencies
import { pluralize } from 'utils';

// Local Typings
interface Props {
  count: number;
  name?: string;
  resource: 'inventory' | 'uniform';
}

// Local Variables
export const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const CheckoutCount: FC<Props> = ({
  count,
  name,
  resource,
}) => (
  <Typography component="p">
    {name ? `${name} has` : 'You have'}
    {' '}
    <StyledStrong>{count}</StyledStrong>{' '}
    {resource} {pluralize(count, 'item')}{' '}
    checked out.
  </Typography>
);

export default CheckoutCount;
