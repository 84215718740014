// External Dependencies
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedDialog,
  SlideUpTransition,
} from 'components/shared';
import { useCreateReturningGroups } from 'gql/mutations';

// Local Dependencies
import ReturningGroupsTable from './ReturningGroupsTable';

// Local Typings
interface Props {
  existingReturningGroups: ReturningGroupsState[] | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export interface ReturningGroupsState {
  groupId: string;
  shouldRetainUsers: boolean;
}

// Local Variables
const StyledDialogRoot = styled(EnhancedDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2, 2, 0, 0),
    bottom: 0,
    height: 600,
    margin: theme.spacing(0, 1.5),
    maxHeight: '95%',
    maxWidth: 1250,
    position: 'absolute',
  },

  '& .MuiDialogContent-root': {
    height: 400,
    padding: 0,
    width: '100%',
  },

  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// Component Definition
const DialogReturningGroups: FC<Props> = ({
  existingReturningGroups,
  isOpen,
  onClose,
}) => {
  const [
    returningGroups,
    setReturningGroups,
  ] = useState<ReturningGroupsState[]>(
    existingReturningGroups || [],
  );

  const [
    createReturningGroups,
    {
      loading: isCreatingReturningGroups,
    },
  ] = useCreateReturningGroups({
    variables: {
      input: returningGroups,
    },
  });

  const handleCreateReturningGroups = useCallback(async () => {
    await createReturningGroups();
    onClose();
  }, [createReturningGroups, onClose]);

  return (
    <StyledDialogRoot
      TransitionComponent={SlideUpTransition}
      fullScreen
      maxWidth={false}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        <Typography
          component="div"
          variant="h6"
        >
          Select Returning Groups
        </Typography>

        <IconButton
          aria-label="Close Select Returning Groups dialog"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <ReturningGroupsTable
          returningGroups={returningGroups}
          setReturningGroups={setReturningGroups}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Discard
        </Button>

        <Button
          color="primary"
          disabled={isCreatingReturningGroups}
          onClick={handleCreateReturningGroups}
          variant="contained"
        >
          Select Groups
        </Button>
      </DialogActions>
    </StyledDialogRoot>
  );
};

export default DialogReturningGroups;
