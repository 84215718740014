// External Dependencies
import * as yup from 'yup';
import { yupToFormErrors } from 'formik';

// Util Definition
/**
 * Run validation against a Yup schema
 */
export const runValidationSchema = <T extends object>(
  value: string,
  field: keyof T,
  schema: yup.ObjectSchema<T>,
): Promise<T> => {
  const emptyErrors = {} as T;

  const inputToValidate = {
    [field]: value,
  } as T;

  const promise = schema.validateAt(
    field as string,
    inputToValidate,
    { abortEarly: true, strict: true },
  );

  return new Promise((resolve, reject) => {
    promise.then(
      () => {
        resolve(emptyErrors);
      },
      (err: any) => {
        // Yup will throw a validation error if validation fails. We catch those and
        // resolve them into Formik errors. We can sniff if something is a Yup error
        // by checking error.name.
        // @see https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string
        if (err.name === 'ValidationError') {
          resolve(yupToFormErrors(err) as T);
        } else {
          // We throw any other errors
          if (process.env.NODE_ENV !== 'production') {
            console.warn(
              'Warning: An unhandled error was caught during validation in validationSchema',
              err,
            );
          }

          reject(err);
        }
      },
    );
  });
};
