// External Dependencies
import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props {
  htmlColor?: string;
  rootClasses?: string;
}

// Local Variables
const StyledSvgIcon = styled(SvgIcon)({
  height: '100%',
  width: '100%',
});

// Component Definition
const PrestoLogoHorizontalSvg: FC<Props & SvgIconProps> = ({
  rootClasses,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      className={clsx(rootClasses)}
      htmlColor={theme.palette.logo}
      viewBox="0 0 1117 156.99"
      {...props}
    >
      <g
        id="Layer_2"
        data-name="Layer 2"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1"
        >
          <circle
            className="cls-1"
            cx="129.5"
            cy="78.16"
            r="23"
          />
          <path
            className="cls-1"
            d="M166.34,8.94c-14.1-7-26-10.47-48.71-8.29A65.5,65.5,0,0,0,93.29,8.41c-9.16,5.23-9.16,5.23-19.08,14.06L5.7,91A19.46,19.46,0,0,0,33.22,118.5h0L101.73,50A39.89,39.89,0,1,1,145,115.16a39.85,39.85,0,0,1-43.22-8.76l-7.85-7.86c-7.46-7.46-19.62-8-27.34-.82a19.47,19.47,0,0,0-.51,28l8.18,8.18A78.81,78.81,0,0,0,207.3,63.16a70,70,0,0,0-3.25-10.26c-2.8-7.38-2.92-8.51-7.16-15.17-12.08-19-20.66-22.7-30.55-28.79"
          />
          <path
            className="cls-1"
            d="M241.73,112.27V61c0-11.48,7.12-19.31,17.89-19.31,11.08,0,18.5,7.83,18.5,19.31V70.7c0,11.28-7.21,18.8-18.19,18.8h-5.49v22.77c0,1.22-.61,1.83-1.73,1.83h-9.15A1.62,1.62,0,0,1,241.73,112.27Zm17.38-34.45c4.07,0,6.21-2.24,6.21-6.71v-11c0-4-2-6.4-5.49-6.4-3.26,0-5.39,2.44-5.39,6.4V77.82Z"
          />
          <path
            className="cls-1"
            d="M305,112.27V61c0-11.48,7.32-19.31,18.1-19.31,11.08,0,18.5,7.83,18.5,19.31v8.85c0,7.21-2.85,12.7-7.53,16l9.86,26.42c.31,1.12-.61,1.83-1.72,1.83h-9.86c-1.12,0-1.43-.71-1.83-1.73L322.55,89.1h-4.78v23.17c0,1.22-.61,1.83-1.73,1.83h-9.25A1.62,1.62,0,0,1,305,112.27Zm17.49-34.86c4.16,0,6.3-2.54,6.3-7.12V60.13c0-4-2.14-6.4-5.59-6.4-3.25,0-5.39,2.44-5.39,6.4V77.41Z"
          />
          <path
            className="cls-1"
            d="M368.91,95.4V61.55c0-11.28,7.32-19,18.29-19h10.68a1.63,1.63,0,0,1,1.83,1.72V52.4a1.65,1.65,0,0,1-1.83,1.83h-9.15c-4.37,0-7,3.05-7,7.73V72.53h13.62a1.66,1.66,0,0,1,1.83,1.83v7.72a1.62,1.62,0,0,1-1.83,1.83H381.72V95.09c0,4.68,2.64,7.73,7,7.73h9.15a1.63,1.63,0,0,1,1.83,1.73v8.23a1.63,1.63,0,0,1-1.83,1.73H387.2C376.23,114.51,368.91,106.78,368.91,95.4Z"
          />
          <path
            className="cls-1"
            d="M424.72,94.18c0-1.22.61-1.83,1.73-1.83h9.15a1.68,1.68,0,0,1,1.82,1.73c.21,6.3,2.24,9.35,6,9.35,3.56,0,5.39-2.24,5.39-5.9,0-14-23.48-19.92-23.48-39,0-10.47,7-16.77,17.38-16.77,10.67,0,17.48,7.63,17.89,20.23,0,1.22-.61,1.83-1.73,1.83h-9.05A1.66,1.66,0,0,1,448,62c-.21-5.79-1.94-8.44-5.09-8.44s-5,1.63-5,5c0,12.51,23.69,18.5,23.69,39,0,11-7.42,17.79-18.4,17.79C432,115.32,425.13,107.29,424.72,94.18Z"
          />
          <path
            className="cls-1"
            d="M520.78,44.68v8.23c0,1.12-.61,1.73-1.83,1.73h-9.66v57.63c0,1.22-.6,1.83-1.72,1.83h-9.15c-1.12,0-1.73-.61-1.73-1.83V54.64h-9.76a1.55,1.55,0,0,1-1.72-1.73V44.68A1.55,1.55,0,0,1,486.93,43h32C520.17,43,520.78,43.56,520.78,44.68Z"
          />
          <path
            className="cls-1"
            d="M543.86,95.81V61.25c0-11.69,7.63-19.42,18.71-19.42s18.6,7.73,18.6,19.11V96.11c0,11.39-7.53,19.11-18.6,19.11S543.86,107.5,543.86,95.81ZM568.36,97V60c0-3.86-2.24-6.4-5.79-6.4s-5.9,2.54-5.9,6.7V96.72c0,4.17,2.34,6.61,5.9,6.61S568.36,100.89,568.36,97Z"
          />
          <path
            className="cls-1"
            d="M611.57,113.09V60.33c0-11,6.71-18.29,16.77-18.29s16.77,7.32,16.77,18.29v52.76a1,1,0,0,1-1,1H640a1,1,0,0,1-1-1V88.29H617.67v24.8a1,1,0,0,1-1,1h-4.07A1,1,0,0,1,611.57,113.09ZM639,82.59V60.33c0-7.93-3.86-12.6-10.67-12.6s-10.67,4.67-10.67,12.6V82.59Z"
          />
          <path
            className="cls-1"
            d="M675.41,95.81a1,1,0,0,1,1-1h4.07a1,1,0,0,1,1,1c.3,8.94,4.06,13.62,11,13.62,7.12,0,10.78-3.66,10.78-10.68,0-17.38-27.34-21.24-27.34-41.06,0-9.45,6.5-15.75,16.36-15.75,9.25,0,15.65,7.52,16.16,18.8a1,1,0,0,1-1,1h-4.07a1,1,0,0,1-1-1c-.4-8.94-4-13.11-10.06-13.11-6.71,0-10.26,3.45-10.26,10.06,0,16.36,27.34,20.13,27.34,41.06,0,9.86-6.71,16.37-16.88,16.37C682.52,115.12,675.71,107.39,675.41,95.81Z"
          />
          <path
            className="cls-1"
            d="M737,95.81a1,1,0,0,1,1-1h4.06a1,1,0,0,1,1,1c.3,8.94,4.07,13.62,11,13.62,7.11,0,10.77-3.66,10.77-10.68,0-17.38-27.34-21.24-27.34-41.06,0-9.45,6.5-15.75,16.36-15.75,9.25,0,15.66,7.52,16.17,18.8a1,1,0,0,1-1,1H765a1,1,0,0,1-1-1c-.41-8.94-4-13.11-10.07-13.11-6.7,0-10.26,3.45-10.26,10.06,0,16.36,27.34,20.13,27.34,41.06,0,9.86-6.71,16.37-16.87,16.37C744.13,115.12,737.32,107.39,737,95.81Z"
          />
          <path
            className="cls-1"
            d="M803.09,43h4.06a1,1,0,0,1,1,1v69.12a1,1,0,0,1-1,1h-4.06a1,1,0,0,1-1-1V44A1,1,0,0,1,803.09,43Z"
          />
          <path
            className="cls-1"
            d="M839.28,95.81a1,1,0,0,1,1-1h4.07a1,1,0,0,1,1,1c.31,8.94,4.07,13.62,11,13.62,7.12,0,10.78-3.66,10.78-10.68,0-17.38-27.35-21.24-27.35-41.06,0-9.45,6.51-15.75,16.37-15.75,9.25,0,15.65,7.52,16.16,18.8a1,1,0,0,1-1,1h-4.06a1,1,0,0,1-1-1c-.4-8.94-4-13.11-10.06-13.11-6.71,0-10.27,3.45-10.27,10.06,0,16.36,27.35,20.13,27.35,41.06,0,9.86-6.71,16.37-16.88,16.37C846.39,115.12,839.58,107.39,839.28,95.81Z"
          />
          <path
            className="cls-1"
            d="M930.67,44v3.66a1,1,0,0,1-1,1h-12.2v64.45a1,1,0,0,1-1,1h-4.06a1,1,0,0,1-1-1V48.64h-12.2a1,1,0,0,1-1-1V44a1,1,0,0,1,1-1h30.5A1,1,0,0,1,930.67,44Z"
          />
          <path
            className="cls-1"
            d="M958.83,113.09V60.33c0-11,6.7-18.29,16.77-18.29s16.77,7.32,16.77,18.29v52.76a1,1,0,0,1-1,1h-4.06a1,1,0,0,1-1-1V88.29H964.92v24.8a1,1,0,0,1-1,1h-4.07A1,1,0,0,1,958.83,113.09Zm27.44-30.5V60.33c0-7.93-3.86-12.6-10.67-12.6s-10.68,4.67-10.68,12.6V82.59Z"
          />
          <path
            className="cls-1"
            d="M1025.31,113.09V60.23c0-10.67,6.6-18.29,16.77-18.29s16.77,7.62,16.77,18.29v52.86a1,1,0,0,1-1,1h-4.07a1,1,0,0,1-1-1V60.23c0-7.52-4.17-12.6-10.67-12.6s-10.67,5.08-10.67,12.6v52.86a1,1,0,0,1-1,1h-4.06A1,1,0,0,1,1025.31,113.09Z"
          />
          <path
            className="cls-1"
            d="M1117,44v3.66a1,1,0,0,1-1,1h-12.2v64.45a1,1,0,0,1-1,1h-4.06a1,1,0,0,1-1-1V48.64h-12.2a1,1,0,0,1-1-1V44a1,1,0,0,1,1-1H1116A1,1,0,0,1,1117,44Z"
          />
        </g>
      </g>
    </StyledSvgIcon>
  );
};
export default PrestoLogoHorizontalSvg;
