// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetChecklist } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import ChecklistTable from './ChecklistTable';
import ShowChecklistBasicInfoCard from '../shared/ShowChecklistBasicInfoCard';

// Component Definition
const ChecklistsShow: FC = () => {
  const { id } = useParamsWithId();

  const checklistsParams = useSelector(tableQueryParams('checklists'));

  const {
    data,
    error,
    loading,
  } = useGetChecklist(id!);

  return (
    <Page
      backButtonProps={{
        label: 'All Checklists',
        path: `/${PATHS.CHECKLISTS}${checklistsParams}`,
      }}
      error={error}
      isLoading={loading}
    >
      <Box marginBottom={2}>
        <ShowChecklistBasicInfoCard checklistData={data?.checklist} />
      </Box>

      {data ? (
        <ChecklistTable
          checklistData={data.checklist}
          loading={loading}
        />
      ) : null}
    </Page>
  );
};

export default ChecklistsShow;
