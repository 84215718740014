// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import SignupCard from './SignupCard';

// Local Typings
interface Props {
  isChooseRoleView?: boolean;
}

// Component Definition
const StudentParentSignUpCards: FC<Props> = ({ isChooseRoleView }) => {
  const theme = useTheme();
  const search = useParsedSearch();
  const navigate = useNavigate();

  const TRANSITION_DURATION = theme.transitions.duration.standard;

  // Clicking on the CTA will send the user to a search-params-driven url
  const handleClickCreateUser = useCallback((
    role: DB.User.OnboardingRole,
    email?: string,
  ) => {
    navigate(`/${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}?onboarding_role=${role}${email ? `&email=${email}` : ''}`);
  }, [navigate]);

  return (
    <>
      {/* PARENT */}
      <SignupCard
        buttonText="Set up Profile"
        isChooseRoleView={isChooseRoleView}
        listItem1={`You have a child in an organization using ${APP_NAME}`}
        listItem2={(
          <>
            Your student&apos;s director sent a Welcome Email with a{' '}
            <strong>Member Code</strong>
          </>
        )}
        listItem3={(
          <>
            OR the director asked you to join with an{' '}
            <strong>Organization Code</strong>
          </>
        )}
        onClickButton={() => handleClickCreateUser('parent', search.email)}
        subheader={`Join a ${APP_NAME} organization`}
        title="Parent"
        transitionTimeout={TRANSITION_DURATION * 3}
      />

      {/* STUDENT */}
      <SignupCard
        buttonText="Set up Profile"
        isChooseRoleView={isChooseRoleView}
        listItem1={`You're a member of an organization using ${APP_NAME}`}
        listItem2={(
          <>
            Your director sent a Welcome Email with a{' '}
            <strong>Member Code</strong>
          </>
        )}
        listItem3={(
          <>
            OR your director asked you to join with an{' '}
            <strong>Organization Code</strong>
          </>
        )}
        onClickButton={() => handleClickCreateUser('student', search.email)}
        subheader={`Join a ${APP_NAME} organization`}
        title="Student"
        transitionTimeout={TRANSITION_DURATION * 4}
      />
    </>
  );
};

export default StudentParentSignUpCards;
