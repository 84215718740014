// External Dependencies
import {
  FinancialPaymentsForTransactionIdResponseItem,
} from '@presto-assistant/api_types/api/v1/financialTransaction';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  currencyFromCentsColumn,
} from 'utils/lib/tableColumns';
import { formatParamDate } from 'utils';

// Local Variables
export const useColumns = (
  extraColumns: DataGridColDef<FinancialPaymentsForTransactionIdResponseItem>[],
) => {
  return useMemo(() => {
    const columns: DataGridColDef<FinancialPaymentsForTransactionIdResponseItem>[] = [
      currencyFromCentsColumn({
        field: 'amountInCents',
        headerName: 'Payment Amount',
        minWidth: 180,
      }),
      {
        field: 'financialItemLabel',
        headerName: 'Item',
        minWidth: 160,
      },
      currencyFromCentsColumn({
        field: 'financialItemPriceInCents',
        headerName: 'Item Price',
        minWidth: 110,
      }),
      {
        field: 'assignedMemberFirstName',
        headerName: 'Assigned to',
        minWidth: 160,
        valueGetter: (params) => {
          return `${params.row.assignedMemberFirstName} ${params.row.assignedMemberLastName}`;
        },
      },
      {
        field: 'refundedAt',
        headerName: 'Date Refunded',
        minWidth: 140,
        valueGetter: (params) => {
          return formatParamDate(params.row.refundedAt);
        },

      },
      {
        field: 'id',
        headerName: 'Financial Payment ID',
        minWidth: 180,
      },
      ...extraColumns,
    ];

    return columns;
  }, [extraColumns]);
};
