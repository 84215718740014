// External Dependencies
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PaymentsIcon from '@mui/icons-material/Payments';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { isStudent as isStudentSelector } from 'state/self/selectors';
import { open } from 'state/ui/paymentAlertDialog/actions';
import { useCanMakePayments } from 'hooks/useCanMakePayments';
import { useGetMyFinancialFeesOverview } from 'gql/queries';
import DialogPaymentAlert from 'components/shared/DialogPaymentAlert';
import MyFinancialStatementButton from 'components/shared/MyFinancialStatementButton';

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '2.25rem',
});

// Component Definition
const MyFinancialOverviewCard = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isStudent = useSelector(isStudentSelector);

  const {
    data,
    loading: areFeesLoading,
  } = useGetMyFinancialFeesOverview();

  const totalAmountDue = useMemo(
    () => {
      if (data) {
        return (
          data.myFinancialFeesOverview.balanceDueInCentsThisYear
          + data.myFinancialFeesOverview.balanceDueInCentsPreviousYears
        );
      }
      return 0;
    },
    [data],
  );

  const canMakePayments = useCanMakePayments();

  const handleClickMakeAPayment = useCallback(() => {
    if (isStudent) {
      return dispatch(open(false));
    }
    return navigate(`/${PATHS.STUDENT_PAYMENTS_NEW}`);
  }, [dispatch, isStudent, navigate]);

  return (
    <>
      <DashboardCard
        content={(
          <>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              marginBottom={2}
            >
              <Typography>
                Total outstanding fees
              </Typography>

              <Typography>
                {areFeesLoading
                  ? (
                    <Box
                      component="span"
                      mt={1}
                    >
                      <CircularProgress />
                    </Box>
                  )
                  : (
                    <StyledStrong>
                      {displayPriceStringFromDollarAmount(
                        convertCentsToDollars(
                          totalAmountDue,
                        ),
                      )}
                    </StyledStrong>
                  )}
              </Typography>
            </Box>

            {!areFeesLoading
              && Boolean(data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears)
              && (
                <ShowPageDataDisplay
                  align="center"
                  label="Previous Years"
                  type="currency"
                  value={data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears}
                />
              )}

            <Box
              alignItems="center"
              display="flex"
              flexWrap="wrap"
              gap={2}
              justifyContent="center"
            >
              <MyFinancialStatementButton />

              {canMakePayments && (
                <Button
                  color="primary"
                  disabled={areFeesLoading}
                  onClick={handleClickMakeAPayment}
                  size="small"
                  startIcon={<PaymentsIcon />}
                  variant="outlined"
                >
                  Make a payment
                </Button>
              )}
            </Box>
          </>
        )}
        horizontallyCenterContent
        title="Financial Overview"
      />
      <DialogPaymentAlert />
    </>
  );
};

export default MyFinancialOverviewCard;
