// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  PRIVACY_POLICY_HREF,
  TERMS_OF_SERVICE_HREF,
} from 'utils/constants';
import { StyledLink } from 'components/shared';

// Local Typings
interface Props {
  withCoppa?: boolean;
}

// Component Definition
const PrivacyAndTermsLinks: FC<Props> = ({
  withCoppa = false,
}) => (
  <>
    <StyledLink
      href={`${PRIVACY_POLICY_HREF}${withCoppa ? '#coppa-compliance' : ''}`}
      openInNewTab
    >
      Privacy Policy
    </StyledLink>
    {' '}|{' '}
    <StyledLink
      href={TERMS_OF_SERVICE_HREF}
      openInNewTab
    >
      Terms of Service
    </StyledLink>
  </>
);

export default PrivacyAndTermsLinks;
