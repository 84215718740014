// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { GET_SELF, useGetMyFinancialFeesOverview, useGetOrganization } from 'gql/queries';
import { hasFeatureFlag } from 'state/self/selectors';
import { useGetFinancialPaymentProviderAccounts } from 'utils/api/financialPaymentProviderAccount';
import client from 'gql/client';

export const useHasABalance = (): boolean => {
  const {
    data,
  } = useGetMyFinancialFeesOverview();

  const hasABalance = Boolean(data?.myFinancialFeesOverview.balanceDueInCentsThisYear
    || data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears);

  return Boolean(hasABalance);
};

/*
This hook is rendered in useGetOrganizationUserRoutes
We should not use hooks that make graphql requests
*/
export const useIsOnlinePaymentsFeatureAvailable = (): {
  areOnlinePaymentsEnabled: boolean;
  hasPaymentProcessor: boolean;
} => {
  const hasOnlinePaymentFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  // We want this to only pull from Apollo cache
  const selfResult = client.readQuery({ query: GET_SELF });

  const self: GQL.ISelf | null = selfResult?.self;

  const organizationId = self?.currentOrgId;

  const {
    data: orgData,
  } = useGetOrganization({
    // eslint-disable-next-line max-len
    skip: !organizationId, // do not remove, due to rendering this hook in useGetOrganizationUserRoutes
  });
  const {
    data: financialPaymentProviderAccounts,
  } = useGetFinancialPaymentProviderAccounts();

  const multiplePaymentProvidersFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const organization = orgData?.organization;

  const district = organization?.district;

  const areOnlinePaymentsEnabledForDistrict = district?.areOnlinePaymentsEnabled;

  const canSetUpOnlinePayments = Boolean(hasOnlinePaymentFeatureFlag
    && areOnlinePaymentsEnabledForDistrict);

  const hasVancoOrStripeId = Boolean(organization?.hasStripeId || organization?.hasVancoPcct);

  const areOnlinePaymentsEnabled = canSetUpOnlinePayments && (
    multiplePaymentProvidersFeatureFlag
      ? Boolean(financialPaymentProviderAccounts?.data?.data.length)
      : hasVancoOrStripeId
  );

  const hasPaymentProcessor = Boolean(organization?.hasAccountWithOnlinePayments);

  return {
    areOnlinePaymentsEnabled,
    hasPaymentProcessor,
  };
};

export const useCanMakePayments = (): boolean => {
  const hasABalance = useHasABalance();

  const {
    hasPaymentProcessor,
  } = useIsOnlinePaymentsFeatureAvailable();
  const isOnlinePaymentsFeatureAvailable = hasPaymentProcessor;

  // Temporary feature flag to hide payment button until Vanco is completely rolled out
  const canMakePayments = isOnlinePaymentsFeatureAvailable && hasABalance;

  return Boolean(canMakePayments && process.env.REACT_APP_SHOW_PAYMENT_BUTTON === 'true');
};
