import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton,
  TextField, Typography,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Flex, SaveButton } from 'components/shared';
import { getNextWeekdayAfterDate } from 'utils';
import { useIsOpen } from 'hooks/useIsOpen';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  isOpen: boolean;
  isSubmitting: boolean;
  onClickSubmit: () => void;
  onClose: () => void;
  sendAt: number | null;
  setSendAt: (value: number | null) => void;
  timezoneOffset: number;
}

interface PresetTime {
  label: string;
  value: number;
}

const ScheduledSendDialog: FC<Props> = ({
  isOpen,
  isSubmitting,
  onClickSubmit,
  onClose,
  sendAt,
  setSendAt,
  timezoneOffset,
}) => {
  const {
    handleClose: setIsUsingCustomTimeFalse,
    isOpen: isUsingCustomTime,
    toggleIsOpen: toggleIsUsingCustomTime,
  } = useIsOpen();
  const [selectedPresetTime, setSelectedPresetTime] = useState<PresetTime | null>(null);

  useEffect(() => {
    if (isOpen) {
      setIsUsingCustomTimeFalse();
      setSelectedPresetTime(null);
    } else {
      setSendAt(null);
    }
  }, [isOpen, setIsUsingCustomTimeFalse, setSendAt]);

  const presetTimes = useMemo<PresetTime[]>(() => {
    const todayAt8am = new Date().setHours(8, 0, 0, 0);
    const todayAtNoon = new Date().setHours(12, 0, 0, 0);
    const todayAt5pm = new Date().setHours(17, 0, 0, 0);

    const nextWeekDay = getNextWeekdayAfterDate(new Date());

    const nextWeekDayDate = nextWeekDay.getDate();
    const tomorrowDate = new Date(new Date().setHours(24)).getDate();

    const nextWeekdayAt8am = nextWeekDay.setHours(8, 0, 0, 0);

    const nextWeekDayText = nextWeekDayDate === tomorrowDate ? 'Tomorrow' : 'Monday';

    const isItTheWeekend = new Date().getDay() === 6 || new Date().getDay() === 0;

    const timeOptions: PresetTime[] = isItTheWeekend ? [
      {
        label: `${nextWeekDayText} at 8am`,
        value: nextWeekdayAt8am,
      },
    ] : [
      {
        label: 'Today at 8am',
        value: todayAt8am,
      },
      {
        label: 'Today at noon',
        value: todayAtNoon,
      },
      {
        label: 'Today at 5pm',
        value: todayAt5pm,
      },
      {
        label: `${nextWeekDayText} at 8am`,
        value: nextWeekdayAt8am,
      },
    ];

    // We don't want to show times that are in the past or in the next 10 minutes
    // The API does not allow scheduling emails for times in the past or in the next 5 minutes
    const tenMinutesFromNow = new Date().getTime() + 10 * 60 * 1000;

    return timeOptions.filter((time) => time.value > tenMinutesFromNow).slice(0, 3);
  }, []);

  const handleClickEdit = useCallback(() => {
    setSelectedPresetTime(null);
    setSendAt(null);
  }, [setSelectedPresetTime, setSendAt]);

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: 400,
        },
      }}
    >
      <DialogTitle>
        Schedule send
      </DialogTitle>

      <DialogContent>
        {!selectedPresetTime && (
          <DialogContentText>
            Schedule an email to be sent at a later date and time.
          </DialogContentText>
        )}

        {selectedPresetTime && (
          <>
            <Typography gutterBottom>
              Send the email at the following time?
            </Typography>

            <Flex
              gap={1}
              marginY={2}
            >
              <Typography
                align="center"
                variant="h4"
              >
                {selectedPresetTime.label}
              </Typography>

              <IconButton
                onClick={handleClickEdit}
              >
                <EditIcon />
              </IconButton>
            </Flex>
          </>
        )}

        {!isUsingCustomTime && !selectedPresetTime && (
          <Flex
            flexDirection="column"
            gap={2}
            marginTop={2}
            width="100%"
          >
            {presetTimes.map((presetTime) => (
              <Button
                fullWidth
                key={presetTime.label}
                onClick={() => {
                  setSelectedPresetTime(presetTime);
                  setSendAt(presetTime.value - timezoneOffset);
                }}
                variant="outlined"
              >
                {presetTime.label}
              </Button>
            ))}

            <Button
              fullWidth
              onClick={toggleIsUsingCustomTime}
              variant="outlined"
            >
              Custom time
            </Button>
          </Flex>
        )}

        {isUsingCustomTime && (
          <TextField
            label="Scheduled send time"
            type="datetime-local"
            sx={{ marginTop: 2, width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              const dateAsNumber = new Date(e.target.value).getTime();

              setSendAt(dateAsNumber - timezoneOffset);
            }}
            value={sendAt ? new Date(sendAt).toISOString().slice(0, 16) : ''}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>

        <SaveButton
          disabled={!sendAt}
          isSaving={isSubmitting}
          onClick={onClickSubmit}
        >
          Schedule Send
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduledSendDialog;
