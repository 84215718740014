// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const addPreviewData = createActionCreator(
  'ADD_UNIFORM_FILE_UPLOAD_DIALOG_PREVIEW_DATA',
  (resolve) => (csvUploadPreview: GQL.ICSVUploadPreview) =>
    resolve(csvUploadPreview),
);

export const close = createActionCreator(
  'CLOSE_UNIFORM_FILE_UPLOAD_DIALOG',
);

export const open = createActionCreator('OPEN_UNIFORM_FILE_UPLOAD_DIALOG');

export const reset = createActionCreator('RESET_UNIFORM_FILE_UPLOAD_DIALOG');

export const updateStep = createActionCreator(
  'UPDATE_UNIFORM_FILE_UPLOAD_DIALOG_STEP',
  (resolve) => (step: number) => resolve(step),
);
