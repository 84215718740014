// External Dependencies
import { DistrictEmailsRequest } from '@presto-assistant/api_types/api/v1/communication';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useGetDistrictEmailIndex = (queryParams: DistrictEmailsRequest['query']) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.communication.districtIndex({
        query: queryParams,
      });
    },
    queryKey: ['districtEmail', queryParams],
  });
};
