// External Dependencies
import DeleteIcon from '@mui/icons-material/Delete';

// Internal Dependencies
import {
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { getFullName } from 'utils';

// Local Typings
interface Props {
  deletedAt: GQL.IInventoryItem['deletedAt'];
  deletedBy: GQL.IInventoryItem['deletedBy'];
  deletedNote: GQL.IInventoryItem['deletedNote'];
}

// Component Definition
const ShowInventoryDeletedData = ({
  deletedAt,
  deletedBy,
  deletedNote,
}: Props): JSX.Element => {
  return (
    <ShowCard
      canEdit={false}
      icon={DeleteIcon}
      title="Deleted Info"
    >
      <ShowPageDataDisplay
        label="Deleted By"
        value={`${getFullName({ firstName: deletedBy?.firstName, lastName: deletedBy?.lastName, middleName: deletedBy?.middleName })} (${deletedBy?.email})`}
      />
      <ShowPageDataDisplay
        label="Deleted Date"
        type="datetime"
        value={deletedAt}
      />
      <ShowPageDataDisplay
        label="Deleted Note"
        value={deletedNote}
      />
    </ShowCard>
  );
};

export default ShowInventoryDeletedData;
