// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  Container, History, Page, ShowCard, ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag, hasPermission } from 'state/self/selectors';
import { useGetFinancialAccount } from 'gql/queries';
import { useHasEnabledOnlinePaymentsForOrg } from 'hooks/useHasEnabledOnlinePaymentsForOrg';
import { useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useGetFinancialPaymentProviderAccounts } from 'utils/api/financialPaymentProviderAccount';
import OnlinePaymentsAlert from '../shared/OnlinePaymentsAlert';

// Component Definition
const AccountsShowPage: FC = () => {
  const { id } = useParamsWithId();

  const {
    data,
    error,
    loading,
  } = useGetFinancialAccount(id!);

  const {
    data: financialPaymentProviderAccounts,
    isLoading: isLoadingFinancialPaymentProviderAccounts,
  } = useGetFinancialPaymentProviderAccounts();

  const areOnlinePaymentsEnabledForOrg = useHasEnabledOnlinePaymentsForOrg();

  const {
    areOnlinePaymentsEnabled,
    hasPaymentProcessor,
  } = useIsOnlinePaymentsFeatureAvailable();
  const isOnlinePaymentsFeatureAvailable = areOnlinePaymentsEnabled && hasPaymentProcessor;

  const canEditAccounts = useSelector(hasPermission('financialAccounts', 'edit'));
  const hasMultiplePaymentProviderFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );

  const editPath = canEditAccounts
    ? `/${PATHS.FINANCIAL_ACCOUNTS}/${id}/edit` : undefined;

  const financialPaymentProviderAccount = financialPaymentProviderAccounts?.data.data.find(
    (account) => account.id === data?.financialAccount.financialPaymentProviderAccountId,
  );

  return (
    <Page
      backButtonProps={{
        label: 'Financial Overview',
        path: `/${PATHS.FINANCIAL_OVERVIEW}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={!data || loading || isLoadingFinancialPaymentProviderAccounts}
    >
      <Container>
        <ShowCard
          icon={AccountBalance}
          isLoading={loading}
          title="Financial Account"
        >
          <ShowPageDataDisplay
            label="Name"
            value={data?.financialAccount.label}
          />

          {isOnlinePaymentsFeatureAvailable && !hasMultiplePaymentProviderFeatureFlag && (
            <ShowPageDataDisplay
              label="Online Payments Allowed"
              type="boolean"
              value={data?.financialAccount.onlinePaymentsEnabled}
            />
          )}

          {isOnlinePaymentsFeatureAvailable && hasMultiplePaymentProviderFeatureFlag && (
            <ShowPageDataDisplay
              label="Payment Provider Account"
              value={financialPaymentProviderAccount?.label}
            />
          )}

          <ShowPageDataDisplay
            label="Can Apply Fundraiser Credits"
            type="boolean"
            value={data?.financialAccount.canApplyFundraiserCredits}
          />

          {!areOnlinePaymentsEnabledForOrg
            && (
              <Box marginTop={2}>
                <OnlinePaymentsAlert />
              </Box>
            )}
        </ShowCard>

        {data?.financialAccount && (
          <History
            id={data.financialAccount.id}
            resource="financialAccounts"
          />
        )}
      </Container>
    </Page>
  );
};

export default AccountsShowPage;
