// External Dependencies
import { Button, ButtonProps } from '@mui/material';
import { FC, useCallback } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadOutlined';

// Internal Dependencies
import { useDownloadBalanceSheet } from 'gql/mutations';
import { useGetOrganization } from 'gql/queries';

type Props = Omit<Partial<ButtonProps>, 'onClick'>;

// Component Definition
const DownloadBalanceSheetButton: FC<Props> = (props) => {
  const { data: orgData } = useGetOrganization();

  const [
    downloadBalanceSheet,
    {
      loading: isDownloadingBalanceSheet,
    },
  ] = useDownloadBalanceSheet();

  const handleClickDownloadBalanceSheet = useCallback(() => {
    const schoolYearEnding = orgData?.organization.currentSchoolYearEnding;

    if (schoolYearEnding) {
      downloadBalanceSheet({
        variables: {
          input: {
            schoolYearEnding,
          },
        },
      });
    }
  }, [orgData?.organization.currentSchoolYearEnding]);

  return (
    <Button
      color="primary"
      disabled={isDownloadingBalanceSheet}
      {...props}
      onClick={handleClickDownloadBalanceSheet}
      startIcon={<CloudDownloadIcon />}
    >
      Balance sheet
    </Button>
  );
};

export default DownloadBalanceSheetButton;
