// External Dependencies
import { PATHS } from 'utils/constants/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import EndOfYearWrapper from './EndOfYearWrapper';

// Component Definition
const EndOfYear = (): JSX.Element => {
  const navigate = useNavigate();

  const { data, loading } = useGetOrganization();

  useEffect(() => {
    if (!loading && data?.organization
      && !data?.organization?.canBeginEndOfYearProcess) {
      navigate(`/${PATHS.DASHBOARD}`);
    }
  }, [data, loading, navigate]);

  return (
    <>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          mt={4}
        >
          <CircularProgress />
        </Box>
      )}

      {data?.organization?.canBeginEndOfYearProcess && (
        <EndOfYearWrapper
          maxCompletedStep={data?.organization?.maxCompletedEndOfYearStep ?? 0}
        />
      )}
    </>
  );
};

export default EndOfYear;
