// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import DynamicFieldsTable from './DynamicFieldsTable';

// Component Definition
const AdminDynamicFields = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        Dynamic Fields
      </Subtitle>

      <EnhancedCard>
        <DynamicFieldsTable />
      </EnhancedCard>
    </>
  );
};

export default AdminDynamicFields;
