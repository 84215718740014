// External Dependencies
import styled from 'styled-components';

export const StyledDashboardDiv = styled.div(({ theme }) => ({
  display: 'grid',
  gridAutoRows: 'minmax(320px, auto)',
  gridGap: theme.spacing(2),
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  marginTop: theme.spacing(2),
}));
