// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ContextData } from 'types/context';
import { Notification } from 'components/shared';
import {
  hasNotifications as hasNotificationsSelector,
  notificationsItems as notificationsItemsSelector,
} from 'state/notifications/selectors';

// Component Definition
const NotificationHandler = () => {
  const hasNotifications = useSelector(hasNotificationsSelector);
  const notificationsItems = useSelector(notificationsItemsSelector);

  if (!hasNotifications) {
    return null;
  }

  return notificationsItems.map((notification: ContextData.INotificationDetail) => (
    <Notification
      key={`${notification.id}`}
      notification={notification}
    />
  ));
};

export default NotificationHandler;
