// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Local Dependencies
import {
  addNotification,
  clearAllNotifications,
  removeNotification,
} from './actions';
import {
  logoutCurrentUser,
} from '../self/actions';

// Local Typings
interface State {
  [items: string]: Notification[];
}

interface Notification {
  id: number;
  label: string;
}

interface Action {
  payload: Notification;
  type: string;
}

export interface NotificationsState {
  data: State;
}

// Reducers
const NOTIFICATIONS_INITIAL_STATE: State = { items: [] };
const data = createReducer(NOTIFICATIONS_INITIAL_STATE, (handleAction) => [
  handleAction(
    addNotification,
    (state: State, { payload }: Action) => {
      if (payload) {
        return {
          items: [...state.items, payload],
        };
      }
      return state;
    },
  ),
  handleAction(
    [clearAllNotifications, logoutCurrentUser],
    () => NOTIFICATIONS_INITIAL_STATE,
  ),
  handleAction(
    removeNotification,
    (state: State, { payload }): State => {
      if (state.items.length > 0 && payload) {
        // Find the provided notification id and remove from data state
        const updatedData: Notification[] = state.items.filter(
          (alert: Notification) => alert.id !== payload,
        );

        return {
          items: updatedData,
        };
      }

      return state;
    },
  ),
]);

export default combineReducers<NotificationsState>({ data });
