// External Dependencies
import {
  FC, useCallback, useState,
} from 'react';
import { Form, FormikErrors, FormikTouched } from 'formik';
import { UserRoles } from '@presto-assistant/api_types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CartIcon from 'mdi-material-ui/Cart';
import InputAdornment from '@mui/material/InputAdornment';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  ConfirmationDialog,
  Container,
  CustomInput,
  EnhancedAlert,
  FormRouteActions,
  MoneyInput,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SimpleUser } from 'gql/queries';
import { UserTypeAhead } from 'components/shared/TypeAheads';
import { convertDollarsToCents, displayPriceStringFromDollarAmount } from 'utils';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
export type FeeFormValues = GQL.ICreateUncategorizedFinancialFeeInput;

interface Props {
  errors: FormikErrors<FeeFormValues>;
  formikValues: FeeFormValues;
  isSubmitting: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setTouched: (
    touched: FormikTouched<GQL.ICreateUncategorizedFinancialFeeInput>,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<FeeFormValues>;
  validateForm: (values: FeeFormValues) =>
    Promise<FormikErrors<GQL.ICreateUncategorizedFinancialFeeInput>>;
}

export interface CustomSetter {
  fieldName: string;
  fieldValue: any;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

export const normalizePayload = (
  values: FeeFormValues,
): GQL.ICreateUncategorizedFinancialFeeInput => ({
  ...values,
  item: {
    ...values.item,
    financialAccountId: values.item.financialAccountId || null,
    priceInCents: convertDollarsToCents(values.item.priceInCents),
    schoolYearEnding: Number(values.item.schoolYearEnding),
  },
  userId: values.userId,
});

// Component Definition
const UncategorizedFeeFormFormikForm: FC<Props> = ({
  errors,
  formikValues,
  isSubmitting,
  onSubmit,
  setFieldValue,
  setTouched,
  touched,
  validateForm,
}) => {
  const navigate = useNavigate();

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const handleClearUsersWithExistingFees = useCallback(() => {
    setIsAlertDialogOpen(false);
  }, []);

  const schoolYearEnding = useGetOrganizationSchoolYear();

  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));

  const isFormTouched = Object.keys(touched).length > 0;

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_FEES}${financialFeesParams}`);
  }, [financialFeesParams, navigate]);

  const handleSubmit: Props['onSubmit'] = useCallback(async (e) => {
    e?.preventDefault();

    const result = await validateForm(
      normalizePayload(formikValues),
    ) as FormikTouched<GQL.ICreateUncategorizedFinancialFeeInput>;

    const errorKeys = Object.keys(result);

    if (errorKeys.length) {
      setTouched(result, true);
    } else {
      setIsAlertDialogOpen(true);
    }
  }, [formikValues, setTouched, validateForm]);

  const handleSelectUser = useCallback((user: SimpleUser | null) => {
    setFieldValue('userId', user?.id ?? '');
  }, [setFieldValue]);

  return (
    <>
      <Container maxWidth={CONTAINER_WIDTH}>
        <Form onSubmit={handleSubmit}>
          <Box mb={2}>
            <ShowCard
              icon={CartIcon}
              title="New Financial Fee"
            >
              <EnhancedAlert>
                You are creating an uncategorized fee for an individual student.
                {' '}
                (e.g. a student spontaneously purchasing clarinet reeds)
              </EnhancedAlert>

              <Box
                display="grid"
                gap={2}
                gridTemplateColumns="repeat(auto-fit, minmax(220px, 1fr))"
                marginTop={2}
                rowGap={0.5}
              >
                <SchoolYearSelect
                  fullWidth={false}
                  name="item.schoolYearEnding"
                  required
                  variant="filled"
                />

                <CustomInput
                  InputProps={{
                    inputComponent: MoneyInput as any,
                    startAdornment,
                  }}
                  fullWidth={false}
                  label="Amount"
                  name="item.priceInCents"
                />

                <Box gridColumn="1 / -1">
                  <FinancialAccountSelect name="item.financialAccountId" />
                </Box>

                <Box gridColumn="1 / -1">
                  <CustomInput
                    label="Fee Description"
                    name="item.label"
                  />
                </Box>

                <Box
                  gridColumn="1 / -1"
                  marginY={1}
                >
                  <UserTypeAhead
                    hasError={Boolean(errors.userId && touched.userId)}
                    helperText={errors.userId}
                    onSelectUser={handleSelectUser}
                    roleId={UserRoles.Student.toString()}
                  />
                </Box>
              </Box>
            </ShowCard>
          </Box>

          <FormRouteActions
            context="Fee"
            isFormTouched={isFormTouched}
            isSubmitting={isSubmitting}
            onPressCancelOrBackButton={handlePressCancelOrBackButton}
          />
        </Form>
      </Container>

      <ConfirmationDialog
        confirmButtonAction={onSubmit}
        confirmButtonText="Yes, Add fee"
        declineButtonAction={handleClearUsersWithExistingFees}
        description={`Assign a fee of ${displayPriceStringFromDollarAmount(formikValues.item.priceInCents)} for ${formikValues.item.label}?`}
        handleClose={handleClearUsersWithExistingFees}
        isConfirmButtonDisabled={!formikValues.userId}
        isSubmitting={isSubmitting}
        open={isAlertDialogOpen}
        title="Ready to add fee"
      />
    </>
  );
};

export default UncategorizedFeeFormFormikForm;
