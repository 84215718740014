// External Dependencies
import { FC } from 'react';
import {
  InventoryConditions,
  InventoryQualities,
} from '@presto-assistant/api_types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  CustomSelect,
} from 'components/shared';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetDistrictOrganizations } from 'gql/queries';
import DistrictOrganizationSelect from 'components/shared/Selectors/DistrictOrganizationSelect';

// Local Typings
interface Props {
  excludeLoanToOrganizationId?: string;
  inputSize: 'small' | 'medium';
}

// Local Variables
const useStyles = makeStyles({
  input: {
    marginBottom: 16,
  },
});

const conditionData = mapEnum(InventoryConditions);
const qualityData = mapEnum(InventoryQualities);

// Component Definition
const InventoryStatusInfoFormFields: FC<Props> = ({
  excludeLoanToOrganizationId,
  inputSize,
}) => {
  const classes = useStyles();

  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  return (
    <Grid
      container
      direction="column"
    >
      <Box my={1}>
        <CustomSelect
          label="Condition"
          name="conditionId"
          options={conditionData}
        />
      </Box>
      <CustomInput
        label="Current Value"
        name="currentValue"
        rootStyles={classes.input}
        size={inputSize}
        type="number"
      />
      <CustomInput
        label="Accessories"
        name="accessories"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomSelect
        label="Quality"
        name="qualityId"
        options={qualityData}
      />
      <Box marginLeft={1}>
        <CustomCheckbox
          label="Is Missing?"
          name="isMissing"
        />
      </Box>
      <CustomInput
        label="Comments"
        multiline
        name="comments"
        rootStyles={classes.input}
        rows={2}
        size={inputSize}
      />

      {organizations.length > 1 && (
        <DistrictOrganizationSelect
          allowUnassignedOption
          excludeOrganizationId={excludeLoanToOrganizationId}
          label="On Loan To"
          name="onLoanToOrganizationId"
          orgTypeId={null}
          unassignedOptionText="(Not on Loan)"
        />
      )}
    </Grid>
  );
};

export default InventoryStatusInfoFormFields;
