// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import EditAdultPermissions from '../../shared/EditAdultPermissions';

// Componeant Definition
const EditDirectorPermissions: FC = () => {
  const { id } = useParamsWithId();

  if (!id) {
    return null;
  }

  return (
    <EditAdultPermissions
      adultUserId={id}
      context="Director"
      pathToShowPage={`/${PATHS.DIRECTORS}/${id}`}
    />
  );
};

export default EditDirectorPermissions;
