// External Dependencies
import { CardContentProps } from '@mui/material/CardContent';
import { CardProps } from '@mui/material/Card';
import { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Local Dependencies
import EnhancedCard from './EnhancedCard';
import EnhancedCardContent from './EnhancedCardContent';
import Flex from './Flex';
import ShowCardHeader from './ShowCardHeader';

// Local Typings
export interface ShowCardProps extends CardProps {
  canEdit?: boolean;
  cardActions?: ReactNode;
  cardContentProps?: CardContentProps;
  editPath?: string;
  icon: FC;
  isLoading?: boolean;
  onClickEditIconButton?: () => void;
  readOnly?: boolean;
  subheader?: string;
  title: string;
}

// Component Definition
const ShowCard = ({
  canEdit = false,
  cardActions,
  cardContentProps = {},
  children,
  editPath,
  icon: Icon,
  isLoading,
  onClickEditIconButton,
  readOnly,
  title,
  subheader,
  ...cardProps
}: ShowCardProps): JSX.Element => {
  const theme = useTheme();

  return (
    <EnhancedCard {...cardProps}>
      <ShowCardHeader
        avatar={(
          <Avatar
            sx={{
              backgroundColor: theme.palette.showPage.people,
            }}
          >
            <Icon aria-label={title} />
          </Avatar>
        )}
        editPath={canEdit && readOnly ? editPath || `${window.location.pathname}/edit` : ''}
        onClickEditIconButton={onClickEditIconButton}
        subheader={subheader}
        title={title}
      />

      <EnhancedCardContent {...cardContentProps}>
        {isLoading
          ? (
            <Flex justifyContent="center">
              <CircularProgress />
            </Flex>
          )
          : children}
      </EnhancedCardContent>

      {cardActions && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
        >
          {cardActions}
        </Box>
      )}
    </EnhancedCard>
  );
};

export default ShowCard;
