// External Dependencies
import { Form, Formik } from 'formik';
import { claimMemberInputSchema } from '@presto-assistant/api_types/schemas/member';
import {
  useCallback, useState,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedAlert, SupportLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { useClaimMember } from 'gql/mutations';
import { useGetClaimMemberLookup } from 'gql/queries';

// Local Dependencies
import {
  ConfirmationDialog,
  CustomInput,
  EnhancedCard,
  SaveButton,
  ShowPageDataDisplay,
} from '..';
import { MemberInfoFormType } from '.';

// Local Typings
interface Props {
  isOnboarding: boolean;
  onResetFormType: () => void;
}

// Local Variables
const formType: MemberInfoFormType = 'claiming';

const supportArticleHref = 'join-as-student-or-parent-from-welcome-email';

const duplicateSubmissionErrorMessage = 'This combination has already been attempted.';

// Component Definition
const ClaimMemberForm = ({
  isOnboarding,
  onResetFormType,
}: Props): JSX.Element => {
  const [input, setInput] = useState<GQL.IClaimMemberInput | null>();
  const [submittedCombinations, setSubmittedCombinations] = useState<Set<string>>(new Set());
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const [
    // Used to submit the form
    lookupMember,
    {
      data: lookupMemberData,
      error,
      loading: isLookingUpMember,
    },
  ] = useGetClaimMemberLookup();

  const organizationLabel = lookupMemberData?.claimMemberLookup.organization?.label
    ?? lookupMemberData?.claimMemberLookup.district?.label
    ?? '';

  const handleNavigateToSuccess = useCallback(() => {
    const queryString = `organization=${organizationLabel}&form_type=${formType}`;
    const fullPath = `/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}?${queryString}`;

    // We need to clear a ton of state here when switching organizations. Let's just reload.
    window.location.href = fullPath;
  }, [organizationLabel]);

  const [
    // Used to submit the confirmation dialog
    claimMember,
    {
      loading: isClaiming,
    },
  ] = useClaimMember({
    onCompleted: () => {
      if (!isOnboarding) {
        handleNavigateToSuccess();
      }
    },
  });

  const handleSubmit = (values: GQL.IClaimMemberInput) => {
    setInput(values);
    const combination = `${values.claimCode}-${values.memberEmail}`;

    if (submittedCombinations.has(combination)) {
      return setIsDuplicate(true);
    }

    if (isDuplicate) {
      setIsDuplicate(false);
    }

    setSubmittedCombinations(new Set(submittedCombinations).add(combination));

    return lookupMember({
      variables: {
        input: values,
      },
    });
  };

  const handleConfirm = useCallback(() => {
    if (input) {
      claimMember({
        variables: {
          input,
        },
      });
    }
  }, [claimMember, input]);

  const handleDecline = useCallback(() => {
    setInput(null);
  }, []);

  return (
    <>
      <Formik<GQL.IClaimMemberInput>
        initialValues={{
          claimCode: '',
          memberEmail: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={claimMemberInputSchema}
      >
        {() => (
          <Form>
            <EnhancedCard sx={{ marginTop: 2, textAlign: 'left' }}>
              <CardContent>
                <Typography
                  component="h3"
                  sx={{
                    marginBottom: 1.5,
                  }}
                  variant="h6"
                >
                  Enter your Member Claim Code
                </Typography>

                <Typography sx={{ marginBottom: 1.5 }}>
                  You will be added as a member of the organization immediately.
                </Typography>

                <CustomInput
                  label="Member Code"
                  name="claimCode"
                />

                <CustomInput
                  label="Email"
                  name="memberEmail"
                />
              </CardContent>

              <Collapse in={Boolean(error?.message)}>
                <EnhancedAlert
                  severity="error"
                  sx={{ textAlign: 'left' }}
                  title={isDuplicate ? 'Duplicate Submission' : 'Error'}
                >
                  <Typography
                    component="div"
                    paragraph
                    variant="body2"
                  >
                    {isDuplicate ? duplicateSubmissionErrorMessage : error?.message}
                  </Typography>

                  <Typography variant="body2">
                    Need help?{' '}
                    <SupportLink
                      buttonText="Learn about joining an organization"
                      slug={supportArticleHref}
                    />
                  </Typography>
                </EnhancedAlert>
              </Collapse>
            </EnhancedCard>

            <Box
              display="flex"
              gap={2}
              justifyContent={isOnboarding ? 'center' : 'flex-end'}
              marginTop={3}
            >
              <Button
                color="primary"
                onClick={onResetFormType}
                variant="outlined"
              >
                Go Back
              </Button>

              <SaveButton
                isSaving={isLookingUpMember}
                type="submit"
              >
                Claim
              </SaveButton>
            </Box>
          </Form>
        )}
      </Formik>

      <ConfirmationDialog
        confirmButtonAction={handleConfirm}
        confirmButtonText="Yes, claim"
        declineButtonAction={handleDecline}
        description={(
          <>
            <ShowPageDataDisplay
              label={lookupMemberData?.claimMemberLookup.organization ? 'Organization' : 'District'}
              value={organizationLabel}
            />

            <ShowPageDataDisplay
              label="Name"
              value={lookupMemberData?.claimMemberLookup ? getFullName(lookupMemberData.claimMemberLookup) : ''}
            />

            <ShowPageDataDisplay
              label="Email"
              value={lookupMemberData?.claimMemberLookup.email}
            />

            <ShowPageDataDisplay
              label="Role"
              value={lookupMemberData?.claimMemberLookup.role.label}
            />
          </>
        )}
        handleClose={handleDecline}
        isSubmitting={isClaiming}
        open={!!input && !!lookupMemberData?.claimMemberLookup}
        title="Claim this member?"
        useCustomText
      />
    </>
  );
};

export default ClaimMemberForm;
