// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

// Local Typings
interface Props {
  outstandingFeesFromCurrentYearInCents: number;
  outstandingFeesFromPriorYearsInCents: number;
}

// Local Variables
const StyledStrong = styled.strong(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
}));

// Component Definition
const TotalOutstandingFeesCard: FC<Props> = ({
  outstandingFeesFromCurrentYearInCents,
  outstandingFeesFromPriorYearsInCents,
}) => (
  <DashboardCard
    content={(
      <>
        <StyledStrong>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(outstandingFeesFromCurrentYearInCents
              + outstandingFeesFromPriorYearsInCents),
          )}
        </StyledStrong>

        <ShowPageDataDisplay
          label="Current Year"
          type="currency"
          value={outstandingFeesFromCurrentYearInCents}
        />
      </>
    )}
    title="Total Outstanding Fees"
  />
);

export default TotalOutstandingFeesCard;
