// External Dependencies
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/HelpOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { EnhancedIconButton, Subtitle, UserAvatar } from 'components/shared';
import { formatDate, getFullName } from 'utils';
import { useGetMyChildrenUnderThirteen } from 'gql/queries/relationship-queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import ParentalConsentButton from './ParentalConsentButton';
import ParentalConsentInfoDialog from './ParentalConsentInfoDialog';

// Local Variables
const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '60%',
  },
});

// Component Definition
const ChildrenUnderThirteen = (): JSX.Element | null => {
  const classes = useStyles();
  const {
    isOpen: isDialogOpen,
    toggleIsOpen: toggleIsDialogOpen,
  } = useIsOpen();

  const { data } = useGetMyChildrenUnderThirteen();

  if (!data?.myChildrenUnderThirteen.length) {
    return null;
  }

  return (
    <>
      <Subtitle>
        <Box
          alignItems="center"
          component="span"
          display="flex"
        >
          Children Under 13 — Parental Authorization
          <EnhancedIconButton
            icon={<HelpIcon />}
            onClick={toggleIsDialogOpen}
            tooltip="Learn more about parental authorization"
          />
        </Box>
      </Subtitle>

      <List>
        {data.myChildrenUnderThirteen.map((child) => (
          <ListItem key={child.id}>
            <ListItemAvatar>
              <UserAvatar iconName="face" />
            </ListItemAvatar>

            <ListItemText
              primary={getFullName(child)}
              secondary={child.parentalConsent
                ? (
                  <>
                    Parental authorization <strong>granted</strong> by
                    {' '}{getFullName(child.parentalConsent.consentedBy)}
                    {' '}on{' '}
                    {formatDate(child.parentalConsent.consentedAt)}
                  </>
                )
                : (
                  <>
                    Parental authorization <strong>not granted</strong>
                  </>
                )}
              secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
            />

            <ListItemSecondaryAction>
              <ParentalConsentButton
                isChecked={Boolean(child.parentalConsent)}
                userId={child.id}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <ParentalConsentInfoDialog
        isOpen={isDialogOpen}
        onClose={toggleIsDialogOpen}
      />
    </>
  );
};

export default ChildrenUnderThirteen;
