// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToDeletedInventoryItems: FC = () => {
  const districtDeletedInventoryItemsParams = useSelector(tableQueryParams('districtDeletedInventoryItems'));

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View deleted inventory items
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_DELETED}${districtDeletedInventoryItemsParams}`}>
        Deleted Inventory Items
      </ForwardButton>
    </Box>
  );
};

export default LinkToDeletedInventoryItems;
