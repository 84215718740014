// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetDistrict } from 'gql/queries';
import { useUpdateDistrict } from 'gql/mutations';

// Local Dependencies
import AdminEditDistrictInfoForm from './AdminEditDistrictInfoForm';

// Local Variables
const pathToAdminSettings = `/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}`;

// Component Definition
const AdminEditDistrictInfo = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    data,
    error,
    loading,
  } = useGetDistrict();

  const navigateToSettings = useCallback(() => {
    navigate(pathToAdminSettings);
  }, [navigate]);

  const [handleUpdateDistrict] = useUpdateDistrict(
    {
      awaitRefetchQueries: true,
      onCompleted: navigateToSettings,
    },
  );

  const handleSubmit = useCallback(async (values: GQL.IUpdateDistrictInput) => {
    await handleUpdateDistrict({
      variables: {
        input: values,
      },
    });
  }, [handleUpdateDistrict]);

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: pathToAdminSettings,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <AdminEditDistrictInfoForm
        districtInfo={data?.district}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default AdminEditDistrictInfo;
