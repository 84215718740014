// External Dependencies
import { ImportResources } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import { close } from 'state/ui/financialPaymentFileUploadDialog/actions';
import {
  isOpen as isFinancialPaymentFileUploadDialogOpenSelector,
} from 'state/ui/financialPaymentFileUploadDialog/selectors';
import CSVImporter from 'components/CSVImporter';

// Component Definition
const DialogFinancialPaymentFileUpload = (): JSX.Element => {
  const dispatch = useDispatch();

  const isFinancialPaymentFileUploadDialogOpen = useSelector(
    isFinancialPaymentFileUploadDialogOpenSelector,
  );

  const handleCloseDialogPaymentFileUpload = () => {
    dispatch(close());
  };

  return (
    <CSVImporter
      context="payment"
      csvFileInfoResource="financialPayments"
      dialogTitle="Add Payments"
      importResourceId={ImportResources.FinancialPayments}
      insertMutationName="insertFinancialPaymentsCsvFileToDB"
      insertMutationOptions={{
        clearCachePredicates: [
          'financialPayment',
          'myFinancialPayments',
          'financialFee',
          'myFinancialFee',
          'financialCredit',
          'financialCreditsByUserId',
          'financialFeesOverview',
          'financialCreditOverview',
          'financialPaymentTotalsByDate',
          'financialTransactionReport',
        ],
      }}
      isOpen={isFinancialPaymentFileUploadDialogOpen}
      onClose={handleCloseDialogPaymentFileUpload}
      uploadMutationName="csvFinancialPaymentUpload"
    />
  );
};

export default DialogFinancialPaymentFileUpload;
