const requireFormFields = (values, nonRequiredKeys = []) => {
  const errors = {};

  Object.entries(values).forEach(([k, v]) => {
    const doesVHaveValue = v !== '' && v !== null && v !== undefined;

    if (!doesVHaveValue && !nonRequiredKeys.includes(k)) {
      errors[k] = 'Required';
    }
  });
  Object.keys(errors).forEach((key) => {
    if (Object.keys(errors[key]).length === 0) {
      delete errors[key];
    }
  });
  return errors;
};

export default requireFormFields;
