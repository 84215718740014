// External Dependencies
import { FC } from 'react';
import ArroForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  OnboardingOrganizationInfoForm,
  Page,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetOrganization } from 'gql/queries';

// Local Variables
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    margin: `${theme.spacing(1.5)} auto`,
  },
  fontSize: '1em',
  margin: '16px auto',
  textAlign: 'center',
}));

const pathToDashboard = `/${PATHS.DASHBOARD}`;

// Component Definition
const DirectorCreateOrganization: FC = () => {
  const {
    data,
    error,
    loading,
  } = useGetOrganization();

  return (
    <Page
      backButtonProps={{
        label: 'Dashboard',
        path: pathToDashboard,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <Container maxWidth={500}>
        <Subtitle>
          Create New Organization
        </Subtitle>

        <StyledCard variant="outlined">
          <CardContent>
            <StyledTypography variant="h6">Who is this for?</StyledTypography>

            <List
              sx={{
                marginBottom: 1,
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <ArroForwardIcon />
                </ListItemIcon>

                <ListItemText primary="You work as a director in multiple organizations." />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ArroForwardIcon />
                </ListItemIcon>

                <ListItemText primary="You are opening up a new school in the district." />
              </ListItem>
            </List>

            <EnhancedAlert>
              In most cases, the directors of each organization should
              create their own {APP_NAME} organization.
            </EnhancedAlert>
          </CardContent>
        </StyledCard>

        <EnhancedCard>
          <OnboardingOrganizationInfoForm
            districtStateId={data?.organization?.district?.state.id}
          />
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default DirectorCreateOrganization;
