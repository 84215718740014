// External Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  delay: PropTypes.number,
  execFunc: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  delay: 2000,
  initialValue: '',
};

// Component Definition
class Debouncer extends Component {
  constructor(props) {
    super(props);

    this.execFuncTimeout = null;

    this.state = {
      value: props.initialValue,
    };
  }

  // Normal debouncing logic
  handleChange = (e) => {
    clearTimeout(this.execFuncTimeout);

    const {
      delay,
      execFunc,
    } = this.props;

    const { value } = e.target;

    this.setState({ value }, () => {
      this.execFuncTimeout = setTimeout(() => {
        execFunc(value);
      }, delay);
    });
  };

  render() {
    const {
      value,
    } = this.state;

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      execFunc,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      initialValue,
      ...props
    } = this.props;

    return (
      <input
        onChange={this.handleChange}
        value={value}
        {...props}
      />
    );
  }
}

Debouncer.propTypes = propTypes;
Debouncer.defaultProps = defaultProps;

export default Debouncer;
