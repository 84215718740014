// External Dependencies
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';

// Internal Dependencies
import { DialogPermissionRequired, TableDataGrid } from 'components/shared';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/financialPaymentFileUploadDialog/actions';
import {
  useGetFinancialPaymentsIndex,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DialogFinancialPaymentFileUpload from 'components/shared/DialogFinancialPaymentFileUpload';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialPaymentsTableZeroState from './FinancialPaymentsTableZeroState';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Local Variables
const clickRowTo = (id: string) => `/${PATHS.FINANCIAL_PAYMENTS}/${id}`;

// Component Definition
const FinancialPaymentsTable = ({
  schoolYearEnding,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const canWritePayments = useSelector(hasPermission('payments', 'write'));

  const {
    isOpen: isPermissionRequiredDialogOpen,
    toggleIsOpen: toggleIsPermissionRequiredDialogOpen,
  } = useIsOpen();

  const {
    data,
    isLoading,
  } = useGetFinancialPaymentsIndex(schoolYearEnding);

  const columns = useColumns(schoolYearEnding);

  const handleOpenDialogFinancialPaymentFileUpload = useCallback(() => {
    if (!canWritePayments) {
      return toggleIsPermissionRequiredDialogOpen();
    }
    return dispatch(open());
  }, [canWritePayments, dispatch, toggleIsPermissionRequiredDialogOpen]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canWritePayments) {
      actions.push({
        action: handleOpenDialogFinancialPaymentFileUpload,
        icon: <CloudUploadIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        sectionTitle: 'Import',
        text: 'Import Payments',
      });
    }

    return actions;
  }, [
    canWritePayments,
    handleOpenDialogFinancialPaymentFileUpload,
  ]);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWritePayments ? {
            label: 'Payment',
            to: `/${PATHS.FINANCIAL_PAYMENTS_NEW}`,
          } : null}
          clickRowTo={clickRowTo}
          columns={columns}
          components={{
            NoRowsOverlay: FinancialPaymentsTableZeroState,
          }}
          key={schoolYearEnding}
          loading={isLoading}
          rows={data}
          tableResource={`financialPayments-${schoolYearEnding}`}
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogPermissionRequired
        isOpen={isPermissionRequiredDialogOpen}
        onClose={toggleIsPermissionRequiredDialogOpen}
      />

      <DialogFinancialPaymentFileUpload />
    </>
  );
};

export default FinancialPaymentsTable;
