// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import { InventoryConditions } from '@presto-assistant/api_types';

// Internal Dependencies
import {
  CustomInput,
  CustomSelect,
  OrganizationSelect,
  StyledLink,
} from 'components/shared';
import { mapEnum } from 'utils/lib/map_enum';
import {
  useGetDistrictLibraryCategories,
  useGetOrganizationTypes,
} from 'gql/queries';
import {
  useGetDistrictLibraryInstrumentations,
} from 'gql/queries/library-instrumentations-queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import {
  useGetGradeLevelSelectOptions,
  useGetLibraryCategorySelectOptions,
  useGetLibraryInstrumentationSelectOptions,
} from './data';
import DialogAddLibraryCategory from './DialogAddLibraryCategory';
import DialogAddLibraryInstrumentation from './DialogAddLibraryInstrumentation';

// Local Typings
interface Props {
  isAdmin: boolean;
  orgTypeId?: string;
  organizationId?: string;
}

// Local Variables
const conditionData = mapEnum(InventoryConditions);

// Component Definition
const LibraryInfoFormFields: FC<Props> = ({
  isAdmin,
  orgTypeId,
  organizationId,
}) => {
  const {
    isOpen: isAddLibraryCategoryDialogOpen,
    toggleIsOpen: toggleAddLibraryCategoryDialog,
  } = useIsOpen();

  const {
    isOpen: isAddLibraryInstrumentationDialogOpen,
    toggleIsOpen: toggleAddLibraryInstrumentationDialog,
  } = useIsOpen();

  const handleClickAddCategory = () => {
    toggleAddLibraryCategoryDialog();
  };

  const handleClickAddInstrumentation = () => {
    toggleAddLibraryInstrumentationDialog();
  };

  // TODO: skip this query if not district admin
  const { data: orgTypeData } = useGetOrganizationTypes();

  const {
    data: gradeLevelData,
  } = useGetGradeLevelSelectOptions();

  const {
    data: categoryData,
  } = useGetLibraryCategorySelectOptions({ isAdmin });

  const {
    data: districtCategoryData,
  } = useGetDistrictLibraryCategories({ organizationId });

  const {
    data: instrumentationData,
  } = useGetLibraryInstrumentationSelectOptions({ isAdmin });

  const {
    data: districtInstrumentationData,
  } = useGetDistrictLibraryInstrumentations({ organizationId });

  const showCreateLinks = (isAdmin && organizationId) || !isAdmin;

  return (
    <>
      {isAdmin && orgTypeId && orgTypeData && (
        <CustomSelect
          fullWidth
          label="Organization Type"
          name="orgTypeId"
          options={orgTypeData.organizationTypes}
          required
          variant="filled"
        />
      )}

      {isAdmin && orgTypeId && (
        <OrganizationSelect orgTypeId={orgTypeId} />
      )}

      {!isAdmin && (
        <CustomSelect
          label="Category"
          name="categoryId"
          options={categoryData?.libraryCategories?.map((category) => ({
            id: category.id,
            label: category.label,
          })).sort((a, b) => a.label.localeCompare(b.label))}
        />
      )}

      {isAdmin && (
        <CustomSelect
          label="Category"
          name="categoryId"
          options={districtCategoryData?.dfaLibraryCategories?.map((category) => ({
            id: category.id,
            label: category.label,
          })).sort((a, b) => a.label.localeCompare(b.label))}
        />
      )}

      {showCreateLinks && (
        <Box
          mx={1.5}
          my={1}
        >
          <StyledLink onClick={handleClickAddCategory}>
            Add Library Category
          </StyledLink>
        </Box>
      )}

      {!isAdmin && (
        <CustomSelect
          label="Voice/Instrumentation"
          name="instrumentationId"
          options={instrumentationData?.libraryInstrumentations?.map((instrumentation) => ({
            id: instrumentation.id,
            label: instrumentation.label,
          })).sort((a, b) => a.label.localeCompare(b.label))}
        />
      )}

      {isAdmin && (
        <CustomSelect
          label="Voice/Instrumentation"
          name="instrumentationId"
          options={(districtInstrumentationData?.dfaLibraryInstrumentations ?? [])
            .map((instrumentation) => ({
              id: instrumentation.id,
              label: instrumentation.label,
            })).sort((a, b) => a.label.localeCompare(b.label))}
        />
      )}

      {showCreateLinks && (
        <Box
          mx={1.5}
          my={1}
        >
          <StyledLink onClick={handleClickAddInstrumentation}>
            Add Library Voice/Instrumentation
          </StyledLink>
        </Box>
      )}

      <CustomInput
        label="Title"
        name="title"
      />
      <CustomInput
        label="Composer"
        name="composer"
      />
      <CustomInput
        label="Arranger"
        name="arranger"
      />
      <CustomInput
        label="Library Number"
        name="number"
      />
      <CustomInput
        label="Publication Date"
        name="year"
        type="number"
      />

      <CustomSelect
        label="Grade Level"
        name="gradeLevelId"
        options={gradeLevelData?.libraryGradeLevels?.map((level) => ({
          id: level.id,
          label: level.description
            ? `Grade ${level.value} (${level.description})`
            : `Grade ${level.value}`,
        }))}
      />

      <CustomSelect
        label="Condition"
        name="conditionId"
        options={conditionData}
      />

      <CustomInput
        label="Comments"
        multiline
        name="comments"
      />

      <DialogAddLibraryCategory
        isAdmin={isAdmin}
        isOpen={isAddLibraryCategoryDialogOpen}
        onClose={toggleAddLibraryCategoryDialog}
        organizationId={isAdmin ? organizationId : undefined}
      />

      <DialogAddLibraryInstrumentation
        isAdmin={isAdmin}
        isOpen={isAddLibraryInstrumentationDialogOpen}
        onClose={toggleAddLibraryInstrumentationDialog}
        organizationId={isAdmin ? organizationId : undefined}
      />
    </>
  );
};

export default LibraryInfoFormFields;
