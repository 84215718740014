// External Dependencies
import { DynamicFieldTypes } from '@presto-assistant/api_types';
import { ListDynamicFieldsResponseItem } from '@presto-assistant/api_types/api/v1/dynamicField';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { capitalize } from 'utils';
import { dateColumn } from 'utils/lib/tableColumns';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetOrganizationTypes } from 'gql/queries';

// Local Dependencies
import { tableRefOptions } from '../../shared/helpers';

// Local Variables
const getFieldTypeLabel = (fieldType: {
  id: DynamicFieldTypes;
  label: string;
}) => {
  switch (fieldType.id) {
    case DynamicFieldTypes['double precision']:
      return 'Number';
    case DynamicFieldTypes.boolean:
      return 'True/False';
    default:
      return capitalize(fieldType.label);
  }
};

const mappedDynamicFieldTypes = mapEnum(DynamicFieldTypes).map((fieldType) => {
  return {
    label: getFieldTypeLabel(fieldType),
    value: fieldType.id,
  };
});

const mappedTableRefs = tableRefOptions.map((tableRef) => ({
  ...tableRef,
  value: tableRef.label,
}));

export const useColumns = (
  extraColumns?: DataGridColDef<ListDynamicFieldsResponseItem>[],
) => {
  const {
    data: orgTypeData,
    // loading,
  } = useGetOrganizationTypes();

  const mappedOrgTypes = useMemo(() =>
    orgTypeData?.organizationTypes.map((orgType) => ({
      id: orgType.id,
      label: orgType.label,
      value: orgType.label,
    })) || [], [orgTypeData]);

  return useMemo(() => {
    const columns: DataGridColDef<ListDynamicFieldsResponseItem>[] = [
      {
        field: 'tableRef',
        headerName: 'Table',
        type: 'singleSelect',
        valueFormatter: (params) => capitalize(params.value),
        valueGetter: (params) =>
          mappedTableRefs.find((tableRef) => tableRef.id === params.row.tableRef)?.label,
        valueOptions: mappedTableRefs,
      },
      {
        field: 'organizationTypeId',
        headerName: 'Organization Type',
        minWidth: 160,
        type: 'singleSelect',
        valueGetter: (params) =>
          mappedOrgTypes.find((type) =>
            parseInt(type.id, 10) === params.row.organizationTypeId)?.label,
        valueOptions: mappedOrgTypes,
      },
      {
        field: 'label',
        headerName: 'Name',
        minWidth: 200,
      },
      {
        field: 'typeId',
        headerName: 'Type',
        minWidth: 160,
        renderCell: (params) => {
          const fieldType = mappedDynamicFieldTypes.find(
            (ft) => ft.value === params.value,
          );

          return fieldType?.label || '';
        },
        type: 'singleSelect',
        valueOptions: mappedDynamicFieldTypes,
      },
      dateColumn({
        field: 'createdAt',
        headerName: 'Date Added',
      }),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [extraColumns, mappedOrgTypes]);
};
