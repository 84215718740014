// External Dependencies
import { QueryHookOptions } from '@apollo/client';
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Variables
interface DistrictRequestsResponse {
  districtRequests: GQL.IDistrictRequest[];
}

export const GET_DISTRICT_REQUESTS = gql`
  query DistrictRequests {
    districtRequests {
      createdAt
      district {
        admin {
          email
        }
        id
        label
      }
      id
      orgId
      requestedByDistrict
    }
  }
`;

export const useGetDistrictRequests = (options?: QueryHookOptions) =>
  useQueryEnhanced<DistrictRequestsResponse>(
    GET_DISTRICT_REQUESTS,
    options,
  );
