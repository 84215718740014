// External Dependencies
import { useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardContent from '@mui/material/CardContent';

// Internal Dependencies
import { EnhancedCard, ListItemWithSecondaryAction } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import BillingSettingsCardHeader from 'components/shared/BillingSettingsCardHeader';

// Component Definition
const BillingSettingsCard = (): JSX.Element => {
  const secondaryAction = useMemo(() => ({
    buttonIcon: <ArrowForwardIcon />,
    buttonText: 'View',
    to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.BILLING}`,
  }), []);

  return (
    <EnhancedCard>
      <BillingSettingsCardHeader />

      <CardContent>
        <ListItemWithSecondaryAction
          primaryText="Manage Billing Details"
          secondaryAction={secondaryAction}
          secondaryText="View and manage your billing details for your district"
          secondaryTypographyProps={{
            component: 'div',
          }}
        />
      </CardContent>
    </EnhancedCard>
  );
};

export default BillingSettingsCard;
