// External Dependencies
import { compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// Internal Dependencies
import rootReducer from './reducers';

const composeEnhancers = process.env.NODE_ENV === 'development'
  ? composeWithDevTools({
    name: 'presto-app',
    shouldCatchErrors: true,
    trace: true,
  })
  : compose;

const enhancer = composeEnhancers();

export const initializeStore = (
  initialState?: any,
) =>
  createStore(rootReducer, initialState, enhancer);
