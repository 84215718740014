// External Dependencies
import {
  FC,
  ReactNode,
  useRef,
  useState,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

// Local Typings
interface SubButtonProps {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  startIcon?: ReactNode;
}
interface Props {
  buttons: SubButtonProps[];
  isLoading?: boolean;
  primaryButtonProps: SubButtonProps;
  secondaryButtonAriaLabel: string;
}

// Component Definition
const SplitButton: FC<Props> = ({
  buttons,
  isLoading,
  primaryButtonProps,
  secondaryButtonAriaLabel,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    buttons[index]?.onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        aria-label="split button"
        color="primary"
        disabled={primaryButtonProps.disabled || isLoading}
        ref={anchorRef}
        variant="contained"
      >
        <Button
          onClick={primaryButtonProps.onClick}
          startIcon={isLoading ? <CircularProgress size={20} /> : primaryButtonProps.startIcon}
        >
          {primaryButtonProps.label}
        </Button>

        {buttons.length > 0 && (
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            aria-label={secondaryButtonAriaLabel}
            color="primary"
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        role={undefined}
        sx={{
          zIndex: (theme) => theme.zIndex.tooltip,
        }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {buttons.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      selected={index === selectedIndex}
                    >
                      {option.startIcon && <ListItemIcon>{option.startIcon}</ListItemIcon>}
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
