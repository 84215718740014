// External Dependencies
import { Form, Formik } from 'formik';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { useSubmitFormBlockResponse } from 'gql/mutations/form-mutations';

// Local Dependencies
import FormContent from './FormContent';

// Local Typings
interface Props {
  disabled?: boolean;
  formAssignmentId: string;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
  readOnly?: boolean;
  setIsFormDirty: (isFormDirty: boolean) => void;
}

// Local Variables
const StyledForm = styled(Form)({ width: '100%' });

// Component Definition
const MyFormBlockForm = ({
  disabled,
  formAssignmentId,
  formBlock,
  readOnly,
  setIsFormDirty,
}: Props): JSX.Element => {
  const [
    submitFormBlockResponse,
  ] = useSubmitFormBlockResponse();

  const initialValues = useMemo<GQL.ISubmitFormBlockResponseInput>(() => {
    return {
      formAssignmentId,
      formBlockId: formBlock.id,
      response: formBlock.response ?? '',
    };
  }, [formAssignmentId, formBlock]);

  const handleFormikSubmit = useCallback((values: GQL.ISubmitFormBlockResponseInput) => {
    if (readOnly) {
      return;
    }

    submitFormBlockResponse({
      variables: {
        input: {
          ...values,
          response: values.response.toString(),
        },
      },
    });
  }, [readOnly, submitFormBlockResponse]);

  if (formBlock.isFixed) {
    return (
      <ShowPageDataDisplay
        label={formBlock.label}
        value={formBlock.response}
      />
    );
  }

  return (
    <Formik<GQL.ISubmitFormBlockResponseInput>
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormContent
            disabled={disabled || formBlock.isFixed}
            formBlock={formBlock}
            onFormBlockSubmit={handleFormikSubmit}
            setIsFormDirty={setIsFormDirty}
            values={values}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default MyFormBlockForm;
