// External Dependencies
import { DynamicFieldTypes } from '@presto-assistant/api_types';
import { FC } from 'react';
import { useField } from 'formik';
import moment from 'moment';

// Internal Dependencies
import { isDynamicFieldDate } from 'utils/lib/dyanmic_field';

// Local Dependencies
import ShowPageDataDisplay from '../ShowPageDataDisplay';

// Local Typings
interface Props {
  field: GQL.IDynamicField;
}

// Local Variables
export const formatDyanmicFieldValue = (field: GQL.IDynamicField, value: any) => {
  if (!value) {
    return '';
  }

  const typeId = Number(field.type.id);

  if (typeId === DynamicFieldTypes.date && isDynamicFieldDate(value)) {
    return moment(value).format('MM/DD/YYYY');
  }

  return value;
};

const useDisplayValue = (field: GQL.IDynamicField) => {
  const [formikField] = useField(field.dynamicFieldRef);

  const { value } = formikField;

  return formatDyanmicFieldValue(field, value);
};

// Component Definition
const ShowPageDynamicDataDisplay: FC<Props> = ({ field }) => {
  const displayValue = useDisplayValue(field);

  return (
    <ShowPageDataDisplay
      label={field.label}
      value={displayValue}
    />
  );
};

export default ShowPageDynamicDataDisplay;
