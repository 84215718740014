// External Dependencies
import { GetDistrictInventoryAuditResponse } from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Internal Dependencies
import {
  EnhancedCard,
  TableDataGrid,
} from 'components/shared';

// Local Dependencies
import { AssignedOrganization, useColumns } from './hooks';

// Local Typings
interface Props {
  assignedOrganizations: GetDistrictInventoryAuditResponse['data']['assignments'] | undefined;
  isLoading: boolean;
}

// Component Definition
const AssignedOrganizationsTable = ({
  assignedOrganizations,
  isLoading,
}: Props): JSX.Element => {
  const tableColumns = useColumns();

  return (
    <EnhancedCard>
      <DataGridContainer>
        <TableDataGrid
          columns={tableColumns}
          getRowId={(row: AssignedOrganization) => row.organization.id}
          loading={isLoading}
          rows={assignedOrganizations ?? []}
          withSearch
          zeroStateMessage="No organizations assigned to this audit"
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default AssignedOrganizationsTable;
