// External Dependencies
import { useLocation } from 'react-router-dom';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { useGetDistrictEmailIndex } from 'utils/api/communication';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridServerSide, {
  useParsedDataGridQueryString,
} from 'components/shared/TableDataGrid/ServerSide';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';

// Local Variables
const handleTableClickRow = (id: string) =>
  `/${PATHS.DISTRICT_ADMIN}/${PATHS.COMMUNICATION}/${id}`;

// Component Definition
const EmailTable = () => {
  const { search } = useLocation();

  const columns = useColumns();

  const {
    filters,
    pagination,
    sort,
  } = useParsedDataGridQueryString(search, {
    columns,
    mapAllKeysToSnakeCase: true,
  });

  const {
    data: districtEmailData,
    isLoading,
  } = useGetDistrictEmailIndex({
    filters,
    limit: pagination?.pageSize ?? 100,
    page: pagination?.page ?? 1,
    sort,
  });

  return (
    <DataGridContainer>
      <TableDataGridServerSide
        clickRowTo={handleTableClickRow}
        columns={columns}
        components={{
          NoRowsOverlay: TableDataGridZeroState,
        }}
        fullCount={districtEmailData?.data.fullCount ?? 0}
        loading={isLoading}
        rows={districtEmailData?.data.data ?? null}
        tableResource="districtEmails"
        withSearch
        zeroStateMessage="No emails found."
      />
    </DataGridContainer>
  );
};

export default EmailTable;
