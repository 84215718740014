// External Dependencies
import { useMemo } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  items: string[];
  message: string;
  title: string;
}

// Component Definition
const ExpandableAlert = ({
  items,
  message,
  title,
}: Props): JSX.Element => {
  const { isOpen, toggleIsOpen } = useIsOpen();

  const listItems = useMemo(() => {
    return items.map((item) => {
      return (
        <li key={item}>{item}</li>
      );
    });
  }, [items]);

  return (
    <EnhancedAlert
      action={(
        <Button
          color="inherit"
          onClick={toggleIsOpen}
          size="small"
        >
          {isOpen ? 'Hide' : 'Show'}
        </Button>
      )}
      severity="warning"
      sx={{ marginBottom: 2 }}
      title={title}
    >
      {message}
      <Collapse in={isOpen}>
        <ul>
          {listItems}
        </ul>
      </Collapse>
    </EnhancedAlert>
  );
};

export default ExpandableAlert;
