import gql from 'graphql-tag';

export const GET_USER_ORGANIZATION_ROLES = gql`
  query UserOrganizationRoles($userId: ID!) {
    userOrganizationRoles(userId: $userId) {
      id
      isPrimary
      organizationRoleId
    }
  }
`;
