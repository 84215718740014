// External Dependencies
import {
  Button,
  ButtonProps,
  CircularProgress,
} from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Local Dependencies
import SubscriberOnlyButton from './SubscriberOnlyButton';

// Local Typings
interface Props {
  children: any;
  disabled?: boolean;
  isSaving?: boolean;
  isSubscriberOnly?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
  variant?: ButtonProps['variant'];
}

// Local Variables
const StyledDiv = styled.div({
  marginLeft: 8,
  textAlign: 'center',
  width: 68,
});
const StyledButton = styled(Button)(({ theme }) => ({
  '.root': {
    color: theme.palette.common.white,
  },
  '.textPrimary': {
    color: theme.palette.prestoPrimary,
  },
}));
const StyledSubscriberOnlyButton = styled(SubscriberOnlyButton)(({ theme }) => ({
  '.root': {
    color: theme.palette.common.white,
  },
  '.textPrimary': {
    color: theme.palette.prestoPrimary,
  },
}));

// Component Definition
const SaveButton: FC<Props & ButtonProps> = ({
  children,
  disabled,
  isSaving,
  isSubscriberOnly,
  onClick,
  variant = 'contained',
  ...props
}) => {
  const ButtonComponent = isSubscriberOnly ? StyledSubscriberOnlyButton : StyledButton;

  return isSaving ? (
    // Adding a wrapper here lets us give the SVG elemeent
    //  about the same amount of space as the SAVE button
    <StyledDiv>
      <CircularProgress
        size={24}
        thickness={6}
      />
    </StyledDiv>
  ) : (
    <ButtonComponent
      classes={{
        root: 'root',
        textPrimary: 'textPrimary',
      }}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      type="submit"
      variant={variant}
      {...props}
    >
      {children}
    </ButtonComponent>
  );
};

export default SaveButton;
