// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import { useEffect } from 'react';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import {
  useQueryEnhanced,
} from 'utils/lib/graphql';

// Local Dependencies
import { FRAGMENT_FINANCIAL_PAYMENT_SHOW } from './financial-payment-queries';

// Local Typings
interface FinancialDepositsIndexResponse {
  financialDepositsIndex: GQL.IFinancialDepositIndexItemAll;
}

export const GET_FINANCIAL_DEPOSITS_INDEX = gql`
  query FinancialDepositsIndex(
    $queryParams: IndexQueryParams
  ) {
    financialDepositsIndex(
      queryParams: $queryParams
    ) {
      data {
        amountInCents
        createdAt
        createdByFirstName
        createdById
        createdByLastName
        depositDate
        depositNumber
        financialAccountId
        financialAccountLabel
        id
      }
      fullCount
    }
  }
`;

export const GET_FINANCIAL_DEPOSIT = gql`
  query FinancialDeposit(
    $id: ID!
  ) {
    financialDeposit(id: $id) {
      amountInCents
      createdAt
      createdBy {
        email
        id
        firstName
        lastName
        role {
          canBeAdmin
          id
          label
        }
      }
      depositDate
      depositNumber
      financialAccount {
        id
        label
      }
      id
      payments {
      ...FinancialPaymentShowFragment
      }
    }
  }
  ${FRAGMENT_FINANCIAL_PAYMENT_SHOW}
`;

export const useGetFinancialDepositsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      FinancialDepositsIndexResponse,
      GQL.IFinancialDepositsIndexOnQueryArguments
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    FinancialDepositsIndexResponse,
    GQL.IFinancialDepositsIndexOnQueryArguments
  >({
    gqlQuery,
    options,
    tableResource: 'financialDeposits',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetFinancialDepositsIndex = () => {
  const [query, values] = useGetFinancialDepositsIndexQuery(
    GET_FINANCIAL_DEPOSITS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useDownloadFinancialDepositsIndex = () =>
  useGetFinancialDepositsIndexQuery(
    GET_FINANCIAL_DEPOSITS_INDEX,
    { exportReport: true },
  );

export const useGetFinancialDeposit = (
  id?: string,
) =>
  useQueryEnhanced<{
    financialDeposit: GQL.IFinancialDeposit
  }>(
    GET_FINANCIAL_DEPOSIT,
    {
      skip: !id,
      variables: { id },
    },
  );
