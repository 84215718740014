export const form = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 368,
  padding: '24px 24px 8px',
};

export const formButton = {
  margin: '8px 0',
};

export const formWrapper = {};

export const pageLayout = {
  paddingBottom: 30,
  paddingTop: 30,
};

export const relative = {
  position: 'relative',
};

export default {
  form,
  formButton,
  formWrapper,
  pageLayout,
  relative,
};
