// External Dependencies
import { Button, ButtonProps } from '@mui/material';
import {
  FC, MouseEvent, useCallback,
} from 'react';

// Internal Dependencies
import { useGetOrganizationSubscriptionDetails } from 'gql/queries';
import { useIsAliasUser } from 'hooks/useIsAliasUser';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
import ExpirationDialog from './ExpirationDialog';

// Component Definition
interface SubscriberOnlyButtonProps extends ButtonProps {
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SubscriberOnlyButton: FC<SubscriberOnlyButtonProps> = ({
  disabled = false,
  onClick,
  ...props
}) => {
  const isAliasUser = useIsAliasUser();
  const {
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const { data } = useGetOrganizationSubscriptionDetails();

  const licenseExpiredForNonDFA = data && !data.organization.active;

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (licenseExpiredForNonDFA) {
      toggleIsOpen();
    } else if (onClick) {
      onClick(event);
    }
  }, [licenseExpiredForNonDFA, onClick, toggleIsOpen]);

  return (
    <>
      <Button
        disabled={disabled || isAliasUser}
        onClick={handleClick}
        {...props}
      />

      <ExpirationDialog
        onClose={toggleIsOpen}
        open={isOpen}
      />
    </>
  );
};

export default SubscriberOnlyButton;
