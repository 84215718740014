// External Dependencies
import { FC, useEffect } from 'react';
import { Genders } from '@presto-assistant/api_types';
import { useFormikContext } from 'formik';

// Internal Dependencies
import { useGetGenderOptions } from 'hooks/useGetGenderOptions';

// Local Dependencies
import CustomSelect from './CustomSelect';

// Local Typings
interface Props {
  readOnly?: boolean;
  required?: boolean;
}

interface GenderFormProps {
  genderId?: string;
  otherGenderLabel?: string;
}

// Component Definition
const GenderSelect: FC<Props> = ({
  readOnly,
  required = false,
}) => {
  const { setFieldValue, values } = useFormikContext<GenderFormProps>();

  useEffect(() => {
    if (values.genderId !== Genders.Other.toString()) {
      setFieldValue('otherGenderLabel', '');
    }
  }, [setFieldValue, values.genderId]);

  const genderOptions = useGetGenderOptions();

  return (
    <CustomSelect
      // need to make sure we have data if readOnly or it breaks
      inputProps={{ readOnly: readOnly && !genderOptions.loading }}
      isLoading={genderOptions.loading}
      label={`Gender${required && !readOnly ? ' *' : ''}`}
      name="genderId"
      options={genderOptions.options}
    />
  );
};

export default GenderSelect;
