// External Dependencies
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Component Definition
const PaymentFormEmptyState = (): JSX.Element => {
  return (
    <Collapse
      in
      timeout="auto"
    >
      <CardContent sx={{ marginX: 3, marginY: 1.5 }}>
        <EnhancedAlert severity="warning">
          This person has no outstanding fees. Please assign a fee before applying a payment.
        </EnhancedAlert>
      </CardContent>
    </Collapse>
  );
};

export default PaymentFormEmptyState;
