// External Dependencies
import { FC } from 'react';

/*
 * SmileyCatSvg
 *
 * From Wikimedia Commons
 * https://upload.wikimedia.org/wikipedia/commons/e/e5/Emojione_1F638.svg
 *
 */

// Component Definition
const SmileyCatSvg: FC = () =>
  (
    <svg
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
    >
      <path
        d="M60.9,2c1.8,13.4,1.5,24.2-1.2,31.1L34.9,14.7C39.8,9.6,49.8,3.5,60.9,2z"
        fill="#dadee0"
      />
      <path
        d="m57.7 32.2l-10.2-13.3c0 0 1.9-7.3 11.6-12.4 2.9 11.6-1.4 25.7-1.4 25.7"
        fill="#f7a4a4"
      />
      <path
        d="m53.8 29.2l-6.3-8.2c0 0 2.6-5 8.6-8.2-3.5 7.5-2.3 16.4-2.3 16.4"
        fill="#f78d8d"
      />
      <path
        d="M3.1,2C1.3,15.4,1.6,26.2,4.3,33.1l24.8-18.5C24.2,9.6,14.2,3.5,3.1,2z"
        fill="#dadee0"
      />
      <path
        d="m6.3 32.2l10.3-13.3c0 0-1.9-7.3-11.6-12.4-3 11.6 1.3 25.7 1.3 25.7"
        fill="#f7a4a4"
      />
      <path
        d="m10.2 29.2l6.4-8.2c0 0-2.6-5-8.6-8.2 3.4 7.5 2.2 16.4 2.2 16.4"
        fill="#f78d8d"
      />
      <path
        d="m58.2 50.8c.8-.9 1.4-1.6 1.7-2.2 1.6-2.6 2.1-5.3 2.1-5.3l-2 .4c0 0 2.3-2.5 1.7-7.8l-2 2.1c0 0 1.9-5.4.3-10.4l-2.2 1.6c0 0 .7-2 .1-3.4l-.9.7c0 0 1.7-4.7.2-6.6-.9 2.8-1.9 2.8-1.9 2.8s1.2-2.1.3-3c-1 1.6-1.8.6-1.8.1.1-.6-.2-1.5 2.7-2-2.3-1.4-5.1-.4-5.1-.4s.1-1.5 2.1-1.4c-1.9-1.6-4.5-.6-4.5-.6-4.1-3.1-9.6-4.9-17.1-4.9-7.4 0-12.9 1.8-17.1 4.9 0 0-2.6-.9-4.5.6 2-.1 2.1 1.4 2.1 1.4s-2.7-1-5.1.4c2.9.4 2.7 1.3 2.7 2 0 .5-.8 1.5-1.8-.1-1 .9.3 3 .3 3s-1 0-1.9-2.8c-1.5 2 .2 6.6.2 6.6l-.9-.7c-.6 1.4.1 3.4.1 3.4l-2-1.6c-1.7 5 .3 10.4.3 10.4l-1.9-2.1c-.7 5.3 1.6 7.8 1.6 7.8l-2-.4c0 0 .5 2.7 2.1 5.3.2.5.8 1.3 1.7 2.2 0 0 0 0 .1.1 4.1 4.1 14.2 11.1 26.1 11.1 11.9 0 22-7 26.1-11.1 0-.1.1-.1.1-.1"
        fill="#dadee0"
      />
      <g fill="#94989b">
        <path
          d="m37.7 10.8c-1.8-.3-3.7-.4-5.7-.4s-3.9.1-5.7.4c.7 3.9 2.8 6.6 5.7 11.6 3-5.1 5-7.8 5.7-11.6"
        />
        <path
          d="m46.8 13.8c-1.9-1.1-4.1-1.9-6.5-2.5.6 2.3 1.9 3.9 3.6 6.9 1.3-2.1 2.2-2.7 2.9-4.4"
        />
        <path
          d="m23.7 11.3c-2.5.6-4.6 1.4-6.5 2.5.7 1.8 1.7 2.3 2.9 4.4 1.8-3.1 3-4.7 3.6-6.9"
        />
      </g>
      <g fill="#4c5359">
        <path
          d="m36 36c-.8-.9-3.3-1-4-1-.7 0-3.2.1-4 1-.6.7-.1 2.3 1.4 3.8 1 .9 1.9 1.2 2.6 1.2.7 0 1.7-.3 2.6-1.2 1.6-1.4 2-3.1 1.4-3.8"
        />
        <path
          d="m27 32.9c-1.9-5.1-4.7-7.7-7.5-7.7s-5.6 2.6-7.5 7.7c-.2.5.8 1.4 1.3.9 1.8-1.9 4-2.7 6.2-2.7 2.2 0 4.4.8 6.2 2.7.5.6 1.5-.4 1.3-.9"
        />
        <path
          d="m51.9 32.9c-1.9-5.1-4.7-7.7-7.5-7.7-2.8 0-5.6 2.6-7.5 7.7-.2.5.8 1.4 1.3.9 1.8-1.9 4-2.7 6.2-2.7s4.4.8 6.2 2.7c.6.6 1.5-.4 1.3-.9"
        />
        <path
          d="m49 45.4c0-.8-.5-1.8-1.8-2.1-3.5-.6-8.6-1.3-15.2-1.3-6.6 0-11.7.7-15.2 1.3-1.3.3-1.8 1.3-1.8 2.1 0 9.6 2.7 12.6 13.8 12.6h6.5c11 0 13.7-3 13.7-12.6"
        />
      </g>
      <g fill="#fff">
        <path
          d="m45.1 46.2c.1-.5 0-1-.4-1.1 0 0-5.7-1.1-12.7-1.1-7 0-12.7 1.1-12.7 1.1-.4.1-.6.6-.4 1.1l.9 2.9c.1.5.6.9 1 .9h22.5c.4 0 .8-.4 1-.9l.8-2.9"
        />
        <path
          d="m32 56c4.9 0 12 0 11.8-2.1 0-.4-.1-.8-.2-1.3-.1-.5-.2-.7-1.1-.7-1.9 0-19.5 0-21 0-.8 0-.9.1-1.1.7-.1.5-.2.9-.2 1.3-.2 2.1 6.9 2.1 11.8 2.1"
        />
      </g>
    </svg>
  );
export default SmileyCatSvg;
