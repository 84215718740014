// External Dependencies
import {
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import DialogContentText from '@mui/material/DialogContentText';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedCard,
  TableDataGrid,
} from 'components/shared';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useGetSuppressedEmailsIndex } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useRemoveSuppressedEmails } from 'gql/mutations';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import SuppressedEmailsTableZeroState from './SuppressedEmailsTableZeroState';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  // This resets the default padding-bottom of 24px coming from MUI
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
  backgroundColor: theme.palette.appBackground,
}));

const StyledStrong = styled.strong({ fontSize: '1.25rem' });

const pinnedColumns = ['email'];

// Component Definition
const SuppressedEmailsTable = (): JSX.Element => {
  const [
    removeEmailRow,
    setRemoveEmailRow,
  ] = useState<GQL.ISuppressedEmailUserIndex | null>(null);
  const [
    selectedSuppressedEmailIds,
    setSelectedSuppressedEmailIds,
  ] = useState<GridRowSelectionModel>([]);

  const dispatch = useDispatch();

  const handleRemoveSelection = useClearTableSelection('suppressedEmails');
  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: toggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const {
    data,
    isLoading,
  } = useGetSuppressedEmailsIndex();

  const handleRemoveSuccess = useCallback(() => {
    toggleIsConfirmationDialogOpen();
    setRemoveEmailRow(null);
    handleRemoveSelection();
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));
  }, [
    dispatch,
    handleRemoveSelection,
    toggleIsConfirmationDialogOpen,
  ]);

  const [
    removeEmails,
    {
      loading: isRemoving,
    },
  ] = useRemoveSuppressedEmails({
    onCompleted: handleRemoveSuccess,
  });

  const isSelectionActive = selectedSuppressedEmailIds.length > 0;

  const handleRemoveFromSupressionList = useCallback(() => {
    // Remove single email
    if (removeEmailRow) {
      removeEmails({
        variables: {
          input: {
            memberIds: [removeEmailRow.id],
          },
        },
      });
      // Remove selection of emails
    } else if (isSelectionActive) {
      removeEmails({
        variables: {
          input: {
            memberIds: selectedSuppressedEmailIds as string[],
          },
        },
      });
    }
  }, [isSelectionActive, removeEmailRow, removeEmails, selectedSuppressedEmailIds]);

  const handleClearRow = useCallback(() => {
    setRemoveEmailRow(null);
  }, []);

  const handleClickRemoveSuppressedEmail = useCallback((row: GQL.ISuppressedEmailUserIndex) => {
    setRemoveEmailRow(row);
    toggleIsConfirmationDialogOpen();
  }, [toggleIsConfirmationDialogOpen]);

  const handleClickRemoveSelectedSuppressedEmails = useCallback(() => {
    toggleIsConfirmationDialogOpen();
  }, [toggleIsConfirmationDialogOpen]);

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const actionColumn = createDataGridActionsColumn<GQL.ISuppressedEmailUserIndex>([
        ...(canEditUsers ? [{
          action: handleClickRemoveSuppressedEmail,
          icon: <RemoveIcon fontSize="small" />,
          isDisabled: isSelectionActive,
          text: 'Remove suppressed email',
        }] : []),
      ]);

      return actionColumn ? [actionColumn] : [];
    },
    [canEditUsers, handleClickRemoveSuppressedEmail, isSelectionActive],
  );

  const columns = useColumns(extraColumns);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canEditUsers) {
      actions.push({
        action: handleClickRemoveSelectedSuppressedEmails,
        icon: <RemoveIcon fontSize="small" />,
        isDisabled: selectedSuppressedEmailIds.length === 0,
        text: 'Remove suppressed emails',
      });
    }

    return actions;
  }, [
    canEditUsers,
    handleClickRemoveSelectedSuppressedEmails,
    selectedSuppressedEmailIds.length,
  ]);

  const emailCount = isSelectionActive ? selectedSuppressedEmailIds.length : 1;

  const singleEmailDialogContent = (
    <>
      The email
      {' '}
      <strong>{removeEmailRow?.email}</strong>
      {' '}
      failed to receive emails for the following reason:
      <EnhancedCard sx={{ m: 1.5 }}>
        <StyledCardContent>
          <Typography
            color="textSecondary"
            component="blockquote"
          >
            {removeEmailRow?.reason}
          </Typography>
        </StyledCardContent>
      </EnhancedCard>
    </>
  );

  const selectedEmailDialogContent = (
    <Box mb={1}>
      You have selected <StyledStrong>{emailCount}</StyledStrong>{' '}
      {pluralize(emailCount, 'email')}.
    </Box>
  );

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          checkboxSelection={canEditUsers}
          columns={columns}
          components={{
            NoRowsOverlay: SuppressedEmailsTableZeroState,
          }}
          getRowHeight={() => 'auto'}
          leftPinnedColumns={pinnedColumns}
          loading={isLoading}
          onSelectionModelChange={setSelectedSuppressedEmailIds}
          rows={data}
          selectionModel={selectedSuppressedEmailIds}
          tableResource="suppressedEmails"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonAction={handleRemoveFromSupressionList}
        confirmButtonText="Yes, Remove"
        declineButtonAction={toggleIsConfirmationDialogOpen}
        description={(
          <DialogContentText
            component="div"
            id="alert-dialog-description"
          >
            {isSelectionActive ? selectedEmailDialogContent : singleEmailDialogContent}

            <div>
              By removing {pluralize(emailCount, 'this email', 'these emails')} from our suppression list,
              we will attempt to send future emails to {pluralize(emailCount, 'this member', 'these members')}.
            </div>
          </DialogContentText>
        )}
        handleClose={toggleIsConfirmationDialogOpen}
        isSubmitting={isRemoving}
        maxWidth="md"
        onExited={handleClearRow}
        open={isConfirmationDialogOpen}
        title={`Remove Suppressed ${pluralize(emailCount, 'Email')}?`}
        useCustomText
      />
    </>
  );
};

export default SuppressedEmailsTable;
