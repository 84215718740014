// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import KeyIcon from 'mdi-material-ui/Key';

// Internal Dependencies
import {
  Container,
  EnhancedAlert,
  InviteAdmin,
  Page,
  ShowCard,
  StyledLink,
} from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { getFullName } from 'utils';
import {
  useGetAdult,
  useGetOrganization,
} from 'gql/queries';
import { useUpdateUserPermissionsByDirector } from 'gql/mutations';
import PermissionEditPage from 'components/shared/PermissionEditPage';
import useIsSelf from 'hooks/useIsSelf';

// Local Typings
interface Props {
  adultUserId: string;
  context: 'Director' | 'Parent';
  pathToShowPage: string;
}

// Component Definition
const EditParentPermissions: FC<Props> = ({
  adultUserId,
  context,
  pathToShowPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState<GQL.IPermissions | null>(null);
  const [permissionIds, setPermissionIds] = useState<number[]>([]);
  const [mayEditPermissions, setMayEditPermissions] = useState(false);

  const isSelf = useIsSelf(adultUserId);

  const {
    data: adultData,
    error,
    loading: isLoadingUser,
  } = useGetAdult(adultUserId);

  const {
    data: organizationData,
  } = useGetOrganization();

  const handleCompletedMutation = useCallback(() => {
    dispatch(addNotification('Permissions updated', 'success'));
    navigate(pathToShowPage);
  }, [dispatch, navigate, pathToShowPage]);

  const [
    updateUser,
    { loading: isSaving },
  ] = useUpdateUserPermissionsByDirector(
    {
      onCompleted: handleCompletedMutation,
      refetchQueries: () => ['Adult'],
      variables: {
        mayEditPermissions,
        permissionIds,
        userId: adultData?.user.id!,
      },
    },
  );

  useEffect(() => {
    setPermissions(adultData?.user.permissions || null);
    setMayEditPermissions(!!adultData?.user.mayEditPermissions);
  }, [adultData]);

  const selfAlert = useMemo(() => {
    if (!isSelf || !adultData?.user) {
      return null;
    }

    // Organization belongs to a district
    if (organizationData?.organization?.district?.admin) {
      return (
        <EnhancedAlert
          severity="warning"
          title="Cannot edit own permissions"
        >
          Send an{' '}
          <StyledLink
            href={`mailto:${organizationData?.organization?.district?.admin?.email}?subject=Request%20to%20update%20Presto%20Assistant%20permissions%20for%20${getFullName(adultData?.user)}`}
          >email
          </StyledLink> to your district administrator{' '}
          <strong>
            {getFullName(organizationData?.organization?.district?.admin)}
          </strong>
          {' '}to request updates to your permissions for {organizationData?.organization?.label}.
        </EnhancedAlert>
      );
    }

    // Organization has no associated district yet
    return (
      <>
        <EnhancedAlert
          severity="warning"
          title="Cannot edit own permissions"
        >
          Only your district administrator can update your
          permissions for {organizationData?.organization?.label}.
        </EnhancedAlert>

        <Box mt={3}>
          <InviteAdmin />
        </Box>
      </>
    );
  }, [isSelf, adultData, organizationData]);

  return (
    <Page
      backButtonProps={{
        label: `${context} Details`,
        path: pathToShowPage,
      }}
      error={error}
      isLoading={!adultData || isSaving || isLoadingUser}
    >
      <Container maxWidth={500}>
        {adultData?.user ? (
          <ShowCard
            canEdit
            icon={KeyIcon}
            title={`Edit Permissions for ${getFullName(adultData?.user)}`}
          >
            {selfAlert ?? (
              <PermissionEditPage
                isDirector={false}
                isLoading={isSaving}
                mayEditPermissions={mayEditPermissions}
                onSave={updateUser}
                onUpdateMayEditPermissions={setMayEditPermissions}
                onUpdatePermissionIds={setPermissionIds}
                organizationName={organizationData?.organization.label}
                permissionIds={permissionIds}
                permissions={permissions}
              />
            )}
          </ShowCard>
        ) : null}
      </Container>
    </Page>
  );
};

export default EditParentPermissions;
