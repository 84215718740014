// External Dependencies
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { pluralize } from 'utils';
import {
  useGetGroupsIndexAll,
  useGetReturningGroups,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import {
  EnhancedStyledLink,
  StyledStrong,
} from '../shared/styles';
import DialogReturningGroups from './DialogReturningGroups';

// Component Definition
const EndOfYearGroupsContent: FC = () => {
  const {
    isOpen: isDialogReturningGroupsOpen,
    toggleIsOpen,
  } = useIsOpen();

  const {
    data: groupsData,
    loading: isLoadingGroupsIndex,
  } = useGetGroupsIndexAll();

  const {
    data: returningGroupsData,
    loading: isLoadingReturningGroups,
  } = useGetReturningGroups();

  const returningGroupsCount = returningGroupsData?.returningGroups.length;

  const existingReturningGroups = useMemo(() =>
    returningGroupsData?.returningGroups?.map((groupData) => ({
      groupId: groupData.group.id,
      shouldRetainUsers: groupData.shouldRetainUsers,
    })), [returningGroupsData?.returningGroups]);

  return (
    <>
      <Box mb={2}>
        <Subtitle>Groups</Subtitle>

        <EnhancedCard>
          <CardContent>
            {(isLoadingReturningGroups || isLoadingGroupsIndex) ? (
              <Box
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography>
                  {groupsData?.groupsIndex.fullCount ? (
                    <>
                      There{' '}
                    </>
                  ) : (
                    <>
                      You have no groups for the current school year, so there{' '}
                    </>
                  )}
                  {pluralize(returningGroupsCount, 'is', 'are')}{' '}
                  <StyledStrong>{returningGroupsCount}</StyledStrong>{' '}
                  {pluralize(returningGroupsCount, 'group')} that will carry over to next school year.

                </Typography>

                {Boolean(groupsData?.groupsIndex.fullCount) && (
                  <Box
                    mb={1}
                    mt={3}
                  >
                    {(returningGroupsCount ?? 0) < 1 ? (
                      <Button
                        color="primary"
                        onClick={toggleIsOpen}
                        size="small"
                        variant="outlined"
                      >
                        Select Groups
                      </Button>
                    ) : (
                      <EnhancedStyledLink onClick={toggleIsOpen}>
                        View/Update Groups
                      </EnhancedStyledLink>
                    )}
                  </Box>
                )}
              </>
            )}
          </CardContent>
        </EnhancedCard>
      </Box>

      {!isLoadingReturningGroups && (
        <DialogReturningGroups
          existingReturningGroups={existingReturningGroups}
          isOpen={isDialogReturningGroupsOpen}
          onClose={toggleIsOpen}
        />
      )}
    </>
  );
};

export default EndOfYearGroupsContent;
