// External Dependencies
import { GET_SELF } from 'gql/queries';
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
interface AcceptTermsData {
  acceptTerms: {
    hasAcceptedTerms: boolean;
    id: string;
  }
}
interface AgreeToAcceptableUsePolicyResponse {
  agreeToAcceptableUsePolicy: GQL.ISelf;
}
interface CreateAuthEmailChangeRequestData {
  createAuthEmailChangeRequest: {
    id: string;
  }
}
interface UpdateAuthEmailData {
  updateAuthEmail: {
    id: string;
  }
}
interface DistrictAgreeToAcceptableUsePolicyResponse {
  districtAgreeToAcceptableUsePolicy: boolean;
}
interface UpdatePasswordData {
  updatePassword: {
    id: string;
  }
}
interface UpdatePasswordAfterAuthData {
  updatePasswordAfterAuth: {
    id: string;
  }
}

export const AGREE_TO_ACCEPTABLE_USE_POLICY = gql`
  mutation AgreeToAcceptableUsePolicy {
    agreeToAcceptableUsePolicy {
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

const CREATE_AUTH_EMAIL_CHANGE_REQUEST = gql`
  mutation CreateAuthEmailChangeRequest(
    $input: CreateAuthEmailChangeRequestInput!
  ) {
    createAuthEmailChangeRequest(
      input: $input
    )
  }
`;

const UPDATE_AUTH_EMAIL = gql`
  mutation UpdateAuthEmail(
    $input: UpdateAuthEmailInput!
  ) {
    updateAuthEmail(
      input: $input
    ) {
      id
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $input: UpdatePasswordInput!
  ) {
    updatePassword(
      input: $input
    ) {
        id
    }
  }
`;

export const UPDATE_PASSWORD_AFTER_AUTH = gql`
  mutation UpdatePasswordAfterAuth(
    $confirmPassword: String!
    $password: String!
  ) {
    updatePasswordAfterAuth(
      confirmPassword: $confirmPassword
      password: $password
    ) {
      id
    }
  }
`;

export const UPDATE_ONBOARDING_PROFILE = gql`
  mutation UpdateOnboardingProfile(
    $input: UpdateSelfInput!
  ) {
    updateOnboardingProfile(
      input: $input
    ) {
      currentOrgId
      firstName
      gender {
        id
        label
      }
      hasCompletedOnboarding
      hasDistrict
      lastName
      middleName
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
      otherGenderLabel
      primarySpokenLanguage {
        id
        label
      }
    }
  }
`;

export const UPDATE_ONBOARDING_CONTACT = gql`
  mutation UpdateOnboardingContact(
    $addressOne: String
    $addressTwo: String
    $city: String
    $email: String!
    $phoneNumber: String
    $stateId: ID
    $zipcode: String
  ) {
    updateSelf(
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      email: $email
      phoneNumber: $phoneNumber
      stateId: $stateId
      zipcode: $zipcode
    ) {
      currentOrgId
      hasCompletedOnboarding
      hasDistrict
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

export const UPDATE_ONBOARDING_INVITATIONS_VIEWED = gql`
  mutation UpdateOnboardingInvitationsViewed(
    $hasSeenInvitationPrompt: Boolean!
  ) {
    updateSelf(
      hasSeenInvitationPrompt: $hasSeenInvitationPrompt
    ) {
      currentOrgId
      hasCompletedOnboarding
      hasDistrict
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

export const UPDATE_AUTH_USER = gql`
  mutation UpdateAuthUser(
    $input: UpdateAuthUserInput!
  ) {
    updateAuthUser (
      input: $input
    ) {
      authUserEmail
      id
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail {
    resendVerificationEmail
  }
`;

export const UPDATE_SELF = gql`
  mutation UpdateSelf(
    $addressOne: String
    $addressTwo: String
    $city: String
    $email: String
    $emailSignature: String
    $firstName: String
    $lastName: String
    $middleName: String
    $phoneNumber: String
    $stateId: ID
    $zipcode: String
  ) {
    updateSelf(
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      email: $email
      emailSignature: $emailSignature
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      phoneNumber: $phoneNumber
      stateId: $stateId
      zipcode: $zipcode
    ) {
      id
      addressOne
      addressTwo
      city
      email
      emailSignature
      firstName
      lastName
      middleName
      phoneNumber
      state {
        id
        label
      }
      stateId
      zipcode
    }
  }
`;

const IGNORE_EOY_WARNING = gql`
  mutation IgnoreEoyWarning {
    completeOnboarding {
      hasCompletedOnboarding
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
    ignoreEoyWarning {
      hasCompletedOnboarding
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

const COMPLETE_ONBOARDING = gql`
  mutation CompleteOnboarding {
    completeOnboarding {
      hasCompletedOnboarding
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

const ACCEPT_TERMS = gql`
  mutation AcceptTerms(
    $input: AcceptTermsInput!
  ) {
    acceptTerms(input: $input) {
      hasAcceptedTerms
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

export const DISTRICT_AGREE_TO_ACCEPTABLE_USE_POLICY = gql`
  mutation DistrictAgreeToAcceptableUsePolicy {
    districtAgreeToAcceptableUsePolicy {
      id
      onboarding {
        isComplete
        onboardingRole
        stages {
          isComplete
          name
        }
      }
    }
  }
`;

const CLAIM_MEMBER = gql`
  mutation ClaimMember(
    $input: ClaimMemberInput!
  ) {
    claimMember(input: $input) {
      id
    }
  }
`;

const ONE_CLICK_CLAIM_MEMBER = gql`
  mutation OneClickClaimMember(
    $input: OneClickClaimMemberInput!
  ) {
    oneClickClaimMember(input: $input) {
      id
    }
  }
`;

const SKIP_SUCCESSOR_ORGANIZATION_ONBOARDING_STEP = gql`
  mutation SkipSuccessorOrganizationOnboardingStep {
    skipSuccessorOrganizationOnboardingStep {
      id
    }
  }
`;

export const useAcceptTerms = (
  options?: MutationOptions<
    AcceptTermsData,
    GQL.IAcceptTermsOnMutationArguments
  >,
) => useMutationEnhanced<
  AcceptTermsData,
  GQL.IAcceptTermsOnMutationArguments
>(
  ACCEPT_TERMS,
  options,
);

export const useCreateAuthEmailChangeRequest = (
  options?: MutationOptions<
    CreateAuthEmailChangeRequestData,
    GQL.ICreateAuthEmailChangeRequestOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateAuthEmailChangeRequestData,
  GQL.ICreateAuthEmailChangeRequestOnMutationArguments
>(
  CREATE_AUTH_EMAIL_CHANGE_REQUEST,
  options,
);

export const useUpdateAuthEmail = (
  options?: MutationOptions<
    UpdateAuthEmailData,
    GQL.IUpdateAuthEmailOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateAuthEmailData,
  GQL.IUpdateAuthEmailOnMutationArguments
>(
  UPDATE_AUTH_EMAIL,
  options,
);

export const useUpdatePassword = (
  options?: MutationOptions<
    UpdatePasswordData,
    GQL.IUpdatePasswordOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdatePasswordData,
  GQL.IUpdatePasswordOnMutationArguments
>(
  UPDATE_PASSWORD,
  options,
);

export const useUpdatePasswordAfterAuth = (
  options?: MutationOptions<
    UpdatePasswordAfterAuthData,
    GQL.IUpdatePasswordAfterAuthOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdatePasswordAfterAuthData,
  GQL.IUpdatePasswordAfterAuthOnMutationArguments
>(
  UPDATE_PASSWORD_AFTER_AUTH,
  options,
);

export const useIgnoreEoyWarning = () =>
  useMutationEnhanced(IGNORE_EOY_WARNING);

export const useCompleteOnboarding = () =>
  useMutationEnhanced(COMPLETE_ONBOARDING);

export const useAgreeToAcceptableUsePolicy = () =>
  useMutationEnhanced<AgreeToAcceptableUsePolicyResponse>(
    AGREE_TO_ACCEPTABLE_USE_POLICY,
  );

export const useDistrictAgreeToAcceptableUsePolicy = () =>
  useMutationEnhanced<DistrictAgreeToAcceptableUsePolicyResponse>(
    DISTRICT_AGREE_TO_ACCEPTABLE_USE_POLICY,
  );

export const useClaimMember = (
  options?: MutationOptions<
    { claimMember: { id: string } },
    GQL.IClaimMemberOnMutationArguments
  >,
) =>
  useMutationEnhanced<{ claimMember: { id: string } }, GQL.IClaimMemberOnMutationArguments>(
    CLAIM_MEMBER,
    {
      ...options,
      refetchQueries: [{ query: GET_SELF }],
    },
  );

export const useOneClickClaimMember = (
  options?: MutationOptions<
    { oneClickClaimMember: { id: string } },
    GQL.IOneClickClaimMemberOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { oneClickClaimMember: { id: string } },
    GQL.IOneClickClaimMemberOnMutationArguments
  >(
    ONE_CLICK_CLAIM_MEMBER,
    {
      ...options,
      refetchQueries: [{ query: GET_SELF }],
    },
  );

export const useUpdateAuthUser = (
  options?: MutationOptions<
    { updateAuthUser: GQL.ISelf },
    GQL.IUpdateAuthUserOnMutationArguments
    >,
) =>
  useMutationEnhanced<{ updateAuthUser: GQL.ISelf }, GQL.IUpdateAuthUserOnMutationArguments>(
    UPDATE_AUTH_USER,
    options,
  );

export const useSkipSuccessorOrganizationOnboardingStep = () =>
  useMutationEnhanced<{ skipSuccessorOrganizationOnboardingStep: GQL.ISelf }>(
    SKIP_SUCCESSOR_ORGANIZATION_ONBOARDING_STEP,
    {
      refetchQueries: [{ query: GET_SELF }],
    },
  );

export const useUpdateSelf = (
  options?: MutationOptions<
    { updateSelf: GQL.ISelf },
    GQL.IUpdateSelfOnMutationArguments
    >,
) =>
  useMutationEnhanced<{ updateSelf: GQL.ISelf }, GQL.IUpdateSelfOnMutationArguments>(
    UPDATE_SELF,
    options,
  );
