// External Dependencies
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

// Internal Dependencies
import { ConfirmationDialog, StyledLink } from 'components/shared';
import { PRIVACY_POLICY_HREF } from 'utils/constants';
import { useUpdateParentalConsent } from 'gql/mutations';
import DataInfo from 'pages/Onboarding/OnboardingAcceptableUseAgreement/DataInfo';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

// Local Variables
const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  maxHeight: 300,
  overflow: 'auto',
  padding: theme.spacing(1),
})) as typeof Paper;

// Component Definition
const GrantParentalConsentDialog = ({
  isOpen,
  onClose,
  userId,
}: Props): JSX.Element => {
  const [
    updateParentalConsent,
    {
      loading: isSubmitting,
    },
  ] = useUpdateParentalConsent({
    onCompleted: onClose,
  });

  const handleConfirm = () => {
    updateParentalConsent({
      variables: {
        input: {
          grantPermission: true,
          userId,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      confirmButtonAction={handleConfirm}
      confirmButtonText="Yes, grant consent"
      declineButtonAction={onClose}
      description={(
        <>
          <DialogContentText>
            Do you wish to grant consent for your child to use Presto Assistant?
            <br />
            Your child will be able to login and manage his/her own account.
          </DialogContentText>

          <StyledPaper variant="outlined">
            <DataInfo />
          </StyledPaper>

          <Box marginTop={2}>
            <DialogContentText>
              More information can be found in our{' '}
              <StyledLink
                href={PRIVACY_POLICY_HREF}
                openInNewTab
              >
                Privacy Policy
              </StyledLink>
            </DialogContentText>
          </Box>
        </>
      )}
      handleClose={onClose}
      isSubmitting={isSubmitting}
      maxWidth="md"
      open={isOpen}
      title="Grant consent?"
      useCustomText
    />
  );
};

export default GrantParentalConsentDialog;
