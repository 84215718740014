// External Dependencies
import { ComponentType, FC } from 'react';
import NumberFormat from 'react-number-format';

// Local Typings
interface Props {
  inputRef: (instance: ComponentType | null) => void;
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

// Component Definition
const MoneyInput: FC<Props> = ({
  inputRef, name, onChange, ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
    onValueChange={(values: any) => {
      onChange({
        target: {
          name,
          value: values.value,
        },
      });
    }}
  />
);

export default MoneyInput;
