// External Dependencies
import { FC, useCallback } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DataGridFilterCard from 'components/shared/DataGridFilterCard';
import SchoolYearDataGridFilter from 'components/shared/SchoolYearDataGridFilter';

// Local Dependencies
import FinancialItemsTable from './FinancialItemsTable';
import OnlinePaymentsAlert from '../../shared/OnlinePaymentsAlert';

// Component Definition
const FinancialItems: FC = () => {
  const orgCurrentSchoolYearEnding: number = useGetOrganizationSchoolYear();

  const search = useParsedSearch();
  const { schoolYearEnding = orgCurrentSchoolYearEnding } = search;

  const getPathOnFilterChange = useCallback((sye: number) => {
    const queryString = stringifyQueryParams({
      ...search,
      school_year_ending: sye,
    });

    return `/${PATHS.FINANCIAL_ITEMS}?${queryString}`;
  }, [search]);

  return (
    <>
      <OnlinePaymentsAlert />

      <Subtitle>
        Financial Items
      </Subtitle>

      <DataGridFilterCard>
        <SchoolYearDataGridFilter
          getPathOnFilterChange={getPathOnFilterChange}
          schoolYearEndingFromUrl={Number(schoolYearEnding)}
        />
      </DataGridFilterCard>

      <EnhancedCard>
        <FinancialItemsTable schoolYearEnding={Number(schoolYearEnding)} />
      </EnhancedCard>
    </>
  );
};

export default FinancialItems;
