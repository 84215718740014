// External Dependencies
import { FC } from 'react';
import { FormBlockTypesSemantic } from '@presto-assistant/api_types';

// Internal Dependencies
import { mapEnum } from 'utils/lib/map_enum';

// Local Dependencies
import CustomSelect, { CustomSelectProps } from '../CustomSelect';

// Local Typings
const options = mapEnum(FormBlockTypesSemantic);

// Component Definition
const FormBlockTypeSelect: FC<Partial<CustomSelectProps>> = (props) => {
  return (
    <CustomSelect
      label="Block Type"
      name="formBlockTypeId"
      options={options}
      {...props}
    />
  );
};

export default FormBlockTypeSelect;
