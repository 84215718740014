// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Local Typings
interface Props {
  children: any;
  className?: string;
  maxWidth?: number | string;
}
interface StyledRootProps {
  $maxWidth?: number | string;
}

// Local Variables
const StyledRoot = styled.div<StyledRootProps>(({ $maxWidth }) => ({
  margin: '0 auto',
  maxWidth: $maxWidth ?? 1000,

}));

// Component Definition
const Container: FC<Props> = ({
  children,
  className,
  maxWidth,
  ...otherProps
}) => (
  <StyledRoot
    $maxWidth={maxWidth}
    className={className}
    {...otherProps}
  >
    {children}
  </StyledRoot>
);

export default Container;
