// External Dependencies
import SvgIcon from '@mui/material/SvgIcon';
import styled, { useTheme } from 'styled-components';

// Local Variables
const StyledSvgIcon = styled(SvgIcon)({
  height: '100%',
  width: '90%',
});

// Component Definition
const PrestoLogoTextSvg = (props: any) => {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      htmlColor={theme.palette.logo}
      viewBox="0 0 383.81 32.27"
      {...props}
    >
      <g>
        <path
          d="M0,30.93V8.47C0,3.43,3.12,0,7.84,0S16,3.43,16,8.47V12.7C16,17.65,12.79,21,8,21H5.57v10c0,.54-.27.8-.76.8H.8A.71.71,0,0,1,0,30.93ZM7.62,15.82c1.78,0,2.72-1,2.72-2.94V8.07c0-1.74-.89-2.81-2.41-2.81S5.57,6.33,5.57,8.07v7.75Z"
        />
        <path
          d="M27.73,30.93V8.47c0-5,3.21-8.47,7.93-8.47s8.11,3.43,8.11,8.47v3.88a8.13,8.13,0,0,1-3.3,7L44.8,30.93c.13.49-.27.8-.76.8H39.72c-.49,0-.63-.31-.81-.75L35.44,20.77h-2.1V30.93c0,.54-.26.8-.75.8H28.53A.7.7,0,0,1,27.73,30.93Zm7.66-15.29c1.83,0,2.77-1.11,2.77-3.12V8.07c0-1.74-.94-2.81-2.45-2.81s-2.37,1.07-2.37,2.81v7.57Z"
        />
        <path
          d="M55.77,23.53V8.69c0-4.95,3.21-8.33,8-8.33h4.68a.71.71,0,0,1,.8.75V4.68a.73.73,0,0,1-.8.8h-4c-1.92,0-3.08,1.34-3.08,3.39V13.5h6a.73.73,0,0,1,.8.81v3.38a.71.71,0,0,1-.8.81h-6v4.9c0,2.05,1.16,3.39,3.08,3.39h4a.71.71,0,0,1,.8.75v3.61a.71.71,0,0,1-.8.76H63.79C59,31.91,55.77,28.53,55.77,23.53Z"
        />
        <path
          d="M80.24,23c0-.54.27-.8.76-.8h4a.73.73,0,0,1,.8.75c.09,2.77,1,4.1,2.63,4.1s2.36-1,2.36-2.58c0-6.15-10.29-8.74-10.29-17.12C80.51,2.76,83.58,0,88.13,0S95.8,3.34,96,8.87c0,.53-.26.8-.75.8h-4a.73.73,0,0,1-.8-.8c-.09-2.54-.85-3.7-2.23-3.7A2,2,0,0,0,86,7.35c0,5.49,10.39,8.12,10.39,17.12,0,4.81-3.25,7.8-8.07,7.8S80.42,28.75,80.24,23Z"
        />
        <path
          d="M122.36,1.29V4.9c0,.49-.26.76-.8.76h-4.23V30.93c0,.54-.27.8-.76.8h-4c-.49,0-.76-.26-.76-.8V5.66h-4.28a.69.69,0,0,1-.76-.76V1.29a.69.69,0,0,1,.76-.76h14C122.1.53,122.36.8,122.36,1.29Z"
        />
        <path
          d="M132.49,23.71V8.56c0-5.13,3.34-8.52,8.2-8.52s8.15,3.39,8.15,8.38V23.85c0,5-3.3,8.37-8.15,8.37S132.49,28.84,132.49,23.71Zm10.74.54V8a2.52,2.52,0,0,0-2.54-2.81c-1.56,0-2.59,1.12-2.59,3v16c0,1.83,1,2.9,2.59,2.9A2.47,2.47,0,0,0,143.23,24.25Z"
        />
        <path
          d="M162.17,31.29V8.16c0-4.82,3-8,7.36-8s7.35,3.21,7.35,8V31.29a.41.41,0,0,1-.44.44h-1.79a.41.41,0,0,1-.44-.44V20.41h-9.36V31.29a.42.42,0,0,1-.45.44h-1.78A.42.42,0,0,1,162.17,31.29Zm12-13.37V8.16c0-3.48-1.7-5.53-4.68-5.53s-4.68,2.05-4.68,5.53v9.76Z"
        />
        <path
          d="M190.17,23.71a.41.41,0,0,1,.44-.44h1.79a.41.41,0,0,1,.44.44c.14,3.92,1.78,6,4.82,6s4.72-1.6,4.72-4.68c0-7.62-12-9.31-12-18,0-4.15,2.85-6.91,7.18-6.91,4.05,0,6.86,3.3,7.08,8.24a.42.42,0,0,1-.44.45h-1.79a.42.42,0,0,1-.44-.45c-.18-3.92-1.74-5.75-4.41-5.75-2.95,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.39,7.18S190.3,28.79,190.17,23.71Z"
        />
        <path
          d="M217.18,23.71a.42.42,0,0,1,.45-.44h1.78a.42.42,0,0,1,.45.44c.13,3.92,1.78,6,4.81,6s4.72-1.6,4.72-4.68c0-7.62-12-9.31-12-18,0-4.15,2.86-6.91,7.18-6.91,4.06,0,6.86,3.3,7.09,8.24a.43.43,0,0,1-.45.45h-1.78a.43.43,0,0,1-.45-.45c-.18-3.92-1.74-5.75-4.41-5.75-2.94,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.4,7.18S217.32,28.79,217.18,23.71Z"
        />
        <path
          d="M246.16.53h1.78a.42.42,0,0,1,.44.45V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V1A.43.43,0,0,1,246.16.53Z"
        />
        <path
          d="M262,23.71a.41.41,0,0,1,.44-.44h1.78a.42.42,0,0,1,.45.44c.13,3.92,1.78,6,4.81,6s4.73-1.6,4.73-4.68c0-7.62-12-9.31-12-18,0-4.15,2.85-6.91,7.17-6.91,4.06,0,6.87,3.3,7.09,8.24a.42.42,0,0,1-.44.45h-1.79a.42.42,0,0,1-.44-.45c-.18-3.92-1.74-5.75-4.42-5.75-2.94,0-4.5,1.52-4.5,4.42,0,7.17,12,8.82,12,18,0,4.33-2.94,7.18-7.4,7.18S262.16,28.79,262,23.71Z"
        />
        <path
          d="M302.1,1v1.6a.43.43,0,0,1-.45.45H296.3V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V3h-5.35a.42.42,0,0,1-.44-.45V1a.42.42,0,0,1,.44-.45h13.37A.43.43,0,0,1,302.1,1Z"
        />
        <path
          d="M314.45,31.29V8.16c0-4.82,2.94-8,7.35-8s7.36,3.21,7.36,8V31.29a.42.42,0,0,1-.45.44h-1.78a.42.42,0,0,1-.45-.44V20.41h-9.36V31.29a.41.41,0,0,1-.44.44h-1.79A.41.41,0,0,1,314.45,31.29Zm12-13.37V8.16c0-3.48-1.69-5.53-4.68-5.53s-4.68,2.05-4.68,5.53v9.76Z"
        />
        <path
          d="M343.6,31.29V8.11c0-4.68,2.9-8,7.36-8s7.35,3.34,7.35,8V31.29a.42.42,0,0,1-.45.44h-1.78a.41.41,0,0,1-.44-.44V8.11c0-3.3-1.83-5.53-4.68-5.53s-4.68,2.23-4.68,5.53V31.29a.42.42,0,0,1-.45.44h-1.78A.42.42,0,0,1,343.6,31.29Z"
        />
        <path
          d="M383.81,1v1.6a.43.43,0,0,1-.45.45H378V31.29a.41.41,0,0,1-.44.44h-1.78a.42.42,0,0,1-.45-.44V3H370a.42.42,0,0,1-.44-.45V1A.42.42,0,0,1,370,.53h13.37A.43.43,0,0,1,383.81,1Z"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default PrestoLogoTextSvg;
