// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Form, Formik } from 'formik';
import { VancoPcctPayload } from '@presto-assistant/api_types/api/v1/organization';
import { setVancoPcctSchema } from '@presto-assistant/api_types/schemas/organization';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { CustomInput, EnhancedAlert, SaveButton } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { getErrorMessage } from 'utils/api';
import { useIsOpen } from 'hooks/useIsOpen';
import { useSetVancoPcct } from 'utils/api/organization';

// Component Definition
const SyncVancoButton: FC = () => {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    isOpen: isSyncVancoDialogOpen,
    toggleIsOpen: toggleIsSyncVancoDialogOpen,
  } = useIsOpen();

  useEffect(() => {
    if (isSyncVancoDialogOpen) {
      setErrorMessage(null);
    }
  }, [isSyncVancoDialogOpen]);

  const initialValues: VancoPcctPayload = {
    vancoAccountCode: '',
    vancoPcct: '',
  };

  const {
    isLoading: isSettingVancoPcct,
    mutate: setVancoPcct,
  } = useSetVancoPcct({
    suppressErrorNotification: true,
  });

  const handleSuccess = useCallback(() => {
    toggleIsSyncVancoDialogOpen();

    dispatch(addNotification('Vanco/RevTrak synced successfully!', 'success'));
  }, [dispatch, toggleIsSyncVancoDialogOpen]);

  const handleFormikSubmit = useCallback((values: VancoPcctPayload) => {
    setErrorMessage(null);

    setVancoPcct({
      vancoAccountCode: values.vancoAccountCode?.trim() ?? '',
      vancoPcct: values.vancoPcct?.trim() ?? '',
    }, {
      onError: (error) => {
        setErrorMessage(getErrorMessage(error));
      },
      onSuccess: () => {
        handleSuccess();
      },
    });
  }, [handleSuccess, setVancoPcct]);

  return (
    <>
      <Button
        onClick={toggleIsSyncVancoDialogOpen}
        variant="outlined"
      >
        Sync
      </Button>

      <Dialog open={isSyncVancoDialogOpen}>
        <DialogTitle>
          Sync Vanco
        </DialogTitle>

        <Formik<VancoPcctPayload>
          initialValues={initialValues}
          onSubmit={handleFormikSubmit}
          validationSchema={setVancoPcctSchema}
        >
          {({
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <EnhancedAlert
                  severity="info"
                  sx={{ marginBottom: 2 }}
                >
                  If you are unsure of your PCCT or Account Code, please contact Vanco.
                </EnhancedAlert>

                <CustomInput
                  label="Vanco PCCT"
                  name="vancoPcct"
                />

                <CustomInput
                  label="Vanco Account Code"
                  name="vancoAccountCode"
                />

                <Collapse in={Boolean(errorMessage)}>
                  <EnhancedAlert severity="error">
                    {errorMessage}
                  </EnhancedAlert>
                </Collapse>
              </DialogContent>

              <DialogActions>
                <Button onClick={toggleIsSyncVancoDialogOpen}>
                  Cancel
                </Button>

                <SaveButton isSaving={isSettingVancoPcct}>
                  Sync
                </SaveButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default SyncVancoButton;
