// External Dependencies
import {
  FC, useCallback,
} from 'react';
import { Formik, FormikHelpers } from 'formik';
import { createFinancialCreditTransferSchema } from '@presto-assistant/api_types/schemas/financialCredits';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
} from 'components/shared';
import { convertDollarsToCents } from 'utils';

// Local Dependencies
import CreditTransferFormFormikForm from './CreditTransferFormForkikForm';

// Local Typings
interface Props {
  financialCredit: GQL.IFinancialCredit;
  onSubmit: (values: GQL.ICreateFinancialCreditTransferInput) => void;
}

// Component Definition
const CreditTransferForm: FC<Props> = ({
  financialCredit,
  onSubmit,
}) => {
  const initialValues: GQL.ICreateFinancialCreditTransferInput = {
    amountInCents: 0,
    note: '',
    originalCreditId: financialCredit.id,
    userId: '',
  };

  const handleFormikSubmit = useCallback(async (
    values: GQL.ICreateFinancialCreditTransferInput,
    formikProps: FormikHelpers<GQL.ICreateFinancialCreditTransferInput>,
  ) => {
    const { setSubmitting } = formikProps;

    // User input of amount is in dollars
    const updatedValues = {
      ...values,
      amountInCents: convertDollarsToCents(values.amountInCents),
    };

    // We use `amount` to keep it simple, but the API expects `amountInCents`
    delete (updatedValues as any).amount;

    await onSubmit?.(updatedValues);

    setSubmitting(false);
  }, [onSubmit]);

  return (
    <Container maxWidth={CONTAINER_WIDTH}>
      <Formik<GQL.ICreateFinancialCreditTransferInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={createFinancialCreditTransferSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          validateForm,
          values: formikValues,
        }) => (
          <CreditTransferFormFormikForm
            financialCredit={financialCredit}
            formikValues={formikValues}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            touched={touched}
            validateForm={validateForm}
          />
        )}
      </Formik>
    </Container>
  );
};

export default CreditTransferForm;
