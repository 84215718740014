// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared/index';
import { open } from 'state/ui/organizationLogoUploadDialog/actions';
import { useDeleteOrganizationLogo } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import ActionsIconMenu from 'components/shared/ActionsIconMenu';

// Component Definition
const OrganizationLogoMenuItems: FC = () => {
  const dispatch = useDispatch();

  const {
    isOpen: isDeleteConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsDeleteConfirmationDialogOpen,
  } = useIsOpen();

  const handleOpenDialog = useCallback(() => {
    dispatch(open());
  }, [open]);

  const [
    deleteOrganizationLogo,
    {
      loading: isDeleting,
    }] = useDeleteOrganizationLogo();

  return (
    <>
      <ActionsIconMenu
        moreActions={[
          {
            action: handleOpenDialog,
            icon: <EditIcon />,
            text: 'Change logo',
          },
          {
            action: handleToggleIsDeleteConfirmationDialogOpen,
            icon: <DeleteIcon />,
            text: 'Delete logo',
          },
        ]}
        tooltipTitle="Edit logo actions"
      />

      <ConfirmationDialog
        confirmButtonText="Yes, delete"
        confirmButtonAction={deleteOrganizationLogo}
        declineButtonAction={handleToggleIsDeleteConfirmationDialogOpen}
        description="Do you want to delete your organization logo?"
        handleClose={handleToggleIsDeleteConfirmationDialogOpen}
        isSubmitting={isDeleting}
        open={isDeleteConfirmationDialogOpen}
        title="Delete logo"
      />
    </>
  );
};

export default OrganizationLogoMenuItems;
