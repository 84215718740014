// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const close = createActionCreator(
  'CLOSE_STUDENT_DIALOG',
);

export const open = createActionCreator('OPEN_EDIT_STUDENT_DIALOG');

export const resetForm = createActionCreator(
  'RESET_STUDENT_DIALOG_FORM',
);

export const setCurrentStep = createActionCreator(
  'SET_STUDENT_DIALOG_CURRENT_STEP',
  (resolve) => (step: number) => resolve(step),
);

export const updateCompletedSteps = createActionCreator(
  'UPDATE_STUDENT_DIALOG_COMPLETED_STEPS',
  (resolve) => (step: number) => resolve(step),
);

export const updateForm = createActionCreator(
  'UPDATE_STUDENT_DIALOG_FORM',
  (resolve) => (values: any) => resolve(values),
);

export const updateFormErrors = createActionCreator(
  'UPDATE_STUDENT_DIALOG_FORM_ERRORS',
  (resolve) => (values: any) => resolve(values),
);
