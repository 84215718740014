// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  close,
  open,
} from './actions';

// Local Typings
export interface PaymentAlertDialogState {
  hasNoFeesToPayOnline: boolean;
  isOpen: boolean;
}

interface HasNoFeesToPayOnlineAction {
  payload: boolean;
  type: string;
}

// Reducers
const hasNoFeesToPayOnline = createReducer(true, (handleAction) => [
  handleAction(
    logoutCurrentUser,
    () => true,
  ),
  handleAction(
    open,
    (_state, { payload }: HasNoFeesToPayOnlineAction) => payload,
  ),
]);

const isOpen = createReducer(false, (handleAction) => [
  handleAction([
    close,
    logoutCurrentUser,
  ], () => false),
  handleAction(open, () => true),
]);

export default combineReducers<PaymentAlertDialogState>({
  hasNoFeesToPayOnline,
  isOpen,
});
