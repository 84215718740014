// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { TableDataGrid } from 'components/shared';
import { useMyGroups } from 'utils/api/groups';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';

// Component Definition
const MyGroupsTable: FC = () => {
  const {
    data,
    isLoading,
  } = useMyGroups();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        columns={columns}
        loading={isLoading}
        rows={data?.data?.data ?? null}
        tableResource="myGroups"
        withSearch
        zeroStateMessage="You are not a member of any groups."
      />
    </DataGridContainer>
  );
};

export default MyGroupsTable;
