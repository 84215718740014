// External Dependencies
import { FileWithPath, useDropzone } from 'react-dropzone';
import {
  useCallback, useMemo, useState,
} from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import DescriptionIcon from '@mui/icons-material/Description';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared/index';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { useFlatFileTypes } from 'hooks/useFlatFileTypes';

// Local Typings
interface Props {
  acceptedFileTypes?: string[];
  disabled?: boolean;
  handleDrop: ((acceptedFiles: File[]) => void) | null;
  helperText?: string;
  marginBottom?: number;
  marginTop?: number;
  maxSizeInMb?: number;
  multiple?: boolean;
  preventFileDialogOpen?: boolean;
  rejectedDropErrorMessage: string;
  showAcceptedFiles?: boolean;
  useMaterialV3?: boolean;
}

// Local Variables
const StyledSection = styled.section<{
  marginBottom: number;
  marginTop: number;
}>(({
  marginBottom,
  marginTop,
  theme,
}) => ({
  '&.disabledDropzone': {
    backgroundColor: 'rgba(150, 150, 150, 0.1)', // a disabled color that should work for any theme
  },
  '&.dropzoneHover': {
    backgroundColor: theme.palette.dropzoneHover,
    border: `3px dashed ${theme.palette.dropzoneBorder}`,
  },
  '&.materialV3': {
    borderRadius: 12,
  },
  '.icon': {
    height: 144,
    width: 144,
  },
  border: `3px dashed ${theme.palette.divider}`,
  borderRadius: 3,
  marginBottom: theme.spacing(marginBottom),
  marginTop: theme.spacing(marginTop),
  textAlign: 'center',
}));

const noOp = () => {
  // do nothing
};

const minSize = 1;

// Component Definition
const UploadDropzone = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  disabled,
  handleDrop,
  helperText,
  marginBottom = 0,
  marginTop = 3,
  maxSizeInMb,
  multiple = false,
  preventFileDialogOpen = false,
  rejectedDropErrorMessage,
  showAcceptedFiles = false,
  useMaterialV3 = false,
}: Props): JSX.Element => {
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (handleDrop) {
        handleDrop(acceptedFiles);
      }
    },
    [handleDrop],
  );

  const acceptedFileTypesArray = useFlatFileTypes(acceptedFileTypes);

  const shouldAcceptAll = useMemo(
    () => acceptedFileTypesArray.every((type) => type === '*'),
    [acceptedFileTypesArray],
  );

  const maxSize = useMemo(
    () => (maxSizeInMb ? maxSizeInMb * 1024 * 1024 : undefined),
    [maxSizeInMb],
  );

  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: shouldAcceptAll ? undefined : acceptedFileTypesArray,
    maxSize,
    minSize,
    multiple,
    noClick: preventFileDialogOpen,
    noDrag: preventFileDialogOpen,
    noKeyboard: preventFileDialogOpen,
    onDrop,
    onDropAccepted: () => setErrorMessage(''),
    onDropRejected: (err, arg) => {
      console.log('rej err : ', err);
      console.log('rej arg : ', arg);

      if (maxSize && err.some((file) => file.size > maxSize)) {
        setErrorMessage(`File size must be less than ${maxSizeInMb}MB`);
      } else if (err.some((file) => file.size < minSize)) {
        setErrorMessage('Please provide a file with content');
      } else {
        setErrorMessage(rejectedDropErrorMessage);
      }
    },
  });

  const rootProps = getRootProps({
    onChange: noOp,
    onClick: noOp,
    tabIndex: -1,
  });

  const files = acceptedFiles.map((file: FileWithPath) => (
    <ListItem key={file.path}>
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>

      <ListItemText primary={file.path} />
    </ListItem>
  ));

  const hasAcceptedFiles = Boolean(files.length);

  return (
    <>
      <StyledSection
        {...rootProps}
        className={clsx(
          isDragActive && 'dropzoneHover',
          disabled && 'disabledDropzone',
          useMaterialV3 && 'materialV3',
        )}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        <input
          {...getInputProps({
            accept: acceptedFileTypes.join(', '),
            disabled,
          })}
        />

        <CloudUploadIcon
          className="icon"
          htmlColor={theme.palette.divider}
        />

        <Typography
          color={disabled ? theme.palette.grey[500] : theme.palette.grey[600]}
          fontSize={18}
          fontWeight={400}
          gutterBottom
        >
          {isDragActive ? 'Drop your file here' : 'Drop file or click to upload'}
        </Typography>

        {helperText && (
          <Typography
            color="textSecondary"
            fontSize="small"
            gutterBottom
          >
            {helperText}
          </Typography>
        )}
      </StyledSection>

      <Collapse in={showAcceptedFiles && hasAcceptedFiles}>
        <Typography
          color="textSecondary"
          fontSize="small"
          gutterBottom
          sx={{
            marginTop: 4,
          }}
        >
          Accepted File{files.length > 1 && 's'}:
        </Typography>

        <List>
          {files}
        </List>
      </Collapse>

      <Collapse in={Boolean(errorMessage)}>
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 1 }}
        >
          {errorMessage}
        </EnhancedAlert>
      </Collapse>
    </>
  );
};

export default UploadDropzone;
