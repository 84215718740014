// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  close,
  open,
} from './actions';

// Local Typings
export interface StudentFileUploadDialogState {
  isOpen: boolean;
}

// Reducers
const isOpen = createReducer(false, (handleAction) => [
  handleAction([close, logoutCurrentUser], () => false),
  handleAction(open, () => true),
]);

export default combineReducers<StudentFileUploadDialogState>({
  isOpen,
});
