// External Dependencies
import { InventoryAuditAbsenceReasons } from '@presto-assistant/api_types';
import { InventoryIndexResponseItem } from '@presto-assistant/api_types/api/v1/inventory';
import {
  ListInventoryAuditAssessmentsByInventoryIdResponse,
} from '@presto-assistant/api_types/api/v1/inventoryAudit';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { useGetDistrictOrganizations, useGetOrganization } from 'gql/queries';

export const useColumns = ({
  extraColumns,
  inventoryAuditAssessmentData,
  inventoryAuditCreatedAt,
  isComplete,
  onClickAssessment,
}: {
  extraColumns?: DataGridColDef<InventoryIndexResponseItem>[];
  inventoryAuditAssessmentData: ListInventoryAuditAssessmentsByInventoryIdResponse['data'];
  inventoryAuditCreatedAt: string;
  isComplete: boolean;
  onClickAssessment: (args: {
    inventoryItemId: string;
    isPresent: boolean;
  }) => void;
}) => {
  const {
    data,
  } = useGetDistrictOrganizations();

  const {
    data: organizationData,
  } = useGetOrganization();

  const handleClickButtonGroup = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return useMemo(() => {
    const organizations = data?.districtOrganizations ?? [];
    const myOrgId = organizationData?.organization?.id;

    const isNewItem = (row: InventoryIndexResponseItem) => row.createdAt > inventoryAuditCreatedAt;

    const columns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        field: 'Audit Status' as any,
        headerName: 'Audit Status',
        minWidth: 200,
        renderCell: (params) => {
          if (params.row.onLoanToOrganizationId === myOrgId) {
            return (
              <Chip label="On Loan" />
            );
          }

          if (isNewItem(params.row)) {
            return (
              <Chip label="New Item" />
            );
          }

          return (
            <ButtonGroup
              disabled={isComplete}
              onClick={handleClickButtonGroup}
              size="small"
            >
              <Button
                onClick={() => onClickAssessment({
                  inventoryItemId: params.row.id,
                  isPresent: false,
                })}
                sx={{ borderRadius: 8 }}
                variant={params.value === 'Absent' ? 'contained' : 'outlined'}
              >
                Absent
              </Button>
              <Button
                onClick={() => onClickAssessment({
                  inventoryItemId: params.row.id,
                  isPresent: true,
                })}
                sx={{ borderRadius: 8 }}
                variant={params.value === 'Present' ? 'contained' : 'outlined'}
              >
                Present
              </Button>
            </ButtonGroup>
          );
        },
        type: 'singleSelect',
        valueGetter: (params) => {
          const datum = inventoryAuditAssessmentData[params.row.id];

          if (params.row.onLoanToOrganizationId === myOrgId) {
            return 'On Loan';
          }

          if (params.row.createdAt > inventoryAuditCreatedAt) {
            return 'New Item';
          }

          if (!datum) {
            return 'Not Audited';
          }

          const isPresent = datum.absenceReasonId === null;

          return isPresent ? 'Present' : 'Absent';
        },
        valueOptions: [
          {
            label: 'Absent',
            value: 'Absent',
          },
          {
            label: 'Present',
            value: 'Present',
          },
          {
            label: 'Not Audited',
            value: 'Not Audited',
          },
          {
            label: 'New Item',
            value: 'New Item',
          },
          {
            label: 'On Loan',
            value: 'On Loan',
          },
        ],
        width: 160,
      },
      {
        field: 'absenceReasonId' as keyof InventoryIndexResponseItem,
        headerName: 'Absence Reason',
        renderCell: (params) => {
          const datum = inventoryAuditAssessmentData[params.row.id];

          if (!datum?.absenceReasonId) {
            return null;
          }

          return toTitleCase(InventoryAuditAbsenceReasons[datum.absenceReasonId]);
        },
        valueGetter: (params) => {
          const datum = inventoryAuditAssessmentData[params.row.id];

          if (!datum) {
            return null;
          }

          return datum.absenceReasonId;
        },
      },
      {
        field: 'areChangesRequested' as keyof InventoryIndexResponseItem,
        headerName: 'Changes Requested',
        minWidth: 150,
        type: 'boolean',
        valueGetter: (params) => {
          const datum = inventoryAuditAssessmentData[params.row.id];

          if (!datum) {
            return null;
          }

          return datum.areChangesRequested;
        },
      },
      {
        field: 'isTransferRequested' as keyof InventoryIndexResponseItem,
        headerName: 'Transfer Requested',
        minWidth: 150,
        type: 'boolean',
        valueGetter: (params) => {
          const datum = inventoryAuditAssessmentData[params.row.id];

          if (!datum) {
            return null;
          }

          return datum.isTransferRequested;
        },
      },
      {
        field: 'categoryLabel',
        headerName: 'Category',
        width: 292,
      },
      {
        field: 'label',
        headerName: 'Name',
        width: 160,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        width: 292,
      },
      {
        field: 'model',
        headerName: 'Model',
        width: 292,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial #',
        width: 160,
      },
      {
        field: 'customBarcode',
        headerName: 'Barcode',
        width: 292,
      },
      {
        field: 'conditionLabel',
        headerName: 'Condition',
        width: 160,
      },
      {
        field: 'isMissing',
        headerName: 'Is Missing',
        type: 'boolean',
        width: 80,
      },
      {
        field: 'checkedOutTo',
        headerName: 'Checked Out To',
        minWidth: 200,
        valueGetter: ((params) => {
          // This handles the case where a student does not have an email address
          // ex John Smith () will become John Smith
          return params.row.checkedOutTo?.replaceAll('()', '').trim();
        }),
      },
      ...(organizations.length > 1 ? [{
        field: 'onLoanToOrganizationLabel',
        headerName: 'On Loan To',
        type: 'singleSelect',
        valueOptions: organizations
          .map((o) => ({
            label: o.label,
            value: o.label,
          })) ?? [],
      }] : []) as DataGridColDef<InventoryIndexResponseItem>[],
      {
        field: 'caseNumber',
        headerName: 'Case #',
        width: 160,
      },
      {
        field: 'location',
        headerName: 'Location',
        width: 160,
      },
      {
        field: 'accessories',
        headerName: 'Accessories',
        width: 160,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        width: 160,
      },
      {
        field: 'combination',
        headerName: 'Combination',
        width: 160,
      },
      {
        field: 'itemNumber',
        headerName: 'Item Number',
        width: 160,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    data?.districtOrganizations,
    extraColumns,
    handleClickButtonGroup,
    inventoryAuditAssessmentData,
    inventoryAuditCreatedAt,
    isComplete,
    onClickAssessment,
    organizationData?.organization,
  ]);
};
