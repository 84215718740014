// External Dependencies
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useUpdateAdmin } from 'utils/api/members';

interface Props {
  isOpen: boolean;
  memberId?: string;
  onClose: () => void;
  returnToOnSuccess: string;
}

const SwitchDirectorToParentDialog = ({
  isOpen,
  memberId,
  onClose,
  returnToOnSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, mutate: updateAdminUserMutation } = useUpdateAdmin();

  const handleConfirmSwitchRole = () => {
    if (memberId) {
      updateAdminUserMutation({
        body: {
          admin: false,
        },
        params: {
          id: memberId,
        },
      }, {
        onSuccess: () => {
          dispatch(updateIsPaginatedListDataLoaded({
            isPaginatedListDataLoaded: false,
          }));

          navigate(returnToOnSuccess);
        },
      });
    }
  };

  return (
    <ConfirmationDialog
      canBeUndone
      confirmButtonAction={handleConfirmSwitchRole}
      confirmButtonText="Yes, Switch"
      declineButtonAction={onClose}
      description={(
        <DialogContentText component="div">
          <Typography paragraph>
            Are you sure you want to switch this member&apos;s role from{' '}
            <strong>director</strong> to <strong>parent</strong>?
          </Typography>

          <Typography>
            This action will retain all of the member&apos;s current permission
            levels but restrict them from viewing organization-level settings.
          </Typography>
        </DialogContentText>
      )}
      handleClose={onClose}
      isSubmitting={isLoading}
      open={isOpen}
      title="Switch Director to Parent?"
      useCustomText
    />
  );
};

export default SwitchDirectorToParentDialog;
