// External Dependencies
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  InventoryRepairIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventoryRepair';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { currencyFromCentsColumn, dateColumn } from 'utils/lib/tableColumns';
import { useGetInventoryItemRepairList } from 'utils/api/inventoryRepair';

// Local Typings
interface Props {
  inventoryItemId: string;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.data-grid-container': {
    '& .MuiDataGrid-columnHeader': {
      fontWeight: 500,
    },

    margin: theme.spacing(1),
  },
  '.repair-history-data-grid': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  marginTop: theme.spacing(2),
}));

const NoRowsOverlay = (): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <Typography
      color="textSecondary"
      variant="body2"
    >
      This item has no repair history.
    </Typography>
  </Box>
);

const columns: GridColDef<InventoryRepairIndexResponseItem>[] = [
  dateColumn({
    field: 'sentDate',
    headerName: 'Sent Date',
  }),
  {
    field: 'serviceVendorLabel',
    flex: 1,
    headerName: 'Service Vendor',
    minWidth: 180,
  },
  {
    field: 'comments',
    flex: 1,
    headerName: 'Comments',
    minWidth: 96,
  },
  dateColumn({
    field: 'estimatedReturnDate',
    headerName: 'Estimated Return Date',
  }),
  dateColumn({
    field: 'actualReturnDate',
    headerName: 'Actual Return Date',
  }),
  {
    field: 'priority',
    flex: 1,
    headerName: 'Priority',
    minWidth: 64,
    type: 'number',
  },
  currencyFromCentsColumn({
    field: 'estimatedCostInCents',
    headerName: 'Estimated Cost',
    minWidth: 160,
  }),
  currencyFromCentsColumn({
    field: 'actualCostInCents',
    headerName: 'Actual Cost',
    minWidth: 160,
  }),
];

// Component Definition
const InventoryRepairList = ({
  inventoryItemId,
}: Props): JSX.Element => {
  const {
    data: inventoryRepairData,
  } = useGetInventoryItemRepairList(inventoryItemId);

  const hasRepairData = Boolean(inventoryRepairData?.data.data.length);

  const dataGridElement = (
    <DataGridPro
      autoHeight
      className="repair-history-data-grid"
      columns={columns}
      disableRowSelectionOnClick
      hideFooter={!hasRepairData}
      rows={inventoryRepairData?.data.data ?? []}
      slots={{
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );

  const childElement = !inventoryRepairData
    ? <CircularProgress />
    : dataGridElement;

  return (
    <StyledRoot>
      <div className="data-grid-container">
        {childElement}
      </div>
    </StyledRoot>
  );
};

export default InventoryRepairList;
