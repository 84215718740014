// External Dependencies
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import List from '@mui/material/List';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { EnhancedCard, ListItemWithSecondaryAction } from 'components/shared';
import { open as openUpdatePasswordDialog } from 'state/ui/updatePasswordDialog/actions';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import DialogEditUsername from './DialogEditUsername';
import UpdatePasswordDialog from './UpdatePasswordDialog';

// Local Typings
interface Props {
  user: GQL.ISelf | null;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiListItemSecondaryAction-root': {
    // This will prevent the list item text from hitting the
    //  secondary action on tiny screens
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'initial',
      right: 'unset',
      top: 'unset',
      transform: 'none',
    },
  },
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  '.listItem': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const LoginInfoCard = ({ user }: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const {
    handleClose: handleCloseDialogEditUsername,
    handleOpen: handleOpenDialogEditUsername,
    isOpen: isDialogEditUsernameOpen,
  } = useIsOpen();

  const handleOpenUpdatePasswordDialog = () => {
    dispatch(openUpdatePasswordDialog());
  };

  if (!user) {
    return null;
  }

  const { authUserEmail } = user;

  return (
    <>
      <StyledEnhancedCard>
        <CardHeader
          avatar={(
            <Avatar className="avatar">
              <LockIcon aria-label="Login info settings" />
            </Avatar>
          )}
          subheader={`Your ${APP_NAME} login information`}
          title={(
            <Typography
              component="h2"
              variant="h6"
            >
              Login Info
            </Typography>
          )}
        />

        <CardContent>
          <Typography
            className="subtitle"
            component="h6"
          >
            Login Info
          </Typography>

          <List>
            <ListItemWithSecondaryAction
              primaryText="Username"
              secondaryAction={{
                buttonIcon: <EditIcon />,
                buttonText: 'Change Username',
                onClick: handleOpenDialogEditUsername,
                onlyShowIconOnMobile: true,
              }}
              secondaryText={authUserEmail}
            />

            <ListItemWithSecondaryAction
              primaryText="Password"
              secondaryAction={{
                buttonIcon: <EditIcon />,
                buttonText: 'Change Password',
                onClick: handleOpenUpdatePasswordDialog,
                onlyShowIconOnMobile: true,
              }}
              secondaryText="************"
            />
          </List>
        </CardContent>
      </StyledEnhancedCard>

      <DialogEditUsername
        isOpen={isDialogEditUsernameOpen}
        onClose={handleCloseDialogEditUsername}
      />

      <UpdatePasswordDialog />
    </>
  );
};

export default LoginInfoCard;
