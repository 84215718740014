// External Dependencies
import { FC, useEffect, useState } from 'react';

// Inernal Dependencies
import { GET_USER_RELATIONSHIPS } from 'gql/queries';
import { RESPOND_TO_RELATIONSHIP_REQUEST } from 'gql/mutations';
import { useMutationEnhanced } from 'utils/lib/graphql';
import ConfirmInvitationList, { InvitationListItem } from 'components/shared/ConfirmInvitationList';

// Local Typings
interface Props {
  relationshipData: GQL.IUserRelationship[];
  width: number;
}

// Local Variables
const getStatus = (relationship: GQL.IUserRelationship): InvitationListItem['status'] => {
  if (relationship.isConfirmed) {
    return 'confirmed';
  }

  if (relationship.isRejected) {
    return 'rejected';
  }

  return 'pending';
};

// Component Definition
const ConfirmRelationships: FC<Props> = ({
  relationshipData,
  width,
}) => {
  const [itemUpdatingId, setItemUpdatingId] = useState<string | null>(null);

  const resetLoadingId = () => {
    setItemUpdatingId(null);
  };

  const [
    respondToRelationshipRequest,
    { loading },
  ] = useMutationEnhanced(
    RESPOND_TO_RELATIONSHIP_REQUEST,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_USER_RELATIONSHIPS }],
    },
  );

  const handleRespondToRelationshipRequest = (id: string, wantsToConfirm: boolean) => () => {
    setItemUpdatingId(id);
    respondToRelationshipRequest({ variables: { id, isConfirmed: wantsToConfirm } });
  };

  const handleConfirm = (id: string) => handleRespondToRelationshipRequest(id, true);

  const handleReject = (id: string) => handleRespondToRelationshipRequest(id, false);

  useEffect(() => {
    // putting this here instead of the onCompleted value
    // to avoid setting state on unmounted component
    if (!loading) {
      resetLoadingId();
    }
  }, [loading]);

  return (
    <ConfirmInvitationList
      itemUpdatingId={itemUpdatingId}
      listItems={relationshipData.map(((item) => ({
        avatar: 'face',
        id: item.id,
        invitationTimestamp: item.createdAt,
        inviterEmail: item.sender.email,
        label: `${item.sender.firstName} ${item.sender.lastName} (${item.senderType.label})`,
        status: getStatus(item),
      })))}
      onConfirm={handleConfirm}
      onReject={handleReject}
      width={width}
    />
  );
};

export default ConfirmRelationships;
