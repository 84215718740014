// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { getLocalStorageItem } from 'utils/lib/local_storage';
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import { changeInventoryTableQueryParams } from './actions';

// Local Typings
interface Action {
  payload: {
    pagesize: number;
  };
  type: string;
}
export interface InventoryState {
  queryParams: {
    pagesize: number;
  }
}

// Local Variables
const LOCAL_PAGESIZE = getLocalStorageItem('inventoryItemsPageSize');

// Reducers
const QUERY_PARAMS_INITIAL_STATE = {
  pagesize: LOCAL_PAGESIZE ? parseInt(LOCAL_PAGESIZE, 10) : 10,
};

const queryParams = createReducer(
  QUERY_PARAMS_INITIAL_STATE,
  (handleAction) => [
    handleAction(
      changeInventoryTableQueryParams,
      (state, { payload }: Action) => payload,
    ),
    handleAction(logoutCurrentUser, () => QUERY_PARAMS_INITIAL_STATE),
  ],
);

export default combineReducers<InventoryState>({ queryParams });
