// External Dependencies
import { FC, useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmCheckInDialog,
  Container,
  DatePickerField,
  EnhancedCard,
  FormActions,
  InventorySimpleDataCard,
  LabelWithLink,
  Subtitle,
} from 'components/shared';
import {
  GET_INVENTORY_ITEM,
  SimpleInventoryItem,
  SimpleUser,
} from 'gql/queries';
import { InventoryCheckoutResponse } from 'gql/queries/inventory-checkout-queries';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { useCheckInInventoryItem } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  inventoryCheckout?: InventoryCheckoutResponse;
  inventoryItem: SimpleInventoryItem;
  member: SimpleUser;
  onSubmit?: (values: InventoryCheckoutFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
}
export interface InventoryCheckoutFormValues {
  checkoutDate: string;
  dueDate: string;
  itemId: string;
  userId: string;
}

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const InventoryCheckoutForm: FC<Props> = ({
  inventoryCheckout,
  inventoryItem,
  member,
  onSubmit,
  readOnly,
  title,
}) => {
  const navigate = useNavigate();

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.INVENTORY}`);
  }, [navigate]);

  const navigateToInventoryItemShow = useCallback((id: string) => {
    navigate(`/${PATHS.INVENTORY}/${id}`);
  }, [navigate]);

  const {
    isOpen: isCheckingIn,
    toggleIsOpen: toggleIsCheckingIn,
  } = useIsOpen();

  const intialValues: InventoryCheckoutFormValues = {
    checkoutDate: inventoryCheckout?.checkoutDate ?? moment().toISOString(),
    dueDate: inventoryCheckout?.dueDate ?? '',
    itemId: inventoryItem.id,
    userId: member.id,
  };

  const handleFormikSubmit = async (
    values: InventoryCheckoutFormValues,
    formikProps: FormikHelpers<InventoryCheckoutFormValues>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  const [checkInInventoryItem, {
    loading: isCheckInInventoryItemSubmitting,
  }] = useCheckInInventoryItem(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['inventoryItemsIndex'],
      onCompleted: () => {
        navigateToInventoryItemShow(inventoryItem.id);
      },
      refetchQueries: () => [
        { query: GET_INVENTORY_ITEM, variables: { id: inventoryItem.id } },
      ],
    },
  );

  const handleCheckInInventoryItem = async () => {
    if (inventoryCheckout) {
      await checkInInventoryItem({
        variables: {
          inventoryCheckoutId: inventoryCheckout.id,
        },
      });
    }
  };

  const {
    brand,
    label,
    model,
    serialNumber,
  } = inventoryItem;

  return (
    <>
      <Container maxWidth={500}>
        <Subtitle>
          {title}
        </Subtitle>

        <EnhancedCard>
          <CardContent>
            <InventorySimpleDataCard
              brand={brand}
              label={label}
              model={model}
              serialNumber={serialNumber}
            />
          </CardContent>

          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
            mt={2}
            mx={3}
          >
            <div>
              <Typography component="span">
                Check{!inventoryCheckout ? 'ing' : 'ed'} out to
              </Typography>{' '}

              <LabelWithLink
                label={(
                  <StyledStrong>
                    {getFullName(member)}
                  </StyledStrong>
                )}
                to={`/${PATHS.STUDENTS}/${member.id}`}
              />
            </div>

            {inventoryCheckout && (
              <Button
                color="primary"
                onClick={toggleIsCheckingIn}
                size="small"
                variant="outlined"
              >
                Check In
              </Button>
            )}
          </Box>

          <Formik<InventoryCheckoutFormValues>
            initialValues={intialValues}
            onSubmit={handleFormikSubmit}
          >
            {({
              handleSubmit,
              isSubmitting,
              touched,
            }) => {
              const isFormTouched = Object.keys(touched).length > 0;

              return (
                <Form onSubmit={handleSubmit}>
                  <CardContent>
                    <DatePickerField
                      ariaLabel="Change check out date"
                      label="Check Out"
                      name="checkoutDate"
                      readOnly={!!inventoryCheckout}
                      required
                    />
                    <DatePickerField
                      ariaLabel="Change due date"
                      disableFuture={false}
                      label="Due Date"
                      name="dueDate"
                    />
                  </CardContent>

                  {!readOnly && (
                    <FormActions
                      context="Inventory Checkout"
                      isEditing={!!inventoryCheckout}
                      isFormTouched={isFormTouched}
                      isSubmitting={isSubmitting}
                      onPressCancelOrBackButton={handlePressCancelOrBackButton}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </EnhancedCard>
      </Container>

      <ConfirmCheckInDialog
        context="inventory item"
        isOpen={isCheckingIn}
        isSubmitting={isCheckInInventoryItemSubmitting}
        onClickCancel={toggleIsCheckingIn}
        onClickConfirm={handleCheckInInventoryItem}
      />
    </>
  );
};

export default InventoryCheckoutForm;
