// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Component Definition
const NotifyOnAssignmentAlert = (): JSX.Element => {
  return (
    <EnhancedAlert
      severity="info"
      title="Assignment Notifications"
    >
      When this form is assigned to members,
      an email notification will be sent to the assignee
      and all connected adult relatives who have received
      the welcome email.
    </EnhancedAlert>
  );
};

export default NotifyOnAssignmentAlert;
