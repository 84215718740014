// External Dependencies
import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.prestoPrimaryLight,
  borderRadius: 8,
  fontSize: '1rem',
  marginBottom: theme.spacing(2),
}));

// Component Definition
const SignupButton: FC<ButtonProps> = ({
  children,
  ...otherProps
}) => (
  <StyledButton
    color="primary"
    size="large"
    variant="contained"
    {...otherProps}
  >
    {children}
  </StyledButton>
);

export default SignupButton;
