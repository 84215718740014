// External Dependencies
import { FC } from 'react';

// Component Definition
const LightTheme: FC = () => (
  <style>
    {`
      body {
        background: lavender;
      }
    `}
  </style>
);

export default LightTheme;
