// External Dependencies
import {
  Box, IconButton, Typography,
} from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from 'moment';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount, formatDate } from 'utils';
import { useGetPaymentsByDate } from 'gql/queries';
import AreaLineChart from 'components/shared/AreaLineChart';

// Local Typings
interface ChartDatum {
  'Amount Paid': number;
  date: string;
}

// Local Variables
const getTicks = (interval: number) => [1, 2, 3, 4, 5].map((num) => num * interval);
const xTickFormatter = (value: string): string => {
  if (!value) {
    return '';
  }

  return formatDate(value, { isUTC: true }) as string;
};
const yTickFormatter = (value: string) => displayPriceStringFromDollarAmount(
  value,
  { hideCents: true },
);

// Component Definition
const PaymentsByDateChart: FC = () => {
  const [month, setMonth] = useState(moment().startOf('month'));

  const queryParams = useMemo<GQL.IFinancialPaymentTotalsByDateWhereConditions>(() => ({
    endDate: moment(month).endOf('month').format('YYYY-MM-DD'),
    startDate: moment(month).format('YYYY-MM-DD'),
  }), [month]);

  const monthText = useMemo(() => moment(month).format('MMMM YYYY'), [month]);

  const isPreviousDisabled = useMemo(
    () => moment(month).startOf('month') <= moment('2021').startOf('year'),
    [month],
  );

  const isNextDisabled = useMemo(
    () => moment(month).startOf('month') >= moment().startOf('month'),
    [month],
  );

  const handlePrevious = useCallback(() => {
    setMonth((m) => moment(m).subtract(1, 'month'));
  }, []);

  const handleNext = useCallback(() => {
    const nextMonth = moment(month).add(1, 'month');

    if (nextMonth < moment()) {
      setMonth(nextMonth);
    }
  }, [month]);

  const { data: paymentData } = useGetPaymentsByDate(queryParams);

  const chartData = useMemo<ChartDatum[] | undefined>(
    () => paymentData?.financialPaymentTotalsByDate
      .map((paymentTotal) => ({
        'Amount Paid': convertCentsToDollars(paymentTotal.totalAmountInCents),
        date: paymentTotal.date,
      })),
    [paymentData],
  );

  const ticks: number[] | undefined = useMemo(
    () => {
      const amounts = chartData?.map((payment) => payment['Amount Paid']);

      const maxValue = Math.max(...(amounts ?? [0]));

      const ceilingValues = [100, 500, 1000, 5000, 10000, 50000, 100000];

      const ceiling = ceilingValues.find((ceilVal) => maxValue < ceilVal);

      if (!ceiling) {
        return undefined;
      }

      return getTicks(ceiling / 5);
    },
    [chartData],
  );

  return (
    <>
      <Box
        height={300}
        width="100%"
      >
        <AreaLineChart<ChartDatum>
          data={chartData}
          tooltipProps={{
            formatter: displayPriceStringFromDollarAmount,
          }}
          xAxisProps={{
            interval: 7,
            tickFormatter: xTickFormatter,
          }}
          xKey="date"
          yAxisProps={{
            tickCount: ticks ? ticks.length - 2 : undefined,
            tickFormatter: yTickFormatter,
            ticks,
          }}
          yKey="Amount Paid"
        />
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <IconButton
          aria-label="Previous month"
          disabled={isPreviousDisabled}
          onClick={handlePrevious}
          size="large"
        >
          <ArrowLeftIcon />
        </IconButton>

        <Box width={150}>
          <Typography align="center">
            {monthText}
          </Typography>
        </Box>

        <IconButton
          aria-label="Next month"
          disabled={isNextDisabled}
          onClick={handleNext}
          size="large"
        >
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default PaymentsByDateChart;
