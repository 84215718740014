// External Dependencies
import { FormBlockTypes } from '@presto-assistant/api_types';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useCallback, useMemo } from 'react';

// Local Dependencies
import FileDownloadBlock from './FileDownloadBlock';
import HeadingBlock from './HeadingBlock';
import MyFormBlockForm from './MyFormBlockForm';
import ParagraphBlock from './ParagraphBlock';

// Local Typings
interface BaseProps {
  disabled?: boolean;
  formAssignmentId: string;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
  formId: string;
  index: number;
}

interface ReadOnlyProps extends BaseProps {
  readOnly: true;
  setIsFormDirtyWithIndex?: never;
}

interface EditableProps extends BaseProps {
  readOnly?: false;
  setIsFormDirtyWithIndex: (isFormDirty: boolean, index: number) => void;
}

type Props = ReadOnlyProps | EditableProps;

// Component Definition
const MyFormBlock = ({
  disabled,
  formAssignmentId,
  formBlock,
  formId,
  index,
  readOnly,
  setIsFormDirtyWithIndex,
}: Props): JSX.Element => {
  const handleSetIsFormDirty = useCallback((isFormDirty: boolean) => {
    if (!setIsFormDirtyWithIndex) {
      return;
    }

    setIsFormDirtyWithIndex(isFormDirty, index);
  }, [index, setIsFormDirtyWithIndex]);

  const readOnlyContent = useMemo(() => {
    const formBlockTypeId = Number(formBlock.formBlockType.id);

    switch (formBlockTypeId) {
      case FormBlockTypes.FileDownload:
        return (
          <FileDownloadBlock
            formAssignmentId={formAssignmentId}
            formBlock={formBlock}
            formId={formId}
            readOnly={readOnly}
          />
        );
      case FormBlockTypes.Heading:
        return <HeadingBlock formBlock={formBlock} />;
      case FormBlockTypes.Paragraph:
        return <ParagraphBlock formBlock={formBlock} />;
      default:
        return null;
    }
  }, [formAssignmentId, formBlock, formId, readOnly]);

  if (readOnlyContent) {
    return readOnlyContent;
  }

  return (
    <MyFormBlockForm
      disabled={disabled}
      formAssignmentId={formAssignmentId}
      formBlock={formBlock}
      readOnly={readOnly}
      setIsFormDirty={handleSetIsFormDirty}
    />
  );
};

export default MyFormBlock;
