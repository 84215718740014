// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetRelationships } from 'gql/queries';
import SubscriberOnlyButton from 'components/shared/SubscriberOnlyButton';

// Component Definition
const DashboardFamilyRelationshipsInfo = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    palette: {
      primary,
    },
  } = useTheme();

  const { data, loading } = useGetRelationships();

  const handleClickViewFamilyRelationships = useCallback(() =>
    navigate(`/${PATHS.PROFILE}#settings-family-card`), [navigate]);

  const handleClickInviteFamily = useCallback(() =>
    navigate(`/${PATHS.RELATIONSHIPS}/new`), [navigate]);

  const pendingRelationships = data?.userRelationships.filter((rel) => rel.isPending) ?? [];
  const pendingRelationshipsCount = pendingRelationships.length;

  const confirmedRelationships = data?.userRelationships.filter((rel) => rel.isConfirmed) ?? [];
  const confirmedRelationshipsCount = confirmedRelationships.length;

  const zeroStateIcon = useMemo(() => (
    <ZeroStateIcon
      Icon={confirmedRelationshipsCount ? GroupIcon : GroupAddIcon}
      htmlColor={primary.light}
    />
  ), [confirmedRelationshipsCount, primary]);

  const emptyStateElement = useMemo(() => (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Invite your family members to link with your {APP_NAME} organization.
        </Typography>
      </Box>

      <SubscriberOnlyButton
        color="primary"
        onClick={handleClickInviteFamily}
        size="small"
        variant="outlined"
      >
        Invite Family
      </SubscriberOnlyButton>
    </>
  ), [handleClickInviteFamily, zeroStateIcon]);

  const pendingElement = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography variant="body2">
          You have
          {' '}
          {pendingRelationshipsCount} pending relationship invitation{pendingRelationshipsCount > 1 ? 's' : ''}.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleClickViewFamilyRelationships}
        size="small"
        variant="outlined"
      >
        View Family
      </Button>
    </>
  );

  const confirmedElement = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography variant="body2">
          You have
          {' '}
          {confirmedRelationshipsCount} family relationship{confirmedRelationshipsCount > 1 ? 's' : ''}.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleClickViewFamilyRelationships}
        size="small"
        variant="outlined"
      >
        View Family
      </Button>
    </>
  );

  const getFamilyContent = () => {
    let content;

    if (pendingRelationships && pendingRelationshipsCount) {
      content = pendingElement;
    } else if (confirmedRelationships && confirmedRelationshipsCount) {
      content = confirmedElement;
    } else {
      content = emptyStateElement;
    }

    return content;
  };

  return (
    <DashboardCard
      content={loading ? <CircularProgress /> : getFamilyContent()}
      horizontallyCenterContent
      title="Family"
      verticallyCenterContent
    />
  );
};

export default DashboardFamilyRelationshipsInfo;
