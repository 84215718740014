// External Dependencies
import setWith from 'lodash.setwith';

// Utils
export const constructInitialValuesFromInputs = (inputs) => {
  const names = inputs.map((input) => input.name);
  const initialValues = {};
  names.forEach((name) => {
    setWith(initialValues, name, '');
  });
  return initialValues;
};

export const setRequiredError = (errorObj, key, value) => {
  if (!value) {
    // eslint-disable-next-line no-param-reassign
    errorObj[key] = 'Required';
  }
};

export const validatePhone = (str) => !!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(str);

export const validatePasswordLength = (str) => {
  const isValidLength = str.length > 5;
  return isValidLength;
};

export const validateEmail = (str) => !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(str);

export const validateZipcode = (str) => !!/^\d{5}(?:[-\s]\d{4})?$/.test(str);
