// External Dependencies
import {
  Button, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedDialog, Flex, SaveButton } from 'components/shared';

// Local Typings
interface Props {
  context: 'inventory item' | 'uniform';
  isOpen: boolean;
  isSubmitting: boolean;
  onClickCancel: () => void;
  onClickConfirm: () => void;
}

// Component Definition
const ConfirmCheckInDialog: FC<Props> = ({
  context,
  isOpen,
  isSubmitting,
  onClickCancel,
  onClickConfirm,
}) => (
  <EnhancedDialog open={isOpen}>
    <DialogTitle>Confirm Check In</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to check in this {context}?
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Flex justifyContent="flex-end">
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={onClickCancel}
          sx={{ mr: 1 }}
          variant="outlined"
        >
          Cancel
        </Button>

        <SaveButton
          color="primary"
          isSaving={isSubmitting}
          onClick={onClickConfirm}
          variant="contained"
        >
          Yes, Check In
        </SaveButton>
      </Flex>
    </DialogActions>
  </EnhancedDialog>
);

export default ConfirmCheckInDialog;
