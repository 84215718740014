// External Dependencies
import { Flex } from 'components/shared';
import { WithoutTypename } from '@presto-assistant/api_types/graphql-utils';
import { useCallback } from 'react';
import { useDownloadFormBlockFile } from 'utils/api/forms';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  formAssignmentId: string;
  formBlock: WithoutTypename<GQL.IMyFormBlock>;
  formId: string;
  readOnly?: boolean;
}

// Component Definition
const FileDownloadBlock = ({
  formAssignmentId,
  formBlock,
  formId,
  readOnly,
}: Props): JSX.Element => {
  const {
    isLoading: isDownloading,
    mutate: downloadFormBlockFile,
  } = useDownloadFormBlockFile();

  const handleClickDownload = useCallback(() => {
    if (readOnly) {
      return;
    }

    downloadFormBlockFile({
      body: { formAssignmentId },
      params: {
        formBlockId: formBlock.id,
        formId,
      },
    });
  }, [downloadFormBlockFile, formAssignmentId, formBlock, formId, readOnly]);

  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      gap={2}
    >
      <Typography
        sx={{ fontSize: '1.1rem', fontWeight: 500 }}
        variant="h6"
      >
        {formBlock.label}
      </Typography>

      <Typography>
        {formBlock.metadata.s3Filename}
      </Typography>

      <Button
        disabled={isDownloading}
        onClick={handleClickDownload}
        size="small"
        startIcon={<CloudDownloadIcon />}
        variant="outlined"
      >
        Download
      </Button>
    </Flex>
  );
};

export default FileDownloadBlock;
