// Internal Dependencies
import {
  Subtitle,
} from 'components/shared';

// Local Dependencies
import AdminInventoryAuditsTable from './AdminInventoryAuditsTable';

// Component Definition
const InventoryAuditsAll = (): JSX.Element => {
  return (
    <>
      <Subtitle>
        District Inventory Audits
      </Subtitle>

      <AdminInventoryAuditsTable />
    </>
  );
};

export default InventoryAuditsAll;
