// Internal Dependencies
import CSVImporter from 'components/CSVImporter';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogInventoryFileUpload = ({
  isOpen,
  onClose,
}: Props): JSX.Element => (
  <CSVImporter
    context="inventory"
    csvFileInfoResource="dfaInventoryItems"
    dialogTitle="Add Inventory Items"
    insertMutationName="dfaInsertInventoryCsvFileToDB"
    insertMutationOptions={{
      clearCachePredicates: ['districtInventory'],
    }}
    isAdmin
    isOpen={isOpen}
    onClose={onClose}
    requiredColumnsHelperText={'Find the "organizationCode" for any organization by clicking on a row in the organizations table. You can quickly copy the code to add it to your CSV file.'}
    uploadMutationName="dfaCsvInventoryUpload"
  />
);

export default DialogInventoryFileUpload;
