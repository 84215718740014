// External Dependencies
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';

// Internal Dependencies
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { downloadFile } from 'utils/lib/download_file';
import { useDownloadFinancialStatements, useDownloadMyFinancialStatements } from 'gql/mutations';

// Local Typings
interface Props {
  downloadFileName: string;
  isGettingFamilyStatements?: boolean;
  isOpen: boolean;
  onClose: () => void;
  userIds?: string[];
}

// Local Variables
const base64Prefix = 'data:application/pdf;base64,';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Component Definition
const FinancialStatementDialog: FC<Props> = ({
  downloadFileName,
  isGettingFamilyStatements,
  isOpen,
  onClose,
  userIds,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(1);

  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumberOfPages(numPages);
  }, []);

  const [
    downloadFinancialStatements,
    {
      data: financialStatementData,
      loading: isDownloadingStatements,
    },
  ] = useDownloadFinancialStatements();

  const [
    downloadMyFinancialStatements,
    {
      data: myFinancialStatementData,
      loading: isDownloadingMyStatements,
    },
  ] = useDownloadMyFinancialStatements();

  const data = isGettingFamilyStatements
    ? myFinancialStatementData?.downloadMyFinancialStatements
    : financialStatementData?.downloadFinancialStatements;

  const isLoading = isGettingFamilyStatements ? isDownloadingMyStatements : isDownloadingStatements;

  const downloadStatements = isGettingFamilyStatements
    ? downloadMyFinancialStatements : downloadFinancialStatements;

  const pdfBase64 = data?.base64;

  const handleDownloadFile = useCallback(() => {
    if (pdfBase64) {
      downloadFile({
        base64: pdfBase64,
        contentType: UPLOAD_FILE_TYPES.pdf,
        fileName: downloadFileName,
      });
    }

    onClose();
  }, [pdfBase64]);

  useEffect(() => {
    if (isOpen) {
      downloadStatements({
        variables: {
          input: {
            userIds,
          },
        },
      });
    }
  }, [isOpen, userIds]);

  useEffect(() => {
    containerRef.current?.scrollTo({
      behavior: 'auto',
      top: 0,
    });
  }, [containerRef]);

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        Financial Statement Preview
      </DialogTitle>

      <DialogContent
        ref={containerRef}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          {isLoading && <CircularProgress />}

          {pdfBase64 && (
            <Document
              file={`${base64Prefix}${pdfBase64}`}
              loading={<CircularProgress />}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array(numberOfPages).fill(true).map((_item, index) => (
                <Paper
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  sx={{ mt: index === 0 ? 0 : 2 }}
                >
                  <Page
                    loading={<CircularProgress />}
                    pageNumber={index + 1}
                  />
                </Paper>
              ))}
            </Document>
          )}
        </Box>
      </DialogContent>

      <Divider />

      {pdfBase64 && (
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
            variant="outlined"
          >
            Close
          </Button>

          <Button
            color="primary"
            onClick={handleDownloadFile}
            variant="contained"
          >
            Download
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default FinancialStatementDialog;
