import {
  validatePasswordLength,
} from './utils';

export const validatePasswordChange = (values) => {
  const errors = {};

  if (!validatePasswordLength(values.password)) {
    errors.password = 'Password must be at least 8 characters long';
  }

  const nonRequiredKeys = [];

  // map over values and make all required
  Object.entries(values).forEach(([k, v]) => {
    if (!v && !nonRequiredKeys.includes(k)) {
      errors[k] = 'Required';
    }
  });

  // is passwords do not match
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }

  Object.keys(errors).forEach((key) => {
    if (Object.keys(errors[key]).length === 0) {
      delete errors[key];
    }
  });

  return errors;
};
