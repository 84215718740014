// External Dependencies
import { FC } from 'react';
import { useDebounce } from 'use-debounce/lib';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { SimpleUniform, useGetUniformsTypeAhead } from 'gql/queries';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  // Intentionally `any` type
  // We don't care what the caller does with this
  onSelectUniform: (uniform: SimpleUniform | null) => any;
}

// Component Definition
const UniformTypeAhead: FC<Props> = ({
  onSelectUniform,
}) => {
  const textField = useTextField('');
  const [searchValue] = useDebounce(textField.value, 500);

  const {
    data,
    loading,
  } = useGetUniformsTypeAhead(searchValue);

  const isLoading = loading || searchValue.trim() !== textField.value.trim();

  const handleChange = (_evt: any, uniform: SimpleUniform | null) => {
    onSelectUniform(uniform);
  };

  return (
    <Autocomplete
      disablePortal
      filterOptions={(uniform) => uniform}
      getOptionDisabled={(uniform) => !!uniform.activeCheckout}
      getOptionLabel={(uniform) => `${uniform.uniformNumber}${uniform.activeCheckout ? ' (Checked Out)' : ''}`}
      isOptionEqualToValue={(uniform, value) => uniform.id === value.id}
      loading={isLoading}
      onChange={handleChange}
      options={data?.uniforms.data ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Search Uniforms"
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            primary={option.uniformNumber}
            secondary={`${option.uniformType.label}, Size ${option.size}`}
          />
        </ListItem>
      )}
    />
  );
};

export default UniformTypeAhead;
