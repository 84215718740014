// External Dependencies
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

// Internal Dependencies
import {
  BackToButton,
  ConfirmationDialog,
  EnhancedCard,
  Flex,
  Loader,
  ShowCardHeader,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { useCancelScheduledSendEmail } from 'gql/mutations';
import { useGetEmail } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParamsWithId } from 'hooks/useParamsWithId';
import { useTableQueryParams } from 'state/table/selectors';
import EmailBody from 'pages/Communication/EmailShow/EmailBody';
import EmailFooter from 'pages/Communication/EmailShow/EmailFooter';
import EmailRecipientsTable from 'pages/Communication/EmailShow/EmailRecipientsTable';
import EmailTitle from 'pages/Communication/EmailShow/EmailTitle';

// Component Definition
const AdminCommunicationEmailShow: FC = () => {
  const navigate = useNavigate();
  const { id } = useParamsWithId();

  const {
    isOpen: isCancelScheduleSendDialogOpen,
    toggleIsOpen: toggleIsCancelScheduleSendDialogOpen,
  } = useIsOpen();

  const dispatch = useDispatch();

  const {
    data,
    loading,
  } = useGetEmail(id);

  const [
    cancelScheduledSendEmail,
    {
      loading: isCancelingScheduledSendEmail,
    },
  ] = useCancelScheduledSendEmail({
    onCompleted: () => {
      toggleIsCancelScheduleSendDialogOpen();
      dispatch(addNotification('Email has been cancelled', 'success'));
      navigate(`/${PATHS.EMAIL_DRAFTS}/${id}`);
    },
  });

  const handleCancelScheduledSendEmail = useCallback(() => {
    if (!id) {
      console.error('Email ID is undefined, cannot cancel the scheduled email.');
      return;
    }

    cancelScheduledSendEmail({
      variables: {
        id,
      },
    });
  }, [cancelScheduledSendEmail, id]);

  const emailParams = useTableQueryParams('districtEmails');

  const isScheduledSend = data?.email.scheduledSendAt !== null && data?.email.sentAt === null;

  const handleClickBack = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.COMMUNICATION}${emailParams}`);
  }, [emailParams, navigate]);

  const theme = useTheme();

  if (loading) {
    return (
      <Loader />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <BackToButton
        context="Communication"
        onClickButton={handleClickBack}
      />

      <EnhancedCard sx={{ marginTop: 2 }}>
        <CardContent>
          <EmailTitle email={data.email} />
        </CardContent>

        <Divider />

        <CardContent>
          <EmailBody
            body={data.email.body}
            scheduledSendAt={data.email.scheduledSendAt}
            sentAt={data.email.sentAt}
            submittedAt={data.email.submittedAt}
          />
        </CardContent>

        {data.email.attachments.length > 0 && (
          <>
            <Divider />

            <CardContent>
              <EmailFooter attachments={data.email.attachments} />
            </CardContent>
          </>
        )}

        {isScheduledSend && (
          <>
            <Divider />

            <CardActions>
              <Flex
                justifyContent="flex-end"
                width="100%"
              >
                <Button
                  onClick={toggleIsCancelScheduleSendDialogOpen}
                  startIcon={<CancelScheduleSendIcon />}
                  variant="outlined"
                >
                  Cancel Scheduled Email
                </Button>
              </Flex>
            </CardActions>
          </>
        )}
      </EnhancedCard>

      {data.email.hasDeliveryInsights && (
        <EnhancedCard sx={{ marginTop: 2 }}>
          <CardContent>
            <ShowCardHeader
              avatar={(
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.showPage.people,
                  }}
                >
                  <TrendingUpIcon />
                </Avatar>
              )}
              title="Delivery Insights"
            />

            <Paper
              sx={{
                marginTop: theme.spacing(2),
              }}
              variant="outlined"
            >
              <EmailRecipientsTable recipients={data.email.recipients} />
            </Paper>
          </CardContent>
        </EnhancedCard>
      )}

      <ConfirmationDialog
        confirmButtonAction={handleCancelScheduledSendEmail}
        declineButtonAction={toggleIsCancelScheduleSendDialogOpen}
        description="This will cancel the scheduled send and set this email back to a draft. Are you sure you want to cancel?"
        handleClose={toggleIsCancelScheduleSendDialogOpen}
        isSubmitting={isCancelingScheduledSendEmail}
        open={isCancelScheduleSendDialogOpen}
        title="Cancel Scheduled Email?"
      />
    </>
  );
};

export default AdminCommunicationEmailShow;
