// External Dependencies
import { darken } from '@mui/material/styles';

// Internal Dependencies
import { Theme } from 'types/styled';

export const getGradientBackground = (theme: Theme) => {
  const isDarkMode = theme.palette.mode === 'dark';

  return isDarkMode
    ? `linear-gradient(160deg, ${darken(theme.palette.prestoPrimary, 0.7)} 30%, ${darken(theme.palette.prestoPrimary, 0.5)} 90%)`
    : `linear-gradient(160deg, ${theme.palette.prestoPrimaryDark} 30%, ${theme.palette.prestoPrimary} 90%)`;
};
