// External Dependencies
import { Box } from '@mui/material';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import { PATHS } from 'utils/constants/routes';
import InactiveTable from './InactiveTable';

// Component Definition
const InactiveMembers = () => (
  <>
    <Box mb={2}>
      <BackToButton
        context="Organization Settings"
        navigateTo={`/${PATHS.SETTINGS}`}
      />
    </Box>

    <Subtitle>
      Inactive Members
    </Subtitle>

    <EnhancedCard>
      <InactiveTable />
    </EnhancedCard>
  </>
);

export default InactiveMembers;
