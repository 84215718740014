// External Dependencies
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  Flex,
  PrestoLogoVerticalSvg,
  SkewedBackground,
  StyledLink,
} from 'components/shared';
import RecoverPasswordDialog from 'components/shared/RecoverPasswordDialog';

// Local Dependencies
import PasswordResetForm from './PasswordResetForm';

// Local Variables
const useStyles = makeStyles({
  buttonWrapper: {
    padding: 16,
  },
  divider: {
    width: '100%',
  },
  link: {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  section: {
    marginTop: 24,
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 360,
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px 0',
  width: 400,
  zIndex: 1,
}));

const LogoContainer = styled.section(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
    maxWidth: 272,
  },
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(7),
  marginTop: theme.spacing(8),
  maxWidth: 360,
  zIndex: 1,
}));

// Component Definition
const PasswordResetPage = () => {
  const classes = useStyles();

  const [isRecoverPasswordDialogOpen, setIsRecoverPasswordDialogOpen] = useState(false);

  const toggleRecoverPasswordDialog = () => {
    setIsRecoverPasswordDialogOpen(!isRecoverPasswordDialogOpen);
  };

  return (
    <Flex
      alignContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <SkewedBackground />

      <LogoContainer>
        <Link
          className={classes.link}
          to="/"
        >
          <PrestoLogoVerticalSvg />
        </Link>
      </LogoContainer>

      <StyledPaper variant="outlined">
        <Typography variant="h6">
          Reset Password
        </Typography>

        <Box my={2}>
          <PasswordResetForm />
        </Box>

        <Divider className={classes.divider} />

        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            onClick={toggleRecoverPasswordDialog}
          >
            Resend Email
          </Button>
        </div>

        <Divider className={classes.divider} />

        <Typography className={classes.section}>
          Remembered Password? <StyledLink href="/">Login</StyledLink>
        </Typography>
      </StyledPaper>

      <RecoverPasswordDialog
        isOpen={isRecoverPasswordDialogOpen}
        onClose={toggleRecoverPasswordDialog}
      />
    </Flex>
  );
};

export default PasswordResetPage;
