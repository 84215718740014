// External Dependencies
import {
  Card,
  CardContent,
  CardProps,
  Fade,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import useHover from 'hooks/useHover';

// Local Dependencies
import SignupButton from './SignupButton';
import SignupCardHeader, { Title } from './SignupCardHeader';
import SignupInfoList from './SignupInfoList';

// Local Typings
interface Props {
  buttonText: string;
  isChooseRoleView?: boolean;
  listItem1?: ReactNode;
  listItem2?: ReactNode;
  listItem3?: ReactNode;
  onClickButton: () => void;
  subheader: string;
  title: Title;
  transitionTimeout: number;
}

interface StyledContainerProps extends CardProps {
  $isChooseRoleView?: boolean;
}

// Local Variables
const StyledCard = styled(Card)<StyledContainerProps>(({
  $isChooseRoleView,
  theme,
}) => ({
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 340,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: $isChooseRoleView ? 340 : 272,
  },
  border: `1px solid ${theme.palette.prestoPrimaryLight}`,
  borderRadius: 10,
  margin: theme.spacing(1),
  textAlign: 'center',
}));

// Component Definition
const SignupCard: FC<Props> = ({
  buttonText,
  isChooseRoleView,
  listItem1,
  listItem2,
  listItem3,
  onClickButton,
  subheader,
  title,
  transitionTimeout,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  return (
    <Fade
      in
      timeout={transitionTimeout}
    >
      <StyledCard
        $isChooseRoleView={isChooseRoleView}
        elevation={isHovered ? 5 : 2}
        raised
        ref={hoverRef}
      >
        <SignupCardHeader
          subheader={subheader}
          title={title}
        />

        <CardContent>
          <SignupButton
            onClick={onClickButton}
          >
            {buttonText}
          </SignupButton>

          {listItem1 && (
            <SignupInfoList
              item1={listItem1}
              item2={listItem2}
              item3={listItem3}
            />
          )}
        </CardContent>
      </StyledCard>
    </Fade>
  );
};
export default SignupCard;
