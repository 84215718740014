// External Dependencies
import { createActionCreator } from 'deox';

// Actions
export const updateRecipients = createActionCreator(
  'UPDATE_EMAIL_RECIPIENTS',
  (resolve) => (recipientIds: string[]) =>
    resolve(recipientIds),
);

export const addOneRecipient = createActionCreator(
  'ADD_ONE_EMAIL_RECIPIENT',
  (resolve) => (recipientId: string) =>
    resolve(recipientId),
);

export const clearRecipients = createActionCreator(
  'CLEAR_ALL_EMAIL_RECIPIENTS',
);

export const removeRecipient = createActionCreator(
  'REMOVE_EMAIL_RECIPIENT',
  (resolve) => (recipientId: string) =>
    resolve(recipientId),
);
