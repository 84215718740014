// External Dependencies
import { AlertColor } from '@mui/material/Alert';
import {
  CreateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { FC, useCallback, useMemo } from 'react';
import {
  FieldArray,
  Form,
  FormikErrors,
  FormikTouched, useFormikContext,
} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  CustomInput,
  DialogPeoplePicker,
  EnhancedAlert,
  FormRouteActions,
  ShowCard,
  StyledLink,
} from 'components/shared';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import { open as openPeoplePickerDialog } from 'state/ui/peoplePickerDialog/actions';
import { pluralize } from 'utils';
import { useGetOrganization } from 'gql/queries';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Dependencies
import IndividualizedCreditFormFields from './IndividualizedCreditFormFields';
import ItemsFieldArray from '../ItemsFieldArray';

// Local Typings
interface Props {
  assignmentsFormikValue: CreateFinancialFundraiserCreditRequest['assignments'];
  errors: FormikErrors<CreateFinancialFundraiserCreditRequest>;
  financialAccountIdFormikValue: string;
  isIndividualizedFormikValue: boolean;
  isSubmitting: boolean;
  itemsFormikValue: CreateFinancialFundraiserCreditRequest['items'];
  onPressCancelOrBackButton: () => void;
  schoolYearEndingFormikValue: number;
  touched: FormikTouched<CreateFinancialFundraiserCreditRequest>;
}

// Component Definition
const FinancialFundraiserCreditForm: FC<Props> = ({
  assignmentsFormikValue,
  errors,
  financialAccountIdFormikValue,
  isIndividualizedFormikValue,
  isSubmitting,
  itemsFormikValue,
  onPressCancelOrBackButton,
  schoolYearEndingFormikValue,
  touched,
}) => {
  const formikContext = useFormikContext<CreateFinancialFundraiserCreditRequest>();

  const handleToggleIsIndividualized = useCallback(() => {
    formikContext.setFieldValue('isIndividualized', !isIndividualizedFormikValue);
  // don't use formikContext in this dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndividualizedFormikValue]);

  const dispatch = useDispatch();

  const {
    data: organizationData,
  } = useGetOrganization();

  const district = organizationData?.organization.district;

  const canUseIndividualizedFundraiserCredits = district?.canUseIndividualizedFundraiserCredits
    ?? false;

  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const isFormTouched = Object.keys(touched).length > 0;

  const linkToIndividualizedText = isIndividualizedFormikValue
    ? 'Standard Fundraiser Credit'
    : 'Individualized Fundraiser Credit';

  const linkToIndividualizedPreText = isIndividualizedFormikValue
    ? 'Needing a single amount for all students?'
    : 'Needing individual amounts for each student?';

  const linkToIndividualizedElement = (
    <StyledLink
      onClick={handleToggleIsIndividualized}
    >
      {linkToIndividualizedText}
    </StyledLink>
  );

  const handleClickSelectMembers = useCallback(() => {
    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  const handleUpdateSelectedMembers = useCallback((memberIds: string[]) => {
    const assignments: CreateFinancialFundraiserCreditRequest['assignments'] = memberIds.map((id) => ({
      amountInCents: 1, // we'll update this on submit to be the default amount
      memberId: id,
    }));

    formikContext.setFieldValue('assignments', assignments);
  // don't use formikContext in this dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preSelectedMemberIds = useMemo(
    () =>
      assignmentsFormikValue.map((assignment) => assignment.memberId),
    [assignmentsFormikValue],
  );

  const assignmentAlertColor: AlertColor = useMemo(() => {
    if (touched.assignments && errors.assignments?.length) {
      return 'error';
    }

    if (assignmentsFormikValue.length === 0) {
      return 'info';
    }

    return 'success';
  }, [assignmentsFormikValue, errors, touched]);

  return (
    <Form>
      <Box mb={2}>
        <ShowCard
          icon={CurrencyExchangeIcon}
          title="New Fundraiser Credit"
        >
          <CustomInput
            label="Fundraiser Name"
            name="label"
          />

          <IndividualizedCreditFormFields
            errors={errors}
            isIndividualized={isIndividualizedFormikValue}
            touched={touched}
          />

          <Collapse in={!isIndividualizedFormikValue}>
            <CustomInput
              helperText="The amount reduced for each student assigned the selected fees"
              isCurrency
              label="Amount"
              name="defaultAmountInCents"
            />
          </Collapse>

          {canUseIndividualizedFundraiserCredits && (
            <Typography
              fontSize="smaller"
              gutterBottom
            >
              {linkToIndividualizedPreText}
              <br />
              Switch to {linkToIndividualizedElement}
            </Typography>
          )}

          <FinancialAccountSelect readOnly={!!itemsFormikValue.length} />

          <SchoolYearSelect
            fullWidth={false}
            inputProps={{
              readOnly: !!itemsFormikValue.length,
            }}
            required
            variant="filled"
          />

          {itemsFormikValue.length > 0 && (
            <EnhancedAlert
              sx={{ marginTop: 2 }}
            >
              To change financial account or school year, remove all selected financial items.
            </EnhancedAlert>
          )}

          <Box marginY={2}>
            <Typography>
              Financial Items
            </Typography>

            <FormHelperText>
              Fundraiser credits are applied to members&apos;
              {' '}
              fees in the order of priority selected.
            </FormHelperText>

            {(errors?.items?.length ?? 0) > 0 && touched.items && (
              <EnhancedAlert
                severity="error"
                sx={{ marginY: 1 }}
              >
                {errors.items}
              </EnhancedAlert>
            )}

            <FieldArray name="items">
              {(arrayHelpers) => (
                <ItemsFieldArray
                  arrayHelpers={arrayHelpers}
                  financialAccountIdFormikValue={financialAccountIdFormikValue}
                  itemsFormikValue={itemsFormikValue}
                  schoolYearEndingFormikValue={schoolYearEndingFormikValue}
                />
              )}
            </FieldArray>
          </Box>

          <Collapse in={!isIndividualizedFormikValue}>
            <EnhancedAlert
              action={(
                <Button
                  color={assignmentsFormikValue.length > 0 ? 'success' : 'inherit'}
                  key="select-members-button"
                  onClick={handleClickSelectMembers}
                  size="small"
                  variant="outlined"
                >
                  {assignmentsFormikValue.length > 0 ? 'Edit' : 'Assign'}
                </Button>
              )}
              severity={assignmentAlertColor}
            >
              Assigning to {assignmentsFormikValue.length} {pluralize(assignmentsFormikValue.length, 'member')}
            </EnhancedAlert>
          </Collapse>
        </ShowCard>
      </Box>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateSelectedMembers}
        preSelectedIds={preSelectedMemberIds}
        tableResource="peoplePickerFeeForm"
        title="Select Members"
      />

      <FormRouteActions
        context="Fundraiser Credit"
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={onPressCancelOrBackButton}
      />
    </Form>
  );
};

export default FinancialFundraiserCreditForm;
