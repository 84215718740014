// External Dependencies
import { FC } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';

// Internal Dependencies
import { CustomSelect, Flex, SaveButton } from 'components/shared';
import { yup } from 'utils/yup';

// Local Typings
interface Props {
  onBack: () => void;
  onCompleted: (values: AgeFormValues) => void;
  readOnly?: boolean;
}
export interface AgeFormValues {
  isAtLeast13: string;
}

// Local Variables
const ageOptions = [
  {
    id: 'yes',
    label: 'Yes',
  },
  {
    id: 'no',
    label: 'No',
  },
];

const schema = yup.object().shape<AgeFormValues>({
  isAtLeast13: yup.string().required('Required'),
});

// Component Definition
const Age: FC<Props> = ({
  onBack,
  onCompleted,
  readOnly,
}) => {
  const handleFormikSubmit = (values: AgeFormValues) => {
    onCompleted(values);
  };

  return (
    <Formik<AgeFormValues>
      initialValues={{
        isAtLeast13: '',
      }}
      onSubmit={handleFormikSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <CustomSelect
            autoFocus
            inputProps={{ readOnly }}
            label="Is this member at least 13 years of age?"
            name="isAtLeast13"
            options={ageOptions}
          />

          {!readOnly && (
            <Flex
              justifyContent="flex-end"
              marginTop={1}
            >
              <Button
                color="primary"
                onClick={onBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Go Back
              </Button>

              <SaveButton
                color="primary"
                variant="contained"
              >
                Next
              </SaveButton>
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Age;
