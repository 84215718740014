// Internal Dependencies
import { History } from 'components/shared';
import {
  SettingsCardWrapper,
  SettingsWrapper,
} from 'components/shared/Settings/styles';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import OrganizationCard from './OrganizationCard';
import OrganizationIntegrationsCard from './OrganizationIntegrationsCard';
import SettingsDistrictData from './SettingsDistrictData';

// Component Definition
const Settings = (): JSX.Element | null => {
  const { data: orgData } = useGetOrganization();

  if (!orgData) {
    return null;
  }

  return (
    <>
      <SettingsWrapper>
        <SettingsCardWrapper>
          <OrganizationCard />
        </SettingsCardWrapper>

        <SettingsCardWrapper>
          <SettingsDistrictData />
        </SettingsCardWrapper>

        <SettingsCardWrapper fullWidth>
          <OrganizationIntegrationsCard />
        </SettingsCardWrapper>
      </SettingsWrapper>

      {orgData?.organization && (
        <History
          id={orgData.organization.id}
          resource="organizations"
          title="Organization"
        />
      )}
    </>
  );
};

export default Settings;
