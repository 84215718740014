// External Dependencies
import { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { SUPPORT_SITE_HREF } from 'utils/constants';
import StyledLink from 'components/shared/StyledLink';

// Local Typings
interface Props {
  onClose: () => void;
  open: boolean;
}

// Component Definition
const ExpirationDialog: FC<Props> = ({ onClose, open }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle>License Needed</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          Please contact us about purchasing a license to access this feature.

        </DialogContentText>

        <DialogContentText>
          <StyledLink
            href={`${SUPPORT_SITE_HREF}/articles/purchase-a-presto-license/#quick-steps`}
            openInNewTab
          >
            Learn more about purchasing a license.
          </StyledLink>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpirationDialog;
