// External Dependencies
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Collapse } from '@mui/material';
import { EditorState, convertToRaw } from 'draft-js';
import { FormikHandlers, FormikState } from 'formik';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';
import EmailEditor from 'pages/Communication/EmailComposer/EmailEditor';

// Local Typings
export interface FormValues {
  emailSignature: string;
}

interface Props {
  handleChange: FormikHandlers['handleChange'];
  values: FormikState<FormValues>['values'];
}

// Component Definition
const EmailSignatureForm: FC<Props> = ({
  handleChange,
  values,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = useCallback((newEditorState: EditorState) => {
    setEditorState(newEditorState);
  }, []);

  useEffect(() => {
    const contentState = stateFromHTML(values.emailSignature);
    setEditorState(EditorState.createWithContent(contentState));
    //  Intentionally not including values in the dependency array
    //  because we only want to update the editor state once
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const evt = {
      target: {
        value: body,
      },
    } as unknown as ChangeEvent<HTMLInputElement>;

    handleChange('emailSignature')(evt);
  }, [editorState, handleChange]);

  const hasNestedParagraph = values.emailSignature.lastIndexOf('<p>') > 0;

  return (
    <>
      <EmailEditor
        editorState={editorState}
        isSimpleToolbar
        isToolbarHidden={false}
        minEditorHeight={100}
        onEditorStateChange={handleEditorStateChange}
        placeholder="Enter your email signature"
      />

      <Collapse in={hasNestedParagraph}>
        <EnhancedAlert
          isTip
          sx={{ marginTop: 2 }}
        >
          Press SHIFT + ENTER to create a new line in your email signature with minimal space.
        </EnhancedAlert>
      </Collapse>
    </>
  );
};

export default EmailSignatureForm;
