// External Dependencies
import Container from '@mui/material/Container';

// Internal Dependencies
import { EnhancedCard, EnhancedCardContent } from 'components/shared';
import BillingSettingsCardHeader from 'components/shared/BillingSettingsCardHeader';

// Local Dependencies
import DownloadFormW9Section from './DownloadFormW9Section';
import TaxExemptFormSection from './TaxExemptFormSection';

// Component Definition
const AdminBilling = (): JSX.Element => {
  return (
    <Container maxWidth="md">
      <EnhancedCard useMaterialV3>
        <BillingSettingsCardHeader />

        <EnhancedCardContent>
          <DownloadFormW9Section />

          <TaxExemptFormSection />
        </EnhancedCardContent>
      </EnhancedCard>
    </Container>
  );
};

export default AdminBilling;
