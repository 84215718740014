// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

// Internal Dependencies
import {
  EnhancedCard, EnhancedCardContent, EnhancedContainer, Flex,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Component Definition
const AllReports: FC = () => {
  const navigate = useNavigate();

  const handleNavigateToNewReport = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}/new`);
  }, [navigate]);

  return (
    <EnhancedContainer>
      <EnhancedCard>
        <EnhancedCardContent>
          <Flex
            justifyContent="center"
            sx={{ paddingY: 2 }}
          >
            <Button
              onClick={handleNavigateToNewReport}
              variant="contained"
            >
              Create New Report
            </Button>
          </Flex>
        </EnhancedCardContent>
      </EnhancedCard>
    </EnhancedContainer>
  );
};

export default AllReports;
