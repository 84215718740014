// External Dependencies
import { FC, useCallback, useState } from 'react';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
} from 'components/shared';
import { pluralize } from 'utils';

// Local Dependencies
import { SHORT_APP_NAME } from 'utils/constants';
import BadRowTable from './BadRowTable';
import GoodRowTable from './GoodRowTable';

// Local Typings
interface Props {
  context: string;
  fileName: string;
  importResourceId?: number;
  isMigration: boolean;
  previewData: GQL.ICSVUploadPreview | null;
}

// Local Variables
const StyledShowHideErrorsButton = styled(Button)(({
  theme,
}) => ({
  '&:hover, &:focus': {
    backgroundColor: theme.palette.mode === 'dark'
      ? darken(theme.palette.warning.light, 0.6)
      : lighten(theme.palette.warning.light, 0.6),
  },
  color: theme.palette.warning.dark,
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  code: {
    backgroundColor: theme.palette.action.hover,
    borderRadius: 4,
    padding: theme.spacing(0.5),
  },
  strong: {
    fontSize: '1.25rem',
    whiteSpace: 'pre',
  },
}));

// Component Definition
const FilePreview: FC<Props> = ({
  context,
  fileName,
  importResourceId,
  isMigration,
  previewData,
}) => {
  const [isShowingBadRows, setIsShowingBadRows] = useState(false);

  const toggleShowBadRows = useCallback(() => {
    setIsShowingBadRows((show) => !show);
  }, []);

  if (isMigration) {
    return (
      <>
        <Typography paragraph>
          Are you ready to import your data from <code>{fileName}</code>?
        </Typography>

        <Typography paragraph>
          A {SHORT_APP_NAME} team member will review your
          data and contact you if there are any issues.
          Please allow up to 2 business days for review.
        </Typography>
      </>
    );
  }

  if (!previewData) {
    return null;
  }

  const {
    badRows,
    goodRowCount,
    message,
  } = previewData;

  return (
    <>
      <StyledTypography gutterBottom>
        Ready to import <strong>{goodRowCount}</strong> good {pluralize(goodRowCount, 'row')} from{' '}
        <code>{fileName}</code>
      </StyledTypography>

      {message && (
        <EnhancedAlert>
          {message}
        </EnhancedAlert>
      )}

      {badRows.length > 0 && (
        <>
          <Box
            maxWidth="400px"
            mb={2}
            mt={3}
          >
            <EnhancedAlert
              action={(
                <StyledShowHideErrorsButton
                  color="primary"
                  key="toggle-bad-rows-table-button"
                  onClick={toggleShowBadRows}
                >
                  {isShowingBadRows ? 'Hide' : 'Show'}
                </StyledShowHideErrorsButton>
              )}
              severity="warning"
            >
              We ignored {badRows.length} {pluralize(badRows.length, 'row')} with invalid
              data.
            </EnhancedAlert>
          </Box>

          <Collapse in={isShowingBadRows}>
            <BadRowTable
              context={context}
              importResourceId={importResourceId}
              previewData={previewData}
            />
          </Collapse>
        </>
      )}

      <GoodRowTable
        context={context}
        previewData={previewData}
      />
    </>
  );
};

export default FilePreview;
