// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import {
  GET_INVENTORY_ITEM,
  SimpleInventoryItem,
  SimpleUser,
  useGetSimpleInventoryItem,
  useGetSimpleUser,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import {
  Page,
  SelectUser,
} from 'components/shared';
import { parseSearch } from 'utils';
import { useCreateInventoryCheckout } from 'gql/mutations';

// Local Dependencies
import InventoryCheckoutForm, { InventoryCheckoutFormValues } from '../shared/InventoryCheckoutForm';
import SelectInventoryItem from './SelectInventoryItem';

// Component Definition
const InventoryCheckoutNew: FC = () => {
  const navigate = useNavigate();
  const search = parseSearch(window.location.search);

  const [userId] = useState<string | undefined>(search.userId);
  const [inventoryItemId] = useState<string | undefined>(search.inventoryItemId);

  const [member, setMember] = useState<SimpleUser | null>(null);
  const [inventoryItem, setInventoryItem] = useState<SimpleInventoryItem | null>(null);

  const {
    data: initialMember,
    loading: isInitialMemberLoading,
  } = useGetSimpleUser(userId);
  const {
    data: initialInventoryItem,
    loading: isInitialInventoryItemLoading,
  } = useGetSimpleInventoryItem(inventoryItemId);

  const navigateToInventoryShow = useCallback((id: string) => {
    navigate(`/${PATHS.INVENTORY}/${id}`);
  }, [navigate]);

  const [createInventoryCheckout] = useCreateInventoryCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['inventoryItemsIndex'],
      onCompleted: (data) => navigateToInventoryShow(data.createInventoryCheckout.item.id),
      refetchQueries: () => [
        { query: GET_INVENTORY_ITEM, variables: { id: inventoryItem?.id } },
      ],
    },
  );

  const handleSubmit = async (values: InventoryCheckoutFormValues) => {
    await createInventoryCheckout({
      variables: values,
    });
  };

  useEffect(() => {
    if (initialMember?.user) {
      setMember(initialMember.user);
    }
  }, [initialMember, setMember]);

  useEffect(() => {
    if (initialInventoryItem?.inventoryItem) {
      setInventoryItem(initialInventoryItem.inventoryItem);
    }
  }, [initialInventoryItem, setInventoryItem]);

  return (
    <Page
      isLoading={isInitialMemberLoading || isInitialInventoryItemLoading}
    >
      {!member && <SelectUser onSelectUser={setMember} />}

      {!inventoryItem && member && (
        <SelectInventoryItem onSelectInventoryItem={setInventoryItem} />
      )}

      {member && inventoryItem && (
        <InventoryCheckoutForm
          inventoryItem={inventoryItem}
          member={member}
          onSubmit={handleSubmit}
          title="New Inventory Checkout"
        />
      )}
    </Page>
  );
};

export default InventoryCheckoutNew;
