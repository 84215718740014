// External Dependencies
import { FC } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { useSelector } from 'react-redux';
import ListItemIcon from '@mui/material/ListItemIcon';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { isMobileScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  iconColor?: string;
  isActive?: boolean;
}

// Local Variables
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(6),
  },
  '&.MuiSvgIcon-root': {
    [theme.breakpoints.down('md')]: {
      height: 22,
      width: 22,
    },
  },
}));

// Component Definition
const LeftIcon: FC<Props> = ({
  icon: Icon,
  iconColor,
  isActive,
}) => {
  const { palette: { prestoPrimaryLight } } = useTheme();
  const isMobileScreen = useSelector(isMobileScreenSize);

  return (
    <StyledListItemIcon>
      <Icon
        fontSize={isMobileScreen ? 'small' : 'medium'}
        htmlColor={iconColor
          || (isActive
            ? prestoPrimaryLight
            : iconColor)}
      />
    </StyledListItemIcon>
  );
};

export default LeftIcon;
