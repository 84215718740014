// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { UserRoles } from '@presto-assistant/api_types';
import { mapEnum } from 'utils/lib/map_enum';
import {
  useMemo,
} from 'react';

export const useColumns = (
  extraColumns?: GridColDef[],
) => useMemo(() => {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 160,
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      width: 160,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 160,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 292,
    },
    {
      field: 'roleLabel',
      headerName: 'Role',
      type: 'singleSelect',
      valueOptions: mapEnum(UserRoles).map((option) => ({
        label: option.label,
        value: option.label,
      })),
      width: 80,
    },
    {
      field: 'isAdmin',
      headerName: 'Is Director',
      type: 'boolean',
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
