// External Dependencies
import { GET_SELF } from 'gql/queries';
import { useUpdatePasswordAfterAuth } from 'gql/mutations';
import { validatePasswordChange } from 'utils/forms/self';

// Local Dependencies
import {
  CustomInput,
  DialogForm,
} from '..';

// Component Definition
const UpdatePasswordInterstitial = () => {
  const [updatePassword] = useUpdatePasswordAfterAuth({
    refetchQueries: [{ query: GET_SELF }],
  });

  return (
    <DialogForm
      dialogProps={{
        maxWidth: 'md',
        open: true,
      }}
      initialValues={{
        confirmPassword: '',
        password: '',
      }}
      onSubmit={(values) =>
        updatePassword({
          variables: {
            ...values,
          },
        })}
      title="Please Update Your Password"
      validate={validatePasswordChange}
    >
      <CustomInput
        helperText="Must contain a letter, number, no spaces, and be at least 8 characters"
        label="New Password"
        name="password"
        type="password"
      />
      <CustomInput
        label="Confirm Password"
        name="confirmPassword"
        type="password"
      />
    </DialogForm>
  );
};

export default UpdatePasswordInterstitial;
