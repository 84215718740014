// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { useGetGettingStartedCounts, useGetOrganization } from 'gql/queries';

// Hook Definition
export const useGetGettingStartedData = () => {
  const {
    data: organizationData,
    loading: isLoadingOrganization,
  } = useGetOrganization();
  const {
    data: gettingStartedCountsData,
    loading: isLoadingDashboardMetrics,
  } = useGetGettingStartedCounts();

  const organization = useMemo(() => organizationData?.organization, [organizationData]);
  const dashboardMetrics = useMemo(
    () =>
      gettingStartedCountsData?.dashboardMetrics,
    [gettingStartedCountsData],
  );

  return useMemo(() => ({
    hasAnotherDirector: (dashboardMetrics?.directorCount ?? 0) > 1,
    hasAtLeastOneInventoryItem: (dashboardMetrics?.inventoryCount ?? 0) > 0,
    hasAtLeastOneLibraryItem: (dashboardMetrics?.libraryCount ?? 0) > 0,
    hasAtLeastOneStudent: dashboardMetrics
      && (dashboardMetrics?.userRoleCounts[0]?.count ?? 0) > 0,
    hasAtLeastOneUniformItem: (dashboardMetrics?.uniformCount ?? 0) > 0,
    hasConnectedGoogleCalendar: organization?.hasGoogleCalendar ?? false,
    hasConnectedStripe: organization?.hasStripeId ?? false,
    hasSentAtLeastOneEmail: (dashboardMetrics?.emailCount ?? 0) > 0,
    isDistrictConnected: organization?.district !== null,
    loading: isLoadingOrganization || isLoadingDashboardMetrics,
  }), [
    dashboardMetrics,
    organization?.district,
    organization?.hasGoogleCalendar,
    organization?.hasStripeId,
    isLoadingOrganization,
    isLoadingDashboardMetrics,
  ]);
};
