// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const ParentsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <>
        <Typography paragraph>
          Use the button at the top to manually add a new Parent member.
        </Typography>

        <Typography>
          When parents join and a director approves their membership,
          they will automatically appear in this table.
        </Typography>
      </>
    )}
  />
);

export default ParentsTableZeroState;
