// External Dependencies
import {
  CreateFinancialFundraiserCreditRequest,
} from '@presto-assistant/api_types/api/v1/financialFundraiserCredit';
import { FC, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import {
  createFinancialFundraiserCreditFromRestEndpointSchema,
} from '@presto-assistant/api_types/schemas/financialFundraiserCredits';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import { Container, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateFundraiserCredit } from 'utils/api/financialFundraiserCredit';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import FinancialFundraiserCreditForm from '../shared/FInancialFundraiserCreditForm';

// Component Definition
const FinancialFundraiserCreditsNew: FC = () => {
  const navigate = useNavigate();
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const {
    isLoading: isSubmitting,
    mutate: createFundraiserCredit,
  } = useCreateFundraiserCredit();

  const initialValues = useMemo<CreateFinancialFundraiserCreditRequest>(() => ({
    assignments: [],
    defaultAmountInCents: 0,
    financialAccountId: '',
    isIndividualized: false,
    items: [],
    label: '',
    schoolYearEnding,
  }), [schoolYearEnding]);

  const handleSubmit = useCallback((values: CreateFinancialFundraiserCreditRequest) => {
    const defaultAmountInCents = convertDollarsToCents(values.defaultAmountInCents);

    const assignments = values.isIndividualized
      ? values.assignments
      : values.assignments.map((assignment) => ({
        ...assignment,
        amountInCents: defaultAmountInCents,
      }));

    createFundraiserCredit({
      ...values,
      assignments,
      defaultAmountInCents,
      schoolYearEnding: Number(values.schoolYearEnding),
    }, {
      onSuccess: (data) => {
        navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${data.data.fundraiserCredit.id}`);
      },
    });
  }, [createFundraiserCredit, navigate]);

  const fundraiserCreditsParams = useSelector(
    tableQueryParams('financialFundraiserCredits'),
  );

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`);
  }, [fundraiserCreditsParams, navigate]);

  return (
    <Page
      backButtonProps={{
        label: 'Financial Fundraiser Credits',
        path: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`,
      }}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <Formik<CreateFinancialFundraiserCreditRequest>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={createFinancialFundraiserCreditFromRestEndpointSchema}
        >
          {({
            errors,
            touched,
            values,
          }) => (
            <FinancialFundraiserCreditForm
              assignmentsFormikValue={values.assignments}
              errors={errors}
              financialAccountIdFormikValue={values.financialAccountId}
              isIndividualizedFormikValue={values.isIndividualized}
              isSubmitting={isSubmitting}
              itemsFormikValue={values.items}
              onPressCancelOrBackButton={handlePressCancelOrBackButton}
              schoolYearEndingFormikValue={Number(values.schoolYearEnding)}
              touched={touched}
            />
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default FinancialFundraiserCreditsNew;
