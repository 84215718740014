// Internal Dependencies
import { yup } from 'utils/yup';

export const feeSchema = yup.object().shape({
  financialItemId: yup
    .string()
    .required('Required'),
  schoolYearEnding: yup
    .string()
    .required('Required'),
  userIds: yup
    .array().of(yup.string()).min(1, 'Select at least one member.'),
});

export const deleteFinancialFeeSchema = yup
  .string()
  .required()
  .label('Deleted Note');
