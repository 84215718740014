// External Dependencies
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal Dependencies
import { navigateSearch } from 'utils/lib/navigate_search';
import { useGetOrganizationTypes } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import District from 'components/shared/District';
import FilterButton from 'components/shared/FilterButton';

// Local Typings
export interface DeletedFilters {
  organizationId: string | null;
  organizationTypeId: number | null;
}

// Component Definition
const Filters: FC = () => {
  const navigate = useNavigate();
  const parsedSearch = useParsedSearch();

  const localFilters: DeletedFilters = {
    organizationId: parsedSearch.organizationId ?? null,
    organizationTypeId: parsedSearch.organizationTypeId ?? null,
  };

  const { data: orgTypeData } = useGetOrganizationTypes();

  const handleClickFilter = (
    key: 'organizationId' | 'organizationTypeId',
    val: string | number | null,
  ) => () => {
    localFilters[key] = val as never;
    navigateSearch(
      navigate,
      {
        ...localFilters,
        page: 1,
      },
    );
  };

  return (
    <District>
      {({ district }) => {
        const { organizations } = district;
        const selectedOrg = organizations.find((org) => org.id === localFilters.organizationId);
        const selectedOrgType = orgTypeData?.organizationTypes.find(
          (orgType) => Number(orgType.id) === localFilters.organizationTypeId,
        );

        return (
          <div>
            <FilterButton
              active={!!localFilters.organizationId}
              buttonChildren={selectedOrg ? selectedOrg.label : 'Organization'}
              id="organization"
              onClick={selectedOrg
                ? handleClickFilter('organizationId', null)
                : null}
              subButtons={organizations.map((org) => ({
                onClick: handleClickFilter('organizationId', org.id),
                selected: localFilters.organizationId === org.id,
                text: org.label,
              }))}
            />

            {orgTypeData && (
              <FilterButton
                active={!!localFilters.organizationTypeId}
                buttonChildren={selectedOrgType ? selectedOrgType.label : 'Organization Type'}
                id="organization"
                onClick={selectedOrgType
                  ? handleClickFilter('organizationTypeId', null)
                  : null}
                subButtons={orgTypeData.organizationTypes.map((orgType) => ({
                  onClick: handleClickFilter('organizationTypeId', orgType.id),
                  selected: localFilters.organizationTypeId === Number(orgType.id),
                  text: orgType.label,
                }))}
              />
            )}
          </div>
        );
      }}
    </District>
  );
};

export default Filters;
