// External Dependencies
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

// Local Variables
const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  hasIcon: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  hasMarginTop: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

const defaultProps = {
  hasIcon: false,
  hasMarginTop: false,
};

function getMarginFromProps({ hasIcon }) {
  return hasIcon ? 4 : 12;
}

const useStyles = makeStyles({
  root: {
    marginBottom: getMarginFromProps,
    marginLeft: 16,
    marginTop: ({
      hasIcon,
      hasMarginTop,
    }) => hasMarginTop && getMarginFromProps({ hasIcon }),
  },
});

// Component Definition
const Subtitle = ({
  children,
  sx,
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      component="h2"
      sx={sx}
      variant="subtitle1"
    >
      {children}
    </Typography>
  );
};

Subtitle.propTypes = propTypes;
Subtitle.defaultProps = defaultProps;

export default Subtitle;
