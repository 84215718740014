// External Dependencies
import { Container } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';

// Internal Dependencies
import InventorySettingsForm from './InventorySettingsForm';

// Component Definition
const AdminInventorySettings: FC = () => {
  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
      }}
    >
      <Container maxWidth="md">
        <InventorySettingsForm />
      </Container>
    </Page>
  );
};

export default AdminInventorySettings;
