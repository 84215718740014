// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';
import DistrictDataUI from 'components/shared/Settings/DistrictDataUI';
import NoDistrictUI from 'components/shared/Settings/NoDistrictUI';

// Component Definition
const SettingsDistrictData: FC = () => {
  const { data } = useGetOrganization();

  if (!data) {
    return null;
  }

  const { organization: { district } } = data;

  if (!district) {
    return (
      <NoDistrictUI />
    );
  }
  if (!district) {
    return null;
  }

  return <DistrictDataUI district={district} />;
};

export default SettingsDistrictData;
