// External Dependencies
import { NavigateFunction } from 'react-router-dom';

// Internal Dependencies
import { KeyCodes } from 'types/keyCodes';
import { NavigateSearchFn } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';

export const handleChangePage = (
  _evt: any,
  newPage: number,
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
) => {
  handleUpdateParams(
    navigate,
    {
      page: newPage + 1,
    },
  );
};

export const handleChangeRowsPerPage = (
  evt: React.ChangeEvent<HTMLInputElement>,
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
) => {
  handleUpdateParams(
    navigate,
    {
      limit: +evt.target.value,
      page: 1,
    },
  );
};

export const handleSearch = (
  value = '',
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
) => {
  handleUpdateParams(
    navigate,
    {
      page: 1,
      q: value,
    },
  );
};

export const handleSort = (
  orderBy: string,
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
  currentParams?: string,
) => {
  const parsedSearch = parseSearch(currentParams ?? window.location.search);

  if (parsedSearch.orderBy === orderBy) {
    handleUpdateParams(
      navigate,
      {
        asc: Boolean(!parsedSearch.asc),
      },
    );
  } else {
    handleUpdateParams(
      navigate,
      {
        asc: true,
        orderBy,
      },
    );
  }
};

export const handleClickSort = (
  orderBy: string,
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
  currentParams?: string,
) => () => {
  handleSort(orderBy, handleUpdateParams, navigate, currentParams);
};

export const handleKeyDownSort = (
  orderBy: string,
  handleUpdateParams: NavigateSearchFn,
  navigate: NavigateFunction,
  currentParams?: string,
) => (evt: React.KeyboardEvent) => {
  if (evt.keyCode === KeyCodes.Enter || evt.keyCode === KeyCodes.Space) {
    evt.preventDefault();
    handleSort(orderBy, handleUpdateParams, navigate, currentParams);
  }
};
