// External Dependencies
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  ConfirmationDialog,
} from 'components/shared';

// Local Typings
interface Props {
  districtName?: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

// Component Definition
const ConfirmUpdateDistrictTaxExemptFormDialog = ({
  districtName,
  onClose,
  onConfirm,
  open,
}: Props): JSX.Element => {
  const districtNameFragment = districtName ? `The ${districtName}` : 'This ';

  return (
    <ConfirmationDialog
      confirmButtonAction={onConfirm}
      confirmButtonText="Yes, Continue"
      declineButtonAction={onClose}
      description={(
        <>
          <DialogContentText paragraph>
            {districtNameFragment} district currently has a tax-exempt form on file with {APP_NAME}.
          </DialogContentText>

          <DialogContentText>
            Continue on to provide a new file that you upload.
            This action will replace this district&apos;s current tax-exempt form.
          </DialogContentText>
        </>
      )}
      handleClose={onClose}
      maxWidth="xs"
      open={open}
      title="Update Tax-Exempt Form?"
      useCustomText
    />
  );
};

export default ConfirmUpdateDistrictTaxExemptFormDialog;
