// External Dependencies
import {
  Chip,
  Collapse,
  LinearProgress,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styled from 'styled-components';

// Internal Dependencies
import { EMAIL } from 'utils/constants';
import { Flex } from 'components/shared';
import { toHundredth } from 'utils/lib/to_hundredth';
import WysiwygEditor from 'components/shared/WysiwygEditor';

// Local Typings
export interface Attachment {
  file: File;
  s3Urls: GQL.IPresignedUrl;
}

interface Props {
  attachments?: Attachment[];
  editorState: any;
  isSimpleToolbar?: boolean;
  isToolbarHidden?: boolean;
  minEditorHeight?: string | number;
  onEditorStateChange: (newEditorState: any) => void;
  onUpdateAttachments?: (file: Attachment[]) => void;
  placeholder?: string;
  totalSize?: number;
  totalSizeInMb?: number;
}

// Local Variables
const StyledSpan = styled.span(({
  theme,
}) => ({
  '.barOne': {
    backgroundColor: theme.palette.secondary.dark,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '.chip': {
    margin: theme.spacing(0.5),
  },
  '.progressContainer': {
    borderRadius: theme.shape.borderRadius,
  },
}));

const maxMb = EMAIL.MAX_ATTACHMENT_SIZE;

// Local Functions
const EmailEditor: FC<Props> = ({
  attachments,
  editorState,
  isSimpleToolbar,
  isToolbarHidden = true,
  minEditorHeight,
  onEditorStateChange,
  onUpdateAttachments,
  placeholder = 'Type your message...',
  totalSize = 0,
  totalSizeInMb = 0,
}) => {
  const handleRemoveAttachment = (attachment: Attachment) => () => {
    if (attachments && onUpdateAttachments) {
      const fileIndex = attachments.indexOf(attachment);

      attachments.splice(fileIndex, 1);

      onUpdateAttachments([...attachments]);
    }
  };

  return (
    <>
      <WysiwygEditor
        editorState={editorState}
        isSimpleToolbar={isSimpleToolbar}
        isToolbarHidden={isToolbarHidden}
        minEditorHeight={minEditorHeight}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
      />

      <StyledSpan>
        <Collapse
          in={attachments && attachments.length > 0}
          timeout="auto"
        >
          <Flex marginBottom={2}>
            {attachments?.map((attachment) => (
              <Chip
                className="chip"
                color="primary"
                icon={<AttachFileIcon fontSize="small" />}
                key={`${attachment.file.name}-${attachment.file.lastModified}`}
                label={attachment.file.name}
                onDelete={handleRemoveAttachment(attachment)}
                variant="outlined"
              />
            ))}
          </Flex>

          {totalSize > 0 && (
            <div className="progressContainer">
              <Typography variant="body2">
                {`${toHundredth(totalSizeInMb)}MB of ${EMAIL.MAX_ATTACHMENT_SIZE}MB`}
              </Typography>

              <LinearProgress
                classes={{
                  bar1Determinate: 'barOne',
                  root: 'bar',
                }}
                color="secondary"
                value={(totalSizeInMb / maxMb) * 100}
                variant="determinate"
              />
            </div>
          )}
        </Collapse>
      </StyledSpan>
    </>
  );
};

export default EmailEditor;
