// External Dependencies
import { BaseSelectProps, SelectChangeEvent } from '@mui/material/Select';
import { FC, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { useGetOrganizationRoles } from 'gql/queries';

// Local Dependencies
import CustomSelect from './CustomSelect';
import Flex from './Flex';

// Local Typings
interface Props {
  excludedIds?: string[] | null | undefined;
  label: string;
  name: string;
  onChange?: ((event: SelectChangeEvent<unknown>, child: ReactNode) => void);
  readOnly?: boolean;
  value?: BaseSelectProps['value'];
}

// Component Definition
const RoleSelector: FC<Props> = ({
  excludedIds,
  label,
  name,
  onChange,
  readOnly,
  ...props
}) => {
  const {
    data,
    error,
    loading,
  } = useGetOrganizationRoles();

  if (error) {
    return (
      <Typography variant="body1">
        Unable to get roles
      </Typography>
    );
  }

  if (loading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  if (!data) {
    return null;
  }

  const {
    organizationRoles,
  } = data;

  const organizationRoleOptions = organizationRoles
    .filter((role) => !excludedIds?.includes(role.id));

  return organizationRoleOptions && (
    <CustomSelect
      inputProps={{ readOnly }}
      label={label}
      name={name}
      onChange={onChange}
      options={organizationRoleOptions}
      {...props}
    />
  );
};

export default RoleSelector;
