// External Dependencies
import { InventoryFundingSources } from '@presto-assistant/api_types';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryPurchaseData = ({ inventoryItemData }: Props): JSX.Element | null => {
  if (!inventoryItemData) {
    return null;
  }

  const {
    inventoryFundingSourceId,
    purchaseOrderNumber,
    purchaseValueInCents,
    purchaseYear,
    purchasedAt,
  } = inventoryItemData;

  const fundingSourceLabel = inventoryFundingSourceId
    ? toTitleCase(InventoryFundingSources[inventoryFundingSourceId])
    : '';

  return (
    <>
      <ShowPageDataDisplay
        label="Purchase Year"
        type="number"
        value={purchaseYear}
      />
      <ShowPageDataDisplay
        label="Purchase Date"
        type="date"
        value={purchasedAt}
      />
      <ShowPageDataDisplay
        label="Purchase Value"
        type="currency"
        value={purchaseValueInCents}
      />
      <ShowPageDataDisplay
        label="Purchase Order Number"
        value={purchaseOrderNumber}
      />
      <ShowPageDataDisplay
        label="Funding Source"
        value={fundingSourceLabel}
      />
    </>
  );
};

export default ShowInventoryPurchaseData;
