// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { StyledLink } from 'components/shared';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const DirectorPermissionsTableZeroState: FC = () => (
  <TableDataGridZeroState
    message={(
      <Typography>
        Invite directors or find organizations from
        the <StyledLink href={PATHS.ORGANIZATIONS}>Organizations page</StyledLink>
        <br />
        to see director permisions in this table.
      </Typography>
    )}
  />
);

export default DirectorPermissionsTableZeroState;
